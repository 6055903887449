import { Table } from "antd";
import Dropdown from "react-bootstrap/Dropdown";
import ApplicationChart from "./ApplicationChart";
import { useEffect, useState } from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Icon } from "@iconify/react";

const BudgetAllList = (props) => {
  const [chartdrawerstatus, setChartDrawerStatus] = useState(false);
  const [tabledata, setTableData] = useState(props.applicationbudgettable);
  // console.log("tablereacords");
  // console.log(tabledata);
  // console.log(props.applicationbudgettable);

  // const [annualdata, setAnnualData] = useState([63000,63000,63000,63000,63000,63000,63000,63000,63000,63000,63000,63000]);

  useEffect(() => {
    setTableData([
      ...props.applicationbudgettable,
      {
        key: "3",
        environment: "Remaining Budget",
        application: "",
        budget_jan:
          Number(props.annualplannedlist[0]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_jan),
            0
          ),
        budget_feb:
          Number(props.annualplannedlist[1]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_feb),
            0
          ),
        budget_mar:
          Number(props.annualplannedlist[2]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_mar),
            0
          ),
        budget_apr:
          Number(props.annualplannedlist[3]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_apr),
            0
          ),
        budget_may:
          Number(props.annualplannedlist[4]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_may),
            0
          ),
        budget_jun:
          Number(props.annualplannedlist[5]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_jun),
            0
          ),
        budget_jul:
          Number(props.annualplannedlist[6]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_jul),
            0
          ),
        budget_aug:
          Number(props.annualplannedlist[7]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_aug),
            0
          ),
        budget_sept:
          Number(props.annualplannedlist[8]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_sept),
            0
          ),
        budget_oct:
          Number(props.annualplannedlist[9]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_oct),
            0
          ),
        budget_nov:
          Number(props.annualplannedlist[10]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_nov),
            0
          ),
        budget_dec:
          Number(props.annualplannedlist[11]) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.budget_dec),
            0
          ),
        annual_total:
          props.annualplannedlist.reduce((a, b) => +a + +b, 0) -
          props.applicationbudgettable.reduce(
            (a, v) => (a = +a + +v.annual_total),
            0
          ),
        action: "",
        type: "footer",
      },
    ]);
  }, [props]);

  const columns = [
    {
      title: "Environment",
      dataIndex: "environment",
      key: "environment",
    },
    {
      title: "Application",
      dataIndex: "application",
      key: "application",
    },
    {
      title: "Account",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Year",
      dataIndex: "budget_year",
      key: "budget_year",
    },
    {
      title: "Jan",
      dataIndex: "budget_jan",
      key: "budget_jan",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Feb",
      dataIndex: "budget_feb",
      key: "budget_feb",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Mar",
      dataIndex: "budget_mar",
      key: "budget_mar",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Apr",
      dataIndex: "budget_apr",
      key: "budget_apr",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "May",
      dataIndex: "budget_may",
      key: "budget_may",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Jun",
      dataIndex: "budget_jun",
      key: "budget_jun",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Jul",
      dataIndex: "budget_jul",
      key: "budget_jul",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Aug",
      dataIndex: "budget_aug",
      key: "budget_aug",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Sep",
      dataIndex: "budget_sept",
      key: "budget_sept",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Oct",
      dataIndex: "budget_oct",
      key: "budget_oct",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Nov",
      dataIndex: "budget_nov",
      key: "budget_nov",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },

    {
      title: "Dec",
      dataIndex: "budget_dec",
      key: "budget_dec",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: "Annual Total",
      dataIndex: "annual_total",
      key: "annual_total",
      render: (record, index) => {
        const number = Number(record);
        if (index.type === "footer" || number === 0) {
          if (index.type === "footer" || number === 0) {
            if (number >= 0) {
              return (
                <>
                  <span style={{ color: "rgb(10 137 0)" }}>
                    {" "}
                    $
                    {number.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            } else {
              return (
                <>
                  <span style={{ color: "#be1e2d" }}>
                    {" "}
                    $
                    {Math.abs(number).toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}{" "}
                  </span>
                </>
              );
            }
          } else {
            return `$${number.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}`;
          }
        } else {
          return `$${number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}`;
        }
      },
    },
    {
      title: (
        <Dropdown className="plat-costimize-dropdown">
          <Dropdown.Toggle
            className="plat-three-dort plat-tap-menu plat-box-three-dort"
            id="dropdown-button-dark-example1"
            variant="secondary"
          >
            <Icon icon="ph:dots-three-bold" class="table-coloum-dots-icon" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="plat-box-menu-list more-details">
            <Dropdown.ItemText
              className="plat-dropdown-item-text"
              onClick={() => props.addYearBudgetDetails()}
            >
              <span className="plat-box-item-title list-expand-left">Add</span>
              <span className="plat-box-item-title list-expand-right">
                <Icon
                  icon="material-symbols:add-box-outline"
                  class="table-add-records-icon"
                />
              </span>
            </Dropdown.ItemText>
          </Dropdown.Menu>
        </Dropdown>
      ),
      dataIndex: "action",
      key: "action",
      // className: "table-edit-list",
      render: (record, index) => {
        var edit = "";
        var addicon = "";
        if (index.type === "footer") {
          // addicon = <Dropdown.ItemText className="plat-dropdown-item-text" onClick={() => props.addYearBudgetDetails()}>
          //             <span className='plat-box-item-title list-expand-left'>
          //                 Add
          //             </span>
          //             <span className='plat-box-item-title list-expand-right'>
          //             <Icon icon="material-symbols:add-box-outline"  class="table-add-records-icon"/>
          //             </span>
          //         </Dropdown.ItemText>
          addicon = "";
          return "";
        } else {
          edit = (
            <Dropdown.ItemText
              className="plat-dropdown-item-text"
              onClick={() => props.editYearBudgetDetails(index)}
            >
              <span className="plat-box-item-title list-expand-left">Edit</span>
              <span className="plat-box-item-title list-expand-right">
                <Icon
                  icon="material-symbols:edit-outline"
                  class="table-add-records-icon"
                />
              </span>
            </Dropdown.ItemText>
          );
          return (
            <>
              <Dropdown className="plat-costimize-dropdown plat-box-costimize-dropdown">
                <Dropdown.Toggle
                  className="plat-three-dort plat-tap-menu plat-box-three-dort"
                  id="dropdown-button-dark-example1"
                  variant="secondary"
                >
                  <Icon
                    icon="ph:dots-three-bold"
                    class="plat-expandalt-icon-view"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="plat-box-menu-list more-details">
                  {edit}
                </Dropdown.Menu>
              </Dropdown>
            </>
          );
        }
      },
    },
  ];

  const tableProps = {};

  const changeChartDrawerStatus = () => {
    setChartDrawerStatus(false);
  };

  const applicationExport = () => {
    const tablelist = tabledata.map((data) => {
      return {
        Environment: data.environment,
        Application: data.application,
        Account: data.account,
        Jan: data.budget_jan,
        Feb: data.budget_feb,
        Mar: data.budget_mar,
        Apr: data.budget_apr,
        May: data.budget_may,
        Jun: data.budget_jun,
        Jul: data.budget_jul,
        Aug: data.budget_aug,
        Sep: data.budget_sept,
        Oct: data.budget_oct,
        Nov: data.budget_nov,
        Dec: data.budget_dec,
        "Annual Total": data.annual_total,
      };
    });
    const fileName = "Application Budget";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheettml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(tablelist);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <>
      <ApplicationChart
        chartdrawerstatus={chartdrawerstatus}
        changeChartDrawerStatus={changeChartDrawerStatus}
        setChartDrawerStatus={setChartDrawerStatus}
        applicationbudgettable={props.applicationbudgettable}
      />
      <div className="budget-allocated-table">
        <h1 className="plat-table-title">Application Budget</h1>
        <div className="table-right-icon-list">
          <div onClick={() => setChartDrawerStatus(true)}>
            <Icon icon="mdi:chart-bell-curve" class="budget-table-chart-icon" />
          </div>
          <div>
            <Dropdown className="plat-costimize-dropdown">
              <Dropdown.Toggle
                className="plat-three-dort plat-tap-menu plat-box-three-dort"
                id="dropdown-button-dark-example1"
                variant="secondary"
              >
                <Icon
                  icon="ph:dots-three-bold"
                  class="plat-expandalt-icon-view"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="plat-box-menu-list more-details">
                <Dropdown.ItemText
                  className="plat-dropdown-item-text"
                  onClick={applicationExport}
                >
                  <span className="plat-box-item-title list-expand-left">
                    Export to Excel
                  </span>
                  <span className="plat-box-item-title list-expand-right">
                    <Icon icon="file-icons:microsoft-excel" />
                  </span>
                </Dropdown.ItemText>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <br></br>
        <div className="table-width">
          <Table
            {...tableProps}
            // className="budget-table"
            className="savings_new_table"
            columns={columns}
            dataSource={tabledata}
            pagination={false}
            // rowClassName={(record, index) => {
            //     console.log(record);
            //     console.log(index);
            // }}
          />
        </div>
      </div>
    </>
  );
};

export default BudgetAllList;
