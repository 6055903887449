import { Card, Col, Row, Select } from "antd";
import React, { useEffect } from "react";
import { Icon } from "@iconify/react";
import AlertDetailsTable from "./AlertDetailsTable";
import AlertDetailsBarChart from "./AlertDetailsBarChart";
import AlertDetailsTableTwo from "./AlertDetailsTableTwo";
import export_logo from "./../../assets/images/cams/excel_logo.png";
import { useState } from "react";
import ModalPopUp from "./ModalPopUp";
import TicketInformationTable from "./TicketInformationTable";
import { useDispatch } from "react-redux";
import {
  getCriticalWarningCount,
  getNServers,
  getWeeklyData,
  nServersFullData,
} from "../action/graphql/cams/cams_reducers";
import moment from "moment";

function AlertDetails({ customer }) {
  const [isShow, setIsShow] = useState(false);
  const handleCancel = () => {
    setIsShow(false);
  };
  const options = [
    {
      label: "10",
      value: "10",
    },
    {
      label: "20",
      value: "20",
    },
    {
      label: "30",
      value: "30",
    },
    {
      label: "40",
      value: "40",
    },
    {
      label: "50",
      value: "50",
    },
  ];

  const [serverCount, setServerCount] = useState("10");
  const handleChange = async (e) => {
    await setServerCount(e);
    getTopServers(e);
  };
  const company_name =
    localStorage.getItem("CognitoIdentityServiceProvider.company") !==
    "Internal1CH"
      ? localStorage.getItem("CognitoIdentityServiceProvider.company")
      : customer;
  const currentDate = new Date();
  var dateString = moment(currentDate).format("YYYY/MM/DD");
  const [serverLabels, setServerLabels] = useState([]);
  const [warnCount, setWarnCount] = useState([]);
  const [criticalCount, setCriticalCount] = useState([]);
  const [serverData, setServerData] = useState([{}]);
  const [sixDatesString, setSixDatesString] = useState("");
  const [severityWeeklyData, setSeverityWeeklyData] = useState([]);
  const [fullDetails, setFullDetails] = useState([]);
  const [alertcountstatus, setAlertCountStatus] = useState(true);
  const dispatch = useDispatch();
  var servers = "";
  useEffect(() => {
    getSixDatesData();

    getTopServers(serverCount);
    getCriticalCount();
  }, [customer, company_name]);

  const [sixDatesLabel, setSixDatesLabel] = useState([]);
  useEffect(() => {}, [
    serverData,
    serverLabels,
    warnCount,
    criticalCount,
    severityWeeklyData,
    sixDatesLabel,
  ]);
  const getSixDatesData = () => {
    const today = moment().format("YYYY-MM-DD");
    const dates = [today];

    sixDatesLabel.push(moment().format("MMM D, YYYY"));
    for (let i = 1; i <= 5; i++) {
      const nextDate = moment().subtract(i, "days").format("YYYY-MM-DD");
      dates.push(nextDate);
      sixDatesLabel.push(moment().subtract(i, "days").format("MMM D, YYYY"));
    }
    let six = dates.join(",");
    setSixDatesString(six);

    dispatch(getWeeklyData({ companyName: company_name, selectedTopdate: six }))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        setSeverityWeeklyData(data.cams_severity_main_table);
      });
  };

  const getTopServers = async (count) => {
    setAlertCountStatus(true);
    dispatch(
      getNServers({
        companyName: company_name,
        selectedTopdate: dateString,
        count: count,
      })
    )
      .unwrap()
      .then(({ data }) => {
        let tempArr = [];
        data.cams_top_servers.forEach((data) => {
          tempArr.push(data.server_name);
        });
        servers = tempArr.join(",");
        getTopServersFullDetails(servers);
        setServerData(data);
        setAlertCountStatus(false);
      });
  };

  const getTopServersFullDetails = (servers) => {
    dispatch(
      nServersFullData({
        companyName: company_name,
        selectedTopdate: dateString,
        server: servers,
      })
    )
      .unwrap()
      .then(({ data }) => {
        setFullDetails(data.cams_top_details);
      });
  };
  const getCriticalCount = () => {
    dispatch(
      getCriticalWarningCount({
        companyName: company_name,
        selectedTopdate: dateString,
      })
    )
      .unwrap()
      .then(({ data }) => {
        let criticalWarningCountData = [];
        criticalWarningCountData = data.cams_alert_percentage;
        let serverTemp = [];
        let warnTemp = [];
        let criticalTemp = [];
        criticalWarningCountData.forEach((data) => {
          serverTemp.push(data.server_name);
          warnTemp.push(parseInt(data.warning));
          criticalTemp.push(parseInt(data.critical));
        });
        setServerLabels(serverTemp);
        setCriticalCount(criticalTemp);
        setWarnCount(warnTemp);
      });
  };
  return (
    <>
      <div className="alert-details mb-5">
        <Row gutter={[16, 16]}>
          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            <Card className="plat-card-1 p-2">
              <div className="d-flex justify-content-between">
                <h6 className="card-title">Top N Servers by alert Count</h6>
                <div className="options">
                  {/* <Icon
                    icon={"ph:dots-three-vertical-bold"}
                    style={{ fontSize: "18px" }}
                  /> */}
                </div>
              </div>
              <div className="">
                <Select
                  className="devops-antd-select"
                  defaultValue={serverCount}
                  onChange={(e) => handleChange(e)}
                  options={options}
                />
              </div>
              <div className="table-section">
                <div
                  className="info-icon-position"
                  onClick={() => {
                    setIsShow(true);
                  }}
                ></div>
                <AlertDetailsTable
                  serverData={serverData}
                  alertcountstatus={alertcountstatus}
                />
                <span className="alert-info">
                  <Icon
                    icon={"material-symbols:info-rounded"}
                    style={{ fontSize: "18px" }}
                  />{" "}
                  &nbsp; Click on the icon to know about the ticket information
                </span>
              </div>
            </Card>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            {" "}
            <Card className="plat-card-1 p-2">
              <div className="d-flex justify-content-between">
                <h6 className="card-title">Alert Count by server name</h6>
              </div>
              <div className="alert-details-chart pt-3">
                <AlertDetailsBarChart
                  serverLabels={serverLabels}
                  warnCount={warnCount}
                  criticalCount={criticalCount}
                />
              </div>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            {/* <div className="export d-flex justify-content-end">
              <span>
                <img src={export_logo} /> Export
              </span>
            </div>{" "} */}
            <Card className="plat-card-1 p-2">
              <div className="alert-text">
                *A-Alerts Processed *SAP-SmartAlert Processed
              </div>
              <div className="alert-details-chart pt-3">
                <AlertDetailsTableTwo
                  customer={customer}
                  dateLabel={sixDatesLabel}
                  severityWeeklyData={severityWeeklyData}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      {isShow && (
        <ModalPopUp
          title="Ticket Information"
          open={isShow}
          handleCancel={handleCancel}
          data={<TicketInformationTable camsAllDetailsData={fullDetails} />}
        />
      )}
    </>
  );
}

export default AlertDetails;
