import React from "react";
import { Space, Table, Tag } from "antd";
import { Icon } from "@iconify/react";
import WeeklySpendByServiceLevel from "./WeeklySpendByServiceLevel";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  PDFViewer,
} from "@react-pdf/renderer";
const styles = StyleSheet.create({
  // table: {
  //   display: "table",
  //   width: "auto",
  //   borderStyle: "solid",
  //   borderWidth: 1,
  //   borderRightWidth: 0,
  //   borderBottomWidth: 0,
  //   marginTop: 10,
  // },
  // tableRow: {
  //   margin: "auto",
  //   flexDirection: "row",
  // },
  // tableCol: {
  //   width: "50%",
  //   borderStyle: "solid",
  //   borderWidth: 1,
  //   // borderLeftWidth: 0,
  //   // borderTopWidth: 0,
  //   padding: 10,
  //   paddingTop: 4,
  //   paddingBottom: 4,
  // },
  // tableColumns: {
  //   width: "50%",
  //   borderStyle: "solid",
  //   borderWidth: 1,
  //   // borderLeftWidth: 0,
  //   // borderTopWidth: 0,
  //   padding: 10,
  //   paddingTop: 4,
  //   paddingBottom: 4,
  // },
  // tableCell: {
  //   margin: "auto",
  //   // marginTop: 5,
  //   fontSize: 8,
  // },

  // table: {
  //   width: "auto",

  //   display: "table",
  // },
  // tableRow: {
  //   flexDirection: "row",
  //   margin: "auto",
  //   borderColor: "#eee",
  //   borderWidth: 1,
  // },
  // tableColumns: {
  //   flex: 1,
  //   borderColor: "#eee",
  //   borderWidth: 1,
  //   padding: 5,
  //   // marginTop: 15,
  //   paddingBottom: 15,
  //   backgroundColor: "#eee",
  // },
  // tableCol: {
  //   flex: 1,
  //   borderColor: "#eee",
  //   borderWidth: 1,
  //   padding: 5,
  //   paddingTop: 15,
  //   paddingBottom: 15,
  // },
  // tableCell: {
  //   textAlign: "center",
  //   fontSize: 8,
  // },
  table: {
    width: "100%",
    borderColor: "black",
    borderWidth: 1,
    flexDirection: "column",
  },
  tableRow: {
    flexDirection: "row",
    backgroundColor: "#f0f0f0",
    borderColor: "black",
    borderWidth: 1,
  },
  tableColumns: {
    flex: 1,
    borderColor: "black",
    borderWidth: 1,
    paddingVertical: 3,
  },
  tableCol: {
    flex: 1,
    borderColor: "black",
    borderWidth: 1,
    paddingVertical: 3,
  },
  tableCell: {
    textAlign: "center",
    fontSize: 8,
  },
});
function OverallSummary({ overallui }) {
  // const handleClick = (id) => {
  //   // Logic to handle navigation to another component based on ID
  //   console.log(`Navigating to component with ID: ${id}`);
  //   // Perform your navigation logic here...
  // };
  // const columns = [
  //   {
  //     title: "Projected Spend $",
  //     dataIndex: "projectedspend",
  //     key: "projectedspend",
  //     align: "right",
  //     render: (text, record) => (
  //       <a
  //         className="atagcolor"
  //         href={`#${record.key}`}
  //         onClick={() => handleClick(record.key)}
  //       >
  //         {text}
  //       </a>
  //     ),
  //   },
  //   {
  //     title: "Spend for the month $",
  //     dataIndex: "spendforthemonth",
  //     key: "spendforthemonth",
  //     align: "right",
  //     render: (text, record) => (
  //       <span>
  //         {record.spendforthemonth}{" "}
  //         <Icon icon="mdi:arrow-down-drop" color="red" />
  //       </span>
  //     ),
  //   },
  // ];
  // const data = [
  //   {
  //     key: "1",
  //     projectedspend: "132760",
  //     spendforthemonth: "58396  -35",
  //   },
  // ];
  const formatNumberWithCommas = (number) => {
    const roundedNumber = Math.round(number); // Round the number to remove floating point
    return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div>
      {/* <Table
        className="weeklytable"
        columns={columns}
        dataSource={data}
        pagination={false}
      /> */}
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>Projected Spend $</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>Spend for the Month $</Text>
          </View>
        </View>

        {overallui.map((item) => {
          return (
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {" "}
                  ${formatNumberWithCommas(item.projected_spend)}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  ${formatNumberWithCommas(item.current_month_spend)}
                  <Text style={{ fontSize: 7 }}>
                    {" "}
                    {item.difference >= 0
                      ? `$${formatNumberWithCommas(item.difference)}`
                      : `-$${formatNumberWithCommas(
                          item.difference.toString().slice(1)
                        )}`}
                  </Text>
                </Text>
              </View>
            </View>
          );
        })}
      </View>
      {/* 
      <br></br>
      <Text style={{ fontSize: 8, textAlign: "center", marginTop: 15 }}>
        {" "}
        * as of :{" "}
        <Text style={{ fontSize: 8, color: "#FF6A2F" }}>02/09/2023</Text>
      </Text> */}
    </div>
  );
}

export default OverallSummary;
