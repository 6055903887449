import React, { memo, useEffect, useState } from "react";
import {
  Row,
  Col,
  Dropdown,
  message,
  Button,
  Checkbox,
  Menu,
  Input,
  Tooltip,
} from "antd";
import { Icon } from "@iconify/react";
import "./Filter.css";
import { getTopMenuList } from "../../action/costimizeV2Active";
import { useDispatch, useSelector } from "react-redux";
import awsicon from "../../../assets/images/aws.svg";
import {
  environmentListMethod,
  cloudListMethod,
  accountListMethod,
  applicationListMethod,
  selectedEnvironmentMethod,
  selectedTopDateMethod,
  selectedAccountMethod,
  selectedCloudMethod,
  selectedApplicationMethod,
  selectedServicesMethod,
  selectedOSMethod,
  selectedInfraMethod,
  selectedDbMethod,
  setServiceList,
  setInfraRList,
  setOsList,
  setDbList,
  setFilterButtonStatus,
} from "../../slice/costimizeSlice";
import { dropDownResult } from "../../custom_hook/CustomHook";
const CostdemazeFilter = () => {
  const {
    applicationList,
    environmentList,
    accountList,
    cloudList,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    serviceList,
    infraList,
    osList,
    dbList,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
    selectedTopdate,
    filterbuttonstatus,
  } = useSelector((state) => state.constimize);
  const [accountlist, setAccountList] = useState([]);
  const [cloudlist, setCloudList] = useState([]);
  const [environmentlist, setEnvironmentList] = useState([]);
  const [applicationlist, setApplicationList] = useState([]);
  const [selectaccount, setSelectAccount] = useState(selectedAccount);
  const [selectcloud, setSelectCloud] = useState(selectedCloud);
  const [recordslist, setRecordsList] = useState([]);
  const [accountDropdownVisible, setAccountDropdownVisible] = useState(false);
  const [clouddropdownVisible, setCloudDropdownVisible] = useState(false);
  const [envdropdownVisible, setEnvDropdownVisible] = useState(false);
  const [datachanges, setDataChanges] = useState(true);
  const [selectapplication, setSelectApplication] =
    useState(selectedApplication);
  const [selectenvironment, setSelectEnvironment] =
    useState(selectedEnvironment);
  const [selectedservicessta, setSelectedServices] = useState(selectedservices);
  const [selectedossta, setSelectedOS] = useState(selectedos);
  const [selectedinfrasta, setSelectedInfra] = useState(selectedinfra);
  const [selecteddbsta, setSelectedDB] = useState(selecteddb);
  const [dbdropdownVisible, setdbDropdownVisible] = useState(false);
  const [osdropdownVisible, setosDropdownVisible] = useState(false);
  const [infradropdownVisible, setInfraDropdownVisible] = useState(false);
  const [applicationdropdownVisible, setApplicationDropdownVisible] =
    useState(false);
  const [servicedropdownVisible, setServiceDropdownVisible] = useState(false);
  const [selectedaccValues, setSelectedaccValues] = useState(["all"]);
  const [selectedcloudValues, setSelectedcloudValues] = useState(["all"]);
  const [selectedenvValues, setSelectedenvValues] = useState(["all"]);
  const [selectedappValues, setSelectedappValues] = useState(["all"]);
  const [selectedosValues, setSelectedosValues] = useState(["all"]);
  const [selecteddbValues, setSelecteddbValues] = useState(["all"]);
  const [selectedinfraValues, setSelectedinfraValues] = useState(["all"]);
  const [selectedserviceValues, setSelectedServiceValues] = useState(["all"]);
  const [serviceslist, setServicesList] = useState([]);
  const [oslist, setOSList] = useState([]);
  const [infralist, setInfraList] = useState([]);
  const [dblist, setdbList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    const companyName = localStorage.getItem(
      "CognitoIdentityServiceProvider.company"
    );
    const cloud_list = selectedCloud.toString();
    dispatch(getTopMenuList({ companyName, selectedTopdate, cloud_list }))
      .unwrap()
      .then(({ data }) => {
        setRecordsList(data.newfunctionquery1);
        if (data.newfunctionquery1.length === 0) {
          messageApi.info("Records not get ready yet.");
        }
        const accountConfigs = data.newfunctionquery1
          .map((v) => v.account_name)
          .filter((name) => name !== null && name !== undefined); // Filter out null or undefined names

        const accountarr = new Set([...accountConfigs]);

        const accountshort = [...accountarr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        // const accountConfigs = data.newfunctionquery1.map(
        //   (v) => v.account_name OLD CODE commented on 15/07/2024
        // );
        // const accountarr = new Set([...accountConfigs]);
        // const accountshort = [...accountarr].sort((a, b) =>
        //   a.toLowerCase() < b.toLowerCase() ? -1 : 1
        // );
        dispatch(accountListMethod([...accountshort]));
        setAccountList([...accountshort]);
        //cloud
        const cloudConfigs = data.newfunctionquery1.map((v) => v.cloud);
        const cloudarr = new Set([...cloudConfigs]);
        const cloudshort = [...cloudarr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        dispatch(cloudListMethod([...cloudshort]));
        setCloudList([...cloudshort]);
        //environment
        const environmentConfigs = data.newfunctionquery1.map(
          (v) => v.environment
        );
        const environmentarr = new Set([...environmentConfigs]);
        const environshort = [...environmentarr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        dispatch(environmentListMethod([...environshort]));
        setEnvironmentList([...environshort]);
        //application
        const applicationConfigs = data.newfunctionquery1.map(
          (v) => v.application
        );

        const applicationarr = new Set([...applicationConfigs]);
        const applicationshort = [...applicationarr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        dispatch(applicationListMethod([...applicationshort]));
        setApplicationList([...applicationshort]);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [selectedTopdate]);
  useEffect(() => {
    var newdropdownlist = recordslist;
    const filteredlist = recordslist.filter((datas) => {
      //dropdown condition
      var checkenvironment = dropDownResult(
        selectedEnvironment,
        datas.environment
      );
      var checkcloud = dropDownResult(selectedCloud, datas.cloud);
      var checkapplication = dropDownResult(
        selectedApplication,
        datas.application
      );
      var checkaccount = dropDownResult(selectedAccount, datas.account_name);

      const conditionloop = [
        checkapplication,
        checkenvironment,
        checkcloud,
        checkaccount,
      ];
      const eval_value = conditionloop.filter(function (element) {
        return element !== undefined;
      });
      var returnvalue;
      if (eval_value.length == 1) {
        returnvalue = eval(eval_value[0]);
      } else if (eval_value.length == 2) {
        returnvalue = eval(eval_value[0], eval_value[1]);
      } else if (eval_value.length == 3) {
        returnvalue = eval(eval_value[0], eval_value[1], eval_value[2]);
      } else if (eval_value.length == 4) {
        returnvalue = eval(
          eval_value[0],
          eval_value[1],
          eval_value[2],
          eval_value[3]
        );
      } else if (eval_value.length == 5) {
        returnvalue = eval(
          eval_value[0],
          eval_value[1],
          eval_value[2],
          eval_value[3],
          eval_value[4]
        );
      } else if (eval_value.length == 6) {
        returnvalue = eval(
          eval_value[0],
          eval_value[1],
          eval_value[2],
          eval_value[3],
          eval_value[4],
          eval_value[5]
        );
      } else if (eval_value.length == 7) {
        returnvalue = eval(
          eval_value[0],
          eval_value[1],
          eval_value[2],
          eval_value[3],
          eval_value[4],
          eval_value[5],
          eval_value[6]
        );
      } else if (eval_value.length == 8) {
        returnvalue = eval(
          eval_value[0],
          eval_value[1],
          eval_value[2],
          eval_value[3],
          eval_value[4],
          eval_value[5],
          eval_value[6],
          eval_value[7]
        );
      }
      var returncondition;
      if (returnvalue === 1) {
        returncondition = true;
      } else if (returnvalue === true) {
        returncondition = true;
      } else {
        returncondition = false;
      }
      return returncondition;
    });
    if (filteredlist.length > 0) {
      newdropdownlist = filteredlist;
    }
    //service
    const serviceConfigs = newdropdownlist.map((v) => v.service);
    const servicearr = new Set([...serviceConfigs]);

    const serviceshort = [...servicearr]?.sort((a, b) =>
      a?.toLowerCase() < b?.toLowerCase() ? -1 : 1
    );
    setServicesList([...serviceshort]);
    dispatch(setServiceList([...serviceshort]));

    //db
    const dbConfigsfilter = newdropdownlist.filter((datas) => {
      return datas.db !== null && datas.db !== "";
    });
    const dbConfigs = dbConfigsfilter.map((v) => v.db);
    const dbarr = new Set([...dbConfigs]);
    const dbshort = [...dbarr].sort((a, b) =>
      a.toLowerCase() < b.toLowerCase() ? -1 : 1
    );

    setdbList([...dbshort]);
    dispatch(setDbList([...dbshort]));

    //os
    const osConfigsfilter = newdropdownlist.filter((datas) => {
      return datas.os !== null && datas.os !== "";
    });
    const osConfigs = osConfigsfilter.map((v) => v.os);
    const osarr = new Set([...osConfigs]);
    const osshort = [...osarr].sort((a, b) =>
      a.toLowerCase() < b.toLowerCase() ? -1 : 1
    );
    setOSList([...osshort]);
    dispatch(setOsList([...osshort]));

    //infra
    const infraConfigs = newdropdownlist.map((v) => v.infra);
    const infraarr = new Set([...infraConfigs]);
    const infrashort = [...infraarr].sort((a, b) =>
      a.toLowerCase() < b.toLowerCase() ? -1 : 1
    );
    setInfraList([...infrashort]);
    dispatch(setInfraRList([...infrashort]));
  }, [
    recordslist,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
  ]);

  const handleChange = (type, value, checked) => {};
  const handleMenuClick = ({ key }) => {
    if (key === "reset") {
    }
  };

  const handleCheckboxChange = (title, value, checked) => {
    let updatedValues;
    if (title === "account") {
      updatedValues = selectedaccValues.includes(value)
        ? selectedaccValues.filter((item) => item !== value)
        : [...selectedaccValues, value];
      setSelectedaccValues(updatedValues);
      if (value === "all") {
        setSelectAccount(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (updatedValues.indexOf("all") !== -1) {
            setSelectAccount([...updatedValues.filter((x) => x !== "all")]);
          } else {
            setSelectAccount([...updatedValues]);
          }
        } else {
          const result = updatedValues.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectAccount(result_value);
        }
      }
    } else if (title === "cloud") {
      updatedValues = selectedcloudValues.includes(value)
        ? selectedcloudValues.filter((item) => item !== value)
        : [...selectedcloudValues, value];

      setSelectedcloudValues(updatedValues);
      if (value === "all") {
        setSelectCloud(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (updatedValues.indexOf("all") !== -1) {
            setSelectCloud([...updatedValues.filter((x) => x !== "all")]);
          } else {
            setSelectCloud([...updatedValues]);
          }
        } else {
          const result = updatedValues.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectCloud(result_value);
        }
      }
    } else if (title === "environment") {
      updatedValues = selectedenvValues.includes(value)
        ? selectedenvValues.filter((item) => item !== value)
        : [...selectedenvValues, value];

      setSelectedenvValues(updatedValues);
      if (value === "all") {
        setSelectEnvironment(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (updatedValues.indexOf("all") !== -1) {
            setSelectEnvironment([...updatedValues.filter((x) => x !== "all")]);
          } else {
            setSelectEnvironment([...updatedValues]);
          }
        } else {
          const result = updatedValues.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectEnvironment(result_value);
        }
      }
    } else if (title === "application") {
      updatedValues = selectedappValues.includes(value)
        ? selectedappValues.filter((item) => item !== value)
        : [...selectedappValues, value];

      setSelectedappValues(updatedValues);
      if (value === "all") {
        setSelectApplication(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (updatedValues.indexOf("all") !== -1) {
            setSelectApplication([...updatedValues.filter((x) => x !== "all")]);
          } else {
            setSelectApplication([...updatedValues]);
          }
        } else {
          const result = updatedValues.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectApplication(result_value);
        }
      }
    } else if (title === "service") {
      updatedValues = selectedserviceValues.includes(value)
        ? selectedserviceValues.filter((item) => item !== value)
        : [...selectedserviceValues, value];

      setSelectedServiceValues(updatedValues);
      if (value === "all") {
        setSelectedServices(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (updatedValues.indexOf("all") !== -1) {
            setSelectedServices([...updatedValues.filter((x) => x !== "all")]);
          } else {
            setSelectedServices([...updatedValues]);
          }
        } else {
          const result = updatedValues.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectedServices(result_value);
        }
      }
    } else if (title === "os") {
      updatedValues = selectedosValues.includes(value)
        ? selectedosValues.filter((item) => item !== value)
        : [...selectedosValues, value];

      setSelectedosValues(updatedValues);
      if (value === "all") {
        setSelectedOS(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (updatedValues.indexOf("all") !== -1) {
            setSelectedOS([...updatedValues.filter((x) => x !== "all")]);
          } else {
            setSelectedOS([...updatedValues]);
          }
        } else {
          const result = updatedValues.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectedOS(result_value);
        }
      }
    } else if (title === "db") {
      updatedValues = selecteddbValues.includes(value)
        ? selecteddbValues.filter((item) => item !== value)
        : [...selecteddbValues, value];

      setSelecteddbValues(updatedValues);
      if (value === "all") {
        setSelectedDB(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (updatedValues.indexOf("all") !== -1) {
            setSelectedDB([...updatedValues.filter((x) => x !== "all")]);
          } else {
            setSelectedDB([...updatedValues]);
          }
        } else {
          const result = updatedValues.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectedDB(result_value);
        }
      }
    } else if (title === "infra") {
      updatedValues = selectedinfraValues.includes(value)
        ? selectedinfraValues.filter((item) => item !== value)
        : [...selectedinfraValues, value];

      setSelectedinfraValues(updatedValues);
      if (value === "all") {
        setSelectedInfra(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (updatedValues.indexOf("all") !== -1) {
            setSelectedInfra([...updatedValues.filter((x) => x !== "all")]);
          } else {
            setSelectedInfra([...updatedValues]);
          }
        } else {
          const result = updatedValues.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectedInfra(result_value);
        }
      }
    }
    setDataChanges(!datachanges);
  };
  const handleallclick = (value) => {
    if (value === "selectAll-account") {
      setSelectedaccValues(accountlist);
    } else if (value === "clearAll-account") {
      setSelectedaccValues(["all"]);
      dispatch(selectedAccountMethod(["all"]));
    } else if (value === "selectAll-cloud") {
      setSelectedcloudValues(cloudlist);
    } else if (value === "clearAll-cloud") {
      setSelectedcloudValues(["all"]);
      dispatch(selectedCloudMethod(["all"]));
    } else if (value === "selectAll-environment") {
      setSelectedenvValues(environmentlist);
    } else if (value === "clearAll-environment") {
      setSelectedenvValues(["all"]);
      dispatch(selectedEnvironmentMethod(["all"]));
    } else if (value === "selectAll-application") {
      setSelectedappValues(applicationlist);
    } else if (value === "clearAll-application") {
      setSelectedappValues(["all"]);
      dispatch(selectedApplicationMethod(["all"]));
    } else if (value === "selectAll-service") {
      setSelectedServiceValues(serviceslist);
    } else if (value === "clearAll-service") {
      setSelectedServiceValues(["all"]);
      dispatch(selectedServicesMethod(["all"]));
    } else if (value === "selectAll-db") {
      setSelecteddbValues(dblist);
    } else if (value === "clearAll-db") {
      setSelecteddbValues(["all"]);
      dispatch(selectedDbMethod(["all"]));
    } else if (value === "selectAll-os") {
      setSelectedosValues(oslist);
    } else if (value === "clearAll-os") {
      dispatch(selectedOSMethod(["all"]));
      setSelectedosValues(["all"]);
    } else if (value === "selectAll-infra") {
      setSelectedinfraValues(infralist);
    } else if (value === "clearAll-infra") {
      setSelectedinfraValues(["all"]);
      dispatch(selectedInfraMethod(["all"]));
    }
  };
  const filteredAcc = accountlist.filter((item) =>
    item.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const filteredCloud = cloudlist.filter((item) =>
    item.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const filteredEnv = environmentlist.filter((item) =>
    item.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const filteredApplication = applicationlist.filter((item) =>
    item.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const filteredService = serviceslist.filter((item) =>
    item?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );
  const filtereddb = dblist.filter((item) =>
    item.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const filteredos = oslist.filter((item) =>
    item.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const filteredinfra = infralist.filter((item) =>
    item.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const getDynamicMenu = (handleClick, title, data) => {
    return (
      <Menu onClick={handleClick}>
        <div className="d-flex justify-content-between fliter-text-size">
          <Menu.Item
            key={`selectAll-${title}`}
            onClick={() => handleallclick(`selectAll-${title}`)}
          >
            <div className="fliter-text-size">Select All</div>
          </Menu.Item>
          <Menu.Item
            key={`clearAll-${title}`}
            onClick={() => handleallclick(`clearAll-${title}`)}
          >
            <div className="fliter-text-size">Clear All</div>
          </Menu.Item>
        </div>
        <div className="px-2">
          <Input
            placeholder={`Search ${title}`}
            className="filter-search-input fliter-text-size"
            prefix={<Icon icon="material-symbols:search" />}
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
        </div>
        {data.map((item) => (
          <Menu.Item key={item}>
            <Checkbox
              checked={
                title === "account"
                  ? selectedaccValues.includes(item)
                  : title === "cloud"
                  ? selectedcloudValues.includes(item)
                  : title === "environment"
                  ? selectedenvValues.includes(item)
                  : title === "application"
                  ? selectedappValues.includes(item)
                  : title === "service"
                  ? selectedserviceValues.includes(item)
                  : title === "os"
                  ? selectedosValues.includes(item)
                  : title === "db"
                  ? selecteddbValues.includes(item)
                  : title === "infra"
                  ? selectedinfraValues.includes(item)
                  : false
              }
              onChange={(e) =>
                handleCheckboxChange(title, item, e.target.checked)
              }
            >
              {title === "cloud" && (
                <>
                  {item.toLowerCase() === "aws" ? (
                    <>
                      <span>
                        <img src={awsicon} />
                      </span>
                      <span className="costdemaze-filter-list-value fliter-text-size">
                        {" "}
                        AWS
                      </span>
                    </>
                  ) : item.toLowerCase() === "azure" ? (
                    <>
                      <span>
                        <Icon icon="vscode-icons:file-type-azure" />
                      </span>
                      <span className="costdemaze-filter-list-value fliter-text-size">
                        {" "}
                        Azure
                      </span>
                    </>
                  ) : item.toLowerCase() === "gcp" ? (
                    <>
                      <span>
                        <Icon icon="skill-icons:gcp-light" />
                      </span>
                      <span className="costdemaze-filter-list-value fliter-text-size">
                        {" "}
                        GCP
                      </span>
                    </>
                  ) : item.toLowerCase() === "oracle" ? (
                    <>
                      <span>
                        {" "}
                        <Icon
                          icon="simple-icons:oracle"
                          style={{ color: "#FF0000" }}
                        />{" "}
                      </span>
                      <span className="costdemaze-filter-list-value fliter-text-size">
                        {" "}
                        Oracle
                      </span>
                    </>
                  ) : (
                    <>{item}</>
                  )}
                </>
              )}
              {title !== "cloud" && (
                <>
                  {item.length > 14 ? (
                    <Tooltip
                      title={
                        <span className="costdemaze-filter-list-value fliter-text-size">
                          {" "}
                          {item}
                        </span>
                      }
                    >
                      <span className="costdemaze-filter-list-value fliter-text-size">{`${item.slice(
                        0,
                        14
                      )}...`}</span>
                    </Tooltip>
                  ) : (
                    <span className="costdemaze-filter-list-value fliter-text-size">
                      {" "}
                      {item}
                    </span>
                  )}
                </>
              )}
            </Checkbox>
          </Menu.Item>
        ))}

        <Menu.Divider />
      </Menu>
    );
  };
  useEffect(() => {
    dispatch(selectedEnvironmentMethod(selectenvironment));
    dispatch(selectedAccountMethod(selectaccount));
    dispatch(selectedCloudMethod(selectcloud));
    dispatch(selectedApplicationMethod(selectapplication));
    dispatch(selectedServicesMethod(selectedservicessta));
    dispatch(selectedOSMethod(selectedossta));
    dispatch(selectedInfraMethod(selectedinfrasta));
    dispatch(selectedDbMethod(selecteddbsta));
  }, [datachanges]);
  const Clearallfilter = () => {
    dispatch(selectedEnvironmentMethod(["all"]));
    dispatch(selectedAccountMethod(["all"]));
    dispatch(selectedCloudMethod(["all"]));
    dispatch(selectedApplicationMethod(["all"]));
    dispatch(selectedServicesMethod(["all"]));
    dispatch(selectedOSMethod(["all"]));
    dispatch(selectedInfraMethod(["all"]));
    dispatch(selectedDbMethod(["all"]));
    setSelectedinfraValues(["all"]);
    setSelectedosValues(["all"]);
    setSelecteddbValues(["all"]);
    setSelectedServiceValues(["all"]);
    setSelectedappValues(["all"]);
    setSelectedenvValues(["all"]);
    setSelectedcloudValues(["all"]);
    setSelectedaccValues(["all"]);
  };
  return (
    <>
      <Row>
        <Col span={12} className="costdemaze-filter-title">
          Filters
        </Col>
        <Col
          span={12}
          className="costdemaze-filter-sub-title costdemaze-filter-end-value"
        >
          <a onClick={Clearallfilter}> Clear All</a>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col
          span={24}
          className="costdemaze-filter-sub-title costdemaze-filter-center-value"
        >
          Global Filters
        </Col>
      </Row>
      <hr />
      <Row>
        <Col span={24}>
          <Dropdown
            // overlay={getMenu()}
            overlay={getDynamicMenu(handleMenuClick, "account", filteredAcc)}
            visible={accountDropdownVisible}
            onVisibleChange={setAccountDropdownVisible}
            className="costdemaze-filter-dropdown"
          >
            <Button className="d-flex justify-content-between">
              <div handleChange={handleChange}>
                All Accounts ({selectedaccValues.length - 1})
              </div>
              <div>
                {accountDropdownVisible ? (
                  <Icon icon="iconamoon:arrow-up-2-light" />
                ) : (
                  <Icon icon="iconamoon:arrow-down-2-light" />
                )}
              </div>
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <Dropdown
            overlay={getDynamicMenu(handleMenuClick, "cloud", filteredCloud)}
            visible={clouddropdownVisible}
            onVisibleChange={setCloudDropdownVisible}
            className="costdemaze-filter-dropdown"
          >
            <Button className="d-flex justify-content-between">
              <div handleChange={handleChange}>
                All Clouds ({selectedcloudValues.length - 1})
              </div>
              <div>
                {clouddropdownVisible ? (
                  <Icon icon="iconamoon:arrow-up-2-light" />
                ) : (
                  <Icon icon="iconamoon:arrow-down-2-light" />
                )}
              </div>
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <Dropdown
            overlay={getDynamicMenu(
              handleMenuClick,
              "environment",
              filteredEnv
            )}
            visible={envdropdownVisible}
            onVisibleChange={setEnvDropdownVisible}
            className="costdemaze-filter-dropdown"
          >
            <Button className="d-flex justify-content-between">
              <div handleChange={handleChange}>
                All Environment ({selectedenvValues.length - 1})
              </div>
              <div>
                {envdropdownVisible ? (
                  <Icon icon="iconamoon:arrow-up-2-light" />
                ) : (
                  <Icon icon="iconamoon:arrow-down-2-light" />
                )}
              </div>
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <Dropdown
            overlay={getDynamicMenu(
              handleMenuClick,
              "application",
              filteredApplication
            )}
            visible={applicationdropdownVisible}
            onVisibleChange={setApplicationDropdownVisible}
            className="costdemaze-filter-dropdown"
          >
            <Button className="d-flex justify-content-between">
              <div handleChange={handleChange}>
                All Application ({selectedappValues.length - 1})
              </div>
              <div>
                {applicationdropdownVisible ? (
                  <Icon icon="iconamoon:arrow-up-2-light" />
                ) : (
                  <Icon icon="iconamoon:arrow-down-2-light" />
                )}
              </div>
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col
          span={24}
          className="costdemaze-filter-sub-title costdemaze-filter-center-value"
        >
          Context Filters
        </Col>
      </Row>
      <hr />
      <Row>
        <Col span={24}>
          <Dropdown
            overlay={getDynamicMenu(
              handleMenuClick,
              "service",
              filteredService
            )}
            visible={servicedropdownVisible}
            onVisibleChange={setServiceDropdownVisible}
            className="costdemaze-filter-dropdown"
            placement="bottomRight"
          >
            <Button className="d-flex justify-content-between">
              <div handleChange={handleChange}>
                All Serives (
                {selectedserviceValues.length - 1 < 0
                  ? 0
                  : selectedserviceValues.length - 1}
                )
              </div>
              <div>
                {servicedropdownVisible ? (
                  <Icon icon="iconamoon:arrow-up-2-light" />
                ) : (
                  <Icon icon="iconamoon:arrow-down-2-light" />
                )}
              </div>
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <Dropdown
            overlay={getDynamicMenu(handleMenuClick, "db", filtereddb)}
            visible={dbdropdownVisible}
            onVisibleChange={setdbDropdownVisible}
            className="costdemaze-filter-dropdown"
            placement="bottomRight"
          >
            <Button className="d-flex justify-content-between">
              <div handleChange={handleChange}>
                All DB (
                {selecteddbValues.length - 1 < 0
                  ? 0
                  : selecteddbValues.length - 1}
                )
              </div>
              <div>
                {dbdropdownVisible ? (
                  <Icon icon="iconamoon:arrow-up-2-light" />
                ) : (
                  <Icon icon="iconamoon:arrow-down-2-light" />
                )}
              </div>
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <Dropdown
            overlay={getDynamicMenu(handleMenuClick, "os", filteredos)}
            visible={osdropdownVisible}
            onVisibleChange={setosDropdownVisible}
            className="costdemaze-filter-dropdown"
            placement="bottomRight"
          >
            <Button className="d-flex justify-content-between">
              <div handleChange={handleChange}>
                All OS (
                {selectedosValues.length - 1 < 0
                  ? 0
                  : selectedosValues.length - 1}
                )
              </div>
              <div>
                {osdropdownVisible ? (
                  <Icon icon="iconamoon:arrow-up-2-light" />
                ) : (
                  <Icon icon="iconamoon:arrow-down-2-light" />
                )}
              </div>
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <Dropdown
            overlay={getDynamicMenu(handleMenuClick, "infra", filteredinfra)}
            visible={infradropdownVisible}
            onVisibleChange={setInfraDropdownVisible}
            className="costdemaze-filter-dropdown"
            placement="bottomRight"
          >
            <Button className="d-flex justify-content-between">
              <div handleChange={handleChange}>
                All Infra(
                {selectedinfraValues.length - 1 < 0
                  ? 0
                  : selectedinfraValues.length - 1}
                )
              </div>
              <div>
                {infradropdownVisible ? (
                  <Icon icon="iconamoon:arrow-up-2-light" />
                ) : (
                  <Icon icon="iconamoon:arrow-down-2-light" />
                )}
              </div>
            </Button>
          </Dropdown>
        </Col>
      </Row>
    </>
  );
};
export default CostdemazeFilter;
