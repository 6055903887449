import { Icon } from "@iconify/react";
import { JoinFull } from "@mui/icons-material";
import { Table } from "antd";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { CgDetailsMore } from "react-icons/cg";

const BudgetYearList = (props) => {
  const columns = [
    {
      title: "Year",
      dataIndex: "budget_year",
      key: "budget_year",
    },
    {
      title: "Jan",
      dataIndex: "budget_jan",
      key: "budget_jan",
      render: (record, index) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Feb",
      dataIndex: "budget_feb",
      key: "budget_feb",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Mar",
      dataIndex: "budget_mar",
      key: "budget_mar",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Apr",
      dataIndex: "budget_apr",
      key: "budget_apr",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "May",
      dataIndex: "budget_may",
      key: "budget_may",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Jun",
      dataIndex: "budget_jun",
      key: "budget_jun",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Jul",
      dataIndex: "budget_jul",
      key: "budget_jul",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Aug",
      dataIndex: "budget_aug",
      key: "budget_aug",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Sep",
      dataIndex: "budget_sept",
      key: "budget_sept",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Oct",
      dataIndex: "budget_oct",
      key: "budget_oct",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Nov",
      dataIndex: "budget_nov",
      key: "budget_nov",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Dec",
      dataIndex: "budget_dec",
      key: "budget_dec",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: "Annual Total",
      dataIndex: "annual_total",
      key: "annual_total",
      render: (record) => {
        const number = Number(record);
        return `$${number.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}`;
      },
    },
    {
      title: (
        <Dropdown className="plat-costimize-dropdown">
          <Dropdown.Toggle
            className="plat-three-dort plat-tap-menu plat-box-three-dort"
            id="dropdown-button-dark-example1"
            variant="secondary"
          >
            <Icon icon="ph:dots-three-bold" class="table-coloum-dots-icon" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="plat-box-menu-list more-details">
            <Dropdown.ItemText
              className="plat-dropdown-item-text"
              onClick={() => props.addYearDetails()}
            >
              <span className="plat-box-item-title list-expand-left">Add</span>
              <span className="plat-box-item-title list-expand-right">
                <Icon
                  icon="material-symbols:add-box-outline"
                  class="table-add-records-icon"
                />
              </span>
            </Dropdown.ItemText>
          </Dropdown.Menu>
        </Dropdown>
      ),
      dataIndex: "action",
      key: "action",
      // className: "table-edit-list",
      render: (record, index) => {
        // if(index.type === 'data'){
        const edit = (
          <Dropdown.ItemText
            className="plat-dropdown-item-text"
            onClick={() => props.editYearDetails(index)}
          >
            <span className="plat-box-item-title list-expand-left">Edit</span>
            <span className="plat-box-item-title list-expand-right">
              <Icon
                icon="material-symbols:edit-outline"
                class="table-add-records-icon"
              />
            </span>
          </Dropdown.ItemText>
        );

        return (
          <>
            <Dropdown className="plat-costimize-dropdown plat-box-costimize-dropdown">
              <Dropdown.Toggle
                className="plat-three-dort plat-tap-menu plat-box-three-dort"
                id="dropdown-button-dark-example1"
                variant="secondary"
              >
                <Icon
                  icon="ph:dots-three-bold"
                  class="plat-expandalt-icon-view"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="plat-box-menu-list more-details">
                {edit}
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
        // }
      },
    },
  ];

  const tableProps = {};

  return (
    <>
      {/* <div className='budget-table-list'> */}
      <div className="budget-allocated-table">
        <h1 className="plat-table-title">Annual Budget</h1>
        <div className="table-width">
          <div className="table-planned-actual">
            <Table
              {...tableProps}
              // className="budget-table"
              className="savings_new_table"
              columns={columns}
              dataSource={props.yearlistrecords}
              pagination={false}
              // rowClassName={(record, index) => {
              //     console.log(record);
              //     console.log(index);
              // }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BudgetYearList;
