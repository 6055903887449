import { Icon } from "@iconify/react";
import { Button, Card, Col, Row, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import "../migrate/migrate.css";

import {
  AverageFirst,
  AverageResolution,
  Incident,
  Ticketseverity,
  categorycount,
  getapplicationcount,
  getslagraph,
  getslmapplication,
  getslmtable,
  totalkubernetescount,
  memorycpucount,
  ecsmemorycpu,
  ecstotalcount,
} from "../action/graphql/a2crover/A2CReducer";

import A2CSidebar from "../a2c_rover/A2CSidebar";
import A2cFilter from "../a2c_rover/A2cFilter";
import ReactApexChart from "react-apexcharts";
import Chart from "react-google-charts";
const Slm = (props) => {
  const dispatch = useDispatch();
  const [slmtable, setSlmTable] = useState([]);
  const [severitychart, setseveritychart] = useState([]);
  const [slmApplicationData, setSlmApplicationData] = useState([]);
  const [slmcount, setSlmCount] = useState([]);
  const [categorycountdata, setCategoryCountData] = useState([]);
  const [graph, setGraph] = useState([]);
  const [resolution, setResolution] = useState([]);
  const [previousdifference, setPreviousDifference] = useState([]);
  const [ResponseAverage, setResponseAverage] = useState([]);
  const [ResponsePrevious, setResponsePrevious] = useState([]);
  const [previouschange, setpreviouschange] = useState([]);
  const [previousincident, setpreviousincident] = useState([]);
  const [previousservice, setpreviousservice] = useState([]);
  const [currentchange, setcurrentchange] = useState([]);
  const [currentincident, setcurrentincident] = useState([]);
  const [currentservice, setcurrentservice] = useState([]);
  const [incidentdifference, setincidentdifference] = useState([]);
  const [servicedifference, setservicedifference] = useState([]);
  const [changedifference, setchangedifference] = useState([]);
  const [formattedmonth, setformattedmonth] = useState([]);
  const [isShowSpinner, setIsShowSpinner] = useState(false);
  const [cpuuseddata, setCpuUsedData] = useState([]);
  const [memoryuseddata, setmemoryUsedData] = useState([]);
  const [totalcpudata, setTotalCpuData] = useState([]);
  const [totalfilesystemdata, setTotalFileSystemData] = useState([]);
  const [totalmemorydata, setTotalMemoryData] = useState([]);
  const [usedfilesystem, setUsedFileSystem] = useState([]);
  const [cpumetricdata, setCpuMetricData] = useState([]);
  const [memorymetricdata, setMemoryMetricData] = useState([]);
  const [transactiondata, setTransactionData] = useState([]);
  const [mmemorypercentage, setMemoryPercentage] = useState([]);
  const [cpupercentage, setCpuPercentage] = useState([]);
  const [filepercentage, setFilePercentage] = useState([]);
  const [ecscpuuseddata, setEcsCpuUsedData] = useState([]);
  const [ecsmemoryuseddata, setEcsmemoryUsedData] = useState([]);
  const [ecstotalcpudata, setEcsTotalCpuData] = useState([]);
  const [ecsecstotalmemorydata, setEcsTotalMemoryData] = useState([]);
  const [totalecsmemoryper, setTotalEcsMemoryPer] = useState([]);
  const [totalecscpuper, setTotalEcsCpuPer] = useState([]);
  const [ecscpumetricdata, setEcsCpuMetricData] = useState([]);
  const [ecsmemorymetricdata, setEcsMemoryMetricData] = useState([]);
  const [ecstransactiondata, setEcsTransaction] = useState([]);

  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const {
    selectedApplication,
    selectedAccount,
    selectedou,
    selectedsla,
    selectedcategory,
    selectedticketowner,
    selectedticketclosed,
    selectedTopdate,
  } = useSelector((store) => store.a2cRover);

  useEffect(() => {
    let avgResolution = 0;
    let avgResolution1 = 0;

    const input = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      category: selectedcategory.toString(),
      company: companyName,
      organiation_unit: selectedou.toString(),
      sla: selectedsla.toString(),
      ticker_owner: selectedticketowner.toString(),
      ticket_closed_by: selectedticketclosed.toString(),
      start_date: "2023-08-01",
      end_date: "2023-12-30 ",
    };

    dispatch(AverageResolution({ input }))
      .unwrap()
      .then(({ data }) => {
        const slmResolutionTime = data.slm_resolution_time.map((datas) => {
          return {
            month_year: datas.month_year,
            avg_resolution: datas.avg_resolution,
            difference_from_prev_month: datas.difference_from_prev_month,
          };
        });
        const result = [];
        for (let i = 1; i < slmResolutionTime.length; i++) {
          const obj = {
            avg_resolution: slmResolutionTime[i].avg_resolution,
            difference_from_prev_month:
              slmResolutionTime[i - 1].difference_from_prev_month,
          };
          result.push(obj);
        }

        if (result.length) {
          avgResolution = Math.abs(Math.round(result[1].avg_resolution));
          setResolution(avgResolution);

          avgResolution1 = Math.abs(
            Math.round(result[1].difference_from_prev_month)
          );
          setPreviousDifference(avgResolution1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedTopdate,
    selectedcategory,
    selectedou,
    selectedsla,
    selectedticketowner,
    selectedticketclosed,
  ]);

  useEffect(() => {
    const input = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      category: selectedcategory.toString(),
      company: companyName,
      organiation_unit: selectedou.toString(),
      sla: selectedsla.toString(),
      ticker_owner: selectedticketowner.toString(),
      ticket_closed_by: selectedticketclosed.toString(),
      start_date: "2023-08-01",
      end_date: "2023-12-30 ",
    };

    dispatch(getslagraph(input))
      .unwrap()
      .then(({ data }) => {
        const slmgraph = data.slm_sla_count.map((datas) => {
          return {
            application: datas.application,
            not_violated: datas.not_violated,
            resolution_time: datas.resolution_time,
            response_and_resolution_violation:
              datas.response_and_resolution_violation,
            response_violation: datas.response_violation,
          };
        });

        setGraph(slmgraph);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedTopdate,
    selectedcategory,
    selectedou,
    selectedsla,
    selectedticketowner,
    selectedticketclosed,
  ]);

  const data = [["From", "To", "Server"]];
  graph.forEach((entry) => {
    const application = entry.application;
    data.push([
      application,
      "Response Violation",
      parseInt(entry.response_violation),
    ]);
    data.push([
      application,
      "Resolution Time Violation",
      parseInt(entry.resolution_time),
    ]);
    data.push([
      application,
      "Response and Resolution Violation",
      parseInt(entry.response_and_resolution_violation),
    ]);
    data.push([application, "Not Violated", parseInt(entry.not_violated)]);
  });
  const options_sankey = {
    series: [
      {
        data: data,
      },
    ],
    sankey: {
      node: {
        label: {
          color: "white",
        },
      },
    },
    noData: {
      text: "No data available", // Message to display when there's no data
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#FFF",
        fontSize: "20px",
      },
    },
  };

  useEffect(() => {
    let avgResponse = 0;
    let avgResponse1 = 0;

    const input = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      category: selectedcategory.toString(),
      company: companyName,
      organiation_unit: selectedou.toString(),
      sla: selectedsla.toString(),
      ticker_owner: selectedticketowner.toString(),
      ticket_closed_by: selectedticketclosed.toString(),
      start_date: "2023-08-01",
      end_date: "2023-12-30 ",
    };

    dispatch(AverageFirst(input))
      .unwrap()
      .then(({ data }) => {
        const SlmResponseTime = data.slm_response_time.map((datas) => {
          return {
            month_year: datas.month_year,
            avg_response: datas.avg_response,
            difference_from_prev_month: datas.difference_from_prev_month,
          };
        });
        const ResponseResult = [];
        for (let i = 1; i < SlmResponseTime.length; i++) {
          const obj = {
            avg_response: SlmResponseTime[i].avg_response,
            difference_from_prev_month:
              SlmResponseTime[i - 1].difference_from_prev_month,
          };
          ResponseResult.push(obj);
        }

        if (ResponseResult.length) {
          avgResponse = Math.abs(Math.round(ResponseResult[1].avg_response));
          setResponseAverage(avgResponse);

          avgResponse1 = Math.abs(
            Math.round(ResponseResult[1].difference_from_prev_month)
          );
          setResponsePrevious(avgResponse1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedTopdate,
    selectedcategory,
    selectedou,
    selectedsla,
    selectedticketowner,
    selectedticketclosed,
  ]);

  useEffect(() => {
    let previouschangedata = 0;
    let previousincidentdata = 0;
    let previousservicedata = 0;
    let currentchangedata = 0;
    let currentincidentdata = 0;
    let currentservicedata = 0;
    let incidentdifference = 0;
    let servicedifference = 0;
    let changedifference = 0;
    let formattedDate = 0;

    const input = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      category: selectedcategory.toString(),
      company: companyName,
      organiation_unit: selectedou.toString(),
      sla: selectedsla.toString(),
      ticker_owner: selectedticketowner.toString(),
      ticket_closed_by: selectedticketclosed.toString(),
      start_date: "2023-08-01",
      end_date: "2023-12-30 ",
    };

    dispatch(Incident(input))
      .unwrap()
      .then(({ data }) => {
        const SlmIncident = data.slm_tile_1.map((datas) => {
          return {
            month_year: datas.month_year,
            incident: datas.incident,
            change_request: datas.change_request,
            service_request: datas.service_request,
            change_diff: datas.change_diff,
            incident_diff: datas.incident_diff,
            service_diff: datas.service_diff,
          };
        });
        const Incidentresult = [];
        for (let i = 0; i < SlmIncident.length; i++) {
          const currentData = SlmIncident[i];
          const previousData = SlmIncident[i - 1];

          if (currentData && previousData) {
            const obj = {
              incident: currentData.incident,
              service_request: currentData.service_request,
              change_request: currentData.change_request,
              month_year: currentData.month_year,
            };
            Incidentresult.push(obj);
          }
        }

        if (Incidentresult.length) {
          previouschangedata = Math.abs(
            Math.round(Incidentresult[0].change_request)
          );
          setpreviouschange(previouschangedata);

          previousincidentdata = Math.abs(
            Math.round(Incidentresult[0].incident)
          );
          setpreviousincident(previousincidentdata);

          previousservicedata = Math.abs(
            Math.round(Incidentresult[0].service_request)
          );
          setpreviousservice(previousservicedata);

          const inputDate = Incidentresult[0].month_year;
          const date = new Date(inputDate);
          const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];

          const month = monthNames[date.getMonth()];
          const year = date.getFullYear();

          const formattedDate = `${month} ${year}`;
          setformattedmonth(formattedDate);
        }

        const Incidentresult1 = [];
        for (let i = 2; i < SlmIncident.length; i++) {
          const currentData = SlmIncident[i];
          const previousData = SlmIncident[i - 1];

          if (currentData && previousData) {
            const obj = {
              change_diff: currentData.change_diff,
              incident_diff: currentData.incident_diff,
              service_diff: currentData.service_diff,
              incident: currentData.incident,
              service_request: currentData.service_request,
              change_request: currentData.change_request,
            };
            Incidentresult1.push(obj);
          }
        }

        if (Incidentresult1.length) {
          currentchangedata = Math.abs(
            Math.round(Incidentresult1[0].change_request)
          );
          setcurrentchange(currentchangedata);

          currentincidentdata = Math.abs(
            Math.round(Incidentresult1[0].incident)
          );
          setcurrentincident(currentincidentdata);

          currentservicedata = Math.abs(
            Math.round(Incidentresult1[0].service_request)
          );
          setcurrentservice(currentservicedata);

          incidentdifference = Math.abs(
            Math.round(Incidentresult1[0].incident_diff)
          );
          setincidentdifference(incidentdifference);

          servicedifference = Math.abs(
            Math.round(Incidentresult1[0].service_diff)
          );
          setservicedifference(servicedifference);

          changedifference = Math.abs(
            Math.round(Incidentresult1[0].change_diff)
          );
          setchangedifference(changedifference);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedTopdate,
    selectedcategory,
    selectedou,
    selectedsla,
    selectedticketowner,
    selectedticketclosed,
  ]);

  useEffect(() => {
    const input = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      category: selectedcategory.toString(),
      company: companyName,
      organiation_unit: selectedou.toString(),
      sla: selectedsla.toString(),
      ticker_owner: selectedticketowner.toString(),
      ticket_closed_by: selectedticketclosed.toString(),
      start_date: "2023-08-01",
      end_date: "2023-12-30 ",
    };

    dispatch(getslmapplication(input))
      .unwrap()
      .then(({ data }) => {
        const applicationcount = data.slm_application_count.map((datas) => {
          return {
            application: datas.application,
            app_count: datas.app_count,
          };
        });
        setSlmApplicationData(applicationcount);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedTopdate,
    selectedcategory,
    selectedou,
    selectedsla,
    selectedticketowner,
    selectedticketclosed,
  ]); 

  const labels_dc = slmApplicationData.map((item) => item.application);
  const value_dc = slmApplicationData.map((item) => item.app_count);
  const numericValues_dc = value_dc.map((value) => parseInt(value, 10));
  const series_dc = numericValues_dc;
  const options_application = {
    chart: {
      type: "donut",
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    dataLabels: {
      formatter: function (val) {
        // Remove the percentage symbol from the labels

        return val.toFixed(0);
      },
    },

    tooltip: {
      enabled: true,

      theme: "light",

      fillSeriesColor: false, // You can use 'light' or 'dark' theme
    },
    stroke: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "23px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#ff6a2f",
              offsetY: -5,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "",
              color: "#ff6a2f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },

    labels: labels_dc,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
  };

  options_application.legend.labels = {
    colors: "#FFFFFF",
  };

  useEffect(() => {
    const input = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      category: selectedcategory.toString(),
      company: companyName,
      organiation_unit: selectedou.toString(),
      sla: selectedsla.toString(),
      ticker_owner: selectedticketowner.toString(),
      ticket_closed_by: selectedticketclosed.toString(),
      start_date: "2023-08-01",
      end_date: "2023-12-30 ",
    };

    dispatch(getapplicationcount(input))
      .unwrap()
      .then(({ data }) => {
        const slmcountdata = data.slm_ticket_status_count.map((datas) => {
          return {
            status: datas.status,
            ticket_count: datas.ticket_count,
          };
        });

        setSlmCount(slmcountdata);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedTopdate,
    selectedcategory,
    selectedou,
    selectedsla,
    selectedticketowner,
    selectedticketclosed,
  ]);

  const labels_app = slmcount.map((item) => item.status);
  const value_app = slmcount.map((item) => item.ticket_count);
  const numericValues_app = value_app.map((value) => parseInt(value, 10));
  const series_app = numericValues_app;
  const options_count = {
    chart: {
      type: "donut",
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    dataLabels: {
      formatter: function (val) {
        return val.toFixed(0);
      },
    },

    tooltip: {
      enabled: true,

      theme: "light",

      fillSeriesColor: false,
    },

    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "23px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#ff6a2f",
              offsetY: -5,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "",
              color: "#ff6a2f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },

    labels: labels_app,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
        },
        noData: {
          text: "No Data",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#fff",
            fontSize: "20px",
            fontFamily: "Poppins",
          },
        },
      },
    ],
    stroke: {
      show: false,
    },
    legend: {
      position: "bottom",
    },
  };

  options_count.legend.labels = {
    colors: "#FFFFFF",
  };

  useEffect(() => {
    const input = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      category: selectedcategory.toString(),
      company: companyName,
      organiation_unit: selectedou.toString(),
      sla: selectedsla.toString(),
      ticker_owner: selectedticketowner.toString(),
      ticket_closed_by: selectedticketclosed.toString(),
      start_date: "2023-08-01",
      end_date: "2023-08-30",
    };

    dispatch(categorycount(input))
      .unwrap()
      .then(({ data }) => {
        const categorycount = data.slm_category_count.map((datas) => {
          // console.log("category_countt",datas)

          return {
            category: datas.category,
            month_year: datas.month_year,
            ticket_count: datas.ticket_count,
          };
        });

        setCategoryCountData(categorycount);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedTopdate,
    selectedcategory,
    selectedou,
    selectedsla,
    selectedticketowner,
    selectedticketclosed,
  ]);

  // console.log(slmcount,"slmmmcountt")

  useEffect(() => {
    const input = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      category: selectedcategory.toString(),
      company: companyName,
      organiation_unit: selectedou.toString(),
      sla: selectedsla.toString(),
      ticker_owner: selectedticketowner.toString(),
      ticket_closed_by: selectedticketclosed.toString(),
      start_date: "2023-08-01",
      end_date: "2023-12-30 ",
    };

    dispatch(Ticketseverity(input))
      .unwrap()
      .then(({ data }) => {
        const Tableslm = data.slm_ticket_severity_count.map((datas) => {
          return {
            severity: datas.severity,
            severity_count: datas.severity_count,
          };
        });
        setseveritychart(Tableslm);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedTopdate,
    selectedcategory,
    selectedou,
    selectedsla,
    selectedticketowner,
    selectedticketclosed,
  ]);

  useEffect(() => {
    const input = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      category: selectedcategory.toString(),
      company: companyName,
      organization_unit: selectedou.toString(),
      sla: selectedsla.toString(),
      ticker_owner: selectedticketowner.toString(),
      ticket_closed_by: selectedticketclosed.toString(),
      start_date: "2023-08-01",
      end_date: "2023-12-01",
    };

    dispatch(getslmtable(input))
      .unwrap()
      .then(({ data }) => {
        // console.log(data, "slmtabledatas");
        const Tableslm = data.slm_ticket_table.map((datas) => {
          return {
            application: datas.application,
            average_resolution: datas.avg_resolution_time,
            category: datas.category,
            closed_time: datas.closed_time,
            created_time: datas.created_time,
            first_response_time: datas.first_response_time,
            number_of_reopen: datas.number_of_reopen,
            resolution_time: datas.resolution_time,
            sla: datas.sla,
            status: datas.status,
            subject: datas.subject,
            ticket_closed_by: datas.ticket_closed_by,
            ticket_owner: datas.ticket_owner,
          };
        });
        setSlmTable(Tableslm);
      })
      .catch((err) => {});
  }, [
    selectedApplication,
    selectedAccount,
    selectedticketowner,
    selectedticketclosed,
    selectedou,
    selectedsla,
    selectedcategory,
  ]);

  useEffect(() => {
    const totalkube = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      company: companyName,
      organiation_unit: selectedou.toString(),
    };

    dispatch(totalkubernetescount({ totalkube }))
      .unwrap()
      .then(({ data }) => {
        const totalcount = data.slm_k8s_total_usage_count.map((datas) => {
          // setTotalKubertes(totalcount);
          return {
            cpu_used: datas.cpu_used,
            memory_used: datas.memory_used,
            total_cpu: datas.total_cpu,
            total_filesystem: datas.total_filesystem,
            total_memory: datas.total_memory,
            filesystem_used: datas.filesystem_used,
          };
        });

        if (totalcount.length > 0) {
          const cpuused = totalcount.map((item) => item.cpu_used);
          setCpuUsedData(cpuused);
          const memoryused = totalcount.map((item) => item.memory_used);
          setmemoryUsedData(memoryused);
          const totalcpu = totalcount.map((item) => item.total_cpu);
          setTotalCpuData(totalcpu);
          const totalfilesystem = totalcount.map(
            (item) => item.total_filesystem
          );
          setTotalFileSystemData(totalfilesystem);
          const totalmemory = totalcount.map((item) => item.total_memory);
          setTotalMemoryData(totalmemory);
          const usedfilesystemvalue = totalcount.map(
            (item) => item.filesystem_used
          );
          setUsedFileSystem(usedfilesystemvalue);

          // const parsecpuused = parseInt(cpuuseddata);
          // console.log(parsecpuused)
          const parsecpuused = parseFloat(cpuused);
          const parsetotcpu = parseFloat(totalcpu);
          const parsememoryused = parseFloat(memoryused);
          const parsememorytotused = parseFloat(totalmemory);
          const parsefileused = parseFloat(usedfilesystemvalue);
          const parsetotfile = parseFloat(totalfilesystem);

          const totalfilepercentage =
            (parsefileused / (parsetotfile * 1000)) * 100;
          setFilePercentage(Math.round(totalfilepercentage));

          const totalmemorypercentage =
            (parsememoryused / parsememorytotused) * 100;
          setMemoryPercentage(Math.round(totalmemorypercentage));

          const totalcpupercentage = (parsecpuused / parsetotcpu) * 100;
          // setCpuPercentage(Math.round(totalcpupercentage))
          setCpuPercentage(Math.round(totalcpupercentage));

          // const totalfilepercentage = usedfilesystem/ totalfilesystemdata * 100

          // console.log(totalfilepercentage)
        } else {
          console.log("no data found");
        }

        const totalmemory = {
          account_name: selectedAccount.toString(),
          application: selectedApplication.toString(),
          company: companyName,
          organiation_unit: selectedou.toString(),
          startdate: "2023-10-01",
        };

        dispatch(memorycpucount({ totalmemory }))
          .unwrap()
          .then(({ data }) => {

            const totalcount = data.slm_k8s_memory_cpu_metrics.map((datas) => {
              // console.log(datas, "cpumemory");

              return {
                cpu_metrics: datas.cpu_metrics,
                memory_metrics: datas.memory_metrics,
                transaction_date: datas.transaction_date,
              };
            });
            if (totalcount.length > 0) {
              // const cpumetrics = totalcount.map((item) => item.cpu_metrics);
              // setCpuMetricData(cpumetrics)

              const cpumetrics = totalcount.map((item) => item.cpu_metrics);
              const cpuvalues = cpumetrics.map((value) => parseInt(value, 10));
              setCpuMetricData(cpuvalues);

              const memorymetrics = totalcount.map(
                (item) => item.memory_metrics
              );
              const memoryvalues = memorymetrics.map((value) =>
                parseInt(value, 10)
              );
              setMemoryMetricData(memoryvalues);

              // const months = [
              //   'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
              //   'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
              // ];

              // const ecstransaction = totalcount.map((item) => {
              //   const date = new Date(item.transaction_date);
              //   const month = months[date.getMonth()];
              //   const day = date.getDate();
              //   const formattedDate = `${month}-${day.toString().padStart(2, '0')}`;
              //   return formattedDate;
              // });

              // setTransactionData(ecstransaction);
              const months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ];

              const ecstransaction = totalcount.map((item) => {
                const date = new Date(item.transaction_date);
                const month = months[date.getMonth()];
                const day = date.getDate();
                const formattedDate = `${month}-${day
                  .toString()
                  .padStart(2, "0")}`;
                return {
                  date: formattedDate,
                  originalDate: item.transaction_date,
                };
              });

              ecstransaction.sort(
                (a, b) => new Date(a.originalDate) - new Date(b.originalDate)
              );

              const sortedDates = ecstransaction.map((item) => item.date);

              setTransactionData(sortedDates);
            }
          });

        //ecs dispatch

        const ecsṭotal = {
          account_name: selectedAccount.toString(),
          application: selectedApplication.toString(),
          company: companyName,
          organiation_unit: selectedou.toString(),
        };

        dispatch(ecstotalcount({ ecsṭotal }))
          .unwrap()
          .then(({ data }) => {

            const ecstotalvalue = data.slm_ecs_total_usage_count.map(
              (datas) => {

                return {
                  cpu_units_used: datas.cpu_units_used,
                  memory_ecs_used: datas.memory_ecs_used,
                  total_cpu_units: datas.total_cpu_units,
                  total_memory_ecs: datas.total_memory_ecs,
                };
              }
            );

            if (ecstotalvalue.length > 0) {
              const ecscpuused = ecstotalvalue.map(
                (item) => item.cpu_units_used
              );
              setEcsCpuUsedData(ecscpuused);
              const ecsmemoryused = ecstotalvalue.map(
                (item) => item.memory_ecs_used
              );
              setEcsmemoryUsedData(ecsmemoryused);
              const ecstotalcpu = ecstotalvalue.map(
                (item) => item.total_cpu_units
              );
              setEcsTotalCpuData(ecstotalcpu);
              const ecstotalmemory = ecstotalvalue.map(
                (item) => item.total_memory_ecs
              );
              setEcsTotalMemoryData(ecstotalmemory);

              const parseecscpuused = parseFloat(ecscpuused);

              const parseecstotcpu = parseFloat(ecstotalcpu);

              const parseecsmemoryused = parseFloat(ecsmemoryused);

              const parseecstotmemory = parseFloat(ecstotalmemory);

              const totalecsmemorypercentage =
                (parseecsmemoryused / parseecstotmemory) * 100;
              setTotalEcsMemoryPer(Math.round(totalecsmemorypercentage));

              const totalecscpupercentage =
                (parseecscpuused / parseecstotcpu) * 100;
              setTotalEcsCpuPer(Math.round(totalecscpupercentage));
            }
          })
          .catch((error) => {
            console.error("An error occurred:", error);
          });

        const ecsmemorycpu1 = {
          account_name: selectedAccount.toString(),
          application: selectedApplication.toString(),
          company: companyName,
          organiation_unit: selectedou.toString(),
          startdate: "2023-10-01",
        };

        dispatch(ecsmemorycpu({ ecsmemorycpu1 }))
          .unwrap()
          .then(({ data }) => {
            const ecstotalcountval = data.slm_ecs_memory_cpu_metrics.map(
              (datas) => {
                return {
                  ecs_cpu_metrics: datas.ecs_cpu_metrics,
                  ecs_memory_metrics: datas.ecs_memory_metrics,
                  transaction_date: datas.transaction_date,
                };
              }
            );
            if (ecstotalcountval.length > 0) {
              const ecscpumetrics = ecstotalcountval.map(
                (item) => item.ecs_cpu_metrics
              );
              const ecscpuvalues = ecscpumetrics.map((value) =>
                parseInt(value, 10)
              );
              setEcsCpuMetricData(ecscpuvalues);

              const ecsmemorymetrics = ecstotalcountval.map(
                (item) => item.ecs_memory_metrics
              );
              const ecsmemoryvalues = ecsmemorymetrics.map((value) =>
                parseInt(value, 10)
              );
              setEcsMemoryMetricData(ecsmemoryvalues);

              const months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ];

              const ecstransaction = ecstotalcountval.map((item) => {
                const date = new Date(item.transaction_date);
                const month = months[date.getMonth()];
                const day = date.getDate();
                const formattedDate = `${month}-${day
                  .toString()
                  .padStart(2, "0")}`;
                return {
                  date: formattedDate,
                  originalDate: item.transaction_date,
                };
              });

              ecstransaction.sort(
                (a, b) => new Date(a.originalDate) - new Date(b.originalDate)
              );

              const sortedDates = ecstransaction.map((item) => item.date);

              setEcsTransaction(sortedDates);
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedAccount, selectedApplication, selectedou, selectedTopdate]);

  const label_chart = severitychart.map((item) => item.severity);
  const chartdata = severitychart.map((item) => item.severity_count);
  const numericValues_db = chartdata.map((value) => parseInt(value, 10));

  const options_db = {
    labels: label_chart,
    dataLabels: {
      formatter: function (val) {
        return val.toFixed(0);
      },
    },
    tooltip: {
      enabled: true,

      theme: "light",

      fillSeriesColor: false,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    stroke: {
      show: false,
    },
    legend: {
      show: true,
      position: "bottom",
      labels: {
        colors: ["#fff"],
      },
    },
  };

  options_db.legend.labels = {
    colors: "#FFFFFF",
  };

  const series_db = numericValues_db;
  const chartData_db = {
    labels: label_chart,
    series: series_db,
  };

  const lineseries = [
    {
      name: "Categories",
      data: categorycountdata.map((item) => item.ticket_count),
    },
  ];
  const categories = categorycountdata.map((item) => {
    const [year, month] = item.month_year.split("-");
    const monthName = new Date(year, month - 1).toLocaleString("en-US", {
      month: "long",
    });
    return monthName;
  });

  const lineoptions = {
    chart: {
      height: 350,
      type: "bar",
      zoom: {
        enabled: false,
      },
    },
    legend: {
      position: "bottom",
      labels: {
        colors: "#ffffff",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    yaxis: {
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    xaxis: {
      categories: categorycountdata.map((item) => item.category),
      labels: {
        style: {
          colors: "#FFFFFF",
          // fontSize: "10px",
        },
        show: true,
        // rotate: -60,
        // rotateAlways: true,
        trim: true,
      },
    },
  };

  lineoptions.legend.labels = {
    colors: "#FFFFFF",
  };

  const series_usagememory = [mmemorypercentage];
  // const series_usagememory = [57];

  const options_usageoptions = {
    chart: {
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#bdbdbd",
          strokeWidth: "97%",
          margin: 5,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "22px",
            color: "#ffffff",
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
      colors: ["#00b74a"],
    },
    labels: ["Average Results"],
  };

  const series_usagecpu = [cpupercentage];

  const options_usagecpu = {
    chart: {
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#bdbdbd",
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "22px",
            color: "#ffffff", // Set the color for the series value (white in this case)
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
      colors: ["#00b74a"],
    },
    labels: ["Average Results"],
  };

  const series_usagefile = [filepercentage];

  // const series_usagefile = [29];

  const options_usagefile = {
    chart: {
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#bdbdbd",
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "22px",
            color: "#ffffff",
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
      colors: ["#00b74a"],
    },
    labels: ["Average Results"],
  };

  const series_ecsmemory = [totalecsmemoryper];

  const options_ecsmemory = {
    chart: {
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#bdbdbd",
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "22px",
            color: "#ffffff",
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
      colors: ["#00b74a"],
    },
    labels: ["Average Results"],
  };

  const series_ecscpu = [totalecscpuper];

  const options_ecscpu = {
    chart: {
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#bdbdbd",
          strokeWidth: "97%",
          margin: 5,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "22px",
            color: "#ffffff",
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
      colors: ["#00b74a"],
    },
    labels: ["Average Results"],
  };

  const columns = [
    {
      title: "Application Name",
      dataIndex: "application",
      key: "application",
      align: "left",
      width: 180,
    },
    {
      title: "Ticket Owner",
      dataIndex: "ticket_owner",
      key: "ticket_owner: ",
      align: "left",
    },
    {
      title: "Ticket Closed By",
      dataIndex: "ticket_closed_by",
      key: "ticket_closed_by",
    },
    {
      title: "Created Date(Ticket)",
      dataIndex: "created_time",
      key: "created_time",
      align: "left",
    },
    {
      title: "Ticket Date Time",
      dataIndex: "closed_time",
      key: "closed_time",
      align: "left",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      align: "left",
    },
    {
      title: "SLA",
      dataIndex: "sla",
      key: "sla",
      align: "left",
      // width: "10%",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      align: "left",
      // width: 100,
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "left",
      width: 150,
    },
    {
      title: "Average Resolution(Mins)",
      dataIndex: "average_resolution",
      key: "average_resolution",
      align: "left",
      width: 250,
    },
    {
      title: "First Response (Mins)",
      dataIndex: "first_response_time",
      key: "first_response_time",
      align: "left",
    },
    {
      title: "Response (Mins)",
      dataIndex: "resolution_time",
      key: "resolution_time",
      align: "left",
      width: 180,
    },
    {
      title: "Number of Reopen",
      dataIndex: "number_of_reopen",
      key: "number_of_reopen",
      align: "left",
      width: 180,
    },
  ];
  const AbsoluteResolution = Math.abs(previousdifference - resolution);
  const AbsoluteResponse = Math.abs(ResponseAverage - ResponsePrevious);

  const options_podmemory = {
    chart: {
      height: 250,
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      categories: transactiondata,
      labels: {
        style: {
          colors: "#fff",
        },
      },
    },
    yaxis: [
      {
        title: {
          text: "GiB",
          style: {
            color: "#FFF",
            // fontSize: "12px",
            fontWeight: "400",
          },
        },
        labels: {
          style: {
            colors: "#FFF",
          },
        },
      },
    ],
  };

  const series_podmemory = [
    {
      data: memorymetricdata,
    },
  ];

  const options_cpuusage = {
    chart: {
      height: 250,
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },

    xaxis: {
      categories: transactiondata,
      labels: {
        style: {
          colors: "#fff",
        },
        // formatter: (value) => {
        //   const monthNamesShort = [
        //     "Jan",
        //     "Feb",
        //     "Mar",
        //     "Apr",
        //     "May",
        //     "Jun",
        //     "Jul",
        //     "Aug",
        //     "Sep",
        //     "Oct",
        //     "Nov",
        //     "Dec",
        //   ];
        //   const date = new Date(selectedTopdate);
        //   const day = date.getDate();
        //   const month = monthNamesShort[date.getMonth()];
        //   const current_month_text = `${value}/${month}`;
        //   return current_month_text;
        // },
      },
    },
    yaxis: [
      {
        title: {
          text: "Cores",
          style: {
            color: "#FFF",
            fontWeight: "400",
          },
        },
        labels: {
          style: {
            colors: "#FFF",
          },
        },
      },
    ],
  };

  const series_ecsmemory1 = [
    {
      data: ecsmemorymetricdata,
    },
  ];

  const options_ecsmemory1 = {
    chart: {
      height: 250,
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },

    xaxis: {
      categories: ecstransactiondata,
      labels: {
        style: {
          colors: "#fff",
        },
        // formatter: (value) => {
        //   const monthNamesShort = [
        //     "Jan",
        //     "Feb",
        //     "Mar",
        //     "Apr",
        //     "May",
        //     "Jun",
        //     "Jul",
        //     "Aug",
        //     "Sep",
        //     "Oct",
        //     "Nov",
        //     "Dec",
        //   ];
        //   const date = new Date(selectedTopdate);
        //   const day = date.getDate();
        //   const month = monthNamesShort[date.getMonth()];
        //   const current_month_text = `${value}/${month}`;
        //   return current_month_text;
        // },
      },
    },
    yaxis: [
      {
        title: {
          text: "GiB",
          style: {
            color: "#FFF",
            fontWeight: "400",
          },
        },
        labels: {
          style: {
            colors: "#FFF",
          },
        },
      },
    ],
  };

  const series_ecscpu1 = [
    {
      data: ecscpumetricdata,
    },
  ];

  const options_ecscpu1 = {
    chart: {
      height: 250,
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },

    xaxis: {
      categories: ecstransactiondata,
      labels: {
        style: {
          colors: "#fff",
        },
        // formatter: (value) => {
        //   const monthNamesShort = [
        //     "Jan",
        //     "Feb",
        //     "Mar",
        //     "Apr",
        //     "May",
        //     "Jun",
        //     "Jul",
        //     "Aug",
        //     "Sep",
        //     "Oct",
        //     "Nov",
        //     "Dec",
        //   ];
        //   const date = new Date(selectedTopdate);
        //   const day = date.getDate();
        //   const month = monthNamesShort[date.getMonth()];
        //   const current_month_text = `${value}/${month}`;
        //   return current_month_text;
        // },
      },
    },
    yaxis: [
      {
        title: {
          text: "Cores",
          style: {
            color: "#FFF",
            // fontSize: "12px",
            fontWeight: "400",
          },
        },
        labels: {
          style: {
            colors: "#FFF",
          },
        },
      },
    ],
  };

  const series_cpuusage = [
    {
      data: cpumetricdata,
    },
  ];
  const [pageSlm,setPageSlm] = useState(5);
  const handleChangePageSlm = (current, pageSlm) => {
  setPageSlm(pageSlm);}

  return (
    <>
      <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
        <A2CSidebar />
        <main className="position-main-div" style={{ padding: 10, flex: 1 }}>
          {" "}
          <div className="cams-content">
            <div className="container-fluid">
              <div className="header-filter d-flex justify-content-between">
                <div className="header-title-a2c-main">SLM</div>
                <span className="d-flex align-items-end">
                  <A2cFilter />
                </span>
              </div>

              <div className="tabss assets-perspective-tabs mt-4 slm-tabs-width">
                <div className="slm-a2crover">
                  <Row gutter={[16, 16]}>
                    <Col span={5}>
                      <Card className="operate-cards">
                        <div className="">
                          <div className="d-flex justify-content-center">
                            <span className="row-card-header ">
                              Average Resolution Time(Mins)
                            </span>
                          </div>
                          <Row justify="space-around" align="middle">
                            <Col>
                              <div className="light-header">Difference</div>
                              <div className="light-sub-header">
                                <span>
                                  {" "}
                                  {resolution > previousdifference ? (
                                    <Icon
                                      icon="typcn:arrow-up"
                                      style={{
                                        marginRight: "3px",
                                        color: "red",
                                      }}
                                    />
                                  ) : (
                                    <Icon
                                      icon="typcn:arrow-down"
                                      style={{
                                        marginRight: "3px",
                                        color: "green",
                                      }}
                                    />
                                  )}
                                  {AbsoluteResolution}
                                </span>
                              </div>
                            </Col>
                            <Col>
                              <h6 className="center-header">{resolution}</h6>
                            </Col>
                            <Col>
                              <div className="light-header">
                                {formattedmonth}
                              </div>
                              <div className="light-sub-header">
                                <span> {previousdifference}</span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                    <Col span={6}>
                      <Card className="operate-cards">
                        <div className="">
                          <div className="d-flex justify-content-center">
                            <span className="row-card-header ">
                              Average First Response Time(Mins)
                            </span>
                          </div>
                          <Row justify="space-around" align="middle">
                            <Col>
                              <div className="light-header">Difference</div>
                              <div className="light-sub-header">
                                <span>
                                  {ResponseAverage > ResponsePrevious ? (
                                    <Icon
                                      icon="typcn:arrow-up"
                                      style={{
                                        marginRight: "3px",
                                        color: "red",
                                      }}
                                    />
                                  ) : (
                                    <Icon
                                      icon="typcn:arrow-down"
                                      style={{
                                        marginRight: "3px",
                                        color: "green",
                                      }}
                                    />
                                  )}
                                  {AbsoluteResponse}
                                </span>
                              </div>
                            </Col>
                            <Col>
                              <h6 className="center-header">
                                {ResponseAverage}
                              </h6>
                            </Col>
                            <Col>
                              <div className="light-header">
                                {formattedmonth}
                              </div>
                              <div className="light-sub-header">
                                <span>{ResponsePrevious}</span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                    <Col span={4}>
                      <Card className="operate-cards">
                        <div className="">
                          <div className="d-flex justify-content-center">
                            <span className="row-card-header ">Incidents</span>
                          </div>
                          <Row justify="space-around" align="middle">
                            <Col>
                              <div className="light-header">Difference</div>
                              <div className="light-sub-header">
                                <span>
                                  {" "}
                                  {currentincident > incidentdifference ? (
                                    <Icon
                                      icon="typcn:arrow-up"
                                      style={{
                                        marginRight: "3px",
                                        color: "red",
                                      }}
                                    />
                                  ) : (
                                    <Icon
                                      icon="typcn:arrow-down"
                                      style={{
                                        marginRight: "3px",
                                        color: "green",
                                      }}
                                    />
                                  )}
                                  {incidentdifference}
                                </span>
                              </div>
                            </Col>
                            <Col>
                              <h6 className="center-header">
                                {currentincident}
                              </h6>
                            </Col>
                            <Col>
                              <div className="light-header">
                                {formattedmonth}
                              </div>
                              <div className="light-sub-header">
                                <span>{previousincident}</span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                    <Col span={4}>
                      <Card className="operate-cards">
                        <div className="">
                          <div className="d-flex justify-content-center">
                            <span className="row-card-header ">
                              Service Request
                            </span>
                          </div>
                          <Row justify="space-around" align="middle">
                            <Col>
                              <div className="light-header">Difference</div>
                              <div className="light-sub-header">
                                <span>
                                  {currentservice > previousservice ? (
                                    <Icon
                                      icon="typcn:arrow-up"
                                      style={{
                                        marginRight: "3px",
                                        color: "red",
                                      }}
                                    />
                                  ) : (
                                    <Icon
                                      icon="typcn:arrow-down"
                                      style={{
                                        marginRight: "3px",
                                        color: "green",
                                      }}
                                    />
                                  )}
                                  {servicedifference}
                                </span>
                              </div>
                            </Col>
                            <Col>
                              <h6 className="center-header">
                                {" "}
                                {currentservice}
                              </h6>
                            </Col>
                            <Col>
                              <div className="light-header">
                                {formattedmonth}
                              </div>
                              <div className="light-sub-header">
                                <span>{previousservice}</span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                    <Col span={5}>
                      <Card className="operate-cards">
                        <div className="">
                          <div className="d-flex justify-content-center">
                            <span className="row-card-header ">
                              Change Request
                            </span>
                          </div>
                          <Row justify="space-around" align="middle">
                            <Col>
                              <div className="light-header">Difference</div>
                              <div className="light-sub-header">
                                <span>
                                  {" "}
                                  {currentchange > previouschange ? (
                                    <Icon
                                      icon="typcn:arrow-up"
                                      style={{
                                        marginRight: "3px",
                                        color: "red",
                                      }}
                                    />
                                  ) : (
                                    <Icon
                                      icon="typcn:arrow-down"
                                      style={{
                                        marginRight: "3px",
                                        color: "green",
                                      }}
                                    />
                                  )}
                                  {changedifference}
                                </span>
                              </div>
                            </Col>
                            <Col>
                              <h6 className="center-header">{currentchange}</h6>
                            </Col>
                            <Col>
                              <div className="light-header">
                                {formattedmonth}
                              </div>
                              <div className="light-sub-header">
                                <span>{previouschange}</span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} justify={"center"} align={"middle"}>
                    <Col span={10}>
                      <Card
                        title="Count Of Tickets by Category (Ticket)"
                        className="operate-cards "
                      >
                        <div className="donut-chart">
                          <ReactApexChart
                            options={lineoptions}
                            series={lineseries}
                            type="bar"
                          />
                        </div>
                      </Card>
                    </Col>
                    <Col span={7}>
                      <Card
                        title="Count Of (%) Tickets by Application"
                        className="operate-cards"
                      >
                        <div className="donut-chart">
                          <ReactApexChart
                            options={options_application}
                            series={series_dc}
                            type="donut"
                            height={340}
                          />
                        </div>
                      </Card>
                    </Col>
                    <Col span={7}>
                      <Card
                        title="Count Of (%) Tickets by Status (Ticket)"
                        className="operate-cards"
                      >
                        <div className="donut-chart">
                          <ReactApexChart
                            options={options_count}
                            series={series_app}
                            type="donut"
                            height={340}
                          />
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} justify={"center"} align={"middle"}>
                    <Col span={16}>
                      <Card
                        title="SLA- Tickets by Application"
                        className="operate-cards"
                      >
                        <div className="donut-chart">
                          <Chart
                            chartType="Sankey"
                            width="100%"
                            height="300px"
                            data={data}
                            options={options_sankey}
                          />
                        </div>
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card
                        title="Count Of (%) Tickets by Severity"
                        className="operate-cards"
                      >
                        <div className="donut-chart">
                          <ReactApexChart
                            options={options_db}
                            series={chartData_db.series}
                            type="pie"
                            height={340}
                          />
                        </div>
                      </Card>
                    </Col>
                  </Row>

                  {/* <Row gutter={[16, 16]}> */}
                  <div className="">
                    <Table
                      className="slm-table "
                      columns={columns}
                      dataSource={slmtable}
                      loading={{
                        indicator: <Spin />,
                        spinning: isShowSpinner,
                      }}
                      pagination={{
                                  pageSize: pageSlm,
                                  showSizeChanger: true,
                                  onShowSizeChange: handleChangePageSlm,
                                  }}
                                  showSizeChanger={true}
                                  scroll={{ x: 2500 }}
                      // pagination={{ pageSize: 10 }}
                    />
                  </div>
                  {/* </Row> */}
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Card className="operate-cards">
                        <h5 className=" kubernetes-header p-0 pt-3">
                          K8S Engine
                        </h5>
                      </Card>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} justify={"center"} align={"middle"}>
                    <Col span={8}>
                      <Row>
                        <Card
                          title="Cluster Memory Usage"
                          className="operate-cards "
                        >
                          <ReactApexChart
                            options={options_usageoptions}
                            series={series_usagememory}
                            type="radialBar"
                            height={200}
                          />
                        </Card>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Card title="Used" className="operate-cards ">
                            <span className="counts"> {memoryuseddata}</span>
                          </Card>
                        </Col>
                        <Col span={12}>
                          <Card title="Total" className="operate-cards ">
                            <span className="counts"> {totalmemorydata}</span>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={8}>
                      <Row>
                        <Card
                          title="Cluster CPU Usage (1d avg)"
                          className="operate-cards"
                        >
                          <ReactApexChart
                            options={options_usagecpu}
                            series={series_usagecpu}
                            type="radialBar"
                            height={200}
                          />

                          {/* <div className="mixed-chart">
                            <div className="semi-donut-chart">
                              <ReactApexChart
                                options={semiDonutOptions}
                                series={semiDonutSeries}
                                type="donut"
                              />
                            </div>
                            <div className="radial-bar-chart">
                              <ReactApexChart
                                options={radialOptions}
                                series={radialSeries}
                                type="radialBar"
                              />
                            </div>
                          </div> */}
                        </Card>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Card title="Used" className="operate-cards ">
                            <span className="counts"> {cpuuseddata}</span>
                          </Card>
                        </Col>
                        <Col span={12}>
                          <Card title="Total" className="operate-cards ">
                            <span className="counts"> {totalcpudata}</span>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={8}>
                      <Row>
                        <Card
                          title="Cluster Filesystem Usage"
                          className="operate-cards"
                        >
                          {/* <div className="mixed-chart">
                            <div className="semi-donut-chart">
                              <ReactApexChart
                                options={semiDonutOptions}
                                series={semiDonutSeries}
                                type="donut"
                              />
                            </div>
                            <div className="radial-bar-chart"> */}
                          <ReactApexChart
                            options={options_usagefile}
                            series={series_usagefile}
                            type="radialBar"
                            height={200}
                          />
                          {/* </div>
                          </div> 
                           */}
                          {/* <ReactApexChart
                              options={options}
                              series={series}
                              type="radialBar"
                            />
                           */}
                        </Card>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Card title="Used" className="operate-cards ">
                            <span className="counts"> {usedfilesystem}</span>
                          </Card>
                        </Col>
                        <Col span={12}>
                          <Card title="Total" className="operate-cards ">
                            <span className="counts">
                              {" "}
                              {totalfilesystemdata}
                            </span>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {/* <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Card className="operate-cards">
                        <h5 className=" kubernetes-header p-0 pt-3">Memory</h5>
                      </Card>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col span={24}>
                      <Card title="Pod Memory Usage" className="operate-cards">
                        <ReactApexChart
                          options={options_podmemory}
                          series={series_podmemory}
                          type="area"
                          height={250}
                        />
                      </Card>
                    </Col>
                  </Row>
                  {/* <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Card className="operate-cards">
                        <h5 className=" kubernetes-header p-0 pt-3">CPU</h5>
                      </Card>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col span={24}>
                      <Card title="Pod CPU Usage" className="operate-cards">
                        <ReactApexChart
                          options={options_cpuusage}
                          series={series_cpuusage}
                          type="area"
                          height={250}
                        />
                      </Card>
                    </Col>
                  </Row>

                  {/* * //new ecs */}

                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Card className="operate-cards">
                        <h5 className=" kubernetes-header p-0 pt-3">
                          ECS Engine
                        </h5>
                      </Card>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} justify={"center"} align={"middle"}>
                    <Col span={12}>
                      <Row>
                        <Card
                          title="Cluster Memory Usage"
                          className="operate-cards "
                        >
                          <ReactApexChart
                            options={options_ecsmemory}
                            series={series_ecsmemory}
                            type="radialBar"
                            height={200}
                          />
                        </Card>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Card title="Used" className="operate-cards ">
                            <span className="counts1">
                              {" "}
                              {ecsmemoryuseddata}
                            </span>
                          </Card>
                        </Col>
                        <Col span={12}>
                          <Card title="Total" className="operate-cards ">
                            <span className="counts1">
                              {" "}
                              {ecsecstotalmemorydata}
                            </span>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Card
                          title="Cluster CPU Usage (1d avg)"
                          className="operate-cards"
                        >
                          <ReactApexChart
                            options={options_ecscpu}
                            series={series_ecscpu}
                            type="radialBar"
                            height={200}
                          />
                        </Card>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Card title="Used" className="operate-cards ">
                            <span className="counts1"> {ecscpuuseddata}</span>
                          </Card>
                        </Col>
                        <Col span={12}>
                          <Card title="Total" className="operate-cards ">
                            <span className="counts1"> {ecstotalcpudata}</span>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {/* <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Card className="operate-cards">
                        <h5 className=" kubernetes-header p-0 pt-3">Memory</h5>
                      </Card>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col span={24}>
                      <Card
                        title="ECS Memory Usage(%)"
                        className="operate-cards"
                      >
                        <ReactApexChart
                          options={options_ecsmemory1}
                          series={series_ecsmemory1}
                          type="area"
                          height={200}
                        />
                      </Card>
                    </Col>
                  </Row>
                  {/* <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Card className="operate-cards">
                        <h5 className=" kubernetes-header p-0 pt-3">CPU</h5>
                      </Card>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col span={24}>
                      <Card title="ECS CPU Usage(%)" className="operate-cards">
                        <ReactApexChart
                          options={options_ecscpu1}
                          series={series_ecscpu1}
                          type="area"
                          height={200}
                        />
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Slm;
