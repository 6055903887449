import { Tabs } from "antd";
import React from "react";
import CustomerIdentifierHome from "./CustomerIdentifier/CustomerIdentifierHome";
import EventIdentifierHome from "./EventIdentifier/EventIdentifierHome";
import RuleIdentifierHome from "./RuleIdentifier/RuleIdentifierHome";

function Identifier() {
  const items = [
    {
      key: "1",
      label: "Customer Identifier",
      children: <CustomerIdentifierHome />,
    },
    {
      key: "2",
      label: "Event Identifier",
      children: <EventIdentifierHome />,
    },
    {
      key: "3",
      label: "Rule Identifier",
      children: <RuleIdentifierHome />,
    },
  ];
  return (
    <>
      <div className="container peems-main-section  mt-3">
        <Tabs className="cams-tabs" defaultActiveKey="1" items={items} />
      </div>
    </>
  );
}

export default Identifier;
