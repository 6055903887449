import React from "react";
import { Card, Progress } from "antd";
import ReactApexChart from "react-apexcharts";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Modal,
} from "antd";
function Plangraphs() {
  const chartOptions = {
    chart: {
      type: "treemap",
    },
    series: [
        {
          data: [
            {
              x: '',
              y: 218
            },
            {
              x: '',
              y: 149
            },
            {
              x: '',
              y: 184
            },
            {
              x: '',
              y: 55
            },
            {
              x: '',
              y: 84
            },
            {
              x: '',
              y: 31
            },
            {
              x: '',
              y: 70
            },
            {
              x: '',
              y: 30
            },
            {
              x: '',
              y: 44
            },
            {
              x: '',
              y: 68
            },
            {
              x: '',
              y: 28
            },
            {
              x: '',
              y: 19
            },
            {
              x: '',
              y: 29
            }
          ]
        }
      ],};

  const columns = [
    {
      title: "Source Resource Name",
      dataIndex: "SourceResourceName",
      key: "SourceResourceName",
    },
    {
      title: "Instance Type",
      dataIndex: "InstanceType",
      key: "InstanceType",
    },
    {
      title: "Right Sized Core",
      dataIndex: "RightSizedCore",
      key: "RightSizedCore",
    },
    {
      title: "Right Sized RAM",
      dataIndex: "RightSizedRam",
      key: "RightSizedRam",
    },
  ];
  const data=[
    {
        key:'1',
        SourceResourceName:'Domino Server',
        InstanceType:'D1_V2',
        RightSizedCore:'4',
        RightSizedRam:'3'


    },
    {
        key:'2',
        SourceResourceName:'Domino Server',
        InstanceType:'D1_V2',
        RightSizedCore:'4',
        RightSizedRam:'3'

    },
    {
        key:'3',
        SourceResourceName:'Domino Server',
        InstanceType:'D1_V2',
        RightSizedCore:'4',
        RightSizedRam:'3'
    },
    {
        key:'4',
        SourceResourceName:'Domino Server',
        InstanceType:'D1_V2',
        RightSizedCore:'4',
        RightSizedRam:'3'
    },
    {
        key:'5',
        SourceResourceName:'Domino Server',
        InstanceType:'D1_V2',
        RightSizedCore:'4',
        RightSizedRam:'3'
    },
    {
        key:'6',
        SourceResourceName:'Domino Server',
        InstanceType:'D1_V2',
        RightSizedCore:'4',
        RightSizedRam:'3'
    },
    {
        key:'7',
        SourceResourceName:'Domino Server',
        InstanceType:'D1_V2',
        RightSizedCore:'4',
        RightSizedRam:'3'
    },
    {
        key:'8',
        SourceResourceName:'Domino Server',
        InstanceType:'D1_V2',
        RightSizedCore:'4',
        RightSizedRam:'3'
    },
    {
        key:'9',
        SourceResourceName:'Domino Server',
        InstanceType:'D1_V2',
        RightSizedCore:'4',
        RightSizedRam:'3'
    },
    {
        key:'10',
        SourceResourceName:'Domino Server',
        InstanceType:'D1_V2',
        RightSizedCore:'4',
        RightSizedRam:'3'
    },
    {
        key:'11',
        SourceResourceName:'Domino Server',
        InstanceType:'D1_V2',
        RightSizedCore:'4',
        RightSizedRam:'3'
    },
    {
        key:'12',
        SourceResourceName:'Domino Server',
        InstanceType:'D1_V2',
        RightSizedCore:'4',
        RightSizedRam:'3'
    }



  ]
  return (
    <div>
      <div className="cards6">
        <Row gutter={16}>
          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            <Card bordered={false} className="cd1">
              <div className="savingstab">
                <Table columns={columns} dataSource={data} pagination={false}></Table>
              </div>
            </Card>
          </Col>

          <Col xxl={6} xl={6} lg={6} md={24} sm={24} xs={24}>
            <Card title=" To-Be Core" bordered={false} className="cd">
              <ReactApexChart
                options={chartOptions}
                series={chartOptions.series}
                type="treemap"
                height={520}
              />
            </Card>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={24} sm={24} xs={24}>
            <Card title="To-Be RAM" bordered={false} className="cd">
            <ReactApexChart
                options={chartOptions}
                series={chartOptions.series}
                type="treemap"
                height={520}
              />
            </Card>
          </Col>
        </Row>
      </div>
      <h6 className="head6"> TCO Analysis</h6>
    </div>
  );
}

export default Plangraphs;
