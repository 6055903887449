import React from "react";
import ProfileInfo from "../../admin/useronboard/ProfileInfo";
import CompanyInfo from "../../admin/useronboard/CompanyInfo";
import OfferingInfo from "../../admin/useronboard/OfferingInfo";
import { Button, message, Steps, theme, Row, Col } from "antd";
import { useState } from "react";
import BasicDetails from "./basicDetails/BasicDetails";
import ConfigureDetails from "./configureDetails/ConfigureDetails";
import PreviewForm from "./previewForm/PreviewForm";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

function ConfigurationForm() {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const navigate = useNavigate();
  const steps = [
    {
      title: "Basic Details",
      content: <BasicDetails setCurrent={setCurrent} current={current} />,
    },
    {
      title: "Configuration",
      content: <ConfigureDetails setCurrent={setCurrent} current={current} />,
    },
    {
      title: "Preview",
      content: <PreviewForm setCurrent={setCurrent} current={current} />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };
  return (
    <>
      <>
        <Row>
          <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}></Col>
          <Col
            xxl={20}
            xl={20}
            lg={20}
            md={20}
            sm={20}
            xs={20}
            className="mt-5"
          >
            <div className="d-flex align-items-center">
              <div className="arrow-back">
                <Icon
                  icon="bi:arrow-left"
                  style={{ fontSize: "25px", cursor: "pointer" }}
                  onClick={() => navigate("/devops-requestor")}
                />
              </div>
              <div style={{ flex: 1, textAlign: "center" }}>
                <h4 className="header-title-devops">
                  Welcome to Self Service Portal
                </h4>
              </div>
            </div>
            <br />
            <div className="form-cover-step center-text">
              <Steps
                type="navigation"
                size="small"
                className="customer-form-step devops-form-steps"
                current={current}
                items={items}
              />
            </div>
            <div className=" ">
              <div>{steps[current].content}</div>
            </div>
          </Col>
          <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}></Col>
        </Row>
      </>
    </>
  );
}

export default ConfigurationForm;
