// features/user/userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userRole: "",
  requestAction: "addNew",
  saveBasicDetailsData: {
    basicDetailsInfo: {
      account_name: "",
      activity_type: "",
      cloud_platform: "",
      operation: "",
      service_name: "",
    },
    accountInfo: {},
    configDetailsInfo: {},
  },
  configuration: [{ configurations: {} }],
  approvalTableData: [],
  requestorTableData: {},
  saveBasicAction: false,
  configuration_records: {},
  jobName: "",
  formFieldDetails: [
    {
      header: "",
      field_names: [
        {
          name: "",
          field_type: "",
          showDropDown: false,
          cloud_platform: "",
          field_value_string: "",
          optionsList: [],
        },
      ],
    },
  ],
  isFormBackButtonClicked: false,
};

const devopsSlice = createSlice({
  name: "devops_slice",
  initialState,
  reducers: {
    saveBasicDetails: (state, { payload }) => {
      state.saveBasicDetailsData.basicDetailsInfo = payload;
    },
    getApprovalTableData: (state, { payload }) => {
      state.approvalTableData = payload;
    },
    getConfigurationData: (state, { payload }) => {
      state.configuration = payload;
    },
    setConfigurationFormData: (state, { payload }) => {
      state.saveBasicDetailsData.configDetailsInfo = payload;
    },
    setAccountInfo: (state, { payload }) => {
      state.saveBasicDetailsData.accountInfo = payload;
    },
    setRequestAction: (state, { payload }) => {
      state.requestAction = payload;
    },
    setRequestorTableData: (state, { payload }) => {
      state.requestorTableData = payload;
    },
    setUserRole: (state, { payload }) => {
      state.userRole = payload;
    },
    setSaveBasicAction: (state, { payload }) => {
      state.saveBasicAction = payload;
    },
    setConfigurationRecords: (state, { payload }) => {
      state.configuration_records = payload;
    },
    setJobName: (state, { payload }) => {
      state.jobName = payload;
    },
    setFormFielDetailsData: (state, { payload }) => {
      state.formFieldDetails = payload;
    },
    setbackButtonClickAction: (state, { payload }) => {
      state.isFormBackButtonClicked = payload;
    },
    reset: () => initialState,
  },
});

export default devopsSlice.reducer;
export const {
  saveBasicDetails,
  getApprovalTableData,
  getConfigurationData,
  setConfigurationFormData,
  setAccountInfo,
  setRequestAction,
  setRequestorTableData,
  setUserRole,
  setSaveBasicAction,
  setConfigurationRecords,
  setFormFielDetailsData,
  setJobName,
  setbackButtonClickAction,
  reset,
} = devopsSlice.actions;
