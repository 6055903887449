import { memo, useState } from "react";
import { Col, Row, Modal, Tooltip, Spin, Card } from "antd";
import { Icon } from "@iconify/react";
import { saveAs } from "file-saver";
import TaggingUploadPopup from "./TaggingUploadPopup";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPaginationCount,
  getTagginglist,
} from "../../action/costimizeV2Active";
import TaggingPopupUp from "./TaggingPopupUp";
import TaggingPopupDown from "./TaggingPopupDown";

const TaggingPopup = (props) => {
  const [preloader, setPreLoader] = useState(false);
  const [mandatorytagstatue, setMandatoryTagStatus] = useState(true);
  const [automatedtagstatue, setAutomateTagStatus] = useState(true);
  const [nocosttagstatue, setNoCostTagStatus] = useState(true);
  const [taggingrecords, setTaggingRecords] = useState({
    mandatory_tag: [],
    automated_tag: [],
    no_cost_impact_tag: [],
  });

  useEffect(() => {
    // console.log(props.untaggedresources.list);
    var mandatory_tag = props.untaggedresources.list?.filter(
      (v, index) => v.tag_category === "Mandatory-tag"
    );
    var automated_tag = props.untaggedresources.list?.filter(
      (v, index) => v.tag_category === "Automated-tag"
    );
    var no_cost_impact_tag = props.untaggedresources.list?.filter(
      (v, index) => v.tag_category === "No-cost-impact-tags"
    );
    setTaggingRecords({
      mandatory_tag: mandatory_tag,
      automated_tag: automated_tag,
      no_cost_impact_tag: no_cost_impact_tag,
    });
  }, [props]);
  const handleCancel = () => {
    props.setModelOpen(false);
  };

  const mandatoryTagOpen = () => {
    setMandatoryTagStatus(false);
    setAutomateTagStatus(true);
    setNoCostTagStatus(true);
  };
  const mandatoryTagClose = () => {
    setMandatoryTagStatus(true);
  };
  const automatedTagOpen = () => {
    setMandatoryTagStatus(true);
    setAutomateTagStatus(false);
    setNoCostTagStatus(true);
  };
  const automatedTagClose = () => {
    setAutomateTagStatus(true);
  };
  const noCostTagOpen = () => {
    setMandatoryTagStatus(true);
    setAutomateTagStatus(true);
    setNoCostTagStatus(false);
  };
  const noCostTagClose = () => {
    setNoCostTagStatus(true);
  };
  return (
    <>
      <Modal
        className="costmodal"
        title="Tagging"
        open={props.modelopen}
        onCancel={handleCancel}
        footer={false}
        // style={{ textAlign: "center", padding: "20px", background:"#000" }}
      >
        <Spin spinning={preloader} wrapperClassName="costimize-loader">
          <Row className="mt-5" gutter={[16, 16]}>
            <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24}>
              <lable className="tagging-pop-ul-title">Services</lable>
            </Col>
            <Col
              xxl={6}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
              className="text-right"
            >
              <lable className="tagging-pop-ul-title">Tagged</lable>
            </Col>
            <Col
              xxl={6}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
              className="d-flex justify-content-end align-items-center"
            >
              <lable className="tagging-pop-ul-title">Partially Tagged</lable>
            </Col>
            <Col
              xxl={6}
              xl={6}
              lg={6}
              md={6}
              sm={24}
              xs={24}
              className="text-right"
            >
              <lable className="tagging-pop-ul-title">Untagged</lable>
            </Col>
          </Row>
          <div style={{ width: "100%" }} className="mt-3">
            {/* {mandatorytagstatue ? (
              <div>
                <TaggingPopupUp
                  tagOpen={mandatoryTagOpen}
                  records={taggingrecords.mandatory_tag}
                  title={"Mandatory Tag"}
                  category={"Mandatory-tag"}
                />
              </div>
            ) : (
              <div>
                <TaggingPopupDown
                  tagClose={mandatoryTagClose}
                  records={taggingrecords.mandatory_tag}
                  category={"Mandatory-tag"}
                  title={"Mandatory Tag"}
                />
              </div>
            )} */}

            <div>
              <TaggingPopupDown
                tagClose={mandatoryTagClose}
                records={taggingrecords.mandatory_tag}
                category={"Mandatory-tag"}
                title={"Mandatory Tag"}
              />
            </div>
          </div>
          {/* <div style={{ width: "100%" }} className="mt-3">
            {automatedtagstatue ? (
              <div>
                <TaggingPopupUp
                  tagOpen={automatedTagOpen}
                  records={taggingrecords.automated_tag}
                  title={"Automated Tag"}
                  category={"Automated-tag"}
                />
              </div>
            ) : (
              <div>
                <TaggingPopupDown
                  tagClose={automatedTagClose}
                  records={taggingrecords.automated_tag}
                  category={"Automated-tag"}
                  title={"Automated Tag"}
                />
              </div>
            )}
          </div> */}
          {/* <div style={{ width: "100%" }} className="mt-3">
            {nocosttagstatue ? (
              <div>
                <TaggingPopupUp
                  tagOpen={noCostTagOpen}
                  records={taggingrecords.no_cost_impact_tag}
                  title={"No Cost Impact Tag"}
                  category={"No-cost-impact-tags"}
                />
              </div>
            ) : (
              <div>
                <TaggingPopupDown
                  tagClose={noCostTagClose}
                  records={taggingrecords.no_cost_impact_tag}
                  category={"No-cost-impact-tags"}
                  title={"No Cost Impact Tag"}
                />
              </div>
            )}
          </div> */}
        </Spin>
      </Modal>
    </>
  );
};

export default memo(TaggingPopup);
