import { Button, Card, Select, Tabs } from "antd";
import React from "react";
import OperateAssetsPerspective from "./OperateAssetsPerspective";
import OperateAnalysisPerspective from "./AnalysisPerspective";
import "./operate.css";
import A2cFilter from "../a2c_rover/A2cFilter";
import A2CSidebar from "../a2c_rover/A2CSidebar";
import { Icon } from "@iconify/react";
function Operateindex() {
  const items = [
    {
      key: "1",
      label: `Assets Perspective`,
      children: <OperateAssetsPerspective />,
    },
    {
      key: "2",
      label: `Analysis Perspective`,
      children: <OperateAnalysisPerspective />,
    },
  ];
  return (
    <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
      <A2CSidebar />
      <main className="position-main-div" style={{ padding: 10, flex: 1 }}>
        {" "}
        <div className="cams-content">
          <div className="container-fluid">
            <div className="header-filter d-flex justify-content-between">
              <div className="header-title-a2c-main">Operate</div>
              <span className="d-flex align-items-end">
                <A2cFilter />
              </span>
            </div>

            <div className="tabss assets-perspective-tabs">
              <div>
                <div className="add-button-position">
                  {/* <Button className="add-btn">
                    <Icon icon="material-symbols:add-circle-outline" />
                    New
                  </Button> */}
                </div>
                <Tabs
                  className="cams-tabs"
                  defaultActiveKey="1"
                  items={items}
                  //   onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Operateindex;
