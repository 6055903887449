import React from "react";
import ProductionCard from "./ProductionCard";
import NonProductionCard from "./Non_prodCard";
import { Row, Col } from "antd";
import { useEffect } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { listcompliance, listPlannedCompliance,slm_get_status} from "../../../action/graphql/slm/SLM_reducers";


const PatchManagements = (props) => {
  
  const tablerecords = props.tablerecords;
  const [chartdetails, setChartdetails] = useState([]);
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [selectedMonth, setSelectedMonth] = useState(selectedTopdate);
  const [prodlist, setprodlist] = useState([])
  const [nonprodlist, setnonprodlist] = useState([])
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [refresh, setRefresh] = useState(false);
  const[plannedtable,setPlannedTable] = useState([]);
  const[completedtable,setCompletedtable] = useState([]);


  useEffect(() => {
    const date = selectedTopdate;


      dispatch(listPlannedCompliance({ companyName, date }))
      .unwrap()
      .then(({ data }) => {

        const PlannedTicket = data.slm_compliance_get_planned.map((datas) => {


          return {
            Environment: datas.environment,
            date: datas.month,
            Application: datas.application,
            Patch_detail: datas.request_description,
            Status: datas.ticket_status,
          };
        }); 
        setPlannedTable(PlannedTicket)
        refreshData();
      })
      .catch((err) => {
        console.log(err.message);
      });


  }, [selectedTopdate]);

  useEffect(() => {
    const date = selectedTopdate;
   
  dispatch(slm_get_status({ companyName, date }))
  .unwrap()

  .then(({ data }) => {

    const TicketStatus = data.slm_compliance_status.map((datas) => {

      return {
        Environment: datas.environment,
        date: datas.month,
        Application: datas.application,
        Patch_detail: datas.request_description,
        Status: datas.ticket_status,
      };
    }); 
    setCompletedtable(TicketStatus)
    refreshData();
  })
  .catch((err) => {
    console.log(err.message);
  });


  }, [selectedTopdate]);

  
  
  const columns = [
    {
      title: 'Environment',
      dataIndex: 'Environment',
      key: 'Environment'
    },
    {
      title: 'Application',
      dataIndex: 'Application',
      key: 'Application'
    },

    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
  
  },
   {
      title: 'Approval Status',
      dataIndex: 'Status',
      key: 'Status'
    },


  ]
  const completedcolumn = [
    {
      title: 'Environment',
      dataIndex: 'Environment',
      key: 'Environment'
    },
    {
      title: 'Application',
      dataIndex: 'Application',
      key: 'Application'
    },
    {
      title: 'Patch detail',
      dataIndex: 'Patch_detail',
      key: 'Patch_detail'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
  
  },
   {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status'
    },
  ]


  useEffect(() => {
    const patchRecords = tablerecords.filter(
      (record) =>
        record.environment.toLowerCase() === "production"

    );
    setprodlist(patchRecords);
    const patchRecords2 = tablerecords.filter(
      (record) =>
        record.environment.toLowerCase() === "non-production"

    );
    setnonprodlist(patchRecords2);
  }, [tablerecords])

  const getChartData = () => {
    const date = selectedMonth;
    dispatch(listcompliance({ companyName, selectedTopdate }))
      .unwrap()
      .then(({ data }) => {

        const countsos = data.newfunctionquery28.map((datas) => {
          return {
            company: datas.company,
            environment: datas.environment,
            month: datas.month,
            os: datas.os,
            patch_id: datas.patch_id,
            patch_status: datas.patch_status,
          };
        });
        setChartdetails(countsos);

      });
  };

  const refreshData = () => {


    setRefresh(true);
    getChartData();
  };

  const date = new Date(selectedTopdate);
 const nameOfMonth=date.toLocaleString('default', { month: 'long' })


  useEffect(() => {
    getChartData();
    // const date = selectedMonth;
    // dispatch(listcompliance({ companyName, date }))
    //   .unwrap()
    //   .then(({ data }) => {
    //     const countsos = data.newfunctionquery28.map((datas) => {
    //       return {
    //         company: datas.company,
    //         environment: datas.environment,
    //         month: datas.month,
    //         os: datas.os,
    //         patch_id: datas.patch_id,
    //         patch_status: datas.patch_status,
    //       };
    //     });
    //     setChartdetails(countsos);
    //     console.log(countsos);
    //   });
  }, [props.updatestatus, selectedMonth, selectedTopdate]);

  useEffect(() => { }, [refresh, chartdetails]);
  return (
    <>
      <Row>
        <Col xxl={12} xl={11} lg={11} md={12} sm={24} xs={24} className="m-2">
          <ProductionCard countsos={chartdetails} refreshData={refreshData} prodlist={prodlist} />
        </Col>
        <Col xxl={1} xl={1} lg={1} md={1} sm={24} xs={24}></Col>
        <Col xxl={12} xl={11} lg={11} md={10} sm={24} xs={24} className="m-2">
          <NonProductionCard
            countsos={chartdetails}
            refreshData={refreshData}
            nonprodlist={nonprodlist}
          />
        </Col>

      </Row>
      <div><h6 className= 'mt-5' style={{ color: 'white' }}>Performed during {""} 
      {nameOfMonth} 2023</h6></div>

      <div className="initial_response_timetable">
        <Table
          className="slm mt-3"
          style={{ overflow: "auto", height: "19rem" }}
          columns={completedcolumn}
          dataSource={completedtable} 
          pagination={true}
        />
      </div>

     <div><h6 className= 'mt-5' style={{ color: 'white' }}>Planned during {""} 
      {nameOfMonth} 2023</h6></div>


      <div className="initial_response_timetable">
        <Table
          className="slm mt-3"
          style={{ overflow: "auto", height: "19rem" }}
          columns={columns}
          dataSource={plannedtable} 
          pagination={true}
        />
      </div>

    </>
  );
};

export default PatchManagements;