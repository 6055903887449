import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ConfigHeaderPrefix } from "../../../../assets/icons/configuration_header_prefix.svg";
import {
  getDropDownValues,
  getOSOffer,
  getOSSKU,
  listRequestTable,
  listRequestTableforAll,
  saveUpdateConfigureForm,
} from "../../../action/graphql/devops/DevOpsAction";
import {
  getApprovalTableData,
  setConfigurationFormData,
  setbackButtonClickAction,
} from "../../../slice/devopsSlice";
import { loginUser } from "../../../action/userAction";

function ConfigureDetails(props) {
  const navigateFn = useNavigate();
  const [isShowAWS, setIsShowAWS] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const devopsStore = useSelector((store) => store.devops);
  const { configuration } = devopsStore;

  const [accountDetailsInfo, setAccountDetailsInfo] = useState(
    devopsStore.saveBasicDetailsData.accountInfo
  );
  const [basicDetailsInfo, setBasicDetailInfo] = useState(
    devopsStore.saveBasicDetailsData.basicDetailsInfo
  );
  const [accountConfig, setAccountConfig] = useState(
    devopsStore.configuration_records
  );
  // console.log(devopsStore.configuration_records);
  let parsedValue = JSON.parse(accountConfig);
  const [response, setResponse] = useState(parsedValue);
  const [ishowSpinner, setIsShowSpinner] = useState(false);
  const [formFieldDetails, setFormFieldDetails] = useState([
    {
      header: "",
      field_names: [
        {
          name: "",
          field_type: "",
          showDropDown: false,
          cloud_platform: "",
          field_value_string: "",
          optionsList: [],
          osOffer: [],
        },
      ],
    },
  ]);

  const [created_by_user, setCreatedByUser] = useState("");
  const getCreatedByUser = () => {
    // to get createdby user
    let user_id = localStorage.getItem("CognitoIdentityServiceProvider.auth");
    let token = localStorage.getItem("_token");
    let created_user;
    const records = {
      user_id: user_id,
      _token: token,
    };
    dispatch(loginUser(records))
      .unwrap()
      .then(({ data }) => {
        created_user = data.getUsers.email_id;
        setCreatedByUser(created_user);
      });
  };
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    let cloud_type =
      devopsStore.saveBasicDetailsData.basicDetailsInfo.cloud_platform;
    getFormFields(response, cloud_type);
    getCreatedByUser();
  }, []);
  const [dropDownAPIData, setdropDownAPIData] = useState([]);
  const objDropDownValues = (obj) => {
    let str = obj.field_type.split(/([^@!$]+)/g);
    obj.field_type = str[1];
    if (str[0] === "@") {
      obj.showDropDown = true;
      obj.showMultiSelect = false;
      let fieldType = str[1];
      let cloud_platform =
        devopsStore.saveBasicDetailsData.basicDetailsInfo.cloud_platform;

      dispatch(getDropDownValues({ cloud_platform, fieldType }))
        .unwrap()
        .then(({ data }) => {
          let tempArray = [];
          let arryJson = [];
          setdropDownAPIData(data);
          if (data.devops_config_mapping.length > 0) {
            arryJson = JSON.parse(data.devops_config_mapping[0].value_list);
            arryJson = arryJson.slice(1, -1).split('","');
            arryJson = arryJson.map((region, index) => ({
              label: region,
              value: region,
            }));
          }

          obj.optionsList = arryJson;
        });
    } else if (str[0] === "$") {
      obj.showDropDown = false;
      obj.showMultiSelect = true;
      let fieldType = str[1];
      let cloud_platform =
        devopsStore.saveBasicDetailsData.basicDetailsInfo.cloud_platform;

      dispatch(getDropDownValues({ cloud_platform, fieldType }))
        .unwrap()
        .then(({ data }) => {
          let tempArray = [];
          let arryJson = [];
          setdropDownAPIData(data);
          if (data.devops_config_mapping.length > 0) {
            arryJson = JSON.parse(data.devops_config_mapping[0].value_list);
            arryJson = arryJson.slice(1, -1).split('","');
            arryJson = arryJson.map((region, index) => ({
              label: region,
              value: region,
            }));
          }
          obj.optionsList = arryJson;
        });
    } else {
      obj.showMultiSelect = false;
      obj.showDropDown = false;
      obj.optionsList = [];
    }
    return obj;
  };

  const getFormFields = (response, cloud_type) => {
    // console.log(response);

    var tempArray = [];
    var formFieldType = "";

    if (response) {
      Object.entries(JSON.parse(response)).map((key, index) => {
        let obj_names = {};
        obj_names["header"] = key[0];
        if (Array.isArray(key[1])) {
          // for array
          key[1].map((arrayitems) => {
            let temparr_one = [];
            let obj_small_arr = [];
            Object.values(arrayitems).map((obj_item, _index) => {
              var label_name = {};
              label_name.cloud_platform =
                devopsStore.saveBasicDetailsData.basicDetailsInfo.cloud_platform;
              if (Array.isArray(obj_item)) {
                obj_item.map((obj_array) => {
                  Object.values(obj_array).map((obj_array_item, _index) => {
                    label_name.name = obj_array_item.label_name;
                    label_name.field_type = obj_array_item.field_value_type;
                  });
                });
              } else if (obj_item.label_name) {
                label_name.name = obj_item.label_name;
                label_name.field_type = obj_item.field_value_type;
              } else {
                Object.values(obj_item).map((obj_array_item, _index) => {
                  if (Array.isArray(obj_array_item)) {
                    obj_array_item.map((obj_array) => {
                      Object.values(obj_array).map((obj_array_item, _index) => {
                        var __obj = {};
                        __obj.name = obj_array_item.label_name;
                        __obj.field_type = obj_array_item.field_value_type;

                        // label_name.name = obj_array_item.label_name;
                        // label_name.field_type = obj_array_item.field_value_type;
                        const obj_val = objDropDownValues(__obj);
                        temparr_one.push(obj_val);
                        obj_small_arr.push(__obj);
                      });
                    });
                  } else {
                    label_name.name = obj_array_item.label_name;
                    label_name.field_type = obj_array_item.field_value_type;
                  }
                });
              }
              //to get dropdown
              const obj_val = objDropDownValues(label_name);

              temparr_one.push(label_name);
            });
            obj_names["field_names"] = temparr_one;
          });
        }

        // else {
        //   // for object
        //   console.log("its an object", key[1]);

        //   Object.values(key[1]).map((obj_item, _index) => {
        //     var label_name = {};

        //     console.log("obj_item,", obj_item);
        //     if (Array.isArray(obj_item)) {
        //       Object.values(obj_item).map((obj_array_item, _index) => {
        //         Object.values(obj_array_item).map((obj_array_item, _index) => {
        //           label_name.name = obj_array_item.label_name;
        //           label_name.field_type = obj_array_item.field_value_type;
        //         });
        //       });
        //       temparr_one.push(label_name);
        //     } else if (obj_item.label_name) {
        //       label_name.name = obj_item.label_name;
        //       label_name.field_type = obj_item.field_value_type;
        //       temparr_one.push(label_name);
        //     } else {
        //       console.log("inside else", obj_item);
        //       Object.values(obj_item).map((obj_array_item, _index) => {
        //         console.log(obj_array_item);
        //         label_name.name = obj_array_item.label_name;
        //         label_name.field_type = obj_array_item.field_value_type;
        //       });
        //       temparr_one.push(label_name);
        //     }
        //     console.log(label_name);
        //   });
        // }

        tempArray.push(obj_names);
        setFormFieldDetails(tempArray);
        setIsShowSpinner(false);
      });
    }
    // if (response) {
    //   if (cloud_type === "Azure") {
    //     Object.entries(JSON.parse(response)).map((key, index) => {
    //       let obj_names = {};
    //       obj_names["header"] = key[0];
    //       key[1].map((item1) => {
    //         let temparr_one = [];
    //         Object.values(item1).map((itm2, _index) => {
    //           var label_name = {};
    //           label_name.name = itm2.label_name;
    //           label_name.field_type = itm2.field_value_type;
    //           label_name.cloud_platform =
    //             devopsStore.saveBasicDetailsData.basicDetailsInfo.cloud_platform;

    //           //to get dropdown
    //           let str = itm2.field_value_type.split(/([^@!]+)/g);
    //           label_name.field_value_string = str[1];
    //           if (str[0] === "@") {
    //             label_name.showDropDown = true;
    //             let fieldType = str[1];
    //             let cloud_platform =
    //               devopsStore.saveBasicDetailsData.basicDetailsInfo
    //                 .cloud_platform;

    //             dispatch(getDropDownValues({ cloud_platform, fieldType }))
    //               .unwrap()
    //               .then(({ data }) => {
    //                 let tempArray = [];
    //                 let arryJson = [];
    //                 setdropDownAPIData(data);
    //                 if (data.devops_config_mapping.length > 0) {
    //                   arryJson = JSON.parse(
    //                     data.devops_config_mapping[0].value_list
    //                   );
    //                   arryJson = arryJson.slice(1, -1).split('","');
    //                   arryJson = arryJson.map((region, index) => ({
    //                     label: region,
    //                     value: region,
    //                   }));
    //                 }

    //                 label_name.optionsList = arryJson;
    //               });
    //           } else {
    //             label_name.showDropDown = false;
    //             label_name.optionsList = [];
    //             formFieldType = "showinput";
    //           }
    //           temparr_one.push(label_name);
    //         });
    //         obj_names["field_names"] = temparr_one;
    //       });
    //       tempArray.push(obj_names);
    //       setFormFieldDetails(tempArray);
    //       setIsShowSpinner(false);
    //     });
    //   } else if (cloud_type === "AWS") {
    //     console.log(basicDetailsInfo);
    //     let operation_type = basicDetailsInfo.operation;
    //     Object.entries(JSON.parse(response)).map((key, index) => {
    //       // Object.entries(response).map((key, index) => {
    //       let obj_names = {};
    //       obj_names["header"] = key[0];
    //       console.log("key", Array.isArray(key[1]));
    //       if (Array.isArray(key[1])) {
    //         key[1].map((item1) => {
    //           let temparr_one = [];
    //           Object.values(item1).map((itm2, _index) => {
    //             console.log(typeof itm2.label_name);
    //             var label_name = {};
    //             if (Array.isArray(itm2)) {
    //               console.log("yes its array", itm2);
    //             } else if (typeof itm2.label_name == "undefined") {
    //               console.log("yes it has object");
    //             } else {
    //               label_name.name = itm2.label_name;
    //               label_name.field_type = itm2.field_value_type;
    //               label_name.cloud_platform =
    //                 devopsStore.saveBasicDetailsData.basicDetailsInfo.cloud_platform;

    //               //to get dropdown
    //               let str = itm2.field_value_type.split(/([^@!]+)/g);
    //               label_name.field_value_string = str[1];
    //               if (str[0] === "@") {
    //                 label_name.showDropDown = true;
    //                 let fieldType = str[1];
    //                 let cloud_platform =
    //                   devopsStore.saveBasicDetailsData.basicDetailsInfo
    //                     .cloud_platform;

    //                 dispatch(getDropDownValues({ cloud_platform, fieldType }))
    //                   .unwrap()
    //                   .then(({ data }) => {
    //                     let tempArray = [];
    //                     let arryJson = [];
    //                     setdropDownAPIData(data);
    //                     if (data.devops_config_mapping.length > 0) {
    //                       arryJson = JSON.parse(
    //                         data.devops_config_mapping[0].value_list
    //                       );
    //                       arryJson = arryJson.slice(1, -1).split('","');
    //                       arryJson = arryJson.map((region, index) => ({
    //                         label: region,
    //                         value: region,
    //                       }));
    //                     }

    //                     label_name.optionsList = arryJson;
    //                   });
    //               } else {
    //                 label_name.showDropDown = false;
    //                 label_name.optionsList = [];
    //                 formFieldType = "showinput";
    //               }
    //               temparr_one.push(label_name);
    //             }
    //           });
    //           obj_names["field_names"] = temparr_one;
    //         });
    //       } else {
    //         let temparr_one = [];
    //         console.log(key[1]);
    //         Object.values(key[1]).map((itm2, _index) => {
    //           var label_name = {};
    //           console.log("elem >> ", itm2);
    //           label_name.name = itm2.label_name;
    //           label_name.field_type = itm2.field_value_type;
    //           label_name.cloud_platform =
    //             devopsStore.saveBasicDetailsData.basicDetailsInfo.cloud_platform;

    //           //to get dropdown
    //           let str = itm2.field_value_type.split(/([^@!]+)/g);
    //           label_name.field_value_string = str[1];
    //           if (str[0] === "@") {
    //             label_name.showDropDown = true;
    //             let fieldType = str[1];
    //             let cloud_platform =
    //               devopsStore.saveBasicDetailsData.basicDetailsInfo
    //                 .cloud_platform;

    //             dispatch(getDropDownValues({ cloud_platform, fieldType }))
    //               .unwrap()
    //               .then(({ data }) => {
    //                 let tempArray = [];
    //                 let arryJson = [];
    //                 setdropDownAPIData(data);
    //                 if (data.devops_config_mapping.length > 0) {
    //                   arryJson = JSON.parse(
    //                     data.devops_config_mapping[0].value_list
    //                   );
    //                   arryJson = arryJson.slice(1, -1).split('","');
    //                   arryJson = arryJson.map((region, index) => ({
    //                     label: region,
    //                     value: region,
    //                   }));
    //                 }

    //                 label_name.optionsList = arryJson;
    //               });
    //           } else {
    //             label_name.showDropDown = false;
    //             label_name.optionsList = [];
    //             formFieldType = "showinput";
    //           }
    //           temparr_one.push(label_name);
    //         });
    //       }

    //       tempArray.push(obj_names);

    //       setFormFieldDetails(tempArray);
    //       setIsShowSpinner(false);
    //     });
    //   }
    // }
    return true;
  };

  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [isShowButtonLoader, setIsShowButtonLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const userRole = localStorage.getItem(
    "CognitoIdentityServiceProvider.offering"
  );

  const getPricingDetails = (
    basicDetailsInfo,
    accountDetailsInfo,
    values,
    accountConfig,
    jobName
  ) => {
    dispatch(
      saveUpdateConfigureForm({
        basicDetailsInfo,
        accountDetailsInfo,
        values,
        accountConfig,
        jobName,
      })
    )
      .unwrap()
      .then(({ data }) => {
        let response = JSON.parse(data.devops_workflow);
        values.id = response.id;
        dispatch(setConfigurationFormData(values));
        setIsShowButtonLoader(false);
        dispatch(setbackButtonClickAction(false));
        props.setCurrent(props.current + 1);
      });
  };
  const handleSubmit = (values) => {
    setIsShowButtonLoader(true);
    values.created_by = created_by_user;
    values.status = "Draft";

    const jobName = devopsStore.jobName;

    if (devopsStore.requestAction === "addNew") {
      if (devopsStore.isFormBackButtonClicked) {
        values.event_type = "update-draft";
        values.id = devopsStore.saveBasicDetailsData.configDetailsInfo.id;
      } else {
        values.event_type = "insert-draft";
        values.id = "";
      }
    } else {
      values.event_type = "update-draft";
      if (devopsStore.requestorTableData.id) {
        values.id = devopsStore.requestorTableData.id;
      } else {
        values.id = devopsStore.saveBasicDetailsData.configDetailsInfo.id;
      }
    }
    // values.event_type = "get_pricing";
    values.created_by = created_by_user;
    values.status = "Draft";
    dispatch(
      saveUpdateConfigureForm({
        basicDetailsInfo,
        accountDetailsInfo,
        values,
        accountConfig,
        jobName,
      })
    )
      .unwrap()
      .then(({ data }) => {
        let response = JSON.parse(data.devops_workflow);
        values.id = response.id;
        dispatch(setConfigurationFormData(values));
        setIsShowButtonLoader(false);
        dispatch(setbackButtonClickAction(false));
        props.setCurrent(props.current + 1);
      });
    values.event_type = "get_pricing";
   
  };

  useEffect(() => {
    if (devopsStore.requestAction === "addNew") {
      if (
        devopsStore.saveBasicDetailsData.basicDetailsInfo.cloud_platform === ""
      ) {
        form.resetFields();
      } else {
        form.setFieldsValue(devopsStore.saveBasicDetailsData.configDetailsInfo);
      }
    } else if (devopsStore.requestAction === "edit") {
      try {
        let config_string = devopsStore.requestorTableData.configurations;
        let cleanedString = config_string.replace(/\\\\/g, "\\");
        let formValues = JSON.parse(cleanedString);
        form.setFieldsValue(JSON.parse(formValues));
      } catch (err) {
        console.log(err);
        let config_string = devopsStore.requestorTableData.configurations;
        let formValues = JSON.parse(config_string);
        form.setFieldsValue(JSON.parse(formValues));
      }
    }
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleAdditionalTags = () => {
    setIsModalOpen(true);
  };

  const removeSpaceAddUnderScore = (val) => {
    let str = val.split(" ").join("_");
    return str.toLowerCase();
  };
  const [osOfferOptions, setOSOfferOptions] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState([]);
  const [osSKUOptions, setOSSKUOptions] = useState([]);
  const handleChangeOS = (e, val) => {

    let arryJson = [];
    let skuArr = [];
    form.setFieldsValue({ offer: "", sku: "" });
    setOSOfferOptions([]);
    let cloud_platform = basicDetailsInfo.cloud_platform;
    // console.log(cloud_platform, e);
    let selectedValue = e;
    let osSku = "all";
    setSelectedOffer(e);
    dispatch(getOSOffer({ cloud_platform, selectedValue, osSku }))
      .unwrap()
      .then(({ data }) => {
        let osResponse = data.devops_cloud_mapping;
        const uniqueOffers = new Set();

        for (const item of osResponse) {
          uniqueOffers.add(item.offer);
        }
        const labelValueList = [];
        for (const offer of uniqueOffers) {
          labelValueList.push({ label: offer, value: offer });
        }
        setOSOfferOptions(labelValueList);
      });
  };

  const handleChangeOSOffer = (e, val) => {
    let arryJson = [];
    setOSSKUOptions([]);
    form.setFieldsValue({ sku: "" });
    let cloud_platform = basicDetailsInfo.cloud_platform;
    let selectedValue = e;
    dispatch(getOSSKU({ cloud_platform, selectedOffer, selectedValue }))
      .unwrap()
      .then(({ data }) => {
        let osResponse = data.devops_cloud_mapping;
        arryJson = osResponse.map((region, index) => ({
          label: region.sku,
          value: region.sku,
        }));
        setOSSKUOptions(arryJson);
      });
  };
  return (
    <>
      <div className="mt-5 configuration-details">
        <span className="heading-devops-config">
          {" "}
          Virtual Machine Configuration
        </span>
      </div>
      {/* {console.log(formFieldDetails)} */}

      <>
        <Form onFinish={handleSubmit} form={form}>
          {ishowSpinner ? (
            <Spin />
          ) : (
            formFieldDetails.map((item) => {
              return (
                <>
                  <div className="sub-heading-devops-config ms-5">
                    <ConfigHeaderPrefix />
                    {" "}{item.header}
                  </div>
                  <br />
                  <div className="configuration-card-body px-5">
                    {item.field_names.map((item, index) => {
                      return (
                        <Row
                          gutter={[16, 16]}
                          className="d-flex justify-content-center"
                        >
                          <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                            <Row gutter={[16, 16]}>
                              <Col xxl={6} xl={6} lg={4} md={4} sm={24}>
                                <span className="devops-form-names">
                                  {" "}
                                  {item.name}
                                </span>
                              </Col>
                              <Col xxl={12} xl={12} lg={12} md={12} sm={24}>
                                {item.showDropDown ? (
                                  item.name === "OS Publisher" ? (
                                    <>
                                      {" "}
                                      <Form.Item
                                        name={item.field_type}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Required",
                                          },
                                        ]}
                                      >
                                        <Select
                                          showSearch
                                          className="devops-antd-select"
                                          bordered={false}
                                          placeholder={`Enter ` + item.name}
                                          options={
                                            item.optionsList &&
                                            item.optionsList.length > 0 &&
                                            item.optionsList
                                          }
                                          onChange={(e) =>
                                            handleChangeOS(e, item.field_type)
                                          }
                                        />
                                      </Form.Item>
                                    </>
                                  ) : item.name === "OS Offer" ? (
                                    <>
                                      <Form.Item
                                        name={item.field_type}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Required",
                                          },
                                        ]}
                                      >
                                        <Select
                                          showSearch
                                          className="devops-antd-select"
                                          bordered={false}
                                          placeholder={`Enter ` + item.name}
                                          options={osOfferOptions}
                                          onChange={(e) =>
                                            handleChangeOSOffer(
                                              e,
                                              item.field_type
                                            )
                                          }
                                        />
                                      </Form.Item>
                                    </>
                                  ) : item.name === "OS SKU" ? (
                                    <>
                                      <Form.Item
                                        name={item.field_type}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Required",
                                          },
                                        ]}
                                      >
                                        <Select
                                          showSearch
                                          className="devops-antd-select"
                                          bordered={false}
                                          placeholder={`Enter ` + item.name}
                                          options={osSKUOptions}
                                        />
                                      </Form.Item>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <Form.Item
                                        name={item.field_type}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Required",
                                          },
                                        ]}
                                      >
                                        <Select
                                          showSearch
                                          className="devops-antd-select"
                                          bordered={false}
                                          placeholder={`Enter ` + item.name}
                                          options={
                                            item.optionsList &&
                                            item.optionsList.length > 0 &&
                                            item.optionsList
                                          }
                                        />
                                      </Form.Item>
                                    </>
                                  )
                                ) : item.showMultiSelect ? (
                                  <>
                                    <Form.Item
                                      name={item.field_type}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Required",
                                        },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        style={{
                                          width: "100%",
                                        }}
                                        mode="multiple"
                                        placeholder={`Enter ` + item.name}
                                        options={
                                          item.optionsList &&
                                          item.optionsList.length > 0 &&
                                          item.optionsList
                                        }
                                        onChange={(e) =>
                                          handleChangeOS(e, item.field_type)
                                        }
                                      />
                                    </Form.Item>
                                  </>
                                ) : item.showRadioButton ? (
                                  <Radio.Group
                                  // onChange={onChange}
                                  // value={value}
                                  >
                                    <Radio value={1}>Enabled</Radio>
                                    <Radio value={2}>Disabled</Radio>
                                  </Radio.Group>
                                ) : item.name === "Admin Password" ||
                                  item.name === "Password" ? (
                                  <Form.Item
                                    name={item.field_type}
                                    rules={[
                                      { required: true, message: "Required" },
                                    ]}
                                  >
                                    <Input
                                      className="devops-antd-input"
                                      bordered={false}
                                      type="password"
                                      // placeholder={`Enter ` + item.name}
                                    />
                                  </Form.Item>
                                ) : (
                                  <Form.Item
                                    name={item.field_type}
                                    rules={[
                                      { required: true, message: "Required" },
                                    ]}
                                  >
                                    <Input
                                      className="devops-antd-input"
                                      bordered={false}
                                      // placeholder={`Enter ` + item.name}
                                    />
                                  </Form.Item>
                                )}
                              </Col>
                            </Row>
                            <br />
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                  <br />
                </>
              );
            })
          )}

          <Row gutter={[16, 16]} justify="end">
            <Col span={24}>
              <Form.Item style={{ textAlign: 'right' }}>
                <Button
                  htmlType="button"
                  className="form-back-button"
                  onClick={() => props.setCurrent(props.current - 1)}
                >
                  Back
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="form-button save-form-button"
                >
                  {isShowButtonLoader ? (
                    <>
                      <div className="d-flex">
                        <div className="">
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </>
                  ) : (
                    " Save"
                  )}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>

      <Modal
        title="Add Tag"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="additional-tag-modal"
        width={800}
      >
        <div className="job-schedule-modal">
          <div className="d-flex justify-content-center">
            <div className="mt-4">
              <Row gutter={[16, 16]} className="d-flex justify-content-center">
                <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                  <Form layout="vertical" onFinish={handleSubmit} form={form}>
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                        <Form.Item name="company_name" label="Key">
                          <Input
                            className="devops-antd-input"
                            // placeholder="Enter Key"
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                        <Form.Item name="company_name" label="Value">
                          <Input
                            className="devops-antd-input"
                            // placeholder="Enter Value"
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                        <Form.Item name="company_name" label="Resource">
                          <Input
                            className="devops-antd-input"
                            // placeholder="Enter Name"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xxl={6}
                        xl={6}
                        lg={6}
                        md={6}
                        sm={24}
                        className="d-flex align-items-center"
                      >
                        <span className="color-red">Remove</span>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                        <Form.Item name="company_name" label="Key">
                          <Input
                            className="devops-antd-input"
                            // placeholder="Enter Key"
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                        <Form.Item name="company_name" label="Value">
                          <Input
                            className="devops-antd-input"
                            // placeholder="Enter Value"
                          />
                        </Form.Item>
                      </Col>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={24}>
                        <Form.Item name="company_name" label="Resource">
                          <Input
                            className="devops-antd-input"
                            // placeholder="Enter Name"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xxl={6}
                        xl={6}
                        lg={6}
                        md={6}
                        sm={24}
                        className="d-flex align-items-center"
                      >
                        <span className="color-red">Remove</span>
                      </Col>
                    </Row>
                    <br />
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ConfigureDetails;