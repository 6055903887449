import React from "react";
import ReactApexChart from "react-apexcharts";
function GroupedBar() {
  const options_Grouped = {
    chart: {
      type: "bar",
      height: 430,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },

    dataLabels: {
      enabled: true,
      position: "top",
      style: {
        fontSize: "12px",
        colors: ["#3B3B3B"],
      },
      offsetY: -20,
    },
    xaxis: {
      categories: ["Production", "Non-Production"],
    },
    title: {
      text: "Spend by Environment & Week ($)",
      align: "center",
      //   width: "1rem",
      style: {
        fontSize: "15px",
        fontWeight: "500",
        color: "#3B3B3B",
      },
    },
    legend: {
      position: "top",
      //   containerMargin: {
      //     top: 10, // Space between the title and legend
      //   },
      //   border: {
      //     show: true, // Display the legend border
      //     radius: 5, // Border radius
      //     width: 1, // Border width
      //     strokeColor: "#000", // Border color
      //     offsetX: 0,
      //     offsetY: 0,
      //   },
    },
    yaxis: {
      title: {
        text: "Spend",
        style: {
          fontSize: "1rem",
          color: "#3B3B3B",
          width: "1rem", // Set the width of the title

          fontWeight: "500",
        },
      },
    },
  };
  const series_Grouped = [
    {
      name: "Aug",
      data: [44, 51],
    },
    {
      name: "Sep",
      data: [23, 34],
    },
    {
      name: "Oct",
      data: [12, 67],
    },
  ];
  return (
    <div>
      <ReactApexChart
        options={options_Grouped}
        series={series_Grouped}
        height={250}
        type="bar"
      />
    </div>
  );
}

export default GroupedBar;
