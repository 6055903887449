import { Card, Col, Modal, Progress, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import "./migrate.css";
import A2cFilter from "../a2c_rover/A2cFilter";
import {
  getTotalResourcetobemigrated,
  getTotalresourceyettobemigrated,
  getTotalResourceunderSpread,
  getResourceMigrated,
} from "../../components/action/graphql/a2crover/A2CReducer.js";
import A2CSidebar from "../a2c_rover/A2CSidebar";
import CostimizeFilter from "../costimize_v2/layout/CostimizeFilter";
import {
  getTotalresourceandcount,
  getTotalCleanupCount,
  getMigrationcompletionProcess,
  getMigrationbyPlannedDate,
  getMigrationByActualDate,
  getResourceStatus,
} from "../../components/action/graphql/a2crover/A2CReducer.js";
function Migrate() {
  const [totalResourcetoMigrated, setTotalResourcetoMigrated] = useState([]);
  const [totalResourceyettoMigate, setTotalResourceyettomigrate] = useState([]);
  const [totalResourceunderSpread, setTotalResourceUnderSpread] = useState([]);
  const [totalResourceMigrated, setTotalResourceMigrated] = useState([]);

  const [migrationCompletionProcess, setMigrationCompletionProcess] = useState(
    []
  );
  const [resourceCountPlannedMigration, setResourceCountPlannedMigration] =
    useState([]);
  const company = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [resourceActualMigrationDate, setResourceMigrationDate] = useState([]);
  const [resourceStatus, setResourceStatus] = useState([]);
  const [progress, setProgress] = useState(0);
  const [cleanupResource, setCleanedupResource] = useState(0);
  const [formatted, setFormatted] = useState([]);
  const [modalOpen, setIsModalOpen] = useState(false);
  const [isShowSpinner, setIsShowSpinner] = useState(true);

  const {
    selectedEnvironment,
    selecteddc,
    selectedos,
    selecteddb,
    selectedApplication,
  } = useSelector((store) => store.a2cRover);
  const input = {
    application: selectedApplication.toString(),
    company: company,
    data_center_name: selecteddc.toString(),
    db: selecteddb.toString(),
    environment: selectedEnvironment.toString(),
    os: selectedos.toString(),
  };
  function dateFormat(inputDate) {
    const date = new Date(inputDate);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
  }
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMigrationbyPlannedDate(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        const record = data.migrate_resource_by_planned.map((datas) => {
          return {
            date: datas.date,
            resource_count: datas.resource_count,
          };
        });

        setResourceCountPlannedMigration(record);
      })
      .catch((err) => {
        console.log(err);
      });
    // second api
    dispatch(getResourceStatus(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        const record = data.migrate_resource_by_status.map((datas) => {
          return {
            migration_status: datas.migration_status,
            resource_count: datas.resource_count,
          };
        });
        setResourceStatus(record);
      })

      .catch((err) => {
        console.log(err);
      });
    // third api
    dispatch(getMigrationcompletionProcess(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        const record = data.migrate_resource_completion_progress.map(
          (datas) => {
            return {
              application: datas.application,
              resource_count_completed: datas.resource_count_completed,
              resource_yet_to_migrated: datas.resource_yet_to_migrated,
            };
          }
        );
        setMigrationCompletionProcess(record);
      })
      .catch((err) => {
        console.log(err);
      });
    // fourth api
    dispatch(getMigrationByActualDate(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        const record = data.migrate_resource_by_actual.map((datas) => {
          const formatDate = (inputDate) => {
            const dateParts = inputDate.split("-");
            const month = new Date(Date.parse(inputDate)).toLocaleString(
              "en-US",
              { month: "short" }
            );
            const day = dateParts[2];
            return `${month} ${day}`;
          };

          const originalDate = datas.date;
          const formattedDate = formatDate(originalDate);
          // Outputs: "Mar 01"
          setFormatted(formattedDate);
          return {
            date: datas.date,
            resource_count: datas.resource_count,
          };
        });
        setResourceMigrationDate(record);
      })
      .catch((err) => {
        console.log(err);
      });
    // 5th api
    dispatch(getTotalResourcetobemigrated(input))
      .unwrap()

      .then(({ data }) => {
        setIsShowSpinner(false);
        const record = data.migrate_total_resource_to_be.map((datas) => {
          return {
            application: datas.application,
            resource_count: datas.resource_count,
          };
        });

        setTotalResourcetoMigrated(record);
      })
      .catch((err) => {
        console.log(err);
      });
    // 6th api
    dispatch(getTotalResourcetobemigrated(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        const record = data.migrate_total_resource_to_be.map((datas) => {
          return {
            application: datas.application,
            resource_count: datas.resource_count,
          };
        });
        setTotalResourcetoMigrated(record);
      })
      .catch((err) => {
        console.log(err);
      });
    // 7th api
    dispatch(getTotalresourceyettobemigrated(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        const record = data.migrate_total_resource_yet_to_be.map((datas) => {
          return {
            application: datas.application,
            resource_count: datas.resource_count,
          };
        });

        setTotalResourceyettomigrate(record);
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(getTotalResourceunderSpread(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        const record = data.migrate_total_resource_under.map((datas) => {
          return {
            application: datas.application,
            resource_count: datas.resource_count,
          };
        });

        setTotalResourceUnderSpread(record);
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(getTotalresourceandcount(input))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        setIsShowSpinner(false);
        if (data.migrate_resource_completion_percentage.length === 0) {
          setTotalResourceandTarget({
            target_resorce_count: 0,
            total_resource: 0,
          });
        } else {
          setTotalResourceandTarget(
            data.migrate_resource_completion_percentage[0]
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(getTotalCleanupCount(input))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        setIsShowSpinner(false);
        if (data.migrate_resource_cleaned_up_percentage.length === 0) {
          setTotalCleanupResourceCount({
            target_resorce_count: 0,
            total_resource: 0,
          });
        } else {
          setTotalCleanupResourceCount(
            data.migrate_resource_cleaned_up_percentage[0]
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(getResourceMigrated(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        const record = data.migrate_total_resource_migrated.map((datas) => {
          return {
            application: datas.application,
            resource_count: datas.resource_count,
          };
        });

        setTotalResourceMigrated(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedApplication,
    selectedEnvironment,
    selecteddc,
    selectedos,
    selecteddb,
  ]);
  const label_date = resourceCountPlannedMigration.map((item) => item.date);
  const resource_count_planned = resourceCountPlannedMigration.map(
    (item) => parseFloat(item.resource_count) || 0
  );
  const formattedcountDates = label_date.map((apiDate) =>
    new Date(apiDate).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    })
  );

  const optionsResourceCountPlanned = {
    chart: {
      type: "area",
      background: "transparent",
    },

    xaxis: {
      categories: formattedcountDates,
      labels: {
        style: {
          colors: "#FFFFFF",
        },
        rotateAlways: true,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },

    dataLabels: {
      enabled: false, // Disable data labels
    },
    plotOptions: {
      line: {
        fill: {
          type: "solid",
          color: "#625A5A",
        },
      },
    },

    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
  };

  const seriesResourceCountPlanned = [
    {
      name: "Resource Count Planned",
      data: resource_count_planned,
      color: "#ffa582",
    },
  ];

  const phaseData = resourceStatus;
  let startValue = 0;
  let endValue = 0;
  let countValue = 0;
  let result = 0;
  const chartData_phase = [];
  if (!phaseData || phaseData.length === 0) {
    result = 0;
  } else {
    phaseData.forEach((phase) => {
      const resourceCount = parseInt(phase.resource_count);
      endValue += resourceCount;
      countValue = countValue + 1;
      chartData_phase.push({
        x: phase.migration_status,
        y: [startValue, endValue],
        fill: "#A7EE7C",
      });

      startValue = endValue + 1;
    });

    // const result = endValue - (countValue - 1);
    const result = endValue;
    chartData_phase.push({
      x: "Total",
      y: [0, result],
      fill: "#999999",
      tooltip: {
        content: `Total: ${result}`,
      },
    });
  }
  const datephas = chartData_phase.map((item) => item.x);

  const options_phase = {
    chart: {
      type: "rangeBar",
      height: 250,
    },
    plotOptions: {
      bar: {
        horizontal: false, // Set to vertical bars
        distributed: true,
        // Distribute bars evenly within each category
      },
    },
    xaxis: {
      type: "category",
      categories: datephas,
      labels: {
        style: {
          colors: "#FFFFFF", // Define colors for each category
        },
        show: true,
      },
    },
    tooltip: {
      enabled: false,
      // Use the custom tooltip formatter
    },
    dataLabels: {
      enabled: true, // Disable data labels
    },

    yaxis: {
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    legend: {
      labels: {
        colors: "#FFFFF",
      },
    },
    noData: {
      text: "No Data",

      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
  };

  const series_phase = [
    {
      name: "Range",
      data: chartData_phase,
      dataLabels: {
        enabled: true, // Enable data labels
      },
    },
  ];

  const labels_application = migrationCompletionProcess.map(
    (item) => item.application
  );
  const resource_count_complete = migrationCompletionProcess.map(
    (item) => parseFloat(item.resource_count_completed) || 0
  );
  const resource_yet_to_migrate = migrationCompletionProcess.map(
    (item) => parseFloat(item.resource_yet_to_migrated) || 0
  );

  const chartOptions_MigrationProcess = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true, // Enable stacking for the bars
    },
    plotOptions: {
      bar: {
        horizontal: true, // Make it horizontal
        // Adjust the bar height
      },
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: labels_application,
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    legend: {
      labels: {
        colors: ["#FFF"],
      },
    },
    noData: {
      text: "No Data",

      align: "center",

      verticalAlign: "middle",

      offsetX: 0,

      offsetY: 0,

      style: {
        color: "#fff",

        fontSize: "20px",

        fontFamily: "Poppins",
      },
    },
  };

  const chartData_MigrationProcess = [
    {
      name: "Resource Count Completed",
      data: resource_count_complete,
    },
    {
      name: "Resource Yet to  Migrate",
      data: resource_yet_to_migrate,
    },
  ];

  const label_date_Actual = resourceActualMigrationDate.map(
    (item) => item.date
  );

  const ActualResourceCountDate = resourceActualMigrationDate.map(
    (item) => parseFloat(item.resource_count) || 0
  );
  const formattedDates = label_date_Actual.map((apiDate) =>
    new Date(apiDate).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    })
  );

  const optionsResourceActualDate = {
    chart: {
      type: "area",
      background: "transparent",
    },

    xaxis: {
      categories: formattedDates,
      labels: {
        style: {
          colors: "#FFFFFF",
        },
        rotateAlways: true,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    dataLabels: {
      enabled: false, // Disable data labels
    },
    plotOptions: {
      area: {
        background: "#F6E2E2", // Set the background color below the data points
      },
    },
    stroke: {
      curve: "straight", // Set the curve style (options: 'smooth', 'straight')
      width: 2, // Set the stroke width
      colors: ["#A7EE7C"], // Set the stroke color
    },

    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
  };

  const seriesResouceActualDate = [
    {
      name: "Resouce Count  By Actual Migration Date",
      data: ActualResourceCountDate,
      color: "#caf4b0",
    },
  ];

  const label_total_res_to_mig = totalResourcetoMigrated.map(
    (item) => item.application
  );
  const value_total_res_to_mig = totalResourcetoMigrated.map(
    (item) => item.resource_count
  );
  const numericValues = value_total_res_to_mig.map((value) =>
    parseInt(value, 10)
  );

  const series_donut = numericValues;
  const totalValue = series_donut.reduce(
    (acc, currentValue) => acc + currentValue,
    0
  );
  const options_donut = {
    chart: {
      type: "donut",
    },

    colors: [
      "#9FDEF1",
      "#2A5D78",
      "#F6AA54",
      "#A7EE7C",
      "#F65454",
      "#057EC1",
      "#230B34",
      "#FE632B",
    ],
    labels: label_total_res_to_mig,
    dataLabels: {
      formatter: function (val) {
        // Remove the percentage symbol from the labels

        return val.toFixed(0);
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "23px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#ff6a2f",
              offsetY: -5,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "",
              color: "#ff6a2f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    stroke: {
      show: false,
    },

    tooltip: {
      enabled: true,
      y: {
        formatter: function (value) {
          return value + " NOS";
        },
      },
      theme: "light",

      fillSeriesColor: false,
      // You can use 'light' or 'dark' theme
    },

    legend: {
      position: "bottom",

      horizontalAlign: "center",
      color: ["#FFFFF"],
    },

    noData: {
      text: "No Data",

      align: "center",

      verticalAlign: "middle",

      offsetX: 0,

      offsetY: 0,

      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },

    responsive: [
      {
        breakpoint: 480,

        options: {
          chart: {
            width: 200,
          },

          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  options_donut.legend.labels = {
    colors: "#FFFFFF", // Set a single color for all legend items
  };

  const label_total_res_yet_to_mig = totalResourceyettoMigate.map(
    (item) => item.application
  );
  const value_total_res_yet_to_mig = totalResourceyettoMigate.map(
    (item) => item.resource_count
  );
  const yet_to_mig = value_total_res_yet_to_mig.map((value) =>
    parseInt(value, 10)
  );

  const series_donut_yet_to_migrate = yet_to_mig;
  const options_donut_yet_to_migrate = {
    chart: {
      type: "donut",
    },

    colors: [
      "#9FDEF1",
      "#2A5D78",
      "#F6AA54",
      "#A7EE7C",
      "#F65454",
      "#057EC1",
      "#230B34",
      "#FE632B",
    ],
    labels: label_total_res_yet_to_mig,
    legend: {
      position: "bottom",

      horizontalAlign: "center",
      color: ["#FFFFF"],
    },
    dataLabels: {
      formatter: function (val) {
        // Remove the percentage symbol from the labels
        return val.toFixed(0);
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "23px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#ff6a2f",
              offsetY: -5,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "",
              color: "#ff6a2f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    stroke: {
      show: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (value) {
          return value + " NOS";
        },
      },
      theme: "light",
      fillSeriesColor: false, // You can use 'light' or 'dark' theme
    },

    noData: {
      text: "No Data",

      align: "center",

      verticalAlign: "middle",

      offsetX: 0,

      offsetY: 0,

      style: {
        color: "#fff",

        fontSize: "20px",

        fontFamily: "Poppins",
      },
    },

    responsive: [
      {
        breakpoint: 480,

        options: {
          chart: {
            width: 200,
          },

          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  options_donut_yet_to_migrate.legend.labels = {
    colors: "#FFFFFF", // Set a single color for all legend items
  };

  const label_total_res_under_spread = totalResourceunderSpread.map(
    (item) => item.application
  );
  const value_total_res_under_spread = totalResourceunderSpread.map(
    (item) => item.resource_count
  );
  const under_spread = value_total_res_under_spread.map((value) =>
    parseInt(value, 10)
  );

  const series_donut_res_under_spread = under_spread;
  const options_donut_res_under_spread = {
    chart: {
      type: "donut",
    },

    colors: [
      "#9FDEF1",
      "#2A5D78",
      "#F6AA54",
      "#A7EE7C",
      "#F65454",
      "#057EC1",
      "#230B34",
      "#FE632B",
    ],
    stroke: {
      show: false,
    },
    labels: label_total_res_under_spread,
    legend: {
      position: "bottom",

      horizontalAlign: "center",
      color: ["#FFFFF"],
    },
    dataLabels: {
      formatter: function (val) {
        // Remove the percentage symbol from the labels

        return val.toFixed(0);
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "23px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#ff6a2f",
              offsetY: -5,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "",
              color: "#ff6a2f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },

    tooltip: {
      enabled: true,
      y: {
        formatter: function (value) {
          return value + " NOS";
        },
      },
      theme: "light",

      fillSeriesColor: false, // You can use 'light' or 'dark' theme
    },

    noData: {
      text: "No Data",

      align: "center",

      verticalAlign: "middle",

      offsetX: 0,

      offsetY: 0,

      style: {
        color: "#fff",

        fontSize: "20px",

        fontFamily: "Poppins",
      },
    },

    responsive: [
      {
        breakpoint: 480,

        options: {
          chart: {
            width: 200,
          },

          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  options_donut_res_under_spread.legend.labels = {
    colors: "#FFFFFF", // Set a single color for all legend items
  };

  const label_total_resource = totalResourceMigrated.map(
    (item) => item.application
  );
  const value_total_resource = totalResourceMigrated.map(
    (item) => item.resource_count
  );
  const total_resource = value_total_resource.map((value) =>
    parseInt(value, 10)
  );

  const series_donut_total_resource = total_resource;
  const options_donut_total_resource = {
    chart: {
      type: "donut",
    },

    colors: [
      "#9FDEF1",
      "#2A5D78",
      "#F6AA54",
      "#A7EE7C",
      "#F65454",
      "#057EC1",
      "#230B34",
      "#FE632B",
    ],
    stroke: {
      show: false,
    },
    labels: label_total_resource,
    legend: {
      position: "bottom",

      horizontalAlign: "center",
      color: ["#FFFFF"],
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "23px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#ff6a2f",
              offsetY: -5,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "",
              color: "#ff6a2f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },

    dataLabels: {
      formatter: function (val) {
        // Remove the percentage symbol from the labels

        return val.toFixed(0);
      },
    },

    tooltip: {
      enabled: true,
      y: {
        formatter: function (value) {
          return value + " NOS";
        },
      },
      theme: "light",

      fillSeriesColor: false, // You can use 'light' or 'dark' theme
    },
    noData: {
      text: "No Data",

      align: "center",

      verticalAlign: "middle",

      offsetX: 0,

      offsetY: 0,

      style: {
        color: "#fff",

        fontSize: "20px",

        fontFamily: "Poppins",
      },
    },

    responsive: [
      {
        breakpoint: 480,

        options: {
          chart: {
            width: 200,
          },

          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  options_donut_total_resource.legend.labels = {
    colors: "#FFFFFF", // Set a single color for all legend items
  };
  const [totalResourceandTarget, setTotalResourceandTarget] = useState({
    target_resorce_count: 0,
    total_resource: 0,
  });
  useEffect(() => {
    dispatch(getTotalresourceandcount(input))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        if (data.migrate_resource_completion_percentage.length === 0) {
          setTotalResourceandTarget({
            target_resorce_count: 0,
            total_resource: 0,
          });
        } else {
          setTotalResourceandTarget(
            data.migrate_resource_completion_percentage[0]
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedApplication,
    selectedEnvironment,
    selecteddc,
    selectedos,
    selecteddb,
  ]);

  //console.log(totalResourceandTarget[0].target_resorce_count, "target resource");

  /* const totalResourceCount = () => {
    dispatch(getTotalresourceandcount())
      .unwrap()
      .then(({ data }) => {
        console.log(data,"data of total resource count");
        setTotalResourceandTarget(
          data.migrate_resource_completion_percentage[0]
        );
      })
      .catch((err) => {});
  };

  useEffect(() => {
    totalResourceCount();
  }, [
    selectedApplication,
    selectedEnvironment,
    selecteddc,
    selectedos,
    selecteddb,
  ]);*/

  let percentage_resource = totalResourceandTarget.target_resorce_count;
  let percentage_resource_cnt =
    (percentage_resource / totalResourceandTarget.total_resource) * 100;
  let percent_resource_total = percentage_resource_cnt.toFixed(2);
  if (isNaN(percentage_resource_cnt)) {
    percent_resource_total = 0;
  }

  const [totalCleanupResourceCount, setTotalCleanupResourceCount] = useState({
    target_resorce_count: 0,
    total_resource: 0,
  });

  let percentage = totalCleanupResourceCount.target_resorce_count;
  let pecentage_cleanup =
    (percentage / totalCleanupResourceCount.total_resource) * 100;
  let percent_clean_up = pecentage_cleanup.toFixed(2);
  if (isNaN(percent_clean_up)) {
    percent_clean_up = 0;
  }
  /*const percentCleanup =()=>{
 
  setProgress(totalCleanupResourceCount.target_resorce_count);
  setCleanedupResource(totalCleanupResourceCount.total_resource);
  const percentage_cleanup=((progress)/(cleanupResource))*100;
  console.log(percentage_cleanup);
  // return percentage_cle/anup;
}*/
  const clickMe = () => {
    setIsModalOpen(true);
  };
  chartOptions_MigrationProcess.legend.labels = {
    colors: "#FFFFFF", // Set a single color for all legend items
  };

  return (
    <div>
      <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
        <A2CSidebar />
        <main className="position-main-div" style={{ padding: 10, flex: 1 }}>
          {" "}
          <div className="cams-content">
            <div className="container-fluid">
              <div className="header-filter d-flex justify-content-between">
                <div className="header-title-a2c-main">Migrate</div>
                <span className="d-flex align-items-end">
                  <A2cFilter />
                </span>
              </div>
              <div>
                <h6 className="headings"> Migration Completion Process</h6>
                <Row>
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Card className="operate-cards">
                      {isShowSpinner ? (
                        <div className=" d-flex justify-content-center align-items-center spinner-height">
                          <Spin />
                        </div>
                      ) : (
                        <ReactApexChart
                          options={chartOptions_MigrationProcess}
                          series={chartData_MigrationProcess}
                          type="bar"
                          height={500}
                        ></ReactApexChart>
                      )}
                    </Card>
                  </Col>
                </Row>
              </div>

              <br />
              {/* Graph */}
              <>
                <div className="">
                  <Row gutter={16}>
                    <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                      <Card
                        title="Resource Count by Migration Status"
                        bordered={false}
                        className="operate-cards"
                      >
                        {isShowSpinner ? (
                          <div className=" d-flex justify-content-center align-items-center spinner-height">
                            <Spin />
                          </div>
                        ) : (
                          <ReactApexChart
                            options={options_phase}
                            series={series_phase}
                            type="rangeBar"
                            height={350}
                          />
                        )}
                      </Card>
                    </Col>

                    <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                      <Card
                        title=" Resource Count By Planned Migration Date"
                        bordered={false}
                        className="operate-cards"
                      >
                        {isShowSpinner ? (
                          <div className=" d-flex justify-content-center align-items-center spinner-height">
                            <Spin />
                          </div>
                        ) : (
                          <ReactApexChart
                            options={optionsResourceCountPlanned}
                            series={seriesResourceCountPlanned}
                            type="area"
                            height={350}
                          />
                        )}
                      </Card>
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                      <Card
                        title="Resource Count By Actual Migration Date"
                        bordered={false}
                        className="operate-cards"
                      >
                        {isShowSpinner ? (
                          <div className=" d-flex justify-content-center align-items-center spinner-height">
                            <Spin />
                          </div>
                        ) : (
                          <ReactApexChart
                            options={optionsResourceActualDate}
                            series={seriesResouceActualDate}
                            type="area"
                            height={350}
                          />
                        )}
                      </Card>
                    </Col>
                  </Row>
                </div>
              </>
              <div className="textalign">
                <p className="text-white">
                  (%) Completion of Resource Migration & Retirement
                </p>
              </div>
              <div className="">
                <Row gutter={[16, 16]}>
                  <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Card className="operate-cards">
                      {isShowSpinner ? (
                        <div className=" d-flex justify-content-center align-items-center spinner-height">
                          <Spin />
                        </div>
                      ) : (
                        <>
                          <div className="box1">
                            <div className="flleft">
                              <p> Cleaned Up Resource Count</p>
                            </div>
                            <div className="flright">
                              <p>Total Resource count</p>
                              <span>
                                {totalResourceandTarget.total_resource}
                              </span>
                            </div>
                          </div>
                          <div className="box2">
                            <h6>{percent_resource_total}%</h6>
                          </div>

                          <div className="box3">
                            <Progress
                              percent={percent_resource_total}
                              showInfo={false}
                            ></Progress>
                          </div>
                        </>
                      )}
                    </Card>
                  </Col>

                  <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Card className="operate-cards">
                      {isShowSpinner ? (
                        <div className=" d-flex justify-content-center align-items-center spinner-height">
                          <Spin />
                        </div>
                      ) : (
                        <>
                          <div className="box1">
                            <div className="flleft">
                              <p> Cleaned up resource count</p>
                              <span>
                                {totalCleanupResourceCount.target_resorce_count}
                              </span>
                            </div>
                            <div className="flright">
                              <p>Source Resource Count</p>
                              <span>
                                {totalCleanupResourceCount.total_resource}
                              </span>
                            </div>
                          </div>
                          <div className="box2">
                            <h6>{percent_clean_up}%</h6>
                          </div>

                          <div className="box3">
                            <Progress
                              percent={percent_clean_up}
                              showInfo={false}
                            ></Progress>
                          </div>
                        </>
                      )}
                    </Card>
                  </Col>
                </Row>
              </div>
              <div>
                <div>
                  <div className="">
                    <div>
                      <Row gutter={[16, 16]}>
                        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                          <Card
                            title="Total Resources (%) To Be Migrated"
                            bordered={false}
                            className="operate-cards"
                          >
                            {isShowSpinner ? (
                              <div className=" d-flex justify-content-center align-items-center spinner-height">
                                <Spin />
                              </div>
                            ) : (
                              <div className="donut-chart">
                                <ReactApexChart
                                  options={options_donut}
                                  series={series_donut}
                                  type="donut"
                                  height={288}
                                  className="donutchart"
                                />
                              </div>
                            )}
                          </Card>
                        </Col>
                        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                          <Card
                            title="Resources (%) Yet To Be Migrated"
                            bordered={false}
                            className="operate-cards"
                          >
                            {isShowSpinner ? (
                              <div className=" d-flex justify-content-center align-items-center spinner-height">
                                <Spin />
                              </div>
                            ) : (
                              <div className="donut-chart">
                                <ReactApexChart
                                  options={options_donut_yet_to_migrate}
                                  series={series_donut_yet_to_migrate}
                                  type="donut"
                                  height={288}
                                  className="donutchart"
                                />
                              </div>
                            )}
                          </Card>
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={[16, 16]}>
                        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                          <Card
                            title="Resources (%) Under Migration Spread"
                            bordered={false}
                            className="operate-cards"
                          >
                            {isShowSpinner ? (
                              <div className=" d-flex justify-content-center align-items-center spinner-height">
                                <Spin />
                              </div>
                            ) : (
                              <div className="donut-chart">
                                <ReactApexChart
                                  options={options_donut_res_under_spread}
                                  series={series_donut_res_under_spread}
                                  type="donut"
                                  height={288}
                                  className="donutchart"
                                />
                              </div>
                            )}
                          </Card>
                        </Col>
                        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                          <Card
                            title="Total Resources (%) Migrated"
                            bordered={false}
                            className="operate-cards"
                          >
                            {isShowSpinner ? (
                              <div className=" d-flex justify-content-center align-items-center spinner-height">
                                <Spin />
                              </div>
                            ) : (
                              <div className="donut-chart">
                                <ReactApexChart
                                  options={options_donut_total_resource}
                                  series={series_donut_total_resource}
                                  type="donut"
                                  height={288}
                                  className="donutchart"
                                />
                              </div>
                            )}
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Migrate;
