import React, { useEffect, useState } from "react";
import {
  escalationDetails,
  listJobSchedules,
  listJobSchedulesOverview,
  listJobSchedulesReport,
} from "../../action/graphql/peems/PeemsAPI";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Modal, Spin } from "antd";
import { Icon } from "@iconify/react";

function PeemsJobsSchedule({ event_identifier, type, startDate }) {
  const [responseData, setResponseData] = useState([]);
  const [isShowSpinner, setShowSpinner] = useState(true);
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  useEffect(() => {
    if (type == "overview") {
      getJobSchedulesOverview(event_identifier);
    } else {
      getJobSchedulesReport(event_identifier);
    }
  }, []);
  const getJobSchedulesReport = (value) => {
    dispatch(listJobSchedulesReport({ value, startDate, companyName }))
      .unwrap()
      .then(({ data }) => {
        setShowSpinner(false);
        let response = JSON.parse(data.getResults);
        setResponseData(response);
      });
  };
  const getJobSchedulesOverview = (value) => {
    dispatch(listJobSchedulesOverview({ value, companyName }))
      .unwrap()
      .then(({ data }) => {
        setShowSpinner(false);
        let json_formatted = JSON.parse(data.listPEEMSExecqs);
        setResponseData(json_formatted);
      });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [isShowSpinnerLoader, setIsShowSpinnerLoader] = useState(true);
  const [liItems, setLiItems] = useState([
    {
      iconName: "solar:user-id-broken",
      title: "Ticket ID",
      value: "",
    },
    {
      iconName: "ic:round-subject",
      title: "Subject",
      value: "",
    },
    {
      iconName: "fluent:book-contacts-20-regular",
      title: "Contact Name",
      value: "",
    },
    {
      iconName: "mdi:customer-service",
      title: "Priority",
      value: "",
    },
    {
      iconName: "tabler:calendar-due",
      title: "Due Date",
      value: "",
    },
    {
      iconName: "uil:channel",
      title: "Status",
      value: "",
    },
  ]);
  let liList = [];
  const [jobEscalationData, setJobEscalationData] = useState([]);
  const handleEscalation = (item) => {
    setIsModalOpen(true);
    setIsShowSpinnerLoader(true);
    dispatch(escalationDetails(item.scheduled_date))
      .unwrap()
      .then(({ data }) => {
        let response = JSON.parse(data.getzohoresponse);
        setJobEscalationData(response);

        setIsShowSpinnerLoader(false);
      });
  };
  useEffect(() => {}, [isShowSpinnerLoader]);
  return (
    <>
      <div>
        {" "}
        {isShowSpinner ? (
          <>
            {" "}
            <div className="d-flex justify-content-center p-2">
              <Spinner animation="grow" className="" />
            </div>
          </>
        ) : (
          <div className="job-schedule-table ">
            <table className="table peems-color">
              <thead>
                <tr>
                  <th>Scheduled Date</th>
                  <th>Job Name</th>
                  <th>Scheduled Start Time</th>
                  <th>Scheduled End Time</th>
                  <th>Escalation Level</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {responseData.map((item) => {
                  return (
                    <>
                      <tr>
                        <td>{item.schedule_date}</td>
                        <td>{item.event_identifier}</td>
                        <td>{item.scheduled_starttime}</td>
                        <td>{item.scheduled_endtime}</td>
                        <td onClick={() => handleEscalation(item)}>
                          <a>{item.escalation_level}</a>
                        </td>
                        <td>
                          {item.status === "Completed" ? (
                            <div className="peems-completed">{item.status}</div>
                          ) : item.status === "active" ? (
                            <div className="peems-active">Yet To Start</div>
                          ) : item.status === "not triggered" ? (
                            <div className="peems-not-trigerred">
                              Not Trigerred
                            </div>
                          ) : item.status === "failed" ||
                            item.status === "Failed" ? (
                            <div className="peems-failed">Failed</div>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <Modal
        title="Escalation level "
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="job-schedule-modal">
          <div className="d-flex justify-content-center">
            <div>
              {isShowSpinnerLoader ? (
                <Spin />
              ) : (
                <>
                  <ul className="job-schedule-ul">
                    {liItems.map((item, index) => {
                      return (
                        <>
                          <li className="mb-4">
                            {/* {console.log()} */}
                            <div className="d-flex align-items-center">
                              <div className="plat-icon-bg">
                                <Icon
                                  icon={item.iconName}
                                  style={{ fontSize: "25px" }}
                                />
                              </div>
                              &nbsp;
                              <div className="d-flex align-items-center">
                                <div>
                                  <h6>{item.title}</h6>
                                  {item.title === "Ticket ID" ? (
                                    <p className="m-0">
                                      {jobEscalationData[0].ticketNumber}
                                    </p>
                                  ) : item.title === "Subject" ? (
                                    <p className="m-0">
                                      {jobEscalationData[0].subject}
                                    </p>
                                  ) : item.title === "Contact Name" ? (
                                    <p className="m-0">
                                      {jobEscalationData[0].contactLastName}
                                    </p>
                                  ) : item.title === "Priority" ? (
                                    <p className="m-0">
                                      {jobEscalationData[0].priority}
                                    </p>
                                  ) : item.title === "Due Date" ? (
                                    <p className="m-0">
                                      {jobEscalationData[0].responseDueDate}
                                    </p>
                                  ) : item.title === "Status" ? (
                                    <p className="m-0">
                                      {jobEscalationData[0].status}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PeemsJobsSchedule;
