import React from "react";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";
import CHLOGO from "../../costimize_v2/WeeklyReport/onechlogo.jpg";
import ServiceDetailed from "./ServiceDetailed";
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 12,
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    width: "100%",
  },
  whiteBg: {
    backgroundColor: "white",
    width: "100%",
    height: "100%",
  },
  horizontalBar: {
    width: "100%",
    height: 16,
    backgroundColor: "#ff6a2f",
    marginVertical: 0,
  },
  headerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "10%",
  },
  imagtag: {
    flexDirection: "row",
  },
  //   textsCenter: {
  //     textAlign: "center",
  //   },
  companyName: {
    color: "#ff6a2f",
    textAlign: "center",
  },
  headingCenter: {
    color: "#000",
  },
  subheadingRow: {
    flexDirection: "row",
    justifyContent: "center",
  },
  subheadingTab: {
    fontSize: 35,
  },
  headingTab: {
    fontSize: 70,
  },
  textsCenter: {
    textAlign: "center",
    marginLeft: 20,
  },
  reportCards: {
    borderWidth: 1,
    borderColor: "#cfcfcf",
    backgroundColor: "#eee",
    borderRadius: 5, // border radius in React Native uses a numeric value
    marginBottom: 24, // numeric value for margin in React Native
  },
  // chartContainer: {
  //   width: "50%",
  //   height: 350,
  //   marginBottom: 10,
  // },
  boldheading: {
    fontSize: "1.25rem",
    marginTop: "1rem",
    color: "#3b3b3b",
    fontweight: "700",
    /* text-align: center; */
  },
  chartImage: {
    width: "50%",
  },

  dateheading: {
    marginTop: "-1rem",
    fontsize: "1rem",
    border: "1px solid black",
    display: "inline-block",
    padding: "0.5rem",
    fontweight: "bold",
    /* font-size: 1rem; */
  },
  headerheading: {
    display: "flex",
    justifyContent: " center",
    color: "#ff6a2f",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  chartheading: {
    display: "flex",
    justifyContent: "left",
    // color: "#ff6a2f",
    // fontFamily: "Poppins",
    // fontStyle: "normal",
    // fontWeight: 700,
    // lineHeight: "normal",
    // marginTop: "1rem",
    textAlign: "left",
    fontWeight: "bold",
    marginTop: 5,
    color: "#FF6A2F",
  },
  subtableheading: {
    color: "#FF6A2F",
    fontWeight: "700",
    fontSize: 20,
  },
  container: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // paddingTop: 10,
    // paddingBottom: 10,
  },
  footerruler: {
    width: "100%",
    height: 1,
    backgroundColor: "#FF6A2F",
  },
  footerRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 1,
  },
  //   footerCol: {
  //     width: "25%",
  //   },
  footerText: {
    fontSize: 8,
  },
  footer: {
    position: "absolute",
    fontSize: 10,
    bottom: 20,
    left: 20,
    right: 20,
    textAlign: "center",
    color: "grey",
    paddingTop: 20,
  },
  // Add your other styles similarly
});

function Test({ props }) {
  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const companyName = company_name.toUpperCase();
  return (
    <div>
      <Document>
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.whiteBg}>
            {/* <View style={styles.horizontalBar} />
            <View style={styles.headingCenter}>
              <View style={styles.headerRow}>
                <Image src={CHLOGO} />
              </View>
              <View style={styles.subheadingRow}>
                <Text style={styles.subheadingTab}>
                  Weekly Consumption Report {"\n"}{" "}
                  <Text>
                    <Text
                      style={{
                        marginLeft: "25%",
                        //   marginRight: "auto",
                        //   textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"\t"}
                      {"\t"} <Text style={{ marginLeft: "auto" }}> For</Text>
                    </Text>{" "}
                    <Text style={styles.companyName}></Text>{" "}
                  </Text>
                </Text>
              </View>
            </View> */}
            <Text>Hello</Text>
            <View style={{ flexDirection: "row", marginTop: 5 }}>
              <View style={{ width: "100%" }}>
                <ServiceDetailed
                  tableWeeklySpenddetail={props.serviceDetailed}
                  startDates={props.startDate}
                  endDates={props.endDate}
                />
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </div>
  );
}

export default Test;
