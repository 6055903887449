import React, { useRef, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getMonthlyTrendChartQGApiData } from "../../../action/costimizedashboardAction";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import moment from "moment";
function LastSixMonthSpends() {
  const [reportformonth, setReportForMonth] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  var company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  // On component
  useEffect(() => {
    setLoading(true);
    const apiVariables = {
      event_type: "month trend",
      screen: "qg",
      account_name: selectedAccount.toString(),
      company: company_name,
      cloud: selectedCloud.toString(),
      month: selectedTopdate,
      application: selectedApplication.toString(),
      db: selecteddb.toString(),
      environment: selectedEnvironment.toString(),
      os: selectedos.toString(),
      service: selectedservices.toString(),
      infra: selectedinfra.toString(),
    };
    dispatch(getMonthlyTrendChartQGApiData(apiVariables))
      .unwrap()
      .then(({ data }) => {
        const jsonString = data.monthlytrendchart_qg;
        // Parse the JSON-encoded string to a JavaScript object
        let trendChartData = JSON.parse(jsonString);
        const transformedData = trendChartData.map((item) => {
          const month = item.report_month;
          const total_spend = Number(item.invoiced_including_tax) || 0;
          const credit_spend = Number(item.credit) || 0;
          const with_credit = item.invoiced_including_tax;
          const aws_consumption = Number(item.aws_consumption) || 0;
          const projected_spend = Number(item.projected_spend) || 0;

          return {
            month,
            total_spend: total_spend.toString(),
            credit_spend: credit_spend.toString(),
            with_credit: with_credit.toString(),
            aws_consumption: aws_consumption.toString(),
            projected_spend: projected_spend.toString(),
          };
        });

        setReportForMonth(transformedData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err?.message);
      });
  }, [
    selectedTopdate,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selecteddb,
    selectedEnvironment,
    selectedos,
    selectedservices,
    selectedinfra,
  ]);

  const with_credit = reportformonth.map((item) =>
    parseFloat(item.with_credit)
  );
  // console.log(with_credit, "withcredit");
  const credtSpend = reportformonth.map((item) =>
    Math.abs(parseFloat(item.credit_spend))
  );

  const aws_consumption = reportformonth.map((item) =>
    parseFloat(item.aws_consumption)
  );
  const projected_spend = reportformonth.map((item) =>
    parseFloat(item.projected_spend)
  );
  const projected_spend_with = projected_spend.map((value) => {
    if (isNaN(value) || value == null) {
      return 0;
    }
    return value;
  });
  const projected = projected_spend_with[0];
  // console.log(projected_spend, "projected spend");
  const allValues = [
    ...with_credit,
    ...credtSpend,
    ...aws_consumption,
    ...projected_spend,
  ];
  // console.log(with_credit);
  // console.log(credtSpend);
  // console.log(aws_consumption);
  // console.log(projected_spend);
  const allNumbers = allValues.filter((value) => !isNaN(value)).map(Number);
  const maxValue = Math.max(...allNumbers);
  const minValue = Math.min(...allNumbers);
  // console.log(maxValue, "Max");
  const series = [
    {
      name: "Projected Spend",
      type: "area",
      data: projected_spend_with,
    },
    {
      name: "Invoice (Including Tax)",
      type: "line",
      data: with_credit,
    },

    {
      name: "Consumption",
      type: "column",
      data: aws_consumption,
    },
    {
      name: "Credits",
      type: "column",
      data: credtSpend,
    },
  ];

  const options = {
    chart: {
      id: "threemonthspend",
      height: 350,
      type: "line",
      stacked: "true",

      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: false,
        columnWidth: "35%",

        dataLabels: {
          total: {
            enabled: false,
            style: {
              fontSize: "10px",
              colors: "#0000",
              background: "#FFF",
            },
            offsetY: -10,
          },
        },
      },
      line: {
        markers: {
          size: 0,
        },
      },
    },
    markers: {
      discrete: [
        {
          seriesIndex: 0,
          dataPointIndex: 0,
          fillColor: "#eee",
          strokeColor: "#eee",
          size: 5,
          shape: "circle",
        },
        {
          seriesIndex: 0,
          dataPointIndex: 1,
          fillColor: "#eee",
          strokeColor: "#eee",
          size: 5,
          shape: "circle",
        },
        {
          seriesIndex: 0,
          dataPointIndex: 2,
          fillColor: "#eee",
          strokeColor: "#eee",
          size: 5,
          shape: "circle",
        },
        {
          seriesIndex: 0,
          dataPointIndex: 3,
          fillColor: "#eee",
          strokeColor: "#eee",
          size: 5,
          shape: "circle",
        },
        {
          seriesIndex: 0,
          dataPointIndex: 4,
          fillColor: "#eee",
          strokeColor: "#eee",
          size: 5,
          shape: "circle",
        },
        {
          seriesIndex: 0,
          dataPointIndex: 5,
          fillColor: "#9C27B0",
          strokeColor: "#fff",
          size: 5,
          shape: "circle",
        },
      ],
    },

    forecastDataPoints: {
      count: moment(selectedTopdate)?.isSame(new Date(), "month") ? 1 : 0,
    },
    yaxis: {
      forceNiceScale: true,
      title: {
        text: "Spend in $",
        style: {
          fontSize: "13px",
          color: "#3B3B3B",
          width: "1rem",
          fontWeight: "500",
        },
      },
      min: 0,
      max: maxValue,
      tickAmount: 5,
      forceNiceScale: true,
      labels: {
        style: {
          colors: "#d4d4d4",
        },
        formatter: function (value) {
          return new Intl.NumberFormat("en-US").format(Math.round(value));
        },
      },
    },

    colors: ["#9C27B0", "#40A2B8", "#FF6A2F", "#A7EE7C"],

    fill: {
      opacity: [0, 0.95, 1],
    },

    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      y: {
        formatter: function (val) {
          if (typeof val !== "undefined" && val !== null) {
            if (isNaN(val) || val === 0) {
              return "-";
            } else {
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
          }
          return "";
        },
      },
    },

    stroke: {
      width: [0, 2],
    },

    xaxis: {
      categories: reportformonth?.map((item) =>
        moment(item?.month).format("MMM")
      ),
      labels: {
        style: {
          colors: "#d4d4d4",
          fontSize: "12px",
        },
      },
    },
    legend: {
      position: "bottom",
      fontSize: "12px",
      labels: {
        colors: "#d4d4d4",
      },
    },
  };

  return (
    <div>
      {/* <pre className="text-white">
        {JSON.stringify(reportformonth, null, 3)}
      </pre> */}
      <Spin spinning={loading} wrapperClassName="costimize-loader">
        <ReactApexChart
          options={options}
          series={series}
          height={250}
          type="line"
        />
      </Spin>

      {/* <p className="m-0 p-0  fz-13 ">
        * The dotted line Represents the current Month
      </p> */}
    </div>
  );
}

export default LastSixMonthSpends;
