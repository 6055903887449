import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Tabs,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import Assetsperspective from "./assetsperspective/Assetsperspective";
import "./assetsperspective.css";
import { Icon } from "@iconify/react";
import A2CSidebar from "../a2c_rover/A2CSidebar";
import A2cFilter from "../a2c_rover/A2cFilter";
import AnalysisPerspective from "./AnalysisPerspective";
import PlanAndDesign from "./PlanAndDesign";
import TCO_Analysis from "./TCO_Analysis";
import CostimizeFilter from "../costimize_v2/layout/CostimizeFilter";
import { useDispatch } from "react-redux";
import { setIsShowTCOFilter } from "../slice/a2c_roverSlice";
import { useNavigate } from "react-router-dom";

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

function Assets() {
  const dispatch = useDispatch();
  const items = [
    {
      key: "5",
      label: `Assets Perspective`,
      children: <Assetsperspective />,
    },
    {
      key: "2",
      label: `Analysis Perspective`,
      children: <AnalysisPerspective />,
    },
    // {
    //   key: "3",
    //   label: `Plan and Design`,
    //   children: <PlanAndDesign />,
    // },
    // {
    //   key: "4",
    //   label: `TCO Analysis`,
    //   children: <TCO_Analysis />,
    // },
  ];
  const [open, setOpen] = useState(false);
  const [isShowFilter, setIsShowFilter] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [count, setCount] = useState(0);
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };
  const defaultColumns = [
    {
      title: "data_center_name",
      dataIndex: "data_center_name",
      editable: true,
    },
    {
      title: "application",
      dataIndex: "application",
      editable: true,
    },
    {
      title: "environment",
      dataIndex: "environment",
      editable: true,
    },
    {
      title: "operating_system",
      dataIndex: "operating_system",
      editable: true,
    },
    {
      title: "database_type",
      dataIndex: "database_type",
      editable: true,
    },
    {
      title: "vm_type",
      dataIndex: "vm_type",
      editable: true,
    },
    {
      title: "vcpu",
      dataIndex: "vcpu",
      editable: true,
    },
    {
      title: "memory",
      dataIndex: "memory",
      editable: true,
    },
    {
      title: "resource_name",
      dataIndex: "resource_name",
      editable: true,
    },
    {
      title: "customer",
      dataIndex: "customer",
      editable: true,
    },
    {
      title: "region",
      dataIndex: "region",
      editable: true,
    },
    {
      title: "modernization_type",
      dataIndex: "modernization_type",
      editable: true,
    },
    {
      title: "migration_complexity",
      dataIndex: "migration_complexity",
      editable: true,
    },
    {
      title: "cpu_as_is",
      dataIndex: "cpu_as_is",
      editable: true,
    },
    {
      title: "memory_as_is",
      dataIndex: "memory_as_is",
      editable: true,
    },
    {
      title: "storage_as_is",
      dataIndex: "storage_as_is",
      editable: true,
    },
    {
      title: "dr_implementation",
      dataIndex: "dr_implementation",
      editable: true,
    },
    {
      title: "migration_methodology",
      dataIndex: "migration_methodology",
      editable: true,
    },
    {
      title: "monitoring_stratergy",
      dataIndex: "monitoring_stratergy",
      editable: true,
    },
  ];
  const handleAdd = () => {
    const newData = {
      data_center_name: `Enter Data`,
      application: `Enter Data`,
      environment: `Enter Data`,
      operating_system: `Enter Data`,
      database_type: `Enter Data`,
      vm_type: `Enter Data`,
      vcpu: `Enter Data`,
      memory: `Enter Data`,
      resource_name: `Enter Data`,
      customer: `Enter Data`,
      region: `Enter Data`,
      modernization_type: `Enter Data`,
      migration_complexity: `Enter Data`,
      cpu_as_is: `Enter Data`,
      memory_as_is: `Enter Data`,
      storage_as_is: `Enter Data`,
      dr_implementation: `Enter Data`,
      migration_methodology: `Enter Data`,
      monitoring_stratergy: `Enter Data`,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const onChanges = (e) => {
    if (e == 4) {
      setIsShowFilter(false);
    } else {
      setIsShowFilter(true);
    }
    // dispatch(setIsShowTCOFilter(true));
  };
  const navigate = useNavigate();

  function HandleOpen() {
    navigate("/assetsnew");
  }
  return (
    <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
      <A2CSidebar />
      <main className="position-main-div" style={{ padding: 10, flex: 1 }}>
        {" "}
        <div className="cams-content">
          <div className="container-fluid">
            <div className="header-filter d-flex justify-content-between">
              <div className="header-title-a2c-main">Assess</div>
              <span className="d-flex align-items-end">
                {isShowFilter ? <A2cFilter /> : ""}
              </span>
            </div>

            <div className="tabss assets-perspective-tabs">
              <Button
                className="add-btn add-button-position"
                onClick={HandleOpen}
              >
                <Icon
                  icon="ic:round-plus"
                  style={{
                    fontSize: "25px",
                    cursor: "pointer",
                    border: "transparent",
                  }}
                ></Icon>{" "}
                New
              </Button>
              <Modal
                title="Assessment Table"
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                okButtonProps={{ className: "yes-button" }}
                cancelButtonProps={{ className: "no-btn" }}
                width="90%"
              >
                <div className="assess-cred-table">
                  <Button
                    onClick={handleAdd}
                    type="primary"
                    style={{
                      marginBottom: 16,
                    }}
                    className="yes-button"
                  >
                    <Icon
                      icon="ic:round-plus"
                      style={{
                        fontSize: "23px",
                        cursor: "pointer",
                        border: "transparent",
                      }}
                    ></Icon>
                    Add
                  </Button>
                  <Button
                    // onClick={handleAdd}
                    type="primary"
                    style={{
                      marginBottom: 16,
                    }}
                    className="yes-button"
                  >
                    <Icon
                      icon="ph:arrow-up"
                      style={{
                        fontSize: "23px",
                        cursor: "pointer",
                        border: "transparent",
                      }}
                    />
                    Upload
                  </Button>
                  <Button
                    // onClick={handleAdd}
                    type="primary"
                    style={{
                      marginBottom: 16,
                    }}
                    className="yes-button"
                  >
                    <Icon
                      icon="ph:arrow-down"
                      style={{
                        fontSize: "23px",
                        cursor: "pointer",
                        border: "transparent",
                      }}
                    />
                    Download
                  </Button>

                  <Table
                    components={components}
                    rowClassName={() => "editable-row"}
                    bordered
                    className="slm-table"
                    dataSource={dataSource}
                    columns={columns}
                  />
                </div>
              </Modal>
              <Tabs
                className="cams-tabs"
                defaultActiveKey="5"
                items={items}
                onChange={onChanges}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Assets;
