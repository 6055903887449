import { Modal, Space, Spin, Table } from "antd";
import { memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getUsageForMonthInfoTableApiData } from "../../action/costimizedashboardAction";
import { RxOpenInNewWindow } from "react-icons/rx";
import ExpandedSpendDetailTable from "./ExpandedSpendDetailTable";

const SpendDetailsView = (props) => {
  const [usageMonthTableData, setUsageMonthTableData] = useState([]);

  const [loader, setLoader] = useState(true);
  const [expandedKey, setExpandedKey] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedRowRecord, setClickedRowRecord] = useState("");
  const showModal = (record) => {
    setClickedRowRecord(record);
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setClickedRowRecord("");
    setIsModalOpen(false);
  };
  const onExpand = (_, { key }) =>
    expandedKey === key ? setExpandedKey(null) : setExpandedKey(key);

  const dispatch = useDispatch();
  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);

  useEffect(() => {
    setLoader(true);

    const apiVariablesUsgInfoTable = {
      screen: "qg",
      event_type: "usage info",
      environment: selectedEnvironment.toString(),
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      company: company_name,
      cloud: selectedCloud.toString(),
      db: selecteddb.toString(),
      infra: selectedinfra.toString(),
      month: selectedTopdate,
      os: selectedos.toString(),
      service: selectedservices.toString(),
    };
    // --api call for graph data-----
    dispatch(getUsageForMonthInfoTableApiData(apiVariablesUsgInfoTable))
      .unwrap()
      .then(({ data }) => {
        const jsonString = data.usageforthemonthinfo;
        // Parse the JSON-encoded string to a JavaScript object
        let resp = JSON.parse(jsonString);

        const addedKeyData = resp.usagemonthinfo_array.map((item, index) => {
          return {
            ...item,
            key: `ky${index + 1}`,
          }; // Add key "ky01", "ky02", ...
        });

        setUsageMonthTableData({
          usgTotal: resp.usageforthemonthtotal,
          usagemonthinfo_array: addedKeyData,
        });
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err?.message);
      });
    // --api call for card data-----
  }, [
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedservices,
    selectedEnvironment,
    selectedTopdate,
    selecteddb,
    selectedinfra,
    selectedos,
  ]);
  const currencyFormatter = (val) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD", // Change to your desired currency code
      minimumFractionDigits: 0, // Minimum number of fraction digits
      maximumFractionDigits: 2, // Maximum number of fraction digits
    }).format(Number(val));
  };
  const columns = [
    {
      title: "View",
      key: "clouds0ww1",
      className: "text-start",
      render: (_, record) => (
        // {record.account_name}
        <Space size="middle">
          <RxOpenInNewWindow
            type="button"
            size={18}
            onClick={() => showModal(record)}
          />
        </Space>
      ),
    },

    {
      title: "Cloud",
      dataIndex: "cloud",
      key: "clouds01",
      className: "text-start",
    },
    {
      title: "Account",
      dataIndex: "account_name",
      key: "accountname02",
      className: "text-start",
    },
    {
      title: "Spend($)",
      dataIndex: "spend",
      key: "spend03",
      className: "text-end",
      render: (text) =>
        new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 0, // Minimum number of fraction digits
          maximumFractionDigits: 2, // Maximum number of fraction digits
        }).format(Number(text)),
    },
  ];
  return (
    <div className="costdmaze-info-table pt-3 ">
      <Modal
        title={clickedRowRecord?.account_name}
        centered
        className="daywise-spend-modal"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="pt-3">
          {/* {JSON.stringify(clickedRowRecord, null, 4)} */}

          <ExpandedSpendDetailTable record={clickedRowRecord} />
        </div>
      </Modal>

      {/* {JSON.stringify(usageMonthTableData, null, 4)} */}
      <Spin spinning={loader} wrapperClassName="costimize-loader">
        {/* {JSON.stringify(tablerecords)} */}
        <Table
          size="medium"
          className="scroll-table"
          columns={columns}
          dataSource={usageMonthTableData?.usagemonthinfo_array}
          pagination={false}
          footer={() => currencyFormatter(usageMonthTableData.usgTotal)}
          // expandable={{
          //   expandedRowRender: (record) => (
          //     <ExpandedSpendDetailTable record={record} />
          //   ),
          //   rowExpandable: (record) => true,
          //   onExpand: onExpand,
          //   expandedRowKeys: [expandedKey],
          // }}
          // shouldCellUpdate={(record, prevRecord) =>
          //   !shallowEqual(record, prevRecord)
          // }
        />
        {/* <p className="fz-13 text-start  mt-2 mb-0 pb-0">
          <b>Note</b> : The value comprises of usage, refunds, purchases, and
          rounding adjustments, but excludes tax.
        </p> */}
      </Spin>
    </div>
  );
};
export default memo(SpendDetailsView);
