import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSummaryDashboard,
  getProjectedSpend,
} from "../../action/costimizedashboardAction";
import { listAnnualBudget } from "../../action/costimizev2DashboardAction";
import Spinner from "react-bootstrap/Spinner";
import { Spin } from "antd";

const SpendChart = (props) => {
  // const { companyName } = useSelector((state) => state.user);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const {
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const dispatch = useDispatch();
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [chartmonth, setChartMonth] = useState([]);
  const [chartspend, setChartSpend] = useState([]);
  const [chartbudget, setChartBudget] = useState([0, 0, 0]);
  const [preloader, setPreloader] = useState(true);

  useEffect(() => {
    //month
    var spend_status = false;
    var budget_status = false;

    let currentDate = new Date();
    let lastThreeMonthsDate = new Date();
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    const datelist = [];
    const month_list = [];
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      datelist.push(`${date.getFullYear()}-${date.getMonth() + 1}-1`);
      month_list.push(
        `${monthNamesShort[date.getMonth()]}-${("" + date.getFullYear()).substr(
          2
        )}`
      );
    }
    setChartMonth(month_list);
    const selectedTopdate_new = datelist.toString();
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
    };
    //spend
    dispatch(
      getAllSummaryDashboard({ companyName, selectedTopdate_new, apivalue })
    )
      .unwrap()
      .then(({ data }) => {
        spend_status = true;
        if (spend_status == true && budget_status == true) {
          setPreloader(false);
        }
        // props.setSpendrecords(data);
        var spend_list = [];
        for (let i = 0; i < 3; i++) {
          let date = new Date(
            lastThreeMonthsDate.getFullYear(),
            lastThreeMonthsDate.getMonth() + i,
            1
          );
          var spend = data.newfunctionquery3.filter((item) => {
            let spenddate = new Date(item.reportmonth);
            if (spenddate.getMonth() + 1 === date.getMonth() + 1) {
              return item;
            }
          });
          if (spend.length > 0) {
            spend_list.push(Math.round(spend[0].spend));
          } else {
            spend_list.push(0);
          }
        }
        setChartSpend(spend_list);
      })
      .catch((err) => {
        console.log(err.message);
      });

    //annual budget
    const cloud = selectedCloud.toString();
    dispatch(listAnnualBudget({ companyName, cloud }))
      .unwrap()
      .then(({ data }) => {
        budget_status = true;
        if (spend_status == true && budget_status == true) {
          setPreloader(false);
        }
        data.list_annual_budget.map((data) => {
          if (Number(data.budget_year) == new Date().getFullYear()) {
            //condition
            let currentDate = new Date();
            let lastThreeMonthsDate = new Date();
            lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
            const budget_records = [];
            for (let i = 0; i < 3; i++) {
              let date = new Date(
                lastThreeMonthsDate.getFullYear(),
                lastThreeMonthsDate.getMonth() + i,
                1
              );
              const records = getMonthBudget(
                `${date.getFullYear()}-${date.getMonth() + 1}-1`,
                data
              );
              budget_records.push(records);
            }
            setChartBudget(budget_records);
          }
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  ]);

  const getMonthBudget = (date, data) => {
    const current_month = new Date().getMonth(date);
    var allocated_buget = 0;
    if (current_month === 1) {
      allocated_buget = data.budget_jan;
    } else if (current_month === 2) {
      allocated_buget = data.budget_feb;
    } else if (current_month === 3) {
      allocated_buget = data.budget_mar;
    } else if (current_month === 4) {
      allocated_buget = data.budget_apr;
    } else if (current_month === 5) {
      allocated_buget = data.budget_may;
    } else if (current_month === 6) {
      allocated_buget = data.budget_jun;
    } else if (current_month === 7) {
      allocated_buget = data.budget_jul;
    } else if (current_month === 8) {
      allocated_buget = data.budget_aug;
    } else if (current_month === 9) {
      allocated_buget = data.budget_sept;
    } else if (current_month === 10) {
      allocated_buget = data.budget_oct;
    } else if (current_month === 11) {
      allocated_buget = data.budget_nov;
    } else {
      allocated_buget = data.budget_dec;
    }
    return allocated_buget;
  };
  const options = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      width: [0, 2],
    },
    colors: ["#c0d0d7", "#21c18b"],

    title: {
      show: false,
      curve: "smooth",
      text: "",
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
      formatter: (num) => {
        return Math.abs(num) > 999
          ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
          : Math.sign(num) * Math.abs(num);
      },
    },
    labels: chartmonth,
    xaxis: {
      labels: {
        style: {
          fontWeight: "bold",
        },
      },
    },
    legend: {
      fontWeight: "bold",
    },
    yaxis: {
      labels: {
        style: {
          fontWeight: "bold",
        },
        formatter: (num) => {
          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
  };

  const series = [
    {
      name: "Spend",
      type: "column",
      data: chartspend,
    },
    {
      name: "Budget",
      type: "line",
      data: chartbudget,
    },
  ];
  return (
    <>
      <Spin tip="Loading..." spinning={preloader} className="plat-spinning">
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={220}
        />
      </Spin>
    </>
  );
};
export default SpendChart;
