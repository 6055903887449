import React, { memo, useState } from "react";
import { Col, Modal, Row } from "antd";
import { headercustomerlogo } from "../.././action/header_logo";
import "./Filter.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Icon } from "@iconify/react";
import {
  getForecastSpend,
  getSpendDetails,
  listApplicationSpend,
  listInstanceTracking,
  taggingResources,
} from "../../action/costimizeV2Active";

import CostimazeDatePicker from "./CostimazeDatePicker";
import moment from "moment";
const QuickHead = (props) => {
  const [shortmonth, setShortMonth] = useState("current_month");
  const dispatch = useDispatch();
  const [company_logo, setCompanyLogo] = useState();
  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [insightsApplication, setInsightsApplication] = useState("None");
  const [insightsForecast, setInsightsForecast] = useState(0);
  const [insightsAvgSix, setInsightsAvgSix] = useState(0);
  const [insightsUntagged, setInsightsUntagged] = useState("0");
  const {
    selectedTopdate,
    selectedAccount,
    selectedCloud,
    selectedEnvironment,
    selectedservices,
    selecteddb,
    selectedinfra,
    selectedos,
  } = useSelector((state) => state.constimize);
  const [monthlist, setMonthList] = useState({
    last: "",
    current: "",
    custom_month: "",
  });

  const handleInsights = () => {
    setIsModalOpen(true);
    let currentDate = new Date(selectedTopdate);
    let lastSixMonthsDate = new Date(selectedTopdate);
    lastSixMonthsDate.setMonth(currentDate.getMonth() - 6);
    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month_list = [];
    var datelist = [];
    for (let i = 0; i < 7; i++) {
      let date = new Date(
        lastSixMonthsDate.getFullYear(),
        lastSixMonthsDate.getMonth() + i,
        1
      );
      month_list.push(
        `${monthNamesShort[date.getMonth()]}-${("" + date.getFullYear()).substr(
          2
        )}`
      );
      datelist.push(`${date.getFullYear()}-${date.getMonth() + 1}-1`);
    }
    // console.log(datelist);
    var apivalue = {
      account: "all",
      account_name: "all",
      application: "all",
      cloud: "all",
      db: "all",
      environment: "all",
      infra: "all",
      date: datelist.toString(),
      month: datelist.toString(),
      os: "all",
      services: "all",
      company_name: company_name,
    };
    const companyName = company_name;
    const selectedTopdate_new = apivalue.date;
    const input = apivalue;
    var projected_date = [
      `${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`,
    ];
    projected_date = projected_date.toString();
    dispatch(getForecastSpend({ companyName, projected_date, apivalue }))
      .unwrap()
      .then(({ data }) => {
        var tempprojectspend = data.newfunctionquery8?.reduce(
          (a, v) => (a = +Number(a) + +Number(v.projected_spend)),
          0
        );
        setInsightsForecast(Math.round(tempprojectspend));
        // console.log("Forecast");
        // console.log(Math.round(tempprojectspend));
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(listInstanceTracking({ apivalue }))
      .unwrap()
      .then(({ data }) => {
        const statusCounts = data.newfunctionquery15.reduce(
          (accumulator, currentValue) => {
            const valueStatus = currentValue.status;
            accumulator[valueStatus] = (accumulator[valueStatus] || 0) + 1;
            return accumulator;
          },
          {}
        );
      })
      .catch((err) => {
        console.log(err.message);
      });
    // console.log(data);
    // const list = data.newfunctionquery15.map((list_data, index) => {}
    dispatch(getSpendDetails({ companyName, selectedTopdate_new, apivalue }))
      .unwrap()
      .then(({ data }) => {
        const spendData = data.newfunctionquery3.map((item) =>
          parseFloat(item.spend)
        );
        const avgofSix =
          spendData
            .slice(0, -1)
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            ) / 6;
        setInsightsAvgSix(avgofSix);
        // console.log("Average");
        // console.log(avgofSix);
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(listApplicationSpend({ companyName, apivalue }))
      .unwrap()
      .then(({ data }) => {
        // console.log("raw data from api");
        // console.log(data.newfunctionquery16);
      })
      .catch((err) => {
        console.log(err);
      });

    input.month = selectedTopdate;
    dispatch(taggingResources({ input }))
      .unwrap()
      .then(({ data }) => {
        const mandatory_tag = data.newfunctionquery2?.filter(
          (v) => v.tag_category === "Mandatory-tag"
        );
        //tagging count
        const tagged_records = mandatory_tag?.filter(
          (v) => v.tagging_details === "tagged"
        );
        const tagged_records_count = tagged_records?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );
        //partially count
        const partially_records = mandatory_tag?.filter(
          (v) => v.tagging_details === "partially_tagged"
        );
        const partially_records_count = partially_records?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );
        //untagged
        const untagged_records = mandatory_tag?.filter(
          (v) => v.tagging_details === "Untagged"
        );
        const untagged_records_count = untagged_records?.reduce(
          (a, v) => (a = +a + +v.count_tag_category),
          0
        );
        const percentage_value =
          (tagged_records_count * 100) /
          (tagged_records_count +
            partially_records_count +
            untagged_records_count);

        setInsightsUntagged(
          Number.isNaN(percentage_value)
            ? 100
            : (100 - percentage_value).toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })
        );
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const selecteddate = new Date(selectedTopdate);
    const selectedmonth = selecteddate.getMonth() + 1;
    const selectedyear = selecteddate.getFullYear();

    const currentddate = new Date();
    const currentmonth = currentddate.getMonth() + 1;
    const currentyear = currentddate.getFullYear();

    setMonthList({
      last:
        currentddate.getMonth() === 0
          ? `${monthNames[11]} ${currentyear - 1}` // If current month is January, set to December of previous year
          : `${monthNames[currentddate.getMonth() - 1]} ${currentyear}`, // Otherwise, set to last month of current year
      current: `${monthNames[currentddate.getMonth()]} ${currentyear}`,
      custom_month: `${monthNames[selecteddate.getMonth()]} ${selectedyear}`,
    });

    if (selectedmonth === currentmonth && selectedyear === currentyear) {
      setShortMonth("current_month");
    } else if (
      selectedmonth === currentddate.getMonth() &&
      selectedyear === currentyear
    ) {
      setShortMonth("last_month");
    } else {
      setShortMonth("custom_month");
    }

    // }
  }, [selectedTopdate]);
  useEffect(() => {
    dispatch(headercustomerlogo({ company_name }))
      .unwrap()
      .then(({ data }) => {
        var encodedStringAtoB = data.getCustomerLogo.platform_logo;
        var testimg = `data:image/svg+xml;base64,${encodedStringAtoB}`;
        setCompanyLogo(testimg);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // const dataAsOf = () => {
  //   return `  ${
  //     moment(selectedTopdate).isSame(new Date(), "month")
  //       ? moment().subtract(3, "days").format("MMM DD")
  //       : moment(selectedTopdate).endOf("month").format("MMM DD")
  //   } `;
  // };
  // console.log(dataAsOf());
  // const dataAsOf = () => {
  //   const threeDaysBefore = moment().subtract(3, "days").format("MMM DD");

  //   return `  ${
  //     moment().isSame(new Date(), "month")
  //       ? threeDaysBefore
  //       : moment().endOf("month").format("MMM DD")
  //   } `;
  // };
  const dataAsOf = () => {
    const currentDate = moment();
    const threeDaysBefore = currentDate.subtract(3, "days").format("MMM DD");
    return `  ${
      moment(selectedTopdate).isSame(currentDate, "month")
        ? threeDaysBefore
        : moment(selectedTopdate).endOf("month").format("MMM DD")
    } `;
  };

  return (
    <>
      <div className="costimize-header-body-quick">
        <Row className="mt-2">
          <Col
            xxl={12}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={24}
            className={
              props.type === "slm"
                ? "header-title site-color slm-color"
                : "header-title site-color"
            }
          >
            {props.title}
            {/* <Icon
              icon="ic:twotone-info"
              style={{
          
                fontSize: "19px",
                color: "#848484",
                cursor: "pointer",
              }}
              onClick={handleInsights}
            /> */}
            <span className="text-small"> *as of {dataAsOf()}</span>
            <Modal
              title="Quick Insights"
              className="costmodal"
              deletestatus={false}
              footer={null}
              open={isModalOpen}
              onCancel={() => {
                setIsModalOpen(false);
              }}
            >
              <p>
                This month's spending is{" "}
                <strong>
                  {insightsForecast > insightsAvgSix ? "Higher" : "Lower"}
                </strong>{" "}
                than the previous six months.
              </p>
              <p>
                <strong>{insightsApplication}</strong> seems to spend more than
                usual.
              </p>
              <p>
                <strong>{insightsUntagged}</strong> of the resource remain
                untagged.
              </p>
              <p>
                There currently <strong> 96 </strong> Running,{" "}
                <strong> 4</strong> Modified and <strong>2</strong> Terminated
                instances.
              </p>
              <p>
                <strong>$60,238</strong> can be saved by making{" "}
                <strong>fsg_prod_empl_portal_az1</strong> into an RI.
              </p>
            </Modal>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
            <div className="header-logo-block">
              {company_logo && (
                <img className="costimize_header_logo" src={company_logo} />
              )}
            </div>
          </Col>
        </Row>
        {/* <Row className="mt-3">
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <div className="header-filter-block">
              <div>
                <ul
                  className={
                    props.type == "slm"
                      ? "header-filter-list slm-ul-li slmdate"
                      : "header-filter-list"
                  }
                >
                  <li>
                    <CostimazeDatePicker />
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row> */}
      </div>
    </>
  );
};
export default QuickHead;
