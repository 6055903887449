import DrawerPlat from "../../properties/Drawer/DrawerPlat";
import { Row, Col } from 'react-bootstrap';

const SavingsTracking = (props) => {
    const changeDrawerStatus = () => {
        props.setSavingsTrackingStatus(false);
    }
    return(
        <>
            <DrawerPlat 
                drawerstatus={props.savingstrackingstatus}
                changeDrawerStatus={changeDrawerStatus}
                deletestatus={false}
                title="Tracking"
            > 
                <div className="tracking-drawer-body">
                    <div className="tracking-drawer-list">
                        <Row>
                            <Col lg={3}>
                                <h1 className="tracking-date">12-1-2023</h1>
                            </Col>
                            <Col lg={9}>
                                <h1 className="tracking-content"> karthick : Savings Created</h1>
                            </Col>
                        </Row>
                       
                        
                    </div>
                </div>  
            </DrawerPlat>
        </>
    )
}

export default SavingsTracking;