import { memo, useState } from "react";
import { Col, Row, Spin, Tooltip } from "antd";
import { Icon } from "@iconify/react";
import ToggleButtonV2 from "../../properties/accordion/ToggleButtonV2";
import AssetTrendChart from "./AssetTrendChart";
import DetailsView from "./DetailsView";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssetSpendVMRecords } from "../../action/costimizeV2Active";
import {
  titleCase,
  numberFormat,
  capitalizeFirst,
} from "../../custom_hook/CustomHook";
import { filterAssetandSpendList } from "../../action/costimizedashboardAction";

const OldRecords = (props) => {
  const {
    applicationList,
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selecteddb,
    selectedEnvironment,
    selectedinfra,
    selectedTopdate,
    selectedos,
    selectedservices,
    environmentList,
    dummydatastatus,
  } = useSelector((state) => state.constimize);
  // const { companyName } = useSelector((state) => state.user);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const dispatch = useDispatch();
  const [countchart, setCountChart] = useState([]);
  const [spendchart, setSpendChart] = useState([]);
  const [tablerecords, setTableRecords] = useState([]);

  const [searchapplication, setSearchApplication] = useState("");

  const [barchartvalue, setBarChartValue] = useState([]);
  const [countchartcategories, setCountChartCategories] = useState([]);
  const [countchartvm, setChartVm] = useState([]);
  const [countchartdbaas, setChartDbass] = useState([]);
  const [countchartothers, setChartOthers] = useState([]);
  const [barcharttype, setBarChartType] = useState("vm");
  //chart status
  const [countstatus, setCountStatus] = useState(false);
  const [spendstatus, setSpendStatus] = useState(false);
  const [vmexpanddetails, setVmExpandDetails] = useState({
    application: "",
    environment: "",
    cloud: "",
  });

  const [applicationviewstatus, setApplicationViewStatus] = useState(false);
  const [vmviewstatus, setVmViewStatus] = useState(false);
  const [preloader, setPreloader] = useState(true);
  //accourdion records
  const [allenvironmentdetails, setAllEnvironmentDetails] = useState([
    { type: "VM", count: 0, spend: 0 },
    { type: "DB", count: 0, spend: 0 },
    { type: "Others", count: 0, spend: 0 },
  ]);
  const [listenvironmentdetails, setListEnvironmentDetails] = useState([]);
  const [assetspenddata, setAssetSpendData] = useState([]);

  const getCloudList = (list) => {
    var cloudlist = ["aws", "azure", "gcp"];
    var envtemp = [];
    cloudlist.map((envdata, index) => {
      // console.log(list);
      const listenvironmentfillter = list.filter((datas) => {
        return datas.all_cloud?.toLowerCase() === envdata?.toLowerCase();
      });

      // console.log("listenvironmentfillter");
      // console.log(listenvironmentfillter);
      var applicationlist = getApplicationList(listenvironmentfillter);
      //  console.log("applicationlist");
      // console.log(applicationlist);
      //vm
      const environmentvmfiltered = listenvironmentfillter.filter((datas) => {
        return datas.infras === "VM";
      });
      var environmentvm = { type: "VM", count: 0, spend: 0 };
      if (environmentvmfiltered.length > 0) {
        environmentvm = {
          type: "VM",
          count: environmentvmfiltered.reduce((a, v) => (a = +a + +v.count), 0),
          spend: environmentvmfiltered.reduce((a, v) => (a = +a + +v.spend), 0),
        };
      }

      //db
      const environmentdbfiltered = listenvironmentfillter.filter((datas) => {
        return datas.infras === "DB";
      });
      var environmentdb = { type: "DB", count: 0, spend: 0 };
      if (environmentdbfiltered.length > 0) {
        environmentdb = {
          type: "DB",
          count: environmentdbfiltered.reduce((a, v) => (a = +a + +v.count), 0),
          spend: environmentdbfiltered.reduce((a, v) => (a = +a + +v.spend), 0),
        };
      }
      //others
      const environmentothersfiltered = listenvironmentfillter.filter(
        (datas) => {
          return datas.infras === "Others";
        }
      );
      var environmentothers = { type: "Others", count: 0, spend: 0 };
      if (environmentothersfiltered.length > 0) {
        environmentothers = {
          type: "Others",
          count: environmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.count),
            0
          ),
          spend: environmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.spend),
            0
          ),
        };
      }
      if (
        environmentvmfiltered.length > 0 ||
        environmentdbfiltered.length > 0 ||
        environmentothersfiltered.length > 0
      ) {
        const envvalue =
          props.type === "db" ? environmentdb : environmentothers;
        envtemp.push([envdata, envvalue, [applicationlist]]);
      }
    });
    // console.log("envtemp");
    // console.log(envtemp);
    return envtemp;
  };

  const getApplicationList = (list) => {
    // console.log(list);

    var cloudlist = applicationList;
    var envtemp = [];
    cloudlist.map((envdata, index) => {
      // console.log(envdata);
      const listenvironmentfillter = list.filter((datas) => {
        // console.log("envdata");
        // console.log(envdata);
        return datas.applications?.toLowerCase() === envdata?.toLowerCase();
      });
      // console.log(listenvironmentfillter);
      //vm
      const environmentvmfiltered = listenvironmentfillter.filter((datas) => {
        return datas.infras === "VM";
      });
      var environmentvm = { type: "VM", count: 0, spend: 0 };
      if (environmentvmfiltered.length > 0) {
        environmentvm = {
          type: "VM",
          count: environmentvmfiltered.reduce((a, v) => (a = +a + +v.count), 0),
          spend: environmentvmfiltered.reduce((a, v) => (a = +a + +v.spend), 0),
        };
      }

      //db
      const environmentdbfiltered = listenvironmentfillter.filter((datas) => {
        return datas.infras === "DB";
      });
      var environmentdb = { type: "DB", count: 0, spend: 0 };
      if (environmentdbfiltered.length > 0) {
        environmentdb = {
          type: "DB",
          count: environmentdbfiltered.reduce((a, v) => (a = +a + +v.count), 0),
          spend: environmentdbfiltered.reduce((a, v) => (a = +a + +v.spend), 0),
        };
      }
      //others
      const environmentothersfiltered = listenvironmentfillter.filter(
        (datas) => {
          return datas.infras === "Others";
        }
      );
      var environmentothers = { type: "Others", count: 0, spend: 0 };
      if (environmentothersfiltered.length > 0) {
        environmentothers = {
          type: "Others",
          count: environmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.count),
            0
          ),
          spend: environmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.spend),
            0
          ),
        };
      }
      if (
        environmentvmfiltered.length > 0 ||
        environmentdbfiltered.length > 0 ||
        environmentothersfiltered.length > 0
      ) {
        const envvalue =
          props.type === "db" ? environmentdb : environmentothers;
        envtemp.push([capitalizeFirst(envdata), envvalue]);
      }
    });
    return envtemp.sort((a, b) =>
      a[0].toLowerCase() < b[0].toLowerCase() ? -1 : 1
    );
  };

  //asset and spend details

  useEffect(() => {
    setPreloader(true);
    // console.log(preloader);
    const apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
    };

    // console.log(apivalue);
    dispatch(
      filterAssetandSpendList({ companyName, selectedTopdate, apivalue })
    )
      .unwrap()
      .then(({ data }) => {
        setAssetSpendData(data.newfunctionquery);
        //all environment
        //vm
        const allenvironmentvmfiltered = data.newfunctionquery?.filter(
          (datas) => {
            return datas.infras === "VM";
          }
        );
        var allenvironmentvm = { type: "VM", count: 0, spend: 0 };
        if (allenvironmentvmfiltered?.length > 0) {
          allenvironmentvm = {
            type: "VM",
            count: allenvironmentvmfiltered.reduce(
              (a, v) => (a = +a + +v.count),
              0
            ),
            spend: allenvironmentvmfiltered.reduce(
              (a, v) => (a = +a + +v.spend),
              0
            ),
          };
        }
        //db
        const allenvironmentdbfiltered = data.newfunctionquery?.filter(
          (datas) => {
            return datas.infras === "DB";
          }
        );
        var allenvironmentdb = { type: "DB", count: 0, spend: 0 };
        if (allenvironmentdbfiltered?.length > 0) {
          allenvironmentdb = {
            type: "DB",
            count: allenvironmentdbfiltered.reduce(
              (a, v) => (a = +a + +v.count),
              0
            ),
            spend: allenvironmentdbfiltered.reduce(
              (a, v) => (a = +a + +v.spend),
              0
            ),
          };
        }
        //others
        const allenvironmentothersfiltered = data.newfunctionquery?.filter(
          (datas) => {
            return datas.infras === "Others";
          }
        );
        var allenvironmentothers = { type: "Others", count: 0, spend: 0 };
        if (allenvironmentothersfiltered?.length > 0) {
          allenvironmentothers = {
            type: "Others",
            count: allenvironmentothersfiltered.reduce(
              (a, v) => (a = +a + +v.count),
              0
            ),
            spend: allenvironmentothersfiltered.reduce(
              (a, v) => (a = +a + +v.spend),
              0
            ),
          };
        }

        const allenvspend =
          props.type === "db" ? allenvironmentdb : allenvironmentothers;
        setAllEnvironmentDetails(allenvspend);

        var envtemp = [];
        //environment list
        environmentList.map((envdata, index) => {
          const listenvironmentfillter = data.newfunctionquery?.filter(
            (datas) => {
              return (
                datas.environments?.toLowerCase() === envdata?.toLowerCase()
              );
            }
          );
          var allcloudlist = getCloudList(listenvironmentfillter);
          //vm
          const environmentvmfiltered = listenvironmentfillter?.filter(
            (datas) => {
              return datas.infras === "VM";
            }
          );
          var environmentvm = { type: "VM", count: 0, spend: 0 };
          if (environmentvmfiltered.length > 0) {
            environmentvm = {
              type: "VM",
              count: environmentvmfiltered.reduce(
                (a, v) => (a = +a + +v.count),
                0
              ),
              spend: environmentvmfiltered.reduce(
                (a, v) => (a = +a + +v.spend),
                0
              ),
            };
          }

          //db
          const environmentdbfiltered = listenvironmentfillter?.filter(
            (datas) => {
              return datas.infras === "DB";
            }
          );
          var environmentdb = { type: "DB", count: 0, spend: 0 };
          if (environmentdbfiltered.length > 0) {
            environmentdb = {
              type: "DB",
              count: environmentdbfiltered.reduce(
                (a, v) => (a = +a + +v.count),
                0
              ),
              spend: environmentdbfiltered.reduce(
                (a, v) => (a = +a + +v.spend),
                0
              ),
            };
          }
          //others
          const environmentothersfiltered = listenvironmentfillter?.filter(
            (datas) => {
              return datas.infras === "Others";
            }
          );
          var environmentothers = { type: "Others", count: 0, spend: 0 };
          if (environmentothersfiltered.length > 0) {
            environmentothers = {
              type: "Others",
              count: environmentothersfiltered.reduce(
                (a, v) => (a = +a + +v.count),
                0
              ),
              spend: environmentothersfiltered.reduce(
                (a, v) => (a = +a + +v.spend),
                0
              ),
            };
          }
          const envvlaue =
            props.type === "db" ? environmentdb : environmentothers;
          envtemp.push([capitalizeFirst(envdata), envvlaue, [allcloudlist]]);
        });
        // console.log(envtemp);
        setListEnvironmentDetails(envtemp);

        var appcountlist = [];
        var appspendlist = [];
        var tablenewrecords = [];
        var categories = [];
        var chartvm = [];
        var chartdbaas = [];
        var chartothers = [];
        var barchartvalue = [];
        applicationList.map((appdata, index) => {
          const listenvironmentfillter = data.newfunctionquery.filter(
            (datas) => {
              return datas.applications === appdata;
            }
          );

          //vm
          const allenvironmentvmfiltered = listenvironmentfillter.filter(
            (datas) => {
              return datas.infras === "VM";
            }
          );
          const vmcount = allenvironmentvmfiltered.reduce(
            (a, v) => (a = +a + +v.count),
            0
          );
          const vmspend = allenvironmentvmfiltered.reduce(
            (a, v) => (a = +a + +v.spend),
            0
          );
          //db
          const allenvironmentdbfiltered = listenvironmentfillter.filter(
            (datas) => {
              return datas.infras === "DB";
            }
          );
          const dbcount = allenvironmentdbfiltered.reduce(
            (a, v) => (a = +a + +v.count),
            0
          );
          const dbspend = allenvironmentdbfiltered.reduce(
            (a, v) => (a = +a + +v.spend),
            0
          );
          //others
          const allenvironmentothersfiltered = listenvironmentfillter.filter(
            (datas) => {
              return datas.infras === "Others";
            }
          );
          const otherscount = allenvironmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.count),
            0
          );
          const othersspend = allenvironmentothersfiltered.reduce(
            (a, v) => (a = +a + +v.spend),
            0
          );
          const totalspend = +vmspend + +dbspend + +othersspend;
          const totalcount = +vmcount + +dbcount + +otherscount;
          // console.log(titleCase(appdata));
          appcountlist.push({
            vm: vmcount,
            others: dbcount,
            db: otherscount,
            name: titleCase(appdata),
          });
          categories.push(titleCase(appdata));
          chartvm.push(vmcount);
          chartdbaas.push(dbcount);
          chartothers.push(otherscount);
          appspendlist.push({
            vm: vmspend,
            others: othersspend,
            db: dbspend,
            name: titleCase(appdata),
          });

          tablenewrecords.push({
            "Application Title": titleCase(appdata),
            "VM Count": vmcount,
            "VM Spend": vmspend,
            "DBaaS Count": dbcount,
            "DBaaS Spend": dbspend,
            "Others Count": otherscount,
            "Others Spend": othersspend,
          });
          barchartvalue.push({
            categories: titleCase(appdata),
            vmcount: vmcount,
            dbcount: dbcount,
            otherscount: otherscount,
          });
        });

        // console.log(tablenewrecords);
        // console.log("table");

        setBarChartValue(barchartvalue);
        setCountChartCategories(categories);
        setChartVm(chartvm);
        setChartDbass(chartdbaas);
        setChartOthers(chartothers);

        props.setChartAllSpend(appspendlist);
        props.setChartAllCount(appcountlist);

        setCountChart(appcountlist);
        setSpendChart(appspendlist);
        setTableRecords(tablenewrecords);
        setPreloader(false);
      })
      .catch((err) => {
        console.log(err.message);
        setPreloader(false);
      });
  }, [
    applicationList,
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selecteddb,
    selectedEnvironment,
    selectedinfra,
    selectedTopdate,
    selectedos,
    selectedservices,
    environmentList,
    props,
  ]);

  const barChartView = (type) => {
    setBarChartType(type);
  };
  const viewAssetAndSpendVmDetails = (data) => {
    setVmViewStatus(true);
    setVmExpandDetails({
      application: data.application,
      cloud: data.cloud,
      environment: data.environment,
    });
  };

  return <></>;
};

export default memo(OldRecords);
