import { Col, Row } from "antd";
import React from "react";
// import company_logo from "../../assets/images/company_logo.png";
import logo from "../../assets/images/company_logo.png";
import cmacgm_logo from "../../assets/images/cmacgm_logo_new.png";
import { useEffect, useState } from "react";

function PeemsHeader() {
  const [company_logo, setCompanyLogo] = useState();
  useEffect(() => {
    const demo_type = localStorage.getItem(
      "CognitoIdentityServiceProvider.demo"
    );
    const company_name = localStorage.getItem(
      "CognitoIdentityServiceProvider.company"
    );
    if (demo_type == "true") {
      setCompanyLogo();
    } else {
      if (company_name === "cmacgm") {
        setCompanyLogo(cmacgm_logo);
      } else if (company_name === "fivestar") {
        setCompanyLogo(logo);
      }
    }
  }, []);
  return (
    <>
      <div className="container-fluid cams-header-body">
        <Row className="mt-2">
          <Col
            xxl={12}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={24}
            className="header-title header-color"
          >
            Event Express(EE)
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={24}>
            <div className="header-logo-block">
              {company_logo && (
                <img className="costimize_header_logo" src={company_logo} />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PeemsHeader;
