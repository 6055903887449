import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsageForMonthInfoTableApiData,
  getUsageForTheMonthTypeInfo,
} from "../../action/costimizedashboardAction";
import { Table } from "antd";

function ExpandedSpendDetailTable({ record }) {
  const [usageMonthExpandedTableData, setUsageMonthExpandedTableData] =
    useState([]);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);

  useEffect(() => {
    setLoader(true);
    const apiVariablesUsgInfoTable = {
      screen: "qg",
      event_type: "usage account info",
      environment: selectedEnvironment.toString(),
      account_name: record.account_name || selectedAccount.toString(),
      application: selectedApplication.toString(),
      company: company_name,
      cloud: selectedCloud.toString(),
      db: selecteddb.toString(),
      infra: selectedinfra.toString(),
      month: selectedTopdate,
      os: selectedos.toString(),
      service: selectedservices.toString(),
    };

    // --api call for graph data-----
    dispatch(getUsageForTheMonthTypeInfo(apiVariablesUsgInfoTable))
      .unwrap()
      .then(({ data }) => {
        const jsonString = data.usageforthemonthtypeinfo;
        // Parse the JSON-encoded string to a JavaScript object
        let resp = JSON.parse(jsonString);
        setUsageMonthExpandedTableData(resp);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err?.message);
      });
    // --api call for card data-----
  }, [
    record,
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedservices,
    selectedEnvironment,
    selectedTopdate,
    selecteddb,
    selectedinfra,
    selectedos,
  ]);
  const columns = [
    {
      title: "Cloud",
      dataIndex: "cloud",
      key: "clouds01",
    },
    {
      title: "Charge Type",
      dataIndex: "charge_type",
      key: "charge_type",
    },
    {
      title: "Spend($)",
      dataIndex: "spend",
      className: "text-end",
      key: "spend03",
      render: (text) =>
        new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 0, // Minimum number of fraction digits
          maximumFractionDigits: 2, // Maximum number of fraction digits
        }).format(Number(text)),
    },
  ];
  return (
    <div className="costdmaze-info-table inner">
      <Table
        columns={columns}
        dataSource={usageMonthExpandedTableData}
        pagination={{ pageSize: 5, size: "small" }}
        loading={loader}
      />
    </div>
  );
}

export default ExpandedSpendDetailTable;
