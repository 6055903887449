import React, { useEffect, useState } from "react";

import moment from "moment";

import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppDecreaseChartApiData,
  getAppIncreaseChartApiData,
} from "../../../action/accountAction";
import Top5DecreaseSpend from "./Top5DecreaseSpend";
import Top5IncreaseSpend from "./Top5IncreaseSpend";
function TopFiveSpendContainer() {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 3);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const filterActiveTab = useSelector(
    (state) => state.costimizespend.QuickGlancePageTop5SpendFilterActiveTab
  );
  const [reportweekwiseappsuminc, setReportweekwiseappsuminc] = useState([]);
  const [reportweekwiseappsumdec, setReportweekwiseappsumdec] = useState([]);

  const today = moment();

  const todayWeekday = today.isoWeekday();
  const daysToLastSaturday = todayWeekday < 7 ? todayWeekday + 1 : 0;

  const defaultStartDate = today
    .subtract(daysToLastSaturday + 7, "days")
    .format("YYYY-MM-DD");

  const defaultEndDate = moment(defaultStartDate)
    .add(6, "days")
    .format("YYYY-MM-DD");
  const [selectedDate, setSelectedDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const start_date = selectedDate;
  const end_date = endDate;
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);

  useEffect(() => {
    const endDateNsub3 = () => {
      return `  ${
        moment(selectedTopdate).isSame(new Date(), "month")
          ? moment().subtract(3, "days").format("YYYY-MM-DD")
          : moment(selectedTopdate).endOf("month").format("YYYY-MM-DD")
      } `;
    };
    // //weekly_report_app_week_wise_top5_increase
    setLoading(true);
    const apiVariablesIncreaseChart = {
      event_type: "application increase",
      screen: "qg",
      company: company_name,
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      cloud: selectedCloud.toString(),
      date: endDateNsub3(),
      db: selecteddb.toString(),
      environment: selectedEnvironment.toString(),
      infra: selectedinfra.toString(),
      os: selectedos.toString(),
      service: selectedservices.toString(),
    };

    dispatch(getAppIncreaseChartApiData(apiVariablesIncreaseChart))
      .unwrap()
      .then(({ data }) => {
        // Handle the response data as needed
        const jsonString = data.app_increasechart_qg;
        // Parse the JSON-encoded string to a JavaScript object
        let resp = JSON.parse(jsonString);

        const convertedData = resp.map((item) => {
          const currentWeek = item.fourth_week || null;
          const previousWeek = item.third_week || null;
          const prePreviousWeek = item.secound_week || null;
          const fourthWeek = item.first_week || null;
          return {
            current_week: currentWeek,
            environment: item.environment,
            previous_week: previousWeek,
            pre_previous_week: prePreviousWeek,
            fourth_week: fourthWeek,
            application: item.application,
            difference: item.difference,
          };
        });
        setReportweekwiseappsuminc(convertedData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err?.message);
        setLoading(false);
      });

    // //weekly_report_app_week_wise_top5_decrease

    const apiVariablesAppDecreaseChart = {
      event_type: "application decrease",
      screen: "qg",
      company: company_name,
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      cloud: selectedCloud.toString(),
      date: endDateNsub3(),
      db: selecteddb.toString(),
      environment: selectedEnvironment.toString(),
      infra: selectedinfra.toString(),
      os: selectedos.toString(),
      service: selectedservices.toString(),
    };

    dispatch(getAppDecreaseChartApiData(apiVariablesAppDecreaseChart))
      .unwrap()
      .then(({ data }) => {
        // Handle the response data as needed
        const jsonString = data.app_decreasechart_qg;
        // Parse the JSON-encoded string to a JavaScript object
        let resp = JSON.parse(jsonString);

        const convertedData = resp.map((item) => {
          const currentWeek = item.fourth_week || null;
          const previousWeek = item.third_week || null;
          const prePreviousWeek = item.secound_week || null;
          const fourthWeek = item.first_week || null;
          return {
            current_week: currentWeek,
            environment: item.environment,
            previous_week: previousWeek,
            pre_previous_week: prePreviousWeek,
            fourth_week: fourthWeek,
            application: item.application,
            difference: item.difference,
          };
        });
        setReportweekwiseappsumdec(convertedData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err?.message);
        setLoading(false);
      });
  }, [
    selectedTopdate,
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selecteddb,
    selectedEnvironment,
    selectedinfra,
    selectedos,
    selectedservices,
  ]);
  return (
    <div>
      <div className="px-3 pt-3">
        {/* <pre className="text-white">
          {" "}
          {JSON.stringify(reportweekwiseappsuminc, null, 4)}
        </pre> */}

        <Spin spinning={loading} wrapperClassName="costimize-loader">
          {filterActiveTab.increaseFilter ? (
            <Top5IncreaseSpend
              applicationlevelspentmore={reportweekwiseappsuminc}
              selectedDate={selectedDate}
              endDate={endDate}
            />
          ) : filterActiveTab.decreaseFilter ? (
            <Top5DecreaseSpend
              applicationlevelspendless={reportweekwiseappsumdec}
              selectedDate={selectedDate}
              endDate={endDate}
            />
          ) : (
            "Loading..."
          )}
        </Spin>
      </div>
    </div>
  );
}

export default TopFiveSpendContainer;
