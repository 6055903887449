import React, { useEffect } from "react";
import { memo, useState } from "react";
import {
  Table,
  Spin,
  Popconfirm,
  Modal,
  Space,
  Form,
  Input,
  Row,
  Col,
  Button,
} from "antd";
import { capitalizeFirst } from "../../custom_hook/CustomHook";
import { useDispatch, useSelector } from "react-redux";
import {
  approveRequest,
  approveRequestBy1CHAdmin,
  listRequestTable,
  listRequestTableforAll,
  rejectRequest,
  rejectRequest1CHAdmin,
} from "../../action/graphql/devops/DevOpsAction";
import {
  getApprovalTableData,
  setRequestorTableData,
} from "../../slice/devopsSlice";
import { Icon } from "@iconify/react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../action/userAction";

function ApproverMain() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalApprove, setIsModalApprove] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const devopsStore = useSelector((state) => state.devops);
  const [tableData, setTableData] = useState([]);

  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [showSpinner, setShowSpinner] = useState(true);
  const userRole = localStorage.getItem(
    "CognitoIdentityServiceProvider.offering"
  );
  const [createdUserEmail, setCreatedUserEmail] = useState([]);
  const getRequestTableDetails = () => {
    if (
      userRole === "master" ||
      userRole === "1ch_admin" ||
      userRole === "admin"
    ) {
      dispatch(listRequestTableforAll({ companyName }))
        .unwrap()
        .then(({ data }) => {
          setShowSpinner(false);

          let tableDataBeforeStart = [];

          tableDataBeforeStart =
            data.list_platform_devops_accounts_activities_all;
          tableDataBeforeStart = tableDataBeforeStart.reverse();
          setTableData(tableDataBeforeStart);
          dispatch(
            getApprovalTableData(
              data.list_platform_devops_accounts_activities_all
            )
          );
        });
    } else {
      // to get createdby user
      let user_id = localStorage.getItem("CognitoIdentityServiceProvider.auth");
      let token = localStorage.getItem("_token");
      let created_by_user;
      const records = {
        user_id: user_id,
        _token: token,
      };
      dispatch(loginUser(records))
        .unwrap()
        .then(({ data }) => {
          created_by_user = data.getUsers.email_id;
          setCreatedUserEmail(created_by_user);
          dispatch(listRequestTable({ companyName, created_by_user }))
            .unwrap()
            .then(({ data }) => {
              setShowSpinner(false);
              let tableDataBeforeStart = [];
              tableDataBeforeStart =
                data.list_platform_devops_accounts_activities_user;
              tableDataBeforeStart = tableDataBeforeStart.reverse();
              setTableData(tableDataBeforeStart);
              dispatch(
                getApprovalTableData(
                  data.list_platform_devops_accounts_activities_user
                )
              );
            });
        });
    }
  };
  const getCreatedUserEmail = () => {
    let created_by_user;
    let user_id = localStorage.getItem("CognitoIdentityServiceProvider.auth");
    let token = localStorage.getItem("_token");
    const records = {
      user_id: user_id,
      _token: token,
    };
    dispatch(loginUser(records))
      .unwrap()
      .then(({ data }) => {
        created_by_user = data.getUsers.email_id;
        setCreatedUserEmail(created_by_user);
      });
  };

  useEffect(() => {
    getRequestTableDetails();
    getCreatedUserEmail();
  }, []);

  const [loader, setLoader] = useState(false);
  const [tagModalOpen, settagModalOpen] = useState(false);
  const [titletag, setTitletag] = useState("Unknown Log");

  const handleOk = () => {
    setIsModalOpen(false);
    setIsModalApprove(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    settagModalOpen(false);
    setIsModalApprove(false);
    setShowSpinner(false);
  };
  const tagClick = (record) => {
    setTitletag(record);
    settagModalOpen(true);
  };
  const navigate = useNavigate();
  const handleViewCR = (record) => {
    setSelectedRow(record);
    dispatch(setRequestorTableData(record));
    navigate("/devops-preview");
  };
  const history_columns = [
    {
      title: "CR ID",
      key: "cr_id",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      className: "devops-table-header",
      align: "center",
      render: (a, b, c) => {
        return b.activity_type + b.id;
      },
      defaultSortOrder: "ascend",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => (a.status > b.status ? -1 : 1),
      className: "devops-table-header",
      align: "center",
      render: (text, record, index) => {
        if (record.status === "Approved") {
          return (
            <div
              className="status-box approved"
              onClick={() => tagClick(record)}
            >
              Approved
            </div>
          );
        } else if (record.status === "Build Failed") {
          return (
            <div className="status-box failed" onClick={() => tagClick(record)}>
              Failed
            </div>
          );
        } else if (record.status === "Draft") {
          return (
            <div className="status-box draft" onClick={() => tagClick(record)}>
              Draft
            </div>
          );
        } else if (record.status === "Build in-Progress") {
          return (
            <div className="status-box draft" onClick={() => tagClick(record)}>
              Build InProgress
            </div>
          );
        } else if (record.status === "Approval Pending") {
          return (
            <div
              className="status-box approval-pending"
              onClick={() => tagClick(record)}
            >
              Approval Pending
            </div>
          );
        } else if (record.status === "OCH Approval Pending") {
          return (
            <div
              className="status-box approval-pending"
              onClick={() => tagClick(record)}
            >
              OCH Approval Pending
            </div>
          );
        } else if (record.status === "Build Completed") {
          return (
            <div
              className="status-box completed"
              onClick={() => tagClick(record)}
            >
              Completed
            </div>
          );
        } else if (record.status === "Rejected") {
          return (
            <div className="status-box failed" onClick={() => tagClick(record)}>
              Rejected
            </div>
          );
        } else {
          return record.status;
        }
      },
    },
    {
      title: "Company Name",
      dataIndex: "customer_name",
      align: "center",
      sorter: (a, b) => (a.customer_name > b.customer_name ? -1 : 1),
      className: "devops-table-header",
    },
    {
      title: "Region",
      dataIndex: "cloud_platform",
      key: "cloud_platform",
      sorter: (a, b) => (a.cloud_platform > b.cloud_platform ? -1 : 1),
      className: "devops-table-header",
      align: "center",
    },
    {
      title: "Launch Category",
      dataIndex: "service_name",
      key: "cr_type",
      sorter: (a, b) => (a.cr_type > b.cr_type ? -1 : 1),
      className: "devops-table-header",
      align: "center",
    },
    {
      title: "Sub Task",
      dataIndex: "operation",
      key: "sub_task",
      sorter: (a, b) => (a.sub_task > b.sub_task ? -1 : 1),
      className: "devops-table-header",
      align: "center",
      width: "200",
    },
    {
      title: "Approved time",
      dataIndex: "approved_time",
      key: "approved_time",
      sorter: (a, b) => (a.approved_time > b.approved_time ? -1 : 1),
      className: "devops-table-header",
      align: "center",
      render: (text, record, index) => {
        if (record.approved_time) {
          let record_text = moment(record.approved_time).format(
            "DD-MMM-YYYY, hh:mm:ss A"
          );
          return record_text;
        } else {
          return "-";
        }
      },
    },

    {
      title: " ",
      dataIndex: "status_list",
      key: "status_list",
      align: "center",
      render: (text, record, index) => {
        return (
          <div>
            <span
              className="approvel-view-cr"
              onClick={() => handleViewCR(record)}
            >
              View CR
            </span>
          </div>
        );
      },
    },
    {
      title: "Comment",
      dataIndex: "och_comments",
      key: "comment",
      align: "center",
      render: (text, record, index) => {
        if (record.och_comments) {
          return record.och_comments;
        } else if (record.cx_comments) {
          return record.cx_comments;
        } else {
          return <>-</>;
        }
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (text, record, index) => {
        if (userRole === "master" && record.status === "Approval Pending") {
          return (
            <>
              <div className="d-flex justify-content-center">
                <Space wrap>
                  <button
                    className="dev-approve-button"
                    onClick={() => approveAction(record)}
                  >
                    <Icon
                      icon="teenyicons:tick-circle-solid"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                    />
                  </button>
                </Space>
                <button
                  className="dev-reject-button"
                  onClick={() => rejectAction(record)}
                >
                  <Icon
                    icon="bi:x-circle-fill"
                    style={{ fontSize: "25px", cursor: "pointer" }}
                  />
                </button>
              </div>
            </>
          );
        } else if (
          userRole == "1ch_admin" &&
          record.status == "OCH Approval Pending"
        ) {
          return (
            <>
              <div className="d-flex justify-content-center">
                <Space wrap>
                  <button
                    className="dev-approve-button"
                    onClick={() => approveAction(record)}
                    disabled={record.is_och_approved === 1}
                  >
                    <Icon
                      icon="teenyicons:tick-circle-solid"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                    />
                  </button>
                </Space>
                <button
                  className="dev-reject-button"
                  onClick={() => rejectAction(record)}
                  disabled={record.is_och_approved === 1}
                >
                  <Icon
                    icon="bi:x-circle-fill"
                    style={{ fontSize: "25px", cursor: "pointer" }}
                  />
                </button>
              </div>
            </>
          );
        } else if (userRole == "admin" && record.status == "Approval Pending") {
          return (
            <>
              <div className="d-flex justify-content-center">
                <Space wrap>
                  <button
                    className="dev-approve-button"
                    onClick={() => approveAction(record)}
                    disabled={record.is_admin_approved === 1}
                  >
                    <Icon
                      icon="teenyicons:tick-circle-solid"
                      style={{ fontSize: "25px", cursor: "pointer" }}
                    />
                  </button>
                </Space>
                <button
                  className="dev-reject-button"
                  onClick={() => rejectAction(record)}
                  disabled={record.is_admin_approved === 1}
                >
                  <Icon
                    icon="bi:x-circle-fill"
                    style={{ fontSize: "25px", cursor: "pointer" }}
                  />
                </button>
              </div>
            </>
          );
        } else {
          return "-";
        }
      },
    },
  ];

  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedActionType, setSelectedActionType] = useState("");
  const approveAction = (record) => {

    // console.log("approve clicked");
    setSelectedRow(record);
    form.setFieldsValue({ comment: "" });
    // setIsModalOpen(true);
    setIsModalApprove(true);
    setSelectedActionType("approve");
    let och_approved_by = record.och_approved_by;

    let id = record.id;
    let job_name = record.job_name;
    let cleanedString = record.configurations.replace(/\\\\/g, "\\");
    let customer_name = record.customer_name;
    let configurations = JSON.parse(record.configurations);
    let values = {};
  };
  const rejectAction = (record) => {
    setSelectedRow(record);
    form.setFieldsValue({ comment: "" });
    setIsModalOpen(true);
    // setIsModalApprove(true);
    setSelectedActionType("reject");
  };

  const handleRejectAction = () => {};

  const handleSubmit = (values) => {
  };
  const handleSubmitapprove = (values) => {
  };
  return (
    <>
      <Modal
        centered
        title={titletag.status}
        open={tagModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="modal-tag devops-status"
        expandable={{
          expandedRowRender: (record) => {
            return <></>;
          },
        }}
      >
        <div>
          <br />
          <div className="total-modal">
            <button className="modal-btn">
              <Icon
                icon="fluent-mdl2:message-friend-request"
                className="modal-icon"
              />
            </button>
            <p className="total-modal-text">
              Request created:{" "}
              {moment(titletag.created_time).format("DD/MMM/YYYY hh:mm:ss A")}
            </p>
          </div>
        </div>
        <div>
          <div className="total-modal">
            <button className="modal-btn">
              <Icon
                icon="ant-design:reload-time-outline"
                className="modal-icon"
              />
            </button>
            <p className="total-modal-text">
              Sent for approval :{" "}
              {moment(titletag.created_time).format("DD/MMM/YYYY hh:mm:ss A")}
            </p>
          </div>
        </div>
        <div>
          <div className="total-modal">
            <button className="modal-btn">
              <Icon
                icon="material-symbols:deployed-code-history-outline-sharp"
                className="modal-icon"
              />
            </button>
            <p className="total-modal-text">
              Initiated deployment :{" "}
              {titletag.approved_time
                ? moment(titletag.approved_time).format(
                    "DD/MMM/YYYY hh:mm:ss A"
                  )
                : " -- "}
            </p>
          </div>
        </div>
        <div>
          <div className="total-modal">
            <button className="modal-btn">
              <Icon
                icon="material-symbols:deployed-code-outline-sharp"
                className="modal-icon"
              />
            </button>
            <p className="total-modal-text">
              Deployment completed :
              {titletag.completed_time
                ? moment(titletag.completed_time).format(
                    "DD/MMM/YYYY hh:mm:ss A"
                  )
                : " -- "}
            </p>
          </div>
        </div>

        <div>
          <div className="total-modal">
            <button className="modal-btn">
              <Icon icon="mdi:user" className="modal-icon" />
            </button>
            <p className="total-modal-text">
              Approved by : {titletag.cx_approved_by}
            </p>
          </div>
        </div>
        <div>
          <div className="total-modal">
            <button className="modal-btn">
              <Icon icon="mdi:user" className="modal-icon" />
            </button>
            <p className="total-modal-text">
              OCH Approved by : {titletag.och_approved_by}
            </p>
          </div>
        </div>
        {titletag.status === "Rejected" && (
          <>
            <div>
              <div>
                <div className="total-modal">
                  <button className="modal-btn">
                    <Icon icon="mdi:user" className="modal-icon" />
                  </button>
                  <p className="total-modal-text">
                    Rejected By : &nbsp;
                    {titletag.rejected_by}
                  </p>
                </div>
              </div>
              <div className="total-modal">
                <button className="modal-btn">
                  <Icon
                    icon="material-symbols:comment-outline"
                    className="modal-icon"
                  />
                </button>
                <p className="total-modal-text">
                  Comment : &nbsp; {titletag.och_comments}
                  {titletag.cx_comments}
                </p>
              </div>
            </div>
          </>
        )}
      </Modal>
      <Modal
        centered
        okButtonProps={{
          style: {
            background:
              "linear-gradient(261deg, #ff6a2f 26.19%, #ffd380 168.97%)",
            color: "white",
          },
        }}
        className="devops-modal sub-heading-devops-config"
        open={isModalOpen}
        onCancel={handleCancel}
        okText={<span className="reject-btn">Submit</span>}
        cancelText="Cancel"
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              let created_by = selectedRow.created_by;
              if (selectedActionType == "reject") {
                setShowSpinner(true);
                let id = selectedRow.id;
                let cx_approval_email = selectedRow.cx_approved_by;
                let is_admin_approved = selectedRow.is_admin_approved;
                let is_och_approved = selectedRow.is_och_approved;
                if (userRole === "1ch_admin") {
                  values.event_type = "och_reject_update_status";
                  dispatch(
                    rejectRequest1CHAdmin({
                      values,
                      id,
                      createdUserEmail,
                      created_by,
                      cx_approval_email,
                      is_och_approved,
                    })
                  )
                    .unwrap()
                    .then(() => {
                      getRequestTableDetails();
                      setShowSpinner(false);
                      Modal.success({
                        content: (
                          <>
                            <span style={{ color: "white" }}>
                              The request has been successfully{" "}
                            </span>
                            <span style={{ color: "#be1e2d" }}>REJECTED</span>
                          </>
                        ),
                        onOk: () => {},
                      });
                    });
                } else {
                  values.event_type = "cx_reject_update_status";
                  dispatch(
                    rejectRequest({
                      values,
                      id,
                      createdUserEmail,
                      created_by,
                      is_admin_approved,
                    })
                  )
                    .unwrap()
                    .then(() => {
                      setShowSpinner(false);
                      getRequestTableDetails();
                      Modal.success({
                        content: (
                          <>
                            <span style={{ color: "white" }}>
                              The request has been successfully{" "}
                            </span>
                            <span style={{ color: "#be1e2d" }}>REJECTED</span>
                          </>
                        ),
                        onOk: () => {},
                      });
                    });
                }

                getRequestTableDetails();
              }
              setIsModalOpen(false);
              // setIsModalApprove(false);

              // onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        cancelButtonProps={{
          style: {
            color: "#0f0f0f",
          },
        }}
      >
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                className="comment-form-field"
                name="comment"
                label="Comment"
                rules={[{ required: true, message: "" }]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        // title="Basic Modal"
        centered
        okButtonProps={{
          style: {
            background:
              "linear-gradient(261deg, #ff6a2f 26.19%, #ffd380 168.97%)",
            color: "white",
          },
        }}
        className="devops-modal sub-heading-devops-config"
        open={isModalApprove}
        onCancel={handleCancel}
        okText={<span className="reject-btn">Submit</span>}
        cancelText="Cancel"
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              let comment = values.comment;
              let created_by = selectedRow.created_by;
              if (selectedActionType == "approve") {
                setShowSpinner(true);
                let id = selectedRow.id;
                let cx_approval_email = selectedRow.cx_approved_by;
                let cleanedString = selectedRow.configurations.replace(
                  /\\\\/g,
                  "\\"
                );
                let job_name = selectedRow.job_name;
                let customer_name = selectedRow.customer_name;
                let configurations = JSON.parse(selectedRow.configurations);
                let och_approved_by = selectedRow.och_approved_by;
                let is_och_approved = selectedRow.is_och_approved;
                let is_admin_approved = selectedRow.is_admin_approved;
                let values = {};
                let cloud_platform = selectedRow.cloud_platform;
                if (userRole === "1ch_admin") {
                  values.event_type = "trigger_build";
                  dispatch(
                    approveRequestBy1CHAdmin({
                      values,
                      job_name,
                      id,
                      configurations,
                      customer_name,
                      createdUserEmail,
                      comment,
                      is_och_approved,
                      cloud_platform,
                    })
                  )
                    .unwrap()
                    .then(() => {
                      getRequestTableDetails();
                      setShowSpinner(false);
                      Modal.success({
                        content: (
                          <>
                            <span style={{ color: "white" }}>
                              The request has been successfully{" "}
                            </span>
                            <span style={{ color: "#3D9D1B" }}>APPROVED</span>
                          </>
                        ),
                        onOk: () => {},
                      });
                    });
                } else {
                  values.event_type = "next_och_approval";
                  dispatch(
                    approveRequest({
                      values,
                      och_approved_by,
                      id,
                      customer_name,
                      createdUserEmail,
                      comment,
                      is_admin_approved,
                      cloud_platform,
                    })
                  )
                    .unwrap()
                    .then(() => {
                      setShowSpinner(false);
                      getRequestTableDetails();
                      Modal.success({
                        content: (
                          <>
                            <span style={{ color: "white" }}>
                              The request has been successfully{" "}
                            </span>
                            <span style={{ color: "#3D9D1B" }}>APPROVED</span>
                          </>
                        ),
                        onOk: () => {},
                      });
                    });
                }

                getRequestTableDetails();
              }
              // setIsModalOpen(false);
              setIsModalApprove(false);

              // onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        cancelButtonProps={{
          style: {
            color: "#0f0f0f",
          },
        }}
      >
        <Form layout="vertical" onFinish={handleSubmitapprove} form={form}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                className="comment-form-field"
                name="comment"
                label="Comment"
                rules={[{ required: true, message: "" }]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <div className="devops-background-inner">
        <div className="instanceTrackingTable">
          <Spin spinning={loader} wrapperClassName="costimize-loader">
            <>
              {" "}
              <Table
                className="slm-table"
                columns={history_columns}
                dataSource={tableData}
                pagination={{ pageSize: 5 }}
                showSizeChanger={false}
                scroll={{ x: 2000 }}
                loading={{ indicator: <Spin />, spinning: showSpinner }}
              />
            </>
          </Spin>
        </div>
      </div>
    </>
  );
}

export default memo(ApproverMain);
