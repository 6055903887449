import React from "react";
import { Space, Table, Tag } from "antd";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  PDFViewer,
} from "@react-pdf/renderer";
const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColumns: {
    width: "9.09%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: "9.09%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 8,
  },
  tableColleft: {
    width: "9.09%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
  },
  tableCellright: {
    // margin: "auto",
    marginTop: 5,
    fontSize: 8,
    marginRight: 3,
  },
  tableCellleft: {
    marginTop: 5,
    fontSize: 8,
    marginLeft: 5,
  },
  tableColright: {
    width: "9.09%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "right",
  },
});
function EnvironmentWeeklySpend({ tableEnv, startDates, endDates }) {
  const start_Date = new Date(startDates);
  const end_Date = new Date(endDates);

  const oneWeek = 7 * 24 * 60 * 60 * 1000;

  // Calculate the start and end dates for previous week and pre-previous week
  const prevWeekStartDate = new Date(start_Date.getTime() - oneWeek);
  const prevWeekEndDate = new Date(end_Date.getTime() - oneWeek);

  const prePrevWeekStartDate = new Date(start_Date.getTime() - 2 * oneWeek);
  const prePrevWeekEndDate = new Date(end_Date.getTime() - 2 * oneWeek);
  const threeweekstartDate = new Date(start_Date.getTime() - 3 * oneWeek);
  const threeweekEndDate = new Date(end_Date.getTime() - 3 * oneWeek);

  // Format the dates to strings in the desired format (DD/MM - DD/MM)
  const formatDate = (date) => {
    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];

    // Function to add suffix to day of the month
    const addSuffix = (day) => {
      if (day > 3 && day < 21) return `${day}th`;
      switch (day % 10) {
        case 1:
          return `${day} st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    const formattedDay = addSuffix(day);

    return `${formattedDay} ${month}`;
  };
  const threeweekRange = `${formatDate(threeweekstartDate)} - ${formatDate(
    threeweekEndDate
  )}`;
  const prevWeekRange = `${formatDate(prevWeekStartDate)} - ${formatDate(
    prevWeekEndDate
  )}`;
  const prePrevWeekRange = `${formatDate(prePrevWeekStartDate)} - ${formatDate(
    prePrevWeekEndDate
  )}`;
  const currentWeekRange = `${formatDate(start_Date)}-${formatDate(end_Date)}`;

  const convertToNumber = (value) => {
    // Convert string to number or return 0 if it's not a valid number
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
  };
  const calculateTotals = () => {
    const totals = {
      no_of_application: 0,
      vm_count: 0,
      cpu: 0,
      memory: 0,
      storage: 0,
      pre_previous_week: 0,
      previous_week: 0,
      current_week: 0,
      fourth_week: 0,
      difference: 0,
    };

    if (!Array.isArray(tableEnv)) {
      return totals; // Return empty totals or handle the case where tableEnv is not an array
    }
    tableEnv.forEach((item) => {
      totals.no_of_application += convertToNumber(item.no_of_application);
      totals.vm_count += convertToNumber(item.vm_count);
      totals.cpu += convertToNumber(item.cpu);
      totals.memory += convertToNumber(item.memory);
      totals.storage += convertToNumber(item.storage);
      totals.fourth_week += convertToNumber(item.fourth_week);
      totals.pre_previous_week += convertToNumber(item.pre_previous_week);
      totals.previous_week += convertToNumber(item.previous_week);
      totals.current_week += convertToNumber(item.current_week);
      totals.difference += convertToNumber(item.difference);
    });

    return totals;
  };
  const formatNumberWithCommas = (number) => {
    const roundedNumber = Math.round(number); // Round the number to remove floating point
    return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const totals = calculateTotals();
  return (
    <div>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>Environment</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>No of Applications</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>VM Count</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>CPU</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>Memory</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>Storage</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>{threeweekRange}</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>{prePrevWeekRange}</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>{prevWeekRange}</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>{currentWeekRange}</Text>
          </View>
          <View style={styles.tableColumns}>
            <Text style={styles.tableCell}>Difference</Text>
          </View>
        </View>
        {tableEnv.map((item) => {
          return (
            <View style={styles.tableRow}>
              <View style={styles.tableColleft}>
                <Text style={styles.tableCellleft}>{item.environment}</Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>
                  {item.no_of_application}{" "}
                </Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>{item.vm_count>0?item.vm_count:"-"}</Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>{item.cpu>0?item.cpu:"-"}</Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>{item.memory>0?item.memory:"-"}</Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>{item.storage>0?item.storage:"-"}</Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>
                  {" "}
                  ${formatNumberWithCommas(item.fourth_week)}{" "}
                </Text>
              </View>

              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>
                  {" "}
                  ${formatNumberWithCommas(item.pre_previous_week)}{" "}
                </Text>
              </View>

              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>
                  {" "}
                  ${formatNumberWithCommas(item.previous_week)}{" "}
                </Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>
                  {" "}
                  ${formatNumberWithCommas(item.current_week)}{" "}
                </Text>
              </View>
              <View style={styles.tableColright}>
                <Text style={styles.tableCellright}>
                  {" "}
                  {item.difference >= 0
                    ? `$${formatNumberWithCommas(item.difference)}`
                    : `-$${formatNumberWithCommas(
                        item.difference.toString().slice(1)
                      )}`}
                  {item.difference >= 0 ? (
                    <Text style={{ color: "red", fontSize: 10 }}>↑</Text>
                  ) : (
                    <Text style={{ color: "green", fontSize: 10 }}>↓</Text>
                  )}
                </Text>
              </View>
            </View>
          );
        })}
        <View style={styles.tableRow}>
          <View style={styles.tableColleft}>
            <Text style={styles.tableCellleft}>Total</Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>
              {totals.no_of_application}
            </Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>{totals.vm_count}</Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>{totals.cpu}</Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>{totals.memory}</Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>{totals.storage}</Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>
              ${formatNumberWithCommas(totals.fourth_week)}
            </Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>
              ${formatNumberWithCommas(totals.pre_previous_week)}
            </Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>
              {" "}
              ${formatNumberWithCommas(totals.previous_week)}
            </Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>
              {" "}
              ${formatNumberWithCommas(totals.current_week)}
            </Text>
          </View>
          <View style={styles.tableColright}>
            <Text style={styles.tableCellright}>
              {" "}
              {totals.difference >= 0
                ? `$${formatNumberWithCommas(totals.difference)}`
                : `-$${formatNumberWithCommas(
                    totals.difference.toString().slice(1)
                  )}`}
            </Text>
          </View>
        </View>
      </View>
    </div>
  );
}

export default EnvironmentWeeklySpend;
