import React from "react";
import { Row, Col, Card, Button, Space } from "antd";
import A2CSidebar from "../a2c_rover/A2CSidebar";
import ReactApexChart from "react-apexcharts";
import { Icon } from "@iconify/react";

function Nosproddemo() {
  const series = [44, 55, 41, 17, 15];

  const options = {
    chart: {
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 100,
          },
          legend: {
            position: "bottom",
            labels: {
              colors: ["#FFFFFF"],
            },
          },
        },
      },
    ],
  };

  const chartoptions = {
    chart: {
      type: "bar",
      stacked: true,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        // colors: ["#3498db", "#e74c3c", "#ff00ff", "#95a5a6", "#e67e22"],
      },
    },
    xaxis: {
      categories: ["Database", "lorem", "Compute"],
      style: {
        colors: "#FFFFFF",
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    // fill: {
    //     colors: ['#A7EE7C','#FFB4D8','#FF9B9B','#FFFFFF','#65CCD2','#FDF7C3','#FFB4D8']
    //   },
    legend: {
      position: "bottom",
      labels: {
        colors: "#FFFFFF",
      },
    },
  };

  const chartseries = [
    {
      name: "Series A",
      data: [0, 0, 0, 50, 49],
    },
    {
      name: "Series B",
      data: [11, 9, 22, 24, 29],
    },
  ];

  const taggedoptions = {
    chart: {
      type: "bar",
      stacked: true,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: [
        "Database",
        "lorem",
        "Compute",
        "Network & Content Delivery",
        "Compute",
        "Database",
      ],
      style: {
        colors: "#FFFFFF",
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
    // fill: {
    //     colors: ['#A7EE7C','#FFB4D8','#FF9B9B','#FFFFFF','#65CCD2','#FDF7C3','#FFB4D8']
    //   },
    legend: {
      position: "bottom",
      labels: {
        colors: "#FFFFFF",
      },
    },
  };

  const taggedseries = [
    {
      name: "Series A",
      data: [0, 0, 0, 50, 49, 29],
    },
    {
      name: "Series B",
      data: [11, 9, 22, 24, 29, 30],
    },
  ];

  return (
    <>
      <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
        <A2CSidebar />
        <main className="position-main-div" style={{ padding: 10, flex: 1 }}>
          {" "}
          <div className="cams-content">
            <div className="container-fluid">
              <div className="header-filter d-flex justify-content-between">
                <div className="header-title-a2c-main">WAR</div>
                <span className="d-flex align-items-end">
                  {/* <A2cFilter /> */}
                </span>
              </div>
              <div className="">
                <Row>
                  <Col span={20}>
                    <h4 className="titletab mt-2">nops-production-demo</h4>
                  </Col>
                  <Col span={2}>
                    <Button className="buttonclass">
                      <Space>
                        <Icon icon="fluent:edit-16-regular" />
                        Edit
                      </Space>
                    </Button>
                  </Col>
                  <Col span={2}>
                    <Button className="delbtn">
                      <Space>
                        <Icon icon="codicon:trash" />
                        Delete
                      </Space>
                    </Button>{" "}
                  </Col>{" "}
                </Row>
                <Row className="sub-header mt-2">
                  <h4>Summary</h4>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={6}>
                    <Card title="Total Resources" className="operate-cards">
                      <span className="counts1">129</span>
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card title="With Violations" className="operate-cards">
                      <span className="counts1">28</span>
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card title="Reserved Instances" className="operate-cards">
                      <span className="counts1">2</span>
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card title="Part of AutoScaling" className="operate-cards">
                      <span className="counts1">0</span>
                    </Card>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div className="textalign">
                      <h5 className="sub-header mt-3">Resources by Regions</h5>
                    </div>
                    <Card className="operate-cards donut-war ">
                      <div className="donut-chart">
                        <ReactApexChart
                          options={options}
                          series={series}
                          type="donut"
                          height={250}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <div className="textalign">
                      <h5 className="sub-header mt-3">
                        Resources by Cloud Services
                      </h5>
                    </div>
                    <Card className="operate-cards donut-war">
                      <div className="donut-chart">
                        <ReactApexChart
                          options={options}
                          series={series}
                          type="donut"
                          height={250}
                        />
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <div className="textalign d-flex">
                      <h5 className="sub-header mt-3">
                        Resources by Service Category
                      </h5>
                    </div>
                    <Card className="operate-cards">
                      <ReactApexChart
                        options={chartoptions}
                        series={chartseries}
                        type="bar"
                        height={300}
                      ></ReactApexChart>
                    </Card>
                  </Col>
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <div className="textalign d-flex">
                      <h5 className="sub-header mt-3">
                        Tagged & Untagged Resources
                      </h5>
                    </div>
                    <Card className="operate-cards">
                      <ReactApexChart
                        options={taggedoptions}
                        series={taggedseries}
                        type="bar"
                        height={300}
                      ></ReactApexChart>
                    </Card>
                  </Col>
                </Row>
              </div>

              <br />
              {/* Graph */}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Nosproddemo;
