import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getSecurityHub } from "../../../action/graphql/slm/SLM_reducers";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import { Icon } from "@iconify/react";
import {Modal,Button,Row,Col} from "antd";
const SecurityChart = (counting) => {
  
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [percent,setPercent] = useState();
  useEffect(() => {
    getSecurityTableData();
  }, []);
  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };
  const dispatch = useDispatch();
  // const companyName = localStorage.getItem("CognitoIdentityServiceProvider.company");
  const companyName = "fivestar";

  // const { selectedTopdate } = useSelector((state) => state.constimize);
  const selectedTopdate = "2023-4-01";

  const [statusCounts, setStatusCounts] = useState({
    passed: 0,
    failed: 0,
  });

  useEffect(() => {}, [statusCounts]);
  const getSecurityTableData = () => {
    dispatch(getSecurityHub({ companyName, selectedTopdate }))
      .unwrap()
      .then(({ data }) => {
        let totalData = data.newfunctionquery29;

        var passedStandards = totalData.filter((data) => {
          return data.security_current_status.toLowerCase() === "passed";
        });
        var failedStandards = totalData.filter((data) => {
          return data.security_current_status.toLowerCase() === "failed";
        });
        setStatusCounts({
          passed: passedStandards.length,
          failed: failedStandards.length,
        });
      
      });
  };
  // const series = [percent,Math.round((100-percent) * 100) / 100 ];
  const series=[44, 55];
  useEffect(() => {
   
    setPercent(Math.round(counting.counting * 100) / 100)
    
  }, [counting, series]);
  
  
  const options = {
    chart: {
      type: "donut",
    },
    legend: {
      show: false,
    },
    // fill : {
    //     color : ['#0DADD0' , '#FFEAA0']
    // },
    colors: ["#FF6A2F", "#2e2e2e"],
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
            },
            value: {
              show: true,
            },
            total: {
              show: true,
              showAlways: true,
              label: "Security Score" ,
              value : series[0],
              formatter: function (percent) {
                // const counting = Number(props.counting);
                return series[0].toString();
               
                // return (((statusCounts.passed)/(statusCounts.passed + statusCounts.failed))*100).toFixed(2) + "%";
              }
              
            },
          },
        },
      },
    },
  };  

  const handleIconClick = () => {
    setDrawerStatus(true);
  };
  return (
    <>
      <Modal
      className="slmmodal"
        open={drawerStatus}
        onCancel={changeDrawerStatus}
        footer={null}
        deletestatus={deletestatus}
        title="Security Chart"
      >
        <div> test </div>
      </Modal>
      <Row>
            <div className="card-optionsspend">
           
                <Button
                   className="card-filter-iconbutton"
                  onClick={() => handleIconClick()}
                  // onClick={handlesubmit()}
                >
                  <Icon
                    className="cursor-pointer"
                    icon="fluent:edit-16-regular"
                    color="white"

                     //style={{float : "right"}}
                  />
                </Button>
             </div>
            </Row>
      {/* <div className="card-optionsspend">
              <span
              className="card-filter-icon"
                
                // onClick={handlesubmit()}
              >
                <Icon
                  className="cursor-pointer"
                  icon="fluent:edit-16-regular"
                  color="white"
                  onClick={() => handleIconClick()}
                  // style={{float : "right"}}
                />
              </span>
            </div> */}
      {/* <div id="chart">
        <Icon
          icon="fluent:edit-16-regular" color="white" 
          onClick={handleIconClick}
          // style={{ float: "right" }}
        /> */}

   <Row>  <ReactApexChart options={options} series={series} type="donut" /></Row>   
    
    </>
  );
};

export default SecurityChart;
