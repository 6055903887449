import { Card, DatePicker, Tabs } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PeemsOverview from "./PeemsOverview/PeemsOverview";
import PeemsReport from "./PeemsReport/PeemsReport";
import JobConfigHome from "./JobConfiguration/JobConfigHome";
import Identifier from "./Identifier/Identifier";
import PeemsHeader from "./PeemsHeader";
import "./peems.css";
import CostimizeFilter from "../costimize_v2/layout/CostimizeFilter";
import { selectedTopDateMethod } from "../slice/peemsSlice";
import logo from "../../assets/images/company_logo.png";
import cmacgm_logo from "../../assets/images/cmacgm_logo.png";
import A2CSidebar from "../a2c_rover/A2CSidebar";

function PeemsIndex() {
  const [shortmonth, setShortMonth] = useState("current_month");
  const dispatch = useDispatch();
  const [company_logo, setCompanyLogo] = useState();

  const { selectedTopdate } = useSelector((state) => state.peems);
  const [monthlist, setMonthList] = useState({
    last: "",
    current: "",
    custom_month: "",
  });
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const items = [
    {
      key: "1",
      label: "Overview",
      children: <PeemsOverview />,
    },
    {
      key: "2",
      label: "Report",
      children: <PeemsReport />,
    },
    {
      key: "3",
      label: "Job Configuration",
      children: <JobConfigHome />,
    },
    {
      key: "4",
      label: "Type Identifier",
      children: <Identifier />,
    },
  ];

  const todayDate =
    new Date().getDate() +
    "-" +
    monthNames[new Date().getMonth()] +
    "-" +
    new Date().getFullYear();

  useEffect(() => {
    const selecteddate = new Date(selectedTopdate);
    let selectedmonth = selecteddate.getMonth() + 1;
    if (selectedmonth < 10) {
      selectedmonth = "0" + selectedmonth;
    }
    const selectedyear = selecteddate.getFullYear();
    const currentddate = new Date();
    let currentmonth = currentddate.getMonth() + 1;
    if (currentmonth < 10) {
      currentmonth = "0" + currentmonth;
    }
    const currentyear = currentddate.getFullYear();
    if (selectedmonth != "custom_month") {
      setMonthList({
        last: `${monthNames[currentddate.getMonth() - 1]} ${currentyear}`,
        current: `${monthNames[currentddate.getMonth()]} ${currentyear}`,
        custom_month: `${monthNames[selecteddate.getMonth()]} ${selectedyear}`,
      });
    }
    if (selectedmonth != "custom_month") {
      if (selectedmonth === currentmonth && selectedyear === currentyear) {
        setShortMonth("current_month");
      } else if (
        selectedmonth === currentmonth &&
        selectedyear === currentyear
      ) {
        setShortMonth("last_month");
      } else {
        setShortMonth("custom_month");
      }
    }

    const company_name = localStorage.getItem(
      "CognitoIdentityServiceProvider.company"
    );

    const demo_type = localStorage.getItem(
      "CognitoIdentityServiceProvider.demo"
    );
    if (demo_type == "true") {
      setCompanyLogo();
    } else {
      if (company_name === "cmacgm") {
        setCompanyLogo(cmacgm_logo);
      } else if (company_name === "fivestar") {
        setCompanyLogo(logo);
      }
    }
  }, [selectedTopdate]);
  const handleMultiMonthChange = useCallback(
    (data, date) => {
      if (data === "current_month") {
        const currentnew = new Date();
        let currentMonth = currentnew.getMonth() + 1;
        if (currentMonth < 10) {
          currentMonth = "0" + currentMonth;
        }
        dispatch(
          selectedTopDateMethod(
            `${currentnew.getFullYear()}-${currentMonth}-01`
          )
        );
      } else if (data === "last_month") {
        const currentnew = new Date();
        let currentMonth = currentnew.getMonth();
        if (currentMonth < 10) {
          currentMonth = "0" + currentMonth;
        }
        dispatch(
          selectedTopDateMethod(
            `${currentnew.getFullYear()}-${currentMonth}-01`
          )
        );
      } else if (data === "custom_month") {
        let mon = date.$M + 1;
        if (date.$M < 10) mon = "0" + mon;
        dispatch(selectedTopDateMethod(`${date.$y}-${mon}-01`));
      }
    },
    [shortmonth]
  );
  const [isShowOverviewFilter, setIsShowOverviewFilter] = useState(true);
  const [isShowReportFilter, setIsShowReportFilter] = useState(false);
  const [isShowIdentifierFilter, setIsShowIdentifierFilter] = useState(false);
  const [isShowJobConfigFilter, setIsShowJobConfigFilter] = useState(false);

  const isClickedReports = (e) => {
    if (e == 1) {
      setIsShowIdentifierFilter(false);
      setIsShowReportFilter(false);
      setIsShowJobConfigFilter(false);
      setIsShowOverviewFilter(true);
    } else if (e == 2) {
      setIsShowIdentifierFilter(false);
      setIsShowOverviewFilter(false);
      setIsShowJobConfigFilter(false);
      setIsShowReportFilter(true);
    } else if (e == 3) {
      setIsShowIdentifierFilter(false);
      setIsShowOverviewFilter(false);
      setIsShowReportFilter(false);
      setIsShowJobConfigFilter(true);
    } else if (e == 4) {
      setIsShowOverviewFilter(false);
      setIsShowReportFilter(false);
      setIsShowJobConfigFilter(false);
      setIsShowIdentifierFilter(true);
    }
  };
  return (
    <>
      <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
        <A2CSidebar />
        <main className="position-main-div" style={{ padding: 10, flex: 1 }}>
          {" "}
          <div className="cams-content">
            <div className="container-fluid">
              <div className="header-filter d-flex justify-content-between">
                <div className="header-title-a2c-main">Event Express</div>
                <span className="d-flex align-items-end">
                  {/* <CostimizeFilter /> */}
                </span>
              </div>

              {/* <div style={{ display: "flex", height: "100%" }} className="costimize_bg"> */}
              {/* <PeemsHeader /> */}
              {/* </div> */}
              <div className="peems-filters mt-3 me-3">
                <div className="header-filter-block">
                  {isShowOverviewFilter ? (
                    <>
                      {" "}
                      <div>
                        <span className="filter-card">{todayDate}</span>
                      </div>
                    </>
                  ) : isShowReportFilter ? (
                    <>
                      {" "}
                      <div>
                        <ul className={"header-filter-list slm-ul-li slmdate"}>
                          {shortmonth === "custom_month" ? (
                            <li
                              className={
                                shortmonth === "custom_month" && "active"
                              }
                            >
                              {monthlist.custom_month}
                            </li>
                          ) : (
                            <li
                              className={
                                shortmonth === "last_month" && "active"
                              }
                              onClick={() =>
                                handleMultiMonthChange("last_month")
                              }
                            >
                              {monthlist.last}
                            </li>
                          )}
                          <li
                            className={
                              shortmonth === "current_month" && "active"
                            }
                            onClick={() =>
                              handleMultiMonthChange("current_month")
                            }
                          >
                            {monthlist.current}
                          </li>
                          <li>
                            <DatePicker
                              onChange={(date) =>
                                handleMultiMonthChange("custom_month", date)
                              }
                              picker="month"
                            />
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : isShowJobConfigFilter ? (
                    <></>
                  ) : isShowIdentifierFilter ? (
                    ""
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="container peems-main-section  mt-3">
                <Tabs
                  className="cams-tabs"
                  defaultActiveKey="1"
                  items={items}
                  onChange={isClickedReports}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default PeemsIndex;
