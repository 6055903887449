import React from "react";
import SpotCostComparision from "./SpotCostComparision";
import SpotRecommendation from "./SpotRecommendation";
import { Row } from "antd";
import { Col } from "antd";
import { Popover } from "antd";
import { AiFillInfoCircle } from "react-icons/ai";
import TableOfSpotQuickGlance from "./TableOfSpotQuickGlance";

function SpotQuickGlance() {
  return (
    <div>
      <div className="py-2"></div>
      <div>
        <Row gutter={16}>
          <Col span={6}>
            <div className="crd relative">
              <h3 className="h-title flex items-center">
                <Popover
                  placement="topLeft"
                  content={"content"}
                  title="Title"
                  trigger="hover"
                >
                  <AiFillInfoCircle size={14} color="grey" role="button" />
                </Popover>
                <span className="mx-2"> Total Instance</span>
              </h3>
              <h6 className="s-title">50</h6>
              <div className="mk-graph-bg"></div>
            </div>
          </Col>
          <Col span={6}>
            <div className="crd relative ">
              <div className="flex ">
                <h3 className="h-title flex items-center">
                  <AiFillInfoCircle size={14} color="grey" role="button" />
                  <span className="mx-2">Recommended Savings</span>
                </h3>
                {/* <div className="ms-auto">
              <Switch size="small" />
            </div> */}
              </div>

              <h6 className="s-title">$5,350</h6>
              <div className="mk-graph-bg"></div>
            </div>
          </Col>
          <Col span={6}>
            <div className="crd relative">
              <h3 className="h-title flex items-center">
                <AiFillInfoCircle size={14} color="grey" role="button" />
                <span className="mx-2">Recommended Spot Count</span>
              </h3>
              <h6 className="s-title">34</h6>
              <div className="mk-graph-bg"></div>
            </div>
          </Col>
          <Col span={6}>
            <div className="crd relative">
              <h3 className="h-title flex items-center">
                <Popover
                  placement="topLeft"
                  content={"content"}
                  title="Title"
                  trigger="hover"
                >
                  <AiFillInfoCircle size={14} color="grey" role="button" />
                </Popover>
                <span className="mx-2">Projected Spend</span>
              </h3>
              <h6 className="s-title">$15,000</h6>
              <div className="mk-graph-bg"></div>
            </div>
          </Col>
        </Row>
        <div className="py-2"></div>
        <Row gutter={16}>
          <Col span={12}>
            <div className="crd relative">
              <SpotCostComparision />
            </div>
          </Col>
          <Col span={12}>
            <div className="crd relative">
              <SpotRecommendation />
            </div>
          </Col>
        </Row>
      </div>
      <div className="py-2"></div>
      <Row gutter={16}>
        <Col span={24}>
          <div className="crd relative">
            <TableOfSpotQuickGlance />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default SpotQuickGlance;
