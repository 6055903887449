import { memo, useEffect, useState } from "react";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Table, Tooltip, Typography } from "antd";
import { numberFormat } from "../../../custom_hook/CustomHook";
import { useDispatch, useSelector } from "react-redux";
import { getSavingsRealised } from "../../../action/costimizeV2Active";

const { Text } = Typography;

const RealisedTable = (props) => {
  //state
  const [tablerecords, setTableRecords] = useState([]);
  const [sum, setSum] = useState(0);

  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const dispatch = useDispatch();

  const columns = [
    {
      title: "Account",
      dataIndex: "account_names",
      key: "account_names",
      // width: "8%",
      align: "center",
      // sorter: (a, b) => {
      //   return a.account_names > b.account_names ? -1 : 1;
      // },
      render: (record, index) => {
        if (record.length > 10) {
          return (
            <>
              <Tooltip placement="bottom" title={record}>
                {record.substring(0, 10)}...
              </Tooltip>
            </>
          );
        } else {
          return record;
        }
      },
    },
    {
      title: "Application",
      dataIndex: "applications",
      key: "applications",
      align: "center",
      // width: "10%",
      // fixed: "left",
      // sorter: (a, b) => {
      //   return a.applications > b.applications ? -1 : 1;
      // },
      render: (record, index) => {
        if (record.length > 15) {
          return (
            <>
              <Tooltip placement="bottom" title={record}>
                {record.substring(0, 15)}...
              </Tooltip>
            </>
          );
        } else {
          return record;
        }
      },
    },
    {
      title: "Environment",
      dataIndex: "environments",
      key: "environments",
      align: "center",
      // width: "10%",
      // sorter: (a, b) => {
      //   return a.environment > b.environment ? -1 : 1;
      // },
      render: (record, index) => {
        if (record.length > 10) {
          return (
            <>
              <Tooltip placement="bottom" title={record}>
                {record.substring(0, 10)}...
              </Tooltip>
            </>
          );
        } else {
          return record;
        }
      },
    },
    {
      title: "Services",
      dataIndex: "services",
      key: "services",
      align: "center",
      // width: "8%",
      // sorter: (a, b) => {
      //   return a.services > b.services ? -1 : 1;
      // },
      render: (record, index) => {
        if (record.length > 10) {
          return (
            <>
              <Tooltip placement="bottom" title={record}>
                {record.substring(0, 10)}...
              </Tooltip>
            </>
          );
        } else {
          return record;
        }
      },
    },
    {
      title: "Resource Name",
      key: "resource_name",
      dataIndex: "resource_name",
      align: "center",
      // width: "10%",
      // sorter: (a, b) => {
      //   return a.resource_name > b.resource_name ? -1 : 1;
      // },
      render: (record, index) => {
        if (!record) {
          return "";
        }

        if (record.length > 10) {
          return (
            <>
              <Tooltip placement="bottom" title={record}>
                {record.substring(0, 10)}...
              </Tooltip>
            </>
          );
        } else {
          return record;
        }
      },
    },
    {
      title: "Current Configuration",
      dataIndex: "current_configuration",
      key: "current_configuration",
      align: "center",
      // width: "13%",
      // sorter: (a, b) => {
      //   return a.current_configuration > b.current_configuration ? -1 : 1;
      // },
      render: (record) => {
        if (record.length > 16) {
          return (
            <>
              <Tooltip placement="bottom" title={record}>
                {record.substring(0, 16)}...
              </Tooltip>
            </>
          );
        } else {
          return record;
        }
      },
    },
    {
      title: "Recommended Configuration",
      dataIndex: "recommended_configuration",
      key: "recommended_configuration",
      align: "center",
      // width: "17%",
      // sorter: (a, b) => {
      //   return a.environment > b.environment ? -1 : 1;
      // },
      render: (record) => {
        if (record.length > 16) {
          return (
            <>
              <Tooltip placement="bottom" title={record}>
                {record.substring(0, 16)}...
              </Tooltip>
            </>
          );
        } else {
          return record;
        }
      },
    },
    {
      title: "Commitment",
      dataIndex: "commitment",
      key: "commitment",
      align: "center",
      // width: "7%",
      // sorter: (a, b) => {
      //   return a.commitment > b.commitment ? -1 : 1;
      // },
      render: (record) => {
        if (record) {
          return <>{record}</>;
        } else {
          return <>-</>;
        }
      },
    },
    {
      title: "Realised Savings",
      dataIndex: "realised_savings",
      key: "realised_savings",
      align: "right",
      // width: "12%",
      sorter: (a, b) => {
        return a.realised_savings > b.realised_savings ? -1 : 1;
      },
      render: (record) => {
        return (
          <>
            {/* <div className="number_right"> */}$
            {record ? numberFormat(record) : 0}
            {/* </div> */}
          </>
        );
      },
    },
  ];

  const {
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedTopdate,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);

  useEffect(() => {
    var input = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      // date: selectedTopdate,
      date: "all",
      company_name: company_name,
    };
    dispatch(getSavingsRealised({ input }))
      .unwrap()
      .then(({ data }) => {
        const value_list = data.get_realised_savingsplan.map(
          (datum, index) => ({
            key: index + 1,
            ...datum,
          })
        );
        setTableRecords(value_list);
        const record = value_list.map((v) =>
          v.recommendation_rule === props.filter
            ? parseFloat(v.realised_savings)
            : 0
        );
        const sumtotal = record.reduce((a, b) => a + b, 0);
        setSum(sumtotal);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    props.recordsupdate,
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedservices,
    selectedEnvironment,
    selectedTopdate,
    selecteddb,
    selectedinfra,
    selectedos,
  ]);
  return (
    <>
      <Table
        className="savop-table"
        scroll={{
          x: 500,
        }}
        columns={columns}
        dataSource={tablerecords.filter(function (val) {
          return val.recommendation_rule === props.filter;
        })}
        pagination={(true, { pageSize: 5 })}
        summary={(pageData) => {
          return (
            <>
              <Table.Summary.Row className="savop-table-footer">
                <Table.Summary.Cell className="d-flex align-items-center justify-content-end">
                  Total:
                  <Text className="white-color" style={{ paddingLeft: "1rem" }}>
                    ${numberFormat(sum)}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </>
  );
};

export default memo(RealisedTable);
