import { Card, Modal, Spin } from "antd";

import { Icon } from "@iconify/react";
import { Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBudgetApiData } from "../../../action/costimizedashboardAction";
import BudgetVsSpendCard from "../budget-graph/BudgetVsSpendCard";
import CardGraph from "./CardGraph";

function BudgetForThePeriod() {
  const [budgetDataState, setBudgetDataState] = useState("");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  var company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);

  useEffect(() => {
    setLoading(true);
    const apiVariables = {
      event_type: "get budget",
      screen: "qg",
      month: selectedTopdate,
      cloud: "all",
      company: company_name,
    };

    dispatch(getBudgetApiData(apiVariables))
      .unwrap()
      .then(({ data }) => {
        const jsonString = data.budget_qg;
        // Parse the JSON-encoded string to a JavaScript object
        let resp = JSON.parse(jsonString);
        resp.sort((a, b) => {
          // Extract the year and month from each budget object
          const [yearA, monthA] = [
            a.budget_year,
            moment().month(a.budget_month),
          ];
          const [yearB, monthB] = [
            b.budget_year,
            moment().month(b.budget_month),
          ];

          // Compare the years first
          if (yearA !== yearB) {
            return yearA - yearB; // Sort by year
          } else {
            // If years are the same, compare the months
            return monthA - monthB; // Sort by month
          }
        });

        setBudgetDataState({
          currentMonthBudgetData: resp[resp.length - 1],
          budgetTrendData: resp,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err?.message);
        setLoading(false);
      });
  }, [selectedTopdate]);

  const currencyFormatter = (val) => {
    if (val) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD", // Change to your desired currency code
        minimumFractionDigits: 0, // Minimum number of fraction digits
        maximumFractionDigits: 2, // Maximum number of fraction digits
      }).format(Number(val));
    } else {
      return 0;
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [clickedRowRecord, setClickedRowRecord] = useState("");
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      {/* <pre className="text-white">
        {JSON.stringify(budgetDataState, null, 3)}
      </pre> */}

      <Modal
        title={"Budget for the period"}
        centered
        className="daywise-spend-modal"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="pt-3">
          <BudgetVsSpendCard />
        </div>
      </Modal>
      <Spin spinning={loading} wrapperClassName="costimize-loader">
        <Card className="plat-card-1 plat-card-p-0 p-0 m-0 mnH overflowHidden">
          <h1 className={` spendCardTitle  ptl-3`}>
            Budget for the period{" "}
            <Tooltip
              overlayClassName="custom-tooltip"
              placement="top"
              title="Auto generated average"
            >
              <span onClick={showModal}>
                <Icon
                  icon="iconamoon:information-circle-fill"
                  style={{
                    // marginTop: "7px",
                    height: "100%",
                    fontSize: "14px",
                    color: "#848484",
                    cursor: "pointer",
                  }}
                />
              </span>
            </Tooltip>
          </h1>
          <div className="usmon">
            {/* <BiSolidUpArrowAlt clolor="red" /> */}
            <p className="t1">
              {currencyFormatter(
                budgetDataState?.currentMonthBudgetData?.budget_amount
              )}
            </p>
          </div>
          <div className="crdGrpBt">
            <CardGraph chartData={budgetDataState?.budgetTrendData} />
          </div>
        </Card>
      </Spin>
    </div>
  );
}

export default BudgetForThePeriod;
