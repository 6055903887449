import React, { useEffect, useState } from "react";
import { Tabs, Row, Col } from "antd";
import "./devops.css";
import RequestorMain from "./Requestor/RequestorMain";
import ApproverMain from "./Approver/ApproverMain";
import { useDispatch } from "react-redux";
import { setUserRole } from "../slice/devopsSlice";
import A2CSidebar from "../a2c_rover/A2CSidebar";

function DevOpsMain() {
  const [items, setItems] = useState([
    {
      key: "1",
      label: `My Request`,
      children: <RequestorMain />,
    },

    {
      key: "2",
      label: `My Approvals`,
      children: <ApproverMain />,
    },
  ]);
  const dispatch = useDispatch();
  useEffect(() => {
    const userRole = localStorage.getItem(
      "CognitoIdentityServiceProvider.offering"
    );

    if (userRole === "master") {
      setItems([
        {
          key: "1",
          label: `My Request`,
          children: <RequestorMain />,
        },
        {
          key: "2",
          label: `My Approvals`,
          children: <ApproverMain />,
        },
      ]);
    } else if (userRole === "1ch_admin" || userRole === "admin") {
      setItems([
        {
          key: "2",
          label: `My Approvals`,
          children: <ApproverMain />,
        },
      ]);
    } else {
      setItems([
        {
          key: "1",
          label: `My Request`,
          children: <RequestorMain />,
        },
      ]);
    }

    dispatch(setUserRole(userRole));
  }, []);

  return (
    <div>
      <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
        <A2CSidebar />
        <main
          className="position-main-div"
          style={{ padding: 10, flex: 1, width: "87%" }}
        >
          {" "}
          <div className="cams-content">
            <div className="container-fluid">
              <div className="header-filter d-flex justify-content-between">
                <div className="header-title-a2c-main">DevOps Express</div>
                <span className="d-flex align-items-end">
                  {/* <CostimizeFilter /> */}
                </span>
              </div>

              <div className="devops-background">
                <Tabs
                  className="cams-tabs"
                  defaultActiveKey="1"
                  items={items}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default DevOpsMain;
