import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import { useState, useEffect } from "react";
import { message, Form, Input, Select, Button, Switch ,Modal} from 'antd';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
const { TextArea } = Input;
const EscalationForm = (props) => {
    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [buttonloading, setButtonLoading] = useState(false);
    const companyName = localStorage.getItem("CognitoIdentityServiceProvider.company");
    const dispatch = useDispatch();
    const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
    const handleSubmit = (value) => {
    }
    return (
        <>
            <Modal
            className="slmmodal"
                open={props.drawerstatus}
                onCancel={props.changeDrawerStatus}
                footer={null}
                deletestatus={props.deletestatus}
                title="Escalation Matrix"
                // rowDelete={rowDelete}
                confirmLoading={confirmLoading}
                setDrawerDeleteStatus={setDrawerDeleteStatus}
            >
                <Form
                    layout="vertical"
                    onFinish={handleSubmit}
                    form={form}
                >
                    <Row>
                        <Col>
                            <Form.Item
                                name="pointofescalation"
                                label="Point of Escalation"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[{
                                        value: "level1",
                                        label: "Level 1",
                                    },
                                    {
                                        value: "level2",
                                        label: "Level 2"
                                    },
                                    {
                                        value: "level3",
                                        label: "Level 3"
                                    }]}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    style={{
                                        width: "100%",
                                    }}

                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item
                                name="role"
                                label="Role"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    style={{
                                        width: "100%",
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                name="emailaddress"
                                label="Email Address"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    style={{
                                        width: "100%",
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item
                                name="mobilenumber"
                                label="Mobile Number"
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                ]}
                            >
                                <Input
                                    style={{
                                        width: "100%",
                                    }}
                                />

                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Form.Item >
                            <Button htmlType="button" onClick={() => props.setDrawerStatus(false)}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit" style={{ background: "#1b5f9d", color: "#fff", float: "right" }} loading={buttonloading}>
                                Submit
                            </Button>

                        </Form.Item>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}
export default EscalationForm;
