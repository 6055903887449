import { Icon } from "@iconify/react";
import { Table } from "antd";
import Dropdown from "react-bootstrap/Dropdown";

const RequestTime = () => {
  const data = [
    {
      key: "1",
      service_level: "Critical",
      initial_response_time: "15mins",
      target: "95%",
      dec_22: "Nil",
      jan_23: "Nil",
      feb_23: "Nil",
      action: "",
      type: "data",
    },
    {
      key: "2",
      service_level: "High",
      initial_response_time: "30mins",
      target: "95%",
      dec_22: "Nil",
      jan_23: "Nil",
      feb_23: "Nil",
      action: "",
      type: "data",
    },
    {
      key: "3",
      service_level: "Medium",
      initial_response_time: "30mins",
      target: "95%",
      dec_22: "Nil",
      jan_23: "Nil",
      feb_23: "Nil",
      action: "",
      type: "data",
    },
    {
      key: "4",
      service_level: "Low",
      initial_response_time: "30mins",
      target: "95%",
      dec_22: "100% (5/5)",
      jan_23: "100% (5/5)",
      feb_23: "100% (6/6)",
      action: "",
      type: "data",
    },
  ];
  const columns = [
    {
      title: "Service Level",
      dataIndex: "service_level",
      key: "service_level",
    },
    {
      title: "initial Response Time",
      dataIndex: "initial_response_time",
      key: "initial_response_time",
      sorter: (a, b) => {
        return a.initial_response_time > b.initial_response_time ? -1 : 1;
      },
    },
    {
      title: "Target",
      dataIndex: "target",
      key: "target",
    },
    {
      title: "Dec - 22",
      dataIndex: "dec_22",
      key: "dec_22",
    },
    {
      title: "Jan - 23",
      dataIndex: "jan_23",
      key: "jan_23",
    },
    {
      title: "Feb - 23",
      dataIndex: "feb_23",
      key: "feb_23",
    },
    {
      title: " ",
      dataIndex: "action",
      key: "action",
      // className: "table-edit-list",
      render: (record, index) => {
        if (index.type === "data") {
          const edit = (
            <Dropdown.ItemText className="plat-dropdown-item-text">
              <span className="plat-box-item-title list-expand-left">Edit</span>
              <span className="plat-box-item-title list-expand-right">
                <Icon
                  icon="material-symbols:edit-outline"
                  class="table-add-records-icon"
                />
              </span>
            </Dropdown.ItemText>
          );

          var addicon = "";
          if (index.key == data.length) {
            addicon = (
              <Dropdown.ItemText className="plat-dropdown-item-text">
                <span className="plat-box-item-title list-expand-left">
                  Add
                </span>
                <span className="plat-box-item-title list-expand-right">
                  <Icon
                    icon="material-symbols:add-box-outline"
                    class="table-add-records-icon"
                  />
                </span>
              </Dropdown.ItemText>
            );
          }
          return (
            <>
              <Dropdown className="plat-costimize-dropdown plat-box-costimize-dropdown">
                <Dropdown.Toggle
                  className="plat-three-dort plat-tap-menu plat-box-three-dort"
                  id="dropdown-button-dark-example1"
                  variant="secondary"
                >
                  <Icon
                    icon="ph:dots-three-bold"
                    class="plat-expandalt-icon-view"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="plat-box-menu-list more-details">
                  {edit}
                  {addicon}
                </Dropdown.Menu>
              </Dropdown>
            </>
          );
        }
      },
    },
  ];

  const tableProps = {};

  return (
    <>
      <div className="budget-allocated-table">
        <h1 className="plat-table-title">Initial Request Time</h1>
        <div className="table-width">
          <div className="table-planned-actual">
            <Table
              {...tableProps}
              className="savings_new_table"
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default RequestTime;
