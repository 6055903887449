import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import { Button, Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import navHeader from "./../../assets/images/Header Logo.png";
import { Navigate, useNavigate } from "react-router-dom";
import { loginUser } from "../action/userAction";
import { signOut } from "../amplify/auth-config";
import { useDispatch, useSelector } from "react-redux";
import "./HomelandingNew.css";
function HomeLandingHeaderNew() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userdetails, setUserDetails] = useState([]);
  const records = {
    user_id: localStorage.getItem("CognitoIdentityServiceProvider.auth"),
    _token: localStorage.getItem("_token"),
  };
  useEffect(() => {
    var path = window.location.pathname;
    dispatch(loginUser(records))
      .unwrap()
      .then(({ data }) => {
        if (data.getUsers) {
          setUserDetails(data.getUsers);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [window.location.pathname]);
  const userLogout = () => {
    dispatch(signOut())
      .unwrap()
      .then(() => {
        localStorage.removeItem("_token");
        localStorage.removeItem("CognitoIdentityServiceProvider.auth");
        navigate("/signin", { replace: true });
        window.location.href = "/signin";
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="nav-circles">
        <div className="nav-header-bg">
          <Navbar className="navHeader " expand="lg">
            <Navbar.Brand
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              <img src={navHeader} alt="nav-logo" height={35} />
              <span className="nav-header-logo-text"></span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <div className="d-flex justify-content-between w-100">
                <Nav></Nav>

                {records.user_id && records._token ? (
                  <Nav className="">
                    <NavDropdown
                      title={
                        <Avatar size="large" className="header-avatar">
                          {userdetails.first_name && (
                            <>{Array.from(userdetails.first_name)[0]}</>
                          )}
                        </Avatar>
                      }
                      id="navbarScrollingDropdown"
                      className="navbar-top-menu costimize-navbar-top-menu"
                    >
                      {userdetails.first_name ? (
                        <>
                          <label className="menu-topicon-username">
                            {userdetails.first_name}
                          </label>
                          <NavDropdown.Divider />
                        </>
                      ) : (
                        <></>
                      )}

                      {localStorage
                        .getItem("CognitoIdentityServiceProvider.offering")
                        ?.toLowerCase() === "master" ||
                      localStorage
                        .getItem("CognitoIdentityServiceProvider.offering")
                        ?.toLowerCase() === "admin" ? (
                        <>
                          {localStorage
                            .getItem("CognitoIdentityServiceProvider.offering")
                            ?.toLowerCase() === "master" &&
                          window.location.pathname !== "/role" ? (
                            <>
                              <NavDropdown.Item
                                className="navbar-top-submenu"
                                onClick={() => navigate("/admin-dashboard")}
                              >
                                Dashboard
                              </NavDropdown.Item>
                            </>
                          ) : (
                            " "
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {window.location.pathname !== "/azure-auth" && (
                        <NavDropdown.Item
                          className="navbar-top-submenu"
                          onClick={() => navigate("/role")}
                        >
                          My Role
                        </NavDropdown.Item>
                      )}

                      <NavDropdown.Item
                        className="navbar-top-submenu"
                        onClick={userLogout}
                      >
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                ) : (
                  <>
                    <Nav className="">
                      {window.location.pathname !== "/signin" ? (
                        <Nav.Link
                          className="navlink-signin d-flex align-items-center"
                          onClick={() => {
                            navigate("/signin");
                          }}
                        >
                          <span id="sign-id" className="signin">
                            {" "}
                            Sign In
                          </span>
                        </Nav.Link>
                      ) : (
                        <> </>
                      )}
                      {window.location.pathname !== "/signup" ? (
                        <Nav.Link
                          onClick={() => {
                            navigate("/signup");
                          }}
                        >
                          <span>
                            <Button
                              className="signup-button"
                              onClick={() => navigate("/signup")}
                              id="sign-up-id"
                            >
                              Sign Up
                            </Button>{" "}
                          </span>
                        </Nav.Link>
                      ) : (
                        <></>
                      )}
                    </Nav>
                  </>
                )}
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </>
  );
}

export default HomeLandingHeaderNew;
