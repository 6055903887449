import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from 'react-redux';
import { listApplicationSpend } from "../../action/costimizev2DashboardAction";
import { Spin } from 'antd';


const AssetBarChart = () => {
  // const { companyName } = useSelector((state) => state.user);
  const companyName = localStorage.getItem("CognitoIdentityServiceProvider.company");
  const {   selectedTopdate, selectedEnvironment, selectedAccount, selectedCloud, selectedApplication,  selectedservices, selectedos, selectedinfra,selecteddb } = useSelector((state) => state.constimize);
  const dispatch = useDispatch();
  const [chartvalue, setChartValue] = useState([]);
  const [chartdate, setChartDate] = useState([]);
  const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const [preloader, setPreloader] = useState(true);


  useEffect(() => {

   
  //month
  const datelist = [];
  const month_list = [];
  let currentDate = new Date();
  let lastThreeMonthsDate = new Date();
  lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
  for (let i = 0; i < 3; i++) {
    let date = new Date(lastThreeMonthsDate.getFullYear(), lastThreeMonthsDate.getMonth() + i, 1);
    datelist.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    month_list.push(`${monthNamesShort[date.getMonth()]}-${('' + date.getFullYear()).substr(2)}`);
  }
  setChartDate(month_list);
  var apivalue = {
      environment : selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      date: datelist.toString()
  }

    dispatch(listApplicationSpend({companyName, apivalue}))
    .unwrap()
    .then(({data}) => {
      setPreloader(false);
      const applicationlist = data.newfunctionquery16.map(v => v.application);
      var application = new Set([...applicationlist]);
      application = [...application];

      var chartvalue = application.map((applicationname) => {
            return {
              name: applicationname,
              data: []
            }
          });
      datelist.map((date) => {
        const environmentvmfiltered = data.newfunctionquery16.filter(datas => {
            return (new Date(datas.report_month).getMonth() + 1) === (new Date(date).getMonth() + 1);
        });

        chartvalue.map((application_data) => {
          const application_spend = environmentvmfiltered.filter(app_datas => {
              return app_datas.application === application_data.name;
          });
          var spend = 0;
          if(application_spend.length > 0){
            spend = Math.round(application_spend[0].spend);
          }
          application_data.data.push(spend);
        });
        setChartValue(chartvalue);
      })
    })
    .catch(err => {
      console.log(err);
    });
  },[selectedEnvironment, selectedAccount, selectedCloud, selectedApplication, selectedservices, selectedos, selectedinfra, selecteddb]);

    const options = {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: true
          }
        },
        colors:['#7e909a','#1c4e80','#ea6a47','#0091d5','#ced2cc','#23282d','#4cb5f5','#1f3f49','#d32d41','#6ab187','#ac3e31', '#484848', '#dbae58', '#dadada', '#20283e', '#488a99'],
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10
          },
        },
        xaxis: {
          categories: chartdate,
          labels: {
            style: {
                fontWeight: 'bold'
            },
          }
        },
        yaxis: {
          labels: {
              style: {
                  fontWeight: 'bold'
              },
              formatter: (num) => { 
                return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
               },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (num) => { 
            return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
           },
           style: {
              fontSize: '9px',
          },
        },
        legend: {
            show: false,
            offsetY: 0,
            position: 'bottom'
          },
        fill: {
          opacity: 1
        }
      };
    
    const series = chartvalue;
    return(
        <>
          <Spin tip="Loading..." spinning={preloader} className="plat-spinning">
            <ReactApexChart options={options} series={series} type="bar" height={220} />
          </Spin>
           
        </>
    )
}

export default AssetBarChart;