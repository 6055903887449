import { Card, Col, Row, Select, Spin } from "antd";
import ReactApexChart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";

import { useDispatch, useSelector } from "react-redux";
import {
  assessosspread,
  assessdbspread,
  assessdcspread,
  assesscoreram,
  assessstorage,
  getAccessVMCount,
  getOperateRAMUtil,
  assessdr,
  assessmonitor,
  assessramutil,
  assessstorageutil,
  assessis,
} from "../../../components/action/graphql/a2crover/A2CReducer";
function Assetsperspective() {
  const dispatch = useDispatch();
  const [osspread, setOsspread] = useState([]);
  const [dbspread, setDbspread] = useState([]);
  const [dcspread, setDcspread] = useState([]);
  const [isShowSpinner, setIsShowSpinner] = useState(true);
  const [assesscore, setAssesscoreram] = useState([]);
  const [assessstoreage, setAssessstorage] = useState([]);
  const [assessdrimp, setAssessdrimp] = useState([]);
  const [assessmon, setAssessmon] = useState([]);
  const [assessstoreutil, setAssessstoreutil] = useState([]);
  const [assessmemoryutil, setAssessramutil] = useState([]);
  const company = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const labels = osspread.map((item) => item.operating_system);
  const labels_os = osspread.map((item) => item.operating_system);
  const values = osspread.map((item) => item.os_count);
  const numericValues = values.map((value) => parseInt(value, 10));
  const series_os = numericValues;
  const {
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  } = useSelector((store) => store.a2cRover);
  // console.log(selectedEnvironment);
  const input = {
    application: selectedApplication.toString(),
    company: company,
    data_center_name: selecteddc.toString(),
    db: selecteddb.toString(),
    environment: selectedEnvironment.toString(),
    os: selectedos.toString(),
  };
  useEffect(() => {
    dispatch(assessosspread(input))
      .unwrap()
      .then(({ data }) => {
        if (data.assess_os_spread) {
          const record = data.assess_os_spread.map((datas) => {
            return {
              operating_system: datas.operating_system,
              os_count: datas.os_count,
            };
          });
          setOsspread(record);
        } else {
        }

        setIsShowSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setIsShowSpinner(false);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  const labels_db = dbspread.map((item) => item.database);
  const value_db = dbspread.map((item) => item.resource_count);
  const numericValues_db = value_db.map((value) => parseInt(value, 10));
  const series_db = numericValues_db;
  const chartData_db = {
    labels: labels_db,
    series: series_db,
  };

  const options_OS = {
    chart: {
      type: "pie",
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },

    labels: labels_os,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "23px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#ff6a2f",
              offsetY: -5,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "",
              color: "#ff6a2f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    dataLabels: {
      formatter: function (val) {
        // Remove the percentage symbol from the labels
        return val.toFixed(0);
      },
    },
    stroke: {
      show: false,
    },
    tooltip: {
      enabled: true,
      theme: "light",
      y: {
        formatter: function (value) {
          return value + " Nos";
        },
      },
      fillSeriesColor: false, // You can use 'light' or 'dark' theme

      enabled: false,
      // formatter: function (val, opts) {
      //   return Math.round(val);
      // },

      enabled: true,
      formatter: function (val, opts) {
        return Math.round(val);
      },
    },
  };
  const options_DB = {
    chart: {
      type: "pie",
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },

    labels: labels_db,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
        },
      },
    ],
    legend: {
      position: "bottom",
    },
    dataLabels: {
      formatter: function (val) {
        // Remove the percentage symbol from the labels
        return val.toFixed(0);
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      y: {
        formatter: function (value) {
          return value + " Nos";
        },
      },
      fillSeriesColor: false, // You can use 'light' or 'dark' theme
      formatter: function (val, opts) {
        return Math.round(val);
      },
    },
    stroke: {
      show: false,
    },
  };
  const labels_dc = dcspread.map((item) => item.data_centre);
  const value_dc = dcspread.map((item) => item.resource_count);
  const numericValues_dc = value_dc.map((value) => parseInt(value, 10));
  const series_dc = numericValues_dc;
  const options_DC = {
    chart: {
      type: "donut",
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      y: {
        formatter: function (value) {
          return value + " Nos";
        },
      },
      fillSeriesColor: false, // You can use 'light' or 'dark' theme

      enabled: false,

      enabled: true,
      formatter: function (val, opts) {
        return Math.round(val);
      },
    },
    stroke: {
      show: false,
    },

    labels: labels_dc,

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
        },
      },
    ],
    legend: {
      position: "bottom",
      colors: "#ffff",
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return Math.round(val);
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "23px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#ff6a2f",
              offsetY: -5,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "",
              color: "#ff6a2f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
  };
  options_DC.legend.labels = {
    colors: "#FFFFFF", // Set a single color for all legend items
  };
  options_OS.legend.labels = {
    colors: "#FFFFFF", // Set a single color for all legend items
  };
  options_DB.legend.labels = {
    colors: "#FFFFFF", // Set a single color for all legend items
  };
  options_DC.legend.labels = {
    colors: "#FFFFFF", // Set a single color for all legend items
  };
  options_OS.legend.labels = {
    colors: "#FFFFFF", // Set a single color for all legend items
  };
  options_DB.legend.labels = {
    colors: "#FFFFFF", // Set a single color for all legend items
  };
  const chartData_os = {
    labels: labels_os,
    series: series_os,
  };
  const [ramUtil, setRAMUtil] = useState([]);

  const getAPIOperateRAMUtil = () => {
    dispatch(getOperateRAMUtil())
      .unwrap()
      .then(({ data }) => {
        const record = data.operate_ram_util.map((datas) => {
          return {
            x: datas.application,
            y: parseInt(datas.mem_util, 10),
          };
        });
        setRAMUtil([{ data: record }]);
        setIsShowSpinner(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAPIOperateRAMUtil();

    dispatch(assessdbspread(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assess_db_spread.map((datas) => {
          return {
            database: datas.database,
            resource_count: datas.resource_count,
          };
        });
        setDbspread(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);
  useEffect(() => {
    dispatch(assessdcspread(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assess_dc_spread.map((datas) => {
          return {
            data_centre: datas.data_centre,
            resource_count: datas.resource_count,
          };
        });
        setDcspread(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);
  useEffect(() => {
    dispatch(assesscoreram(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assess_coreram.map((datas) => {
          return {
            cpu: datas.cpu,
            memory: datas.memory,
            resource_name: datas.resource_name,
          };
        });
        setAssesscoreram(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  const categories = assesscore.map((item) => item.resource_name);
  const cpuData = assesscore.map((item) => parseFloat(item.cpu) || 0);
  const memoryData = assesscore.map((item) => parseFloat(item.memory) || 0);
  const storageData = assesscore.map(
    (item) => parseFloat(item.storage_as) || 0
  );
  const chartData_core = [
    {
      name: "CPU",
      data: cpuData,
    },
    {
      name: "Memory",
      data: memoryData,
    },
  ];
  const chartOptions_core = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    stroke: {
      show: false,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },

    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: [
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
          ],
        },
      },
    },
    legend: {
      position: "bottom",
      labels: {
        colors: "white", // Set the legend label text color to white
      },
    },
    // yaxis: {
    //   scrollbar: {
    //     enabled: true,
    //   },
    // },
    yaxis: {
      labels: {
        maxWidth: 80,
        style: {
          colors: "white", // Set the y-axis values color to white
        },
      },
    },
  };
  useEffect(() => {
    dispatch(assessstorage(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assess_storage.map((datas) => {
          return {
            resource_name: datas.resource_name,
            storage: datas.storage,
          };
        });

        setAssessstorage(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);
  const categories_storage = assessstoreage.map((item) => item.resource_name);
  const storage = assessstoreage.map((item) => parseFloat(item.storage) || 0);

  const chartData_storage = [
    {
      name: "Storage",
      data: storage,
    },
  ];
  const chartOptions_storage = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },

    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories_storage,
      labels: {
        style: {
          colors: [
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
            "#FFFFFF",
          ],
        },
      },
    },
    // yaxis: {
    //   scrollbar: {
    //     enabled: true,
    //   },
    // },
    yaxis: {
      labels: {
        maxWidth: 80,
        style: {
          colors: "white", // Set the y-axis values color to white
        },
      },
    },
  };
  useEffect(() => {
    dispatch(assessdr(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assess_dr_implemetation.map((datas) => {
          return {
            dr_implementation: datas.dr_implementation,
            resource_count: datas.resource_count,
          };
        });

        setAssessdrimp(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);
  const categories_dr = assessdrimp.map((item) => item.dr_implementation);
  const dr_count = assessdrimp.map(
    (item) => parseFloat(item.resource_count) || 0
  );

  const chartData_dr = [
    {
      name: "Count",
      data: dr_count,
    },
  ];
  const bar_options_dr = {
    chart: {
      type: "bar",
      stacked: true,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: categories_dr,
      labels: {
        style: {
          colors: "white",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "white",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
    },
  };

  useEffect(() => {
    dispatch(assessmonitor(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assess_monitoring_implementation.map((datas) => {
          return {
            monitoring_status: datas.monitoring_status,
            application_count: datas.application_count,
          };
        });

        setAssessmon(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  const categories_mon = assessmon.map((item) => item.monitoring_status);
  const app_count = assessmon.map(
    (item) => parseFloat(item.application_count) || 0
  );

  const chartData_mon = [
    {
      name: "Count",
      data: app_count,
    },
  ];
  const bar_options_mon = {
    chart: {
      type: "bar",
      stacked: true,
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: categories_mon,
      labels: {
        style: {
          colors: "white",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "white",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
    },
  };
  useEffect(() => {
    dispatch(assessstorageutil(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assess_storage_util.map((datas) => {
          return {
            application: datas.application,
            core: datas.core,
          };
        });
        // console.log(record)
        setAssessstoreutil(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);
  useEffect(() => {
    dispatch(assessramutil(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assess_Ram_util.map((datas) => {
          return {
            application: datas.application,
            memory: datas.memory,
          };
        });
        setAssessramutil(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  const chartOptionscore = {
    chart: {
      type: "treemap",
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },

    series: [
      {
        data: assessstoreutil.map((item) => ({
          x: item.application,
          y: parseFloat(item.core),
        })),
      },
    ],
    colors: ["#FF0000"],
  };
  const chartOptionsram = {
    chart: {
      type: "treemap",
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },

    series: [
      {
        data: assessmemoryutil.map((item) => ({
          x: item.application,
          y: parseFloat(item.memory),
        })),
      },
    ],
    colors: ["#FF0000"],
  };
  const treeoptions = {
    legend: {
      show: false,
    },

    chart: {
      height: 350,

      type: "treemap",
    },

    plotOptions: {
      treemap: {
        distributed: true,

        enableShades: false,
      },
    },

    colors: ["#EED9C1", "#F1C89A", "#FF6A2F"],
  };
  const [vmCount, setVMCount] = useState({
    resource_count: 0,
    core: 0,
    memory: 0,
    storage_as: 0,
  });

  useEffect(() => {
    dispatch(getAccessVMCount(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        setVMCount(data.assess_VM_resource_count[0]);
      })

      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);
  const roundedValue = Math.round(vmCount.resource_count);
  const roundedValue2 = Math.round(vmCount.core);
  const roundedValue3 = Math.round(vmCount.memory);
  const roundedValue4 = Math.round(vmCount.storage_as);
  // sankeyChart
  const isassess = [["From", "To", "Server"]];
  const [assessischart, setassessischart] = useState([]);

  const options_sankey = {
    sankey: {
      node: {
        label: {
          color: "white",
        },
      },
    },
  };
  useEffect(() => {
    dispatch(assessis(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.assessvm_appwise.map((datas) => {
          return {
            application: datas.application,
            data_center_name: datas.data_center_name,
            vm_count: datas.vm_count,
          };
        });
        setassessischart(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selecteddc,
    selecteddb,
    selectedApplication,
    selectedos,
  ]);

  assessischart.forEach((record, index) => {
    isassess.push([
      record.application,
      record.data_center_name,
      parseInt(record.vm_count),
    ]);
  });
  return (
    <>
      <div className="">
        <div className="text-white" style={{ color: "white" }}>
          <Row gutter={[16, 16]}>
            <Col xxl={3} xl={3} lg={3} md={3} sm={24} xs={24}>
              <Card className="assets-perspective-card mb-2">
                <div className="assets-header">VM</div>
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">{roundedValue}</span>
                  )}
                </div>
              </Card>
              <Card className="assets-perspective-card mb-2">
                <div className="assets-header">Core</div>
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">{roundedValue2}</span>
                  )}
                </div>
              </Card>
              <Card className="assets-perspective-card mb-2">
                <div className="assets-header">RAM in GB</div>
                <div className="d-flex justify-content-center">
                  {isShowSpinner ? (
                    <Spin />
                  ) : (
                    <span className="count">{roundedValue3}</span>
                  )}
                </div>
              </Card>
              <Card className="assets-perspective-card ">
                <div className="assets-header">Storage</div>
                <div className="d-flex justify-content-center">
                  <span className="count">{roundedValue4}</span>
                </div>
              </Card>
            </Col>
            <Col xxl={7} xl={7} lg={7} md={7} sm={24} xs={24}>
              <Card
                title="Top 5 Resource Count in DC(%)"
                className="operate-cards  "
              >
                {isShowSpinner ? (
                  <div className=" d-flex justify-content-center align-items-center spinner-height">
                    <Spin />
                  </div>
                ) : (
                  <ReactApexChart
                    options={options_DC}
                    series={series_dc}
                    type="donut"
                    height={350}
                    className="donutchart"
                  />
                )}
              </Card>
            </Col>
            <Col xxl={7} xl={7} lg={7} md={7} sm={24} xs={24}>
              <Card title="Top 5 OS Spread(%)" className="operate-cards  ">
                {isShowSpinner ? (
                  <div className=" d-flex justify-content-center align-items-center spinner-height">
                    <Spin />
                  </div>
                ) : (
                  <ReactApexChart
                    options={options_OS}
                    series={series_os}
                    type="donut"
                    height={350}
                    className="donutchart"
                  />
                )}
              </Card>
            </Col>
            <Col xxl={7} xl={7} lg={7} md={7} sm={24} xs={24}>
              <Card title="Database Spread(%)" className="operate-cards   ">
                {isShowSpinner ? (
                  <div className=" d-flex justify-content-center align-items-center spinner-height">
                    <Spin />
                  </div>
                ) : (
                  <ReactApexChart
                    options={options_DB}
                    series={chartData_db.series}
                    type="pie"
                    height={350}
                    className="donutchart"
                  />
                )}
              </Card>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Card
                title="Resource Count Vs Planned DR strategy "
                className="operate-cards"
              >
                {isShowSpinner ? (
                  <div className=" d-flex justify-content-center align-items-center spinner-height">
                    <Spin />
                  </div>
                ) : (
                  <ReactApexChart
                    className=""
                    options={bar_options_dr}
                    series={chartData_dr}
                    type="bar"
                    height={350}
                  />
                )}
              </Card>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Card
                title="Resource Count Vs Monitoring Strategy"
                className="operate-cards "
              >
                {isShowSpinner ? (
                  <div className=" d-flex justify-content-center align-items-center spinner-height">
                    <Spin />
                  </div>
                ) : (
                  <ReactApexChart
                    className=""
                    options={bar_options_mon}
                    series={chartData_mon}
                    type="bar"
                    height={350}
                  />
                )}
              </Card>
            </Col>
          </Row>
          <br />
          {/* sankey chart */}
          <div>
            {/* <Card className="operate-cards " title="Application Moderniszation">
              <Row gutter={[16, 16]} type="flex" justify={"center"}>
                <Col span={12}>
                  <p className="text-white text-center">AS-IS</p>
                  <div className="p-3">
                    <Chart
                      chartType="Sankey"
                      // width="90%"
                      height="400px"
                      data={isassess}
                      options={options_sankey}
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <p className="text-white text-center">TO-BE</p>
                  <div className="p-3">
                    <Chart
                      chartType="Sankey"
                      // width="90%"
                      height="400px"
                      data={isassess}
                      options={options_sankey}
                    />
                  </div>
                </Col>
              </Row>
            </Card> */}
          </div>
          <br />
          <Row gutter={[16, 16]}>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Card
                title="Resource Wise Core/RAM Analysis"
                className="operate-cards"
              >
                {isShowSpinner ? (
                  <div className=" d-flex justify-content-center align-items-center spinner-height">
                    <Spin />
                  </div>
                ) : (
                  <ReactApexChart
                    options={chartOptions_core}
                    series={chartData_core}
                    type="bar"
                    overflowY="scroll"
                    // height={350}
                  ></ReactApexChart>
                )}
              </Card>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Card
                title="Resource Wise Storage Analysis"
                className="operate-cards "
              >
                {isShowSpinner ? (
                  <div className=" d-flex justify-content-center align-items-center spinner-height">
                    <Spin />
                  </div>
                ) : (
                  <ReactApexChart
                    options={chartOptions_storage}
                    series={chartData_storage}
                    type="bar"
                    overflowY="scroll"
                  ></ReactApexChart>
                )}
              </Card>
            </Col>
          </Row>
          <br />

          <Row gutter={[16, 16]}>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Card title="As-Is Core Utilization" className="operate-cards ">
                {isShowSpinner ? (
                  <div className=" d-flex justify-content-center align-items-center spinner-height">
                    <Spin />
                  </div>
                ) : (
                  <ReactApexChart
                    options={chartOptionscore}
                    series={chartOptionscore.series}
                    type="treemap"
                    height={600}
                  />
                )}
              </Card>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <Card title="As-Is RAM Utilization" className="operate-cards ">
                {isShowSpinner ? (
                  <div className=" d-flex justify-content-center align-items-center spinner-height">
                    <Spin />
                  </div>
                ) : (
                  <ReactApexChart
                    options={chartOptionsram}
                    series={chartOptionsram.series}
                    // options={treeoptions}
                    // series={ramUtil}
                    type="treemap"
                    height={600}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Assetsperspective;
