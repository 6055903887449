import client from "../apploClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_CUSTOMER_LOGO } from "../Graphql";
import { useErrorNavigation } from "../custom_hook/CustomHook";
export const headercustomerlogo = createAsyncThunk(
  // action type string
  "user/role",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const role = await client.mutate({
        mutation: GET_CUSTOMER_LOGO,
        variables: {
          company: data.company_name,
        },
      });
      return role;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
