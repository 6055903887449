import { memo, useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  message,
  Switch,
  Checkbox,
} from "antd";
import DrawerPlat from "../../properties/Drawer/DrawerPlat";
import { Icon } from "@iconify/react";

const RoleDetailsView = (props) => {
  const [roledetails, setRoleDetails] = useState(props.selectedrecords);
  const [accessdetails, setAccessDetails] = useState({
    user_management: {
      access: 0,
      read: 0,
      write: 0,
    },
    role_management: {
      access: 0,
      read: 0,
      write: 0,
    },
    account_onboarding: {
      access: 0,
      read: 0,
      write: 0,
    },
    quick_glance: {
      access: 0,
      read: 0,
      write: 0,
    },
    budget_spend: {
      access: 0,
      read: 0,
      write: 0,
    },
    cost_savings: {
      access: 0,
      read: 0,
      write: 0,
    },
    compliance: {
      access: 0,
      read: 0,
      write: 0,
    },
    asset_spend: {
      access: 0,
      read: 0,
      write: 0,
    },
    savings_model: {
      access: 0,
      read: 0,
      write: 0,
    },
    budget_planning: {
      access: 0,
      read: 0,
      write: 0,
    },
    instance_runtime: {
      access: 0,
      read: 0,
      write: 0,
    },
  });
  useEffect(() => {
    // console.log(props);
    setRoleDetails(props.selectedrecords);
    const user_management = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "user_management"
    );
    const role_management = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "role_management"
    );
    const account_onboarding = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "account_onboarding"
    );
    const quick_glance = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "quick_glance"
    );
    const budget_spend = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "budget_spend"
    );
    const cost_savings = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "cost_savings"
    );
    const compliance = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "compliance"
    );
    const asset_spend = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "asset_spend"
    );
    const savings_model = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "savings_model"
    );
    const budget_planning = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "budget_planning"
    );
    const instance_runtime = props.selectedrecords.resources.filter(
      (datas) => datas.blocks === "instance_runtime"
    );

    setAccessDetails({
      user_management:
        user_management.length > 0
          ? {
              access: user_management[0].access,
              read: user_management[0].read,
              write: user_management[0].write,
            }
          : {
              access: 0,
              read: 0,
              write: 0,
            },
      role_management:
        role_management.length > 0
          ? {
              access: role_management[0].access,
              read: role_management[0].read,
              write: role_management[0].write,
            }
          : {
              access: 0,
              read: 0,
              write: 0,
            },
      account_onboarding:
        account_onboarding.length > 0
          ? {
              access: account_onboarding[0].access,
              read: account_onboarding[0].read,
              write: account_onboarding[0].write,
            }
          : {
              access: 0,
              read: 0,
              write: 0,
            },
      quick_glance:
        quick_glance.length > 0
          ? {
              access: quick_glance[0].access,
              read: quick_glance[0].read,
              write: quick_glance[0].write,
            }
          : {
              access: 0,
              read: 0,
              write: 0,
            },
      budget_spend:
        budget_spend.length > 0
          ? {
              access: budget_spend[0].access,
              read: budget_spend[0].read,
              write: budget_spend[0].write,
            }
          : {
              access: 0,
              read: 0,
              write: 0,
            },
      cost_savings:
        cost_savings.length > 0
          ? {
              access: cost_savings[0].access,
              read: cost_savings[0].read,
              write: cost_savings[0].write,
            }
          : {
              access: 0,
              read: 0,
              write: 0,
            },
      compliance:
        compliance.length > 0
          ? {
              access: compliance[0].access,
              read: compliance[0].read,
              write: compliance[0].write,
            }
          : {
              access: 0,
              read: 0,
              write: 0,
            },
      asset_spend:
        asset_spend.length > 0
          ? {
              access: asset_spend[0].access,
              read: asset_spend[0].read,
              write: asset_spend[0].write,
            }
          : {
              access: 0,
              read: 0,
              write: 0,
            },
      savings_model:
        savings_model.length > 0
          ? {
              access: savings_model[0].access,
              read: savings_model[0].read,
              write: savings_model[0].write,
            }
          : {
              access: 0,
              read: 0,
              write: 0,
            },
      budget_planning:
        budget_planning.length > 0
          ? {
              access: budget_planning[0].access,
              read: budget_planning[0].read,
              write: budget_planning[0].write,
            }
          : {
              access: 0,
              read: 0,
              write: 0,
            },
      instance_runtime:
        instance_runtime.length > 0
          ? {
              access: instance_runtime[0].access,
              read: instance_runtime[0].read,
              write: instance_runtime[0].write,
            }
          : {
              access: 0,
              read: 0,
              write: 0,
            },
    });
  }, [props]);
  const changeDrawerStatus = () => {
    props.setDrawerDetailsStatus(false);
  };

  return (
    <>
      <DrawerPlat
        drawerstatus={props.drawerdetailsstatus}
        deletestatus={false}
        changeDrawerStatus={changeDrawerStatus}
        title="Role Information"
      >
        <Row gutter={[16, 16]}>
          <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
            <lable className="role-permission-head-title">Role : </lable>
            <span className="text-white">{roledetails.role_name}</span>
          </Col>
          <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
            <lable className="role-permission-head-title">Offering : </lable>
            <span className="text-white">{roledetails.offering}</span>
          </Col>
          <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
            <lable className="role-permission-head-title">
              Company Name :{" "}
            </lable>
            <span className="text-white">{roledetails.customer}</span>
          </Col>
          <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
            <lable className="role-permission-head-title">
              Role Category :&nbsp;
            </lable>
            <span className="text-white">{roledetails.role_category}</span>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="mt-3">
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <lable className="role-head-title">Specify Permissions</lable>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="mt-3">
          <Col xxl={18} xl={18} lg={18} md={18} sm={20} xs={20}>
            <lable className="role-permission-head-title">Admin Panel</lable>
          </Col>
          <Col
            xxl={3}
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            className="text-center"
          >
            <lable className="role-permission-access">Read</lable>
          </Col>
          <Col
            xxl={3}
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            className="text-center"
          >
            <lable className="role-permission-access ">Write</lable>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="mt-2">
          <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={20}>
            <span className="text-white">User Management</span>
          </Col>
          <Col
            xxl={2}
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            className="text-center"
          >
            <lable className="role-permission-access">
              {accessdetails.user_management.read ? (
                <Icon icon="charm:tick" />
              ) : (
                <Icon icon="tabler:x" />
              )}
            </lable>
          </Col>
          <Col
            xxl={2}
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            className="text-center"
          >
            <lable className="role-permission-access">
              {accessdetails.user_management.write ? (
                <Icon icon="charm:tick" />
              ) : (
                <Icon icon="tabler:x" />
              )}
            </lable>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="mt-2">
          <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={20}>
            <span className="text-white">Role Management</span>
          </Col>
          <Col
            xxl={2}
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            className="text-center"
          >
            <lable className="role-permission-access">
              {accessdetails.role_management.read ? (
                <Icon icon="charm:tick" />
              ) : (
                <Icon icon="tabler:x" />
              )}
            </lable>
          </Col>
          <Col
            xxl={2}
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            className="text-center"
          >
            <lable className="role-permission-access">
              {accessdetails.role_management.write ? (
                <Icon icon="charm:tick" />
              ) : (
                <Icon icon="tabler:x" />
              )}
            </lable>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="mt-2">
          <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={20}>
            <span className="text-white">Account Onboarding</span>
          </Col>
          <Col
            xxl={2}
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            className="text-center"
          >
            <lable className="role-permission-access">
              {accessdetails.account_onboarding.read ? (
                <Icon icon="charm:tick" />
              ) : (
                <Icon icon="tabler:x" />
              )}
            </lable>
          </Col>
          <Col
            xxl={2}
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            className="text-center"
          >
            <lable className="role-permission-access">
              {accessdetails.account_onboarding.write ? (
                <Icon icon="charm:tick" />
              ) : (
                <Icon icon="tabler:x" />
              )}
            </lable>
          </Col>
        </Row>
        <div>
          <Row gutter={[16, 16]} className="mt-3">
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <lable className="role-permission-head-title">CostDeMaze</lable>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-3">
            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
              {accessdetails.quick_glance.access ? (
                <Icon icon="charm:tick" />
              ) : (
                <Icon icon="tabler:x" />
              )}
              Quick Glance
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
              {accessdetails.asset_spend.access ? (
                <Icon icon="charm:tick" />
              ) : (
                <Icon icon="tabler:x" />
              )}
              Assets & Spend
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
              {accessdetails.savings_model.access ? (
                <Icon icon="charm:tick" />
              ) : (
                <Icon icon="tabler:x" />
              )}
              Savings Model
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}></Col>

            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
              {accessdetails.budget_planning.access ? (
                <Icon icon="charm:tick" />
              ) : (
                <Icon icon="tabler:x" />
              )}
              Budget Planning
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
              {accessdetails.instance_runtime.access ? (
                <Icon icon="charm:tick" />
              ) : (
                <Icon icon="tabler:x" />
              )}
              Instance Runtime
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-3">
            <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24}>
              <lable className="role-offering-head-title">Quick Glance</lable>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-2">
            <Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={5}>
              {accessdetails.budget_spend.access ? (
                <div className="d-flex">
                  <Icon icon="charm:tick" />{" "}
                  <label className="role-offering-list-title">
                    Budget/Spend
                  </label>
                </div>
              ) : (
                <div className="d-flex">
                  <Icon icon="tabler:x" />
                  <label className="role-offering-list-title">
                    Budget/Spend
                  </label>
                </div>
              )}
            </Col>
            <Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={5}>
              {" "}
              {accessdetails.cost_savings.access ? (
                <Icon icon="charm:tick" />
              ) : (
                <Icon icon="tabler:x" />
              )}
              <label className="role-offering-list-title">Cost Savings</label>
            </Col>
            <Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={5}>
              {" "}
              {accessdetails.compliance.access ? (
                <Icon icon="charm:tick" />
              ) : (
                <Icon icon="tabler:x" />
              )}
              <label className="role-offering-list-title">Compliance</label>
            </Col>
            <Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={5}>
              {" "}
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="text-center"
            >
              <lable className="role-permission-access">
                {accessdetails.quick_glance.read ? (
                  <Icon icon="charm:tick" />
                ) : (
                  <Icon icon="tabler:x" />
                )}
              </lable>
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="text-center"
            >
              <lable className="role-permission-access">
                {accessdetails.quick_glance.write ? (
                  <Icon icon="charm:tick" />
                ) : (
                  <Icon icon="tabler:x" />
                )}
              </lable>
            </Col>
          </Row>
          {/* <Row gutter={[16, 16]} className="mt-3">
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <lable className="role-offering-head-title">Assets & Spend</lable>
            </Col>
          </Row> */}
          <Row gutter={[16, 16]} className="mt-2">
            <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={20}>
              <label className="role-offering-list-title">Assets & Spend</label>
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="text-center"
            >
              <lable className="role-permission-access">
                {accessdetails.asset_spend.read ? (
                  <Icon icon="charm:tick" />
                ) : (
                  <Icon icon="tabler:x" />
                )}
              </lable>
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="text-center"
            >
              <lable className="role-permission-access">
                {accessdetails.asset_spend.write ? (
                  <Icon icon="charm:tick" />
                ) : (
                  <Icon icon="tabler:x" />
                )}
              </lable>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-2">
            <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={20}>
              <label className="role-offering-list-title">Savings Model</label>
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="text-center"
            >
              <lable className="role-permission-access">
                {accessdetails.savings_model.read ? (
                  <Icon icon="charm:tick" />
                ) : (
                  <Icon icon="tabler:x" />
                )}
              </lable>
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="text-center"
            >
              <lable className="role-permission-access">
                {accessdetails.savings_model.write ? (
                  <Icon icon="charm:tick" />
                ) : (
                  <Icon icon="tabler:x" />
                )}
              </lable>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-2">
            <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={20}>
              <label className="role-offering-list-title">
                Budget Planning
              </label>
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="text-center"
            >
              <lable className="role-permission-access">
                {accessdetails.budget_planning.read ? (
                  <Icon icon="charm:tick" />
                ) : (
                  <Icon icon="tabler:x" />
                )}
              </lable>
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="text-center"
            >
              <lable className="role-permission-access">
                {accessdetails.budget_planning.write ? (
                  <Icon icon="charm:tick" />
                ) : (
                  <Icon icon="tabler:x" />
                )}
              </lable>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-2">
            <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={20}>
              <label className="role-offering-list-title">
                Instance Runtime
              </label>
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="text-center"
            >
              <lable className="role-permission-access">
                {accessdetails.instance_runtime.read ? (
                  <Icon icon="charm:tick" />
                ) : (
                  <Icon icon="tabler:x" />
                )}
              </lable>
            </Col>
            <Col
              xxl={2}
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className="text-center"
            >
              <lable className="role-permission-access">
                {accessdetails.instance_runtime.write ? (
                  <Icon icon="charm:tick" />
                ) : (
                  <Icon icon="tabler:x" />
                )}
              </lable>
            </Col>
          </Row>
        </div>
      </DrawerPlat>
    </>
  );
};

export default memo(RoleDetailsView);
