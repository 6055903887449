import { memo, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  message,
  Modal,
  DatePicker,
  Tabs,
  Switch,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
// import DatePicker from "react-datepicker";
import {
  editsaving,
  listunrealisedtotracking,
  updaterealisedtable,
} from "../../../action/costimizeV2Active";
import TextArea from "antd/es/input/TextArea";

const AssignDeferForm = (props) => {
  const dispatch = useDispatch();
  const [buttonloading, setButtonLoading] = useState(false);
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [startDate, setStartDate] = useState(new Date());
  const [form] = Form.useForm();
  const [isDefer, setIsDefer] = useState(false);
  const [deferForever, setdeferForever] = useState(false);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const changeDrawerStatus = () => {
    props.setDrawerStatus(false);
    setIsDefer(false);
    setdeferForever(false);
  };

  const handleSubmit = (values) => {
    form.resetFields();
    const date = `${new Date(values.targetdate).getFullYear()}-${
      new Date(values.targetdate).getMonth() + 1
    }-${new Date(values.targetdate).getDate()}`;
    const target_date = deferForever ? "2099-01-01" : date;
    const inputs = props.oldUpdateRecords;
    if (inputs.status === "Deferred") {
      const input = {
        application: inputs.applications,
        environment: inputs.environments,
        status: inputs.status,
        service: inputs.services,
        account_names: inputs.account_names,
        resource_sub_category: inputs.resource_sub_category,
        company_name: companyName,
        recommendation_rule: inputs.recommendation_rule,
        resource_id: inputs.resource_id,
        resource_name: inputs.resource_name,
        current_configuration: inputs.current_configuration,
        recommended_configuration: inputs.recommended_configuration,
        commitment: inputs.commitment,
        cloud: inputs.clouds,
        potential_savings: inputs.potential_savings,
        realised_savings: inputs.realised_savings,
        reason: values.reason,
        username: values.username,
        report_month: selectedTopdate,
        targetdate: values.targetdate,
        email: values.email,
      };
      dispatch(updaterealisedtable({ input }))
        .unwrap()
        .then((data) => {
          const status = data.data.update_savings_plan_recommendation[0].status;

          if (status === "Implementation Completed") {
          }
          message.success("Updated Successfully ");
          props.setDrawerStatus(false);
          setButtonLoading(false);
          props.setRecordsUpdate(!props.recordsupdate);
          return dispatch(
            editsaving({ inputs, values, companyName, target_date })
          ).unwrap();
        })
        .then((editSavingResult) => {
          message.success("Mail sent Successfully");
          props.setDrawerStatus(false);
          setButtonLoading(false);
          props.setRecordsUpdate(!props.recordsupdate);
        })
        .catch((err) => {
          console.log(err.message);
          message.error(err);
          setButtonLoading(false);
        });
    } else {
      setStartDate(new Date());
      dispatch(
        listunrealisedtotracking({ inputs, values, companyName, target_date })
      )
        .unwrap()
        .then((listUnrealisedResult) => {
          message.success("Added Successfully Done");
          return dispatch(
            editsaving({ inputs, values, companyName, target_date })
          ).unwrap();
        })
        .then((editSavingResult) => {
          message.success("Mail sent Successfully");
          props.setDrawerStatus(false);
          setButtonLoading(false);
          props.setRecordsUpdate(!props.recordsupdate);
        })
        .catch((err) => {
          console.log(err.message);
          message.error(err.message);
          setButtonLoading(false);
        });
    }
  };

  // const items = [
  //   {
  //     key: "1",
  //     label: `Assign`,
  //     children: (
  //       <Form
  //         layout="vertical"
  //         onFinish={handleSubmit}
  //         form={form}
  //         className="mt-4"
  //       >
  //         <Row gutter={[16, 16]}>
  //           <Col span={12}>
  //             <Form.Item
  //               name="username"
  //               label="Assignee Username"
  //               rules={[{ required: true, message: "Please Enter Username" }]}
  //             >
  //               <Input
  //                 className="antd-input-theme "
  //                 style={{
  //                   width: "100%",
  //                 }}
  //               />
  //             </Form.Item>
  //           </Col>
  //           <Col span={12}>
  //             <Form.Item
  //               name="email"
  //               label="Assignee Email"
  //               rules={[{ required: true, message: "Please Enter Email" }]}
  //             >
  //               <Input
  //                 className="antd-input-theme"
  //                 style={{
  //                   width: "100%",
  //                 }}
  //               />
  //             </Form.Item>
  //           </Col>
  //         </Row>
  //         <Row gutter={[16, 16]}>
  //           <Col span={12}>
  //             <Form.Item
  //               name="targetdate"
  //               label="Target Date"
  //               rules={[{ required: true, message: "Please Enter Targetdate" }]}
  //             >
  //               <DatePicker
  //                 className="datepicker"
  //                 selected={startDate}
  //                 onChange={(date) => setStartDate(date)}
  //               />
  //             </Form.Item>
  //           </Col>
  //         </Row>
  //         <Row gutter={[16, 16]}>
  //           <Col span={24}>
  //             <Form.Item>
  //               <Button
  //                 htmlType="button"
  //                 onClick={() => props.setDrawerStatus(false)}
  //                 className="savops-cancel-btn"
  //               >
  //                 Cancel
  //               </Button>
  //               <Button
  //                 type="primary"
  //                 htmlType="submit"
  //                 className="savops-submit-btn"
  //                 loading={buttonloading}
  //               >
  //                 Submit
  //               </Button>
  //             </Form.Item>
  //           </Col>
  //         </Row>
  //       </Form>
  //     ),
  //   },
  //   {
  //     key: "2",
  //     label: `Defer`,
  //     children: (
  //       <Form
  //         layout="vertical"
  //         onFinish={handleSubmit}
  //         form={form}
  //         className="mt-4"
  //       >
  //         <Row gutter={[16, 16]}>
  //           <Col span={12}>
  //             <Form.Item
  //               name="username"
  //               label="Username"
  //               rules={[{ required: true, message: "Please Enter Username" }]}
  //             >
  //               <Input
  //                 className="antd-input-theme "
  //                 style={{
  //                   width: "100%",
  //                 }}
  //               />
  //             </Form.Item>
  //           </Col>
  //           <Col span={12}>
  //             <Form.Item
  //               name="email"
  //               label="Email"
  //               rules={[{ required: true, message: "Please Enter Email" }]}
  //             >
  //               <Input
  //                 className="antd-input-theme"
  //                 style={{
  //                   width: "100%",
  //                 }}
  //               />
  //             </Form.Item>
  //           </Col>
  //         </Row>
  //         <Form.Item label="Reason" name="reason">
  //           <TextArea rows={3} />
  //         </Form.Item>

  //         <Row gutter={[16, 16]}>
  //           <Col span={12}>
  //             <Form.Item
  //               name="targetdate"
  //               label="Target Date"
  //               rules={[{ required: true, message: "Please Enter Targetdate" }]}
  //               style={{
  //                 width: "100%",
  //               }}
  //             >
  //               <DatePicker
  //                 className="datepicker"
  //                 selected={startDate}
  //                 onChange={(date) => setStartDate(date)}
  //                 width="100%"
  //               />
  //             </Form.Item>
  //           </Col>
  //         </Row>
  //         <Row gutter={[16, 16]}>
  //           <Col span={24}>
  //             <Form.Item className="mt-3">
  //               <Button
  //                 htmlType="button"
  //                 onClick={() => props.setDrawerStatus(false)}
  //                 className="savops-cancel-btn"
  //               >
  //                 Cancel
  //               </Button>
  //               <Button
  //                 type="primary"
  //                 htmlType="submit"
  //                 className="savops-submit-btn"
  //                 loading={buttonloading}
  //               >
  //                 Submit
  //               </Button>
  //             </Form.Item>
  //           </Col>
  //         </Row>
  //       </Form>
  //     ),
  //   },
  // ];

  return (
    <>
      <Modal
        className="costmodal"
        open={props.drawerstatus}
        onCancel={changeDrawerStatus}
        title="Savings Model"
        footer={null}
      >
        {/* <div className="d-flex align-items-center">
          <Tabs
            type="line"
            defaultActiveKey="1"
            items={items}
            className="savops-tabs"
          />
        </div> */}
        <Form
          layout="horizontal"
          onFinish={handleSubmit}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          form={form}
          className="mt-4"
          requiredMark={false}
        >
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: "Please Enter Username" }]}
          >
            <Input
              className="antd-input-theme "
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: "Please Enter Email" }]}
          >
            <Input
              className="antd-input-theme"
              style={{
                width: "100%",
              }}
            />
          </Form.Item>

          <Row gutter={[16, 16]}>
            <Col className="mt-1 d-flex justify-content-end" span={4}>
              <Switch
                defaultChecked
                checkedChildren="Track"
                unCheckedChildren="Defer"
                onChange={(checked) => {
                  setIsDefer(!checked);
                  setdeferForever(false);
                }}
              />
            </Col>
            {isDefer ? (
              <Col span={7}>
                <Form.Item
                  name="deferoption"
                  label="Defer :"
                  labelCol={{
                    span: 10,
                  }}
                  wrapperCol={{
                    span: 14,
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  <Switch
                    defaultChecked
                    checkedChildren="Until"
                    unCheckedChildren="Forever"
                    onChange={(checked) => {
                      setdeferForever(!checked);
                    }}
                  />
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
            <Col span={13}>
              {deferForever && isDefer ? (
                <Form.Item
                  name="targetdate"
                  label="Target Date"
                  style={{
                    width: "100%",
                  }}
                  labelCol={{ span: 9 }}
                  wrapperCol={{ span: 15 }}
                >
                  {/* <DatePicker
                  className="datepicker"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  // width="100%"
                /> */}
                  <DatePicker
                    className="assign-defer-form-datepicker"
                    placeholder="Forever"
                    disabled
                    onChange={(date, dateString) => setStartDate(date)}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name="targetdate"
                  label="Target Date"
                  rules={[
                    { required: true, message: "Please Enter Target date" },
                  ]}
                  style={{
                    width: "100%",
                  }}
                  labelCol={{ span: 9 }}
                  wrapperCol={{ span: 15 }}
                >
                  {/* <DatePicker
                                      className="datepicker"
                                      selected={startDate}
                                      onChange={(date) => setStartDate(date)}
                                      // width="100%"
                                    /> */}
                  <DatePicker
                    className="assign-defer-form-datepicker"
                    onChange={(date, dateString) => setStartDate(date)}
                  />
                </Form.Item>
              )}
            </Col>
          </Row>

          {/* </Col>
              </Row>
              <Row gutter={[16, 16]} className="mt-4">
                <Col span={24}> */}
          {/* </Col>
              </Row>
            </Col>
            <Col span={12}> */}
          {isDefer ? (
            <Form.Item label="Reason" name="reason">
              <TextArea rows={3} />
            </Form.Item>
          ) : (
            <></>
          )}
          {/* </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}> */}
          {/* <Form.Item className="mt-3"> */}
          <div className="d-flex justify-content-around mb-3">
            <Button
              htmlType="button"
              onClick={() => props.setDrawerStatus(false)}
              className="savops-cancel-btn"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="savops-submit-btn"
              loading={buttonloading}
            >
              Submit
            </Button>
          </div>
          {/* </Form.Item> */}
          {/* </Col>
          </Row> */}
        </Form>
      </Modal>
    </>
  );
};

export default memo(AssignDeferForm);
