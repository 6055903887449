import React from "react";
import { Card, Col, Collapse, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EscalationForm from "./EscalationForm";
import { Icon } from "@iconify/react";
function Escalation() {
    const [drawerstatus, setDrawerStatus] = useState(false);
    const [deletestatus, setDeleteStatus] = useState(false);
    const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
    const [updatestatus, setUpdateStatus] = useState(true);
    const [oldRecords, setOldRecords] = useState([]);
    const changeDrawerStatus = () => {
        setDrawerStatus(false);
    };
    const dataSource = [
        {
            key: '1',
            pointofescalation: "Leve1 1",
            name: 'Service Assurance',
            role: '24X7Support Team',
            emailaddress: "serviceassurance@1cloudhub.com",
            mobilenumber: " +91 7010921213",
        },
        {
            key: '2',
            pointofescalation: "Leve1 2",
            name: 'SPOC',
            role: 'SPOC',
            emailaddress: "satheesraj@1cloudhub.com",
            mobilenumber: "+91 7010921213",
        },
        {
            key: '3',
            pointofescalation: "Leve1 3",
            name: 'Guru Ragavendra Prasath PK',
            role: 'Service Delivery Lead',
            emailaddress: "guru@1cloudhub.com",
            mobilenumber: "+91 9944763081",
        },
    ];
    const columns = [
        {
            title: 'Point of Escalation',
            dataIndex: 'pointofescalation',
            key: 'pointofescalation',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Email Address',
            dataIndex: 'emailaddress',
            key: 'emailaddress',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobilenumber',
            key: 'mobilenumber',
        },
    ];
    const handleadd = () => {
        setDrawerStatus(true);
        setDeleteStatus(false);
    }
    const tableProps = {};
    return (
        <>
            <div>
                <EscalationForm
                    drawerstatus={drawerstatus}
                    changeDrawerStatus={changeDrawerStatus}
                    deletestatus={deletestatus}
                    setUpdateStatus={setUpdateStatus}
                    updatestatus={updatestatus}
                    setDrawerStatus={setDrawerStatus}
                    oldrecords={oldRecords}
                />
                <div onClick={handleadd} >
                    <button className="table-add-button float-right">
                        <Icon icon="material-symbols:add-circle-outline" />
                        Add
                    </button>
                </div>
              
                <div>
                    <Table
                        {...tableProps}
                        className="slm"
                        style={{ overflowX: "scroll" }}
                        columns={columns}
                        dataSource={dataSource}
                        pagination={true}
                    />
                </div>
            </div>

        </>
    )
}
export default Escalation;