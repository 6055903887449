import ReactApexChart from "react-apexcharts";
import moment from "moment";
import React from "react";
import { useState, useEffect } from "react";

const ProjectStatusChart = ({ daysDiff }) => {
  useEffect(() => {
    daysDiff.map((item) => {
      const startDate = item.start_date;
      const endDate = item.project_target_date;
    });
  }, [daysDiff]);

  const options = {
    chart: {
      height: 350,
      type: "rangeBar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        var a = moment(val[0]);
        var b = moment(val[1]);
        var diff = b.diff(a, "days");
        return diff + (diff > 1 ? " days" : " day");
      },
    },
    title: {
      text: `Project Status`,
      align: "center",
      margin: 0,
      offsetX: 0,
      offsetY: 10,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#595959",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 0, 100, 100],
      },
    },
    xaxis: {
      type: "datetime",
    },
    legend: {
      position: "top",
    },
  };

  const series = [
    {
      data: [
        {
          x: "CKYC Migration",
          y: [
            new Date("2023-01-25").getTime(),
            new Date("2023-02-25").getTime(),
          ],
        },
        {
          x: "CKYC Migration ",
          y: [
            new Date("2023-01-25").getTime(),
            new Date("2023-02-25").getTime(),
          ],
        },
        {
          x: "IpV6 Enablement",
          y: [
            new Date("2023-01-15").getTime(),
            new Date("2023-02-15").getTime(),
          ],
        },
        {
          x: "DataVault",
          y: [
            new Date("2023-01-18").getTime(),
            new Date("2023-02-23").getTime(),
          ],
        },
      ],
    },
  ];
  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="rangeBar"
        height={300}
      />
    </>
  );
};

export default ProjectStatusChart;
