import React from 'react'
import { Table, Divider } from 'antd';
const columns = [
  {
    title: 'Application',
    dataIndex: 'Application',
  },
  {
    title: 'Month',
    dataIndex: 'Month',
  },
  {
    title: 'Spend',
    dataIndex: 'Spend',
  },
];

const data = [
    {
      key: '1',
      Application: 'ABC-Prod',
      Month: 'June',
      Spend: 63908.35573891768,
    },
    {
        key: '2',
        Application: 'BAU',
        Month: 'June',
        Spend: 26714.02401892345,
    },
    {
        key: '3',
        Application: 'ABC-Dev',
        Month: 'June',
        Spend:  12849.023819781341,
    },
  ];


const DataTable = () => {
  return (
    <div>
        <Table columns={columns} dataSource={data} size="small" />
    </div>
  )
}

export default DataTable