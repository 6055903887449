import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RequestTime from './RequestTime';
import ResolutionTime from './ResolutionTime';

const SLA = () => {
    return(
        <>
            <Row>
                <Col>
                    <RequestTime />
                </Col>
                <Col>
                    <ResolutionTime />
                </Col>
            </Row>
        </>
    )
}

export default SLA;