import React, { useEffect, useState, useRef } from "react";
import { Table, Tooltip } from "antd";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { weeklyreportweekwisechartsummary } from "../../action/accountAction";
import ReactApexChart from "react-apexcharts";
import apexcharts from "apexcharts";
import "../../costimize_v2/costimize_v2.css";
function MicroChart({ data }) {
  const [chartImages, setChartImages] = useState([]);

  // useEffect(() => {
  //   const captureCharts = () => {
  //     const images = [];
  //     let dataArray = Array.isArray(data) ? data : [data];
  //     if (Array.isArray(dataArray)) {
  //       dataArray.forEach((chartData, element, index) => {
  //         setTimeout(() => {
  //           const chartElement = document.getElementById(`charts`);
  //           if (chartElement) {
  //             apexcharts
  //               .exec(`appdata`, index, "dataURI")
  //               .then(({ imgURI }) => {
  //                 images[index] = imgURI;
  //                 setChartImages(images);
  //                 console.log(chartImages);
  //               });
  //           }
  //         }, 0);
  //       });
  //     } else {
  //       console.log("error");
  //     }
  //   };

  //   captureCharts();
  // }, [data]);
  //Working but error
  useEffect(() => {
    const captureCharts = () => {
      const images = [];
      let dataArray = Array.isArray(data) ? data : [data]; // Convert to array if not already an array

      dataArray.forEach((chartData, index) => {
        setTimeout(() => {
          const chartElement = document.getElementById(`charts`);
          if (chartElement) {
            const execPromise = apexcharts.exec(`appdata`, index, "dataURI");
            if (execPromise && execPromise.then) {
              execPromise
                .then(({ imgURI }) => {
                  images[index] = imgURI;
                  setChartImages([...images]); // Update state with a new array reference
                 // Log the updated images array
                })
                .catch((error) => {
                  console.error("Error in promise:", error);
                });
            } else {
              console.error("apexcharts.exec() didn't return a promise");
            }
          }
        }, 0); // End of setTimeout
      }); // End of forEach
    }; // End of captureCharts function

    captureCharts();
  }, [data]);
  const isAllZero =
    parseFloat(data.fourth_week) === 0 &&
    parseFloat(data.pre_previous_week) === 0 &&
    parseFloat(data.previous_week) === 0 &&
    parseFloat(data.current_week) === 0 &&
    parseFloat(data.zeroth_week) === 0;

  if (isAllZero) {
    return null; // Return null or a placeholder if all values are zero
  }
  const formatNumberWithCommas = (number) => {
    const formattedNumber = Math.round(number).toLocaleString(); // Format number with commas
    return `$${formattedNumber}`; // Add $ symbol
  };

  const chartOptions = {
    chart: {
      type: "line",
      id: `appdata`,
      height: 10,
      sparkline: {
        enabled: true, // Enable sparkline to shrink the chart
      },
    },
    series: [
      {
        name: "spend",
        data: [
          parseFloat(data.fourth_week) || 0,
          parseFloat(data.pre_previous_week) || 0,
          parseFloat(data.previous_week) || 0,
          parseFloat(data.current_week) || 0,
        ],
      },
    ],
    xaxis: {
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      followCursor: true,
      x: {
        show: false,
      },
      y: {
        formatter: (value) => formatNumberWithCommas(value), // Format tooltip value
      },
    },
    stroke: {
      width: 2,
    },
    markers: {
      discrete: [
        {
          seriesIndex: 0,
          dataPointIndex: 0,
          fillColor:
            data.fourth_week > data.zeroth_week * 1.1 ||
            data.fourth_week - data.zeroth_week >= 100
              ? "#FF0000"
              : data.fourth_week > data.zeroth_week
              ? "#ff9248"
              : "#00FF00",
          strokeColor: "#fff",
          size: 5,
          shape: "circle",
        },
        {
          seriesIndex: 0,
          dataPointIndex: 1,
          fillColor:
            data.pre_previous_week > data.fourth_week * 1.1 ||
            data.pre_previous_week - data.fourth_week >= 100
              ? "#FF0000"
              : data.pre_previous_week > data.fourth_week
              ? "#ff9248"
              : "#00FF00",
          strokeColor: "#fff",
          size: 5,
          shape: "circle",
        },
        {
          seriesIndex: 0,
          dataPointIndex: 2,
          fillColor:
            data.previous_week > data.pre_previous_week * 1.1 ||
            data.previous_week - data.pre_previous_week >= 100
              ? "#FF0000"
              : data.previous_week > data.pre_previous_week
              ? "#ff9248"
              : "#00FF00",
          strokeColor: "#fff",
          size: 5,
          shape: "circle",
        },
        {
          seriesIndex: 0,
          dataPointIndex: 3,
          fillColor:
            data.current_week > data.previous_week * 1.1 ||
            data.current_week - data.previous_week >= 100
              ? "#FF0000"
              : data.current_week > data.previous_week
              ? "#ff9248"
              : "#00FF00",
          strokeColor: "#fff",
          size: 5,
          shape: "circle",
        },
      ],
    },
    dataLabels: {
      enabled: false,
      style: {
        colors: ["#000", "#00FF00", "#0000FF"],
      },
    },
  };
  // console.log(chartImages);
  return (
    <div>
      {/* <div key={id} id={`chart-${id}`}> */}
      <div id="charts">
        <ReactApexChart
          options={chartOptions}
          series={chartOptions.series}
          height="30"
          width="100"
        />
      </div>

      {/* </div> */}
    </div>
  );
}

function Appdata({ applicationweekly, selectedDate, endDate }) {
  const start_date = selectedDate;
  const end_date = endDate;

  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const dispatch = useDispatch();
  const [reportweekwiseappsum, setReportweekwiseappsum] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const input = {
    company_name: company_name,
    start_date: start_date,
    end_date: end_date,
  };
  const startDate = new Date(start_date);
  const end_Date = new Date(end_date);

  const oneWeek = 7 * 24 * 60 * 60 * 1000;

  // Calculate the start and end dates for previous week and pre-previous week
  const prevWeekStartDate = new Date(startDate.getTime() - oneWeek);
  const prevWeekEndDate = new Date(end_Date.getTime() - oneWeek);

  const prePrevWeekStartDate = new Date(startDate.getTime() - 2 * oneWeek);
  const prePrevWeekEndDate = new Date(end_Date.getTime() - 2 * oneWeek);
  const threeweekstartDate = new Date(startDate.getTime() - 3 * oneWeek);
  const threeweekEndDate = new Date(end_Date.getTime() - 3 * oneWeek);

  // Format the dates to strings in the desired format (DD/MM - DD/MM)
  const formatDate = (date) => {
    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];

    // Function to add suffix to day of the month
    const addSuffix = (day) => {
      if (day > 3 && day < 21) return `${day}th`;
      switch (day % 10) {
        case 1:
          return `${day} st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    const formattedDay = addSuffix(day);

    return `${formattedDay} ${month}`;
  };
  const threeweekRange = `${formatDate(threeweekstartDate)} - ${formatDate(
    threeweekEndDate
  )}`;
  const prevWeekRange = `${formatDate(prevWeekStartDate)} - ${formatDate(
    prevWeekEndDate
  )}`;
  const prePrevWeekRange = `${formatDate(prePrevWeekStartDate)} - ${formatDate(
    prePrevWeekEndDate
  )}`;
  const currentWeekRange = `${formatDate(startDate)}-${formatDate(end_Date)}`;
  // const generateRandomId = () => {
  //   return Math.random().toString(36).substring(7);
  // };

  // // Update applicationweekly data to include a random ID for each row
  // const dataWithIds = applicationweekly.map((record) => ({
  //   ...record,
  //   randomId: generateRandomId(),
  // }));
  // const [randomid, setRandomId] = useState([]);
  // dataWithIds.forEach((record) => {
  //   setRandomId(record.randomId);
  // });

  const formatNumberWithCommas = (number) => {
    const roundedNumber = Math.round(number); // Round the number to remove floating point
    return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const columns = [
    {
      title: "Application",
      dataIndex: "application",
      key: "application",
      align: "left",
      render: (text) => (
        <a style={{ color: "#000" }} href="#appdetail">
          {text}
        </a>
      ),
    },

    {
      title: "VM Count",
      dataIndex: "vm_count",
      key: "vm_count",
      align: "right",
      render: (text, record, index) => {
        if (record.vm_count && record.vm_count !== "0") {
          return record.vm_count;
        } else {
          return "-";
        }
      },
    },
    {
      title: "CPU",
      dataIndex: "cpu",
      key: "cpu",
      align: "right",
      render: (text, record, index) => {
        if (record.cpu && record.cpu !== "0") {
          return record.cpu;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Memory  (GiB)",
      dataIndex: "memory",
      key: "memory",
      align: "right",
      render: (text, record, index) => {
        if (record.memory && record.memory !== "0") {
          return record.memory;
        } else {
          return "-";
        }
      },
    },

    {
      title: "Storage (GiB)",
      dataIndex: "storage",
      key: "storage	",
      align: "right",
      render: (text, record, index) => {
        if (record.storage && record.storage !== "0") {
          return record.storage;
        } else {
          return "-";
        }
      },
    },
    {
      title: threeweekRange,
      dataIndex: "fourth_week",
      key: "fourth_week",
      align: "right",
      render: (text, record, index) => {
        if (record.fourth_week && record.fourth_week !== "0") {
          return <span>${formatNumberWithCommas(text)}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      title: prePrevWeekRange,
      dataIndex: "pre_previous_week",
      key: "pre_previous_week",
      align: "right",
      render: (text, record, index) => {
        if (record.pre_previous_week && record.pre_previous_week !== "0") {
          return <span>${formatNumberWithCommas(text)}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      title: prevWeekRange,
      dataIndex: "previous_week",
      key: "previous_week",
      align: "right",
      render: (text, record, index) => {
        if (record.previous_week && record.previous_week !== "0") {
          return <span>${formatNumberWithCommas(text)}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      title: currentWeekRange,
      dataIndex: "current_week",
      key: "current_week",
      align: "right",
      render: (text, record, index) => {
        if (record.current_week && record.current_week !== "0") {
          return <span>${formatNumberWithCommas(text)}</span>;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Difference",
      dataIndex: "difference",
      key: "difference",
      align: "right",
      render: (text, record) => (
        <span>
          {record.difference > 0 ? (
            <>
              ${record.difference}
              <Icon icon="mdi:arrow-drop-up" color="red" />
            </>
          ) : record.difference < 0 ? (
            <>
              -${record.difference.toString().slice(1)}
              <Icon icon="mdi:arrow-down-drop" color="green" />
            </>
          ) : (
            "-"
          )}
        </span>
      ),
    },
    {
      title: "Spend Trend",
      dataIndex: "randomId",
      key: "spentrend",
      render: (text, record) => <MicroChart data={record} />,
    },
  ];
  const rowClassName = (record) => {
    if (
      parseInt(record.difference, 10) > 100 ||
      parseInt(record.difference, 10) < -100
    ) {
      return "highlight-row";
    }
    return "";
  };

  const data = [];
  const environments = [
    ...new Set(applicationweekly.map((item) => item.environment)),
  ];
  // console.log(applications);
  return (
    <div>
      {environments.map((environment) => (
        <div key={environment}>
          {" "}
          <h4 className="subtableheading">Environment: &nbsp; {environment}</h4>
          <Table
            className="weeklytable"
            columns={columns}
            dataSource={applicationweekly.filter(
              (item) => item.environment === environment
            )}
            pagination={false}
            rowClassName={rowClassName}
          />
        </div>
      ))}
    </div>
  );
}

export default Appdata;
