import { Icon } from "@iconify/react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  
} from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import PdfBackground from './PdfBackground';
import backgroundImage from "../../../assets/images/CostDeMaze Black Logo 2400x1800.png";


import logo from "../../../assets/images/company_logo.png";
import cmacgm_logo from "../../../assets/images/cmacgm_logo_new.png";
const image = {uri: '../../../assets/images/CostDeMaze Black Logo 2400x1800.png'};
const styles = StyleSheet.create({
  header: {
    width: "100%",
    textAlign: "center",
    marginBottom: 20,
  },
  headersecond: {
    width: "100%",
    textAlign: "center",
    marginBottom: 40,
  },
  footer: {
    position: "absolute",
    fontSize: 10,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    paddingTop: 20,
  },
  customer_logo: {
    width: "145px",
    height: "29px",
  },
  company_logo: {
    width: "145px",
    height: "29px",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "16.7%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  tableColRight: {
    width: "16.7%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "right",
    // marginRight: 60,
  },
  tableCellRight: {
    // margin: "auto",
    marginTop: 5,
    fontSize: 10,
    marginRight: 5,
  },
   container: {
      background: 'url("../../../assets/images/CostDeMaze Black Logo 2400x1800.png") no-repeat center center fixed',
    }
});

const PdfHeader = ({ company_logo }) => {
  return (
    <>
      <View style={styles.header} fixed>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Text style={{ flex: 1, textAlign: "left" }}>
            {company_logo && (
              <Image src={company_logo} style={styles.customer_logo} />
            )}
          </Text>
          <Text style={{ flex: 1, textAlign: "right", marginRight: -30 }}>
            <Image
              style={styles.company_logo}
              src="../../../assets/images/1ch_logo.jpg"
            />
          </Text>
        </View>
      </View>
    </>
  );
};

const Page2PdfHeader = () => {
  return (
    <>
      <View style={styles.headersecond} fixed>
        <View style={{ display: "flex", flexDirection: "row" }}></View>
      </View>
    </>
  );
};

const PdfFooter = () => {
  return (
    <>
      <View style={styles.footer} fixed>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Text style={{ flex: 1, textAlign: "left", marginLeft: 20 }}>
            Date1 : {new Date().getDate()}/{new Date().getMonth() + 1}/
            {new Date().getFullYear()}
          </Text>
          <Text style={{ flex: 1, textAlign: "right", marginRight: 20 }}>
            © {new Date().getFullYear()} Copyright: 1CloudHub
          </Text>
        </View>
      </View>
    </>
  );
};
const MyDocument = (props) => {
  const [reportdate, setReportDate] = useState({
    date: "",
    month: "",
    year: "",
  });
  const [rilist, setRIList] = useState([]);
  const [startstoplist, setStartStopList] = useState([]);
  const [rightSizingOPList, setRightSizingOPList] = useState([]);
  const [spot_start_stop_list, setSpot_start_stop_list] = useState([]);
  const [graviton_instance_list, setGravitonInstance_List] = useState([]);
  const [disk_recommendation_list, setDisk_recommendation_list] = useState([]);

  const [rem_totla, setRemTotal] = useState({
    ri: 0,
    startstop: 0,
    rightSizingOP: 0,
    spot_start_stop: 0,
    graviton_instance: 0,
    disk_recommendation: 0,
    total: 0,
  });
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  useEffect(() => {
    setReportDate({
      date: new Date(props.date).getDate(),
      month: monthNames[new Date(props.date).getMonth()],
      year: new Date(props.date).getFullYear(),
      lastmonth: monthNames[new Date(props.date).getMonth() - 1],
    });
    if (props.records) {
      props.records.sort((a, b) => b.potential_savings - a.potential_savings);
      const total_value = props.records?.reduce(
        (a, v) => (a = +a + +v?.potential_savings),
        0
      );
      const rilist_filter = props.records?.filter(
        (datas) => datas.recommendation_type.toLowerCase() === "ri"
      );
      const stat_stop_filter = props.records?.filter(
        (datas) => datas.recommendation_type.toLowerCase() === "stop/start"
      );

      //   for other
      const rightSizingOP_filter = props.records?.filter(
        (datas) =>
          datas.recommendation_type.toLowerCase() === "right sizing(op)"
      );
      const spot_start_stop_filter = props.records?.filter(
        (datas) =>
          datas.recommendation_type.toLowerCase() === "spot & start/stop"
      );
      const graviton_instance_filter = props.records?.filter(
        (datas) =>
          datas.recommendation_type.toLowerCase() === "graviton instance"
      );

      const disk_recommendation_filter = props.records?.filter(
        (datas) =>
          datas.recommendation_type.toLowerCase() === "disk recommendation"
      );
      const ri_total = rilist_filter.reduce(
        (a, v) => (a = +a + +v?.potential_savings),
        0
      );
      const start_total = stat_stop_filter.reduce(
        (a, v) => (a = +a + +v?.potential_savings),
        0
      );
      const rightSizingOP_total = rightSizingOP_filter.reduce(
        (a, v) => (a = +a + +v?.potential_savings),
        0
      );
      const spot_start_stop_filter_total = spot_start_stop_filter.reduce(
        (a, v) => (a = +a + +v?.potential_savings),
        0
      );
      const graviton_instance_total = graviton_instance_filter.reduce(
        (a, v) => (a = +a + +v?.potential_savings),
        0
      );
      const disk_recommendation_total = disk_recommendation_filter.reduce(
        (a, v) => (a = +a + +v?.potential_savings),
        0
      );
      setRemTotal({
        ri: ri_total,
        startstop: start_total,
        total: total_value,
        rightSizingOP: rightSizingOP_total,
        spot_start_stop: spot_start_stop_filter_total,
        disk_recommendation: disk_recommendation_total,
        graviton_instance: graviton_instance_total,
      });
      setRIList(rilist_filter.sort());
      setStartStopList(stat_stop_filter.sort());
      setRightSizingOPList(rightSizingOP_filter.sort());
      setSpot_start_stop_list(spot_start_stop_filter.sort());
      setGravitonInstance_List(graviton_instance_filter.sort());
      setDisk_recommendation_list(disk_recommendation_filter.sort());
    }
  }, [props]);

  return (
    <>
      <Document>
        <Page pageNumber={1} style={{ padding: 20, }}>
          <PdfHeader company_logo={props.company_logo} />
          {/* <Image src={backgroundImage} /> */}
          <View >
          {/* <ImageBackground source={image} resizeMode="cover" style={styles.image}> */}
          {/* <Image src={backgroundImage} style={styles.pageBackground} /> */}
          {/* <PdfBackground backgroundImage={backgroundImage} /> */}
            <Text style={{ textAlign: "center", top: 300 }}>
              Cost Saving report for the month of {reportdate.month},{" "}
              {reportdate.year}
            </Text>
            {/* </ImageBackground> */}
          </View>
          <PdfFooter />
        </Page>
        <Page
          pageNumber={2}
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 50,
            paddingTop: 20,
            // backgroundImage:{backgroundImage},
            //  backgroundColor: '#FFFF00',
            // backgroundImage: backgroundImage,
          }}
        >
          <Page2PdfHeader />
          <View style={{ marginTop: 10 }}>
            <Text style={{ fontSize: "15px" }}>Summary :</Text>
          </View>
          <View style={{ marginTop: 5 }}>
            <Text style={{ fontSize: "12px", marginLeft: 20 }}>
              We have reviewed the landscape and for the month of{" "}
              {reportdate.lastmonth} we see a potential savings of upto $
              {/* {rem_totla.total.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}{" "} */}
              {Math.round(rem_totla.total).toLocaleString()} per annum. Please
              review and action eligible candidate for cost savings and don't
              forget to move the implemented recommendations to realized tab in
              our costimize platform.
            </Text>
          </View>
          <View style={{ marginTop: 10 }}>
            <Text style={{ fontSize: "12px" }}>
              For this month we see cost savings opportunity in the below
              categories:
            </Text>
          </View>

          <View style={{ marginTop: 10, fontSize: "11px" }}>
            {/* {props.recommendationlist.map((data, index) => (
              <Text style={{ marginLeft: 20, lineHeight: 1.5 }}>
                <Text style={{ marginRight: 5 }}>•</Text> {data.text}
              </Text>
            ))} */}
            {rilist.length > 0 && (
              <Text style={{ marginLeft: 20, lineHeight: 1.5 }}>
                <Text style={{ marginRight: 5 }}>•</Text> RI ($
                {Math.round(rem_totla.ri).toLocaleString()})
              </Text>
            )}
            {startstoplist.length > 0 && (
              <Text style={{ marginLeft: 20, lineHeight: 1.5 }}>
                <Text style={{ marginRight: 5 }}>•</Text> Start/Stop ($
                {Math.round(rem_totla.startstop).toLocaleString()})
              </Text>
            )}
            {rightSizingOPList.length > 0 && (
              <Text style={{ marginLeft: 20, lineHeight: 1.5 }}>
                <Text style={{ marginRight: 5 }}>•</Text> Right Sizing(OP) ($
                {Math.round(rem_totla.rightSizingOP).toLocaleString()})
              </Text>
            )}
            {spot_start_stop_list.length > 0 && (
              <Text style={{ marginLeft: 20, lineHeight: 1.5 }}>
                <Text style={{ marginRight: 5 }}>•</Text> Spot & Start/Stop ($
                {Math.round(rem_totla.spot_start_stop).toLocaleString()})
              </Text>
            )}
            {graviton_instance_list.length > 0 && (
              <Text style={{ marginLeft: 20, lineHeight: 1.5 }}>
                <Text style={{ marginRight: 5 }}>•</Text> Graviton Instance ($
                {Math.round(rem_totla.graviton_instance).toLocaleString()})
              </Text>
            )}
            {disk_recommendation_list.length > 0 && (
              <Text style={{ marginLeft: 20, lineHeight: 1.5 }}>
                <Text style={{ marginRight: 5 }}>•</Text> Disk Recommendation ($
                {Math.round(rem_totla.disk_recommendation).toLocaleString()})
              </Text>
            )}
          </View>

          {rilist.length > 0 && (
            <View style={{ marginTop: 20, fontSize: "11px" }}>
              <Text style={{ marginLeft: 20, lineHeight: 2, fontWeight: 900 }}>
                <Text style={{ marginRight: 5 }}>•</Text> RI :
              </Text>
              <Text style={{ marginLeft: 40, lineHeight: 1.5 }}>
                {/* {rem_totla.ri.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })} */}
                {Math.round(rem_totla.ri).toLocaleString()}, details below. We
                observed some systems are not used through out the day,
                implementing a start/stop schedule will help you to save cost
                upto $ {Math.round(rem_totla.ri).toLocaleString()}, details
                below.
              </Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                    {/* <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Account!</Text>
                    </View> */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Application11</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Environment</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Service</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Current Configuration</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      Recommended Configuration
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Potential Savings</Text>
                  </View>
                </View>
                {rilist.map((data) => {
                  return (
                    <View style={styles.tableRow}>
                      {/* <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.account}</Text>
                      </View> */}
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.application}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.environment}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.services}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {data.current_configuration}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {data.recommended_configuration}
                        </Text>
                      </View>
                      <View style={styles.tableColRight}>
                        <Text style={styles.tableCellRight}>
                          $
                          {/* {data.potential_savings.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })} */}
                          {Math.round(data.potential_savings).toLocaleString()}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          )}
          {startstoplist.length > 0 && (
            <View style={{ marginTop: 30, fontSize: "11px" }}>
              <Text style={{ marginLeft: 20, lineHeight: 2, fontWeight: 900 }}>
                <Text style={{ marginRight: 5 }}>•</Text> Start/Stop :
              </Text>
              <Text style={{ marginLeft: 40, lineHeight: 1.5 }}>
                We see some systems are over provisioned, reconfiguring the same
                with our recommended size will help you to save cost of upto ${" "}
                {Math.round(rem_totla.startstop).toLocaleString()}, details
                below.
                {/* {rem_totla.startstop.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })} */}
              </Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  {/* <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Account!</Text>
                  </View> */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Application</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Environment</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Service</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Current Configuration</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      Recommended Configuration
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Potential Savings</Text>
                  </View>
                </View>
                {startstoplist.map((data) => {
                  return (
                    <View style={styles.tableRow}>
                      {/* <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.account}</Text>
                      </View> */}
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.application}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.environment}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.services}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {data.current_configuration}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {data.recommended_configuration}
                        </Text>
                      </View>
                      <View style={styles.tableColRight}>
                        <Text style={styles.tableCellRight}>
                          $
                          {/* {data.potential_savings.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })} */}
                          {Math.round(data.potential_savings).toLocaleString()}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          )}

          {/* added new for other two  */}
          {rightSizingOPList.length > 0 && (
            <View style={{ marginTop: 30, fontSize: "11px" }}>
              <Text style={{ marginLeft: 20, lineHeight: 2, fontWeight: 900 }}>
                <Text style={{ marginRight: 5 }}>•</Text> Right Sizing(OP) :
              </Text>
              <Text style={{ marginLeft: 40, lineHeight: 1.5 }}>
                We observed some systems are not used through out the day,
                implementing a start/stop schedule and availing spot offering
                will help you to save cost upto ${" "}
                {Math.round(rem_totla.rightSizingOP).toLocaleString()}, details
                below.
              </Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  {/* <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Account!</Text>
                  </View> */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Application</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Environment</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Service</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Current Configuration</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      Recommended Configuration
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Potential Savings</Text>
                  </View>
                </View>
                {rightSizingOPList.map((data) => {
                  return (
                    <View style={styles.tableRow}>
                      {/* <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.account}</Text>
                      </View> */}
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.application}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.environment}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.services}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {data.current_configuration}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {data.recommended_configuration}
                        </Text>
                      </View>
                      <View style={styles.tableColRight}>
                        <Text style={styles.tableCellRight}>
                          $
                          {/* {data.potential_savings.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })} */}
                          {Math.round(data.potential_savings).toLocaleString()}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          )}

          {spot_start_stop_list.length > 0 && (
            <View style={{ marginTop: 30, fontSize: "11px" }}>
              <Text style={{ marginLeft: 20, lineHeight: 2, fontWeight: 900 }}>
                <Text style={{ marginRight: 5 }}>•</Text> Spot & Start/Stop :
              </Text>
              <Text style={{ marginLeft: 40, lineHeight: 1.5 }}>
                We see potential savings if we implement scheduled stop/start so
                that you upto $
                {/* {rem_totla.spot_start_stop.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })} */}
                {Math.round(rem_totla.spot_start_stop).toLocaleString()},
                details below.
              </Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  {/* <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Account!</Text>
                  </View> */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Application</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Environment</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Service</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Current Configuration</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      Recommended Configuration
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Potential Savings</Text>
                  </View>
                </View>
                {spot_start_stop_list.map((data) => {
                  return (
                    <View style={styles.tableRow}>
                      {/* <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.account}</Text>
                      </View> */}
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.application}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.environment}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.services}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {data.current_configuration}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {data.recommended_configuration}
                        </Text>
                      </View>
                      <View style={styles.tableColRight}>
                        <Text style={styles.tableCellRight}>
                          $
                          {/* {data.potential_savings.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })} */}
                          {Math.round(data.potential_savings).toLocaleString()}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          )}

          {graviton_instance_list.length > 0 && (
            <View style={{ marginTop: 30, fontSize: "11px" }}>
              <Text style={{ marginLeft: 20, lineHeight: 2, fontWeight: 900 }}>
                <Text style={{ marginRight: 5 }}>•</Text> Graviton Instance :
              </Text>
              <Text style={{ marginLeft: 40, lineHeight: 1.5 }}>
                For Non-production we can explore option of moving the system to
                Gaviton based instance family, moving them Graviton will result
                in a cost savings of upto $
                {/* {rem_totla.graviton_instance.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })} */}
                {Math.round(rem_totla.graviton_instance).toLocaleString()}
                .Note: The recommendations have not analysed the compatibility
                of the underlying application.
              </Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  {/* <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Account!</Text>
                  </View> */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Application</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Environment</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Service</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Current Configuration</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      Recommended Configuration
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Potential Savings</Text>
                  </View>
                </View>
                {graviton_instance_list.map((data) => {
                  return (
                    <View style={styles.tableRow}>
                      {/* <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.account}</Text>
                      </View> */}
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.application}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.environment}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.services}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {data.current_configuration}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {data.recommended_configuration}
                        </Text>
                      </View>
                      <View style={styles.tableColRight}>
                        <Text style={styles.tableCellRight}>
                          $
                          {/* {data.potential_savings.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })} */}
                          {Math.round(data.potential_savings).toLocaleString()}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          )}
          {disk_recommendation_list.length > 0 && (
            <View style={{ marginTop: 30, fontSize: "11px" }}>
              <Text style={{ marginLeft: 20, lineHeight: 2, fontWeight: 900 }}>
                <Text style={{ marginRight: 5 }}>•</Text> Disk Recommendation :
              </Text>
              <Text style={{ marginLeft: 40, lineHeight: 1.5 }}>
                We have analysed your disk configurations and see reconfiguring
                them as per our recommendation will help you to save cost of
                upto ${" "}
                {Math.round(rem_totla.disk_recommendation).toLocaleString()},
                details below.
              </Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  {/* <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Account!</Text>
                  </View> */}
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Application</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Environment</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Service</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Current Configuration</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      Recommended Configuration
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Potential Savings</Text>
                  </View>
                </View>
                {disk_recommendation_list.map((data) => {
                  return (
                    <View style={styles.tableRow}>
                      {/* <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.account}</Text>
                      </View> */}
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.application}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.environment}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data.services}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {data.current_configuration}
                        </Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                          {data.recommended_configuration}
                        </Text>
                      </View>
                      <View style={styles.tableColRight}>
                        <Text style={styles.tableCellRight}>
                          $
                          {/* {data.potential_savings.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })} */}
                          {Math.round(data.potential_savings).toLocaleString()}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          )}

          <PdfFooter />
        </Page>
      </Document>
    </>
  );
};
export default MyDocument;
