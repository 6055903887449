import React from "react";
import { Col, Popover, Row, Switch } from "antd";
import { Chart } from "react-google-charts";
import { AiFillInfoCircle } from "react-icons/ai";
import "./networking.css";
function NetworkingCost() {
  const data = [
    ["From", "To", "Cost($)"],
    ["Asia Pacific (Mumbai)", "Internet", 2.89],
    ["Asia Pacific (Mumbai)", "Internet", 2.89],
    ["Asia Pacific (Mumbai)", "Cdn", 2.89],
    ["US East (N. Virginia)", "Asia Pacific (Seoul)", 1.3],
    ["South America (Sao Paulo)", "EU (Ireland)", 3.0],
    ["South America (Sao Paulo)", "Asia Pacific (Seoul)", 3.0],
    ["South America (Sao Paulo)", "Internet", 3.0],
    ["US West (N. California)", "EU (Ireland)", 4.0],
    // ["Asia Pacific (Singapore)", "Asia Pacific (Singapore )", 0.89],
    ["Asia Pacific (Singapore)", "US West (Oregon)", 2.4],
    ["Asia Pacific (Mumbai)", "Cdn", 2.0],
    ["Canada (Central)", "US East (N.Virginia)", 2.0],
    ["Europe (London)", "US East (N.Virginia)", 2.0],
    ["Middle East (UAE)", "US East (N.Virginia)", 2.0],
  ];

  const options_sankey = {
    sankey: {
      node: {
        label: {
          color: "white",
        },
      },
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
  };
  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Row gutter={16}>
            <Col span={6}>
              <div className="crd relative">
                <h3 className="h-title flex items-center">
                  <Popover
                    placement="topLeft"
                    content={"content"}
                    title="Title"
                    trigger="hover"
                  >
                    <AiFillInfoCircle size={14} color="grey" role="button" />
                  </Popover>
                  <span className="mx-2"> Total Inbound request</span>
                </h3>
                <h6 className="s-title">3.95 GB</h6>
                <div className="mk-graph-bg">{/* <CardGraph /> */}</div>
              </div>
            </Col>
            <Col span={6}>
              <div className="crd relative ">
                <div className="flex ">
                  <h3 className="h-title flex items-center">
                    <AiFillInfoCircle size={14} color="grey" role="button" />
                    <span className="mx-2">Total outbound request</span>
                  </h3>
                </div>

                <h6 className="s-title">1.49 GB</h6>
                <div className="mk-graph-bg">{/* <CardGraph /> */}</div>
              </div>
            </Col>

            <Col span={6}>
              <div className="crd relative">
                <h3 className="h-title flex items-center">
                  <Popover
                    placement="topLeft"
                    content={"Total Inbound cost"}
                    title="Total Inbound cost"
                    trigger="hover"
                  >
                    <AiFillInfoCircle size={14} color="grey" role="button" />
                  </Popover>
                  <span className="mx-2">Total Inbound cost</span>
                </h3>
                <h6 className="s-title">$29.74</h6>
                <div className="mk-graph-bg">{/* <CardGraph /> */}</div>
              </div>
            </Col>
            <Col span={6}>
              <div className="crd relative">
                <h3 className="h-title flex items-center">
                  <AiFillInfoCircle size={14} color="grey" role="button" />
                  <span className="mx-2"> Total outbound cost</span>
                </h3>
                <h6 className="s-title">$29.18</h6>
                <div className="mk-graph-bg">{/* <CardGraph /> */}</div>
              </div>
            </Col>
          </Row>
          <div className="py-2"></div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="crd relative h-auto sankey-chart-container">
            <Chart
              chartType="Sankey"
              height="350px"
              data={data}
              options={options_sankey}
              key={Math.random()}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default NetworkingCost;
