// features/user/userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  environmentList: [],
  QuickGlancePageTop5SpendTabActiveTab: {
    production: true,
    nonProduction: false,
    unTagged: false,
  },
  QuickGlancePageTop5SpendFilterActiveTab: {
    increaseFilter: true,
    decreaseFilter: false,
  },
};

const costimizespendSlice = createSlice({
  name: "costimizespend",
  initialState,
  reducers: {
    environmentListMethod: (state, { payload }) => {
      state.environmentList = payload;
    },
    setQuickGlancePageTop5SpendTab: (state, action) => {
      state.QuickGlancePageTop5SpendTabActiveTab = action.payload;
    },
    setQuickGlancePageTop5SpendFilterTab: (state, action) => {
      state.QuickGlancePageTop5SpendFilterActiveTab = action.payload;
    },
  },
  extraReducers: {},
});

export default costimizespendSlice.reducer;
export const {
  environmentListMethod,
  setQuickGlancePageTop5SpendTab,
  setQuickGlancePageTop5SpendFilterTab,
} = costimizespendSlice.actions;
