import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import { Row, Col, Form, Input, Button,Modal } from "antd";
import { getServiceRequestData, service_chart } from "../../../action/graphql/slm/SLM_reducers";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ServiceRequestTrendTable from "./ServiceRequestTrendTable";
import { Icon } from "@iconify/react";

const ServiceRequestChart = () => {
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [modalOpen,setModalOpen]=useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [monthLabelList, setMonthLabelList] = useState([]);
  const [datarecord, setdatarecord] = useState([])
  const dispatch = useDispatch();
  const changeDrawerStatus = () => {
    // setDrawerStatus(false);
    setModalOpen(false)
  };
  const companyName = "fivestar";
  const { selectedTopdate } = useSelector((state) => state.constimize);
  useEffect(() => {
    var parts = selectedTopdate.split("-");
    var year = parseInt(parts[0]);
    var month = parseInt(parts[1]);
    var day = parseInt(parts[2]);
    var currentDate = new Date(year, month - 1, day);
    var lastThreeMonthsDates = [];

    for (var i = 0; i < 3; i++) {
      var formattedMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      lastThreeMonthsDates.push(
        currentDate.getFullYear() + "-" +
        formattedMonth + "-" +
        "01"
      );

      currentDate.setMonth(currentDate.getMonth() - 1);
    }

    lastThreeMonthsDates.reverse();

    dispatch(service_chart({ lastThreeMonthsDates, companyName }))
      .unwrap()
      .then(({ data }) => {

        const lastThreeMonthsData = lastThreeMonthsDates.map(date => {
          const matchingData = data.slm_compliance_ticket_sr.find(item => item.month === date);
          return matchingData ? parseInt(matchingData.service_request_count) : 0;
        });


        setdatarecord(lastThreeMonthsData)

      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedTopdate])

  const generateMonthLabels = () => {

    const currentMonth = new Date(selectedTopdate).getMonth();
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun ",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const monthLabelList = [];
    for (let i = 2; i >= 0; i--) {
      const prevMonthIndex = (currentMonth + 12 - i) % 12;
      monthLabelList.push(months[prevMonthIndex]);
    }

    return monthLabelList;
  };
  const monthLabels = generateMonthLabels();
  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: undefined,
      formatter: function (val, opts) {
        return val;
      },
      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: undefined,
      },

      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },

    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    xaxis: {
      // categories: monthLabelList,
      categories: monthLabels,
      // categories: generateMonthLabels(numMonthsToShow),
    },
    fill: {
      opacity: 1,
      colors: ["#32A7A0"],
    },
    title: {
      text: `Service Request Trend`,
      align: "center",
      margin: 0,
      offsetX: 0,
      offsetY: 10,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#595959",
      },
    },
  };

  const series = [
    {
      name: "No of SR Recevied",
      data: datarecord,
    },
  ];
  const handleIconClick = () => {
    setModalOpen(true);
  };

  return (
    <>
      <Modal
      className="slmmodal"
        open={modalOpen}
        onCancel={changeDrawerStatus}
        deletestatus={deletestatus}
        title="Service Request"
        footer={null}
      >
        <ServiceRequestTrendTable />

      </Modal>
      <Row>
      <div className="card-optionsspend">
                <span
                  className="card-filter-iconbutton"
                  onClick={handleIconClick}
                >
                  <Icon
                    className="cursor-pointer"
                    icon="fluent:edit-16-regular" color="white" 
                    
                  />
                </span>
              </div>  
              </Row>
              <Row>
      <div className="incident-trends">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={260}
        />
      </div>
      </Row>
    </>
  );
};

export default ServiceRequestChart;
