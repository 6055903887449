import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { qgBudgetSpendTrend } from "../../../action/costimizedashboardAction";

const BudgetVsSpendCard = () => {
  const roundAndFormat = (value) => {
    if (value >= 1000) {
      return (value / 1000).toFixed(1);
    } else {
      return value.toFixed(1);
    }
  };

  const [budgetSpendData, setBudgetSpendData] = useState([]);
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const {
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
    selectedTopdate,
  } = useSelector((state) => state.constimize);

  useEffect(() => {
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      companyName: companyName,
      month: selectedTopdate,
    };

    dispatch(qgBudgetSpendTrend({ apivalue }))
      .unwrap()
      .then(({ data }) => {
        const convertedData = Object.values(
          data?.qgbudgetspendtrend.reduce((acc, cur) => {
            const { month, clouds, spend, budget } = cur;
            const key = `${month}-${clouds.toUpperCase()}`;
            if (!acc[key]) {
              acc[key] = {
                reportmonth: month,
                cloud: [
                  {
                    clouds: clouds.toUpperCase(),
                    budget,
                    spend,
                  },
                ],
              };
            } else {
              acc[key].cloud.push({
                clouds: clouds.toUpperCase(),
                budget,
                spend,
              });
            }
            return acc;
          }, {})
        );

        setBudgetSpendData(convertedData);
      })
      .catch((err) => {
        console.log(err?.message);
      });
  }, []);

  const processedData = budgetSpendData.map((entry) => ({
    reportmonth: entry.reportmonth,
    AWS: roundAndFormat(
      parseFloat(
        entry.cloud.find((cloud) => cloud.clouds === "AWS")?.spend || 0
      )
    ),
    Azure: roundAndFormat(
      parseFloat(
        entry.cloud.find((cloud) => cloud.clouds === "Azure")?.spend || 0
      )
    ),
    BudgetAWS: roundAndFormat(
      parseFloat(
        entry.cloud.find((cloud) => cloud.clouds === "AWS")?.budget || 0
      )
    ),
    BudgetAzure: roundAndFormat(
      parseFloat(
        entry.cloud.find((cloud) => cloud.clouds === "Azure")?.budget || 0
      )
    ),
  }));
  // ...
  const isAzurePresent = budgetSpendData.some((item) =>
    item.cloud.some((cloud) => cloud.clouds.toLowerCase() === "azure")
  );
  const isAwsPresent = budgetSpendData.some((item) =>
    item.cloud.some((cloud) => cloud.clouds.toLowerCase() === "aws")
  );
  let seriesData = [];
  if (isAwsPresent) {
    seriesData = [
      {
        name: "AWS Spend",
        type: "line", // Change to line for Spend
        data: processedData.map((entry) => entry.AWS),
      },
      {
        name: "AWS Budget",
        type: "bar", // Change to bar for Budget
        data: processedData.map((entry) => entry.BudgetAWS),
      },
    ];
  }
  if (isAzurePresent) {
    seriesData = [
      {
        name: "Azure Spend",
        type: "line", // Change to line for Spend
        data: processedData.map((entry) => entry.Azure),
      },
      {
        name: "Azure Budget",
        type: "bar", // Change to bar for Budget
        data: processedData.map((entry) => entry.BudgetAzure),
      },
    ];
  }
  if (isAwsPresent && isAzurePresent) {
    seriesData = [
      {
        name: "AWS Spend",
        type: "line", // Change to line for Spend
        data: processedData.map((entry) => entry.AWS),
      },
      {
        name: "AWS Budget",
        type: "bar", // Change to bar for Budget
        data: processedData.map((entry) => entry.BudgetAWS),
      },
      {
        name: "Azure Spend",
        type: "line", // Change to line for Spend
        data: processedData.map((entry) => entry.Azure),
      },
      {
        name: "Azure Budget",
        type: "bar", // Change to bar for Budget
        data: processedData.map((entry) => entry.BudgetAzure),
      },
    ];
  }
  const options = {
    chart: {
      height: 380,
      type: "line",
    },
    legend: {
      show: true,
      labels: {
        colors: "white",
      },
    },
    series: seriesData,
    colors: ["#FF5733", "#a7ee7c", "#FF5733", "#a7ee7c"],
    stroke: {
      width: [3, 3, 0, 0], // Adjust the width for lines and bars
      curve: "smooth",
    },
    forecastDataPoints: {
      count: moment(selectedTopdate)?.isSame(new Date(), "month") ? 1 : 0,
    },
    plotOptions: {
      bar: {
        columnWidth: "35%",
      },
    },
    

    labels: processedData.map((entry) =>
      moment(entry.reportmonth).format("MMM")
    ),
    xaxis: {
      // type: "datetime",
      labels: {
        show: true,
        rotate: 0,
        style: {
          colors: "white",
          fontSize: "12px",
        },
      },
    },
    yaxis: [
      {
        title: {
          text: "Spend",
        },
        opposite: true, // Move the "Spend" axis to the opposite side
        labels: {
          show: true,
          style: {
            colors: "white",
            fontSize: "12px",
          },

          formatter: function (value) {
            return value + "K";
          },
        },
      },
      {
        title: {
          text: "Budget",
        },

        labels: {
          show: true,
          style: {
            colors: "white",
            fontSize: "12px",
          },
          formatter: function (value) {
            return value + "K";
          },
        },
      },
    ],
  };

  return (
    <div className="budget-vs-spend-card">
      {/* <JsonViewer data={dataStaticA} />
      <JsonViewer data={budgetSpendData} /> */}
      {/* <pre> {JSON.stringify(budgetSpendData, null, 4)}</pre> */}
      <ReactApexChart
        options={options}
        series={options.series}
        type="line"
        height={380}
      />
    </div>
  );
};

export default BudgetVsSpendCard;
