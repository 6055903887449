import {React,useState} from "react";
import { Form, Input, Button, Row, Col, Select,Space} from "antd";
import { useNavigate } from "react-router-dom";
import A2CSidebar from "../a2c_rover/A2CSidebar";
import { Icon } from "@iconify/react";
function LandingZone() {
    const navigate = useNavigate();
    const handleAddClick = () => {
        navigate("/landingzoneadd");
      };
 
    return(
        <>
        <div>
        <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
        <A2CSidebar />
        <main className="position-main-div" style={{ padding: 10, flex: 1 }}>
          {" "}
          <div className="cams-content">
            <div className="container-fluid">
              <div className="header-filter d-flex justify-content-between">
                <div className="header-title-a2c-main">Landing Zone</div>
                <span className="d-flex align-items-end"></span>
              </div>
          <div className="title-btn-row1">
                    <Button
                      className="title-btn"
                      onClick={() => handleAddClick()}
                    >
                      <Space>
                        <Icon icon="material-symbols:add-circle-outline" />
                        Add
                      </Space>
                    </Button>
                  </div>
                  </div>
                  </div>
      </main>
      </div>
      </div>
        </>
    )
}
export default LandingZone;





  

