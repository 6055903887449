import React, { useState,useEffect } from "react";
import ReactApexChart from "react-apexcharts";
const NonProductionWindowChart = (props) => {

  const [prodlist,setprodlist]=useState([])
  const [nonprodlist,setnonprodlist]=useState([])
  const record=props.nonprodlist

  useEffect(()=>{
    const patchRecords = record.filter(
      (record) =>
        record.compliants
        
        
    );
    setprodlist(patchRecords);
    const patchRecords2 = record.filter(
      (record) =>
        record.non_complaints 
        
    );
    setnonprodlist(patchRecords2);
  },[record])
 
  const t1=prodlist.length
  const t2=nonprodlist.length

  var countfilteredLin1 = props.countsos.filter(function (element) {
    return (
      element.environment == "non-production" &&
      element.os == "windows" &&
      element.patch_status == "compliance"
    );
  }).length;
  var countfilteredLin2 = props.countsos.filter(function (element) {
    return (
      element.environment == "non-production" &&
      element.os == "windows" &&
      element.patch_status == "non-compliance"
    );
  }).length;
  const compliantsSum = record.reduce((sum, item) => sum + parseInt(item.compliants), 0);
  const nonComplaintsSum = record.reduce((sum, item) => sum + parseInt(item.non_complaints), 0);

  const series=[compliantsSum,nonComplaintsSum]
  // const series =[t1,t2]
  // const series = [countfilteredLin1, countfilteredLin2];
  const options = {
    chart: {
      type: "donut",
    },
    legend: {
      show: false,
    },
    colors: ["#84AEE7", "#FFEAA0"],
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.round(val);
      },
    },
    title: {
      show: true,
      text: "Windows",
      style:{
        color:'#FFF',
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="donut" />
      </div>
    </>
  );
};

export default NonProductionWindowChart;
