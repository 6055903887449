import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ServiceRequestTable from './ServiceRequestTable';
import ReactApexChart from "react-apexcharts";


const ServiceRequest = () => {

    const options = {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: undefined,
            formatter: function (val, opts) {
                return val
            },
            textAnchor: 'middle',
            distributed: false,
            offsetX: 0,
            offsetY: 0,
            style: {
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: undefined
            },
            background: {
              enabled: true,
              foreColor: '#fff',
              padding: 4,
              borderRadius: 2,
              borderWidth: 1,
              borderColor: '#fff',
              opacity: 0.9,
              dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45
              }
            },
            dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45
            }
          },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        // dataLabels: {
        //   enabled: false
        // },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Dec - 2022', 'Jan - 2023', 'Feb - 2023'],
        },
        fill: {
          opacity: 1
        },
        title: {
            text: `Service Request Trend`,
            align: 'center',
            margin: 0,
            offsetX: 0,
            offsetY: 10,
            floating: false,
            style: {
              fontSize:  '14px',
              fontWeight:  'bold',
              color:  '#595959'
            },
        },
        
       
      };

      const series =  [{
        name: 'No of SR Recevied',
        data: [11, 21, 15]
      }];



    return(
        <>
        <Row>
            <Col>
                <div className="incident-trends">
                    <ReactApexChart options={options} series={series} type="bar" height={235} />
                </div>
            </Col>
         </Row>
         <Row>
            <Col>
                <ServiceRequestTable />
            </Col>
         </Row>
         
        </>
    )
}

export default ServiceRequest;