import React from "react";
import SLMSidebar from "../../SLMSidebar";
import MainHead from "../../../costimize_v2/layout/MainHead";
import Escalation from "./Escalation";

function SLM_ESCALATIONMATRIX() {


    return (
        <>
            <div style={{ display: "flex", height: "100%" }} className="costimize_bg">
                <SLMSidebar />
                <div className="quickglancetitle">
                <MainHead type="slm" title="Escalation Matrix" />
                </div>
                <div className="slm-body-screen ">
                    <div className="slm-tabs mt-4" > <Escalation /></div>
                </div>
            </div>

        </>
    );
}

export default SLM_ESCALATIONMATRIX;
