import { createAsyncThunk } from "@reduxjs/toolkit";
import client_a2c from "../../client/A2C_Client";
import client_war from "../../client/warclient";
import {
  ASSESS_OS_SPREAD,
  ASSESS_DB_SPREAD,
  ASSESS_DC_SPREAD,
  ASSESS_CORERAM,
  ASSESS_STORAGE,
  ASSESS_DR,
  ASSESS_MONITOR,
  ASSESS_STORAGE_UTIL,
  ASSESS_RAM_UTIL,
  TCO_ANALYSIS,
  ASSESS_PHASE_WISE,
  ASSESS_PLANNED_MIGRATION,
  ASSESS_TO_BE_TABLE,
  ASSESS_TO_BE_CORE,
  ASSESS_TO_BE_MEMORY,
  ASSESS_CLOUD_READINESS,
  ASSESS_MIGRATIONMETHOD,
  ASSESS_MIGRATIONCOMP,
  ASSESS_IS,
  ASSESS_CORE,
  ASSESS_WAF,
  GET_TOTAL_RESOURCE_TO_BE_MIGRATED,
  GET_TOTAL_RESOURCES_YET_TO_BE_MIGRATED,
  GET_TOTAL_RESOURCES_MIGRATED,
  GET_TOTAL_RESOURCE_SPREAD,
  GET_OPERATE_MONITORING,
  GET_OPERATE_DR_SPREAD,
  GET_SLM_FILTER,
  GET_OPERATE_DB_SPREAD,
  MEMORY_CPU_COUNT,
  TOTAL_USAGE_COUNT_KUBERNETES,
  ECS_COUNT_TOTAL,
  LIST_WORKLOAD,
  CREATE_WORKLOAD_NAME,
  LIST_RISKCOUNTS,
  LIST_QUESTION_PILLARS,
  DOWNLOAD_REPORT,
  RISK_QUESTION_LIST,
  RISK_COUNTWISE_PILLAR,

} from "./A2CRoverQueries";
import {
  GET_OPERATE_CMA,
  GET_OPERATE_RAM_UTIL,
  GET_OPERATE_SERVICE_COUNT,
  GET_OPERATE_STORAGE_UTIL,
  GET_OPERATE_TCO,
  GET_OPERATE_VM_DETAILS,
  GET_OPERATE_WAF,
  GET_TARGET_AND_TOTAL_RESOURCE_COUNT,
  GET_TOTAL_CLEANUP_COUNT,
  MIGRATION_COMPLETION_PROCESS,
  GET_PLANNED_MIGRATION_BY_DATE,
  GET_ACTUAL_MIGRATION_BY_DATE,
  GET_RESOURCE_STATUS,
  GET_ASSESS_VM_COUNT,
  GET_SLM_TABLE,
  TICKET_SEVERITY,
  AVERAGE_RESOLUTION,
  AVERAGE_FIRST_RESPONSE,
  INCIDENET,
  GET_APPLICATION_COUNT,
  GET_SLM_APPLICATION,
  GET_CATEGORY_COUNT,
  GET_SLA_GRAPH,
  GET_OPERATE_OS_COUNT,
  ECS_MEMORY_CPU_COUNT,
  // GET_OPERATE_SERVICE_COUNT,
  // GET_OPERATE_OU_COUNT,
  // GET_OPERATE_VM_DETAILS,
  // GET_OPERATE_RAM_UTIL,
  // GET_OPERATE_STORAGE_UTIL,
  GET_ASSESS_FILTER,
  GET_OPERATE_FILTER,
  GET_OPERATE_OU_COUNT,
  GET_APP_MODERNIZATION_IS,
  GET_APP_MODERNIZATION_TO_BE,
  GET_KUBERNETES_SPREAD,
  GET_ECS,
  ASSSES_ADVIISORY_SCORE,
  ASSESSMENT_TABLE,
  ASSESSMENT_TABLE_LIST,
  ASSESSMENT_TABLE_UPDATE,
  ASSESSMENT_TABLE_DELETE,
  ASSESSMENT_TABLE_UPLOAD,
  UPDATE_QUESTIONS_DATA,
} from "./A2CRoverQueries";
import { useErrorNavigation } from "../../../../components/custom_hook/CustomHook";

export const getAccessVMCount = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {

    try {
      const aasList = await client_a2c.mutate({
        mutation: GET_ASSESS_VM_COUNT,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getslmtable = createAsyncThunk(
  "access/slm/table",
  async (data, { rejectWithValue }) => {
    // console.log("datrafortable",data)

    try {
      const countData = await client_a2c.mutate({
        mutation: GET_SLM_TABLE,
        variables: {
          account_name: data.account_name,
          application: data.application,
          category: data.category,
          company: data.company,
          organiation_unit: data.organization_unit,
          sla: data.sla,
          ticker_owner: data.ticker_owner,
          ticket_closed_by: data.ticket_closed_by,
          start_date: data.start_date,
          end_date: data.end_date,
        },
      });
      return countData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const Ticketseverity = createAsyncThunk(
  "access/ticket/severity",
  async (data, { rejectWithValue }) => {
    try {
      const ticketcount = await client_a2c.query({
        query: TICKET_SEVERITY,
        variables: {
          account_name: data.account_name,
          application: data.application,
          category: data.category,
          company: data.company,
          organiation_unit: data.organiation_unit,
          sla: data.sla,
          ticker_owner: data.ticker_owner,
          ticket_closed_by: data.ticket_closed_by,
          start_date: data.start_date,
          end_date: data.end_date,
        },
      });
      return ticketcount;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const AverageResolution = createAsyncThunk(
  // action type string
  "access/vm/count",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log("dataseverity",data)
    try {
      const averageresol = await client_a2c.query({
        query: AVERAGE_RESOLUTION,
        variables: {
          account_name: data.input.account_name,
          application: data.input.application,
          category: data.input.category,
          company: data.input.company,
          organiation_unit: data.input.organiation_unit,
          sla: data.input.sla,
          ticker_owner: data.input.ticker_owner,
          ticket_closed_by: data.input.ticket_closed_by,
          start_date: data.input.start_date,
          end_date: data.input.end_date,
        },
      });
      return averageresol;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const AverageFirst = createAsyncThunk(
  // action type string
  "access/vm/count",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log("average firstt",data)
    try {
      const averageresol = await client_a2c.query({
        query: AVERAGE_FIRST_RESPONSE,
        variables: {
          account_name: data.account_name,
          application: data.application,
          category: data.category,
          company: data.company,
          organiation_unit: data.organiation_unit,
          sla: data.sla,
          ticker_owner: data.ticker_owner,
          ticket_closed_by: data.ticket_closed_by,
          start_date: data.start_date,
          end_date: data.end_date,
        },
      });
      return averageresol;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const Incident = createAsyncThunk(
  // action type string
  "access/vm/count",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const averageresol = await client_a2c.query({
        query: INCIDENET,

        variables: {
          account_name: data.account_name,
          application: data.application,
          category: data.category,
          company: data.company,
          organiation_unit: data.organiation_unit,
          sla: data.sla,
          ticker_owner: data.ticker_owner,
          ticket_closed_by: data.ticket_closed_by,
          start_date: data.start_date,
          end_date: data.end_date,
        },
      });
      return averageresol;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOperateDBSpread = createAsyncThunk(
  // action type string
  "operate/dr-spread",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const queryData = await client_a2c.mutate({
        mutation: GET_OPERATE_DB_SPREAD,
        variables: {
          application: data.application,
          company: data.company,
          account_name: data.account_name,
          organiation_unit: data.organiation_unit,
          environment: data.environment,
        },
      });
      return queryData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOperateDRSpread = createAsyncThunk(
  // action type string
  "operate/dr-spread",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const queryData = await client_a2c.mutate({
        mutation: GET_OPERATE_DR_SPREAD,
        variables: {
          application: data.application,
          company: data.company,
          account_name: data.account_name,
          organiation_unit: data.organiation_unit,
          environment: data.environment,
        },
      });
      return queryData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getOperateMonitoring = createAsyncThunk(
  // action type string
  "operate/monitoring",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const queryData = await client_a2c.mutate({
        mutation: GET_OPERATE_MONITORING,
        variables: {
          application: data.application,
          company: data.company,
          account_name: data.account_name,
          organiation_unit: data.organiation_unit,
          environment: data.environment,
        },
      });
      return queryData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getslmapplication = createAsyncThunk(
  // action type string
  "access/vm/count",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log("getapplicationslm",data)
    try {
      const slmapplicationchart = await client_a2c.query({
        query: GET_SLM_APPLICATION,
        variables: {
          account_name: data.account_name,
          application: data.application,
          category: data.category,
          company: data.company,
          organiation_unit: data.organiation_unit,
          sla: data.sla,
          ticker_owner: data.ticker_owner,
          ticket_closed_by: data.ticket_closed_by,
          start_date: data.start_date,
          end_date: data.end_date,
        },
      });
      return slmapplicationchart;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getapplicationcount = createAsyncThunk(
  // action type string
  "access/application",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log("getapplicationcount",data)
    try {
      const ticketcount = await client_a2c.query({
        query: GET_APPLICATION_COUNT,
        variables: {
          account_name: data.account_name,
          application: data.application,
          category: data.category,
          company: data.company,
          organiation_unit: data.organiation_unit,
          sla: data.sla,
          ticker_owner: data.ticker_owner,
          ticket_closed_by: data.ticket_closed_by,
          start_date: data.start_date,
          end_date: data.end_date,
        },
      });
      return ticketcount;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOperateCMA = createAsyncThunk(
  // action type string
  "operate/ou-count",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const queryData = await client_a2c.mutate({
        mutation: GET_OPERATE_CMA,
        variables: {
          application: data.application,
          company: data.company,
          account_name: data.account_name,
          organiation_unit: data.organiation_unit,
          environment: data.environment,
        },
      });
      return queryData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOperateWAF = createAsyncThunk(
  // action type string
  "operate/waf",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const queryData = await client_a2c.mutate({
        mutation: GET_OPERATE_WAF,
        variables: {
          application: data.application,
          company: data.company,
          account_name: data.account_name,
          organiation_unit: data.organiation_unit,
          environment: data.environment,
        },
      });
      return queryData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getOperateTCO = createAsyncThunk(
  // action type string
  "operate/tco",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const queryData = await client_a2c.mutate({
        mutation: GET_OPERATE_TCO,
        variables: {
          application: data.application,
          company: data.company,
          account_name: data.account_name,
          organization_unit: data.organiation_unit,
          environment: data.environment,
        },
      });
      return queryData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const categorycount = createAsyncThunk(
  // action type string
  "access/category/count",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const ticketcount = await client_a2c.query({
        query: GET_CATEGORY_COUNT,
        variables: {
          account_name: data.account_name,
          application: data.application,
          category: data.category,
          company: data.company,
          organiation_unit: data.organiation_unit,
          sla: data.sla,
          ticker_owner: data.ticker_owner,
          ticket_closed_by: data.ticket_closed_by,
          start_date: data.start_date,
          end_date: data.end_date,
        },
      });
      return ticketcount;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getslagraph = createAsyncThunk(
  // action type string
  "access/category/sla",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log("get_sla_graphh",data)
    try {
      const ticketcount = await client_a2c.query({
        query: GET_SLA_GRAPH,
        variables: {
          account_name: data.account_name,
          application: data.application,
          category: data.category,
          company: data.company,
          organiation_unit: data.organiation_unit,
          sla: data.sla,
          ticker_owner: data.ticker_owner,
          ticket_closed_by: data.ticket_closed_by,
          start_date: data.start_date,
          end_date: data.end_date,
        },
      });
      return ticketcount;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOperateRAMUtil = createAsyncThunk(
  // action type string
  "operate/os-count",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const queryData = await client_a2c.mutate({
        mutation: GET_OPERATE_RAM_UTIL,
        variables: {
          application: data.application,
          company: data.company,
          account_name: data.account_name,
          organiation_unit: data.organiation_unit,
          environment: data.environment,
        },
      });
      return queryData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOperateStorageUtil = createAsyncThunk(
  // action type string
  "operate/os-count",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const queryData = await client_a2c.mutate({
        mutation: GET_OPERATE_STORAGE_UTIL,
        variables: {
          application: data.application,
          company: data.company,
          account_name: data.account_name,
          organiation_unit: data.organiation_unit,
          environment: data.environment,
        },
      });
      return queryData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const assessdr = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_DR,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const assesswaf = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_WAF,
        variables: {
          company: data.company,
          environment: data.environment,
          account_name: data.account_name,
          organiation_unit: data.organiation_unit,
          application: data.application,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const assessmonitor = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_MONITOR,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const assessstorageutil = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_STORAGE_UTIL,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const assessramutil = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_RAM_UTIL,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const tcoanalysis = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: TCO_ANALYSIS,
        variables: {
          company: data.company,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
//ASSESS_PHASE_WISE
export const assessphasewise = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_PHASE_WISE,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const assessplannedmigration = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_PLANNED_MIGRATION,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const assessplannedmigrationtable = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_TO_BE_TABLE,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const assesstobecore = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_TO_BE_CORE,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const assesstobememory = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_TO_BE_MEMORY,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const assesscloudreadiness = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_CLOUD_READINESS,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const assessmigration = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_MIGRATIONMETHOD,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const assessmigrationcomp = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_MIGRATIONCOMP,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const assessis = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_IS,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const assesstocore = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_CORE,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getTotalResourcetobemigrated = createAsyncThunk(
  // action type string

  "access/vm/count",

  // callback function

  async (data, { rejectWithValue }) => {
    try {
      const resourceMigrated = await client_a2c.query({
        query: GET_TOTAL_RESOURCE_TO_BE_MIGRATED,

        variables: {
          application: data.application,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
          company: data.company,
        },
      });

      return resourceMigrated;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
    }
  }
);
export const getTotalresourceyettobemigrated = createAsyncThunk(
  // action type string
  "access/vm/count",
  // callback function

  async (data, { rejectWithValue }) => {
    try {
      // console.log("helloeveryone", data);

      const resourceMigrated = await client_a2c.query({
        query: GET_TOTAL_RESOURCES_YET_TO_BE_MIGRATED,
        variables: {
          application: data.application,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
          company: data.company,
        },
      });
      return resourceMigrated;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
    }
  }
);
export const getResourceMigrated = createAsyncThunk(
  // action type string
  "access/vm/count",
  // callback function

  async (data, { rejectWithValue }) => {
    try {
      // console.log("helloeveryone", data);

      const resourceMigrated = await client_a2c.query({
        query: GET_TOTAL_RESOURCES_MIGRATED,
        variables: {
          application: data.application,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
          company: data.company,
        },
      });

      return resourceMigrated;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
    }
  }
);
export const getTotalResourceunderSpread = createAsyncThunk(
  // action type string
  "access/vm/count",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const resourceMigrated = await client_a2c.query({
        query: GET_TOTAL_RESOURCE_SPREAD,
        variables: {
          application: data.application,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
          company: data.company,
        },
      });

      return resourceMigrated;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
    }
  }
);

export const getTotalresourceandcount = createAsyncThunk(
  // action type string
  "access/vm/count",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const countData = await client_a2c.mutate({
        mutation: GET_TARGET_AND_TOTAL_RESOURCE_COUNT,
        variables: {
          application: data.application,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
          company: data.company,
        },
      });
      return countData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTotalCleanupCount = createAsyncThunk(
  // action type string
  "access/vm/count",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const countData = await client_a2c.mutate({
        mutation: GET_TOTAL_CLEANUP_COUNT,
        variables: {
          application: data.application,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
          company: data.company,
        },
      });
      return countData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getMigrationcompletionProcess = createAsyncThunk(
  // action type string

  "access/vm/count",

  // callback function

  async (data, { rejectWithValue }) => {
    try {
      const resourceMigrated = await client_a2c.query({
        query: MIGRATION_COMPLETION_PROCESS,

        variables: {
          application: data.application,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
          company: data.company,
        },
      });

      return resourceMigrated;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
    }
  }
);

export const getMigrationbyPlannedDate = createAsyncThunk(
  // action type string

  "access/vm/count",

  // callback function

  async (data, { rejectWithValue }) => {
    try {
      const resourceMigrated = await client_a2c.query({
        query: GET_PLANNED_MIGRATION_BY_DATE,

        variables: {
          application: data.application,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
          company: data.company,
        },
      });

      return resourceMigrated;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
    }
  }
);

export const getMigrationByActualDate = createAsyncThunk(
  // action type string

  "access/vm/count",

  // callback function

  async (data, { rejectWithValue }) => {
    try {
      const resourceMigrated = await client_a2c.query({
        query: GET_ACTUAL_MIGRATION_BY_DATE,

        variables: {
          application: data.application,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
          company: data.company,
        },
      });

      return resourceMigrated;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
    }
  }
);

export const getResourceStatus = createAsyncThunk(
  // action type string

  "access/vm/count",

  // callback function

  async (data, { rejectWithValue }) => {
    try {
      const resourceMigrated = await client_a2c.query({
        query: GET_RESOURCE_STATUS,

        variables: {
          application: data.application,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
          company: data.company,
        },
      });

      return resourceMigrated;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
    }
  }
);

export const assessosspread = createAsyncThunk(
  // action type string
  "assessosspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_OS_SPREAD,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const assessdbspread = createAsyncThunk(
  // action type string
  "assessdbspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_DB_SPREAD,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const assessdcspread = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_DC_SPREAD,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const assesscoreram = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_CORERAM,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const assessstorage = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_a2c.mutate({
        mutation: ASSESS_STORAGE,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          db: data.db,
          environment: data.environment,
          os: data.os,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getOperateVMDetails = createAsyncThunk(
  // action type string
  "operate/vm/details",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const queryData = await client_a2c.query({
        query: GET_OPERATE_VM_DETAILS,
        variables: {
          application: data.application,
          company: data.company,
          account_name: data.account_name,
          organiation_unit: data.organiation_unit,
          environment: data.environment,
        },
      });
      return queryData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getOperateOUCount = createAsyncThunk(
  // action type string
  "operate/ou-count",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const queryData = await client_a2c.mutate({
        mutation: GET_OPERATE_OU_COUNT,
        variables: {
          application: data.application,
          company: data.company,
          account_name: data.account_name,
          organiation_unit: data.organiation_unit,
          environment: data.environment,
        },
      });
      return queryData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getOperateServiceCount = createAsyncThunk(
  // action type string
  "operate/service-count",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const queryData = await client_a2c.mutate({
        mutation: GET_OPERATE_SERVICE_COUNT,
        variables: {
          application: data.application,
          company: data.company,
          account_name: data.account_name,
          organiation_unit: data.organiation_unit,
          environment: data.environment,
        },
      });
      return queryData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getOperateOSCount = createAsyncThunk(
  // action type string
  "operate/os-count",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const queryData = await client_a2c.mutate({
        mutation: GET_OPERATE_OS_COUNT,
        variables: {
          application: data.application,
          company: data.company,
          account_name: data.account_name,
          organiation_unit: data.organiation_unit,
          environment: data.environment,
        },
      });
      return queryData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getslmfilter = createAsyncThunk(
  // action type string
  "operate/os-count",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const slmfilter = await client_a2c.mutate({
        mutation: GET_SLM_FILTER,
        variables: {
          company: data.companyName,
        },
      });
      return slmfilter;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getAssessReducer = createAsyncThunk(
  // action type string

  "access/vm/count",

  // callback function

  async (data, { rejectWithValue }) => {
    try {
      const resourceMigrated = await client_a2c.query({
        query: GET_ASSESS_FILTER,

        variables: {
          company: data.companyName,
        },
      });

      return resourceMigrated;
    } catch (error) {
      useErrorNavigation(error);

      console.log(error);
    }
  }
);

export const getOperateReducer = createAsyncThunk(
  // action type string
  "operate/filter",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const filterData = await client_a2c.query({
        query: GET_OPERATE_FILTER,

        variables: {
          company: data.companyName,
        },
      });
      return filterData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const applicationModernizationASSIS = createAsyncThunk(
  "access/slm/table",
  async (data, { rejectWithValue }) => {
    // console.log("datrafortable",data)
    try {
      const modernizationApp = await client_a2c.mutate({
        mutation: GET_APP_MODERNIZATION_IS,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          environment: data.environment,
          db: data.db,
          os: data.os,
        },
      });
      return modernizationApp;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const applicationModernizationtobe = createAsyncThunk(
  "access/slm/table",
  async (data, { rejectWithValue }) => {
    // console.log("datrafortable",data)
    try {
      const modernizationApptobe = await client_a2c.mutate({
        mutation: GET_APP_MODERNIZATION_TO_BE,
        variables: {
          application: data.application,
          company: data.company,
          data_center_name: data.data_center_name,
          environment: data.environment,
          db: data.db,
          os: data.os,
        },
      });
      return modernizationApptobe;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const kuberneteslandscapespread = createAsyncThunk(
  "access/slm/table",
  async (data, { rejectWithValue }) => {
    // console.log("datrafortable",data)

    try {
      const kubernetesspread = await client_a2c.mutate({
        mutation: GET_KUBERNETES_SPREAD,
        variables: {
          application: data.application,
          company: data.company,

          environment: data.environment,
          organization_unit: data.organization_unit,
          account_name: data.account_name,
        },
      });
      return kubernetesspread;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const operateecs = createAsyncThunk(
  "access/slm/table",
  async (data, { rejectWithValue }) => {
    // console.log("datrafortable",data)

    // console.log(" ecs datas", data);

    try {
      const ecs = await client_a2c.mutate({
        mutation: GET_ECS,
        variables: {
          account_name: data.account_name,
          application: data.application,
          company: data.company,
          environment: data.environment,
          organization_unit: data.organization_unit,
        },
      });
      return ecs;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const totalkubernetescount = createAsyncThunk(
  "access/vm/count",
  async (data, { rejectWithValue }) => {
    // console.log("totalusage",data)
    // console.log(data, "totalkubernets");
    try {
      const averageresol = await client_a2c.query({
        query: TOTAL_USAGE_COUNT_KUBERNETES,
        variables: {
          account_name: data.totalkube.account_name,
          application: data.totalkube.application,
          company: data.totalkube.company,
          organiation_unit: data.totalkube.organiation_unit,
        },
      });
      return averageresol;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const memorycpucount = createAsyncThunk(
  "access/memeory/cpu/count",
  async (data, { rejectWithValue }) => {
    // console.log(data, "memorycpuvalues");
    try {
      const averageresol = await client_a2c.query({
        query: MEMORY_CPU_COUNT,
        variables: {
          account_name: data.totalmemory.account_name,
          application: data.totalmemory.application,
          company: data.totalmemory.company,
          organiation_unit: data.totalmemory.organiation_unit,
          startdate: data.totalmemory.startdate,
        },
      });
      return averageresol;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const ecsmemorycpu = createAsyncThunk(
  "access/memeory/cpu/count",
  async (data, { rejectWithValue }) => {
    // console.log(data, "memorycpuvalues");
    try {
      const averageresol = await client_a2c.query({
        query: ECS_MEMORY_CPU_COUNT,
        variables: {
          account_name: data.ecsmemorycpu1.account_name,
          application: data.ecsmemorycpu1.application,
          company: data.ecsmemorycpu1.company,
          organiation_unit: data.ecsmemorycpu1.organiation_unit,
          startdate: data.ecsmemorycpu1.startdate,
        },
      });
      return averageresol;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const ecstotalcount = createAsyncThunk(
  "access/ecs/count",
  async (data, { rejectWithValue }) => {
    // console.log(data, "ecstotalcount");
    try {
      const averageresol = await client_a2c.query({
        query: ECS_COUNT_TOTAL,
        variables: {
          account_name: data.ecsṭotal.account_name,
          application: data.ecsṭotal.application,
          company: data.ecsṭotal.company,
          organiation_unit: data.ecsṭotal.organiation_unit,
        },
      });
      return averageresol;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const assesadvisoryscore = createAsyncThunk(
  "access/ecs/count",
  async (data, { rejectWithValue }) => {
    // console.log(data, "ecstotalcount");
    try {
      const advisoryscore = await client_a2c.query({
        query: ASSSES_ADVIISORY_SCORE,
        variables: {
          application: data.application,
          company: data.company,

          data_center_name: data.data_center_name,
          environment: data.environment,
        },
      });
      return advisoryscore;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createworkload = createAsyncThunk(
  "war/worklaod/creation",
  async (data, { rejectWithValue }) => {
    try {
      const workloadcreation = await client_war.query({
        query: CREATE_WORKLOAD_NAME,
        variables: {
          event_type: data.input.event_type,
          workload_name: data.input.workload_name,
          description: data.input.description,
          environment: data.input.environment,
          account_id: data.input.account_id,
          architectural_design: "",
          review_owner: data.input.review_owner,
          lenses: data.input.lenses,
          aws_regions: data.input.aws_regions,
        },
      });
      return workloadcreation;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listworkloadnames = createAsyncThunk(
  "war/worklaod/creation",
  async (data, { rejectWithValue }) => {
    try {
      const workloadcreation = await client_war.query({
        query: LIST_WORKLOAD,
        variables: {
          event_type: data.listinput.event_type,
        },
      });
      return workloadcreation;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listriskcounts = createAsyncThunk(
  "war/worklaod/listrisks",
  async (data, { rejectWithValue }) => {
    try {
      const listriskscount = await client_war.query({
        query: LIST_RISKCOUNTS,
        variables: {
          event_type: data.riskinput.event_type,
          workload_id: data.riskinput.workload_id,
        },
      });
      return listriskscount;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listquestions = createAsyncThunk(
  "war/worklaod/listrisks",
  async (data, { rejectWithValue }) => {
    try {
      const listquestiondata = await client_war.query({
        query: LIST_QUESTION_PILLARS,
        variables: {
          event_type: data.questionlist.event_type,
          piller_type: data.questionlist.piller_type,
        },
      });
      return listquestiondata;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const questionupdate = createAsyncThunk(
  "war/worklaod/listrisks",
  async (data, { rejectWithValue }) => {
    console.log(data, "listriskkk");
    try {
      const updatequestions = await client_war.query({
        query: UPDATE_QUESTIONS_DATA,
        variables: {
          event_type: data.input1.event_type,
          is_applicable: data.input1.is_applicable,
          workload_id:data.input1.workload_id,
          lens_alias: data.input1.lens_alias,
          notes: data.input1.notes,
          question_id: data.input1.question_id,
          selected_choices: data.input1.selected_choices,
        },
      });
      return updatequestions;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const riskcountpillarwise = createAsyncThunk(
  "war/worklaod/listrisks",
  async (data, { rejectWithValue }) => {
    console.log(data, "listriskkk");
    try {
      const riskpillarwisecount = await client_war.query({
        query: RISK_COUNTWISE_PILLAR,
        variables: {
          event_type: data.input1.event_type,
          lenses: data.input1.lenses,
          pillar_id: data.input1.pillar_id,
          pillar_name: data.input1.pillar_name,
          workload_id: data.input1.workload_id,
        },
      });
      return riskpillarwisecount;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const downloadreport = createAsyncThunk(
  "war/workload/download/report",
  async (data, { rejectWithValue }) => {
    try {
      const reportdownload = await client_war.query({
        query: DOWNLOAD_REPORT,
        variables: {
          event_type: data.reportlist.event_type,
          lens_alias: data.reportlist.lens_alias,
          workload_id: data.reportlist.workload_id,
        },
      });
      return reportdownload;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const assessmenttable = createAsyncThunk(
  "assessdcspread/chart",
  async (data, { rejectWithValue }) => {
    try {
      const assementtable = await client_a2c.mutate({
        mutation: ASSESSMENT_TABLE,
        variables: {
          application: data.application,
          cpu_as_is: data.cpu_as_is,
          customer: "internal1ch",
          data_center_name: data.data_center_name,
          database_type: data.database_type,
          dr_implementation: data.dr_implementation,
          environment: data.environment,
          memory_as_is: data.memory_as_is,
          migration_complexity: data.migration_complexity,
          migration_methodology: data.migration_methodology,
          modernization_type: data.modernization_type,
          monitoring_stratergy: data.monitoring_stratergy,
          operating_system: data.operating_system,
          region: data.region,
          resource_name: data.resource_name,
          server_role: data.server_role,
          storage_as_is: data.storage_as_is,
        },
      });
      return assementtable;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const assessmenttablelist = createAsyncThunk(
  "assessdcspread/chartlist",
  async (data, { rejectWithValue }) => {
    try {
      const assesstablelist = await client_a2c.query({
        query: ASSESSMENT_TABLE_LIST,
        variables: {
          company: data.company,
        },
      });
      return assesstablelist;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const assessmenttableupdate = createAsyncThunk(
  "assessdcspread/chartupdate",
  async (data, { rejectWithValue }) => {
    try {
      const assesstableupdate = await client_a2c.mutate({
        mutation: ASSESSMENT_TABLE_UPDATE,
        variables: {
          application: data.application,
          cpu_as_is: data.cpu_as_is,
          customer: "internal1ch",
          data_center_name: data.data_center_name,
          database_type: data.database_type,
          dr_implementation: data.dr_implementation,
          environment: data.environment,
          memory_as_is: data.memory_as_is,
          migration_complexity: data.migration_complexity,
          migration_methodology: data.migration_methodology,
          modernization_type: data.modernization_type,
          monitoring_stratergy: data.monitoring_stratergy,
          operating_system: data.operating_system,
          region: data.region,
          resource_name: data.resource_name,
          server_role: data.server_role,
          storage_as_is: data.storage_as_is,
          id: data.id,
        },
      });
      return assesstableupdate;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listriskquestions = createAsyncThunk(
  "war/workload/download/report",
  async (data, { rejectWithValue }) => {
    try {
      const riskquestions = await client_war.query({
        query: RISK_QUESTION_LIST,
        variables: {
          event_type: data.riskquestions.event_type,
          lens_alias: data.riskquestions.lens_alias,
          workload_id: data.riskquestions.workload_id,
        },
      });
      return riskquestions;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const assessmenttabledelete = createAsyncThunk(
  "assessdcspread/chartdelete",
  async (data, { rejectWithValue }) => {
    try {
      const assesstabledelete = await client_a2c.mutate({
        mutation: ASSESSMENT_TABLE_DELETE,
        variables: {
          customer: data.customer,
          id: data.id,
        },
      });
      return assesstabledelete;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const assessmenttableupload = createAsyncThunk(
  "assessdcspread/chartupload",
  async (data, { rejectWithValue }) => {
    try {
      const assesstableupload = await client_a2c.query({
        query: ASSESSMENT_TABLE_UPLOAD,
        variables: {
          file: {
            bucket: "1ch-platform-assess-data-a2crover",
            content: data.content,
            contentType: "csv",
            key: "MY-File",
            region: "ap-south-1",
            customer: "internal1ch",
            event: "Create",
          },
        },
      });
      return assesstableupload;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
