import { Table, Tooltip } from "antd";
import Column from "antd/es/table/Column";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getForecastSpend,
  getForecastSpendDetails,
} from "../../../action/costimizeV2Active";
import { getForecastApiData } from "../../../action/costimizedashboardAction";

function SpendForecastTableData() {
  const [forecastdetailsrecord, setForecastdetailrecord] = useState([]);
  const [forecastDataState, setForecastDataState] = useState("");
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [forecastdetails, setForecastDetails] = useState({
    forcast_value: 0,
    status: "=",
    diff_value: 0,
  });
  const [budgetdetails, setBudgetDetails] = useState({
    overall_budget: 0,
    last_month_budget: 0,
    month_budget: 0,
    status: "=",
    chartvalue: [0, 0, 0],
  });
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  useEffect(() => {
    var apivalue = {
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
    };

    dispatch(
      getForecastSpend({
        companyName,
        projected_date: selectedTopdate,
        apivalue,
      })
    )
      .unwrap()
      .then(({ data }) => {
        var tempprojectspend = data.newfunctionquery8?.reduce(
          (a, v) => (a = +Number(a) + +Number(v.projected_spend)),
          0
        );
        var status_value = "";
        var diff_value = "";
        const budget_amount = budgetdetails.overall_budget;
        if (Number(budget_amount) === Number(tempprojectspend)) {
          status_value = "=";
          diff_value = 0;
        } else {
          if (budget_amount > tempprojectspend) {
            status_value = "+";
            diff_value = Math.round(
              (budget_amount * 100) / tempprojectspend - 100
            );
          } else {
            status_value = "-";
            // diff_value = Math.round(
            //   (budget_amount * 100) / tempprojectspend - 100
            // );
            diff_value = Math.round(
              (tempprojectspend * 100) / budget_amount - 100
            );
          }
        }
        if (diff_value === Infinity) {
          diff_value = 0;
        }
        setForecastDetails({
          forcast_value: Math.round(tempprojectspend),
          status: status_value,
          diff_value: Math.abs(diff_value),
        });
      })
      .catch((err) => {});
    // getForecastSpendDetails
    dispatch(
      getForecastSpendDetails({
        companyName,
        projected_date: selectedTopdate,
        apivalue,
      })
    )
      .unwrap()
      .then(({ data }) => {
        const currencyFormatter = (val) => {
          return new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0, // Ensure no rounding
            maximumFractionDigits: 0, // Specify the maximum number of fraction digits
          }).format(Number(val));
        };
        const record = data.projected_spend_detailed.map((datas) => {
          return {
            account_names: datas.account_names,
            clouds: datas.clouds,
            customer: datas.customer,
            month: datas.month,
            days: datas.days,
            remaining_days: datas.remaining_days,
            average_dailly_spend_for_last_7_days: currencyFormatter(
              datas.average_dailly_spend_for_last_7_days
            ),
            actuals_spent_for_the_past_days_of_the_month: currencyFormatter(
              datas.actuals_spent_for_the_past_days_of_the_month
            ),
            estimated_spend_for_remaining_days_of_the_month: currencyFormatter(
              datas.estimated_spend_for_remaining_days_of_the_month
            ),
            // parseFloat(datas.estimated_spend_for_remaining_days_of_the_month).toFixed(2),
            total_estimates_spend_pre_tax_and_support: currencyFormatter(
              datas.total_estimates_spend_pre_tax_and_support
            ),
            managed_service: currencyFormatter(datas.managed_service),
            tax: currencyFormatter(datas.tax),
            estimated_spend: currencyFormatter(datas.estimated_spend),
          };
        });
        setForecastdetailrecord(record);
      })
      .catch((err) => {
        console.log(err);
        // setLForecastStatus(false);
      });
  }, [selectedAccount, selectedTopdate, selectedCloud]);
  const currencyFormatter = (val) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD", // Change to your desired currency code
      minimumFractionDigits: 0, // Minimum number of fraction digits
      maximumFractionDigits: 2, // Maximum number of fraction digits
    }).format(Number(val));
  };

  useEffect(() => {
    //This api was calling to get total forecast sumed value getForecastApiData
    const apiVariablesForForecast = {
      event_type: "forecast trend",
      screen: "qg",
      account_name: selectedAccount.toString(),
      company: companyName,
      cloud: selectedCloud.toString(),
      month: selectedTopdate,
    };
    dispatch(getForecastApiData(apiVariablesForForecast))
      .unwrap()
      .then(({ data }) => {
        const jsonString = data?.forecasttrend;
        // Parse the JSON-encoded string to a JavaScript object
        let resp = JSON.parse(jsonString);
        // Custom comparator function to sort by report_month
        const sortByDate = (a, b) => {
          const dateA = new Date(a.report_month);
          const dateB = new Date(b.report_month);
          return dateA - dateB;
        };

        // Sort the array
        const sortedData = resp.sort(sortByDate);
        setForecastDataState({
          foreCastTrend: sortedData,
          currentMonth: resp[resp.length - 1],
        });
      })
      .catch((err) => {
        console.log(err?.message);
      });
  }, [selectedTopdate, selectedAccount, selectedCloud]);
  return (
    <>
      {/* {JSON.stringify(forecastDataState?.currentMonth?.forecast)} */}
      <div className="costdmaze-info-table py-4">
        {/* {JSON.stringify(forecastdetailsrecord)} */}
        <Table
          className="scroll-table"
          size="small"
          loading={forecastdetailsrecord.length ? false : true}
          dataSource={forecastdetailsrecord}
          pagination={false}
          footer={() => (
            <tr style={{ textAlign: "right" }}>
              <td>
                Total Forecast Value:{" "}
                {currencyFormatter(forecastDataState?.currentMonth?.forecast)}
              </td>
            </tr>
          )}
        >
          <Column
            title="Acc Name"
            dataIndex="account_names"
            key="account_names"
          />
          <Column
            title="Cloud"
            dataIndex="clouds"
            key="clouds"
            className="text-center"
          />
          {/* <Column title="Date" dataIndex="month" key="month" /> */}
          {/* <Column title="Customer" dataIndex="customer" key="customer" /> */}

          <Column
            title={
              <Tooltip placement="topLeft" title="a">
                Days
              </Tooltip>
            }
            className="text-center"
            dataIndex="days"
            key="days"
          />

          <Column
            title={
              <Tooltip placement="topLeft" title="b">
                Remaining Days{" "}
              </Tooltip>
            }
            dataIndex="remaining_days"
            className="text-center"
            key="remaining_days"
          />

          <Column
            title={
              <Tooltip placement="topLeft" title="x">
                Last 7 days average
              </Tooltip>
            }
            className="text-end"
            // title="Average Dailly spend for last 7 days"
            dataIndex="average_dailly_spend_for_last_7_days"
            key="average_dailly_spend_for_last_7_days"
          />

          <Column
            title={
              <Tooltip placement="topLeft" title="A">
                Spend for the past days
              </Tooltip>
            }
            className="text-end"
            dataIndex="actuals_spent_for_the_past_days_of_the_month"
            key="actuals_spent_for_the_past_days_of_the_month"
          />

          <Column
            title={
              <Tooltip placement="topLeft" title="B=b*x">
                Spend for remaining days{" "}
              </Tooltip>
            }
            className="text-end"
            dataIndex="estimated_spend_for_remaining_days_of_the_month"
            key="estimated_spend_for_remaining_days_of_the_month"
          />

          <Column
            title={
              <Tooltip placement="topLeft" title="C = A+ B">
                Total Estimates Spend Pre Tax and Support
              </Tooltip>
            }
            className="text-end"
            dataIndex="total_estimates_spend_pre_tax_and_support"
            key="total_estimates_spend_pre_tax_and_support"
          />

          <Column
            title={
              <Tooltip placement="topLeft" title="D = C * % Support">
                Managed Service
              </Tooltip>
            }
            className="text-end"
            dataIndex="managed_service"
            key="managed_service"
          />

          <Column
            title={
              <Tooltip placement="topLeft" title="E = (C+D) * 0.18">
                Tax
              </Tooltip>
            }
            className="text-end"
            dataIndex="tax"
            key="tax"
          />

          <Column
            title={
              <Tooltip placement="topLeft" title="F = C+D+E">
                Estimated Spend
              </Tooltip>
            }
            className="text-end"
            dataIndex="estimated_spend"
            key="estimated_spend"
          />
        </Table>
      </div>
    </>
  );
}

export default SpendForecastTableData;
