import React from "react";
import { Tabs, Row, Card, Col, Spin } from "antd";
import CostimizeSidebar from "../../costimize_v2/layout/CostimizeSidebar";
import MainHead from "../../costimize_v2/layout/MainHead";
import InstanceRuntimeTable from "./instanceRuntimeTable";
import InstanceTrackingTable from "./instanceTrackingTable";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import ReactApexChart from "react-apexcharts";
import InstanceStoppedTable from "./InstanceStoppedTable";
import { useDispatch, useSelector } from "react-redux";
import { listStopInstance } from "../../action/costimizeV2Active";

const InstanceOverview = () => {
  const [rolestatus, seRoleStatus] = useState(true);
  const [instanceruntimerecords, setInstanceRuntimeRecords] = useState([]);
  const [totalinstance, setTotalInstance] = useState(0);
  const [instancechartvalue, setInstanceChartValue] = useState([0, 0, 0]);
  const [stopinstancecount, setStopInstanceCount] = useState(0);
  const [tablerecords, setTableRecords] = useState([]);
  const [loader, setLoader] = useState(false);

  const {
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedos,
  } = useSelector((state) => state.constimize);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const role = JSON.parse(
      localStorage.getItem("CognitoIdentityServiceProvider.role")
    );
    if (role) {
      if (role[0]["instance_overview"] === "Read") {
        seRoleStatus(false);
      }
    }
  }, []);
  useEffect(() => {
    setTotalInstance(instanceruntimerecords.length);
    const withoutplanned = instanceruntimerecords.filter((item) => {
      if (Math.round(Number(item.planned_runtime)) === 0) {
        return item;
      }
    });
    const withplanned = instanceruntimerecords.filter((item) => {
      if (Math.round(Number(item.planned_runtime)) !== 0) {
        return item;
      }
    });
    setInstanceChartValue([
      withoutplanned.length,
      withplanned.length,
      stopinstancecount,
    ]);
  }, [instanceruntimerecords, stopinstancecount]);

  useEffect(() => {
    const apivalue = {
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      cloud: selectedCloud.toString(),
      company_name: companyName,
      environment: selectedEnvironment.toString(),
      month: selectedTopdate,
      os: selectedos.toString(),
    };

    dispatch(listStopInstance({ apivalue }))
      .unwrap()
      .then(({ data }) => {
        setTableRecords(data.get_stopped_instance);
        setStopInstanceCount(data.get_stopped_instance.length);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedEnvironment,
    selectedTopdate,
    selectedos,
  ]);

  const items = [
    {
      key: "1",
      label: `Instance Runtime`,
      children: (
        <InstanceRuntimeTable
          rolestatus={rolestatus}
          setInstanceRuntimeRecords={setInstanceRuntimeRecords}
          setLoader={setLoader}
        />
      ),
    },
    {
      key: "2",
      label: `Instance Tracking`,
      children: <InstanceTrackingTable />,
    },
    {
      key: "3",
      label: `Stopped Instances`,
      children: <InstanceStoppedTable tablerecords={tablerecords} />,
    },
  ];

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        distributed: true,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
      truncate: {
        maxChars: 10,
      },
    },
    // colors: ["#76C5F1"],
    // colors: ["#F12A3D", "#FCAF04", "#5A95E4"],
    colors: ["#FF6A2F", "#FF6A2F", "#FF6A2F"],
    
    grid: {
      show: false,
    },
    yaxis: {
      labels: {
        style:{
          colors:"white",
          },
        maxWidth: 250,
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [
        "Planned hrs not defined",
        "Running hrs exceeding planned",
        "Stopped Instances(more then a week)",
      ],
      labels: {
        // maxWidth: 300,
        style:{
        colors:"white",
        },
        formatter: function (num, opts) {
          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
  };

  const series = [
    {
      name: "Instance Runtime",
      data: instancechartvalue,
    },
  ];

  return (
    <>
      <div style={{ display: "flex", height: "100%" }} className="costimize_bg">
        <CostimizeSidebar />
        <MainHead title="Instance Overview" />

        <div className="costimize-body-screen">
          <Row>
            <h1 className="instance-title">
              Non Production Runtime Compliance
            </h1>
          </Row>
          <Row gutter={[16, 16]} className="mt-3">
            <Col xxl={4} xl={4} lg={4} md={24} sm={24} xs={24}>
              <Card className="plat-card-1">
                <Spin spinning={loader} wrapperClassName="costimize-loader">
                  <div className="mt-3">
                    <h1 className="card-1-title-2 site-color">Total</h1>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Icon
                      icon="pajamas:clear-all"
                      className="costimize-center-icon site-color"
                    />
                  </div>
                  <div className="mb-3">
                    <h1 className="card-1-value-2 site-color">
                      {totalinstance}
                    </h1>
                  </div>
                </Spin>
              </Card>
            </Col>
            <Col xxl={20} xl={20} lg={20} md={24} sm={24} xs={24}>
              <Card className="plat-card-1">
                <Spin spinning={loader} wrapperClassName="costimize-loader">
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={130}
                  />
                </Spin>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4" gutter={[16, 16]}>
            <Tabs
              defaultActiveKey="1"
              items={items}
              className="cams-tabs"
            />
          </Row>
        </div>
      </div>
    </>
  );
};

export default InstanceOverview;
