import { useEffect, useState, memo } from "react";
import { Table, Spin } from "antd";
import { Icon } from "@iconify/react";
import awsicon from "../../../assets/images/aws.svg";

const InstanceStoppedTable = (props) => {
  const [tablerecords, setTableRecords] = useState([]);

  useEffect(() => {
    setTableRecords(props.tablerecords);
  }, [props]);

  const history_columns = [
    {
      title: "Instance ID",
      dataIndex: "resourceid",
      key: "resourceid",
      align: "left",
      width: "40%",
      sorter: (a, b) => (a.resourceid > b.resourceid ? -1 : 1),
    },
    {
      title: "Instance Name",
      dataIndex: "resourcename",
      key: "resourcename",
      align: "left",
      width: "20%",
      sorter: (a, b) => (a.resourcename > b.resourcename ? -1 : 1),
    },
    {
      title: "Cloud",
      dataIndex: "cloud",
      key: "cloud",
      align: "left",
      width: "20%",
      sorter: (a, b) => (a.cloud > b.cloud ? -1 : 1),
      render: (record, index) => {
        if (record) {
          if (record.toLowerCase() === "aws") {
            return (
              <>
                <img src={awsicon} />
                {/* <Icon icon="logos:aws" class="asset-spend-cloud-list-icon" />{" "} */}
                AWS
              </>
            );
          } else if (record.toLowerCase() === "azure") {
            return (
              <>
                <Icon icon="vscode-icons:file-type-azure" /> Azure
              </>
            );
          } else if (record.toLowerCase() === "gcp") {
            return (
              <>
                <Icon icon="skill-icons:gcp-light" /> GCP
              </>
            );
          } else if (record.toLowerCase() === "oracle") {
            return (
              <>
                <Icon icon="simple-icons:oracle" style={{ color: "#FF0000" }} />{" "}
                Oracle
              </>
            );
          }
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "left",
      width: "20%",
      sorter: (a, b) => (a.status > b.status ? -1 : 1),
      render: (record, index) => {
        if (record) {
          return <>Last {record} Days</>;
        }
      },
    },
  ];
  const tableProps = {};
  return (
    <>
      <div className="costimize-table-width">
        <div className="instanceTrackingTable">
          {/* <Spin spinning={loader} wrapperClassName="costimize-loader"> */}
          <Table
            {...tableProps}
            className="slm"
            columns={history_columns}
            dataSource={tablerecords}
            pagination={true}
          />
          {/* </Spin> */}
        </div>
      </div>
    </>
  );
};

export default memo(InstanceStoppedTable);
