import React, { memo } from "react";

import {
  PDFViewer,
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import { useEffect } from "react";
import { useState } from "react";
import { capitalizeFirst } from "../custom_hook/CustomHook";
import * as inr from "inr-words";
const logo = require("../../assets/images/1CloudHub-Logo-horiz-hires-RGB-copy-1-removebg-preview 1.png");

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
  },
  sectionTitle: {
    margin: 10,
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  section: {
    margin: 15,
    padding: 10,
    fontSize: 10,
    // lineHeight:2,
  },
  viewer: {
    // width: window.innerWidth, //the pdf viewer will take up all of the width and height
    // height: window.innerHeight,
  },
  tableCell: {
    borderBottomWidth: 1,
    borderTop: 1,
    width: 90,

    // borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
  },
  tableCell_01: {
    borderBottomWidth: 1,
    width: "15%",
    borderTop: 1,

    // borderLeftWidth: 1,
    // borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 122,
  },
  tableCell_02: {
    borderBottomWidth: 1,
    width: "35%",
    borderTop: 1,

    // borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: "auto",
    padding: 5,
  },
  tableCell_client: {
    borderBottomWidth: 1,
    width: "50%",
    borderTop: 1,

    // borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 122,
    padding: 5,
  },
  tableCell_03: {
    borderBottomWidth: 1,
    width: "25%",
    borderTop: 1,

    // borderLeftWidth: 1,
    // borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 'auto',
    height: 122,
  },
  tableCell_03_col: {
    borderBottomWidth: 1,
    width: "100%",
    borderTop: 1,

    // borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 'auto',
    height: 122,
  },
  tableCell_03_col_terms: {
    borderBottomWidth: 1,
    width: "100%",
    borderTop: 1,

    // borderLeftWidth: 1,
    // borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 'auto',
    height: 122,
  },
  tableCell_03_col_3: {
    borderBottomWidth: 1,
    width: "100%",
    borderTop: 1,

    // borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 'auto',
    height: 60,
  },
  tableCell_04: {
    borderBottomWidth: 1,
    width: "25%",
    borderTop: 1,

    // borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 'auto',
    height: 122,
  },
  tableCell_04_col: {
    borderBottomWidth: 1,
    width: "100%",
    borderTop: 1,

    // borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 'auto',
    height: 122,
  },
  tableCell_04_col_4: {
    borderBottomWidth: 1,
    width: "100%",
    borderTop: 1,

    // borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 'auto',
    height: 40,
  },
  tableCell_04_subcol_4: {
    // borderBottomWidth: 1,
    width: "100%",
    //borderTop: 1,

    // borderLeftWidth: 1,
    // borderRightWidth: 1,
    // borderBottomColor: 'gray',
    flexDirection: "row",
    alignItems: "center",
    // height: 'auto',
    height: 40,
  },
  tableCell_1: {
    borderBottomWidth: 1,
    width: 30,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    padding: 5,
  },
  tableCell_2: {
    borderBottomWidth: 1,
    width: 230,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    padding: 5,
  },
  tableCell_2_total: {
    borderBottomWidth: 1,
    width: 230,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "end",
    textAlign: "right",
    height: 24,
    padding: 5,
  },
  tableCell_3: {
    borderBottomWidth: 1,
    width: 60,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    padding: 5,
  },
  tableCell_3_total: {
    borderBottomWidth: 1,
    width: 60,
    // borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    padding: 5,
  },
  tableCell_4: {
    borderBottomWidth: 1,
    width: 30,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    padding: 5,
  },
  tableCell_4_total: {
    borderBottomWidth: 1,
    width: 30,
    // borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    padding: 5,
  },
  tableCell_5: {
    borderBottomWidth: 1,
    width: 80,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    padding: 5,
    textAlign: "right",
    fontWeight: "bold",
  },
  tableCell_last: {
    borderBottomWidth: 1,
    width: 550,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    padding: 5,
    textAlign: "Start",
    fontWeight: "bold",
  },
  tableHeader: {
    fontWeight: "bold",
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "black",
    flexDirection: "row",
    alignItems: "center",
    height: 25,
  },

  image: {
    width: "50",
    height: 100,
    marginTop: 500,
    marginLeft: 500,
    // textAlign:'right',
    alignItems: "center",
  },

  text: {
    fontSize: 12,
    fontWeight: "bold", // set fontWeight to 'bold' to make the text bold
  },
  tableCol: {
    borderBottomWidth: 1,

    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: "auto",
  },
  //  tableCol:{
  //   borderBottomWidth: 1,

  //   borderLeftWidth: 1,
  //   borderRightWidth: 1,
  //   borderBottomColor: 'gray',
  //   flexDirection: 'row',
  //   alignItems: 'center',
  //   height: 'auto',
  // },
  tableCell_tax_sub: {
    // borderBottomWidth: 1,
    width: 350,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "flex-end",
    // height: 40,
    height: "auto",
    padding: 3,
    textAlign: "right",
    fontWeight: "bold",
  },
  tableCell_tax: {
    borderBottomWidth: 1,
    width: 350,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 40,
    height: "auto",
    padding: 6,
    textAlign: "right",
    fontWeight: "bold",
  },
  tableCell_Int_tax: {
    borderBottomWidth: 1,
    textAlign: "center",
    width: 120,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    // alignItems: 'center',
    // height: '25',
    height: "auto",
    padding: 6,
  },
  tableCell_Int_tax_subCol: {
    borderBottomWidth: 1,
    textAlign: "center",
    width: 60,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    // alignItems: 'center',
    //  height: '40',
    height: "auto",
    padding: 5,
  },
  tableCell_Total_tax: {
    borderBottomWidth: 1,
    width: 90,
    borderRightWidth: 1,
    // borderLeftWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 40,
    height: "auto",
    padding: 5,
  },
  tableCell_Total_tax_sub: {
    // borderBottomWidth: 1,
    width: 90,
    borderRightWidth: 1,
    // borderLeftWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    // height: 40,
    height: "auto",
    textAlign: "justify",
    padding: 5,
  },
  tableCol_tax: {
    // borderBottomWidth: 1,

    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    //  height: '40',
    height: "auto",
  },
  tableCell_letter: {
    width: "50%",
    padding: 3,
    height: "auto",
    marginLeft: 10,
  },
  tableCell_letter_left: {
    width: "50%",
    padding: 3,
    height: "auto",
    marginLeft: 10,
  },
  tableCell_letter_bottom: {
    width: "50%",
    height: 60,
    textAlign: "right",
    padding: 3,
    borderLeftWidth: 1,
    borderTop: 1,
  },
  tableCol_bottom: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomColor: "gray",
    flexDirection: "row",
    alignItems: "center",
    height: "auto",
  },
});

const InvoicePdfDownload = (props) => {
  const [data, setData] = useState([]);
  const [gstPercent, setGstPercent] = useState("");
  const [sumInWords, setSumInWords] = useState("");
  const [invoicetaxInWords, setInvoicetaxInWords] = useState("");
  const [tax_value, setTaxValue] = useState(0);
  useEffect(() => {
    setData(props.data);
    var tax = 0;
    if (props.data.och_tax === "IN-GST-18") {
      tax = 18;
    } else {
      tax = 8;
    }
    setTaxValue(
      ((Number(props.data.usage) * (1 - Number(props.data.discount) / 100) +
        Number(props.data.platform_cost)) *
        tax) /
        100
    );
    // const data = props.data;
    // const sum =
    //   parseFloat(data.och_billing_cost) + parseFloat(data.och_platform_cost);
    // setSumInWords(numberToWords.toWords(props.data.total_invoice_amount));

    const testamount = parseFloat(props.data.total_invoice_amount); // Convert to a number
    const sumFinaltax = inr(testamount);
    const wordsofsum = sumFinaltax
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    setSumInWords(wordsofsum);

    // // const taxInWords = numberToWords.toWords(data.tax);
    const taxwords =
      ((Number(props.data.usage) * (1 - Number(props.data.discount) / 100) +
        Number(props.data.platform_cost)) *
        tax) /
      100;
    const textword = inr(taxwords);
    const wordsoftax = textword
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    setInvoicetaxInWords(wordsoftax);
    // setInvoicetaxInWords(
    //   numberToWords.toWords(
    //     ((Number(props.data.usage) * (1 - Number(props.data.discount) / 100) +
    //       Number(props.data.platform_cost)) *
    //       tax) /
    //       100
    //   )
    // );
    // setGstPercent(parseInt(data.och_tax.split("-").slice(-1)[0]));
  }, [props]);

  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.sectionTitle}>
            <Text>Tax Invoice </Text>
          </View>
          <View style={styles.section}>
            <Text>IRN:</Text>
            <Text>Ack No:</Text>
            <Text>IRN:</Text>
          </View>
          <View style={styles.section}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell_01}>
                <Image src={logo} style={styles.image} />
              </Text>
              {data.billing_entity == "1CH" && (
                <Text style={styles.tableCell_02}>
                  <Text style={{ fontWeight: "bold" }}>
                    1CloudHub Pte Limited{"\n"}
                  </Text>
                  #02-26,{"\n"}
                  OxleyTower,{"\n"}
                  138 Robinson Road, {"\n"}
                  Singapore-068906{"\n"}
                  {"\n"}
                  {"\n"}
                  {"\n"}
                  {"\n"}
                  {"\n"}
                  {/* Pan No:AAGCB8921N{"\n"}
                  GSTIN/UIN:33AAGCB8921N2ZC{"\n"}
                  State Name:Tamil Nadu,Code:33{"\n"}
                  E-mail:finance.operation@1clodhub.com{"\n"} */}
                </Text>
              )}
              {data.billing_entity == "OCH" && (
                <Text style={styles.tableCell_02}>
                  <Text style={{ fontWeight: "bold" }}>
                    OCH Digitech Private Limited
                  </Text>
                  Plot No 12,A (SP)1st Floor,{"\n"}
                  Kamak Tower,{"\n"}
                  Thiruvika Industrial Estate,{"\n"}
                  Gunidy,Chennai {"\n"}
                  {"\n"}
                  {/* Pan No:AAGCB8921N{"\n"} */}
                  {"\n"}
                  {"\n"}
                  {"\n"}
                  {"\n"}
                  {/* // GSTIN/UIN:33AAGCB8921N2ZC{"\n"}
                 // State Name:Tamil Nadu,Code:33{"\n"}
                 // E-mail:finance.operation@1clodhub.com{"\n"}  */}
                </Text>
              )}
              <View style={[styles.tableCell_03, { flexDirection: "column" }]}>
                <View style={styles.tableCell_03_col}>
                  <Text>Invoice No:{data.invoice_no}</Text>
                </View>
                <View style={styles.tableCell_03_col}>
                  <Text>Delivery Note</Text>
                </View>
                <View style={styles.tableCell_03_col}>
                  <Text>Reference No.& Date:</Text>
                </View>
                <View style={styles.tableCell_03_col}>
                  <Text>Buyer's Order No.</Text>
                </View>
              </View>
              <View style={[styles.tableCell_04, { flexDirection: "column" }]}>
                <View style={styles.tableCell_04_col}>
                  <Text>Dated:{data.month}</Text>
                </View>
                <View style={styles.tableCell_04_col}>
                  <Text>Mode/Terms of Payment</Text>
                </View>
                <View style={styles.tableCell_04_col}>
                  <Text>Other References</Text>
                </View>
                <View style={styles.tableCell_04_col}>
                  <Text>Dated:</Text>
                </View>
              </View>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell_client}>
                Buyer(Bill to){"\n"}
                {data.client}
                {"\n"}
                {data.spp_address_1},{"\n"}
                {data.spp_city},{"\n"}
                {data.spp_state},{"\n"}
                {data.spp_pincode},{"\n"}
              </Text>
              <View style={[styles.tableCell_03, { flexDirection: "column" }]}>
                <View style={styles.tableCell_03_col_3}>
                  <Text>Dispatch Doc No.</Text>
                </View>
                <View style={styles.tableCell_03_col_3}>
                  <Text>Dispatch Through</Text>
                </View>
                <View style={styles.tableCell_03_col_terms}>
                  <Text>Terms of Delivery</Text>
                </View>
              </View>
              <View style={[styles.tableCell_04, { flexDirection: "column" }]}>
                <View style={styles.tableCell_04_col_4}>
                  <Text>Delivery Note Date</Text>
                </View>
                <View style={styles.tableCell_04_col_4}>
                  <Text>Destination</Text>
                </View>
                <View style={styles.tableCell_04_subcol_4}>
                  <Text></Text>
                </View>
                <View style={styles.tableCell_04_subcol_4}>
                  <Text></Text>
                </View>
              </View>
            </View>
            <View style={styles.tableHeader}>
              <Text style={styles.tableCell_2}>Particulars</Text>
              <Text style={styles.tableCell_3}>HSN/SAC</Text>
              <Text style={styles.tableCell_3}>Quantity</Text>
              <Text style={styles.tableCell_3}>Rate</Text>
              <Text style={styles.tableCell_4}>per</Text>
              <Text style={styles.tableCell_5}>Amount</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell_2}>AWS Consumption</Text>
              <Text style={styles.tableCell_3}></Text>
              <Text style={styles.tableCell_3}></Text>
              <Text style={styles.tableCell_3}></Text>
              <Text style={styles.tableCell_4}></Text>
              <Text style={styles.tableCell_5}>
                {Math.round(data.usage).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
            </View>
            {Number(data.discount) !== 0 && (
              <View style={styles.tableCol}>
                <Text style={styles.tableCell_2}>
                  Discount(AWS Consumption)
                </Text>
                <Text style={styles.tableCell_3}></Text>
                <Text style={styles.tableCell_3}></Text>
                <Text style={styles.tableCell_3}>
                  {Math.round(Number(data.discount)).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
                <Text style={styles.tableCell_4}>%</Text>
                <Text style={styles.tableCell_5}>
                  {(
                    (Number(data.usage) * Number(data.discount)) /
                    100
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                </Text>
              </View>
            )}
            {Number(data.platform_cost) !== 0 && (
              <View style={styles.tableCol}>
                <Text style={styles.tableCell_2}>Platform Cost</Text>
                <Text style={styles.tableCell_3}></Text>
                <Text style={styles.tableCell_3}></Text>
                <Text style={styles.tableCell_3}></Text>
                <Text style={styles.tableCell_4}></Text>
                <Text style={styles.tableCell_5}>
                  {Math.round(data.platform_cost).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Text>
              </View>
            )}

            <View style={styles.tableCol}>
              <Text style={styles.tableCell_2}>
                Tax(AWS Consumption with discount + Platform Cost)
              </Text>
              <Text style={styles.tableCell_3}></Text>
              <Text style={styles.tableCell_3}></Text>
              <Text style={styles.tableCell_3}>
                {data.och_tax === "IN-GST-18" ? `18` : `8`}
              </Text>
              <Text style={styles.tableCell_4}>%</Text>
              <Text style={styles.tableCell_5}>
                {Math.round(tax_value).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
            </View>

            <View style={styles.tableCol}>
              <Text style={styles.tableCell_2_total}>Total</Text>
              <Text style={styles.tableCell_3}></Text>
              <Text style={styles.tableCell_3}></Text>
              <Text style={styles.tableCell_3}></Text>
              <Text style={styles.tableCell_4}></Text>
              <Text style={styles.tableCell_5}>
                {data.billing_currency === "INR"
                  ? ` ${Math.round(data.total_invoice_amount).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}`
                  : ` ${Math.round(data.total_invoice_amount).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}`}
                {}
              </Text>
            </View>
            <View style={styles.tableCol}>
              {/* <Text style={styles.tableCell_last}> INR Thirty thousand one hundred seventy-eight and Thirty Five paise Only</Text> */}
              <Text style={styles.tableCell_last}>
                {capitalizeFirst(sumInWords)}
              </Text>
            </View>
            <View style={styles.tableCol_tax}>
              <Text style={styles.tableCell_tax_sub}>Taxable value</Text>
              <Text style={styles.tableCell_Int_tax}>Integrated Tax</Text>
              <Text style={styles.tableCell_Total_tax_sub}>
                Total Tax Amonut
              </Text>
            </View>
            <View style={styles.tableCol_tax}>
              <Text style={styles.tableCell_tax}></Text>
              <Text style={styles.tableCell_Int_tax_subCol}>Rate</Text>
              <Text style={styles.tableCell_Int_tax_subCol}> Amount</Text>
              <Text style={styles.tableCell_Total_tax}></Text>
            </View>

            <View style={styles.tableCol_tax}>
              <Text style={styles.tableCell_tax}>
                {Math.round(
                  Number(data.usage) * (1 - Number(data.discount) / 100) +
                    Number(data.platform_cost)
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
              <Text style={styles.tableCell_Int_tax_subCol}>
                {" "}
                {data.och_tax === "IN-GST-18" ? `18` : `8`}%
              </Text>
              <Text style={styles.tableCell_Int_tax_subCol}>
                {" "}
                {Math.round(tax_value).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
              <Text style={styles.tableCell_Total_tax}>
                {" "}
                {Math.round(tax_value).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
            </View>
            <View style={styles.tableCol_tax}>
              <Text style={styles.tableCell_tax}>
                Total :{" "}
                {Math.round(
                  Number(data.usage) * (1 - Number(data.discount) / 100) +
                    Number(data.platform_cost)
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
              <Text style={styles.tableCell_Int_tax_subCol}>
                {" "}
                {data.och_tax === "IN-GST-18" ? `18` : `8`}%
              </Text>
              <Text style={styles.tableCell_Int_tax_subCol}>
                {" "}
                {Math.round(tax_value).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
              <Text style={styles.tableCell_Total_tax}>
                {" "}
                {Math.round(tax_value).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell_last}>
                {capitalizeFirst(invoicetaxInWords)}
              </Text>
            </View>
            <View style={styles.tableCol_bottom}>
              <Text style={styles.tableCell_letter_left}>
                Company's PAN : AAGCB8921N{" "}
              </Text>
              <Text style={styles.tableCell_letter}>
                Company's Bank Details {"\n"}
                Bank Name : HDFC Bank {"\n"}
                A/c No. : 50200021801161 {"\n"}
                Branch & IFS Code : Valasaravakkam & HDFC0000024
              </Text>
            </View>

            <View style={styles.tableCol}>
              <Text style={styles.tableCell_letter}>
                Declaration{"\n"}
                We declare that this invoice shows the actul price of the goods
                described and that all particulars are true and {"\n"} correct.
              </Text>
              <Text style={styles.tableCell_letter_bottom}>
                for OCH Digitech Private Limited{"\n"}
                {"\n"}
                {"\n"}
                Authorised Signatory
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default memo(InvoicePdfDownload);
