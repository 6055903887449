import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { BsInfoSquareFill } from "react-icons/bs";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { getDailyWiseSpendApiData } from "../../../action/costimizedashboardAction";

import { Modal, Spin } from "antd";

const DayWiseSpend = () => {
  const [dailyWiseSpendDataState, setDailyWiseSpendDataState] = useState([]);
  const [currentModalData, setCurrentModalData] = useState(0); // Add state for the current index
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const dispatch = useDispatch();
  var company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const prevDateRef = useRef(""); // Ref for previous date
  // const getEndMonthDate = () => {
  //   return moment(selectedTopdate).isSame(new Date(), "month")
  //     ? moment().subtract(3, "days").format("YYYY-MM-DD")
  //     : moment(selectedTopdate).endOf("month").format("YYYY-MM-DD");
  // };
  const getEndMonthDate = () => {
    const currentDate = moment();
  const threeDaysBefore = currentDate.subtract(3, "days").format("YYYY-MMM-DD");
  return `  ${
     moment(selectedTopdate).isSame(currentDate, "month")
      ? threeDaysBefore
      : moment(selectedTopdate).endOf("month").format("YYYY-MMM-DD")
  } `;
  };
 
  const dailySpendApi = () => {
    setLoading(true);
    const apiVariables = {
      event_type: "30 days trend",
      screen: "qg",
      company: company_name,
      account_name: selectedAccount.toString(),
      application: selectedApplication.toString(),
      cloud: selectedCloud.toString(),
      date: prevDateRef.current ? prevDateRef.current : getEndMonthDate(),
      db: selecteddb.toString(),
      environment: selectedEnvironment.toString(),
      infra: selectedinfra.toString(),
      os: selectedos.toString(),
      service: selectedservices.toString(),
    };

    dispatch(getDailyWiseSpendApiData(apiVariables))
      .unwrap()
      .then(({ data }) => {
        const jsonString = data.last30daystrendchart_qg;
        // Parse the JSON-encoded string to a JavaScript object
        let resp = JSON.parse(jsonString);
        setDailyWiseSpendDataState(resp);

        setLoading(false);
        prevDateRef.current = "";
      })
      .catch((err) => {
        console.log(err?.message);
        setLoading(false);

        prevDateRef.current = "";
      });
  };
  useEffect(() => {
    dailySpendApi();
  }, [selectedTopdate,selectedAccount,selectedApplication,selectedCloud,selecteddb,selectedEnvironment,selectedos,selectedinfra,selectedservices]);

  const chartOptions = {
    chart: {
      id: "spend-chart",
      type: "bar",
      height: "250px",
      parentheightoffset: 0,
    },
    grid: {
      show: true,

      padding: { left: 5, right: 0, top: 0, bottom: -26 },
    },
    xaxis: {
      categories: dailyWiseSpendDataState.map((item) =>
        moment(item.date).format("MMM DD")
      ), // Convert date to milliseconds

      labels: {
        rotate: -45,
        style: {
          colors: "white", // Set label color to white
          fontSize: "8px",
        },
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: "Spend in $",
        style: {
          fontSize: "13px",
          color: "#3B3B3B",
          fontWeight: "500",
        },
      },
      axisBorder: { show: false },
      labels: {
        style: {
          colors: ["white"], // Set label color to white
          fontSize: "10px", // Adjust font size as needed
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "40%",
        dataLabels: {
          enabled: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: "#ff6a2f",

    annotations: {
      points: dailyWiseSpendDataState
        .filter((item) => item.day_spend > 2450)
        .map((item) => {
          const date = moment(item.date);
          // Check if the date is the 1st of the month
          const isFirstOfMonth = date.date() === 1;
          // Define marker object conditionally
          const marker = isFirstOfMonth
            ? null
            : {
                size: 5,
                fillColor: "red",
                strokeColor: "#333",
                strokeWidth: 3,
                shape: "circle",
                radius: 2,
              };

          return {
            x: date.format("MMM DD"),
            y: item.day_spend,
            click: (event) => {
              setCurrentModalData(event);
              showModal();
            },
            marker: marker,
          };
        })
        .filter((point) => point.marker !== null), // Filter out points with null marker
    },
  };

  const chartSeries = [
    {
      name: "Spend",
      data: dailyWiseSpendDataState.map((item) => item.day_spend),
    },
  ];
  const onPrev = () => {
    prevDateRef.current = dailyWiseSpendDataState[0]?.date;
    dailySpendApi();
  };

  const onNext = () => {
    if (dailyWiseSpendDataState.length > 0) {
      prevDateRef.current = moment(
        dailyWiseSpendDataState[dailyWiseSpendDataState.length - 1]?.date
      )
        .add(1, "months")
        .format("YYYY-MM-DD");
      if (moment(prevDateRef.current).isSame(new Date(), "month")) {
        prevDateRef.current = "";
      }
    } else {
      prevDateRef.current = moment().format("YYYY-MM-DD"); // Set to current date if array is empty
    }
    dailySpendApi();
  };
  return (
    <div>
      <Modal
        title={
          <div className="flex align-items-center ">
            <BsInfoSquareFill size={16} color="#ff6a2f" className="me-1" />{" "}
            Reason for the increase
          </div>
        }
        centered
        className="daywise-spend-modal"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="pt-3">
          {/* <p>Date : {moment(currentModalData.x).format("MMM DDD, YYYY")}</p>
          <p className="py-2"> Spend : ${currentModalData.y}</p> */}

          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
        </div>
      </Modal>

      <Spin spinning={loading} wrapperClassName="costimize-loader">
        <Chart
          className="daywise"
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={250}
        />
        <div className=" position-relative ">
          <div className="arrow-container">
            <button className="grp-prev" onClick={onPrev}>
              <FaChevronLeft />
            </button>
            <button
              className="grp-next"
              onClick={onNext}
              disabled={moment(
                dailyWiseSpendDataState[dailyWiseSpendDataState.length - 1]
                  ?.date
              ).isSame(new Date(), "month")}
            >
              <FaChevronRight />
            </button>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default DayWiseSpend;
