import { Col, Row, Switch, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setQuickGlancePageTop5SpendFilterTab,
  setQuickGlancePageTop5SpendTab,
} from "../../../slice/costimizespendSlice";
import TopFiveIncreaseSpendContainer from "./TopFiveSpendContainer";
import "./budget-spend-tabs-table.css";
const TabsLayoutProdNonProd = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(
    (state) => state.costimizespend.QuickGlancePageTop5SpendTabActiveTab
  );
  const filterActiveTab = useSelector(
    (state) => state.costimizespend.QuickGlancePageTop5SpendFilterActiveTab
  );
  const handleTabChange = (tabKey) => {
    if (tabKey === "tab1") {
      dispatch(
        setQuickGlancePageTop5SpendTab({
          production: true,
          nonProduction: false,
          unTagged: false,
        })
      );
    } else if (tabKey === "tab2") {
      dispatch(
        setQuickGlancePageTop5SpendTab({
          production: false,
          nonProduction: true,
          unTagged: false,
        })
      );
    } else if (tabKey === "tab3") {
      dispatch(
        setQuickGlancePageTop5SpendTab({
          production: false,
          nonProduction: false,
          unTagged: true,
        })
      );
    }
  };

  const handleIncrease = () => {
    if (!filterActiveTab.increaseFilter) {
      dispatch(
        setQuickGlancePageTop5SpendFilterTab({
          increaseFilter: true,
          decreaseFilter: false,
        })
      );
    } else {
      dispatch(
        setQuickGlancePageTop5SpendFilterTab({
          increaseFilter: false,
          decreaseFilter: true,
        })
      );
    }
  };

  return (
    <div className="spendTrendContainerCard">
      <p className="vertical-text">
        {`Top ${
          filterActiveTab.increaseFilter ? "increase" : "decrease"
        } spend`}
      </p>
      <Row gutter={[16, 16]}>
        <Col lg={20}>
          <div className={` tabsH`}>
            <button
              onClick={() => handleTabChange("tab1")}
              className={` ${activeTab.production ? ` active ` : ""}`}
            >
              Production
            </button>

            <button
              className={` ${activeTab.nonProduction ? ` active ` : ""}`}
              onClick={() => handleTabChange("tab2")}
            >
              Non-Production
            </button>
            <button
              className={` ${activeTab.unTagged ? ` active ` : ""}`}
              onClick={() => handleTabChange("tab3")}
            >
              Untagged
            </button>
          </div>
        </Col>
        <Col lg={4}>
          <div className="caret-align  ">
            <div onClick={handleIncrease}>
              <Tooltip
                placement="topLeft"
                overlayClassName="custom-tooltip"
                title={`Showing top
                  ${
                    filterActiveTab.increaseFilter ? "increase" : "decrease"
                  } spend
                `}
              >
                <Switch
                  // checkedChildren={"-"}
                  // unCheckedChildren="+"
                  size="small"
                  defaultChecked
                  className={
                    filterActiveTab.increaseFilter
                      ? "production-switch"
                      : "non-production-switch"
                  }
                />
              </Tooltip>
              {/* {filterActiveTab.increaseFilter ? (
                <MdOutlineArrowUpward color="#d12e4b" />
              ) : (
                <MdOutlineArrowDownward color="green" />
              )} */}
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <div className="tab-content">
            <TopFiveIncreaseSpendContainer />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TabsLayoutProdNonProd;
