import React from 'react'
import ReactApexChart from "react-apexcharts";


const Charts = () => {

    const series = [
        {
          name: 'ABC-PROD',
          data: [44, 55, 57, 56, 61,],
        },
        {
          name: 'BAU',
          data: [76, 85, 101, 98, 87,],
        },
      ];
    
      const options = {
        chart: {
          type: 'bar',
          height: 350,
          
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun',],
        },
        yaxis: {
          title: {
            text: '$ (thousands)',
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: (val) => `$ ${val} thousands`,
          },
        },
      };

  return (
    <div>
      <ReactApexChart options={options} series={series} type="bar" height={350} width={300}/>
    </div>
    
  )
}

export default Charts