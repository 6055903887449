import { memo, useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  message,
  Modal,
  DatePicker,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirst } from "../../../custom_hook/CustomHook";
import {
  editsaving,
  updaterealisedtable,
} from "../../../action/costimizeV2Active";
const EditAction = (props) => {
  const { environmentList, applicationList, serviceList, accountList } =
    useSelector((state) => state.constimize);
  const changeDrawerStatus = () => {
    props.setDrawerStatus(false);
  };
  const dispatch = useDispatch();
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [buttonloading, setButtonLoading] = useState(false);
  const [form] = Form.useForm();
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [rowdata, setRowdata] = useState([]);
  useEffect(() => {
    var records = {
      applications: "",
      environments: "",
      status: "",
      services: "",
      account_names: "",
      resource_sub_category: "",
      recommendation_rule: "",
      resource_id: "",
      resource_name: "",
      current_configuration: "",
      recommended_configuration: "",
      commitment: "",
      potential_savings: "",
      points_discussed: "",
      remarks: "",
      status: "",
    };
    if (props.oldUpdateRecords) {
      records = {
        applications: props.oldUpdateRecords.applications,
        environments: props.oldUpdateRecords.environments,
        status: props.oldUpdateRecords.status,
        services: props.oldUpdateRecords.services,
        account_names: props.oldUpdateRecords.account_names,
        resource_sub_category: props.oldUpdateRecords.resource_sub_category,
        recommendation_rule: props.oldUpdateRecords.recommendation_rule,
        resource_id: props.oldUpdateRecords.resource_id,
        resource_name: props.oldUpdateRecords.resource_name,
        current_configuration: props.oldUpdateRecords.current_configuration,
        recommended_configuration:
          props.oldUpdateRecords.recommended_configuration,
        commitment: props.oldUpdateRecords.commitment,
        potential_savings: props.oldUpdateRecords.potential_savings,
        realised_savings: props.oldUpdateRecords.realised_savings,
      };
      setRowdata(records);
    }
    // form.setFieldsValue(records);
    const defaultValues = {
      username: props.oldUpdateRecords.username,
      email: props.oldUpdateRecords.email_address,
      status: props.oldUpdateRecords.status,
      // targetdate: props.oldUpdateRecords.targetdate,
    };
    form.setFieldsValue(defaultValues);
  }, [props]);
  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const handleSubmit = (value) => {
    const input = {
      account_names: props.oldUpdateRecords.account_names,
      application: props.oldUpdateRecords.applications,
      commitment: props.oldUpdateRecords.commitment,
      current_configuration: props.oldUpdateRecords.current_configuration,
      environment: props.oldUpdateRecords.environments,
      potential_savings: props.oldUpdateRecords.potential_savings,
      recommendation_rule: props.oldUpdateRecords.recommendation_rule,
      recommended_configuration:
        props.oldUpdateRecords.recommended_configuration,
      resource_id: props.oldUpdateRecords.resource_id,
      resource_name: props.oldUpdateRecords.resource_name,
      resource_sub_category: props.oldUpdateRecords.resource_sub_category,
      service: props.oldUpdateRecords.services,
      status: value.status,
      username: value.username,
      targetdate: value.targetdate,
      email: value.email,
      report_month: selectedTopdate,
      company_name: company_name,
      cloud: props.oldUpdateRecords.cloud,
    };
    dispatch(updaterealisedtable({ input }))
      .unwrap()
      .then((data) => {
        const status = data.data.update_savings_plan_recommendation[0].status;

        if (status === "Implementation Completed") {
        }
        message.success("Updated Successfully ");
        props.setDrawerStatus(false);
        setButtonLoading(false);
        props.setRecordsUpdate(!props.recordsupdate);
        if (value.email !== props.oldUpdateRecords.email_address) {
          const target_date = input.targetdate;
          const inputs = {
            account_names: props.oldUpdateRecords.account_names,
            applications: props.oldUpdateRecords.applications,
            commitment: props.oldUpdateRecords.commitment,
            current_configuration: props.oldUpdateRecords.current_configuration,
            environments: props.oldUpdateRecords.environments,
            potential_savings: props.oldUpdateRecords.potential_savings,
            recommendation_rule: props.oldUpdateRecords.recommendation_rule,
            recommended_configuration:
              props.oldUpdateRecords.recommended_configuration,
            resource_id: props.oldUpdateRecords.resource_id,
            resource_name: props.oldUpdateRecords.resource_name,
            resource_sub_category: props.oldUpdateRecords.resource_sub_category,
            services: props.oldUpdateRecords.services,
            status: value.status,
            username: value.username,
            targetdate: value.targetdate,
            email: value.email,
            report_month: selectedTopdate,
            company_name: company_name,
            cloud: props.oldUpdateRecords.cloud,
          };
          const values = value;
          const companyName = company_name;
          dispatch(editsaving({ inputs, values, companyName, target_date }))
            .unwrap()
            .then((editSavingResult) => {
              message.success("Mail sent Successfully");
              props.setDrawerStatus(false);
              setButtonLoading(false);
              props.setRecordsUpdate(!props.recordsupdate);
            });
        }
      })
      .catch((err) => {
        console.log(err.message);
        message.error(err);
        setButtonLoading(false);
      });
  };

  return (
    <>
      <Modal
        className="costmodal"
        open={props.drawerstatus}
        onCancel={changeDrawerStatus}
        drawerdeletestatus={drawerdeletestatus}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        confirmLoading={confirmLoading}
        setConfirmLoading={setConfirmLoading}
        // rowDelete={rowDelete}
        // deletestatus={deletestatus}
        title="Savings Model"
        footer={null}
      >
        {/* <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="applications"
                label="Application"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  disabled={true}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={allapplication}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="environments"
                label="Environment"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  disabled={true}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={allenvironment}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="status"
                label="Status"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "Analysis Pending",
                      label: "Analysis Pending",
                    },
                    {
                      value: "Analysis Completed",
                      label: "Analysis Completed",
                    },
                    {
                      value: "Implementation Planned",
                      label: "Implementation Planned",
                    },
                    {
                      value: "Implementation Completed",
                      label: "Implementation Completed",
                    },
                    {
                      value: "Not Implemented",
                      label: "Not Implemented",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="services"
                label="Service"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  disabled={true}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={allservice}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="account_names"
                label="Account"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  disabled={true}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={allaccount}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="resource_sub_category"
                label="Resource Sub Category"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  disabled={true}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "Instance",
                      label: "Instance",
                    },
                    {
                      value: "EBS",
                      label: "EBS",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="recommendation_rule"
                label="Recommendation Type"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  disabled={true}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "Right Sizing",
                      label: "Right Sizing",
                    },
                    {
                      value: "OS Licensing",
                      label: "OS Licensing",
                    },
                    {
                      value: "DB Licensing",
                      label: "DB Licensing",
                    },
                    {
                      value: "Storage Tiering",
                      label: "Storage Tiering",
                    },
                    {
                      value: "Stop/Start",
                      label: "Stop/Start",
                    },
                    {
                      value: "Savings Plan",
                      label: "Savings Plan",
                    },
                    {
                      value: "Instance Tiering",
                      label: "Instance Tiering",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="resource_id"
                label="Resource ID"
                rules={[{ required: true, message: "" }]}
              >
                <Input
                  className="tracking-input"
                  disabled={true}
                  //   disabled={deletestatus ? true : false}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="resource_name"
                label="Resource Name"
                rules={[{ required: true, message: "" }]}
              >
                <Input className="tracking-input" disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="current_configuration"
                label="Current Configuration"
                rules={[{ required: true, message: "" }]}
              >
                <Input className="tracking-input" disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="recommended_configuration"
                label="Recommended Configuration"
                rules={[{ required: true, message: "" }]}
              >
                <Input className="tracking-input" disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="commitment"
                label="Commitment"
                rules={[{ required: true, message: "" }]}
              >
                <Input className="tracking-input" disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                name="potential_savings"
                label="Potential Savings"
                rules={[{ required: true, message: "" }]}
              >
                <Input
                  prefix="$"
                  style={{
                    width: "100%",
                  }}
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
          {deletestatus && (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  name="realised_savings"
                  label="Realized Savings"
                  // rules={[{ required: true, message: '' }]}
                >
                  <Input
                    prefix="$"
                    style={{
                      width: "100%",
                    }}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => props.setDrawerStatus(false)}
                  className="cancel-btn"
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn"
                  loading={buttonloading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form> */}
        <Form
          layout="horizontal"
          onFinish={handleSubmit}
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          form={form}
          className="mt-4"
          requiredMark={false}
        >
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: "Please Enter Username" }]}
          >
            <Input
              className="antd-input-theme "
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: "Please Enter Email" }]}
          >
            <Input
              className="antd-input-theme"
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="status"
                label="Status"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "Analysis Pending",
                      label: "Analysis Pending",
                    },
                    {
                      value: "Analysis Completed",
                      label: "Analysis Completed",
                    },
                    {
                      value: "Implementation Planned",
                      label: "Implementation Planned",
                    },
                    {
                      value: "Implementation Completed",
                      label: "Implementation Completed",
                    },
                    {
                      value: "Not Implemented",
                      label: "Not Implemented",
                    },
                    // {
                    //   value: "Deferred",
                    //   label: "Deferred",
                    // },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={13}>
              <Form.Item
                name="targetdate"
                label="Target Date"
                rules={[
                  { required: true, message: "Please Enter Target date" },
                ]}
                style={{
                  width: "100%",
                }}
                labelCol={{ span: 9 }}
                wrapperCol={{ span: 15 }}
              >
                <DatePicker
                  className="assign-defer-form-datepicker"
                  onChange={(date, dateString) => setStartDate(date)}
                />
              </Form.Item>
            </Col>
          </Row>

          <div className="d-flex justify-content-around mb-3">
            <Button
              htmlType="button"
              onClick={() => props.setDrawerStatus(false)}
              className="savops-cancel-btn"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="savops-submit-btn"
              loading={buttonloading}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default memo(EditAction);
