import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Table, Form, Input, Modal, Dropdown } from "antd";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import {
  assessmenttable,
  assessmenttableupdate,
  assessmenttablelist,
  assessmenttabledelete,
  assessmenttableupload,
} from "../action/graphql/a2crover/A2CReducer";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);

  const inputRef = useRef(null);

  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);

    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();

      handleSave({
        ...record,

        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,

            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

function AssetsPage() {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedFile, setSelectedFile] = useState();
  const [fileuploadnew, setFileUploadNew] = useState();

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // const handleSubmission = () => {
  //     const formData = new FormData();
  //     formData.append('File', selectedFile);
  //       const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const base64Data = reader.result.split(',')[1];
  //       console.log('Base64 Data:', base64Data);
  //       setFileUploadNew(base64Data);
  //       console.log(fileuploadnew);
  //     };
  //     reader.readAsDataURL(selectedFile);
  //       dispatch(assessmenttableupload(fileuploadnew))
  //       .unwrap()
  //       .then(({data}) => {
  //         console.log('File uploaded successfully',data);
  //       })
  //       .catch ((error) => {
  //       console.error('Error uploading file:', error);
  //     })
  //   }

  //   const handleDelete = (key) => {
  //     const newData = dataSource.filter((item) => item.key !== key);

  //     setDataSource(newData);
  //   };

  const handleSubmission = () => {
    const formData = new FormData();
    formData.append("File", selectedFile);

    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Data = reader.result.split(",")[1];
      setFileUploadNew(base64Data);
    };
    const s3ObjectInput = {
      bucket: "your-bucket-name",
      content: fileuploadnew,
      contentType: "image/png",
      key: "your-file-key",
      region: "your-region",
    };

    reader.readAsDataURL(selectedFile);
    dispatch(assessmenttableupload({ s3ObjectInput }))
      .unwrap()
      .then(({ data }) => {
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  const defaultColumns = [
    {
      title: "Data Center Name",
      dataIndex: "data_center_name",
      editable: true,
      key: "data_center_name",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e, record.key, "data_center_name")}
        />
      ),
    },
    {
      title: "Environment",
      dataIndex: "environment",
      editable: true,
      key: "environment",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e, record.key, "environment")}
        />
      ),
    },

    {
      title: "Application",
      dataIndex: "application",
      editable: true,
      key: "application",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e, record.key, "application")}
        />
      ),
    },
    {
      title: "Server Role",
      dataIndex: "server_role",
      editable: true,
      key: "server_role",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e, record.key, "server_role")}
        />
      ),
    },
    {
      title: "Resource Name",
      dataIndex: "resource_name",
      editable: true,
      key: "resource_name",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e, record.key, "resource_name")}
        />
      ),
    },
    {
      title: "Operating System",
      dataIndex: "operating_system",
      editable: true,
      key: "operating_system",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e, record.key, "operating_system")}
        />
      ),
    },
    {
      title: "Database Type",
      dataIndex: "database_type",
      editable: true,
      key: "database_type",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e, record.key, "database_type")}
        />
      ),
    },
    {
      title: "Region",
      dataIndex: "region",
      editable: true,
      key: "region",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e, record.key, "region")}
        />
      ),
    },
    {
      title: "Modernization Type",
      dataIndex: "modernization_type",
      editable: true,
      key: "modernization_type",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) =>
            handleInputChange(e, record.key, "modernization_type")
          }
        />
      ),
    },
    {
      title: "Migration Complexity",
      dataIndex: "migration_complexity",
      editable: true,
      key: "migration_complexity",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) =>
            handleInputChange(e, record.key, "migration_complexity")
          }
        />
      ),
    },
    {
      title: "CPU as is",
      dataIndex: "cpu_as_is",
      editable: true,
      key: "cpu_as_is",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e, record.key, "cpu_as_is")}
        />
      ),
    },
    {
      title: "Memory as is",
      dataIndex: "memory_as_is",
      editable: true,
      key: "memory_as_is",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e, record.key, "memory_as_is")}
        />
      ),
    },
    {
      title: "Storage as is",
      dataIndex: "storage_as_is",
      editable: true,
      key: "storage_as_is",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) => handleInputChange(e, record.key, "storage_as_is")}
        />
      ),
    },
    {
      title: "Dr Implementation",
      dataIndex: "dr_implementation",

      editable: true,
      key: "dr_implementation",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) =>
            handleInputChange(e, record.key, "dr_implementation")
          }
        />
      ),
    },
    {
      title: "Migration Methodology",
      dataIndex: "migration_methodology",
      editable: true,
      key: "migration_methodology",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) =>
            handleInputChange(e, record.key, "migration_methodology")
          }
        />
      ),
    },
    {
      title: "Monitoring Stratergy",
      dataIndex: "monitoring_stratergy",
      editable: true,
      key: "monitoring_stratergy",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) =>
            handleInputChange(e, record.key, "monitoring_stratergy")
          }
        />
      ),
    },
    {
      title: " ",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => (
        <>
          <div onClick={() => getTableValues(record, index)}>
            <Icon
              icon="fluent:save-multiple-20-regular"
              style={{ fontSize: "150%", cursor: "pointer" }}
            />
          </div>
        </>
      ),
    },
    {
      title: " ",
      dataIndex: "action",
      key: "action",
      render: (text, record, index, id) => (
        <>
          <div onClick={() => getTableValuesUpdate(record, index, id)}>
            <Icon
              icon="material-symbols:security-update-good"
              style={{ fontSize: "150%", cursor: "pointer" }}
            />
          </div>
        </>
      ),
    },
    {
      title: " ",
      dataIndex: "action",
      key: "action",
      render: (text, record, index, id) => (
        <>
          <div onClick={() => getTableValuesDelete(record, index, id)}>
            <Icon
              icon="openmoji:delete"
              style={{ fontSize: "150%", cursor: "pointer" }}
            />
          </div>
        </>
      ),
    },
  ];
  const handleInputChange = (e, key, dataIndex) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    newData[index][dataIndex] = e.target.value;
    setDataSource(newData);
  };

  const getTableValues = (record, index) => {
    const input = dataSource[0];
    dispatch(assessmenttable(input))
      .unwrap()
      .then(({ data }) => {
      })
      .catch((err) => {
        console.error("Error in assessmenttable:", err);
      });
    const updatedTableData = [...dataSource];
    updatedTableData[index] = { ...record };
    setDataSource(updatedTableData);
  };

  var companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const getTableValuesUpdate = (record, index) => {

    const id = record.id;

    if (id) {
      const input = {
        id: id,
        application: record.application || "",
        cpu_as_is: record.cpu_as_is || "",
        customer: record.customer || "",
        data_center_name: record.data_center_name || "",
        database_type: record.database_type || "",
        dr_implementation: record.dr_implementation || "",
        environment: record.environment || "",
        memory_as_is: record.memory_as_is || "",
        migration_complexity: record.migration_complexity || "",
        migration_methodology: record.migration_methodology || "",
        modernization_type: record.modernization_type || "",
        monitoring_stratergy: record.monitoring_stratergy || "",
        operating_system: record.operating_system || "",
        region: record.region || "",
        resource_name: record.resource_name || "",
        server_role: record.server_role || "",
        storage_as_is: record.storage_as_is || "",
      };
      dispatch(assessmenttableupdate(input))
        .unwrap()
        .then(({ data }) => {
        })
        .catch((err) => {
          console.error("Error in assessmenttableupdate:", err);
        });
      const updatedTableDataUpdate = [...dataSource];
      updatedTableDataUpdate[index] = { ...record };
      setDataSource(updatedTableDataUpdate);
    }
  };

  const getTableValuesDelete = (record, index) => {

    const id = record.id;

    if (id) {
      const input = {
        id: id,
        application: record.application || "",
        cpu_as_is: record.cpu_as_is || "",
        customer: record.customer || "",
        data_center_name: record.data_center_name || "",
        database_type: record.database_type || "",
        dr_implementation: record.dr_implementation || "",
        environment: record.environment || "",
        memory_as_is: record.memory_as_is || "",
        migration_complexity: record.migration_complexity || "",
        migration_methodology: record.migration_methodology || "",
        modernization_type: record.modernization_type || "",
        monitoring_stratergy: record.monitoring_stratergy || "",
        operating_system: record.operating_system || "",
        region: record.region || "",
        resource_name: record.resource_name || "",
        server_role: record.server_role || "",
        storage_as_is: record.storage_as_is || "",
      };
      dispatch(assessmenttabledelete(input))
        .unwrap()
        .then(({ data }) => {
        })
        .catch((err) => {
          console.error("Error in assessmenttabledelete:", err);
        });
      const updatedTableDataDelete = [...dataSource];
      updatedTableDataDelete[index] = { ...record };
      setDataSource(updatedTableDataDelete);
    }
  };

  const handleAdd = () => {
    const newData = {
      data_center_name: `Enter Data`,
      environment: `Enter Data`,
      application: `Enter Data`,
      server_role: `Enter Data`,
      resource_name: `Enter Data`,
      operating_system: `Enter Data`,
      database_type: `Enter Data`,
      region: `Enter Data`,
      modernization_type: `Enter Data`,
      migration_complexity: `Enter Data`,
      cpu_as_is: `Enter Data`,
      memory_as_is: `Enter Data`,
      storage_as_is: `Enter Data`,
      dr_implementation: `Enter Data`,
      memory_as_is: `Enter Data`,
      storage_as_is: `Enter Data`,
      dr_implementation: `Enter Data`,
      migration_methodology: `Enter Data`,
      monitoring_stratergy: `Enter Data`,
    };

    setDataSource([...dataSource, newData]);

    setCount(count + 1);
  };

  const handleSave = (row) => {
    const newData = [...dataSource];

    const index = newData.findIndex((item) => row.key === item.key);

    const item = newData[index];

    newData.splice(index, 1, {
      ...item,

      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,

      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,

      onCell: (record) => ({
        record,

        editable: col.editable,

        dataIndex: col.dataIndex,

        title: col.title,

        handleSave,
      }),
    };
  });

  const navigate = useNavigate();
  function HandleOpen() {
    navigate("/a2c-rover");
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const csvData = dataSource.map((item) => ({
    application: item.application,
    cpu_as_is: item.cpu_as_is,
    customer: item.customer,
    data_center_name: item.data_center_name,
    database_type: item.database_type,
    dr_implementation: item.dr_implementation,
    environment: item.environment,
    id: item.id,
    memory_as_is: item.memory_as_is,
    migration_complexity: item.migration_complexity,
    migration_methodology: item.migration_methodology,
    modernization_type: item.modernization_type,
    monitoring_stratergy: item.monitoring_stratergy,
    operating_system: item.operating_system,
    region: item.region,
    resource_name: item.resource_name,
    server_role: item.server_role,
    storage_as_is: item.storage_as_is,
  }));

  const csvTemplate = dataSource.map((item) => ({
    application: "",
    cpu_as_is: "",
    customer: "",
    data_center_name: "",
    database_type: "",
    dr_implementation: "",
    environment: "",
    id: "",
    memory_as_is: "",
    migration_complexity: "",
    migration_methodology: "",
    modernization_type: "",
    monitoring_stratergy: "",
    operating_system: "",
    region: "",
    resource_name: "",
    server_role: "",
    storage_as_is: "",
  }));
  const items = [
    {
      label: (
        <CSVLink filename="my-file.csv" data={csvTemplate}>
          Template
        </CSVLink>
      ),
      key: "0",
    },
    {
      label: (
        <CSVLink filename="my-file.csv" data={csvData}>
          Data
        </CSVLink>
      ),
      key: "1",
    },
  ];

  useEffect(() => {
    const inputlist = {
      company: companyName,
    };

    dispatch(assessmenttablelist(inputlist))
      .unwrap()
      .then(({ data }) => {
        const Tableaccess = data.assess_get_data.map((datas) => {
          return {
            application: datas.application,
            cpu_as_is: datas.cpu_as_is,
            customer: datas.customer,
            data_center_name: datas.data_center_name,
            database_type: datas.database_type,
            dr_implementation: datas.dr_implementation,
            environment: datas.environment,
            id: datas.id,
            memory_as_is: datas.memory_as_is,
            migration_complexity: datas.migration_complexity,
            migration_methodology: datas.migration_methodology,
            modernization_type: datas.modernization_type,
            monitoring_stratergy: datas.monitoring_stratergy,
            operating_system: datas.operating_system,
            region: datas.region,
            resource_name: datas.resource_name,
            server_role: datas.server_role,
            storage_as_is: datas.storage_as_is,
          };
        });
        setDataSource(Tableaccess);
      })
      .catch((err) => {
        console.error("Error in assessmenttablelist:", err);
      });
  }, []);

  return (
    <>
      <div className="AssetsPage">
        <div className="cams-content">
          <div className="container-fluid">
            <div className="header-filter d-flex justify-content-between">
              <div className="header-title-a2c-main">Assess</div>
            </div>
          </div>
        </div>

        <h3 className="header-title-a2c-main1"> Assessment Table</h3>

        <div className="tabss assets-perspective-tabs">
          <div className="assess-cred-table">
            <Button
              style={{
                marginBottom: 16,
              }}
              className="yes-button"
              onClick={HandleOpen}
            >
              <Icon
                icon="ph:arrow-left"
                style={{
                  fontSize: "23px",

                  cursor: "pointer",

                  border: "transparent",
                }}
              ></Icon>
              Back
            </Button>

            <Button
              onClick={handleAdd}
              style={{
                marginBottom: 16,
              }}
              className="yes-button"
            >
              <Icon
                icon="ic:round-plus"
                style={{
                  fontSize: "23px",

                  cursor: "pointer",

                  border: "transparent",
                }}
              ></Icon>
              Add
            </Button>

            <Button
              onClick={showModal}
              style={{
                marginBottom: 16,
              }}
              className="yes-button"
            >
              <Icon
                icon="ph:arrow-up"
                style={{
                  fontSize: "23px",

                  cursor: "pointer",

                  border: "transparent",
                }}
              />
              Upload
            </Button>
            <Dropdown
              menu={{
                items,
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Button
                  style={{
                    marginBottom: 16,
                  }}
                  className="yes-button"
                >
                  <Icon
                    icon="ph:arrow-down"
                    style={{
                      fontSize: "23px",

                      cursor: "pointer",

                      border: "transparent",
                    }}
                  />
                  Download
                </Button>
              </a>
            </Dropdown>
            <div className="">
              <Table
                className="slm-table"
                components={components}
                rowClassName={() => "editable-row"}
                dataSource={dataSource}
                columns={columns}
                pagination={{ pageSize: 10 }}
                scroll={{ x: 2000 }}
                bordered={false}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="costmodalnew"
        open={isModalOpen}
        footer={false}
        onCancel={handleCancel}
      >
        <div className="fileupload">
          <input type="file" name="file" onChange={changeHandler} />
          <div className="uploadbutton">
            <Button className="yes-button" onClick={handleSubmission}>
              <Icon
                icon="ph:arrow-up"
                style={{
                  fontSize: "23px",

                  cursor: "pointer",

                  border: "transparent",
                }}
              />
              Upload
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AssetsPage;
