import React, { memo, useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Tooltip } from "antd";
import budgeticon from "../../../assets/icons/budget_sidebar_icon.svg";
import budgeticon_white from "../../../assets/icons/budget_sidebar_icon_white.svg";
import { checkRolePermission } from "../../custom_hook/CustomHook";
import Reportdata from "../../../components/costimize_v2/WeeklyReport/Reportdata";
import { useDispatch, useSelector } from "react-redux";
import {
  environmentListMethod,
  cloudListMethod,
  accountListMethod,
  applicationListMethod,
  selectedEnvironmentMethod,
  selectedTopDateMethod,
  selectedAccountMethod,
  selectedCloudMethod,
  selectedApplicationMethod,
  selectedServicesMethod,
  selectedOSMethod,
  selectedInfraMethod,
  selectedDbMethod,
  setServiceList,
  setInfraRList,
  setOsList,
  setDbList,
  setFilterButtonStatus,
} from "../../slice/costimizeSlice";
const CostimizeSidebar = () => {
  const dispatch = useDispatch();
  const { toggleSidebar } = useProSidebar();
  const [sidebarmenu, setSidebarMenu] = useState(1);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const [permission, setPermission] = useState({
    quickglance: checkRolePermission("quick_glance")
      ? checkRolePermission("quick_glance").access
      : 0,
    assetspend: checkRolePermission("asset_spend")
      ? checkRolePermission("asset_spend").access
      : 0,
    savingsmodel: checkRolePermission("savings_model")
      ? checkRolePermission("savings_model").access
      : 0,
    budgetoverview: checkRolePermission("budget_planning")
      ? checkRolePermission("budget_planning").access
      : 0,
    instance: checkRolePermission("instance_runtime")
      ? checkRolePermission("instance_runtime").access
      : 0,
    report: checkRolePermission("report")
      ? checkRolePermission("report").access
      : 0,
  });

  useEffect(() => {
    if (window.location.pathname === "/costdemaze/savings_model") {
      setSidebarMenu(3);

    } else if (window.location.pathname === "/costdemaze/assent_spend") {
      setSidebarMenu(2);
      dispatch(selectedAccountMethod(["all"]));
      dispatch(selectedCloudMethod(["all"]));
      dispatch(selectedEnvironmentMethod(["all"]));
      dispatch(selectedApplicationMethod(["all"]));
      dispatch(selectedServicesMethod(["all"]));
      dispatch(selectedDbMethod(["all"])); 
      dispatch(selectedOSMethod(["all"])); 
      dispatch(selectedInfraMethod(["all"])); 
      dispatch(selectedTopDateMethod(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`));
    } else if (window.location.pathname === "/costdemaze/instance_overview") {
      setSidebarMenu(5);
    } else if (window.location.pathname === "/costdemaze/budget_planning") {
      setSidebarMenu(4);
    } else if (window.location.pathname === "/costdemaze/report") {
      setSidebarMenu(6);
    } else {
      setSidebarMenu(1);
      dispatch(selectedAccountMethod(["all"]));
      dispatch(selectedCloudMethod(["all"]));
      dispatch(selectedEnvironmentMethod(["all"]));
      dispatch(selectedApplicationMethod(["all"]));
      dispatch(selectedServicesMethod(["all"]));
      dispatch(selectedDbMethod(["all"])); 
      dispatch(selectedOSMethod(["all"])); 
      dispatch(selectedInfraMethod(["all"])); 
      dispatch(selectedTopDateMethod(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`));
    }
  }, [window.location.pathname]);
  const [reportmonth, setReportmonth] = useState([]);
  const [reportweek, setReportweek] = useState([]);
  const [reportweekwise, setReportweekwise] = useState([]);
  const [reportweekwisesum, setReportweekwisesum] = useState([]);
  const [reportweekwiseappsum, setReportweekwiseappsum] = useState([]);
  const [reportweekwiseappsuminc, setReportweekwiseappsuminc] = useState([]);
  const [reportweekwiseappsumdec, setReportweekwiseappsumdec] = useState([]);
  const [reportweekwiseappsumservice, setReportweekwiseappsumservice] =
    useState([]);
  return (
    <>
      {window.innerWidth > 780 ? (
        <>
          <Sidebar
            // defaultCollapsed
            // collapsedWidth="64px"
            className="costimize-sidebar"
            // width="64px"
            rootStyles={{
              width: "64px",
              minWidth: "64px",
            }}
          >
            <Menu>
              {permission.quickglance ? (
                <Tooltip placement="right" title="Quick Glance">
                  <MenuItem
                    className={sidebarmenu === 1 && "active"}
                    component={<Link to="/costdemaze/quick_glance" />}
                  >
                    <Icon icon="uis:chart" className="side-icon-hover" />
                  </MenuItem>
                </Tooltip>
              ) : (
                ""
              )}
              {permission.assetspend ? (
                <Tooltip placement="right" title="Asset/Spend">
                  <MenuItem
                    className={sidebarmenu === 2 && "active"}
                    component={<Link to="/costdemaze/assent_spend" />}
                  >
                    <Icon
                      icon="fluent:cart-20-filled"
                      className="side-icon-hover"
                    />
                  </MenuItem>
                </Tooltip>
              ) : (
                ""
              )}
              {/* {permission.savingsmodel ? (
                <Tooltip placement="right" title="Savings Opportunity">
                  <MenuItem
                    className={sidebarmenu === 3 && "active"}
                    component={<Link to="/costdemaze/savings_model" />}
                  >
                    <Icon
                      icon="fluent:savings-24-filled"
                      className="side-icon-hover"
                    />
                  </MenuItem>
                </Tooltip>
              ) : (
                ""
              )} */}
              {permission.budgetoverview ? (
                <Tooltip placement="right" title="Budget Planning">
                  <MenuItem
                    className={sidebarmenu === 4 && "active"}
                    component={<Link to="/costdemaze/budget_planning" />}
                  >
                    {/* <img
                      src={sidebarmenu === 4 ? budgeticon : budgeticon_white}
                      className="sidebar-budget-without-white"
                    /> */}
                    <Icon
                      icon="ant-design:message-filled"
                      className="side-icon-hover"
                    />

                    {/* <img
                  src={budgeticon_white}
                  className="sidebar-budget-with-white"
                  style={{ display: "none" }}
                /> */}
                  </MenuItem>
                </Tooltip>
              ) : (
                ""
              )}
              {permission.budgetoverview ? (
                <Tooltip placement="right" title="Report">
                  <MenuItem
                    className={sidebarmenu === 6 && "active"}
                    component={<Link to="/costdemaze/report" />}
                  >
                    <Icon icon="mdi:report-line" className="side-icon-hover" />
                  </MenuItem>
                </Tooltip>
              ) : (
                ""
              )}
              {/* {companyName !== "gmmco" ? ( */}
              <>
                {permission.instance ? (
                  <Tooltip placement="right" title="Instance Overview">
                    <MenuItem
                      className={sidebarmenu === 5 && "active"}
                      component={<Link to="/costdemaze/instance_overview" />}
                    >
                      <Icon
                        icon="ic:outline-access-time-filled"
                        className="side-icon-hover"
                      />
                    </MenuItem>
                  </Tooltip>
                ) : (
                  ""
                )}
              </>
              {/* ) : (
                <></>
              )} */}
            </Menu>
          </Sidebar>
        </>
      ) : (
        <>
          <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
            <Sidebar
              // breakPoint="always"
              // collapsedWidth="64px"
              className="costimize-sidebar mobile-costimize-sidebar"
              // width="64px"
            >
              <Menu>
                {permission.quickglance ? (
                  <Tooltip placement="right" title="Quick Glance">
                    <MenuItem
                      className={sidebarmenu === 1 && "active"}
                      component={<Link to="/costdemaze/quick_glance" />}
                    >
                      <Icon
                        icon="carbon:summary-kpi"
                        className="side-icon-hover"
                      />
                    </MenuItem>
                  </Tooltip>
                ) : (
                  ""
                )}

                {permission.assetspend ? (
                  <Tooltip placement="right" title="Asset/Spend">
                    <MenuItem
                      className={sidebarmenu === 2 && "active"}
                      component={<Link to="/costdemaze/assent_spend" />}
                    >
                      <Icon
                        icon="fluent-mdl2:fixed-asset-management"
                        className="side-icon-hover"
                      />
                    </MenuItem>
                  </Tooltip>
                ) : (
                  ""
                )}
                {permission.savingsmodel ? (
                  <Tooltip placement="right" title="Savings Opportunity">
                    <MenuItem
                      className={sidebarmenu === 3 && "active"}
                      component={<Link to="/costdemaze/savings_model" />}
                    >
                      <Icon icon="fluent:savings-24-filled" />
                    </MenuItem>
                  </Tooltip>
                ) : (
                  ""
                )}
                {permission.budgetoverview ? (
                  <Tooltip placement="right" title="Budget Planning">
                    <MenuItem
                      className={sidebarmenu === 4 && "active"}
                      component={<Link to="/costdemaze/budget_planning" />}
                    >
                      <img
                        src={sidebarmenu === 4 ? budgeticon : budgeticon_white}
                        className="sidebar-budget-without-white"
                      />
                      {/* <img
                  src={budgeticon_white}
                  className="sidebar-budget-with-white"
                  style={{ display: "none" }}
                /> */}
                    </MenuItem>
                  </Tooltip>
                ) : (
                  ""
                )}
                {companyName !== "gmmco" ? (
                  <>
                    {permission.instance ? (
                      <Tooltip placement="right" title="Instance Overview">
                        <MenuItem
                          className={sidebarmenu === 5 && "active"}
                          component={
                            <Link to="/costdemaze/instance_overview" />
                          }
                        >
                          <Icon
                            icon="ic:outline-access-time-filled"
                            className="side-icon-hover"
                          />
                        </MenuItem>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Menu>
            </Sidebar>
            <main className="sidebar-mobile-main">
              <div>
                <Icon
                  className="sidemenu-toggle"
                  icon="ic:round-menu"
                  onClick={() => toggleSidebar()}
                />
              </div>
            </main>
          </div>
        </>
      )}
    </>
  );
};

export default memo(CostimizeSidebar);
