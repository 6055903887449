import React from "react";
import SLMSidebar from "./SLMSidebar";
import MainHead from "../costimize_v2/layout/MainHead";
import { Card, Col, Collapse, Row } from "antd";
import { Icon } from "@iconify/react";
import ReactApexChart from "react-apexcharts";
import AvailabiltyChart from "./AvailabilityChart";
import AssetTrend from "./AssetTrend";
import SpendtrendChart from "./SpendtrendChart";
import { useSelector, useDispatch } from "react-redux";
import {
  slmquickglanceincidentcount,
  slmquickglancecount,
  assetsmanagecount,
  listCompliance,
} from "../action/graphql/slm/SLM_reducers";
import { useEffect, useState } from "react";

function SLM_Quickglance() {
  const dispatch = useDispatch();
  const [incidentDate, setincidentDate] = useState([]);
  const [incidentCount, setincidentCount] = useState("");
  const [tablerecords, setTableRecords] = useState([]);
  const [p1Count, setp1Count] = useState("");
  const [assetCount, setassetCount] = useState("");
  const [percentageP1Completed, setPercentageP1Completed] = useState(0);
  const [percentageP2Completed, setPercentageP2Completed] = useState(0);
  const [percentageP3Completed, setPercentageP3Completed] = useState(0);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const {
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month - 1, 1).toISOString().slice(0, 7) + "-01";
  }

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const monthsInYear = Array.from({ length: 12 }, (_, i) => i + 1);

  const monthString = monthsInYear
    .map((month) => getFirstDayOfMonth(currentYear, month))
    .join(",");
  const monthLabels = monthsInYear.map((month) => {
    const date = new Date(currentYear, month - 1, 1);
    const monthName = date.toLocaleString("default", { month: "short" });
    return monthName;
  });
  useEffect(() => {
    const month = monthString;
    dispatch(slmquickglanceincidentcount({ companyName, month }))
      .unwrap()
      .then(({ data }) => {
        const records = data.slm_quick_glance_incident_count.map((datas) => {
          return {
            no_of_incidents: datas.no_of_incidents,
            month: datas.month,
          };
        });
        setincidentDate(records);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [companyName, selectedTopdate]);
  useEffect(() => {
    const month = selectedTopdate;
    dispatch(slmquickglancecount({ companyName, month }))
      .unwrap()
      .then(({ data }) => {
        const records = data.slm_quick_glance_count;
        const [firstRecord] = records;

        if (firstRecord) {
          const noOfIncidentsValue = firstRecord.no_of_incidents;
          const noOfp1 = firstRecord.no_of_p1;
          setincidentCount(noOfIncidentsValue);
          setp1Count(noOfp1);
        } else {
          console.log("No data available.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [companyName, selectedTopdate]);
  useEffect(() => {
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account_name: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      service: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      month: selectedTopdate.toString(),
    };
    dispatch(assetsmanagecount({ companyName, apivalue }))
      .unwrap()
      .then(({ data }) => {
        const records = data.slm_asset_managed_count;
        const [assetRecord] = records;

        if (assetRecord) {
          const noOfassetValue = assetRecord.no_of_assets_monitored;

          setassetCount(noOfassetValue);
        } else {
          console.log("No data available.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
    selectedTopdate,
    companyName,
  ]);

  useEffect(() => {
    const company = companyName;
    const report_month = selectedTopdate;
    dispatch(listCompliance({ company, report_month }))
      .unwrap()
      .then(({ data }) => {
        const records = data.slm_compliance_ticket.map((datas) => {
          return {
            application: datas.application,
            environment: datas.environment,
            month: datas.month,
            request_category: datas.request_category,
            ticket_status: datas.ticket_status,
            cr_type: datas.cr_type,
            priority_type: datas.priority_type,
            operating_system: datas.operating_system,
            service_request_count: datas.service_request_count,
            change_request_count: datas.change_request_count,
            incident_count: datas.incident_count,
            compliants: datas.compliants,
            non_complaints: datas.non_complaints,
            type: "data",
          };
        });
        setTableRecords(records);
      })
      .catch((err) => {
        console.log(err.message);
      });
    setTableRecords([]);
  }, [selectedTopdate]);
  const priorityData = {};

  tablerecords.forEach((record) => {
    const { priority_type, ticket_status } = record;

    if (!priorityData[priority_type]) {
      priorityData[priority_type] = {
        total: 0,
        completed: 0,
        failed: 0,
        postCompletion: 0,
      };
    }
    priorityData[priority_type].total++;
    if (ticket_status === "completed") {
      priorityData[priority_type].completed++;
    } else if (ticket_status === "Failed") {
      priorityData[priority_type].failed++;
    } else if (ticket_status === "Post Completion") {
      priorityData[priority_type].postCompletion++;
    }
  });
  useEffect(() => {
    Object.keys(priorityData).forEach((priorityType) => {
      const { total, completed } = priorityData[priorityType];
      const percentageCompleted = (completed / total) * 100;
      const formattedPercentage = percentageCompleted.toFixed(2);

      if (priorityType === "p1") {
        setPercentageP1Completed(formattedPercentage);
      } else if (priorityType === "p2") {
        setPercentageP2Completed(formattedPercentage);
      } else if (priorityType === "p3") {
        setPercentageP3Completed(formattedPercentage);
      }
    });
  }, [selectedTopdate, tablerecords]);
  // console.log(incidentDate)
  // console.log(incidentCount)
  // if(incidentCount){const [firstRecord] = incidentCount;

  //   var countofincident =firstRecord.no_of_incidents;
  //   var countofp1=firstRecord.no_of_p1;
  //   console.log(firstRecord.no_of_incidents);
  //   console.log(firstRecord.no_of_p1);}
  //   else{
  //     var countofincident =0;
  //     var countofp1=0;
  //   }

  const series = [
    {
      name: "Incident Count",
      data: incidentDate.map((item) => ({
        x: new Date(item.month).getTime(),
        y: parseInt(item.no_of_incidents),
      })),
    },
  ];

  // const series = [
  //   {
  //     name: "STOCK ABC",
  //     data: [10, 45, 15, 12, 10, 17, 16],
  //   },
  // ];
  const options = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    title: {
      text: "Incident Trends",
      align: "center",
      style: {
        color: "#FFF",
        fontFamily: "Poppins",
        fontSize: "15px",
      },
    },
    // labels: monthLabels,
    labels: monthLabels.map((label) => new Date(label).getTime()),
    // labels: [
    //   "Jan 2023",
    //   "feb 2023",
    //   "Mar 2023",
    //   "Apr 2023",
    //   "May 2023",
    //   "Jun 2023",
    // ],
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: "#FFF",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#FFF",
        },
      },
    },

    legend: {
      horizontalAlign: "left",
    },
  };
  // const options1 = {
  //   chart: {
  //     type: "bar",
  //     height: 350,
  //     stacked: true,
  //     stackType: "100%",
  //   },
  //   title: {
  //     text: "Top 5 Application",
  //     align: "center",
  //   },
  //   responsive: [
  //     {
  //       breakpoint: 480,
  //       options: {
  //         legend: {
  //           position: "bottom",
  //           offsetX: -10,
  //           offsetY: 0,
  //         },
  //       },
  //     },
  //   ],
  //   xaxis: {
  //     categories: ["Employment", "Nucleus", "Finance_Prod", "Navision", "Java"],
  //     labels: {
  //       show: true,
  //       rotate: -70,
  //       trim: true,
  //     },
  //   },
  //   fill: {
  //     opacity: 1,
  //   },
  //   legend: {
  //     show: true,
  //     position: "bottom",
  //     offsetX: 100,
  //     offsetY: 0,
  //   },

  //   colors: ["#5DB9A7", "#FFE03D"],
  // };

  // const series1 = [
  //   {
  //     name: "Target",
  //     data: [84, 55, 41, 57, 12],
  //   },
  //   {
  //     name: "Availability",
  //     data: [40, 51, 16, 57, 92],
  //   },
  // ];
  const series2 = [
    {
      name: "STOCK ABC",
      data: [11, 14, 15, 12, 20, 17],
    },
  ];
  const options2 = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    title: {
      text: "Spend Trend",
      align: "center",
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },

    xaxis: {
      type: "category",
      categories: ["Jan", "feb", "Mar", "Apr", "May", "Jun"],
    },
    yaxis: {
      opposite: true,
      labels: {
        show: true,
        align: "rights",
      },
    },
    legend: {
      horizontalAlign: "left",
    },
    colors: ["#58BBD1"],
  };
  const { Panel } = Collapse;

  const patchRecords = tablerecords.filter(
    (record) => record.request_category.toLowerCase() === "patch"
  );

  let complaintsPatch = 0;
  let nonComplaintsPatch = 0;

  patchRecords.forEach((record) => {
    const complaints = parseInt(record.compliants);
    const nonComplaints = parseInt(record.non_complaints);

    complaintsPatch += complaints;
    nonComplaintsPatch += nonComplaints;
  });

  let totalPatch = complaintsPatch + nonComplaintsPatch;
  let percentageComplaintsPatch = (complaintsPatch / totalPatch) * 100;
  let percentageComplaintsPatch_slm = percentageComplaintsPatch.toFixed(2);
  return (
    <>
      {" "}
      <div style={{ display: "flex", height: "100%" }} className="costimize_bg">
        <SLMSidebar />
        <div className="quickglancetitle">
          <MainHead type="slm" title="Quick Glance" />
        </div>
        <div className="slm-body-screen ">
          <div className="">
            <div className="landscape-perfomance">
              <h4 className="slm-colors">Landscape Perfomance</h4>
              <div className="mt-5">
                <Row gutter={[16, 16]}>
                  <Col xxl={12} xl={10} lg={10} md={12} sm={24} xs={24}>
                    <Row gutter={[16, 16]}>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        {" "}
                        <Card className="plat-card-1">
                          <div className="d-flex justify-content-center">
                            <h6 className="card-title mt-2 card-header-color">
                              No. of assets monitored
                            </h6>
                          </div>
                          <div className="mt-2 mb-1">
                            <h6 className="card-value-1">
                              <Icon icon={"mdi:monitor-eye"} /> {assetCount}
                            </h6>
                          </div>
                        </Card>
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Card className="plat-card-1">
                          <div className="d-flex justify-content-center">
                            <h6 className="card-title mt-2 card-header-color">
                              No. of incidents created
                            </h6>
                          </div>
                          <div className="mt-2 mb-1">
                            <h6 className="card-value-1">
                              <Icon icon={"ri:instance-fill"} />
                              &nbsp;
                              {/* {countofincident}  */}
                              {incidentCount}
                            </h6>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    <br />
                    <Row gutter={[16, 16]}>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        {" "}
                        <Card className="plat-card-1">
                          <div className="d-flex justify-content-center">
                            <h6 className="card-title mt-2 card-header-color">
                              No. of P1’s
                            </h6>
                          </div>
                          <div className="mt-2 mb-1">
                            <h6 className="card-value-1">
                              <Icon icon={"iconoir:priority-up"} />
                              &nbsp;
                              {/* {countofp1} */}
                              {p1Count}
                            </h6>
                          </div>
                        </Card>
                      </Col>
                      <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                        <Card className="plat-card-1">
                          <div className="d-flex justify-content-center">
                            <h6 className="card-title mt-2 card-header-color">
                              SLA Breaches
                            </h6>
                          </div>
                          <div className="mt-2 mb-1">
                            <h6 className="card-value-1">
                              <Icon icon={"ri:split-cells-horizontal"} />
                              <div className="card-value-2">
                                Critical-{percentageP1Completed}% <br />
                                Moderate-{percentageP2Completed}% &nbsp;
                                <br />
                                Low-{percentageP3Completed}%<br />
                              </div>
                              &nbsp;
                            </h6>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col xxl={12} xl={14} lg={14} md={12} sm={24} xs={24}>
                    <Card className="plat-card-1">
                      <ReactApexChart
                        options={options}
                        series={series}
                        type="area"
                        height={195}
                      />
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="availability mt-5">
              <h4 className="slm-colors">Availability</h4>
              <div className="mt-3">
                <Row gutter={[16, 16]}>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                    <Card className="plat-card-1">
                      {/* <ReactApexChart
                      options={options1}
                      series={series1}
                      type="bar"
                      height={250}
                    /> */}
                      <AvailabiltyChart />
                    </Card>
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                    <Card className="plat-card-1">
                      {/* <ReactApexChart
                      options={options2}
                      series={series2}
                      type="area"
                      height={250}
                    /> */}
                      <SpendtrendChart />
                    </Card>
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                    <Card className="plat-card-1">
                      {/* <ReactApexChart
                      options={options1}
                      series={series1}
                      type="bar"
                      height={250}
                    /> */}
                      <AssetTrend />
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="compliance mt-5">
              <h4 className="slm-colors">Compliance</h4>
              <div className="mt-3">
                <Row gutter={[16, 16]}>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                    <Card className="plat-card-1 ">
                      <div className="d-flex justify-content-center">
                        <h6 className="card-title mt-3 card-header-color">
                          Security Compliance
                        </h6>
                      </div>
                      <div className="mt-2 mb-2">
                        <h6 className="card-value-1">56%</h6>
                      </div>
                    </Card>
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                    <Card className="plat-card-1 me-3 ms-3">
                      <div className="d-flex justify-content-center">
                        <h6 className="card-title mt-3 card-header-color">
                          Patch Compliance
                        </h6>
                      </div>
                      <div className="mt-2 mb-2">
                        <h6 className="card-value-1">
                          {!typeof percentageComplaintsPatch_slm === NaN
                            ? percentageComplaintsPatch_slm
                            : 0}
                          %
                        </h6>
                      </div>
                    </Card>
                  </Col>
                  <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                    <Card className="plat-card-1">
                      <div className="d-flex justify-content-center">
                        <h6 className="card-title mt-3 card-header-color">
                          Tagging Compliance
                        </h6>
                      </div>
                      <div className="mb-2 mt-2">
                        <h6 className="card-value-1">46%</h6>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
              {/* <div className="availability mt-5">
                <Row>
                  <Col xxl={18} xl={18} lg={18} md={18} sm={24} xs={24}>
                    <h4 className="slm-colors">Key Events</h4>
                  </Col>
                  <Col xxl={3} xl={3} lg={3} md={3} sm={24} xs={24}>
                    <button className="table-add-button float-right">
                      <Icon icon="fluent:edit-12-regular" />
                      Edit
                    </button>
                  </Col>
                  <Col xxl={3} xl={3} lg={3} md={3} sm={24} xs={24}>
                    <button className="table-add-button float-right">
                      <Icon icon="material-symbols:add-circle-outline" />
                      Add
                    </button>
                  </Col>
                </Row>
                <div className="mt-3 keyevents">
                  <Row>
                    <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Card className="plat-card-1">
                        <div className="slmul-li">
                          <ul className="ulslm">
                            <li>hello everyone</li>
                          </ul>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div> */}
            </div>

            {/* <div className="compliance mt-5">
            <div className="mt-3">
              <Card className="plat-card-1 d-flex">
                <Row>
                  <Col xxl={18} xl={18} lg={18} md={18} sm={24} xs={24}>
                    <Collapse defaultActiveKey={["1"]} ghost>
                      <Panel
                        className="collapse-header"
                        header="Key Events"
                        key="1"
                      >
                        <ul className="collapse-ui-list">
                          <li>
                            Lorem ipsum dolor sit amet &nbsp;
                            <Icon icon={"ri:external-link-fill"} />
                          </li>
                          <li>
                            Sit deleniti eveniet eum repudiandae blanditiis et
                            earum omnis qui fuga veritatis in molestias
                            fuga.&nbsp;
                            <Icon icon={"ri:external-link-fill"} />
                          </li>
                          <li>
                            Aut aperiam eius et possimus reiciendis et labore
                            nisi et modi corporis ut inventore rerum.&nbsp;
                            <Icon icon={"ri:external-link-fill"} />
                          </li>
                          <li>
                            Qui officia tempore est optio iure in consequatur
                            doloremque quo tempore maiores sed rerum dignissimos
                            eum labore sunt hic aperiam sunt&nbsp;
                            <Icon icon={"ri:external-link-fill"} />
                          </li>
                        </ul>
                      </Panel>
                    </Collapse>
                  </Col>
                  <Col xxl={6} xl={6} lg={6} md={6} sm={24} xs={24}>
                    <button className="table-add-button float-right">
                      <Icon icon="material-symbols:add-circle-outline" />
                      &nbsp; Add
                    </button>
                    <button className="table-add-button float-right">
                      <Icon icon="material-symbols:edit" />
                      &nbsp; Edit
                    </button>
                  </Col>
                </Row>
              </Card>
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default SLM_Quickglance;
