import React, { useState } from "react";
import Chart from "react-apexcharts";
import JsonViewer from "../../../jsonData/JsonViewer";
import { Spin } from "antd";
// ... (other imports and code)

const CardGraph = ({ chartData }) => {
  let spendData = chartData
    ? chartData.map((item) =>
        Math.abs(
          item.spend || item.forecast || item.credit_cost || item.budget_amount
        )
      )
    : [];

  const chartDataValue = {
    chartvalue: spendData,
  };

  const spark1 = {
    chart: {
      type: "area",
      height: 500,
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      curve: "straight",
      colors: "#ff6a2f",
      opacity: 0.1,
    },

    fill: {
      type: "solid",
      colors: "#ff6a2f",
      opacity: 0.1,
    },

    series: [
      {
        name: "usage spend",
        data: chartDataValue.chartvalue,
      },
    ],
    // labels: generateLastThreeMonthDates,
    tooltip: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    xaxis: {
      show: false,
    },
    yaxis: {
      show: false,
    },
  };

  return (
    <div>
      {/* JsonViewer component */}
      {/* Assuming JsonViewer displays spenddetails in a JSON format */}
      {/* {JSON.stringify(chartData)} */}

      <Chart
        options={spark1}
        series={spark1.series}
        type={chartData && chartData[0]?.credit_cost ? "bar" : "area"}
        height={60}
        width={"100%"}
      />
    </div>
  );
};

export default CardGraph;
