import { gql } from "@apollo/client";

const LIST_OVERVIEW = gql`
  query MyQuery($companyName: String!) {
    get_count_status(customer_name: $companyName)
  }
`;

const LIST_JOB_SCHEDULES_OVERVIEW = gql`
  query MyQuery($event_identifier: String!, $customer_name: String!) {
    listPEEMSExecqs(
      event_identifier: $event_identifier
      customer_name: $customer_name
    )
  }
`;

const LIST_JOB_SCHEDULES_REPORT = gql`
  query MyQuery(
    $companyName: String!
    $startDate: String!
    $event_identifier: String!
  ) {
    getResults(
      customer: $companyName
      startDate: $startDate
      event_identifier: $event_identifier
    )
  }
`;

const LIST_REPORT = gql`
  query MyQuery($customer: String!, $startDate: String!) {
    getjobcount_athena_monthly(
      count: "completed"
      customer: $customer
      startDate: $startDate
    )
  }
`;

const LIST_CHART = gql`
  query MyQuery($customer: String!, $startDate: String!) {
    getcount_athena_monthly(
      status: "active"
      customer: $customer
      startDate: $startDate
    )
  }
`;

const ESCALATION_DETAILS = gql`
  query MyQuery($date: String!) {
    getzohoresponse(
      event_type: "PEEMS"
      date: $date
      email: "alertmanager@ses.1cloudhub.com"
      subject: "Scheduled Process Is Not Triggered"
    )
  }
`;

const LIST_CUSTOMER = gql`
  query MyQuery($companyName: String!) {
    listPEEMSRules(
      filter: {
        rule_id: { beginsWith: "c" }
        customer_name: { contains: $companyName }
      }
    ) {
      nextToken
      items {
        rule_id
        condition
        key
        status
        value
      }
    }
  }
`;
const LIST_EVENT = gql`
  query MyQuery($companyName: String!) {
    listPEEMSRules(
      filter: {
        rule_id: { beginsWith: "e" }
        customer_name: { contains: $companyName }
      }
    ) {
      nextToken
      items {
        rule_id
        condition
        key
        status
        value
      }
    }
  }
`;
const LIST_RULE = gql`
  query MyQuery($companyName: String!) {
    listPEEMSRules(
      filter: {
        rule_id: { beginsWith: "r" }
        customer_name: { contains: $companyName }
      }
    ) {
      nextToken
      items {
        rule_id
        condition
        key
        status
        value
      }
    }
  }
`;

const CREATE_IDENTIFIER = gql`
  mutation MyMutation(
    $condition: String!
    $key: String!
    $rule_id: String!
    $value: String!
    $companyName: String!
    $event_type: String!
    $identifier_type: String!
  ) {
    createPEEMSRules(
      input: {
        condition: $condition
        key: $key
        rule_id: $rule_id
        value: $value
        customer_name: $companyName
        event_type: $event_type
        identifier_type: $identifier_type
      }
    ) {
      condition
      key
      rule_id
      value
    }
  }
`;

const UPDATE_IDENTIFIER = gql`
  mutation MyMutation(
    $condition: String!
    $key: String!
    $rule_id: String!
    $value: String!
    $companyName: String!
  ) {
    updatePEEMSRules(
      input: {
        condition: $condition
        key: $key
        rule_id: $rule_id
        value: $value
        customer_name: $companyName
      }
    ) {
      condition
      key
      rule_id
      value
    }
  }
`;

const JOB_CONFIG_IDENTIFIER = gql`
  query MyQuery($companyName: String!) {
    getjobconfigured(customer_name: $companyName)
  }
`;

const JOB_CREATE = gql`
  mutation MyMutation(
    $cron_description: String!
    $cron_value: String!
    $customer_identifier: String!
    $companyName: String!
    $event_identifier: String!
    $job_description: String!
    $job_type: String!
    $job_name: String!
    $jobactual_runtime: String!
    $jobbuffer_runtime: String!
    $rule_identifier: String!
    $event_type: String!
  ) {
    peemscreatejob(
      cron_description: $cron_description
      cron_value: $cron_value
      customer_identifier: $customer_identifier
      customer_name: $companyName
      event_identifier: $event_identifier
      job_description: $job_description
      job_name: $job_name
      job_type: $job_type
      jobactual_runtime: $jobactual_runtime
      jobbuffer_runtime: $jobbuffer_runtime
      rule_identifier: $rule_identifier
      event_type: $event_type
    )
  }
`;

export {
  LIST_OVERVIEW,
  LIST_JOB_SCHEDULES_OVERVIEW,
  LIST_REPORT,
  LIST_CHART,
  ESCALATION_DETAILS,
  LIST_CUSTOMER,
  LIST_EVENT,
  LIST_RULE,
  CREATE_IDENTIFIER,
  UPDATE_IDENTIFIER,
  JOB_CONFIG_IDENTIFIER,
  JOB_CREATE,
  LIST_JOB_SCHEDULES_REPORT,
};
