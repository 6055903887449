import {
  REQUEST_CHATBOT_CREDIT,
  CHART_BOT_RESPONSE,
  CHART_BOT_PROMPTS_LIST,
} from "../../action/graphql/ChatbotGraphql";
import Chatbotclient from "../../action/client/ChatbotClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
// CREDIT REQUEST API

export const requestCredits = createAsyncThunk(
  // action type string
  "chartbot/responce",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await Chatbotclient.mutate({
        mutation: REQUEST_CHATBOT_CREDIT,
        variables: {
          userId: data,
        },
      });
      return listbudget;
    } catch (error) {
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// MESSAGE REQUEST API

export const requestMessage = createAsyncThunk(
  // action type string
  "chartbot/responce",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await Chatbotclient.mutate({
        mutation: CHART_BOT_RESPONSE,
        variables: {
          date_time: data.date_time,
          schema: data.schema,
          user_name: data.user_name,
          cloud: data.cloud,
          query: data.message,
          user_session: data.user_session,
        },
      });
      return listbudget;
    } catch (error) {
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getPromptsList = createAsyncThunk(
  // action type string
  "chartbot/prompts",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await Chatbotclient.mutate({
        mutation: CHART_BOT_PROMPTS_LIST,
        variables: {
          cloud: data.cloud,
          prompt_type: data.prompt_type,
          schema: data.schema,
        },
      });
      return listbudget;
    } catch (error) {
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
