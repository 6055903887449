import React from "react";
import "./terms.css";
const Terms = () => {
  return (
    <div className="terms-background">
      <h1 className="terms-heading">Terms and Conditions</h1>
      <div className="terms-content">
        <div className="terms-box">
          <div className="terms-body">
            <p className="terms-and-conditions">
              Welcome to Cloud Shifu! Before you proceed with the registration,
              please carefully read and accept the following terms and
              conditions. By creating an account on our platform, you
              acknowledge that you have read, understood, and agreed to comply
              with these terms. If you do not agree with any part of these
              terms, please refrain from registering and using our services.
              <br />
              <br />
              <header className="terms-sub-heading">1. User Account</header>
              <br />
              1.1. You must be at least 18 years old to create an account and
              use our services.
              <br />
              <br />
              1.2. You are responsible for maintaining the confidentiality of
              your account credentials (username and password). Any activities
              that occur under your account are your sole responsibility.
              <br />
              <br />
              1.3. You agree to provide accurate and up-to-date information
              during the registration process and keep it current throughout
              your usage of the platform.
              <br />
              <br />
              <header className="terms-sub-heading">2. Service Usage</header>
              <br />
              2.1. Our platform allows you to monitor and manage cloud costs
              associated with your registered cloud service provider accounts.
              You agree to use the services only for their intended purpose and
              in compliance with all applicable laws and regulations.
              <br />
              <br />
              2.2. You are solely responsible for any data you upload or share
              on our platform and must ensure that it does not infringe any
              third-party rights, violate any laws, or contain malicious
              software.
              <br />
              <br />
              <header className="terms-sub-heading">3. Data Privacy</header>
              <br />
              3.1. We take data privacy seriously. By using our services, you
              consent to the collection, storage, and processing of your
              personal information in accordance with our Privacy Policy.
              <br />
              <br />
              3.2. Your data will be handled securely and will not be shared
              with any third parties without your explicit consent, except as
              required by law.
              <br />
              <br />
              <header className="terms-sub-heading">
                4. Payment and Subscription
              </header>
              <br />
              4.1. Some features of our platform may be accessible through paid
              subscriptions. By subscribing to any of our paid plans, you agree
              to pay the specified fees as outlined in our pricing page.
              <br />
              <br />
              4.2. Payment transactions are processed by raising an Invoice. We
              do not store your credit card or payment information.
              <br />
              <br />
              4.3. Subscriptions will automatically renew at the end of each
              billing cycle unless you cancel your subscription before the
              renewal date.
              <br />
              <br />
              <header className="terms-sub-heading">
                5. Intellectual Property
              </header>
              <br />
              5.1. All intellectual property rights related to our platform and
              its content, including but not limited to software, logos,
              trademarks, and documentation, are owned by One Cloud Hub.
              <br />
              <br />
              5.2. You may not copy, modify, distribute, sell, or lease any part
              of our platform or its content without our explicit written
              consent.
              <br />
              <br />
              <header className="terms-sub-heading">
                6. Termination of Account
              </header>
              <br />
              6.1. We reserve the right to suspend or terminate your account at
              any time and for any reason, including but not limited to
              violations of these terms or any illegal activities associated
              with your account.
              <br />
              <br />
              6.2. Upon termination, you will lose access to your account and
              any data associated with it. We may retain certain data in
              accordance with our Privacy Policy.
              <br />
              <br />
              <header className="terms-sub-heading">
                7. Disclaimer of Warranties
              </header>
              <br />
              7.1. Our platform is provided "as is" without any warranties,
              express or implied. We do not guarantee that our services will be
              uninterrupted, error-free, or free of viruses.
              <br />
              <br />
              7.2. We do not warrant the accuracy, reliability, or completeness
              of any information provided on our platform.
              <br />
              <br />
              <header className="terms-sub-heading">
                8. Limitation of Liability
              </header>
              <br />
              8.1. In no event shall One Cloud Hub be liable for any direct,
              indirect, incidental, special, or consequential damages arising
              out of or in connection with the use of our platform.
              <br />
              <br />
              8.2. Your use of our platform is at your own risk.
              <br />
              <br />
              <header className="terms-sub-heading">
                9. Changes to Terms and Conditions
              </header>
              <br />
              9.1. We reserve the right to update or modify these terms and
              conditions at any time. Any changes will be posted on our
              platform, and it is your responsibility to review them
              periodically.
              <br />
              <br />
              9.2. Continued use of our platform after the modifications signify
              your acceptance of the updated terms.
              <br />
              <br />
              <header className="terms-sub-heading">Third Party Links:</header>
              <br />
              a) From time to time, the Services may contain links to web sites
              that are not owned, operated or controlled by One Cloud Hub or its
              affiliates.
              <br />
              <br />
              b) All such links are provided solely as a convenience to you. If
              you use these links, you will leave the Services. Neither we nor
              any of our respective affiliates are responsible for any content,
              materials or other information located on or accessible from any
              other web site.
              <br />
              <br />
              c) Neither we nor any of our respective affiliates endorse,
              guarantee, or make any representations or warranties regarding any
              other web sites, or any content, materials or other information
              located or accessible from any other web sites, or the results
              that you may obtain from using any other web sites. If you decide
              to access any other web sites linked to or from this Services, you
              do so entirely at your own risk.
              <br />
              <br />
              <header className="terms-sub-heading">
                Transactional Partners:
              </header>
              <br />
              In some cases we partner with another company to co-promote their
              services within our Services. In these cases, you are transacting
              directly with the other party. On those pages, the transactional
              partners’ brand is clearly visible and their terms of service are
              posted.
              <br />
              <br />
              When using these partner pages, you are bound by partner terms of
              service in addition to remaining bound by One Cloud Hub Terms of
              Service. When there is a conflict between these Terms of Service
              and the partner’s terms of service, their terms of service will
              prevail.
              <br />
              <br />
              <header className="terms-sub-heading">
                Access and Interference:
              </header>
              <br />
              You agree that you will not use any robot, spider, scraper or
              other automated means to access the Services for any purpose
              without our express written permission.
              <br />
              <br />
              Additionally, you agree that you will not:
              <br />
              <br />
              (i) take any action that imposes, or may impose in our sole
              discretion an unreasonable or disproportionately large load on our
              infrastructure;
              <br />
              <br />
              (ii) interfere or attempt to interfere with the proper working of
              the site or any activities conducted on the Services; or
              <br />
              <br />
              (iii) bypass any measures we may use to prevent or restrict access
              to the Services. Right to Takedown Content. Except as disclosed in
              our Privacy Policy, we will not monitor, edit, or disclose the
              contents of a user’s e-mail unless required in the course of
              normal maintenance of the Services and its systems or unless
              required to do so by law or in the good-faith belief that such
              action is necessary to:
              <br />
              <br />
              (1) comply with the law or comply with legal process served on One
              Cloud Hub or the Services;
              <br />
              <br />
              (2) protect and defend the rights or property of One Cloud Hub,
              the Services, or the users of the Services; or
              <br />
              <br />
              (3) act in an emergency to protect the personal safety of our
              guests, the Services, or the public. Users shall remain solely
              responsible for the content of their messages and One Cloud Hub
              shall have no obligation to pre-screen any such content.
              <br />
              <br />
              However, we shall have the right in our sole discretion to edit,
              refuse to post or remove any material submitted to or posted on
              the Services at any time without notice. Without limiting the
              foregoing, we shall have the right to remove any material that we
              find to be in violation of the provisions hereof or otherwise
              objectionable, and the additional right to deny any user who fails
              to conform to any provision of these Terms of Service access to
              the Services.
              <br />
              <br />
              By clicking the "I agree" button during the registration process,
              you acknowledge that you have read, understood, and agreed to
              these terms and conditions.
              <br />
              <br />
              If you have any questions or concerns, please contact our support
              team before proceeding with registration.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
