import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, Form, Input, Select, message } from "antd";
import { Row, Col } from "react-bootstrap";
import "./spp.css";
import {
  AddInvoiceTable,
  updateInvoicetable,
  deleteInvoice,
} from "../action/costimizev2DashboardAction";
import DrawerPlat from "./../properties/Drawer/DrawerPlat";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import DatePicker from "react-datepicker";

dayjs.extend(customParseFormat);

const Invoiceform = (props) => {
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const dispatch = useDispatch();
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [buttonloading, setButtonLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isValueChanged, setValueChanged] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [initialFormValues, setInitialFormValues] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [form] = Form.useForm();
  const monthFormat = "YYYY/MM";
  const [fname, setFName] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(
    moment(new Date()).format("YYYY/MM")
  );

  useEffect(() => {
    var records = {
      client: "",
      report_month: " ",
      billing_entity: "",
      resell_partner: "",
      cloud: "",
      cloud_invoice_no: "",
      usage: " ",
      discount: "",
      platform_cost: "",
      och_tax: "",
      invoice_no: "",
      invoice_usage_cost: "",
      discount: "",
      invoice_total_amount: "",
      billing_currency: "",
      total_invoice_amount: "",
      och_invoiceid: "",
      och_platform_cost: "",
      //och_billing_cost: "",
      status: "",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      pincode: "",
      country: "",
      date: "",
    };

    if (props.oldrecords) {
      records = {
        client: props.oldrecords.client,
        date: props.oldrecords.month,
        // report_month: moment(props.oldrecords.month).format('YYYY/MM'),
        billing_entity: props.oldrecords.billing_entity,
        resell_partner: props.oldrecords.resell_partner,
        cloud: props.oldrecords.cloud,
        cloud_invoice_no: props.oldrecords.cloud_invoice_no,
        invoice_usage_cost: props.oldrecords.usage,
        discount: props.oldrecords.discount,
        och_platform_cost: props.oldrecords.platform_cost,
        invoice_total_amount: props.oldrecords.invoice_total_amount,
        och_tax: props.oldrecords.och_tax,
        billing_currency: props.oldrecords.billing_currency,
        och_invoiceid: props.oldrecords.invoice_no,
        total_invoice_amount: props.oldrecords.total_invoice_amount,
        status: props.oldrecords.status,
        bill_id: props.oldrecords.bill_id,
        address_1: props.oldrecords.spp_address_1,
        address_2: props.oldrecords.spp_address_2,
        city: props.oldrecords.spp_city,
        state: props.oldrecords.spp_state,
        pincode: props.oldrecords.spp_pincode,
        country: props.oldrecords.country,
      };
      setInitialFormValues(records);

      setSelectedMonth(
        moment(new Date(records.report_month)).format("YYYY/MM")
      );
    }
    form.setFieldsValue(records);
  }, [props, selectedMonth]);
  const onFinish = (value) => {
    setButtonLoading(true);

    const discount = value.discount;

    const discountAmount = value.invoice_usage_cost * (1 - discount / 100);

    const discountsumplatform =
      parseInt(discountAmount) + parseInt(value.och_platform_cost);

    const invoice_total_amount = discountsumplatform;

    const total_usage_amount = discountsumplatform;

    const gstPercent = parseInt(value.och_tax.split("-").slice(-1)[0]);

    const gsttax = discountsumplatform * (gstPercent / 100);
    const och_billing_cost = discountsumplatform + gsttax;

    const total_invoice_amount = och_billing_cost;

    let input = {
      client: value.client,
      report_month: `${new Date(value.date).getFullYear()}-${
        new Date(value.date).getMonth() + 1
      }-01`,
      billing_entity: value.billing_entity,
      resell_partner: value.resell_partner,
      cloud: value.cloud,
      cloud_invoice_no: value.cloud_invoice_no,
      invoice_usage_cost: value.invoice_usage_cost,
      discount: value.discount,
      och_platform_cost: value.och_platform_cost,
      och_tax: value.och_tax,
      billing_currency: value.billing_currency,
      och_invoiceid: value.och_invoiceid,
      status: value.status,
      address_1: value.address_1,
      address_2: value.address_2,
      city: value.city,
      state: value.state,
      pincode: value.pincode,
      country: value.country,
      total_invoice_amount: total_invoice_amount,
      total_usage_amount: total_usage_amount,
    };

    form.setFieldsValue({
      invoice_total_amount: invoice_total_amount,
      och_billing_cost: och_billing_cost,
    });

    if (props.deletestatus) {
      const bill_id = props.oldrecords.bill_id;

      dispatch(updateInvoicetable({ input, bill_id }))
        .unwrap()
        .then(({ data }) => {
          message.success("Record Updated");
          props.setUpdateStatus(!props.updatestatus);
          props.setDrawerStatus(false);
          setButtonLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
          message.info("Record Not Updated");
        });
    } else {
      dispatch(AddInvoiceTable({ input }))
        .unwrap()
        .then(({ data }) => {
          message.success("New Records Added");
          props.setUpdateStatus(!props.updatestatus);
          props.setDrawerStatus(false);
          setButtonLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
          message.info("Record not inserted");
        });
    }
  };
  const rowDelete = () => {
    setConfirmLoading(true);
    const bill_id = props.oldrecords.bill_id;
    dispatch(deleteInvoice({ bill_id }))
      .unwrap()
      .then((data) => {
        message.success("Deleted");
        props.setUpdateStatus(!props.updatestatus);
        props.setDrawerStatus(false);
        setButtonLoading(false);
        setConfirmLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        message.info("Record not deleted");
      });
  };

  const handleDateChange = (date, dateString) => {
    if (dateString) {
      setSelectedMonth(dateString);
    } else {
      setSelectedMonth(
        moment(new Date(initialFormValues.report_month)).format("YYYY/MM")
      );
    }
  };
  const options =
    selectedValue === "1CH"
      ? [
          {
            value: "SG-GST-8",
            label: "SG-GST-8%",
          },
        ]
      : selectedValue === "OCH"
      ? [
          {
            value: "IN-GST-18",
            label: "IN-GST-18%",
          },
        ]
      : [
          {
            value: "SG-GST-8",
            label: "SG-GST-8%",
          },
          {
            value: "IN-GST-18",
            label: "IN-GST-18%",
          },
        ];

  const optionhandleChange = (value) => {
    setSelectedValue(value);
    setValueChanged(value !== initialFormValues.och_tax);
  };
  const optionsbilling =
    selectedValue === "1CH"
      ? [
          {
            value: "USD",
            label: "USD",
          },
        ]
      : selectedValue === "OCH"
      ? [
          {
            value: "INR",
            label: "INR",
          },
        ]
      : [
          {
            value: "USD",
            label: "USD",
          },
          {
            value: "INR",
            label: "INR",
          },
        ];
  const optionhandleChangebilling = (value) => {
    setSelectedValue(value);
    setValueChanged(value !== initialFormValues.billing_currency);
  };

  return (
    <>
      <DrawerPlat
        drawerstatus={props.drawerstatus}
        changeDrawerStatus={props.changeDrawerStatus}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        rowDelete={rowDelete}
        deletestatus={props.deletestatus}
        confirmLoading={confirmLoading}
        title="Invoice "
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row>
            <Col>
              <Form.Item
                name="client"
                label="Client Name"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => {
                    setFName(e.target.value);
                    setValueChanged(
                      e.target.value !== initialFormValues.client
                    );
                  }}
                />
              </Form.Item>
            </Col>
            <Col className="spp-date">
              <Form.Item
                name="date"
                label="Invoice Date"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker
                  className="datepicker"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showMonthYearPicker
                />
                {/* <input type="hidden" value={selectedMonth} />
                <DatePicker
                  value={dayjs(selectedMonth, monthFormat)}
                  format={monthFormat}
                  onChange={handleDateChange}
                  className='spp-date'
                  picker="month"

                /> */}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="billing_entity"
                label="Billing Entity"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "OCH",
                      label: "OCH",
                    },
                    {
                      value: "1CH",
                      label: "1CH",
                    },
                  ]}
                  onChange={(value) => {
                    const taxValue = value === "1CH" ? "SG-GST-8" : "IN-GST-18";
                    const billingCurrency = value === "1CH" ? "USD" : "INR";
                    form.setFieldsValue({
                      och_tax: taxValue,
                      billing_currency: billingCurrency,
                    });
                    setSelectedValue(value);
                    setValueChanged(value !== initialFormValues.billing_entity);
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="resell_partner"
                label="Resell Partner"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "crayon",
                      label: "Crayon",
                    },
                    {
                      value: "redington",
                      label: "Redington",
                    },
                    {
                      value: "techdata",
                      label: "Tech Data",
                    },
                    {
                      value: "spp",
                      label: "SPP",
                    },
                  ]}
                  onChange={(value) => {
                    setSelectedValue(value);
                    setValueChanged(value !== initialFormValues.resell_partner);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="cloud"
                label="Cloud"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "azure",
                      label: "Azure",
                    },
                    {
                      value: "aws",
                      label: "AWS ",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="cloud_invoice_no"
                label="Cloud Invoice No"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => {
                    setFName(e.target.value);
                    setValueChanged(
                      e.target.value !== initialFormValues.cloud_invoice_no
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="invoice_usage_cost"
                label="Usage"
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Please enter a valid number",
                  },
                  {
                    required: true,
                    message: "Please enter a number",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => {
                    setFName(e.target.value);
                    setValueChanged(
                      e.target.value !== initialFormValues.invoice_usage_cost
                    );
                  }}
                />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                name="discount"
                label="Discount"
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Please enter a valid number",
                  },
                  {
                    required: true,
                    message: "Please enter a number",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => {
                    setFName(e.target.value);
                    setValueChanged(
                      e.target.value !== initialFormValues.discount
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="och_platform_cost"
                label="Platform Cost"
                rules={[
                  {
                    pattern: /^[0-9]+(\.[0-9]+)?$/,
                    message: "Please enter a valid number",
                  },
                  {
                    required: true,
                    message: "Please enter a number",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => {
                    setFName(e.target.value);
                    setValueChanged(
                      e.target.value !== initialFormValues.och_platform_cost
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="och_tax"
                label="Tax"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={options}
                  onChange={optionhandleChange}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="billing_currency"
                label="Billing Currency"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={optionsbilling}
                  onChange={optionhandleChangebilling}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="och_invoiceid"
                label="1CH Invoice No."
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => {
                    setFName(e.target.value);
                    setValueChanged(
                      e.target.value !== initialFormValues.och_invoiceid
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item name="status" label="Status">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "Yet to Invoice",
                      label: "Yet to Invoice",
                    },
                    {
                      value: "Invoiced",
                      label: "Invoiced",
                    },
                  ]}
                  onChange={(value) => {
                    setSelectedValue(value);
                    setValueChanged(value !== initialFormValues.status);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <h5 className="ant-modal-title">Address</h5>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="address_1"
                label="Address Line1"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => {
                    setFName(e.target.value);
                    setValueChanged(
                      e.target.value !== initialFormValues.address_1
                    );
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="address_2"
                label="Address Line2"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => {
                    setFName(e.target.value);
                    setValueChanged(
                      e.target.value !== initialFormValues.address_2
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => {
                    setFName(e.target.value);
                    setValueChanged(e.target.value !== initialFormValues.city);
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="state"
                label="State"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => {
                    setFName(e.target.value);
                    setValueChanged(e.target.value !== initialFormValues.state);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="pincode"
                label="Zip/Postal Code"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="country"
                label="Country"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => {
                    setFName(e.target.value);
                    setValueChanged(
                      e.target.value !== initialFormValues.country
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="spp-actions-row">
            <Col span={6} offset={6}>
              <Form.Item>
                <Button
                  htmlType="button"
                  className="no-btn"
                  onClick={() => props.setDrawerStatus(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col span={6} offset={6}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="yes-button"
                  disabled={!props.deletestatus && !isValueChanged}
                  loading={buttonloading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DrawerPlat>
    </>
  );
};
export default Invoiceform;
