import { Icon } from "@iconify/react";
// import { Button, Col, Form, Modal, Row,Input} from "antd";
import {
  Table,
  Spin,
  Popconfirm,
  Modal,
  Space,
  Form,
  Input,
  Row,
  Col,
  Button,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  approveRequest,
  approveRequestBy1CHAdmin,
  getApprovalAllValues,
  rejectRequest,
  rejectRequest1CHAdmin,
} from "../../../action/graphql/devops/DevOpsAction";
// import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import { loginUser } from "../../../action/userAction";

function ApprovalPreviewForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isApproveButtonClicked, setIsApproveButtonClicked] = useState(false);
  const [is_admin_approved, setIsAdminApproved] = useState(0);
  const [is_och_approved, setIsochApproved] = useState(0);
  const [isModalOpenApprove, setIsModalOpenApprove] = useState(false);
  const { id } = useParams();
  const { TextArea } = Input;
  const [isShowButtonLoader, setIsShowButtonLoader] = useState(false);
  // const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const userRole = localStorage.getItem(
    "CognitoIdentityServiceProvider.offering"
  );
  const devopsStore = useSelector((state) => state.devops);
  const [data, setData] = useState([]);
  const [crPreviewData, setCRPreviewData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getDetails = () => {
    dispatch(getApprovalAllValues({ id }))
      .unwrap()
      .then(({ data }) => {
        let response = data.list_platform_devops_accounts_activities_id;

        setCRPreviewData(response);
        handleDeformData(response);
      });
  };

  const [createdUserEmail, setCreatedUserEmail] = useState([]);

  const getCreatedUserEmail = () => {
    let created_by_user;
    let user_id = localStorage.getItem("CognitoIdentityServiceProvider.auth");
    let token = localStorage.getItem("_token");
    const records = {
      user_id: user_id,
      _token: token,
    };
    dispatch(loginUser(records))
      .unwrap()
      .then(({ data }) => {
        created_by_user = data.getUsers.email_id;
        setCreatedUserEmail(created_by_user);
      });
  };
  useEffect(() => {
    localStorage.setItem("approval-preview", true);
    setData(devopsStore.requestorTableData);
    getDetails();
    getCreatedUserEmail();
  }, []);

  const removeSpaces = (value) => {
    let str = value.replace(/_/g, " ");
    str = str.charAt(0).toUpperCase() + str.slice(1);
    if (str === "Cx approved by") {
      return (str = "Admin approved by");
    } else if (str === "Cx comments") {
      return (str = "Admin comments");
    }
    return str;
  };

  const handleDeformData = (response) => {};
  const [form] = Form.useForm();

  const handleApproveSubmit = () => {
    setShowSpinner(true);
    setIsApproveButtonClicked(true);
    let record = crPreviewData;
    setSelectedRow(record);
    form.setFieldsValue({ comment: "" });
    setIsModalOpenApprove(true);

    // setShowSpinner(true);

    let id, och_approved_by, job_name, configurations, customer_name;
    crPreviewData.map((item) => {
      id = item.id;
      och_approved_by = item.och_approved_by;
      job_name = item.job_name;
      let cleanedString = item.configurations.replace(/\\\\/g, "\\");
      configurations = JSON.parse(item.configurations);
      customer_name = item.customer_name;
    });

    let values = {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalOpenApprove(false);
  };
  const handleSubmit = (values) => {};
  const [selectedRow, setSelectedRow] = useState([]);

  const handleSubmitApprove = (values) => {
    setIsApproveButtonClicked(true);
    setShowSpinner(true);
  };
  // useEffect(() => {
  //   if (userRole === 'admin' && is_admin_approved === 1) {
  //     setIsButtonDisabled(true);
  //   } else {
  //     setIsButtonDisabled(false);
  //   }
  // }, [userRole, is_admin_approved]);

  // useEffect(() => {

  //   localStorage.setItem('isButtonDisabled', isButtonDisabled);
  // }, [isButtonDisabled]);

  const handleReject = () => {
    let record = crPreviewData;
    setSelectedRow(record);
    form.setFieldsValue({ comment: "" });
    setIsModalOpen(true);
    // setIsButtonDisabled(true);
  };

  return (
    <div>
      <>
        <Form onFinish={handleApproveSubmit} form={form}>
          <Row>
            <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}></Col>
            <Col
              xxl={20}
              xl={20}
              lg={20}
              md={20}
              sm={20}
              xs={20}
              className="mt-5"
            >
              <div className="">
                <div className="d-flex align-items-center header-title-devops">
                  <Icon
                    icon="bi:arrow-left"
                    style={{ fontSize: "25px", cursor: "pointer" }}
                    onClick={() => navigate("/devops-requestor")}
                  />{" "}
                  &nbsp; &nbsp; <h4>CR Preview</h4>
                </div>
              </div>
              <br />
              <div className="">
                <div className="basic-details">
                  <Row
                    gutter={[16, 16]}
                    className="d-flex justify-content-center sub-heading-devops-config"
                  >
                    <Col xxl={18} xl={18} lg={18} md={18} sm={24}>
                      {crPreviewData.map((items) => {
                        return Object.entries(items).map((item) => {
                          return (
                            <>
                              {item[0] === "Admin password" ? (
                                <>
                                  <Row
                                    gutter={[16, 16]}
                                    className="devops-preview-rows"
                                  >
                                    <Col xxl={7} xl={7} lg={7} md={7} sm={24}>
                                      {/* <span className="sub-heading-devops-config"> */}
                                      <span className="sub-heading-devops-config">
                                        {removeSpaces(item[0])}
                                      </span>
                                    </Col>
                                    <Col xxl={1} xl={1} lg={1} md={1} sm={1}>
                                      :
                                    </Col>
                                    <Col
                                      xxl={16}
                                      xl={16}
                                      lg={16}
                                      md={16}
                                      sm={24}
                                    >
                                      * * * * * * * * * *
                                    </Col>
                                  </Row>
                                </>
                              ) : item[0] == "created_time" ? (
                                <>
                                  <Row
                                    gutter={[16, 16]}
                                    className="devops-preview-rows"
                                  >
                                    <Col xxl={7} xl={7} lg={7} md={7} sm={24}>
                                      <span className="sub-heading-devops-config">
                                        {removeSpaces(item[0])}
                                      </span>
                                    </Col>
                                    <Col xxl={1} xl={1} lg={1} md={1} sm={1}>
                                      :
                                    </Col>
                                    <Col
                                      xxl={16}
                                      xl={16}
                                      lg={16}
                                      md={16}
                                      sm={24}
                                    >
                                      <span className="sub-heading-devops-config">
                                        {moment(item[1]).format(
                                          "DD-MMM-YYYY, hh:mm:ss A"
                                        )}
                                      </span>
                                    </Col>
                                  </Row>
                                </>
                              ) : item[0] == "__typename" ||
                                item[0] == "rule_name" ||
                                item[0] == "build_number" ||
                                item[0] == "approved_time" ||
                                (item[0] == "event_type") |
                                  (item[0] == "event_type") ||
                                item[0] == "is_admin_approved" ||
                                item[0] == "is_och_approved" ||
                                item[0] == "job_name" ? (
                                <></>
                              ) : item[0] == "configurations" ? (
                                Object.entries(
                                  JSON.parse(JSON.parse(item[1]))
                                ).map((itm) => {
                                  return itm[0] === "id" ||
                                    itm[0] == "event_type" ||
                                    itm[0] == "status" ||
                                    itm[0] == "created_by" ? (
                                    <></>
                                  ) : itm[0] === "admin_password" ? (
                                    <>
                                      <Row
                                        gutter={[16, 16]}
                                        className="devops-preview-rows"
                                      >
                                        <Col
                                          xxl={7}
                                          xl={7}
                                          lg={7}
                                          md={7}
                                          sm={24}
                                        >
                                          <span className="sub-heading-devops-config">
                                            {removeSpaces(itm[0])}
                                          </span>
                                        </Col>
                                        <Col
                                          xxl={1}
                                          xl={1}
                                          lg={1}
                                          md={1}
                                          sm={1}
                                        >
                                          :
                                        </Col>
                                        <Col
                                          xxl={16}
                                          xl={16}
                                          lg={16}
                                          md={16}
                                          sm={24}
                                        >
                                          * * * * * * * * * * *
                                        </Col>
                                      </Row>
                                    </>
                                  ) : (
                                    <>
                                      <Row
                                        gutter={[16, 16]}
                                        className="devops-preview-rows"
                                      >
                                        <Col
                                          xxl={7}
                                          xl={7}
                                          lg={7}
                                          md={7}
                                          sm={24}
                                        >
                                          <span className="sub-heading-devops-config">
                                            {removeSpaces(itm[0])}
                                          </span>
                                        </Col>
                                        <Col
                                          xxl={1}
                                          xl={1}
                                          lg={1}
                                          md={1}
                                          sm={1}
                                        >
                                          :
                                        </Col>
                                        <Col
                                          xxl={16}
                                          xl={16}
                                          lg={16}
                                          md={16}
                                          sm={24}
                                          style={{ wordWrap: "break-word" }}
                                        >
                                          {itm[1]}
                                        </Col>
                                      </Row>
                                    </>
                                  );
                                })
                              ) : (
                                <>
                                  {" "}
                                  <Row
                                    gutter={[16, 16]}
                                    className="devops-preview-rows"
                                  >
                                    <Col xxl={7} xl={7} lg={7} md={7} sm={24}>
                                      <span className="sub-heading-devops-config">
                                        {removeSpaces(item[0])}
                                      </span>
                                    </Col>
                                    <Col xxl={1} xl={1} lg={1} md={1} sm={1}>
                                      :
                                    </Col>
                                    <Col
                                      xxl={16}
                                      xl={16}
                                      lg={16}
                                      md={16}
                                      sm={24}
                                    >
                                      {item[1]}
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </>
                          );
                        });
                      })}
                    </Col>
                  </Row>
                </div>
                <br />
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item>
                      {crPreviewData.map((items) => {
                        return Object.entries(items).map((item) => {
                          return (
                            <>
                              {userRole === "admin" &&
                              item[0] === "is_admin_approved" ? (
                                <Button
                                  className="dev-reject-button"
                                  htmlType="submit"
                                  onClick={handleReject}
                                  disabled={item[1] == 1 ? true : false}
                                >
                                  Reject
                                </Button>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        });
                      })}
                      {crPreviewData.map((items) => {
                        return Object.entries(items).map((item) => {
                          return (
                            <>
                              {userRole === "1ch_admin" &&
                              item[0] === "is_och_approved" ? (
                                <Button
                                  className="dev-reject-button"
                                  htmlType="submit"
                                  onClick={handleReject}
                                  disabled={item[1] == 1 ? true : false}
                                >
                                  Reject
                                </Button>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        });
                      })}

                      {crPreviewData.map((items) => {
                        return Object.entries(items).map((item) => {
                          return (
                            <>
                              {userRole === "admin" &&
                              item[0] === "is_admin_approved" ? (
                                // {userRole === 'admin' ?
                                <Button
                                  type="primary"
                                  className="form-button"
                                  htmlType="submit"
                                  onClick={handleApproveSubmit}
                                  disabled={item[1] == 1 ? true : false}
                                >
                                  Approve
                                </Button>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        });
                      })}
                      {crPreviewData.map((items) => {
                        return Object.entries(items).map((item) => {
                          return (
                            <>
                              {userRole === "1ch_admin" &&
                              item[0] === "is_och_approved" ? (
                                <Button
                                  type="primary"
                                  className="form-button"
                                  htmlType="submit"
                                  onClick={handleApproveSubmit}
                                  disabled={item[1] == 1 ? true : false}
                                >
                                  Approve
                                </Button>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        });
                      })}

                      {/* <Button
                        type="primary"
                        htmlType="submit"
                        className="form-button"
                      // onClick={() => {
                      //   handleApproveSubmit();
                      // }}
                      >
                        {isShowButtonLoader ? (
                          <>
                            <div className="d-flex">
                              <div className="">
                                <span
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span class="sr-only">Loading...</span>
                              </div>
                            </div>
                          </>
                        ) : (
                          " Approve"
                        )}
                      </Button> */}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}></Col>
          </Row>
        </Form>
        <Modal
          okButtonProps={{
            style: {
              background:
                "linear-gradient(261deg, #ff6a2f 26.19%, #ffd380 168.97%)",
              color: "white",
            },
          }}
          className="devops-modal sub-heading-devops-config"
          open={isModalOpen}
          onCancel={handleCancel}
          okText={<span className="reject-btn">Submit</span>}
          cancelText="Cancel"
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                setShowSpinner(true);
                let id,
                  och_approved_by,
                  job_name,
                  configurations,
                  created_by,
                  is_admin_approved,
                  is_och_approved,
                  cx_approval_email;
                crPreviewData.map((item) => {
                  id = item.id;
                  och_approved_by = item.och_approved_by;
                  job_name = item.job_name;
                  let cleanedString = item.configurations.replace(
                    /\\\\/g,
                    "\\"
                  );
                  configurations = JSON.parse(item.configurations);
                  created_by = item.created_by;
                  cx_approval_email = item.cx_approved_by;
                  is_admin_approved = item.is_admin_approved;
                  cx_approval_email = item.cx_approved_by;

                  is_och_approved = item.is_och_approved;
                });
                if (userRole === "1ch_admin") {
                  values.event_type = "och_reject_update_status";
                  dispatch(
                    rejectRequest1CHAdmin({
                      values,
                      id,
                      createdUserEmail,
                      created_by,
                      cx_approval_email,
                      is_och_approved,
                    })
                  )
                    .unwrap()
                    .then(() => {
                      setShowSpinner(false);
                      Modal.success({
                        content: (
                          <>
                            <span style={{ color: "white" }}>
                              The request has been successfully{" "}
                            </span>
                            <span style={{ color: "#be1e2d" }}>REJECTED</span>
                          </>
                        ),
                        onOk: () => {
                          navigate("/devops-requestor");
                        },
                      });
                    });
                } else {
                  values.event_type = "cx_reject_update_status";
                  dispatch(
                    rejectRequest({
                      values,
                      id,
                      createdUserEmail,
                      created_by,
                      is_admin_approved,
                    })
                  )
                    .unwrap()
                    .then(() => {
                      setShowSpinner(false);
                      Modal.success({
                        content: (
                          <>
                            <span style={{ color: "white" }}>
                              The request has been successfully{" "}
                            </span>
                            <span style={{ color: "#be1e2d" }}>REJECTED</span>
                          </>
                        ),
                        onOk: () => {
                          navigate("/devops-requestor");
                        },
                      });
                    });
                }

                setIsModalOpen(false);
              })
              .catch((info) => {});
          }}
        >
          <Form layout="vertical" onFinish={handleSubmit} form={form}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  name="comment"
                  label="Comment"
                  className="comment-form-field"
                  rules={[{ required: true, message: "" }]}
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
          // title="Basic Modal"
          open={isModalOpenApprove}
          onCancel={handleCancel}
          okText={<span className="reject-btn">Submit</span>}
          // okText={
          //   <span className={`reject-btn${userRole === "admin" && is_admin_approved === 1 ? " disabled" : ""}`}>
          //     Submit
          //   </span>}
          cancelText="Cancel"
          okButtonProps={{
            style: {
              background:
                "linear-gradient(261deg, #ff6a2f 26.19%, #ffd380 168.97%)",
              color: "white",
            },
          }}
          className="devops-modal sub-heading-devops-config"
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                setShowSpinner(true);
                let id,
                  och_approved_by,
                  job_name,
                  configurations,
                  created_by,
                  customer_name,
                  comment,
                  is_admin_approved,
                  is_och_approved,
                  cx_approval_email,
                  cloud_platform;
                crPreviewData.map((item) => {
                  id = item.id;
                  comment = values.comment;
                  och_approved_by = item.och_approved_by;
                  job_name = item.job_name;
                  cloud_platform = item.cloud_platform;
                  let cleanedString = item.configurations.replace(
                    /\\\\/g,
                    "\\"
                  );
                  configurations = JSON.parse(item.configurations);
                  customer_name = item.customer_name;
                  is_admin_approved = item.is_admin_approved;
                  is_och_approved = item.is_och_approved;
                  // let customer_name = item.customer_name;
                });
                if (userRole === "1ch_admin") {
                  values.event_type = "trigger_build";
                  dispatch(
                    approveRequestBy1CHAdmin({
                      values,
                      id,
                      job_name,
                      configurations,
                      customer_name,
                      createdUserEmail,
                      comment,
                      is_och_approved,
                      cloud_platform,
                    })
                  )
                    .unwrap()
                    .then(() => {
                      setShowSpinner(false);
                      Modal.success({
                        content: (
                          <>
                            <span style={{ color: "white" }}>
                              The request has been successfully{" "}
                            </span>

                            <span style={{ color: "#3D9D1B" }}>APPROVED</span>
                          </>
                        ),
                        onOk: () => {
                          navigate("/devops-requestor");
                        },
                      });
                    });
                } else {
                  values.event_type = "next_och_approval";
                  dispatch(
                    approveRequest({
                      values,
                      och_approved_by,
                      id,
                      createdUserEmail,
                      customer_name,
                      comment,
                      is_admin_approved,
                      cloud_platform,
                    })
                  )
                    .unwrap()
                    .then(() => {
                      setShowSpinner(false);
                      Modal.success({
                        content: (
                          <>
                            <span style={{ color: "white" }}>
                              The request has been successfully{" "}
                            </span>
                            <span style={{ color: "#3D9D1B" }}>APPROVED</span>
                          </>
                        ),
                        onOk: () => {
                          navigate("/devops-requestor");
                        },
                      });
                    });
                }

                setIsModalOpenApprove(false);
              })
              .catch((info) => {});
          }}
        >
          <Form layout="vertical" onFinish={handleSubmitApprove} form={form}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  className="comment-form-field"
                  name="comment"
                  label="Comment"
                  rules={[{ required: true, message: "" }]}
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    </div>
  );
}

export default ApprovalPreviewForm;
