import { memo, useEffect, useState } from "react";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Typography,
  Tooltip,
  Tag,
  Popconfirm,
  Select,
  message,
} from "antd";
import {
  numberFormat,
  checkRolePermission,
} from "../../../custom_hook/CustomHook";
import TrackingForm from "../../savings_model/TrackingForm";
import {
  getSavingsTracking,
  updaterealisedtable,
} from "../../../action/costimizeV2Active";
import EditAction from "./EditAction";

const { Text } = Typography;
const RecordStatus = (props) => {
  const [statusEditable, setStatusEditable] = useState(true);
  const dispatch = useDispatch();
  const handleStatusEdit = (status, record) => {
    const input = {
      account_names: record.account_names,
      application: record.applications,
      commitment: record.commitment,
      current_configuration: record.current_configuration,
      environment: record.environments,
      potential_savings: record.potential_savings,
      recommendation_rule: record.recommendation_rule,
      recommended_configuration: record.recommended_configuration,
      resource_id: record.resource_id,
      resource_name: record.resource_name,
      resource_sub_category: record.resource_sub_category,
      service: record.services,
      status: status,
      username: record.username,
      targetdate: record.targetdate,
      company_name: localStorage.getItem(
        "CognitoIdentityServiceProvider.company"
      ),
      cloud: record.cloud,
      report_month: record.report_month,
    };
    dispatch(updaterealisedtable({ input }))
      .unwrap()
      .then((data) => {
        const status = data.data.update_savings_plan_recommendation[0].status;

        if (status === "Implementation Completed") {
        }
        message.success("Updated Successfully ");
        props.setRecordsUpdate(!props.recordsupdate);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  return statusEditable ? (
    <Tag color="#2f2f2f" onClick={() => setStatusEditable(!statusEditable)}>
      {props.status}
    </Tag>
  ) : (
    <div>
      <Select
        defaultValue={props.status}
        className="track-table-select"
        popupClassName="track-table-select-dropdown"
        onChange={(value) => {
          setStatusEditable(!statusEditable);
          handleStatusEdit(value, props.record);
        }}
        options={[
          {
            value: "Task Assigned",
            label: "Task Assigned",
          },
          {
            value: "Analysis Pending",
            label: "Analysis Pending",
          },
          {
            value: "Analysis Completed",
            label: "Analysis Completed",
          },
          {
            value: "Implementation Planned",
            label: "Implementation Planned",
          },
          {
            value: "Implementation Completed",
            label: "Implementation Completed",
          },
          {
            value: "Not Implemented",
            label: "Not Implemented",
          },
        ]}
      />
    </div>
  );
};

const TrackedTable = (props) => {
  const {
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedTopdate,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const [drawerstatus, setDrawerStatus] = useState(false);
  const [tablerecords, setTableRecords] = useState([]);
  const [columns, setColumns] = useState([]);
  const [formType, setFormType] = useState("");
  const [oldUpdateRecords, setOldUpdateRecords] = useState({});

  const permission = {
    savingswrite: checkRolePermission("savings_model")
      ? checkRolePermission("savings_model").write
      : 0,
  };
  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const dispatch = useDispatch();

  useEffect(() => {
    var input = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      date: selectedTopdate,
      // date: "all",
      company_name: company_name,
    };
    const getStatusTagColor = (status) => {
      switch (status) {
        case "Task Assigned":
          return "#FF0000";
        case "Analysis Pending":
          return "#FFA500";
        case "Analysis Completed":
          return "#2db7f5";
        case "Implementation Planned":
          return "#0039a6";
        case "Implementation Completed":
          return "#006400";
        case "Not Implemented":
          return "#7F00FF";
        default:
          return "default";
      }
    };
    props.isdefer
      ? setColumns([
          {
            title: "Account",
            dataIndex: "account_names",
            key: "account_names",
            // width: "13%",
            // sorter: (a, b) => {
            //   return a.account > b.account ? -1 : 1;
            // },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 15) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 15)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          {
            title: "Application",
            dataIndex: "applications",
            key: "applications",
            align: "center",
            // width: "13%",
            // sorter: (a, b) => {
            //   return a.applications > b.applications ? -1 : 1;
            // },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 10) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 10)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          {
            title: "Environment",
            dataIndex: "environments",
            key: "environments",
            align: "center",
            // width: "13%",
            // sorter: (a, b) => {
            //   return a.environments > b.environments ? -1 : 1;
            // },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 10) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 10)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          {
            title: "Resource Name",
            key: "resource_name",
            // width: "13%",
            align: "center",
            dataIndex: "resource_name",
            // sorter: (a, b) => {
            //   return a.resource_name > b.resource_name ? -1 : 1;
            // },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 10) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 10)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          {
            title: "Potential Savings",
            dataIndex: "potential_savings",
            key: "potential_savings",
            align: "right",
            // width: "13%",
            sorter: (a, b) => {
              return a.services > b.services ? -1 : 1;
            },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 10) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 10)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          {
            title: "Username",
            dataIndex: "username",
            align: "center",
            key: "username",
            // width: "13%",
          },
          {
            title: "Target Date",
            dataIndex: "targetdate",
            key: "targetdate",
            align: "center",
            // width: "13%",
          },
          {
            title: "Reason",
            dataIndex: "reason",
            key: "reason",
            fixed: "right",
            align: "center",
            // width: "9%",
            render: (record, index) => {
              return (
                <>
                  {record && (
                    <div className="description-popup">
                      <Popconfirm
                        className="description-popup"
                        placement="top"
                        title=""
                        description={record}
                        footer={false}
                        okText=""
                        cancelText=""
                        cancelButtonProps={{ style: { display: "none" } }}
                        okButtonProps={{ style: { display: "none" } }}
                      >
                        <Icon
                          icon="material-symbols-light:description-outline"
                          style={{ fontSize: "23px", cursor: "pointer" }}
                          className="cursor-pointer description-popup-icon"
                        />
                      </Popconfirm>
                    </div>
                  )}
                </>
              );
            },
          },
        ])
      : setColumns([
          {
            title: "Account",
            dataIndex: "account_names",
            key: "account_names",
            // width: "11%",
            align: "center",

            // sorter: (a, b) => {
            //   return a.account > b.account ? -1 : 1;
            // },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 10) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 15)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          {
            title: "Application",
            dataIndex: "applications",
            key: "applications",
            align: "center",
            // width: "11%",
            // sorter: (a, b) => {
            //   return a.applications > b.applications ? -1 : 1;
            // },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 15) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 15)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          {
            title: "Environment",
            dataIndex: "environments",
            key: "environments",
            align: "center",
            // width: "12%",
            // sorter: (a, b) => {
            //   return a.environments > b.environments ? -1 : 1;
            // },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 10) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 10)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          {
            title: "Resource Name",
            key: "resource_name",
            // width: "13%",
            align: "center",
            dataIndex: "resource_name",
            // sorter: (a, b) => {
            //   return a.resource_name > b.resource_name ? -1 : 1;
            // },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 10) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 10)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          {
            title: "Potential Savings",
            dataIndex: "potential_savings",
            key: "potential_savings",
            align: "right",
            // width: "11%",
            sorter: (a, b) => {
              return a.services > b.services ? -1 : 1;
            },
            render: (record) => {
              return (
                <>
                  {/* <div className="number_right"> */}$
                  {record ? numberFormat(record) : 0}
                  {/* </div> */}
                </>
              );
            },
          },
          {
            title: "Assignee",
            dataIndex: "username",
            key: "username",
            align: "center",
            // width: "10%",
          },
          {
            title: "Target Date",
            dataIndex: "targetdate",
            key: "targetdate",
            align: "center",
            // width: "11%",
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center",
            // width: "18%",
            render: (status, index) => (
              <Tag color="#2f2f2f">{status}</Tag>
              // <RecordStatus
              //   status={status}
              //   record={index}
              //   setRecordsUpdate={props.setRecordsUpdate}
              //   recordsupdate={props.recordsupdate}
              // />
            ),
          },
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            fixed: "right",
            align: "center",
            // width: "5%",
            render: (record, index) => {
              if (props.rolestatus) {
                if (permission.savingswrite) {
                  return (
                    <>
                      <div
                        onClick={() => {
                          setFormType("update");
                          setOldUpdateRecords(index);
                          setDrawerStatus(true);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon icon="material-symbols:edit" />
                      </div>
                    </>
                  );
                } else {
                  return <></>;
                }
              } else {
                return <></>;
              }
            },
          },
        ]);
    dispatch(getSavingsTracking({ input }))
      .unwrap()
      .then(({ data }) => {
        var value_list = data.tracking_savingsmodel.map((datum, index) => ({
          key: index + 1,
          ...datum,
        }));
        setTableRecords(value_list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    props.recordsupdate,
    props.isdefer,
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedservices,
    selectedEnvironment,
    selectedTopdate,
    selecteddb,
    selectedinfra,
    selectedos,
  ]);

  return (
    <>
      <EditAction
        drawerstatus={drawerstatus}
        setDrawerStatus={setDrawerStatus}
        oldUpdateRecords={oldUpdateRecords}
        formType={formType}
        recordsupdate={props.recordsupdate}
        setRecordsUpdate={props.setRecordsUpdate}
      />
      <Table
        className="savop-table"
        columns={columns}
        scroll={{
          x: 500,
        }}
        dataSource={tablerecords.filter(function (val) {
          return (
            val.recommendation_rule === props.filter &&
            (props.isdefer
              ? val.status === "Deferred"
              : val.status !== "Deferred")
          );
        })}
        // pagination={(true, { pageSize: 5 })}
        pagination={false}
        summary={(pageData) => {
          const potential = pageData.reduce(
            (a, v) => (a = +a + +v?.potential_savings),
            0
          );

          let val = potential.toFixed(2);
          return (
            <>
              <Table.Summary.Row className="savop-table-footer">
                <Table.Summary.Cell className="d-flex align-items-center justify-content-end">
                  Total:
                  <Text className="white-color" style={{ paddingLeft: "1rem" }}>
                    ${val}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </>
  );
};

export default memo(TrackedTable);
