import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const RealizedChart = (props) => {
  const [chartvalue, setChartValue] = useState({
    lable: [],
    value: [],
  });
  useEffect(() => {
    var value_list = [];

    const tablelist = props.records.map((data) => {
      if (
        data.realised_savings === null ||
        Math.round(data.realised_savings) === 0
      ) {
        const lost_opportunity = data.potential_savings - data.realised_savings;
        value_list.push({
          recommendation_type: data.recommendation_rule,
          potential_savings: Number(data.potential_savings),
        });
      }
    });

    //recommendation type
    const recommendation_list = value_list.map((v) => v.recommendation_type);
    const recommendation_set = new Set([...recommendation_list]);
    const recommendation = [...recommendation_set];
    var chart_lable = [];
    var chart_value = [];

    recommendation.map((data) => {
      const datalist = value_list.filter((datas) => {
        return datas.recommendation_type?.toLowerCase() === data?.toLowerCase();
      });
      const potential = datalist.reduce(
        (a, v) => (a = +a + +v?.potential_savings),
        0
      );
      chart_value.push(Math.round(potential));
      chart_lable.push(data);
    });
    const total = value_list.reduce(
      (a, v) => (a = +a + +v?.potential_savings),
      0
    );
    chart_value.push(Math.round(total));
    chart_lable.push("Total");
    setChartValue({
      lable: chart_lable,
      value: chart_value,
    });

    // console.log(value_list);
    // console.log(recommendation);
  }, [props]);
  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#487b78", "#ffc000", "#0e5772", "#ee7304", "#00b0f0", "#00b050"],
    xaxis: {
      categories: chartvalue.lable,
      labels: {
        formatter: function (num, opts) {
          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
  };

  const series = [
    {
      name: "Potential Savings",
      data: chartvalue.value,
    },
  ];

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={220}
      />
    </>
  );
};

export default RealizedChart;
