import { memo, useEffect } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { profileInfoMethod, updateUsersMethod } from "../../slice/userSlice";

const ProfileInfo = (props) => {
  const navigateFn = useNavigate();
  const { profileInfo, onboardAction, updateuserdetails } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    var records;
    if (onboardAction === "create") {
      records = {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
      };
    } else {
      records = {
        first_name: updateuserdetails.first_name
          ? updateuserdetails.first_name
          : "",
        last_name: updateuserdetails.last_name
          ? updateuserdetails.last_name
          : "",
        email: updateuserdetails.email_id ? updateuserdetails.email_id : "",
        phone_number: updateuserdetails.mobile_number
          ? updateuserdetails.mobile_number
          : "",
      };
    }
    form.setFieldsValue(records);
  }, []);

  const handleSubmit = (values) => {
    dispatch(
      profileInfoMethod({
        ...profileInfo,
        first_name: values.first_name,
        last_name: values.last_name,
        mobile_number: values.phone_number,
        email_id: values.email,
      })
    );
    dispatch(
      updateUsersMethod({
        ...updateuserdetails,
        first_name: values.first_name,
        last_name: values.last_name,
        mobile_number: values.phone_number,
        email_id: values.email,
      })
    );

    props.setCurrent(props.current + 1);
  };
  return (
    <Form layout="vertical" onFinish={handleSubmit} form={form}>
      <Row gutter={[16, 16]}>
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[{ required: true, message: "" }]}
          >
            <Input className="background-antd-color " />
          </Form.Item>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[{ required: true, message: "" }]}
          >
            <Input className="background-antd-color " />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address",
              },
              {
                required: true,
                message: " ",
              },
            ]}
          >
            <Input
              className="background-antd-color "
              disabled={props.display_status}
            />
          </Form.Item>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            name="phone_number"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input type="number" className="background-antd-color " />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item className="create-user-but-d-flex">
            {props.type === "update" ? (
              <>
                <Button
                  htmlType="button"
                  className="float-left"
                  onClick={() => navigateFn("/user-mng")}
                >
                  Back
                </Button>
              </>
            ) : (
              <Button
                htmlType="button"
                className="float-left"
                onClick={() => props.setModelOpen(false)}
              >
                Cancel
              </Button>
            )}

            <Button
              type="primary"
              htmlType="submit"
              className="yes-btn float-right"
            >
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default memo(ProfileInfo);
