import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Col, Card, Checkbox, Button, Switch,Input } from "antd";
const { TextArea } = Input;

const Security = (props) => {
  const [Securitydata, setSecuritydata] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [countNumber, setCountNumber] = useState(0);
  const [description, setDescription] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({});
  const [toggle, setToggle] = useState(true);
  const [message, setMessage] = useState("");

  const choices = JSON.parse(
    Securitydata.find((item) => item.question_id === selectedQuestion)?.choices ||
      "[]"
  );
  
  const choiceid = JSON.parse(
    Securitydata.find((item) => item.question_id === selectedQuestion)?.choiceid ||
      "[]"
  );
  
  const handleChange1 = (event) => {
    setMessage(event.target.value);
  };

  const handleClick = () => {
    setToggle(!toggle);
  };
  
  const pillar_type = 'Performance Efficiency';

  const handleQuestionClick = (questionId) => {
    setSelectedQuestion(questionId);
  };

  // const handleOptionChange = (option) => {
  //   console.log(option)
  //   setOptionsvalue(option)

  //   setSelectedOptions((prevOptions) => ({
  
  //     ...prevOptions,
  //     [selectedQuestion]: {
  //       ...(prevOptions[selectedQuestion] || {}),
  //       [option]: !(prevOptions[selectedQuestion]?.[option] || false),
  //     },
      
  //   }));
 
  // };
  const handleOptionChange = (choiceId) => {
    // Update the state, considering only the choice ID
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [selectedQuestion]: {
        ...prevOptions[selectedQuestion],
        [choiceId]: choiceId,
      },
    }));
  
  };
  
  const isLastQuestion = currentQuestionIndex === Securitydata.length - 1;

  const handleSaveButtonClick = () => {
    setCountNumber((prevCount) => prevCount + 1);
    const optionvalue = selectedOptions[selectedQuestion] ? Object.keys(selectedOptions[selectedQuestion]) : [];
    // console.log(optionvalue)
    const workloadid="42b700bf7c38cbedfc27f087c62e9f36"
    const lensalias = "wellarchitected"

    const input1 = {
      event_type:"question-update",
      workload_id:workloadid,
      lens_alias:lensalias,
      question_id: selectedQuestion,
      selected_choices: optionvalue,
      notes: description,
      is_applicable : toggle
      }
      // useEffect(() => {
      axios
      .post("http://localhost:8000/api/endpoint", input1)
      .then((result) => {
        if (isLastQuestion) {
          props.setRiskCounts(props.riskcounts)
        }          
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  const input = {
    "event_type": "list-question",
    "piller_type": pillar_type
  };

  useEffect(() => {
    axios.post("http://localhost:8000/api/endpoint", input)
      .then((result) => {
        setSecuritydata(result.data);
        // console.log(result.data.choiceid)

      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleNextButtonClick = () => {
    if (currentQuestionIndex < Securitydata.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setSelectedQuestion(Securitydata[currentQuestionIndex + 1]?.question_id);
      setMessage("")
    }
  };

  const handleBackButtonClick = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
      setSelectedQuestion(Securitydata[currentQuestionIndex - 1]?.question_id);
    }
  };

  return (
    <div>
      <Row className="mt-2">
        <h4 className="titletab">Performance Efficiency</h4>
        <Button className="buttonclass">
          {countNumber}/{Securitydata.length}
        </Button>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xl={11} lg={12} md={12}>
          <Card className="operate-cards mt-4 scroll-container">
            <div>
              {Array.isArray(Securitydata) && Securitydata.map((item) => (
                <div className="white-color font-of-qus mt-2" key={item.question_id} onClick={() => handleQuestionClick(item.question_id)}>
                  <div>
                    <span>
                      <h5 className="white-color font-of-qus">
                        <span className="color-orange">{item.question_no}. </span>
                        {item.question_title}
                      </h5>
                    </span>
                    <hr style={{ color: "grey" }} />
                  </div>
                </div>
              ))}
            </div>
          </Card>
        </Col>
        <Col xl={1} lg={1} md={1}></Col>
        <Col xl={11} lg={11} md={11}>
          {selectedQuestion !== null && (
            <Card className="operate-cards mt-4 scroll-container">
              <h5 className="white-color font-of-qus mt-3">
                <span style={{ Color: "color-orange" }}>QUESTION:{Securitydata.find((item) => item.question_id === selectedQuestion)?.question_no.replace(/^Q/, '')}</span>
                <br /><br />
                {Securitydata.find((item) => item.question_id === selectedQuestion)?.question_title}
              </h5>
              <Row><Col>
                <Switch className="war_switch"
                 onClick={handleClick} 
                //  checked={checked} onChange={setChecked}
                 />{" "}
              </Col><Col><p className="color-orange ms-2">This Question is Not Applicable</p></Col></Row>
              <ul>
  {choices.map((choice, index) => (
    <li key={index}>
      <Checkbox
        className="mt-2 check-color"
        onChange={() => handleOptionChange(choiceid[index])}
        checked={selectedOptions[selectedQuestion]?.[choiceid[index]] === choiceid[index]}
      >
        {choice}
      </Checkbox>
    </li>
  ))}
</ul>

            
              <ul>

              </ul>
              <Row>
                  <Col span={24}>
                    <TextArea
                      rows={3}
                      className="textareacls whitecolor"
                      onChange={handleChange1}
                      value={message}
                      placeholder="Message"
                    />
                  </Col>
                </Row>
              <Row className="mt-3" gutter={[16, 16]} style={{ display: "flex", justifyContent: "center" }}>
                <Col>
                  <Button className="buttonclass" onClick={handleBackButtonClick}>
                    BACK
                  </Button>
                </Col>
                <Col>
                <Col>
          <Button
            className={isLastQuestion ? "submitbutton" : "savebutton"}
            onClick={handleSaveButtonClick}
            disabled={!selectedOptions[selectedQuestion] && toggle}
          >
            {isLastQuestion ? "SUBMIT" : "SAVE"}
          </Button>
        </Col>
                </Col>
                <Col>
                  <Button className="buttonclass ms-1" 
                  onClick={handleNextButtonClick}
                  // onClick={(handleNextButtonClick) => setMessage("")}
                  >
                    NEXT
                  </Button>
                </Col>
              </Row>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Security;
