import { Spin, Table } from "antd";
import React, { useState } from "react";
function TcoanalysisTable() {
  const [isShowSpinner, setIsShowSpinner] = useState(true);

  const columns = [
    {
      title: "Cost Component",
      dataIndex: "CostComponent",
      key: "SoCostComponent",
    },
    {
      title: "On-Permises ($/year)",
      dataIndex: "onpermises",
      key: "onpermises",
    },
    {
      title: "Azure On-Demand($/year)",
      dataIndex: "azureondemand",
      key: "azureondemand",
    },
    {
      title: "Azure RI-3yr RI($/year)",
      dataIndex: "Azureari",
      key: "Azureri",
    },
    {
      title: "Azure RI-1yr RI($/year)",
      dataIndex: "Azureri1yr",
      key: "Azureri1yr",
    },
    {
      title: "Azure 8*5 ($/year)",
      dataIndex: "Azure8",
      key: "Azure8",
    },
    {
      title: "Azure 10*5 ($/year)",
      dataIndex: "Azure10",
      key: "Azure10",
    },
    {
      title: "Azure 12*7 ($/year)",
      dataIndex: "Azure12",
      key: "Azure12",
    },
  ];

  const mainData = [
    {
      key: "1",
      CostComponent: "Compute",
      onpermises: "61465.86",
      azureondemand: "475236.2",
      Azureari: "22653.3",
      Azureari1yr: "22653.3",
      Azure8: "22653.3",
      Azure10: "22653.3",
      Azure12: "22653.3",
    },
    {
      key: "2",
      CostComponent: "Compute",
      onpermises: "61465.86",
      azureondemand: "475236.2",
      Azureari: "22653.3",
      Azureari1yr: "22653.3",
      Azure8: "22653.3",
      Azure10: "22653.3",
      Azure12: "22653.3",
    },
    {
      key: "3",
      CostComponent: "Compute",
      onpermises: "61465.86",
      azureondemand: "475236.2",
      Azureari: "22653.3",
      Azureari1yr: "22653.3",
      Azure8: "22653.3",
      Azure10: "22653.3",
      Azure12: "22653.3",
    },
    {
      key: "3",
      CostComponent: "Compute",
      onpermises: "61465.86",
      azureondemand: "475236.2",
      Azureari: "22653.3",
      Azureari1yr: "22653.3",
      Azure8: "22653.3",
      Azure10: "22653.3",
      Azure12: "22653.3",
    },
    {
      key: "4",
      CostComponent: "Compute",
      onpermises: "61465.86",
      azureondemand: "475236.2",
      Azureari: "22653.3",
      Azureari1yr: "22653.3",
      Azure8: "22653.3",
      Azure10: "22653.3",
      Azure12: "22653.3",
    },
    {
      key: "5",
      CostComponent: "Compute",
      onpermises: "61465.86",
      azureondemand: "475236.2",
      Azureari: "22653.3",
      Azureari1yr: "22653.3",
      Azure8: "22653.3",
      Azure10: "22653.3",
      Azure12: "22653.3",
    },
  ];
  return (
    <div>
      <div className="savingtab">
        <Table
          columns={columns}
          dataSource={mainData}
          loading={{ indicator: <Spin />, spinning: isShowSpinner }}
          pagination={false}
        />
      </div>
    </div>
  );
}

export default TcoanalysisTable;
