import { memo, useEffect, useState } from "react";
import { Modal, Row, Col, message, Spin, Tabs } from "antd";
import CPUChart from "./CPUChart";
import MemoryChart from "./MemoryChart";
import { Icon } from "@iconify/react";
import { getAssetSpendDetailsView } from "../../action/costimizeV2Active";
import { useDispatch, useSelector } from "react-redux";

const DetailsView = (props) => {
  const dispatch = useDispatch();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const handleCancel = () => {
    props.setDetailsViewStatus(false);
  };
  const [memorychart, setMemoryChart] = useState({
    memory_max: [],
    memory_min: [],
    memory_avg: [],
  });
  const [cpuchart, setCpuChart] = useState({
    cpu_max: [],
    cpu_min: [],
    cpu_avg: [],
  });
  const [label, setLabel] = useState([]);
  //loader
  const [loader, setLoader] = useState(true);
  const [month, setMonth] = useState("");
  useEffect(() => {
    setLoader(true);
    const resourceid = props.details.resourceid;
    const date = new Date(selectedTopdate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthName = monthNames[month - 1];
    setMonth(monthName);
    const lastDate = `${year}-${month}-${new Date(year, month, 0).getDate()}`;
    const firstDate = `${year}-${month}-${new Date(
      year,
      month - 1,
      1
    ).getDate()}`;

    dispatch(
      getAssetSpendDetailsView({ resourceid, lastDate, firstDate, companyName })
    )
      .unwrap()
      .then(({ data }) => {
        if (data.newfunctionquery077.length > 0) {
          var memory_max = [];
          var memory_min = [];
          var memory_avg = [];
          var cpu_max = [];
          var cpu_min = [];
          var cpu_avg = [];
          var label = [];
          for (
            let index = 0;
            index < new Date(year, month, 0).getDate();
            index++
          ) {
            const date = index + 1;
            const records = data.newfunctionquery077?.filter(
              (datas) => new Date(datas.date_time).getDate() === date
            );

            if (records.length === 0) {
              memory_max.push(0);
              memory_min.push(0);
              memory_avg.push(0);
              cpu_max.push(0);
              cpu_min.push(0);
              cpu_avg.push(0);
              label.push({ index: index + 1, date_time: null });
            } else {
              memory_max.push(Math.round(Number(records[0].maximum_memory)));
              memory_min.push(Math.round(Number(records[0].minimum_memory)));
              memory_avg.push(Math.round(Number(records[0].average_memory)));
              cpu_max.push(Math.round(Number(records[0].maximum_cpu)));
              cpu_min.push(Math.round(Number(records[0].minimum_cpu)));
              cpu_avg.push(Math.round(Number(records[0].average_cpu)));

              label.push({
                index: index + 1,
                date_time: records[0].date_time,
              });
            }
          }

          setMemoryChart({
            memory_max: memory_max,
            memory_min: memory_min,
            memory_avg: memory_avg,
          });
          setCpuChart({
            cpu_max: cpu_max,
            cpu_min: cpu_min,
            cpu_avg: cpu_avg,
          });
          setLabel(label);
          setLoader(false);
        } else {
          setLoader(false);
          message.error("Data Not Found");
          props.setDetailsViewStatus(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        message.error("Data Not Found");
        props.setDetailsViewStatus(false);
        console.log(err.message);
      });
  }, [props]);
  const items = [
    {
      key: "1",
      label: `CPU`,
      children: (
        <div className="popup-chart-cart">
          <CPUChart label={label} value={cpuchart} />
        </div>
      ),
    },
    {
      key: "2",
      label: `Memory`,
      children: (
        <div className="popup-chart-cart">
          <MemoryChart label={label} value={memorychart} />
        </div>
      ),
    },
  ];
  return (
    <>
      <Modal
        title={
          props.details.resourcename
            ? props.details.resourcename
            : "Detailed View"
        }
        open={props.detailsviewstatus}
        onCancel={handleCancel}
        footer={false}
        style={{ textAlign: "center" }}
        width={900}
      >
        <Spin spinning={loader} wrapperClassName="costimize-loader">
          <div style={{ width: "100%" }} className="assets-modal-tabs">
            <Row>
              <Col span={24} className=" asset-modal-date">
                {" "}
                {month ? month : ""}
              </Col>
              <Col span={24}>
                <Tabs
                  type="line"
                  defaultActiveKey="1"
                  items={items}
                  className="assets-tabs"
                  centered
                />
              </Col>
            </Row>
          </div>
          <Row gutter={[30, 30]}>
            {/* <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <div className="popup-chart-cart">
                <CPUChart label={label} value={cpuchart} />
              </div>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <div className="popup-chart-cart">
                <MemoryChart label={label} value={memorychart} />
              </div>
            </Col> */}
          </Row>
        </Spin>
      </Modal>
    </>
  );
};

export default memo(DetailsView);
