import React from "react";
import Billingtable from './Billingtable';
import "../spp/spp.css";


const Sppclient = () => {
    return (
        <>
            <div className='spp_bg'>
                <div className="spp-invoice">
                    <Billingtable/>
                </div>
            </div>
        </>
    )

}
export default Sppclient;   