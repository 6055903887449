
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProductionWindowChart from './ProductionWindowChart';
import ProductionLinuxChart from './ProductionLinuxChart';

const PathManagement = () => {

    return(
        <>
          <Row gutter={[16, 16]}>
                <Col className='path-management-border'  xxl={16} xl={16} lg={16} md={16} sm={24} xs={24}>
                <div className="path-management-div">
                  <h1 className="path-management-title">Production 11</h1>
                </div>
                <div className='path-chart-header'>
                  <ProductionWindowChart />
                </div>  
                <div className='path-chart-header'>
                  <ProductionLinuxChart />
                </div>
              </Col>
              <Col xxl={16} xl={16} lg={16} md={16} sm={24} xs={24}>
                <div className="path-management-div">
                  <h1 className="path-management-title">Non - Production</h1>
                </div>
                <div className='path-chart-header'>
                  <ProductionWindowChart />
                </div>  
                <div className='path-chart-header'>
                  <ProductionLinuxChart />
                </div>
              </Col>
          </Row>
          <div><h4>Performed during July 2023</h4></div>
        </>
    )
}

export default PathManagement;