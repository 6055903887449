import React from "react";
import { Space, Table, Tag } from "antd";
import { Icon } from "@iconify/react";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  PDFViewer,
} from "@react-pdf/renderer";
const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColumns: {
    width: "16.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: "16.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 8,
  },
  tableColleft: {
    width: "16.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
  },
  tableCellright: {
    // margin: "auto",
    marginTop: 5,
    fontSize: 8,
    marginRight: 3,
  },
  tableCellleft: {
    marginTop: 5,
    fontSize: 8,
    marginLeft: 5,
  },
  tableColright: {
    width: "16.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "right",
  },
  // table: {
  //   display: "table",
  //   width: "auto",
  //   borderStyle: "solid",
  //   borderWidth: 1,
  //   borderRightWidth: 0,
  //   borderBottomWidth: 0,
  //   // height: "100%",
  //   borderColor: "#eee",
  // },
  // tableRow: {
  //   margin: "auto",
  //   flexDirection: "row",
  // },
  // tableCol: {
  //   width: "25%",
  //   borderStyle: "solid",
  //   borderWidth: 1,
  //   borderLeftWidth: 0,
  //   borderTopWidth: 0,
  //   borderColor: "#eee",
  // },
  // tableColumns: {
  //   width: "25%",
  //   borderStyle: "solid",
  //   borderWidth: 1,
  //   borderLeftWidth: 0,
  //   borderTopWidth: 0,
  //   borderColor: "#eee",
  //   backgroundColor: "#eee",
  // },
  // tableCell: {
  //   margin: "auto",
  //   marginTop: 5,
  //   fontSize: 10,
  //   padding: 5,
  //   paddingTop: 25,
  //   paddingBottom: 25,
  // },
});
function ApplicationLevelsummaryspendMore({ reportINC, startDates, endDates }) {
  const start_Date = new Date(startDates);
  const end_Date = new Date(endDates);

  const oneWeek = 7 * 24 * 60 * 60 * 1000;

  // Calculate the start and end dates for previous week and pre-previous week
  const prevWeekStartDate = new Date(start_Date.getTime() - oneWeek);
  const prevWeekEndDate = new Date(end_Date.getTime() - oneWeek);

  const prePrevWeekStartDate = new Date(start_Date.getTime() - 2 * oneWeek);
  const prePrevWeekEndDate = new Date(end_Date.getTime() - 2 * oneWeek);
  const threeweekstartDate = new Date(start_Date.getTime() - 3 * oneWeek);
  const threeweekEndDate = new Date(end_Date.getTime() - 3 * oneWeek);

  // Format the dates to strings in the desired format (DD/MM - DD/MM)
  const formatDate = (date) => {
    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];

    // Function to add suffix to day of the month
    const addSuffix = (day) => {
      if (day > 3 && day < 21) return `${day}th`;
      switch (day % 10) {
        case 1:
          return `${day} st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    const formattedDay = addSuffix(day);

    return `${formattedDay} ${month}`;
  };
  const threeweekRange = `${formatDate(threeweekstartDate)} - ${formatDate(
    threeweekEndDate
  )}`;
  const prevWeekRange = `${formatDate(prevWeekStartDate)} - ${formatDate(
    prevWeekEndDate
  )}`;
  const prePrevWeekRange = `${formatDate(prePrevWeekStartDate)} - ${formatDate(
    prePrevWeekEndDate
  )}`;
  const currentWeekRange = `${formatDate(start_Date)}-${formatDate(end_Date)}`;

  const environments = [...new Set(reportINC.map((item) => item.environment))];
  const formatNumberWithCommas = (number) => {
    const roundedNumber = Math.round(number); // Round the number to remove floating point
    return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div>
      {environments.map((environment, index) => (
        <View style={styles.section}>
          <View style={{ flexDirection: "row", marginTop: 5, marginRight: 10 }}>
            <Text style={{ color: "#FF6A2F", fontSize: 12 }}>
              {" "}
              Environment: {environment}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>Application</Text>
              </View>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>{threeweekRange}</Text>
              </View>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>{prePrevWeekRange}</Text>
              </View>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>{prevWeekRange}</Text>
              </View>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>{currentWeekRange}</Text>
              </View>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>Difference</Text>
              </View>
            </View>

            {reportINC
              .filter((item) => item.environment === environment)
              .map((item, dataIndex) => (
                <View style={styles.tableRow}>
                  <View style={styles.tableColleft}>
                    <Text style={styles.tableCellleft}>{item.application}</Text>
                  </View>
                  <View style={styles.tableColright}>
                    <Text style={styles.tableCellright}>
                      {" "}
                      {item.fourth_week > 0 || item.fourth_week < 0
                        ? `$${formatNumberWithCommas(item.fourth_week)}`
                        : "-"}
                    </Text>
                  </View>
                  <View style={styles.tableColright}>
                    <Text style={styles.tableCellright}>
                      {" "}
                      {item.pre_previous_week > 0 || item.pre_previous_week < 0
                        ? `$${formatNumberWithCommas(item.pre_previous_week)}`
                        : "-"}
                    </Text>
                  </View>
                  <View style={styles.tableColright}>
                    <Text style={styles.tableCellright}>
                      {" "}
                      {item.previous_week > 0 || item.previous_week < 0
                        ? `$${formatNumberWithCommas(item.previous_week)}`
                        : "-"}
                    </Text>
                  </View>
                  <View style={styles.tableColright}>
                    <Text style={styles.tableCellright}>
                      {" "}
                      {item.current_week > 0 || item.current_week < 0
                        ? `$${formatNumberWithCommas(item.current_week)}`
                        : "-"}
                    </Text>
                  </View>
                  <View style={styles.tableColright}>
                    <Text style={styles.tableCellright}>
                      {" "}
                      {item.difference > 0
                        ? `$${formatNumberWithCommas(item.difference)}`
                        : item.difference < 0
                        ? `-$${formatNumberWithCommas(
                            item.difference.toString().slice(1)
                          )}`
                        : "-"}
                    </Text>
                  </View>
                </View>
              ))}
          </View>
        </View>
      ))}
    </div>
  );
}

export default ApplicationLevelsummaryspendMore;
