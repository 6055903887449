import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DrawerPlat from "../../properties/Drawer/DrawerPlat";
import { useEffect, useState } from 'react';
import { message, Form, Input, Button, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { capitalizeFirst } from "../../custom_hook/CustomHook";
import { addRtoRop, updateRtoRop, deleteRtoRpo } from "../../action/costimizev2DashboardAction";

const RtoRpoForm = (props) => {
    const [form] = Form.useForm();
    const [buttonloading, setButtonLoading] = useState(false);
    const dispatch = useDispatch();
    // const { companyName } = useSelector((state) => state.user);
    const companyName = localStorage.getItem("CognitoIdentityServiceProvider.company");
    const { applicationList } = useSelector((state) => state.constimize);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
    const [allapplication, setAllapplication] = useState(applicationList.map((list) => {
        return {
          value: list,
          label: capitalizeFirst(list),
        }
      }));
    useEffect(() => {
        form.setFieldsValue(props.oldrecords);
    },[props])

    const handleSubmit = (value) => {
        setButtonLoading(true);
        const input = {
            application: value.application, 
            company_name: companyName, 
            environment: value.environment, 
            instance_name: value.instance_name, 
            report_month: props.oldrecords.date ? `${new Date(props.oldrecords.date).getFullYear()}-${new Date(props.oldrecords.date).getMonth() + 1}-01` :`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`, 
            rpo: value.rpo, 
            rto: value.rto
        }
        if(props.deletestatus){
            const key = props.oldrecords.key;
            dispatch(updateRtoRop({input, key}))
            .unwrap()
            .then(({ data }) => {
                message.success('Record Updated');
                props.setUpdateStatus(!props.updatestatus);
                props.setDrawerStatus(false);
                setButtonLoading(false);  
            })
            .catch(err => {
                console.log(err.message);
                message.error(err.message);
                setButtonLoading(false);
            });
        }else{
            dispatch(addRtoRop({input}))
            .unwrap()
            .then(({ data }) => {
                message.success('New Records Added');
                props.setUpdateStatus(!props.updatestatus);
                props.setDrawerStatus(false);
                setButtonLoading(false);
                
            })
            .catch(err => {
                console.log(err);
                message.error(err.message);
                setButtonLoading(false);
            });
        }
    }   
    const rowDelete = () => { 
        setConfirmLoading(true);
        const key = props.oldrecords.key;
        dispatch(deleteRtoRpo({key, companyName}))
        .unwrap()
        .then((data) => {
            message.success('Deleted');
            props.setUpdateStatus(!props.updatestatus);
            props.setDrawerStatus(false);
            setButtonLoading(false);
            setConfirmLoading(false);
        })
        .catch(err => {
            console.log(err.message);
            message.error(err.message);
            setConfirmLoading(false);
        });  
    }
    return(
        <>
            <DrawerPlat 
                drawerstatus = {props.drawerstatus}
                changeDrawerStatus = {props.changeDrawerStatus}
                deletestatus = {props.deletestatus}
                setUpdateStatus = {props.setUpdateStatus}
                updatestatus = {props.updatestatus}
                setDrawerStatus= {props.setDrawerStatus}
                oldrecords = {props.oldrecords}
                title={`RTO and RPO (${props.oldrecords && props.oldrecords.environment})`}
                confirmLoading = {confirmLoading}
                rowDelete={rowDelete}
                setDrawerDeleteStatus={setDrawerDeleteStatus}
            >
                <Form 
                    layout="vertical"
                    onFinish={handleSubmit}
                    form = {form}
                >
                    <Row>
                        <Col>
                            <Form.Item
                                    name="environment"
                                    label="Environment"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    
                                    <Input 
                                        style={{
                                            width: "100%",
                                        }}
                                        disabled
                                    />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item
                                    name="application"
                                    label="Application"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    
                                    <Select
                                        showSearch
                                        style={{
                                        width: "100%",
                                        }}
                                        placeholder="Select"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={allapplication}
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                    name="instance_name"
                                    label="Instance Name"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    
                                    <Input 
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item
                                    name="rto"
                                    label="RTO"
                                    rules={[{ required: true, message: '', pattern: new RegExp(/^[0-9]+$/) }]}
                                >
                                    
                                    <Input 
                                        suffix="Mins"
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                    name="rpo"
                                    label="RPO"
                                    rules={[{ required: true, message: '', pattern: new RegExp(/^[0-9]+$/) }]}
                                >
                                    
                                    <Input 
                                        suffix="Hrs"
                                        style={{
                                            width: "100%",
                                        }}
                                    />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item >
                            <Button htmlType="button"  onClick={() => props.changeDrawerStatus(false)}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit" style={{ background: "#1b5f9d", color: "#fff", float: "right" }} loading = {buttonloading}>
                                Submit
                            </Button>

                        </Form.Item>
                    </Row>  
                </Form>

            </DrawerPlat>
        </>
    )
}

export default RtoRpoForm;