import { gql } from "@apollo/client";

// const ENVIRONMENT_LIST = gql`query MyQuery($company_name: String!) {
//     distinctenvironment(company_name: $company_name) {
//       environment
//     }
//   }`;

const ENVIRONMENT_LIST = gql`
  query MyQuery($company_name: String!, $month: String!) {
    newfunctionquery1(company_name: $company_name, month: $month) {
      account_name
      application
      cloud
      environment
      db
      infra
      os
      service
    }
  }
`;

const APPLICATIONS_LIST = gql`
  query MyQuery($company_name: String!) {
    distinctapplication(company_name: $company_name) {
      application
    }
  }
`;

const ACCOUNT_LIST = gql`
  query MyQuery($company_name: String!) {
    distinctaccount(company_name: $company_name) {
      account_name
    }
  }
`;

const SELECTED_ENVIRONMENT_LIST = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $month: String!
    $company_name: String!
    $cloud: String!
  ) {
    listenvironment(
      account_name: $account_name
      application: $application
      company_name: $company_name
      month: $month
      cloud: $cloud
    ) {
      environment
    }
  }
`;

const SELECTED_APPLICATION_LIST = gql`
  query MyQuery(
    $account_name: String!
    $environment: String!
    $month: String!
    $company_name: String!
    $cloud: String!
  ) {
    listapplication(
      account_name: $account_name
      company_name: $company_name
      environment: $environment
      month: $month
      cloud: $cloud
    ) {
      application
    }
  }
`;

const SELECTED_ACCOUNT_LIST = gql`
  query MyQuery(
    $application: String!
    $environment: String!
    $month: String!
    $company_name: String!
    $cloud: String!
  ) {
    listaccount(
      application: $application
      company_name: $company_name
      environment: $environment
      month: $month
      cloud: $cloud
    ) {
      account_name
    }
  }
`;

const SERVICES_LIST = gql`
  query MyQuery($company_name: String!) {
    distinctService(company_name: $company_name) {
      service
    }
  }
`;

const OS_LIST = gql`
  query MyQuery($company_name: String!) {
    distinctOs(company_name: $company_name) {
      operating_system
    }
  }
`;

const INFRA_LIST = gql`
  query MyQuery($company_name: String!) {
    distinctInfra(company_name: $company_name) {
      infra
    }
  }
`;

const DB_LIST = gql`
  query MyQuery($company_name: String!) {
    distinctDB(company_name: $company_name) {
      db
    }
  }
`;

const DB_SELECTED_LIST = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $environment: String!
    $month: String!
    $service: String!
    $infra: String!
  ) {
    DBdropdown(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      environment: $environment
      infra: $infra
      month: $month
      service: $service
    ) {
      db
    }
  }
`;

const OS_SELECTED_LIST = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $environment: String!
    $Infra: String!
    $month: String!
    $service: String!
  ) {
    OSdropdown(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      environment: $environment
      Infra: $Infra
      month: $month
      service: $service
    ) {
      operating_system
    }
  }
`;

const INFRA_SELECTED_LIST = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $environment: String!
    $month: String!
    $service: String!
    $operating_system: String!
  ) {
    Infradropdown(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      environment: $environment
      month: $month
      service: $service
      operating_system: $operating_system
    ) {
      infra
    }
  }
`;
const SERVICES_SELECTED_LIST = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $environment: String!
    $month: String!
    $db: String!
    $os: String!
    $infra: String!
  ) {
    servicedropdown(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      db: $db
    ) {
      service
    }
  }
`;

const ASSET_AND_SPEND_LIST = gql`
  query MyQuery($company_name: String!) {
    allassetspend(company_name: $company_name) {
      account_name
      cloud
      application
      environment
      month
      service
      os
      infra
      count
      spend
    }
  }
`;

const SUMMARY_DASHBOARD_OLD = gql`
  query MyQuery($company_name: String!, $month: String!) {
    allsummaryapi(company_name: $company_name, month: $month) {
      report_month
      actual_spend
      projected_spend
      actual_spend_diff
      projected_spend_diff
    }
  }
`;
export const GET_DAILY_WISE_SPEND_API_DATA_QG_QUERY = gql`
  query last30daystrendchart_qg(
    $event_type: String!
    $screen: String!
    $environment: String!
    $account_name: String!
    $application: String!
    $company: String!
    $cloud: String!
    $db: String!
    $infra: String!
    $date: String!
    $os: String!
    $service: String!
  ) {
    last30daystrendchart_qg(
      event_type: $event_type
      screen: $screen
      environment: $environment
      account_name: $account_name
      application: $application
      company: $company
      cloud: $cloud
      db: $db
      infra: $infra
      date: $date
      os: $os
      service: $service
    )
  }
`;

export const GET_MONTHLY_TREND_CHART_QG_QUERY = gql`
  query monthlytrendchart_qg(
    $event_type: String!
    $screen: String!
    $environment: String!
    $account_name: String!
    $application: String!
    $company: String!
    $cloud: String!
    $db: String!
    $infra: String!
    $month: String!
    $os: String!
    $service: String!
  ) {
    monthlytrendchart_qg(
      event_type: $event_type
      screen: $screen
      environment: $environment
      account_name: $account_name
      application: $application
      company: $company
      cloud: $cloud
      db: $db
      infra: $infra
      month: $month
      os: $os
      service: $service
    )
  }
`;
export const GET_BUDGET_API_DATA_GRAPH_QUERY = gql`
  query budget_qg(
    $event_type: String!
    $screen: String!
    $month: String!
    $cloud: String!
    $company: String!
  ) {
    budget_qg(
      event_type: $event_type
      screen: $screen
      month: $month
      cloud: $cloud
      company: $company
    )
  }
`;
export const GET_CREDIT_TREND_API_DATA_GRAPH_QUERY = gql`
  query credittrend(
    $event_type: String!
    $screen: String!
    $environment: String!
    $account_name: String!
    $application: String!
    $company: String!
    $cloud: String!
    $db: String!
    $infra: String!
    $month: String!
    $os: String!
    $service: String!
  ) {
    credittrend(
      event_type: $event_type
      screen: $screen
      environment: $environment
      account_name: $account_name
      application: $application
      company: $company
      cloud: $cloud
      db: $db
      infra: $infra
      month: $month
      os: $os
      service: $service
    )
  }
`;
export const GET_FORECAST_API_GRAPH_QUERY = gql`
  query forecasttrend(
    $event_type: String!
    $screen: String!
    $account_name: String!
    $company: String!
    $cloud: String!
    $month: String!
  ) {
    forecasttrend(
      event_type: $event_type
      screen: $screen
      account_name: $account_name

      company: $company
      cloud: $cloud

      month: $month
    )
  }
`;
export const USAGE_FOR_THE_MONTH_TREND_GRAPH_QUERY = gql`
  query usageforthemonthtrend(
    $event_type: String!
    $screen: String!
    $environment: String!
    $account_name: String!
    $application: String!
    $company: String!
    $cloud: String!
    $db: String!
    $infra: String!
    $month: String!
    $os: String!
    $service: String!
  ) {
    usageforthemonthtrend(
      event_type: $event_type
      screen: $screen
      environment: $environment
      account_name: $account_name
      application: $application
      company: $company
      cloud: $cloud
      db: $db
      infra: $infra
      month: $month
      os: $os
      service: $service
    )
  }
`;
export const USAGE_FOR_THE_MONTH_TYPE_INFO_GRAPH_QUERY = gql`
  query usageforthemonthtypeinfo(
    $event_type: String!
    $screen: String!
    $environment: String!
    $account_name: String!
    $application: String!
    $company: String!
    $cloud: String!
    $db: String!
    $infra: String!
    $month: String!
    $os: String!
    $service: String!
  ) {
    usageforthemonthtypeinfo(
      event_type: $event_type
      screen: $screen
      environment: $environment
      account_name: $account_name
      application: $application
      company: $company
      cloud: $cloud
      db: $db
      infra: $infra
      month: $month
      os: $os
      service: $service
    )
  }
`;
export const USAGE_FOR_THE_MONTH_INFO_TABLE_GRAPH_QUERY = gql`
  query usageforthemonthinfo(
    $event_type: String!
    $screen: String!
    $environment: String!
    $account_name: String!
    $application: String!
    $company: String!
    $cloud: String!
    $db: String!
    $infra: String!
    $month: String!
    $os: String!
    $service: String!
  ) {
    usageforthemonthinfo(
      event_type: $event_type
      screen: $screen
      environment: $environment
      account_name: $account_name
      application: $application
      company: $company
      cloud: $cloud
      db: $db
      infra: $infra
      month: $month
      os: $os
      service: $service
    )
  }
`;
export const BUDGET_VS_SPEND_CARD_GRAPH_QUERY = gql`
  query budget_vs_spend_cloudwise(
    $account: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $month: String!
    $company_name: String!
    $db: String!
    $infra: String!
    $os: String!
    $service: String!
  ) {
    budget_vs_spend_cloudwise(
      account_name: $account
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      clouds
      reportmonth
      spend
    }
  }
`;
export const QG_BUDGET_SPEND_TREND_GRAPH_QUERY = gql`
  query qgbudgetspendtrend(
    $account: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $month: String!
    $company_name: String!
    $db: String!
    $infra: String!
    $os: String!
    $service: String!
  ) {
    qgbudgetspendtrend(
      account_name: $account
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      clouds
      month
      spend
      budget
    }
  }
`;

export const USAGE_FOR_THE_MONTH_QUERY = gql`
  query usageforthemonth(
    $event_type: String!
    $screen: String!
    $environment: String!
    $account_name: String!
    $application: String!
    $company: String!
    $cloud: String!
    $db: String!
    $infra: String!
    $month: String!
    $os: String!
    $service: String!
  ) {
    usageforthemonth(
      event_type: $event_type
      screen: $screen
      environment: $environment
      account_name: $account_name
      application: $application
      company: $company
      cloud: $cloud
      db: $db
      infra: $infra
      month: $month
      os: $os
      service: $service
    )
  }
`;

const SUMMARY_DASHBOARD = gql`
  query MyQuery(
    $account: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $month: String!
    $company_name: String!
    $db: String!
    $infra: String!
    $os: String!
    $service: String!
  ) {
    newfunctionquery3(
      account_name: $account
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      reportmonth
      spend
      cloud
      projected_spend
      actualspend_previous_month_dif
      projectedspend_previous_month_diff
    }
  }
`;

const CREDIT_CHART = gql`
  query MyQuery(
    $account: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $month: String!
    $company_name: String!
    $db: String!
    $infra: String!
    $os: String!
    $service: String!
  ) {
    newfunctionquery4(
      account_name: $account
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      reportmonth
      spend
    }
  }
`;

const UNTAGGEDRESOURCES_DASHBOARD = gql`
  query MyQuery(
    $account: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $month: String!
    $company_name: String!
    $db: String!
    $infra: String!
    $os: String!
    $service: String!
  ) {
    newfunctionquery2(
      account_name: $account
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      count_untagged_resource
      tagging_details
      services
    }
  }
`;

const TOTAL_SPEND_TRAND_DASHBOARD = gql`
  query MyQuery($company_name: String!, $month: String!) {
    monthwisedata(company_name: $company_name, month: $month) {
      month
      spend
    }
  }
`;

const CHART_MONTH_SPEND_DASHBOARD = gql`
  query MyQuery(
    $account: String!
    $application: String!
    $cloud: String!
    $company_name: String!
    $environment: String!
    $month: String!
    $db: String!
    $os: String!
    $infra: String!
    $service: String!
  ) {
    newfunctionquery(
      account_name: $account
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      environments
      spend
    }
  }
`;

const ALL_SAVING_MODEL_DASHBOARD = gql`
  query MyQuery($company_name: String!) {
    allsavingsview(company_name: $company_name) {
      actual_savings
      application
      environment
      potential_savings
      pricing_model
      spend
      account_name
    }
  }
`;

const APPLICATION_CHART_SPEND_DASHBOARD = gql`
  query MyQuery(
    $account: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $month: String!
    $company_name: String!
    $db: String!
    $infra: String!
    $os: String!
    $service: String!
  ) {
    newfunctionquery(
      account_name: $account
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      applications
      spend
    }
  }
`;

const FILTER_SAVING_MODEL_DASHBOARD_OLD = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $month: String!
    $company_name: String!
  ) {
    SavingmodeldetailApi(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      environment: $environment
      month: $month
    ) {
      account_name
      actual_savings
      application
      environment
      potential_savings
      pricing_model
      spend
    }
  }
`;

const FILTER_SAVING_MODEL_DASHBOARD = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $month: String!
    $company_name: String!
    $db: String!
    $infra: String!
    $os: String!
    $service: String!
  ) {
    newfunctionquery6(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      account_names
      applications
      environments
      actual_savings
      potential_savings
      spend
      pricing_model
    }
  }
`;

const FILTER_ASSET_SPEND_DASHBOARD = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $month: String!
    $company_name: String!
    $db: String!
    $infra: String!
    $os: String!
    $service: String!
  ) {
    newfunctionquery(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      account_names
      applications
      environments
      all_cloud
      count
      reportmonth
      services
      dbaas
      operating_system
      infras
      spend
      second_month_spend
    }
  }
`;

const ASSETS_DB_CHART = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $month: String!
    $company_name: String!
    $db: String!
    $infra: String!
    $os: String!
    $service: String!
  ) {
    assetspend_dbaas_graph(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      os: $os
      service: $service
    ) {
      environments
      account_names
      applications
      all_cloud
      customer
      spend
      first_month_spend
      second_month_spend
      third_month_spend
    }
  }
`;
const ASSETS_DB_CHART_NEW = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $month: String!
    $company_name: String!
    $db: String!
    $infra: String!
    $service: String!
    $resourcename: String!
  ) {
    assetspend_dbaas_spend_trend(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      db: $db
      environment: $environment
      infra: $infra
      month: $month
      service: $service
      resourcename: $resourcename
    ) {
      customer
      applications
      environments
      clouds
      resourcename
      first_month_spend
      second_month_spend
      third_month_spend
    }
  }
`;
// const FILTER_ASSET_SPEND_RDS = gql`
//   query MyQuery(
//     $account_name: String!
//     $application: String!
//     $cloud: String!
//     $environment: String!
//     $report_month: String!
//     $company: String!
//     $infra: String!
//     $os: String!
//     $service: String!
//   ) {
//     assetspend_resource_rds(
//       account_name: $account_name
//       application: $application
//       cloud: $cloud
//       company: $company
//       environment: $environment
//       infra: $infra
//       report_month: $report_month
//       os: $os
//       service: $service
//     ) {
//       account_name
//       environment
//       application
//       cloud
//       resourceid
//       ram
//       core
//       instancetype
//       storage
//       billingperiodstartdate
//       spend
//     }
//   }
// `;

const FILTER_ASSET_SPEND_RDS = gql`
  query MyQuery(
    $application: String!
    $cloud: String!
    $company_name: String!
    $environment: String!
    $infra: String!
    $os: String!
    $report_month: String!
    $service: String!
    $account_name: String!
    $db: String!
  ) {
    assetspend_rds_resource(
      application: $application
      cloud: $cloud
      company_name: $company_name
      environment: $environment
      infra: $infra
      os: $os
      report_month: $report_month
      service: $service
      account_name: $account_name
      db: $db
    ) {
      account_name
      environment
      application
      cloud
      resourcename
      resourceid
      ram
      core
      instancetype
      billingperiodstartdate
      spend
    }
  }
`;

const ASSET_SUMMARY_DASHBOARD = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $month: String!
    $company_name: String!
    $service: String!
  ) {
    quickglancetable(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      environment: $environment
      month: $month
      service: $service
    ) {
      asset_type
      core
      count
      ram
      storage
    }
  }
`;

const PROJECTED_SPEND = gql`
  query MyQuery($account: String!, $month: String!, $company_name: String!) {
    newfunctionquery8(
      account_name: $account
      company_name: $company_name
      month: $month
    ) {
      account_names
      projected_spend
      reportmonth
    }
  }
`;

const START_STOP_SCHEDULER_DASHBOARD = gql`
  query MyQuery($cloud: String!, $company_name: String!, $month: String!) {
    newfunctionquery17(
      cloud: $cloud
      company_name: $company_name
      usagestartdate: $month
    ) {
      jobid
      status
      usagedate
    }
  }
`;

const UNDER_UTILIZED = gql`
  query MyQuery($cloud: String!, $company_name: String!, $month: String!) {
    newfunctionquery23(
      cloud: $cloud
      company_name: $company_name
      report_month: $month
    ) {
      underutilized_resources
      total_resources
      date
    }
  }
`;
const FILTER_KUBERNETES_SPEND = gql`
  query MyQuery(
    $account_name: String!
    $application: String!
    $cloud: String!
    $environment: String!
    $month: String!
    $company_name: String!
    $infra: String!
    $service: String!
  ) {
    newfunctionquery36(
      account_name: $account_name
      application: $application
      cloud: $cloud
      company_name: $company_name
      environment: $environment
      infra: $infra
      month: $month
      service: $service
    ) {
      application
      environment
      count
      cloud
      spend
      savings_opportunity
    }
  }
`;

export {
  ENVIRONMENT_LIST,
  APPLICATIONS_LIST,
  ACCOUNT_LIST,
  SELECTED_ENVIRONMENT_LIST,
  SELECTED_APPLICATION_LIST,
  SELECTED_ACCOUNT_LIST,
  SERVICES_LIST,
  OS_LIST,
  INFRA_LIST,
  OS_SELECTED_LIST,
  INFRA_SELECTED_LIST,
  ASSET_AND_SPEND_LIST,
  SUMMARY_DASHBOARD,
  UNTAGGEDRESOURCES_DASHBOARD,
  TOTAL_SPEND_TRAND_DASHBOARD,
  CHART_MONTH_SPEND_DASHBOARD,
  ALL_SAVING_MODEL_DASHBOARD,
  APPLICATION_CHART_SPEND_DASHBOARD,
  FILTER_SAVING_MODEL_DASHBOARD,
  SERVICES_SELECTED_LIST,
  DB_LIST,
  DB_SELECTED_LIST,
  FILTER_ASSET_SPEND_DASHBOARD,
  ASSET_SUMMARY_DASHBOARD,
  CREDIT_CHART,
  PROJECTED_SPEND,
  START_STOP_SCHEDULER_DASHBOARD,
  UNDER_UTILIZED,
  FILTER_KUBERNETES_SPEND,
  FILTER_ASSET_SPEND_RDS,
  ASSETS_DB_CHART,
  ASSETS_DB_CHART_NEW,
};
