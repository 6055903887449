import { Button, DatePicker, Row, Select, Spin, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import export_logo from "./../../assets/images/cams/excel_logo.png";
import moment from "moment";
import { useDispatch } from "react-redux";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  getReportData,
  getReportsPages,
} from "../action/graphql/cams/cams_reducers";
import { saveAs } from "file-saver";

const CamsReport = (customer) => {
  const options = [
    {
      label: "SA Processed",
      value: "cams_processed",
    },
    {
      label: "All Alerts",
      value: "all_alerts",
    },
  ];
  const tempDate = new Date();
  // const defaultDate = moment(tempDate, 'YYYY-MM-DD');
  // const [selectedDate, setSelectedDate] = useState(moment(tempDate).format("YYYY-MM-DD"));
  const [selectedDate, setSelectedDate] = useState();
  const dispatch = useDispatch();
  const [tableDisplayData, setTableDisplayData] = useState([]);
  const [readyForExport, setReadyForExport] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [loading, setLoading] = useState(false);
  function convertToCSV(data) {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((obj) => Object.values(obj).join(","));
    return `${header}\n${rows.join("\n")}`;
  }

  function downloadCSV(excelrecords, date, type) {
    const data = excelrecords;
    const filename = `${type}.csv`;
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, filename);
    message.success("Report Downloaded");
  }
  const { RangePicker } = DatePicker;
  var tempDataArray = [];

  const getTableData = (startDate, endDate, currentPage, totalPages) => {
    let currTemp = currentPage + 1;
    let totalTemp = totalPages;

    dispatch(
      getReportData({
        company_name: customer.customer,
        start_date: startDate,
        end_date: endDate,
        page_size: 100,
        alert_type: type,
        page_count: currentPage,
      })
    )
      .unwrap()
      .then(({ data }) => {
        console.log(data, "alertdetails");
        tempDataArray.push(...data.cams_report_tab_main_table);
        console.log(tempDataArray);
        currentPage = currentPage + 1;
        setTableDisplayData(tempDataArray);
        setReadyForExport(true);
        setLoading(false);
        setShowSpinner(false);
      });
  };

  const generateReports = () => {
    if (selectedDate && selectedDate.startDate && selectedDate.endDate) {
      const { startDate, endDate } = selectedDate;

      setLoading(true);
      setTableDisplayData([]);
      setShowSpinner(true);
      tempDataArray = [];
      dispatch(
        getReportsPages({
          company_name: customer.customer,
          start_date: startDate,
          end_date: endDate,
          count: 100,
          alert_type: type,
        })
      )
        .unwrap()
        .then(({ data }) => {
          var totalPages = Number(
            data.cams_report_tab_table_pagesize[0].total_pages
          );
          var currentPage = 1;
          getTableData(startDate, endDate, currentPage, totalPages);
        });
    } else {
      message.error("Please Select Date Range");
    }
  };
  const [type, setType] = useState("all_alerts");
  const [reportData, setReportData] = useState([]);
  const columns = [
    {
      title: "Alert Type",
      key: "alert_type",
      dataIndex: "alert_type",
      sorter: (a, b) => (a.alert_type > b.alert_type ? -1 : 1),
      className: "devops-table-header",
      align: "center",
      defaultSortOrder: "ascend",
    },

    {
      title: "From Address",
      dataIndex: "from_address",
      key: "from_address",
      sorter: (a, b) => (a.from_address > b.from_address ? -1 : 1),
      className: "devops-table-header",
      align: "center",
    },
    {
      title: "Server Name",
      key: "server_name",
      dataIndex: "server_name",
      sorter: (a, b) => (a.server_name > b.server_name ? -1 : 1),
      className: "devops-table-header",
      align: "center",
    },
    {
      title: "Severity level",
      dataIndex: "severity",
      key: "severity",
      sorter: (a, b) => (a.severity > b.severity ? -1 : 1),
      className: "devops-table-header",
      align: "center",
    },
    {
      title: "Subject",
      key: "subject",
      dataIndex: "subject",
      sorter: (a, b) => (a.subject > b.subject ? -1 : 1),
      className: "devops-table-header",
      align: "center",
    },
    {
      title: "To Address",
      dataIndex: "to_address",
      key: "to_address",
      sorter: (a, b) => (a.to_address > b.to_address ? -1 : 1),
      className: "devops-table-header",
      align: "center",
      render: (cell, row) => {
        const split_records = cell.split("(");
        if (split_records.length >= 2) {
          const replace_records_1 = cell.replace("(", "");
          const replace_records_2 = replace_records_1.replace(")", "");
          const mail_list = replace_records_2.split(",");
          const mail_list_html = mail_list.map((data) => {
            return (
              <>
                {data} <br></br>
              </>
            );
          });
          return mail_list_html;
        } else {
          return cell;
        }
      },
    },
    {
      title: "Date",
      key: "date_column",
      dataIndex: "date_column",
      align: "center",
      sorter: (a, b) => (a.date > b.date ? -1 : 1),
      className: "devops-table-header",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      sorter: (a, b) => (a.time > b.time ? -1 : 1),
      className: "devops-table-header",
      align: "center",
    },
  ];

  const disabledDate = (current) => {
    // Disable all dates after today
    return current && current > moment().endOf("day");
  };

  // const handleDateChange = (date, dateString) => {
  //   const formattedDate = moment(dateString).format("YYYY-MM-DD");

  //   setSelectedDate(formattedDate);
  //   // if(formattedDate !== "Invalid date"){
  //   // }
  // };
  const handleDateChange = (dates, dateStrings) => {
    const [startDate, endDate] = dateStrings.map((dateString) =>
      moment(dateString).format("YYYY-MM-DD")
    );
    setSelectedDate({ startDate, endDate });
    console.log({ startDate, endDate });
  };

  const downloadReport = () => {
    if (tableDisplayData.length > 0) {
      downloadCSV(tableDisplayData, selectedDate, type);
    } else {
      message.error("No data to export");
    }
  };
  return (
    <>
      <div className="h-100 cams-content  mb-5">
        <Row gutter={[16, 16]}>
          <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
            <div className="d-flex justify-content-end align-items-center mr-3 ">
              <div className="cams-date">
                <RangePicker
                  className="datepicker"
                  onChange={handleDateChange}
                />
                {/* <DatePicker
                  className="datepicker"
                  disabledDate={disabledDate}
                  // defaultValue={dayjs(selectedDate, "YYYY-MM-DD")}
                  format={"YYYY-MM-DD"}
                  onChange={handleDateChange}
                /> */}
              </div>
              <Select
                defaultValue={type}
                options={options}
                dropdownMatchSelectWidth={false}
                onChange={(val) => {
                  setType(val);
                }}
              />

              <Button
                className="yes-button"
                onClick={!loading ? generateReports : undefined}
              >
                Submit
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="alert-detail-table-reports reports-table d-block">
            {showSpinner ? (
              <Spin className="reports-spinner" />
            ) : (
              <>
                <div
                  className="export d-flex justify-content-end"
                  onClick={downloadReport}
                >
                  <span>
                    <img
                      className="ml-3 cursor-pointer"
                      alt="Export"
                      src={export_logo}
                    />
                    Export
                  </span>
                </div>
                <div className="alert-detail-table2-width1">
                  {/* <BootstrapTable
                    keyField="id"
                    data={tableDisplayData}
                    columns={columns}
                    bordered={false}
                    noDataIndication="No Records"
                    // pagination={paginationFactory()}
                  /> */}
                  <Table
                    className="devops_Table"
                    columns={columns}
                    dataSource={tableDisplayData}
                    pagination={{ pageSize: 5 }}
                    // loading={{ indicator: <Spin />, spinning: showSpinner }}
                  />
                </div>
              </>
            )}
          </div>
        </Row>
      </div>
    </>
  );
};
export default CamsReport;
