import { memo, useState } from "react";
import DrawerPlat from "../../properties/Drawer/DrawerPlat";
import { Table, Modal } from "antd";

const TaggingBestPractice = (props) => {
  const [tablerecords, setTableRecords] = useState([
    {
      tag_label: "SecurityLevel",
      examples: "High, Medium, Low",
      purpose: "Indicates the security level of the resource",
    },
    {
      tag_label: "DataClassification",
      examples: "Confidential, Restricted, Public",
      purpose: "Classifies the sensitivity level of the data",
    },
    {
      tag_label: "ComplianceType",
      examples: "HIPAA, GDPR, PCI-DSS, SOX",
      purpose:
        "Identifies the applicable compliance framework for the resource",
    },
    {
      tag_label: "ComplianceStatus",
      examples: "Compliant, Non-compliant, In-progress",
      purpose: "Tracks the compliance status of the resource",
    },
    {
      tag_label: "DataSensitivity",
      examples: "Personal, Proprietary, Public",
      purpose: "Determines the sensitivity of the data stored or processed",
    },
    {
      tag_label: "DataRetention",
      examples: "Short-term, Long-term, Indefinite",
      purpose: "Specifies the retention period for the data",
    },
    {
      tag_label: "Environment",
      examples: "Development, Testing, Production",
      purpose: "Identifies the environment in which the resource is deployed",
    },
    {
      tag_label: "DeploymentZone",
      examples: "Public, Private, Hybrid",
      purpose: "Specifies the deployment zone for the resource",
    },
    {
      tag_label: "CostCenter",
      examples: "Marketing, IT, Finance, Sales",
      purpose: "Attributes costs to the respective department or team",
    },
    {
      tag_label: "BudgetCategory",
      examples: "Operational, Capital, Research",
      purpose: "Categorizes expenses based on budget allocation",
    },
    {
      tag_label: "ApplicationName",
      examples: "CRM, ERP, E-commerce, Analytics",
      purpose: "Associates the resource with a specific application",
    },
    {
      tag_label: "ApplicationTier",
      examples: "Frontend, Backend, Database, Middleware",
      purpose: "Defines the tier or layer of the application",
    },
    {
      tag_label: "ProjectName",
      examples: "ProjectA, ProjectB, ProjectC",
      purpose: "Associates the resource with a specific project",
    },
    {
      tag_label: "ProjectStatus",
      examples: "Active, On-hold, Completed",
      purpose: "Tracks the status of the project",
    },
    {
      tag_label: "ResourceType",
      examples: "VirtualMachine, Storage, Network, Database",
      purpose: "Identifies the type of resource",
    },
    {
      tag_label: "ServiceName",
      examples: "AWS, Azure, GCP, OCI",
      purpose: "Specifies the cloud service provider used",
    },
  ]);

  const changeDrawerStatus = () => {
    props.setTaggingPracticeStatus(false);
  };

  const columns = [
    {
      title: "Tag Label",
      dataIndex: "tag_label",
      key: "tag_label",
      sorter: (a, b) => {
        return a.tag_label > b.tag_label ? -1 : 1;
      },
      render: (record) => {
        return record;
      },
    },
    {
      title: "Examples",
      dataIndex: "examples",
      key: "examples",
      sorter: (a, b) => {
        return a.examples > b.examples ? -1 : 1;
      },
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
      sorter: (a, b) => {
        return a.purpose > b.purpose ? -1 : 1;
      },
    },
  ];
  return (
    <>
      <Modal
        className="costmodal"
        open={props.taggingpracticestatus}
        onCancel={changeDrawerStatus}
        deletestatus={false}
        title="Cloud Resource Tagging Guide"
        footer={null}
      >
        <h1 className="block-title-20">Why Tagging Cloud Resources Matters</h1>
        <p className="mt-3 text-justify">
          Properly tagging your cloud resources is a crucial step in optimizing
          your cloud costs and improving resource management. Tags are key-value
          pairs that provide metadata to identify and categorize your cloud
          resources. By implementing a robust tagging strategy, you gain the
          following benefits:
        </p>
        <h1 className="block-title">1. Cost Allocation and Accountability</h1>
        <p className="mt-3 text-justify">
          Tags allow you to allocate costs accurately across different
          departments, projects, or teams. By associating expenses with specific
          tags, you can clearly identify the financial impact of each resource,
          making it easier to hold teams accountable for their cloud spending.
        </p>
        <h1 className="block-title">2. Resource Organization and Management</h1>
        <p className="mt-3 text-justify">
          As your cloud infrastructure grows, keeping track of resources becomes
          increasingly challenging. Tags help organize resources logically,
          enabling you to quickly search, filter, and group them based on
          different criteria. This improves resource management and streamlines
          operations.
        </p>
        <h1 className="block-title">3. Cost Optimization and Reporting</h1>
        <p className="mt-3 text-justify">
          With tagged resources, you gain valuable insights into your cloud
          spending. You can generate customized cost reports based on tags,
          allowing you to identify areas of high expenditure and potential cost
          optimization opportunities.
        </p>
        <h1 className="block-title">4. Enhanced Security and Compliance</h1>
        <p className="mt-3 text-justify">
          Tags can be used to classify sensitive data, assign security levels,
          or indicate compliance requirements for specific resources. This
          ensures that appropriate security measures are applied consistently
          across your cloud environment.
        </p>

        <h1 className="block-title-20 mt-5">
          Best Practices for Tagging Cloud Resources
        </h1>
        <p className="mt-3 text-justify">
          To make the most of cloud resource tagging, consider the following
          best practices:
        </p>
        <h1 className="block-title">1. Consistent Tagging Conventions</h1>
        <p className="mt-3 text-justify">
          Establish a standardized tagging convention that all teams must adhere
          to. Consistency is crucial for accurate cost allocation and resource
          management.
        </p>
        <h1 className="block-title">2. Meaningful Tags</h1>
        <p className="mt-3 text-justify">
          Choose descriptive and meaningful tag names and values. Avoid using
          vague or generic terms that may lead to confusion.
        </p>
        <h1 className="block-title">3. Apply Tags Strategically</h1>
        <p className="mt-3 text-justify">
          Identify the most critical resources that require tagging. While
          tagging all resources can be beneficial, focus on high-cost or
          critical assets initially.
        </p>
        <h1 className="block-title">4. Review and Update Tags Regularly</h1>
        <p className="mt-3 text-justify">
          Cloud environments are dynamic, with resources frequently being
          created, modified, or deleted. Ensure regular reviews to keep tags
          up-to-date and relevant.
        </p>

        <h1 className="block-title-20 mt-5">Example Tag Categorizations</h1>
        <p className="mt-3 text-justify">
          Here are some common tag categorizations that organizations use to
          optimize cloud resource management:
        </p>
        <div
          className="
        "
        >
          {" "}
          <Table
            className="slm-table"
            columns={columns}
            dataSource={tablerecords}
            pagination={false}
            // scroll={{ x: 1200 }}
          />
        </div>

        <p className="mt-3 text-justify">
          Remember that these examples are just a starting point. Customize your
          tags according to your organization's unique needs.
        </p>

        <p className="mt-3 text-justify">
          By following these best practices and implementing a well-thought-out
          tagging strategy, you can gain better visibility into your cloud costs
          and effectively optimize your cloud resources.
        </p>
      </Modal>
    </>
  );
};

export default memo(TaggingBestPractice);
