import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { listservicemonth } from "../action/costimizeV2Active";
import { useDispatch, useSelector } from "react-redux";
import { useEffect,useState } from "react";



const AssetTrend = ()=>{
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const[chart,setChart] =  useState([])
  // onst [chart, setChart] = useState([]);
  const [chartData, setChartData] = useState([]);
  const dispatch = useDispatch();
  const {
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedos,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    const datelist = [];
    const month_list = [];
    let currentDate = new Date(selectedTopdate);
    let lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      datelist.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
      month_list.push(
        `${monthNamesShort[date.getMonth()]}-${("" + date.getFullYear()).substr(
          2
        )}`
      );
    }
    setChart(month_list);
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      os: selectedos.toString(),
      db: selecteddb.toString(),
      month: datelist.toString(),
    };

    dispatch(listservicemonth({ companyName, apivalue }))
      .unwrap()
      .then(({ data }) => {
        // console.log(data,"data3months")
     
      const listservice = data.slm_asset_trend.map((datas) => {

        // console.log("spend trend for 3 months",datas)



        const ec2Count = parseInt(datas.ec2_count, 10);
        const dbCount = parseInt(datas.db_count, 10);


        return {
          month: `${monthNamesShort[new Date(datas.month).getMonth()]}-${new Date(
            datas.month
          ).getFullYear().toString().substr(-2)}`,
       
          ec2_count: ec2Count,
          db_count: dbCount,
        };
      
      });

      setChartData(listservice);
    })
    .catch((err) => {
      console.log(err);
    });
  }, [selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedos,
    selecteddb,
    selectedTopdate,
    companyName])

  
    const options = {
      series: [
        {
          name: 'EC2',
          data: chartData.map((item) => item.ec2_count),
        },
        {
          name: 'DB',
          data: chartData.map((item) => item.db_count),
        },
      ],
      chart: {
        type: 'bar',
        height: 250,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'round',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: chartData.map((item) => item.month),
        labels:{
          style:{
            colors:"#FFF",
          }
        }
      },
      fill: {
        opacity: 1,
      },
      yaxis: {
        tickAmount: 5,
        min: 0,
        labels:{
          style:{
            colors:"#FFF",
          }
        }
        
      },
    };


  return (
    <div id="chart">
        <h1 className="spend-chart-titleslm">Asset Trend</h1>

      <ReactApexChart options={options} series={options.series} type="bar" height={250} />
    </div>
  );
}

export default AssetTrend;
