import ApplicationLevelDeatiledView from "./ApplicationLevelDeatiledView";
import apexcharts from "apexcharts";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Companylogo from "../../costimize_v2/WeeklyReport/company_logo.png";
import CHLOGO from "../../costimize_v2/WeeklyReport/onechlogo.jpg";
import ServiceLevelDetaileView from "./ServiceLevelDetaileView";
import { Card, Col, Row, Switch ,Button } from "antd";
import { headercustomerlogo } from "../../action/header_logo";
import { useLocation } from "react-router-dom";
import CostimizeSidebar from "../layout/CostimizeSidebar";
import {
  weeklyreportservicedetailed,
  weeklyreportappsummary,
} from "../../action/accountAction";
import { useNavigate } from "react-router-dom";
import Reportdata from "./Reportdata";
function DetailedReport() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enddate, setenddate] = useState([]);
  const [company_logo, setCompanyLogo] = useState();
  const [reportDetailedService, setReportdetailedservice] = useState([]);
  const [reportappwise, setReportweekappservice] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  useEffect(() => {
    dispatch(headercustomerlogo({ company_name }))
      .unwrap()
      .then(({ data }) => {
        var encodedStringAtoB = data.getCustomerLogo.platform_logo;
        var testimg = `data:image/svg+xml;base64,${encodedStringAtoB}`;
        setCompanyLogo(testimg);
      })
      .catch((err) => {
        //.log(err);
      });
  }, []);
  //   const location = useLocation();
  //   const { startDate, endDate } = location.state || {};
  // const location = useLocation();
  // const { selectedDate } = location.state.selectedDate;
  // console.log("startDate:", selectedDate);

  // if (!startDate || !endDate) {
  //   return <div>Start Date or End Date not found</div>;
  // }
  // const location = useLocation();
  // const passedData = location.state.data;
  // console.log(passedData);
  // const location = useLocation();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding leading zero if needed
    let day = date.getDate().toString().padStart(2, "0"); // Adding leading zero if needed
    return `${year}-${month}-${day}`;
  };

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const start_dates = searchParams.get("start");
  const end_dates = searchParams.get("end");

  const start_date = formatDate(start_dates);
  const end_date = formatDate(end_dates);

  useEffect(() => {
    dispatch(
      weeklyreportservicedetailed({
        company_name,
        start_date,
        end_date,
      })
    )
      .unwrap()
      .then(({ data }) => {

        const reportservicedetailed =
          data.weekly_report_service_week_detailed_summary.map((datas) => {
            return {
              service: datas.service,
              environment: datas.environment,
              application: datas.application,
              region: datas.region,
              pre_previous_week: datas.pre_previous_week,
              previous_week: datas.previous_week,
              current_week: datas.current_week,
              difference: datas.difference,
              zeroth_week:datas.zeroth_week,
              fourth_week: datas.fourth_week,
            };
          });
        setReportdetailedservice(reportservicedetailed);
        setSpinner(false);
      })
      .catch((err) => {
        //.log(err);
        setSpinner(true);
      });

    dispatch(
      weeklyreportappsummary({
        company_name,
        start_date,
        end_date,
      })
    )
      .unwrap()
      .then(({ data }) => {
        const reportweekapp =
          data.weekly_report_service_application_week_detailed_summary.map(
            (datas) => {
              return {
                environment: datas.environment,
                application: datas.application,
                vm_count: datas.vm_count,
                cpu: datas.cpu,
                memory: datas.memory,
                storage: datas.storage,
                pre_previous_week: datas.pre_previous_week,
                previous_week: datas.previous_week,
                current_week: datas.current_week,
                difference: datas.difference,
                zeroth_week:datas.zeroth_week,
                fourth_week: datas.fourth_week,
              };
            }
          );
        setReportweekappservice(reportweekapp);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(true);
      });
  }, [start_date, end_date]);

  // useEffect(() => {

  // }, [start_date, end_date]);
  const handleback =()=>{
    navigate('/costdemaze/report')
  }
  return (
    <div>
      {/* <Reportdata
        setReportdetailedservice={setReportdetailedservice}
        setReportweekappservice={setReportweekappservice}
      /> */}
      <Row> <Col span={1}>
          <CostimizeSidebar />
        </Col>
      <Col span={23}>
      <div className="white-bg">
        {" "}
        <div className="page">
          <div class="horizontal-bar"></div>
          <div className="scroll" id="appdetail">
          <Row>
                      <Col
                        span={24}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <Button
                          className="previous-btn"
                          onClick={() => navigate('/costdemaze/report')}
                        >
                          Back to Previous Page
                        </Button>
                      </Col>
                    </Row>
            <Row>
              <Col span={18}>
                {company_logo && (
                  <img className="costimize_header_logo" src={company_logo} alt="companylog"/>
                )}
              </Col>
              <Col span={6}>
                <h5 className="boldheading">Weekly Consumption Report</h5>
              </Col>
            </Row>
            <Row>
              <Col span={20}></Col>
              <Col span={4}></Col>
            </Row>
            <h4 className="subtableheading">
              {" "}
              Detailed view of VM along with Application and Spend: <br></br>
            </h4>
            <Row className="chartrow">
              <Col span={24}>
                <ApplicationLevelDeatiledView
                  applicationweekly={reportappwise}
                  selectedDate={start_date}
                  endDate={end_date}
                  spinner={spinner}
                />
              </Col>
            </Row>
          </div>
          <hr className="ruler footerruler"></hr>
          <Row className="footerrow">
            <Col span={12}>
              <p>© 1CloudHub Pvt Ltd. 2023. All Rights Reserved.</p>
            </Col>
            <Col span={10}>
              <p>-1-</p>
            </Col>
            <Col span={2}>
              <p>Confidential</p>
            </Col>
          </Row>
        </div>
        {/* kamalika */}
        <div className="page">
          <div class="horizontal-bar"></div>
          <div className="scroll" id="services">
            <Row>
              <Col span={18}>
                {company_logo && (
                  <img className="costimize_header_logo" src={company_logo}  alt="companylogo"/>
                )}
              </Col>
              <Col span={6}>
                <h5 className="boldheading">Weekly Consumption Report</h5>
              </Col>
            </Row>
            <Row>
              <Col span={20}></Col>
              <Col span={4}>
                {/* <a href="#" className="linktag">
                  Back to Home
                </a> */}
              </Col>
            </Row>
            <h4 className="subtableheading">
              {" "}
              Detailed view of VM along with Service and Spend <br></br>
            </h4>
            <Row className="chartrow">
              <Col span={24}>
                {/* <ServiceLevelDetaileView
                  servicelevelweekly={reportweekwiseappsumservice}
                /> */}
                <ServiceLevelDetaileView
                  servicelevelweekly={reportDetailedService}
                  selectedDate={start_date}
                  endDate={end_date}
                  spinner={spinner}
                />
              </Col>
            </Row>

            {/* //New page */}
          </div>
          <hr className="ruler footerruler"></hr>
          <Row className="footerrow">
            <Col span={12}>
              <p>© 1CloudHub Pvt Ltd. 2023. All Rights Reserved.</p>
            </Col>
            <Col span={10}>
              <p>-2-</p>
            </Col>
            <Col span={2}>
              <p>Confidential</p>
            </Col>
          </Row>
        </div>
      </div>
      </Col>
        </Row>
     
    </div>
  );
}

export default DetailedReport;
