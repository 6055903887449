import {
  MDBInput,
  MDBCheckbox,
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";
import { useState } from "react";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import { registerUser, getTempUserDetails } from "./action/userAction";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { enableRegisterFlag } from "./slice/userSlice";
import { useSelector } from "react-redux";
import { usermanagerUserList } from "./action/userAction";
import GoogleLogin from "react-google-login";
import { FcGoogle } from "react-icons/fc";
import { ImLinkedin } from "react-icons/im";
import { Button, message } from "antd";
import { Icon } from "@iconify/react";
//auth
import {
  signUp,
  VerificationEamil,
  resendVerificationCode,
} from "./amplify/auth-config";
import { useEffect } from "react";

function Register(props) {
  const dispatch = useDispatch();
  const navigateFn = useNavigate();
  const params = useParams();
  const { loading, userInfo, profileInfo } = useSelector((state) => state.user);
  const [buttonloading, setButtonLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    phoneNo: "",
    password: "",
    repeat_password: "",
    email_otp: "",
    checkbox: false,
  });
  const [close, setCloseAsset] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errorcontent, setErrorContent] = useState(
    "Unable to register the user"
  );
  const [otpstatus, setOTPStatus] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityPass = () => {
    setConfirmPassword(!confirmPassword);
  };

  const onChange = (e) => {
    if (e.target.name === "checkbox") {
      var checkboxvalue = checkbox;
      if (checkbox) {
        checkboxvalue = false;
      } else {
        checkboxvalue = true;
      }
      setFormValue({ ...formValue, [e.target.name]: checkboxvalue });
    } else {
      setFormValue({ ...formValue, [e.target.name]: e.target.value });
    }
  };
  useEffect(() => {
    const code = params.code;
    var path = window.location.pathname;
    const path_array = path.split("/");
    if (path_array[1] === "signup") {
      if (code) {
        const temp_user_id = atob(code);
        dispatch(getTempUserDetails({ temp_user_id }))
          .unwrap()
          .then((value) => {
            if (value.data.getPlatformTempUser) {
              setFormValue({
                firstName: value.data.getPlatformTempUser.first_name,
                lastName: value.data.getPlatformTempUser.last_name,
                email: value.data.getPlatformTempUser.email_id,
                companyName: value.data.getPlatformTempUser.compnay_name,
                phoneNo: value.data.getPlatformTempUser.contact_number,
              });
            }
            //  else {
            //   navigateFn("/");
            // }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      // else {
      //   navigateFn("/");
      // }
    }
  }, [props]);
  // const submit = () => {
  //     setLoader(true);
  //     dispatch(usermanagerUserList())
  //     .unwrap()
  //     .then(({ data }) => {
  //       if (data.listUserConfigs) {
  //         const newdata = data.listUserConfigs.items;
  //         const filteredlist = newdata.filter(datas => {
  //             return datas.company_name === companyName;
  //         });
  //         if(filteredlist.length > 0){
  //             setCloseAsset(true);
  //             setError(true);
  //             setLoader(false);
  //             setErrorContent("Company name already exists");
  //         }else{
  //             if (firstName && lastName && email && companyName && phoneNo && password && checkbox) {
  //                 setLoader(true);
  //                 dispatch(registerUser({ firstName, email, password, lastName, phoneNo, companyName,checkbox }))
  //                 .unwrap()
  //                 .then(value => {
  //                     console.log(value)
  //                     setCloseAsset(true);
  //                     setError(false);
  //                     setLoader(false);
  //                     navigateFn('/signin');
  //                     dispatch(enableRegisterFlag(true))
  //                 })
  //                 .catch(err => {
  //                     setCloseAsset(true);
  //                     setError(true);
  //                     setLoader(false);
  //                     setErrorContent("Unable to register the user");
  //                 });
  //             }
  //         }
  //       }
  //     })
  //     .catch(err => {
  //       setError(err);
  //     });

  // }

  const submit = () => {
    if (!checkbox) {
      message.error(
        "Please accept the Terms and Conditions before continuing."
      );
      return;
    }
    setButtonLoading(true);
    dispatch(
      signUp({
        firstName,
        email,
        password,
        lastName,
        phoneNo,
        companyName,
        checkbox,
      })
    )
      .unwrap()
      .then((data) => {
        setError(false);
        setCloseAsset(true);
        setButtonLoading(false);
        setErrorContent(
          "Register successfully done. Verification code sent to the Mail ID."
        );
        setOTPStatus(true);
      })
      .catch((err) => {
        setButtonLoading(false);
        console.log(err.message);
        setErrorContent(err.message);
        setError(true);
        setCloseAsset(true);
      });
  };
  const handleOpenTerms = () => {
    window.open("/terms", "_blank");
    // navigateFn("/terms");
  };
  const {
    firstName,
    lastName,
    email,
    companyName,
    phoneNo,
    password,
    repeat_password,
    checkbox,
    email_otp,
  } = formValue;

  const OTPsubmit = () => {
    dispatch(VerificationEamil({ email_otp, email }))
      .unwrap()
      .then((data) => {
        setOTPStatus(false);
        setButtonLoading(false);
        setCloseAsset(true);
        setError(false);
        setLoader(false);
        navigateFn("/signin");
        dispatch(enableRegisterFlag(true));
      })
      .catch((err) => {
        console.log(err.message);
        setButtonLoading(false);
        setErrorContent(err.message);
        setError(true);
        setCloseAsset(true);
      });
  };

  const resendOTP = () => {
    dispatch(resendVerificationCode({ email }))
      .unwrap()
      .then((data) => {
        setError(false);
        setCloseAsset(true);
        setErrorContent("Code send successfully done.");
        setOTPStatus(true);
      })
      .catch((err) => {
        console.log(err.message);
        setErrorContent(err.message);
        setError(true);
        setCloseAsset(true);
      });
  };
  return (
    <>
      <div className="plat-signgroup-right-body">
        {close ? (
          <Alert
            onClose={() => setCloseAsset(false)}
            severity={error ? "error" : "success"}
          >
            {" "}
            {error ? errorcontent : errorcontent}
          </Alert>
        ) : null}
        {otpstatus ? (
          <>
            <MDBRow>
              <MDBValidation>
                <div className="loginForm">
                  <MDBCol sm="12" md="12" lg="12" xl="12">
                    <div>
                      <div>
                        <MDBValidationItem
                          className="pb-2"
                          feedback="Please enter the verification code."
                          invalid
                        >
                          <div className="d-flex m-1 form-field">
                            <label className="plat-form-label me-4 pe-4">
                              Verification Code
                            </label>
                            <MDBInput
                              id="form1"
                              type="text"
                              name="email_otp"
                              className="loginInputField"
                              onChange={onChange}
                              required
                              value={email_otp}
                            />
                          </div>
                        </MDBValidationItem>
                      </div>
                    </div>
                  </MDBCol>
                  <div className="row mb-4 mt-3">
                    <div className="plat-loing-btn-body">
                      {loader ? <LinearProgress color="primary" /> : null}
                      <MDBBtn
                        disabled={loader}
                        className="me-1 plat-login-button"
                        onClick={OTPsubmit}
                      >
                        Submit
                      </MDBBtn>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <p className="mb-3">
                      {" "}
                      Didn't Receive OTP?{" "}
                      <a
                        className="sign-page-link"
                        href="javascript:void(0)"
                        onClick={resendOTP}
                      >
                        {" "}
                        RESEND OTP{" "}
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </MDBValidation>
            </MDBRow>
          </>
        ) : (
          <>
            <MDBRow>
              <MDBValidation>
                <div className="loginForm">
                  <MDBRow>
                    {/* <div className="text-center mb-3">
                        <MDBBtn className='btn btn-primary btn-floating mx-1' style={{ backgroundColor: '#1266f1' }} href='#'>
                            <MDBIcon fab icon='google' />
                        </MDBBtn>
                        <GoogleLogin>
                        </GoogleLogin>
                        <MDBBtn className='btn btn-primary btn-floating mx-1' style={{ backgroundColor: '#1266f1' }} href='#'>
                            <MDBIcon fab icon='linkedin-in' />
                        </MDBBtn> 
                        <MDBBtn className='btn btn-primary btn-floating mx-1' style={{ backgroundColor: '#1266f1' }} href='#'>
                            <FcGoogle size={32}  />
                        </MDBBtn>
                        <MDBBtn className='btn btn-primary btn-floating mx-1' style={{ backgroundColor: '#1266f1' }} href='#'>
                            <ImLinkedin size={33}  color="#1b5f9d" />
                        </MDBBtn>
                    </div>
                    <p className="text-center">or</p> */}
                    <MDBCol sm="12" md="12" lg="12" xl="12">
                      <div>
                        <MDBValidationItem
                          className="pb-2"
                          feedback="Please enter the first name."
                          invalid
                        >
                          <div className="d-flex m-1 form-field">
                            <label className="plat-form-label firstnamecss me-4">
                              First Name
                            </label>
                            <MDBInput
                              id="form1"
                              type="text"
                              name="firstName"
                              className="loginInputField"
                              onChange={onChange}
                              value={firstName}
                              required={true}
                              // readOnly={true}
                            />
                          </div>
                        </MDBValidationItem>
                      </div>
                    </MDBCol>
                    <MDBCol sm="12" md="12" lg="12" xl="12">
                      <div>
                        <MDBValidationItem
                          className="pb-2 pt-2"
                          feedback="Please enter the last name."
                          invalid
                        >
                          <div className="d-flex m-1 form-field">
                            <label className="plat-form-label lastnamecss me-4">
                              Last Name
                            </label>
                            <MDBInput
                              id="form1"
                              type="text"
                              name="lastName"
                              className="loginInputField"
                              onChange={onChange}
                              value={lastName}
                              required
                              // readOnly={true}
                            />
                          </div>
                        </MDBValidationItem>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBCol sm="12" md="12" lg="12" xl="12">
                    <div className="userName">
                      <MDBValidationItem
                        className="pb-2 pt-2"
                        feedback="Please enter the email."
                        invalid
                      >
                        <div className="d-flex m-1 form-field">
                          <label className="plat-form-label emailname me-5 ms-2">
                            Email ID
                          </label>
                          <MDBInput
                            id="form1"
                            type="text"
                            name="email"
                            className="loginInputField"
                            onChange={onChange}
                            value={email}
                            pattern="[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                            required
                            // readOnly={true}
                          />
                        </div>
                      </MDBValidationItem>
                    </div>
                  </MDBCol>
                  <MDBCol sm="12" md="12" lg="12" xl="12">
                    <div>
                      <MDBValidationItem
                        className="pb-2 pt-2"
                        feedback="Please enter the password."
                        invalid
                      >
                        <div className="d-flex m-1 form-field">
                          <label className="plat-form-label pswdcss me-5 ms-2 ">
                            Password
                          </label>
                          <MDBInput
                            id="form1"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            className="loginInputField"
                            onChange={onChange}
                            required
                            value={password}
                          />

                          <span
                            className="eye-icon"
                            onClick={togglePasswordVisibility}
                            style={{
                              cursor: "pointer",
                              marginLeft: "-20px",
                              zIndex: "1",
                            }}
                          >
                            {showPassword ? (
                              <Icon
                                icon="fa:eye"
                                style={{ fontSize: "10px", color: "black" }}
                              />
                            ) : (
                              <Icon
                                icon="fa:eye-slash"
                                style={{ fontSize: "10px", color: "black" }}
                              />
                            )}
                          </span>
                        </div>
                      </MDBValidationItem>
                    </div>
                  </MDBCol>

                  <MDBCol sm="12" md="12" lg="12" xl="12">
                    <div>
                      <MDBValidationItem
                        className="pb-2 pt-2"
                        feedback="Please enter the password."
                        invalid
                      >
                        <div className="d-flex m-1 form-field">
                          <label className="plat-form-label confirmpaswd me-1">
                            Confirm Password
                          </label>
                          <MDBInput
                            id="form1"
                            type={confirmPassword ? "text" : "password"}
                            name="repeat_password"
                            className="loginInputField"
                            onChange={onChange}
                            required
                            value={repeat_password}
                          />
                          <span
                            className="eye-icon"
                            onClick={togglePasswordVisibilityPass}
                            style={{
                              cursor: "pointer",
                              marginLeft: "-20px",
                              zIndex: "1",
                            }}
                          >
                            {confirmPassword ? (
                              <Icon
                                icon="fa:eye"
                                style={{ fontSize: "10px", color: "black" }}
                              />
                            ) : (
                              <Icon
                                icon="fa:eye-slash"
                                style={{ fontSize: "10px", color: "black" }}
                              />
                            )}
                          </span>
                        </div>
                      </MDBValidationItem>
                    </div>
                  </MDBCol>

                  <MDBCol sm="12" md="12" lg="12" xl="12">
                    <div>
                      <MDBValidationItem
                        className="pb-2 pt-2"
                        feedback="Please enter the company name."
                        invalid
                      >
                        <div className="d-flex m-1 form-field">
                          <label className="plat-form-label cmpynamecss me-3">
                            Company Name
                          </label>
                          <MDBInput
                            id="form1"
                            type="text"
                            name="companyName"
                            className="loginInputField"
                            onChange={onChange}
                            value={companyName}
                            required
                            // readOnly={true}
                          />
                        </div>
                      </MDBValidationItem>
                    </div>
                  </MDBCol>
                  <MDBCol sm="12" md="12" lg="12" xl="12">
                    <div>
                      <MDBValidationItem
                        className="pb-2 pt-2"
                        feedback="Please enter the phone number."
                        invalid
                      >
                        <div className="d-flex m-1 form-field">
                          <label className="plat-form-label phone-no me-2 ">
                            Phone No
                          </label>
                          <MDBInput
                            id="form1"
                            type="text"
                            name="phoneNo"
                            className="loginInputField"
                            onChange={onChange}
                            required
                            value={phoneNo}
                            pattern="[0-9]*"
                            // readOnly={true}
                          />
                        </div>
                      </MDBValidationItem>
                    </div>
                  </MDBCol>
                  <div className="row mb-1">
                    <div className="col   mb-1 text-center termsAndConditionText">
                      <div className="form-check form-check-btn-group mt-4">
                        <MDBCheckbox
                          name="checkbox"
                          onChange={onChange}
                          value={checkbox}
                          id="flexCheckDefault"
                          // label=""
                          // required
                          // id="termsCheckbox"
                          // label="I agree to the terms and conditions"
                          required
                          invalidMessage="You must agree before submitting."
                          validMessage="Thank you!"
                        />
                        <p>
                          I have read and agree to the{" "}
                          <a
                            className="sign-page-link sign-page-link-absolute"
                            href="javascript:void(0)"
                            onClick={handleOpenTerms}
                          >
                            {" "}
                            Terms and Conditions{" "}
                          </a>{" "}
                        </p>
                        <div className="plat-loing-btn-body py-2">
                          {/* {loader ? <LinearProgress color="primary" /> : null}
                                <MDBBtn
                                disabled={loader}        
                                className='me-1 plat-login-button'
                                onClick={submit}>Sign Up</MDBBtn> */}
                          <Button
                            type="primary"
                            htmlType="submit"
                            className=" plat-signin-button"
                            loading={buttonloading}
                            id="submit-signup"
                            onClick={submit}
                          >
                            Sign Up
                          </Button>
                        </div>

                        <p>
                          Already have an account?{" "}
                          <a
                            className="sign-page-link sign-page-link-absolute"
                            href="javascript:void(0)"
                            onClick={props.onSelectTab}
                          >
                            {" "}
                            Sign in{" "}
                          </a>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </MDBValidation>
            </MDBRow>
          </>
        )}
      </div>
    </>
  );
}

export default Register;
