import { memo, useEffect, useState } from "react";
import { Col, Row, Tooltip, message } from "antd";
import { Icon } from "@iconify/react";
import TaggingPopupTable from "./TaggingPopupTable";
import { RxOpenInNewWindow } from "react-icons/rx";

const TaggingPopupDown = (props) => {
  const [tablepopupstatus, setTablePopupStatus] = useState(false);
  const [tabletype, setTableType] = useState();
  const [pagerecords, setPageRecords] = useState([]);
  useEffect(() => {
    // console.log(props);
    const services_list = props.records?.map((v) => v.services);
    const service_set = new Set([...services_list]);
    const services = [...service_set];
    var all_tagging_list = [];
    services.map((service_name) => {
      //tagging count
      const tagged_records = props.records?.filter(
        (v, index) =>
          v.tagging_details === "tagged" && v.services === service_name
      );
      // console.log(tagged_records);
      const tagged_records_count = tagged_records?.reduce(
        (a, v) => (a = +a + +v.count_tag_category),
        0
      );
      //partially count
      const partially_records = props.records?.filter(
        (v, index) =>
          v.tagging_details === "partially_tagged" &&
          v.services === service_name
      );
      const partially_records_count = partially_records?.reduce(
        (a, v) => (a = +a + +v.count_tag_category),
        0
      );
      //untagged
      const untagged_records = props.records?.filter(
        (v, index) =>
          v.tagging_details === "Untagged" && v.services === service_name
      );
      const untagged_records_count = untagged_records?.reduce(
        (a, v) => (a = +a + +v.count_tag_category),
        0
      );
      all_tagging_list.push({
        servicename: service_name,
        taggingcount: tagged_records_count,
        partiallycount: partially_records_count,
        untaggedcount: untagged_records_count,
      });
    });
    setPageRecords(all_tagging_list);
  }, [props]);

  return (
    <>
      {tablepopupstatus && (
        <TaggingPopupTable
          tablepopupstatus={tablepopupstatus}
          setTablePopupStatus={setTablePopupStatus}
          tabletype={tabletype}
        />
      )}

      <Row className="tagging-popup-body" gutter={[20, 20]}>
        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
          <ul className="p-0">
            {pagerecords.map((records, key) => (
              <>
                {records.servicename.length > 30 ? (
                  <>
                    <Tooltip placement="bottom" title={records.servicename}>
                      <li className="tagging-popup-service">
                        <div> {records.servicename.substring(0, 30)}...</div>
                        {/* <div>
                          <Icon icon="uil:import" />
                        </div> */}
                      </li>
                    </Tooltip>
                  </>
                ) : (
                  <li className="tagging-popup-service">
                    <div>{records.servicename}</div>
                    {/* <div>
                      <Icon icon="uil:import" />
                    </div> */}
                  </li>
                )}
              </>
            ))}
          </ul>
        </Col>
        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
          <ul className="p-0">
            {pagerecords.map((records, key) => (
              <li
                className="text-right csp"
                onClick={() => {
                  if (records.taggingcount === 0) {
                    setTablePopupStatus(false);
                    message.info("No data available");
                  } else {
                    setTablePopupStatus(true);
                    setTableType({
                      type: "tagged",
                      category: props.category,
                      title: "Tagged",
                      records: records,
                    });
                  }
                }}
              >
                <lable>
                  <u>{records.taggingcount}</u>
                </lable>
              </li>
            ))}
          </ul>
        </Col>
        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
          <ul className="p-0">
            {pagerecords.map((records, key) => (
              <li
                className="text-right csp"
                onClick={() => {
                  if (records.partiallycount === 0) {
                    setTablePopupStatus(false);
                    message.info("No data available");
                  } else {
                    setTablePopupStatus(true);
                    setTableType({
                      type: "partially_tagged",
                      category: props.category,
                      title: "Partially Tagged",
                      records: records,
                    });
                  }
                }}
              >
                <lable>
                  {" "}
                  <u>{records.partiallycount}</u>
                </lable>
              </li>
            ))}
          </ul>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
          <ul className="p-0">
            {pagerecords.map((records, key) => (
              <li
                className="text-right csp"
              
                onClick={() => {
                  if (records.untaggedcount === 0) {
                    setTablePopupStatus(false);
                    message.info("No data available");
                  } else {
                    setTablePopupStatus(true);
                  setTableType({
                    type: "Untagged",
                    category: props.category,
                    title: "Untagged",
                    records: records,
                  });
                  }
                }}
              >
                <lable>
                  <u>{records.untaggedcount}</u>
                </lable>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </>
  );
};
export default memo(TaggingPopupDown);
