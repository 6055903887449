import { Icon } from "@iconify/react";
import { Card, Progress, Spin } from "antd";
import React from "react";

function PeemsStatusCards({ statusCounts, isShowSpinner, type }) {
  // console.log(statusCounts);
  return (
    <>
      <div className="cards">
        <Card
          title={
            <div className="d-flex justify-content-around">
              <span className="job-status-icon">
                <Icon icon="pajamas:clear-all" />
              </span>
              <span className="job-status-text ms-2">Total Jobs </span>
            </div>
          }
          bordered={false}
          className="totaljobs-center"
        >
          <div className="job-card job-card-initial ">
            <div className="w-100 ">
              {isShowSpinner ? (
                <div className="d-flex justify-content-center">
                  <Spin />
                </div>
              ) : (
                <>
                  <div className="d-flex justify-content-center mt-2">
                    <h3 className="total-job-count">
                      {statusCounts.totalJobs}
                    </h3>
                  </div>
                </>
              )}
            </div>
          </div>
        </Card>
        <Card
          title={
            <div className="d-flex justify-content-between">
              <span className="job-status-icon">
                <Icon icon="ic:round-done-all" /> &nbsp;Success
              </span>
            </div>
          }
          bordered={false}
        >
          <div className="job-card ">
            <div className="w-100">
              {isShowSpinner ? (
                <div className="d-flex justify-content-center">
                  <Spin />
                </div>
              ) : (
                <>
                  <div className="d-flex justify-content-center mt-2">
                    <h4 className="job-status-count">
                      {statusCounts.successJobs}
                    </h4>
                  </div>
                  <div className="d-flex justify-content-center mt-2 job-progress">
                    <div className="success">
                      <Progress
                        percent={statusCounts.successJobs}
                        showInfo={false}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Card>
        {type != "report" && (
          <Card
            title={
              <div className="d-flex justify-content-between">
                <span className="job-status-icon">
                  <Icon icon="icon-park-outline:timer" /> Scheduled
                </span>
              </div>
            }
            bordered={false}
          >
            <div className="job-card ">
              <div className="w-100">
                {isShowSpinner ? (
                  <div className="d-flex justify-content-center">
                    <Spin />
                  </div>
                ) : (
                  <>
                    <div className="d-flex justify-content-center mt-2">
                      <h4 className="job-status-count">
                        {statusCounts.scheduleJobs}
                      </h4>
                    </div>
                    <div className="d-flex justify-content-center mt-2 job-progress">
                      <div className="scheduled">
                        <Progress
                          percent={statusCounts.scheduleJobs}
                          showInfo={false}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Card>
        )}
        <Card
          title={
            <div className="d-flex justify-content-between">
              <span className="job-status-icon">
                <Icon icon="healthicons:no" /> &nbsp;Failed
              </span>
            </div>
          }
          bordered={false}
        >
          <div className="job-card ">
            <div className="w-100">
              {isShowSpinner ? (
                <div className="d-flex justify-content-center">
                  <Spin />
                </div>
              ) : (
                <>
                  <div className="d-flex justify-content-center mt-2">
                    <h4 className="job-status-count">
                      {statusCounts.failureJobs}
                    </h4>{" "}
                  </div>
                  <div className="d-flex justify-content-center mt-2 job-progress">
                    <div className="Failed">
                      <Progress
                        percent={statusCounts.failureJobs}
                        showInfo={false}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Card>
        <Card
          title={
            <div className="d-flex justify-content-between">
              <div className="borderbox">
                <span className="job-status-icon">
                  <Icon icon="material-symbols:release-alert-rounded" />{" "}
                  &nbsp;Not Triggered
                </span>
              </div>
            </div>
          }
          bordered={false}
        >
          <div className="job-card ">
            <div className="w-100">
              {isShowSpinner ? (
                <div className="d-flex justify-content-center">
                  <Spin />
                </div>
              ) : (
                <>
                  <div className="d-flex justify-content-center mt-2">
                    <h4 className="job-status-count">
                      {statusCounts.unTrigerredJobs}
                    </h4>
                  </div>
                  <div className="d-flex justify-content-center mt-2 job-progress">
                    <div className="triggered">
                      <Progress
                        percent={statusCounts.unTrigerredJobs}
                        showInfo={false}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}

export default PeemsStatusCards;
