import React, { useState } from "react";

const ToggleButtonV2 = (props) => {
  //   console.log(props);
  const [isActive, setActive] = useState(props.status);
  // const ToggleClass = () => {
  //     setActive(!isActive);
  // };
  const changeStatus = () => {
    setActive(!isActive);
  };

  return (
    <div>
      {isActive ? (
        <span
          onClick={() => changeStatus()}
          className={props.newclass + " asset-symble v2-asset-symble"}
        >
          +
        </span>
      ) : (
        <span
          onClick={() => changeStatus()}
          className={props.newclass + " asset-symble v2-asset-symble"}
        >
          -
        </span>
      )}
      <span onClick={() => changeStatus()}>{props.children}</span>
    </div>
  );
};
export default ToggleButtonV2;
