import { Icon } from "@iconify/react";
import { Table } from "antd";
import Dropdown from "react-bootstrap/Dropdown";

const ServiceRequestTable = () => {
  const data = [
    {
      key: "1",
      request: "CR",
      completed_on_time: "100%",
      cancelled: "-",
      failed: "-",
      post_completion: "None",
      action: "",
      type: "data",
    },
    {
      key: "2",
      request: "SR",
      completed_on_time: "100%",
      cancelled: "-",
      failed: "-",
      post_completion: "None",
      action: "",
      type: "data",
    },
  ];
  const columns = [
    {
      title: "Request",
      dataIndex: "request",
      key: "request",
    },
    {
      title: "Completed on Time",
      dataIndex: "completed_on_time",
      key: "completed_on_time",
      sorter: (a, b) => {
        return a.completed_on_time > b.completed_on_time ? -1 : 1;
      },
    },
    {
      title: "Cancelled",
      dataIndex: "cancelled",
      key: "cancelled",
    },
    {
      title: "Failed",
      dataIndex: "failed",
      key: "failed",
    },
    {
      title: "Post Completion",
      dataIndex: "post_completion",
      key: "post_completion",
    },
    {
      title: " ",
      dataIndex: "action",
      key: "action",
      // className: "table-edit-list",
      render: (record, index) => {
        if (index.type === "data") {
          const edit = (
            <Dropdown.ItemText className="plat-dropdown-item-text">
              <span className="plat-box-item-title list-expand-left">Edit</span>
              <span className="plat-box-item-title list-expand-right">
                <Icon
                  icon="material-symbols:edit-outline"
                  class="table-add-records-icon"
                />
              </span>
            </Dropdown.ItemText>
          );

          var addicon = "";
          if (index.key == data.length) {
            addicon = (
              <Dropdown.ItemText className="plat-dropdown-item-text">
                <span className="plat-box-item-title list-expand-left">
                  Add
                </span>
                <span className="plat-box-item-title list-expand-right">
                  <Icon
                    icon="material-symbols:add-box-outline"
                    class="table-add-records-icon"
                  />
                </span>
              </Dropdown.ItemText>
            );
          }
          return (
            <>
              <Dropdown className="plat-costimize-dropdown plat-box-costimize-dropdown">
                <Dropdown.Toggle
                  className="plat-three-dort plat-tap-menu plat-box-three-dort"
                  id="dropdown-button-dark-example1"
                  variant="secondary"
                >
                  <Icon
                    icon="ph:dots-three-bold"
                    class="plat-expandalt-icon-view"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="plat-box-menu-list more-details">
                  {edit}
                  {addicon}
                </Dropdown.Menu>
              </Dropdown>
            </>
          );
        }
      },
    },
  ];

  const tableProps = {};

  return (
    <>
      <div className="budget-allocated-table">
        <h1 className="plat-table-title">Resolution Time</h1>
        <div className="table-width">
          <div className="table-planned-actual">
            <Table
              {...tableProps}
              className="savings_new_table"
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ServiceRequestTable;
