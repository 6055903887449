import ReactECharts from 'echarts-for-react';
import "./style.css";
import { useEffect, useState } from 'react';
import ReactApexChart from "react-apexcharts";

var colors = [
  '#ee7304',
  '#ffc000',
  '#0e5772',
  '#487b78',
  '#00b0f0',
  '#00b050'
]


export default function PieChart(props) {
  // console.log(props);
  const [chartvalue, setChartValue] = useState([]);
  const [chartcategory, setChartCategory] = useState([]);
  const [chartseries, setChartSeries ] = useState([]);
  const [secondmax,setSecondMax] = useState(0);
  const [firstmax,setFirstMax] = useState(0);

  useEffect(() => {
    const dummy = [...props.spendchart].sort((a, b) => (a.value > b.value ? -1 : 1));
      var chartdummyvalue = [];
      var chartcategory = [];
      var chartvalue = [];
      dummy.map((data,index) => {
        if(index < 5){
          chartdummyvalue.push(data);
          chartcategory.push(data.name);
          chartvalue.push(Math.round(data.value));
        }
        // else{
        //   const lastvalue = chartdummyvalue.slice(-1);
        //   chartdummyvalue.pop();
        //   chartdummyvalue.push({value:+lastvalue[0].value + +data.value ,name:"Others"});
        // }
      })
      setFirstMax(Math.max(...chartvalue));
      setChartCategory(chartcategory);
      setChartSeries(chartvalue);
      setChartValue(chartdummyvalue)
      if(Math.max(...chartvalue) > 10){
        setSecondMax(Math.round(chartvalue.reduce((pv, cv) => pv + cv, 0) / 5));
      }else{
        setSecondMax(Math.max(...chartvalue));
      }

  },[props]);

  const options =  {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
          show: false,
      },
      background: '#fff'
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          orientation: 'vertical',
          position: 'top' // bottom/center/top
        }
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -10,
      formatter: function (num, opts) {
        // if(secondmax > num || num === 0){
        //   return '';
        // }else{
          return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
        // }
      },
      style: {
        fontSize: '9px',
        colors: ['#fff']
      },
      background: {
        enabled: true,
        foreColor: '#000',
        borderWidth: 0,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      }
    },
    // colors: chartvalue.chartcolor,
    colors: colors,
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: chartcategory,
      tickPlacement: 'between',
      labels: {
          show: true,
          rotate: 0,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: true,
          minHeight: undefined,
          // maxHeight: undefined,
          // style: {
          //     colors: [],
          //     fontSize: '8px'
          // },
          offsetX: 0,
          offsetY: -5,
          style: {
              fontSize: '8px',
          },
      },
    },
    fill: {
      opacity: 1
    },
    title: {
        text: `Top 5 Application - Spend`,
        align: 'center',
        margin: 0,
        offsetX: 0,
        offsetY: 10,
        floating: false,
        style: {
          fontSize:  '14px',
          fontWeight:  'bold',
          color:  '#595959'
        },
    },
    yaxis: {
        max:secondmax,
        
        labels: {
          show: true,
          formatter: (value) => { 
            var num = value;
            if(firstmax > 10){
              if( Math.round(value) === secondmax){
                // return firstmax;
                return '...';
              }else{
                return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num);
              }
            }else{
              return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num);
            }  
           },
      },
    },
    legend: {
      show: false,
      offsetY: -7,
    },
  };

  const series =  [{
    name: 'spend',
    data: chartseries
  }];
  return (
    <ReactApexChart options={options} series={series} type="bar" height={235} />
  )
}