import { memo, useState, useCallback, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getSpendDetails, SpendService } from "../action/costimizeV2Active";
// import ModalPopUp from "../cams/ModalPopUp";
import { Table, Modal } from "antd";

const SpendtrendChart = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [monthdate, setMonthDate] = useState();
  const [openmodal, setOpenModal] = useState(false);
  const [monthlist, setMonthList] = useState(0);
  const [spendtrend, setspendtrend] = useState([]);
  const [spendservice, setSpendService] = useState([]);
  const [selectedDataPoint, setSelectedDataPoint] = useState(null);

  const [chartdetails, setChartDetails] = useState({
    label: [],
    spendvalue: [],
    budgetvalue: [0, 0, 0],
    monthlist: [],
  });

  // console.log(chartdetails,"chartdetailss")
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const [selectmonth, setSelectmonth] = useState(selectedTopdate);

  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const handleCancel = () => {
    setOpenModal(false);
    setSelectedDataPoint(null);
  };

  useEffect(() => {
    let currentDate = new Date(selectedTopdate);
    let lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 5);
    const datelist = [];
    for (let i = 0; i < 6; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      datelist.push(`${date.getFullYear()}-${date.getMonth() + 1}-1`);
    }
    const selectedTopdate_new = datelist.toString();
    var apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
    };

    dispatch(getSpendDetails({ companyName, selectedTopdate_new, apivalue }))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);
        setspendtrend(data.newfunctionquery3);

        var spend_list = [];
        var spend_value = 0;
        for (let i = 0; i < 6; i++) {
          let date = new Date(
            lastThreeMonthsDate.getFullYear(),
            lastThreeMonthsDate.getMonth() + i,
            1
          );
          var spend = data.newfunctionquery3.filter((item) => {
            let spenddate = new Date(item.reportmonth);
            if (spenddate.getMonth() + 1 === date.getMonth() + 1) {
              return item;
            }
          });
          if (
            date.getMonth() + 1 ===
            new Date(selectedTopdate).getMonth() + 1
          ) {
            if (spend.length > 0) {
              spend_value = spend[0].spend;
            }
          }
          if (spend.length > 0) {
            spend_list.push(Math.round(spend[0].spend));
          } else {
            spend_list.push(0);
          }
        }

        setChartDetails((prevChartDetails) => ({
          ...prevChartDetails,
          spendvalue: spend_list,
        }));

        // dispatch(SpendService({ companyName, monthdate, apivalue }))
        //   .unwrap()
        //   .then(({ data }) => {
        //       const spendEnvironment = data.slm_environment_spend_trend.map(datas => ({
        //       currentMonthSpend: datas.current_month_spend,
        //       previousMonthSpend: datas.previous_month_spend,
        //       service: datas.service,
        //     }));
        //     setSpendService(spendEnvironment);

        //   })
      })
      .catch((err) => {});
  }, [
    monthdate,
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  ]);

  const getMonthNames = (monthdate) => {
    const date = new Date(monthdate);
    const currentMonth = date.toLocaleString("default", { month: "long" });
    date.setMonth(date.getMonth() - 1);
    const previousMonth = date.toLocaleString("default", { month: "long" });
    return { previousMonth, currentMonth };
  };
  const { previousMonth, currentMonth } = getMonthNames(monthdate);
  const resourcedata = [
    {
      title: "Services",
      dataIndex: "service",
      key: "service",
    },
    {
      title: previousMonth,
      dataIndex: "previousMonthSpend",
      key: "previousMonthSpend",
      render: (text) => `$${text}`,
    },
    {
      title: currentMonth,
      dataIndex: "currentMonthSpend",
      key: "currentMonthSpend",
      render: (text) => `$${text}`,
    },
  ];
  useEffect(() => {
    let currentDate = new Date(selectedTopdate);
    let lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 5);

    const monthNamesShort = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month_list = [];
    var chart_new_format = [];
    var month_lable_list = [];
    for (let i = 0; i < 6; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );
      month_list.push(
        `${monthNamesShort[date.getMonth()]}-${("" + date.getFullYear()).substr(
          2
        )}`
      );
      const spend = spendtrend.find((item) => {
        let spenddate = new Date(item.reportmonth);
        return spenddate.getMonth() + 1 === date.getMonth() + 1;
      });
      if (spend) {
        chart_new_format.push(Math.round(spend.spend));
      } else {
        chart_new_format.push(0);
      }
      month_lable_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }
    setChartDetails({
      label: month_list,
      spendvalue: chart_new_format,
      monthlist: month_lable_list,
    });
  }, [
    spendtrend,
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  ]);

  const changeMonth = (month) => {
    // console.log("in");
    setOpen(true);
    setMonthList(month);
    // console.log("change")
  };

  const items = [
    {
      label: <a onClick={() => changeMonth(6)}>6 Months</a>,
      key: "1",
    },
    {
      label: <a onClick={() => changeMonth(12)}>12 Months</a>,
      key: "2",
    },
  ];
  const series = [
    {
      name: "Spend",
      type: "area",
      data: chartdetails.spendvalue,
    },
  ];

  function handleDataPointSelection(event, chartContext, config) {
    setOpenModal(true);
    if (config.dataPointIndex !== undefined) {
      const originalDate = options.labels[config.dataPointIndex];
      const xValue = chartContext.w.globals.seriesX[0][config.dataPointIndex];
      const yValue = chartContext.w.globals.series[0][config.dataPointIndex];

      const dataString = options.labels[config.dataPointIndex];

      const convertDate = (dataString) => {
        const [monthAbbreviation, year] = dataString.split("-");
        const monthMap = {
          Jan: "01",
          Feb: "02",
          Mar: "03",
          Apr: "04",
          May: "05",
          Jun: "06",
          Jul: "07",
          Aug: "08",
          Sep: "09",
          Oct: "10",
          Nov: "11",
          Dec: "12",
        };
        const month = monthMap[monthAbbreviation];
        const formattedDate = `20${year}-${month}-01`;
        return formattedDate;
      };

      const formattedDate1 = convertDate(originalDate);
      setMonthDate(formattedDate1);
    }
  }

  const options = {
    chart: {
      height: 350,
      type: "area",
      events: {
        dataPointSelection: handleDataPointSelection,
      },
    },
    stroke: {
      curve: "straight",
    },
    tooltip: {
      enabled: true,
      intersect: false,
      shared: true,
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    labels: chartdetails.label,

    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetX: 0,
      markers: {
        radius: 12,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    markers: {
      size: 4,
      hover: {
        size: 7,
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: "#FFF",
        },
      },
    },
    yaxis: [
      //   {
      //     title: {
      //       text: "Budget",
      //       style: {
      //         fontSize: "12px",
      //         fontWeight: 500,
      //       },
      //     },
      //     labels: {
      //       formatter: (num) => {
      //         if (num === 0) {
      //           return "0";
      //         } else if (num === Infinity) {
      //           return "0";
      //         } else {
      //           return Math.abs(num) > 999
      //             ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      //             : Math.sign(num) * Math.abs(num);
      //         }
      //       },
      //     },
      //   },
      {
        title: {
          style: {
            fontSize: "12px",
            fontWeight: 500,
          },
        },
        labels: {
          formatter: (num) => {
            if (num === 0) {
              return "0";
            } else if (num === Infinity) {
              return "0";
            } else {
              return Math.abs(num) > 999
                ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                : Math.sign(num) * Math.abs(num);
            }
          },
          style: {
            colors: "#FFF",
          },
        },
      },
    ],
    fill: {
      colors: "#1a9cb8",
    },
  };

  return (
    <div style={{ position: "relative" }}>
      <h1 className="spend-chart-titleslm">Spend Trend</h1>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={210}
      />
      {/* {selectedDataPoint !== null && ( */}
      <Modal
        width="40%"
        title="Environment Spend Trend"
        open={openmodal}
        onCancel={handleCancel}
        footer={null}
      >
        <hr style={{ borderTop: "2px solid white" }} />

        <Table
          className="slm mt-3"
          style={{ overflow: "auto", height: "19rem", width: "2rem" }}
          columns={resourcedata}
          dataSource={spendservice}
          pagination={false}
        />
      </Modal>
      {/* // )} */}
    </div>
  );
};

export default memo(SpendtrendChart);
