import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const ActionItemChart = ({ categoryCount }) => {
  // const [series1,setSeries1]=useState([]);
  // console.log(categoryCount)
  //   const series = [
  //   {
  //     data: [0.5, 1, 2, 0.5],
  //   },
  // ];

  const [series, setSeries] = useState([
    {
      data: [],
    },
  ]);

  // useEffect(() => {
  //   console.log(categoryCount);
  //   const categories = ['Cost Savings', 'Budgeting', 'Security', 'Operations'];
  //   const filterCounts = [];

  //   categories.forEach((category) => {
  //     const filteredItems = categoryCount.filter((item) => item.category.toLowerCase() === category.toLowerCase());
  //     const filteredCount = filteredItems.length;
  //     console.log(filteredItems);
  //     filterCounts.push(filteredCount);
  //   });

  //   console.log(filterCounts);
  //   setSeries = [
  //     {
  //       data: filterCounts,
  //     },
  //   ];

  // }, [categoryCount]);
  useEffect(() => {
    const categories = ["Cost Savings", "Budgeting", "Security", "Operations"];
    const filterCounts = [];

    if (Array.isArray(categoryCount)) {
      categories.forEach((category) => {
        const filteredItems = categoryCount.filter(
          (item) => item.category.toLowerCase() === category.toLowerCase()
        );
        const filteredCount = filteredItems.length;
        filterCounts.push(filteredCount);
      });
    } else {
    }

    setSeries([
      {
        data: filterCounts,
      },
    ]);
  }, [categoryCount]);

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    // xaxis: {
    //   categories: ["Budgeting", "Security", "Cost Savings", "Operations"],
    // },
    xaxis: {
      // categories: ['Cost Savings','Operations',  'Security', 'Budgeting'],
      categories: ["Cost Savings", "Budgeting", "Security", "Operations"],
      labels: {
        style: {
          colors: "white", // Change X-axis category text color to white
        },
      },
    },

    fill: {
      colors: ["#EECF2A", "#EECF2A", "#EECF2A", "#EECF2A"],
    },
    grid: {
      show: true,
      borderColor: "#90A4AE",
      strokeDashArray: 0,
      position: "back",
      xaxis: {
        lines: {
          show: true,
        },
 
      },
      yaxis: {
        lines: {
          show: false,
        },
        labels: {
          style: {
            colors: "white", // Change X-axis category text color to white
          },
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  };

  // const series = [
  //   {
  //     data: [0.5, 1, 2, 0.5],
  //   },
  // ];

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={200}
      />
    </>
  );
};

export default ActionItemChart;
