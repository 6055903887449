import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./activitytracker.css";
import CustimizeFooter from "../CustimizeFooter";
import OperationTapmenu from "../OperationTapmenu";
import ActionItems from "./ActionItems";
import ProjectStatus from "./ProjectStatus";
import KeyActivitiesTable from "./KeyActivitiesTable";
import KeyActivities from "./KeyActivities";
import ActionItemChart from "./ActionItemChart";
import ProjectStatusChart from "./ProjectStatusChart";
import ActionTable from "./ActionTable";
import ProjectStatusTable from "./ProjectStatusTable";
import { useState } from "react";

const ActivityTracker = () => {
  const [categoryCount, setCategoryCount] = useState({});
  const [daysDiff, setDaysDiff] = useState([]);

  return (
    <>
      <div className="plat-operation-body">
        <div className="plat-dashboard-tabs">
          <OperationTapmenu
            excalname="Activity tracker"
            type="activity tracker"
            title="Activity Tracker"
          />

          <div className="plat-full-asset-spend-cover">
            <Row>
              <Col>
                <ActionItemChart categoryCount={categoryCount} />
              </Col>
              <Col>
                <ProjectStatusChart daysDiff={daysDiff} />
              </Col>
            </Row>
            <Row className="budget-mt">
              <Col>
                {/* <ActionItems  /> */}
                <ActionTable setCategoryCount={setCategoryCount} />
              </Col>
            </Row>
            <Row>
              <Col>
                <ProjectStatusTable setDaysDiff={setDaysDiff} />
              </Col>
            </Row>
            <Row>
              <Col>
                {/* <KeyActivities/> */}
                <KeyActivitiesTable />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <CustimizeFooter />
    </>
  );
};

export default ActivityTracker;
