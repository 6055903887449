import React from "react";
import "../costimize_v2.css";
import { Row, Col, Card } from "antd";
import ReactApexChart from "react-apexcharts";
function TopFiveApplicationSpenLess() {
  const options = {
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      padding: {
        top: 10, // Adjust top padding
        bottom: 10, // Adjust bottom padding
      },
    },
    //   stroke: {
    //     width: 2, // Adjust line width if needed
    //   },
    markers: {
      size: 0, // Adjust marker size if not required
    },

    xaxis: {
      categories: [
        "Finnone-19c",
        "Finnone",
        "Employee Portal",
        "Finnone prod -dr",
        "Model Building",
      ],
      labels: {
        style: {
          colors: "white",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "white",
        },
      },
    },
    colors: ["#FF6A2F"],
  };
  const series = [
    {
      data: [24, 89, 67, 45, 41],
    },
  ];
  return (
    <div>
      <Row className="mt-3">
        <Col>
          <h1 className="site-color block-title">
            {" "}
            Top 5 Application Spend Less ($)
          </h1>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card className="plat-card-1">
            {" "}
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height="350"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default TopFiveApplicationSpenLess;
