import { Icon } from "@iconify/react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { Button } from "antd";
import { MDBTabsContent, MDBTabsPane } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import headertext from "../../assets/images/landing/Rectangle (1).png";
import header from "../../assets/images/landing/Rectangle.png";
import google from "../../assets/images/landing/image 280.png";
import apple from "../../assets/images/landing/image 281.png";
import CloudShifuLogoBig from "../../assets/images/landing/cloudshifu-logo-big.png";
import EllipseCircle from "../../assets/images/landing/ellipse-circle.svg";
import underline from "../../assets/images/landing/underline-orange.svg";
import UserProfileImage from "../../assets/images/landing/user-profile-img.svg";
import Login from "../Login";
import Register from "../Register";
import { enableRegisterFlag } from "../slice/userSlice";
import "./signup_new.css";
const Signgroup = (props) => {
  const [basicActive, setBasicActive] = useState("tab1");
  const { isRegisterFlag } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handleClose = (event) => {
    dispatch(enableRegisterFlag(false));
  };

  useEffect(() => {
    if (props.page_type === "signin") {
      setBasicActive("tab1");
    } else {
      setBasicActive("tab2");
    }
  }, [props]);
  const tabChange = () => {
    if (basicActive === "tab2") {
      setBasicActive("tab1");
    } else {
      setBasicActive("tab2");
    }
  };
  return (
    <>
      {isRegisterFlag ? (
        <Snackbar
          open={true}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Successfully register user
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
      {/* old code  */}
      {/* <div className="login-bg">
        <div>
          <img src={loginImg} alt="left-img" className="left-img" />

          <div className="left-text">
            <p className="para-text-1">
              Making your multi cloud operation more
            </p>
            <p className="para-text-2">
              <span>visible, efficient and simple.</span>
            </p>
          </div>
        </div>
        <div>
          <img src={yellowBg} alt="yellow-bg" className="yellow-background" />
          <Container>
            <div className="plat-home-page">
              <div className="plat-home-logo"></div>
              <Row>
                <Col lg="8" md="7">
                  <Row>
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="d-flex justify-content-center align-items-start"
                    ></Col>
                  </Row>
                </Col>
                <Col lg="4" md="5" style={{ "z-index": "1" }}>
                  <div className="plat-sign-login-tap mt-2 pt-5">
                    <MDBTabsContent>
                      <MDBTabsPane show={basicActive === "tab1"}>
                        <Login onSelectTab={tabChange} />
                      </MDBTabsPane>
                      <MDBTabsPane show={basicActive === "tab2"}>
                        <Register onSelectTab={tabChange} />
                      </MDBTabsPane>
                    </MDBTabsContent>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div> */}
      {/* new code  */}
      <div className="login-container ">
        <div className="login-bg-img">
          <div className="login-bg">
            <div>
              <Container>
                <div className="plat-home-page">
                  <div className="plat-home-logo"></div>
                  <Row>
                    <Col
                      lg="6"
                      md="6"
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div>
                            <div className="left-text">
                              <p className="para-text-1">
                                Making your multi cloud operation
                                <br /> more
                                <span className="para-text-2">
                                  <span> visible, efficient and simple.</span>
                                </span>
                              </p>
                            </div>
                            <div className="cloudshifu-text">CloudShifu.Ai</div>
                            <div className="cloudshifu-underline">
                              <img src={underline} />
                            </div>
                            <div className="cloudshifu-book-demo">
                              <a
                                href="https://www.1cloudhub.com/contact-us/"
                                target="_blank"
                              >
                                <Button className="">
                                  Book a Demo &nbsp;&nbsp;
                                  <Icon icon={"ph:arrow-right"} />
                                </Button>
                              </a>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      lg="6"
                      md="6"
                      style={{
                        "z-index": "1",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div className="position-relative">
                        <div className="user-profile-image">
                          <img
                            src={UserProfileImage}
                            className="user-profile-Image"
                          />
                        </div>
                        <div className="cloushifu-logo">
                          <img
                            src={CloudShifuLogoBig}
                            className="cloudShifu-Image"
                            height={400}
                          />
                        </div>
                        <div className="ellipse-circle">
                          <img src={EllipseCircle} />
                        </div>
                      </div>
                      <div className="plat-sign-login-tap mt-2 pt-5">
                        <MDBTabsContent>
                          <MDBTabsPane show={basicActive === "tab1"}>
                            <Login onSelectTab={tabChange} />
                          </MDBTabsPane>
                          <MDBTabsPane show={basicActive === "tab2"}>
                            <Register onSelectTab={tabChange} />
                          </MDBTabsPane>
                        </MDBTabsContent>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-column">
          <div className="signin-home-1">
            <img height={20} src={header} alt="Capture" /> &nbsp;
            <img height={20} src={headertext} alt="Capture_text" /> &nbsp;
          </div>
          <br></br>
          <div>
            <img height={20} src={google} alt="Capture" /> &nbsp;
            <img height={20} src={apple} alt="Capture_text" /> &nbsp;
          </div>
        </div>
        <div class="footer-column">
          <div class="footer-heading">Contact us</div>
          <p class="footer-body">Singapore</p>
          <p class="footer-body">India</p>
          <p class="footer-body">United States</p>
          <p class="footer-body">Philippines</p>
          <p class="footer-body">Malaysia</p>
        </div>
        <div class="footer-column">
          <div class="footer-heading">Categories</div>
          <p class="footer-body">Our Offering</p>
          <p class="footer-body">Blogs</p>
          <p class="footer-body">Case Studies</p>
          <p class="footer-body">FAQs</p>
          <p class="footer-body">Privacy Policy</p>
        </div>
        <div class="footer-column">
          <div class="footer-heading">About</div>
          <p class="footer-body">Our Story</p>
          <p class="footer-body">Leadership</p>
          <p class="footer-body">Media</p>
          <p class="footer-body">Careers</p>
        </div>

        <div class="footer-column">
          <div class="footer-heading">Follow Us</div>
          <div class="social-icons">
            <span class="social-icon">
              <Icon icon="il:instagram" class="soc-icon" />
            </span>{" "}
            <span class="social-icon">
              <Icon icon="ic:baseline-facebook" class="soc-icon" />
            </span>{" "}
            <span class="social-icon">
              <Icon icon="mdi:youtube" class="soc-icon" />
            </span>{" "}
            <span class="social-icon">
              <Icon icon="mdi:twitter" class="soc-icon" />
            </span>
          </div>
        </div>
      </div>
      <div class="footer">© CloudShifu.Ai 2023. All Rights Reserved</div>

      {/* <CustimizeFooter /> */}
    </>
  );
};

export default Signgroup;
