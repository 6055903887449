import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OperationTapmenu from '../OperationTapmenu';
import PlannedTable from './PlannedTable';
import CustimizeFooter from '../../dashboard-v2/CustimizeFooter';


const PlannedOpenActivity = () => {
    return(
        <>
            <div className='plat-operation-body'>
                <div className='plat-dashboard-tabs'>
                    <OperationTapmenu 
                        excalname='Planned & Open activity' 
                        type="planned open activity"
                        title = "Planned & Open activity"
                    />
                     <div className='plat-full-asset-spend-cover'>
                        <Row className='budget-mt'>
                            <Col>
                               <PlannedTable />
                            </Col>
                        </Row>
                     </div>
                </div>
            </div>
            <CustimizeFooter />
        </>
    )
}

export default PlannedOpenActivity;