import React, { useEffect, useState } from "react";
import { FadeIn } from "react-anim-kit";
import { ConditionallyRender } from "react-util-kit";
import { useChatContext, createChatBotMessage } from "react-chatbot-kit";
import { Spin } from "antd";
import styles from "./InformationBox/InformationBox.module.css";
import InformationBox from "./InformationBox/InformationBox";
import CreditRequest from "../MessageTemplates/CreditRequest";
import Credits from "../MessageTemplates/Credits";
import { requestMessage, getPromptsList } from "../ChatbotApiCalls";
import Loading from "../Loading";
import { useSelector, useDispatch } from "react-redux";

const Prompts = ({ infoBox, setState }) => {
  const [cloudstatus, setCloudStatus] = useState(true);
  const [promptstatus, setpromptstatus] = useState(false);
  const [category, setCategory] = useState(false);
  const [cloudname, setcloudname] = useState();
  const [customername, setcustomername] = useState();
  const [categoryname, setCategoryname] = useState();
  const [cloudtype, setCloudType] = useState("");
  const [promptslist, setPromptsList] = useState("");
  const [loaderstatus, setLoaderStatus] = useState(false);
  const { cloudList } = useSelector((state) => state.constimize);
  const dispatch = useDispatch();
  //   const { setState } = useChatContext();

  useEffect(() => {
    setState((state) => ({
      ...state,
      infoBox: "messageParser",
    }));
  }, [setState]);

  const onclickCloudtype = (type, companyName) => {
    setcloudname(type);
    setcustomername(companyName);
    // setLoaderStatus(true);
    setCategory(true);
    setCloudStatus(false);
  };
  const onclickCategory = (choice) => {
    setCategory(false);
    //
    // setpromptstatus(true);
    setLoaderStatus(true);
    const input = {
      cloud: cloudname.toLowerCase(),
      prompt_type: choice,
      schema: localStorage.getItem("CognitoIdentityServiceProvider.company"),
    };
    dispatch(getPromptsList(input))
      .unwrap()
      .then(({ data }) => {
        setLoaderStatus(false);
        const records = JSON.parse(data.chatbot_prompts);
        setPromptsList(records);
        setCloudStatus(false);
        setCloudType(choice);
        setpromptstatus(true);
        setLoaderStatus(false);
      })
      .catch((err) => {
        setPromptsList([]);
        setLoaderStatus(false);
      });
  };

  const onclickCloudPrompts = (message) => {
    setState((state) => ({ ...state, infoBox: "" }));
    handleLoader();
    const input = {
      message: message,
      date_time: `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()}`,
      schema: localStorage.getItem("CognitoIdentityServiceProvider.company"),
      user_name: localStorage.getItem("CognitoIdentityServiceProvider.auth"),
      cloud: cloudname.toLowerCase(),
      user_session: localStorage.getItem("_token"),
    };
    dispatch(requestMessage(input))
      .unwrap()
      .then(({ data }) => {
        const records = JSON.parse(data.chatbot_chat);
        deleteRecord();
        if (records.remaining_credits <= 0) {
          const message_1 = createChatBotMessage(
            <CreditRequest
              message="We have reached our limit for the day and are unable to accept further messages at the moment."
              total={records.total_credits}
              remaining={0}
              ChatbotMessage={ChatbotMessage}
              handleLoader={handleLoader}
            />
          );
          addMessageToBotState(message_1);
        } else {
          const message_1 = createChatBotMessage(
            <Credits
              message={records.chat}
              total={records.total_credits}
              remaining={records.remaining_credits}
            />
          );
          addMessageToBotState(message_1);
        }
      })
      .catch((err) => {
        console.log(err);
        deleteRecord();
        const message_1 = createChatBotMessage(
          "I'm sorry, I'm unable to comprehend your question. Can you please provide more specific information or ask a different question?"
        );
        addMessageToBotState(message_1);
      });
  };
  const ChatbotMessage = (message) => {
    const message_1 = createChatBotMessage(message);
    addMessageToBotState(message_1);
  };
  const addMessageToBotState = (message) => {
    setState((state) => ({
      ...state,
      messages: [...state.messages, message],
    }));
  };
  const handleLoader = () => {
    const message_1 = createChatBotMessage(<Loading />);
    addMessageToBotState(message_1);
  };

  const deleteRecord = () => {
    setState((prev) => ({
      ...prev,
      messages: prev.messages.slice(0, -1),
    }));
  };

  function generateTransactionId(length) {
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let transactionId = "";

    for (let i = 0; i < length; i++) {
      let randomIndex = Math.floor(Math.random() * characters.length);
      transactionId += characters.charAt(randomIndex);
    }

    return transactionId;
  }
  const showMessageParserInfoBox = infoBox === "messageParser";

  return (
    <div className={styles.overview}>
      <FadeIn left by={250}>
        {/* <MessageParserOverview className={styles.overviewSVG} /> */}
      </FadeIn>

      <ConditionallyRender
        ifTrue={showMessageParserInfoBox}
        show={
          <InformationBox setState={setState}>
            <Spin spinning={loaderstatus}>
              <h1 className={styles.prompts_title}>Prompts Library</h1>
              {cloudstatus && (
                <>
                  {cloudList.map((cloud) => (
                    <button
                      className={styles.button_6}
                      onClick={() =>
                        onclickCloudtype(
                          cloud,
                          localStorage.getItem(
                            "CognitoIdentityServiceProvider.company"
                          )
                        )
                      }
                    >
                      {cloud}
                    </button>
                  ))}
                </>
              )}
              {category && (
                <>
                  <button
                    className={styles.button_6}
                    onClick={() => onclickCategory("spend")}
                  >
                    Spend
                  </button>
                  <button
                    className={styles.button_6}
                    onClick={() => onclickCategory("recommendation")}
                  >
                    Recommendation
                  </button>
                  <button
                    className={styles.button_6}
                    onClick={() => onclickCategory("budget")}
                  >
                    Budget
                  </button>
                </>
              )}
              {promptstatus && (
                <>
                  {promptslist.map((data) => (
                    <>
                      <button
                        className={styles.button_7}
                        onClick={() => onclickCloudPrompts(data.prompts)}
                      >
                        {data.prompts}
                      </button>
                    </>
                  ))}
                </>
              )}
            </Spin>
          </InformationBox>
        }
      />
    </div>
  );
};

export default Prompts;
