import { Col, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  tcoanalysisrightsizing,
  summarytco,
} from "../../components/action/graphql/a2crover/A2CCostAnalysisREducer";

function TCO_Analysis() {
  const dispatch = useDispatch();
  const company = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [tablerecord, settablerecord] = useState([]);
  const [tableSummary, setTableSummary] = useState([]);
  const columnssummary = [
    {
      title: "AWS Pricing Plan",
      dataIndex: "aws_pricing_plan",
      key: "aws_pricing_plan",
      // width: 200,
      className: "devops-table-header",
      align: "left",
    },
    {
      title: "Estimated Cost",
      dataIndex: "estimated_cost",
      key: "estimated_cost",
      // width: 100,
      className: "devops-table-header",
      align: "left",
    },
  ];
  const data = [];
  const columns = [
    {
      title: "Application",
      dataIndex: "application",
      key: "application",
      align: "left",
      className: "devops-table-header",
      width: 200,
    },
    {
      title: "Environment",
      dataIndex: "environment",
      key: "environment",
      align: "right",
      className: "devops-table-header",
    },
    {
      title: "On Demand",
      dataIndex: "on_demand",
      key: "on_demand",
      align: "right",
      className: "devops-table-header",
    },
    {
      title: "RI 1yr",
      dataIndex: "ri_1yr",
      key: "ri_1yr",
      align: "right",
      className: "devops-table-header",
    },
    {
      title: "RI 3yr",
      dataIndex: "ri_3yr",
      key: "ri_3yr",
      align: "right",
      className: "devops-table-header",
    },
  ];
  const input = {
    company: "bi_worldwide",
    os_license: "No license",
  };

  useEffect(() => {
    dispatch(tcoanalysisrightsizing(input))
      .unwrap()
      .then(({ data }) => {
        const record = data.cost_analysis_tco_table.map((datas) => {
          return {
            application: datas.application,
            environment: datas.environment,
            on_demand: datas.on_demand,
            ri_1yr: datas.ri_1yr,
            ri_3yr: datas.ri_3yr,
          };
        });
        settablerecord(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    dispatch(summarytco(input))
      .unwrap()
      .then(({ data }) => {
        const record = data.cost_analysis_tco_summary.map((datas) => {
          return {
            aws_pricing_plan: datas.aws_pricing_plan,
            estimated_cost: datas.estimated_cost,
          };
        });
        setTableSummary(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <Table
        columns={columnssummary}
        dataSource={tableSummary}
        pagination={false}
        className="slm-table"
      />
      <br></br>
      {/* <Row gutter={[16, 16]}>
        <Col span={24}> */}
      <Table
        columns={columns}
        dataSource={tablerecord}
        pagination={false}
        className="slm-table"
        // scroll={{ x: 2000 }}
      />

      {/* </Col>
      </Row> */}
    </div>
  );
}

export default TCO_Analysis;
