import React from "react";
import { Table } from "antd";

function CloudCostComparision() {
  const dataSource = [
    {
      key: "1",
      aws_instance: "t3.small",
      aws_cost: "$19.27",
      azure_instance: "Standard_B1s",
      azure_cost: "$10.22",
      gcp_instance: "e2-medium",
      gcp_cost: "$24.46",
    },
    {
      key: "2",
      aws_instance: "t2.micro",
      aws_cost: "$10.66",
      azure_instance: "Standard_B1ms",
      azure_cost: "$17.96",
      gcp_instance: "n1-standard-1",
      gcp_cost: "$34.67",
    },
    {
      key: "3",
      aws_instance: "t3.nano",
      aws_cost: "$4.82",
      azure_instance: "Standard_B2als",
      azure_cost: "$13.13",
      gcp_instance: "e2-micro",
      gcp_cost: "$6.11",
    },
    {
      key: "4",
      aws_instance: "t3a.small",
      aws_cost: "$17.23",
      azure_instance: "Standard_B2s",
      azure_cost: "$36.21",
      gcp_instance: "n1-standard-2",
      gcp_cost: "$69.35",
    },
    {
      key: "5",
      aws_instance: "r6gd.medium",
      aws_cost: "$50.74",
      azure_instance: "Standard_B2ms",
      azure_cost: "$66.58",
      gcp_instance: "n1-standard-4",
      gcp_cost: "$138.70",
    },
    {
      key: "6",
      aws_instance: "t3a.large",
      aws_cost: "$68.91",
      azure_instance: "Standard_B2ls",
      azure_cost: "$37.08",
      gcp_instance: "e2-highcpu-2",
      gcp_cost: "$36.11",
    },
    {
      key: "7",
      aws_instance: "t4g.medium",
      aws_cost: "$30.95",
      azure_instance: "Standard_B4ms",
      azure_cost: "$132.86",
      gcp_instance: "n1-standard-8",
      gcp_cost: "$277.40",
    },
    {
      key: "8",
      aws_instance: "c6i.large",
      aws_cost: "$71.54",
      azure_instance: "Standard_B8ms",
      azure_cost: "$266.45",
      gcp_instance: "n1-standard-16",
      gcp_cost: "$554.80",
    },
    {
      key: "9",
      aws_instance: "c6id.large",
      aws_cost: "$171.70",
      azure_instance: "Standard_B8ls",
      azure_cost: "$242.36",
      gcp_instance: "e2-highcpu-8",
      gcp_cost: "$144.45",
    },
    {
      key: "10",
      aws_instance: <p className="text-orange m-0">Total</p>,
      aws_cost: <p className="text-orange m-0">$445.82</p>,
      azure_instance: <p className="text-orange m-0">Total</p>,
      azure_cost: <p className="text-orange m-0">$822.85</p>,
      gcp_instance: <p className="text-orange m-0">Total</p>,
      gcp_cost: <p className="text-orange m-0">$1286.05</p>,
    },
  ];

  const columns = [
    {
      title: "AWS Instance Type",
      dataIndex: "aws_instance",
      key: "aws_instance",
    },
    {
      title: "AWS Cost",
      dataIndex: "aws_cost",
      key: "aws_cost",
    },
    {
      title: "Azure Instance Type",
      dataIndex: "azure_instance",
      key: "azure_instance",
    },
    {
      title: "Azure Cost",
      dataIndex: "azure_cost",
      key: "azure_cost",
    },
    {
      title: "Gcp Instance Type",
      dataIndex: "gcp_instance",
      key: "gcp_instance",
    },
    {
      title: "Gcp Cost",
      dataIndex: "gcp_cost",
      key: "gcp_cost",
    },
  ];

  return <Table className="slm" dataSource={dataSource} columns={columns} />;
}

export default CloudCostComparision;
