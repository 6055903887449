import React from "react";
import SavingsOppurtunity from "../savings_oppurtunity/SavingsOppurtunity";
import SpotQuickGlance from "../savings_oppurtunity/SpotQuickGlance";
import { Col, Row, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
function IndexoOfAnnualCost() {
  // const items = [
  //   {
  //     key: "1",
  //     label: "Cost Savings",
  //     children: <SavingsOppurtunity />,
  //   },
  //   {
  //     key: "2",
  //     label: "Spot Cost Savings",
  //     children: <SpotQuickGlance />,
  //   },
  // ];

  return (
    <div>
      <SavingsOppurtunity />
      {/* <Tabs defaultActiveKey="1" className="qg-custom-tabs">
        {items.map((item) => (
          <TabPane tab={item.label} key={item.key}>
            {item.children}
          </TabPane>
        ))}
      </Tabs> */}
    </div>
  );
}

export default IndexoOfAnnualCost;
