import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const OperationTapmenu = (props) =>{
   
    

    return(
        <>
            
                <Row className='custimize-header'>
                    <div className='custimize-body-title'>
                        <h1 className='top-Plat-quick-title'>{props.title}</h1> 
                        {/* <div className='bottom-header-border'></div> */}
                    </div>
                </Row>
                {/* <Row>
                    <Col lg={9}>
                        <Row>
                            <Col className='mb-2 down-date-short' lg={2}>
                                <h1 className='date-period-title'>
                                    Period
                                </h1>
                            </Col>
                            <Col className='mb-2 down-date-list-short' lg={1}>
                                <div className={'date-period-box '} >
                                    <h1 className='date-period-list'>
                                    Current Month
                                    </h1>
                                </div>
                            </Col>
                            <Col className='mb-2 down-date-list-short' lg={1} >
                                <div className={'date-period-box '} >
                                    <h1 className='date-period-list'>
                                    Last Month
                                    </h1>
                                </div>
                            </Col>
                            <Col className='mb-2 down-date-list-short' lg={1} >
                            <div className={'date-period-box '} >
                                    <h1 className='date-period-list' >
                                    Last 3 Months
                                    </h1>
                                </div>
                            </Col>
                            <Col className='mb-2 down-date-list-short' lg={1}>
                            <div className={'date-period-box '} >
                                    <h1 className='date-period-list'>
                                    Last 6 Months
                                    </h1>
                                </div>
                            </Col>
                            <Col className='mb-2 down-date-list-short' lg={1}>
                                <div className={'date-period-box '} >
                                    <h1 className='date-period-list'>
                                    Last 12 Months
                                    </h1>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>  */}
        </>
    );
}
export default OperationTapmenu;