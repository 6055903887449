import { createAsyncThunk } from "@reduxjs/toolkit";

import { useEffect } from "react";
import {
  ADD_CONFIG_QUERY,
  APPROVE_QUERY,
  APPROVE_QUERY_BY_1CH,
  APPROVE_REQUEST_QUERY,
  BASIC_CONFIG_ADD_UPDATE,
  GET_APPROVAL_PREVIEW_QUERY,
  GET_OS_DETAILS_QUERY,
  LIST_DEVOPS_ACCOUNT,
  LIST_DEVOPS_CATEGORY,
  LIST_REQUEST_TABLE,
  LIST_REQUEST_TABLE_ALL,
  LIST_REQUEST_TABLE_USER,
  REJECT_QUERY,
  REJECT_QUERY_1CH_ADMIN,
  SAVE_CONFIGURE_QUERY,
  SAVE_UPDATE_CONFIGURE_QUERY,
  SELECT_DROPDOW_QUERY,
  SEND_EMAIL_QUERY,
} from "./DevOpsQuery";
import client_new from "../../client/dashboardapplonewClinet";
import { useErrorNavigation } from "../../../custom_hook/CustomHook";

export const listDevopsAccount = createAsyncThunk(
  "devops/dashboard",
  async (data, { rejectWithValue }) => {
    try {
      const datasList = await client_new.mutate({
        mutation: LIST_DEVOPS_ACCOUNT,
        variables: {
          customer: data.customer,
        },
      });
      return datasList;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // if (error.networkError && error.networkError.statusCode === 500) {
      //     window.location.href = "/signin";
      //   }

      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        return rejectWithValue(error.graphQLErrors[0].message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listDevopscategory = createAsyncThunk(
  // action type string
  "devops/dashboard",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const datasListCategory = await client_new.mutate({
        mutation: LIST_DEVOPS_CATEGORY,
        variables: {
          cloud_platform: data.cloud_platform,
        },
      });
      return datasListCategory;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listRequestTableforAll = createAsyncThunk(
  // action type string
  "devops/request_table",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const datasListCategory = await client_new.mutate({
        mutation: LIST_REQUEST_TABLE_ALL,
        variables: {
          customer_name: data.companyName,
          // customer_name: "1",
        },
      });
      return datasListCategory;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const listRequestTable = createAsyncThunk(
  // action type string
  "devops/request",
  // callback function
  async (data, { rejectWithValue }) => {
    const user_id = localStorage.getItem("CognitoIdentityServiceProvider.auth");
    try {
      const datasListCategory = await client_new.mutate({
        mutation: LIST_REQUEST_TABLE_USER,
        variables: {
          created_by: data.created_by_user,
          customer_name: data.companyName,
        },
      });
      return datasListCategory;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addUpdateForm = createAsyncThunk(
  // action type string
  "devops/add_edit",
  // callback function
  async (data, { rejectWithValue }) => {
    const customer = localStorage.getItem(
      "CognitoIdentityServiceProvider.company"
    );
    try {
      const save_basic_details = await client_new.mutate({
        mutation: BASIC_CONFIG_ADD_UPDATE,
        variables: {
          event_type: data.values.event_type,
          account_name: data.accountDetailsInfo[0].account_name,
          activity_type: data.basicDetailsInfo.activity_type,
          configurations: data.configDetailsInfo,
          created_by: "123",
          customer_name: customer,
          cx_approval_email: data.accountDetailsInfo[0].customer_approval_email,
          cx_comments: "",
          job_name: "123",
          och_approval_email: data.accountDetailsInfo[0].och_approval_email,
          och_comments: "",
          operation: data.basicDetailsInfo.operation,
          status: data.values.status,
          service_name: data.basicDetailsInfo.service_name,
          cloud_platform: data.basicDetailsInfo.cloud_platform,
        },
      });
      return save_basic_details;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const saveUpdateConfigureForm = createAsyncThunk(
  // action type string
  "devops/add_edit",
  // callback function
  async (data, { rejectWithValue }) => {
    const customer = localStorage.getItem(
      "CognitoIdentityServiceProvider.company"
    );
    try {
      const save_basic_details = await client_new.mutate({
        mutation: SAVE_UPDATE_CONFIGURE_QUERY,
        variables: {
          event_type: data.values.event_type,
          id: data.values.id,
          account_name: data.accountDetailsInfo[0].account_name,
          activity_type: data.basicDetailsInfo.activity_type,
          configurations: JSON.stringify(data.values),
          created_by: data.values.created_by,
          customer_name: customer,
          cx_approval_email: data.accountDetailsInfo[0].customer_approval_email,
          cx_comments: "",
          job_name: data.jobName,
          och_approval_email: "",
          och_comments: "",
          operation: data.basicDetailsInfo.operation,
          status: data.values.status,
          service_name: data.basicDetailsInfo.service_name,
          cloud_platform: data.basicDetailsInfo.cloud_platform,
        },
      });
      return save_basic_details;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addConfigureForm = createAsyncThunk(
  // action type string
  "devops/add",
  // callback function
  async (data, { rejectWithValue }) => {
    const customer = localStorage.getItem(
      "CognitoIdentityServiceProvider.company"
    );
    try {
      const add_details = await client_new.mutate({
        mutation: ADD_CONFIG_QUERY,
        variables: {
          event_type: data.values.event_type,
          id: data.values.id,
          status: data.values.status,
        },
      });
      return add_details;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const sendEmailStatus = createAsyncThunk(
  // action type string
  "devops/send_email",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const save_basic_details = await client_new.mutate({
        mutation: SEND_EMAIL_QUERY,
        variables: {
          event_type: data.event_type,
          cx_approval_email: data.accountDetailsInfo[0].customer_approval_email,
          id: data.values.id,
          customer_name: data.values.customer_name,
          cloud_platform: data.basicDetailsInfo.cloud_platform,
        },
      });
      return save_basic_details;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const approveRequest = createAsyncThunk(
  // action type string
  "devops/send_approve",
  // callback function

  async (data, { rejectWithValue }) => {
    // console.log(data.is_och_approved)

    try {
      const save_basic_details = await client_new.query({
        query: APPROVE_QUERY,
        variables: {
          event_type: data.values.event_type,
          och_approval_email: data.och_approved_by,
          cx_comments: data.comment,
          id: data.id,
          customer_name: data.customer_name,
          cx_approval_email: data.createdUserEmail,
          is_admin_approved: data.values.is_admin_approved,
          // is_och_approved: data.values.is_och_approved,
          cloud_platform: data.cloud_platform,
        },
      });
      return save_basic_details;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const rejectRequest = createAsyncThunk(
  // action type string
  "devops/send_Reject",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const save_basic_details = await client_new.query({
        query: REJECT_QUERY,
        variables: {
          event_type: data.values.event_type,
          cx_comments: data.values.comment,
          id: data.id,
          cx_approval_email: data.createdUserEmail,
          created_by: data.created_by,
          is_admin_approved: data.is_admin_approved,
          // is_och_approved: data.is_och_approved,
        },
      });
      return save_basic_details;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const rejectRequest1CHAdmin = createAsyncThunk(
  // action type string
  "devops/send_Reject_1ch_Admin",
  // callback function
  async (data, { rejectWithValue }) => {
    console.log(data.values.is_och_approved);
    try {
      const save_basic_details = await client_new.query({
        query: REJECT_QUERY_1CH_ADMIN,
        variables: {
          event_type: data.values.event_type,
          och_comments: data.values.comment,
          id: data.id,
          och_approval_email: data.createdUserEmail,
          created_by: data.created_by,
          cx_approval_email: data.cx_approval_email,
          // is_admin_approved: data.is_admin_approved,
          is_och_approved: data.values.is_och_approved,
        },
      });
      return save_basic_details;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const reintialize = createAsyncThunk(
  // action type string
  "devops/send_Reject",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const save_basic_details = await client_new.query({
        query: REJECT_QUERY_1CH_ADMIN,
        variables: {
          event_type: data.obj.event_type,
          id: data.obj.id,
          cx_comments: data.obj.cx_comments,
          och_comments: data.obj.och_comments,
          created_by: data.obj.createdUserEmail,
          och_approval_email: data.obj.createdUserEmail,
          cx_approval_email: data.obj.cx_approval_email,
          is_admin_approved: data.obj.is_admin_approved,
          is_och_approved: data.obj.is_och_approved,
          rejected_by: data.obj.rejected_by,
        },
      });
      return save_basic_details;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getDropDownValues = createAsyncThunk(
  // action type string
  "devops/get_dropdown",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const get_dropdown_values = await client_new.query({
        query: SELECT_DROPDOW_QUERY,
        variables: {
          cloud_platform: data.cloud_platform,
          field: data.fieldType,
        },
      });
      return get_dropdown_values;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const approveRequestBy1CHAdmin = createAsyncThunk(
  // action type string
  "devops/send_approve_by1ch",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const save_basic_details = await client_new.query({
        query: APPROVE_QUERY_BY_1CH,
        variables: {
          event_type: data.values.event_type,
          och_comments: data.comment,
          job_name: data.job_name,
          id: data.id,
          configurations: data.configurations,
          customer_name: data.customer_name,
          och_approval_email: data.createdUserEmail,
          // is_admin_approved: data.values.is_admin_approved,
          is_och_approved: data.is_och_approved,
          cloud_platform: data.cloud_platform,
        },
      });
      return save_basic_details;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOSOffer = createAsyncThunk(
  // action type string
  "devops/getOSOffer",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const save_basic_details = await client_new.query({
        query: GET_OS_DETAILS_QUERY,
        variables: {
          cloud_platform: data.cloud_platform,
          publisher: data.selectedValue,
          offer: data.osSku,
        },
      });
      return save_basic_details;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOSSKU = createAsyncThunk(
  // action type string
  "devops/getOSSKU",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const save_basic_details = await client_new.query({
        query: GET_OS_DETAILS_QUERY,
        variables: {
          cloud_platform: data.cloud_platform,
          publisher: data.selectedOffer,
          offer: data.selectedValue,
        },
      });
      return save_basic_details;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getApprovalAllValues = createAsyncThunk(
  // action type string
  "devops/getApprovalValues",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const save_basic_details = await client_new.query({
        query: GET_APPROVAL_PREVIEW_QUERY,
        variables: {
          id: data.id,
        },
      });
      return save_basic_details;
    } catch (error) {
      // window.location.href = "/signin";
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
