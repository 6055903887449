import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Col, Card, Checkbox, Button, Switch, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { listquestions, questionupdate, riskcountpillarwise } from "../action/graphql/a2crover/A2CReducer";
import { Icon } from "@iconify/react";
const { TextArea } = Input;

const Reliability = (props) => {


  const { selectWorkloadid } = useSelector((state) => state.a2cRover)
  const [Securitydata, setSecuritydata] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [countNumber, setCountNumber] = useState(0);
  const [description, setDescription] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({});
  const [toggle, setToggle] = useState(true);
  const [message, setMessage] = useState("");
  const [choiceequal, setChoiceEqual] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [listapivalui, setListAPIValue] = useState([])
  const [questions, setQuestions] = useState([]);
  const [optionvaluetick, setOptionValueTick] = useState()

  const dispatch = useDispatch();

  const choices = JSON.parse(
    Securitydata.find((item) => item.question_id === selectedQuestion)?.choices ||
    "[]"
  );

  const choiceid = JSON.parse(
    Securitydata.find((item) => item.question_id === selectedQuestion)?.choiceid ||
    "[]"
  );



  const handleChange1 = (event) => {
    setMessage(event.target.value);
  };

  const handleClick = () => {
    setToggle(!toggle);
  };

  const pillar_type = 'Reliability';

  const handleQuestionClick = (questionId) => {
    setSelectedQuestion(questionId);
    // console.log(questionId);
  };

  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };



  const handleOptionChange = (choiceId) => {
    setSelectedOptions((prevOptions) => {
      const selectedOptionsForQuestion = { ...(prevOptions[selectedQuestion] || {}) };
      setIsSubscribed(current => !current);

      if (selectedOptionsForQuestion[choiceId]) {
        delete selectedOptionsForQuestion[choiceId];
      } else {
        selectedOptionsForQuestion[choiceId] = choiceId;
      }
      console.log("Selected Choice ID:", choiceId);

      return {
        ...prevOptions,
        [selectedQuestion]: selectedOptionsForQuestion,
      };

    });
  };

  const handleSaveButtonClick = () => {

    setCountNumber((prevCount) => prevCount + 1);
    // setCountNumber(prevCount + 1) 

    // const optionvalue = selectedOptions[selectedQuestion] ? Object.keys(selectedOptions[selectedQuestion]) : [];
    // console.log(optionvalue)
    // const optionvalue = selectedOptions[selectedQuestion] ? Object.keys(selectedOptions[selectedQuestion]).join(', ') : '';
    // console.log(optionvalue);

   
    
    // const workloadid = "42b700bf7c38cbedfc27f087c62e9f36";
    const lensalias = "wellarchitected";

    const optionvalue = selectedOptions[selectedQuestion] ? Object.keys(selectedOptions[selectedQuestion]) : [];
    console.log(optionvalue);

    const input1 = {
      event_type: "question-update",
      workload_id: "451b7d3f2e8552f0d44cfca364b7606d",
      lens_alias: lensalias,
      question_id: selectedQuestion,
      selected_choices: "optionvalue",
      notes: description,
      is_applicable: toggle,
    };

    console.log(input1, "inputvalueforupdate");
    dispatch(questionupdate({ input1 }))
      .unwrap()
      .then(({ data }) => {
        console.log(data, "QUESTIONUPDATE");
        // Check if the question has already been counted
        if (!choiceequal.includes(selectedQuestion)) {
          setCountNumber((prevCount) => prevCount + 1);
          setChoiceEqual((prevChoiceEqual) => [...prevChoiceEqual, selectedQuestion]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const handleNextButtonClick = () => {
    if (currentQuestionIndex < Securitydata.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setSelectedQuestion(Securitydata[currentQuestionIndex + 1]?.question_id);
      setMessage("")
    }
  };

  const handleBackButtonClick = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
      setSelectedQuestion(Securitydata[currentQuestionIndex - 1]?.question_id);
    }
  };
  const lens_alias = "wellarchitected"
  const pillarid = "reliability"



  useEffect(() => {
    const questionlist = {
      event_type: "list-question",
      piller_type: "Reliability",
    };

    dispatch(listquestions({ questionlist }))
      .unwrap()
      .then(({ data }) => {
       
        const parsedData = JSON.parse(data.list_question_api);

        setSecuritydata(parsedData);
       
      })
      .catch((err) => {
        console.log(err);
      });


  }, []);

  useEffect(() => {
    const input1 = {
      event_type: "pillars-wise-count-list",
      lenses: "wellarchitected",
      pillar_id: "reliability",
      pillar_name: "Reliability",
      workload_id: "451b7d3f2e8552f0d44cfca364b7606d",
    };
    console.log("checking")
    dispatch(riskcountpillarwise({ input1 }))
      .unwrap()
      .then(({ data }) => {
        console.log(data, "risk-count-pillar-wise");

        const parsedData = JSON.parse(data.pillars_wisecount_list);

        const highRiskCount = parsedData.pillar_risk_counts.Reliability.High;
        const mediumRiskCount = parsedData.pillar_risk_counts.Reliability.Medium;
        const noneCount = parsedData.pillar_risk_counts.Reliability.None;
        const notApplicableCount = parsedData.pillar_risk_counts.Reliability["Not Applicable"];
        const riskDataQuestionTitles = parsedData.risk_data.map((item) => ({
          questionTitle: item.QuestionTitle,
          risk: item.Risk
        }));
        console.log(riskDataQuestionTitles)


        console.log("High Risk Count:", highRiskCount);
        console.log("Medium Risk Count:", mediumRiskCount);
        console.log("None Count:", noneCount);
        console.log("Not Applicable Count:", notApplicableCount);
        console.log("Risk Data Question Titles:", riskDataQuestionTitles);

      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  return (
    <div>
      <Row className="mt-2">
        <h4 className="titletab">Reliability</h4>
        <Button className="buttonclass">
          {countNumber}/{Securitydata.length}
        </Button>
        <h5 className="white-color font-of-qus ms-2 mt-2"><u>High</u><Icon
          icon={"ph:warning-bold"}
          style={{ color: "red", width: "15px" }}
        // onClick={showModalHigh}
        /></h5>
        <h5 className="white-color font-of-qus ms-2 mt-2">Medium <Icon
          icon={"ph:warning-bold"}
          style={{ color: "orange", width: "15px" }}
        // onClick={showModalMedium}
        /></h5>
        <h5 className="white-color font-of-qus ms-2 mt-2">None
          <Icon
            icon={"bi:x-circle"}
            style={{ color: "pink", width: "14px" }}
          // onClick={showModalNone}
          />
        </h5>
        <h5 className="white-color font-of-qus ms-2 mt-2">Not Applicable<Icon
          icon={"mi:ban"}
          style={{ color: "white", width: "15px" }}
        // onClick={showModalNotApplicable}
        /></h5>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xl={11} lg={12} md={12}>
          <Card className="operate-cards mt-4 scroll-container">
            <div>
              {Array.isArray(Securitydata) && Securitydata.map((item) => (
                <div className="white-color font-of-qus mt-2" key={item.question_id} onClick={() => handleQuestionClick(item.question_id)}>
                  <div>
                    <span>
                      <h5 className="white-color font-of-qus">
                        <span className="color-orange">{item.question_no}. </span>
                        {item.question_title}
                      </h5>
                    </span>
                    <hr style={{ color: "grey" }} />
                  </div>
                </div>
              ))}
            </div>
          </Card>
        </Col>
        <Col xl={1} lg={1} md={1}></Col>
        <Col xl={11} lg={11} md={11}>
          {selectedQuestion !== null && (
            <Card className="operate-cards mt-4 scroll-container">
              <h5 className="white-color font-of-qus mt-3">
                <span style={{ Color: "color-orange" }}>QUESTION:{Securitydata.find((item) => item.question_id === selectedQuestion)?.question_no.replace(/^Q/, '')}</span>
                <br /><br />
                {Securitydata.find((item) => item.question_id === selectedQuestion)?.question_title}
              </h5>
              <Row><Col>
                <Switch className="war_switch"
                  onClick={handleClick}
                />{" "}
              </Col><Col><p className="color-orange ms-2">This Question is Not Applicable</p></Col></Row>
              <ul>
                {choices.map((choice, index) => (
                  <li key={index}>
                    <Checkbox
                      className="mt-2 white-color font-of-qus mt-2"
                      // defaultChecked={true}
                      onChange={() => handleOptionChange(choiceid[index])}

                      // value={isSubscribed}
                      id="subscribe"
                      name="subscribe"
                      checked={selectedOptions[selectedQuestion]?.[choiceid[index]] === choiceid[index]}
                    >
                      {choice}
                    </Checkbox>
                  </li>
                ))}
              </ul>
              <ul>
              </ul>
              <Row>
                <Col span={24}>
                  <TextArea
                    rows={3}
                    className="textareacls  white-color"
                    onChange={handleChange1}
                    value={message}
                    placeholder="Message"
                  />
                </Col>
              </Row>
              <Row className="mt-3" gutter={[16, 16]} style={{ display: "flex", justifyContent: "center" }}>
                <Col>
                  <Button className="buttonclass" onClick={handleBackButtonClick}>
                    BACK
                  </Button>
                </Col>
                <Col>
                  <Col>
                    <Button
                      onClick={handleSaveButtonClick}
                      disabled={!selectedOptions[selectedQuestion] && toggle}
                    >
                      SAVE
                    </Button>
                  </Col>
                </Col>
                <Col>
                  <Button className="buttonclass ms-1"
                    onClick={handleNextButtonClick}
                  >
                    NEXT
                  </Button>
                </Col>
              </Row>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Reliability;
