import React from "react";
import { Card, Progress } from "antd";
import ReactApexChart from "react-apexcharts";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Modal,
} from "antd";
import "./asset.css";
import Plangraphs from "./Plangraphs.js";
import TcoanalysisTable from "./TcoanalysisTable.js";
import RangeChartAssets from "./RangeChartAssets";
function AssestsPlandandDesign() {
  const chartOptions = {
    chart: {
      type: "area",
    },
    xaxis: {
      categories: [
        "Jan1,2021",
        "Jan 10,2021",
        "Jan 17,2021",
        "Jan 24,2021",
        "Jan 31,2021",
        "Feb 7,2021",
        "Feb 14,2021",
        "Feb 28,2021",
        "Mar 7,2021",
      ],
      labels: {
        style: {
          colors: "#FFFFFF",
        },
      },
    },
  };
  const chartData = [
    {
      name: "Series 1",
      data: [0, 10, 25, 15, 20, 30, 43, 28, 45],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.3,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
      stroke: {
        curve: "smooth", // Enable smooth curve
        color: "#FFFF", // Specify the curve color
      },
    },
  ];
  chartOptions.dataLabels = {
    enabled: false,
  };
  chartOptions.stroke = {
    width: 2,
    curve: "smooth", // Enable smooth curve
  };

  return (
    <div>
      <div className="migroot">
        <h2 className="heading">My Dashboard</h2>
        <div className="cards5">
          <Card title={"DC"} bordered={false}>
            <div className="selectst">
              <Form>
                <Form.Item>
                  <Select
                    showSearch
                    placeholder="Select"
                    options={[
                      {
                        label: "DC-SGPO1 ,DC-SGP02",
                        value: "DC-SGPO1 ,DC-SGP02",
                      },
                      {
                        label: "equals",
                        value: "equals",
                      },
                    ]}
                  />
                </Form.Item>
              </Form>
            </div>
          </Card>

          <Card title="Application" bordered={false}>
            <div className="selectst">
              <Form>
                <Form.Item>
                  <Select
                    showSearch
                    style={{
                      width: "100%",
                    }}
                    placeholder="Select"
                    options={[
                      {
                        label: "DC-SGPO1 ,DC-SGP02",
                        value: "DC-SGPO1 ,DC-SGP02",
                      },
                      {
                        label: "equals",
                        value: "equals",
                      },
                    ]}
                  />
                </Form.Item>
              </Form>
            </div>
          </Card>
          <Card title="Environment" bordered={false}>
            <div className="selectst">
              <Form>
                <Form.Item>
                  <Select
                    showSearch
                    style={{
                      width: "100%",
                    }}
                    placeholder="Select"
                    options={[
                      {
                        label: "DC-SGPO1 ,DC-SGP02",
                        value: "DC-SGPO1 ,DC-SGP02",
                      },
                      {
                        label: "equals",
                        value: "equals",
                      },
                    ]}
                  />
                </Form.Item>
              </Form>
            </div>
          </Card>

          <Card title="OS" bordered={false}>
            <div className="selectst">
              <Form>
                <Form.Item>
                  <Select
                    showSearch
                    style={{
                      width: "100%",
                    }}
                    placeholder="Select"
                    options={[
                      {
                        label: "DC-SGPO1 ,DC-SGP02",
                        value: "DC-SGPO1 ,DC-SGP02",
                      },
                      {
                        label: "equals",
                        value: "equals",
                      },
                    ]}
                  />
                </Form.Item>
              </Form>
            </div>
          </Card>
          <Card title="Database" bordered={false}>
            <div className="selectst">
              <Form>
                <Form.Item>
                  <Select
                    showSearch
                    style={{
                      width: "100%",
                    }}
                    placeholder="Select"
                    options={[
                      {
                        label: "DC-SGPO1 ,DC-SGP02",
                        value: "DC-SGPO1 ,DC-SGP02",
                      },
                      {
                        label: "equals",
                        value: "equals",
                      },
                    ]}
                  />
                </Form.Item>
              </Form>
            </div>
          </Card>
        </div>
        <div className="boxes">
          <p className="leftas">Assets Prespective</p>
          <p className="centeras">Analysis Prespective</p>
          <p className="rightas">Plan and Design</p>
        </div>
        <div className="cards6">
          <Row gutter={32}>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Card
                title="Phase Wise App Migration"
                bordered={false}
                className="cd"
              >
                <RangeChartAssets />
              </Card>
            </Col>

            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Card
                title="Resource Count Vs Planned Migration Date"
                bordered={false}
                className="cd1"
              >
                <ReactApexChart
                  options={chartOptions}
                  series={chartData}
                  type="area"
                  height={250}
                ></ReactApexChart>
              </Card>
            </Col>
          </Row>
        </div>
        <Plangraphs />
        <TcoanalysisTable />
      </div>
    </div>
  );
}

export default AssestsPlandandDesign;
