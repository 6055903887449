import { Card, Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import {
  getOperateTCO,
  getOperateWAF,
  getOperateCMA,
} from "../action/graphql/a2crover/A2CReducer";

function OperateAnalysisPerspective() {
  const dispatch = useDispatch();

  const company = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [isShowSpinner, setIsShowSpinner] = useState(true);

  const {
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedou,
  } = useSelector((store) => store.a2cRover);

  const input = {
    company: company,
    account_name: selectedAccount.toString(),
    application: selectedApplication.toString(),
    environment: selectedEnvironment.toString(),
    organiation_unit: selectedou.toString(),
  };

  const [waf, setWAF] = useState([]);

  const waf_series = [];
  const waf_label = [];
  for (let key in waf) {
    if (waf.hasOwnProperty(key)) {
      if (waf[key] !== "operate_waf_count") {
        waf_series.push(waf[key]);
        waf_label.push(key);
      }
    }
  }
  const numeric_waf_series = waf_series.map((value) => parseInt(value, 10));
  const [tco, setTCO] = useState([]);
  const tco_labels = tco.map((item) => item.ou);
  const tco_series = tco.map((item) => item.sumofcost);
  const numeric_tco_series = tco_series.map((value) => parseInt(value, 10));
  const [cma, setCMA] = useState([]);
  const cma_labels = cma.map((item) => item.database);
  const cma_series = cma.map((item) => item.db_count);
  const numeric_cma_series = cma_series.map((value) => parseInt(value, 10));

  useEffect(() => {
    dispatch(getOperateWAF(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);
        setWAF(data.operate_waf_spread[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    // second api
    dispatch(getOperateTCO(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.operate_tco_spread.map((datas) => {
          return {
            ou: datas.ou,
            sumofcost: datas.sumofcost,
          };
        });
        setTCO(record);
      })
      .catch((err) => {
        console.log(err);
      });
    // third api
    dispatch(getOperateCMA(input))
      .unwrap()
      .then(({ data }) => {
        setIsShowSpinner(false);

        const record = data.operate_cma_spread.map((datas) => {
          return {
            database: datas.database,
            db_count: datas.db_count,
          };
        });
        setCMA(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedEnvironment, selectedAccount, selectedApplication, selectedou]);

  const waf_options = {
    chart: {
      type: "donut",
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "25px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#ff6a2f",
              offsetY: 0,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "",
              color: "#ff6a2f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    dataLabels: {
      formatter: function (val) {
        // Remove the percentage symbol from the labels
        return val.toFixed(0);
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      y: {
        formatter: function (value) {
          return value + " Nos";
        },
      },
      fillSeriesColor: false, // You can use 'light' or 'dark' theme
    },
    colors: [
      "#9FDEF1",
      "#2A5D78",
      "#F6AA54",
      "#A7EE7C",
      "#F65454",
      "#057EC1",
      "#230B34",
      "#FE632B",
    ],
    labels: waf_label,
    legend: {
      position: "bottom",
      labels: {
        colors: "#ffffff",
      },
      horizontalAlign: "center",
    },
    stroke: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          xaxis: {
            labels: {
              style: {
                colors: "#FFFFFF",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: "#FFFFFF",
              },
            },
          },
          legend: {
            position: "bottom",
            labels: {
              colors: "#ffffff",
            },
          },
        },
      },
    ],
  };

  const cma_options = {
    chart: {
      type: "pie",
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    dataLabels: {
      formatter: function (val) {
        // Remove the percentage symbol from the labels
        return val.toFixed(0);
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      y: {
        formatter: function (value) {
          return value + " Nos";
        },
      },
      fillSeriesColor: false, // You can use 'light' or 'dark' theme
    },
    colors: [
      "#9FDEF1",
      "#2A5D78",
      "#F6AA54",
      "#A7EE7C",
      "#F65454",
      "#057EC1",
      "#230B34",
      "#FE632B",
    ],
    labels: cma_labels,
    legend: {
      position: "bottom",
      labels: {
        colors: "#ffffff",
      },
      horizontalAlign: "center",
    },
    stroke: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          xaxis: {
            labels: {
              style: {
                colors: "#FFFFFF",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: "#FFFFFF",
              },
            },
          },
          legend: {
            position: "bottom",
            labels: {
              colors: "#ffffff",
            },
          },
        },
      },
    ],
  };

  const tco_options = {
    chart: {
      type: "donut",
    },
    noData: {
      text: "No Data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#fff",
        fontSize: "20px",
        fontFamily: "Poppins",
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: "25px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#ff6a2f",
              offsetY: 0,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              showAlways: true,
              label: "",
              color: "#ff6a2f",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    dataLabels: {
      formatter: function (val) {
        // Remove the percentage symbol from the labels
        return val.toFixed(0);
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      y: {
        formatter: function (value) {
          return value + " Nos";
        },
      },
      fillSeriesColor: false, // You can use 'light' or 'dark' theme
    },
    colors: [
      "#9FDEF1",
      "#2A5D78",
      "#F6AA54",
      "#A7EE7C",
      "#F65454",
      "#057EC1",
      "#230B34",
      "#FE632B",
    ],
    labels: tco_labels,
    legend: {
      position: "bottom",
      labels: {
        colors: "#ffffff",
      },
      horizontalAlign: "center",
    },
    stroke: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          xaxis: {
            labels: {
              style: {
                colors: "#FFFFFF",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: "#FFFFFF",
              },
            },
          },
          legend: {
            position: "bottom",
            labels: {
              colors: "#ffffff",
            },
          },
        },
      },
    ],
  };

  return (
    <div className="">
      <>
        <>
          <div className="margin-right-1">
            <Row gutter={[16, 16]}>
              <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                <Card
                  title="Cloud Modernization Assessment %"
                  className="operate-analysis-cards"
                >
                  {isShowSpinner ? (
                    <div className=" d-flex justify-content-center align-items-center spinner-height">
                      <Spin />
                    </div>
                  ) : (
                    <ReactApexChart
                      options={cma_options}
                      series={numeric_cma_series}
                      type="pie"
                      height={443}
                      className="donutchart"
                    />
                  )}
                </Card>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <Card
                  title="Well Architected Framework Assessment %"
                  className="operate-analysis-cards"
                >
                  {isShowSpinner ? (
                    <div className=" d-flex justify-content-center align-items-center spinner-height">
                      <Spin />
                    </div>
                  ) : (
                    <ReactApexChart
                      options={waf_options}
                      series={numeric_waf_series}
                      type="donut"
                      height={403}
                      className="donutchart"
                    />
                  )}
                </Card>
              </Col>

              <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <Card
                  title="Source TCO spend Per year %"
                  className="operate-analysis-cards"
                >
                  {isShowSpinner ? (
                    <div className=" d-flex justify-content-center align-items-center spinner-height">
                      <Spin />
                    </div>
                  ) : (
                    <ReactApexChart
                      options={tco_options}
                      series={numeric_tco_series}
                      type="donut"
                      height={403}
                      className="donutchart"
                    />
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </>
      </>
    </div>
  );
}

export default OperateAnalysisPerspective;
