import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBContainer,
} from "mdb-react-ui-kit";

import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { FaRegCalendarAlt } from "react-icons/fa";
import "./popupchart.css";
import ReactECharts from "echarts-for-react";
import { IoMdClose } from "react-icons/io";
import { useEffect } from "react";
import { getRandomColor } from "../../custom_hook/CustomHook";

const AssetSpendSpendChart = (props) => {
  const [colorlist, setColorList] = useState([]);
  const [chartcategory, setChartCategory] = useState([]);
  const [chartseries, setChartSeries] = useState([]);
  const [secondmax, setSecondMax] = useState(0);
  const [firstmax, setFirstMax] = useState(0);

  useEffect(() => {
    const chartdata = props.spendchart;
    const colors = [];
    var chartcategory = [];
    var chartvalue = [];
    chartdata.map((data) => {
      colors.push(getRandomColor());
      chartcategory.push(data.name);
      chartvalue.push(Math.round(data.value));
    });
    setColorList(colors);
    setChartCategory(chartcategory);
    setChartSeries(chartvalue);
    setFirstMax(Math.max(...chartvalue));
    if (Math.max(...chartvalue) > 10) {
      setSecondMax(
        Math.round(
          chartvalue.reduce((pv, cv) => pv + cv, 0) / chartvalue.length
        )
      );
    } else {
      setSecondMax(Math.max(...chartvalue));
    }
  }, [props]);

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
      background: "#fff",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
        columnWidth: "55%",
        endingShape: "rounded",
        dataLabels: {
          orientation: "vertical",
          position: "top", // bottom/center/top
        },
      },
    },
    dataLabels: {
      enabled: true,
      // offsetY: 10000,
      formatter: function (num, opts) {
        // if(secondmax > num || num === 0){
        //   return '';
        // }else{
        return Math.abs(num) > 999
          ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
          : Math.sign(num) * Math.abs(num);
        // }
      },
      style: {
        fontSize: "9px",
        colors: ["#fff"],
      },
      background: {
        enabled: true,
        foreColor: "#000",
        borderWidth: 0,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    // colors: chartvalue.chartcolor,
    colors: colorlist,
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: chartcategory,
      tickPlacement: "between",
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: true,
        minHeight: undefined,
        // maxHeight: undefined,
        // style: {
        //     colors: [],
        //     fontSize: '8px'
        // },
        offsetX: 0,
        offsetY: 0,
      },
    },
    fill: {
      opacity: 1,
    },
    title: {
      text: `Spend`,
      align: "center",
      margin: 0,
      offsetX: 0,
      offsetY: 10,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#595959",
      },
    },
    yaxis: {
      max: secondmax,
      labels: {
        show: true,
        formatter: (value) => {
          var num = value;
          if (firstmax > 10) {
            if (Math.round(value) === secondmax) {
              // return firstmax;
              return "...";
            } else {
              return Math.abs(num) > 999
                ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                : Math.sign(num) * Math.abs(num);
            }
          } else {
            return Math.abs(num) > 999
              ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
              : Math.sign(num) * Math.abs(num);
          }
        },
      },
    },
    legend: {
      show: false,
      offsetY: -7,
    },
  };

  const series = [
    {
      name: "spend",
      data: chartseries,
    },
  ];
  return (
    <>
      <MDBModal
        show={props.zoominstatus}
        setShow={props.setZoomInStatus}
        tabIndex="-1"
      >
        <MDBModalDialog className="plat-modal-dialog">
          <MDBModalContent>
            <div className="plat-model-fullcover">
              <MDBModalBody>
                <ReactApexChart options={options} series={series} type="bar" />
              </MDBModalBody>
              <div
                className="plat-model-close-icon"
                onClick={() => props.setZoomInStatus(false)}
              >
                <IoMdClose className="plat-model-top-close-icon" />
              </div>
            </div>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};
export default AssetSpendSpendChart;
