import { React,useState } from "react";
import { Row, Col, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import LandingZoneForm from "./LandingZoneForm";
import LandingZoneFormPage from "./LandingZoneFormPage";

function LandingZoneAdd() {
  const navigate = useNavigate();
  function HandleOpen() {
    navigate("/landingzone");
  }
  const { TabPane } = Tabs;

    const [basicDetailsFilled, setBasicDetailsFilled] = useState(false);

    const handleBasicDetailsSave = (formData) => {
      const isFilled = Object.values(formData).some((value) => !!value);
      setBasicDetailsFilled(isFilled);
    };
   
  const handleConfigurationSave = () => {
    if (basicDetailsFilled) {
    } else {
    }
  };

  

  // const items = [
  //   {
  //     key: "1",
  //     label: `Basic Details`,
  //     children: <LandingZoneForm />,
  //   },
  //   {
  //     key: "2",
  //     label: `Configuration`,
  //     children: <LandingZoneFormPage />,
  //   },
  // ];

  return (
    <>
      <Row>
        <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}></Col>
        <Col xxl={20} xl={20} lg={20} md={20} sm={20} xs={20} className="mt-5">
          <div className="d-flex align-items-center">
            <div className="arrow-back">
              <Icon
                icon="bi:arrow-left"
                style={{ fontSize: "25px", cursor: "pointer" }}
                onClick={HandleOpen}
              />
            </div>
            <div style={{ flex: 1, textAlign: "center" }}>
              <h4 className="header-title-devops">
                Welcome to Self Service Portal
              </h4>
            </div>
          </div>
        </Col>
        <Col xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}></Col>
      </Row>{" "}
      <div>
        {/* <Tabs
          className="ant-steps-item-title1"
          style={{marginLeft:""}}
          defaultActiveKey="1"
          items={items}
        /> */}
        <Tabs defaultActiveKey="1" className="ant-steps-item-title1"
>
        <TabPane tab="Basic Details" key="1">
          <LandingZoneForm onSave={handleBasicDetailsSave} />
        </TabPane>
        <TabPane
          tab="Configuration"
          key="2"
          disabled={!basicDetailsFilled}
        >
          <LandingZoneFormPage />
        </TabPane>
      </Tabs>
      </div>
    </>
  );
}
export default LandingZoneAdd;
