import { createAsyncThunk } from "@reduxjs/toolkit";
import client_new from "../../client/dashboardapplonewClinet";
import { useErrorNavigation } from "../../../custom_hook/CustomHook";
import {
  CAMS_ALL_DETAILS,
  CRITICAL_WARNING_COUNT,
  GET_ALERT_TREND,
  GET_ALL_ALERTS_DETAIL,
  GET_CAMS_ALERTS_DETAILS,
  GET_CAMS_COUNT,
  GET_CUSTOMERS,
  GET_DISTINCT_SERVERS,
  GET_N_SERVERS,
  GET_N_SERVER_FULL_DETAILS,
  GET_PAGE_COUNT_REPORTS,
  GET_REPORT_DATA,
  GET_WEEKLY_DATA,
  SEVERITY_COUNT,
  ZOHO_MAPPER,
  GET_ACTION_LIST,
  GET_ACTION,
  REGION_LIST,
  PIPELINE_ACTION,
} from "./cams_graphQLQuery";
import { useNavigate } from "react-router-dom";
import client_a2c from "../../client/A2C_Client";

export const getDistinctServers = createAsyncThunk(
  // action type string
  "cams/summaryview/distinctCount",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const countData = await client_new.query({
        query: GET_DISTINCT_SERVERS,
        variables: {
          company_name: data.companyName,
          date: data.selectedTopdate,
        },
      });
      return countData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCamsAlertCount = createAsyncThunk(
  // action type string
  "cams/summaryview/camsAlertCount",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const camsCountData = await client_new.query({
        query: GET_CAMS_COUNT,
        variables: {
          company_name: data.companyName,
          date: data.selectedTopdate,
        },
      });
      return camsCountData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const camsAllDetails = createAsyncThunk(
  // action type string
  "cams/summaryview/camsAlertCount",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const camsAllDetails = await client_new.query({
        query: CAMS_ALL_DETAILS,
        variables: {
          company_name: data.companyName,
          date: data.selectedTopdate,
        },
      });
      return camsAllDetails;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const camsSeverityCount = createAsyncThunk(
  // action type string
  "cams/summaryview/camsAlertCount",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const severityCount = await client_new.query({
        query: SEVERITY_COUNT,
        variables: {
          company_name: data.companyName,
          date: data.selectedTopdate,
        },
      });
      return severityCount;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getNServers = createAsyncThunk(
  // action type string
  "cams/summaryview/getNServers",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const topNSevers = await client_new.query({
        query: GET_N_SERVERS,
        variables: {
          company_name: data.companyName,
          date: data.selectedTopdate,
          count: data.count,
        },
      });
      return topNSevers;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const nServersFullData = createAsyncThunk(
  // action type string
  "cams/summaryview/getNServersFullData",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const nSeversFullData = await client_new.query({
        query: GET_N_SERVER_FULL_DETAILS,
        variables: {
          company_name: data.companyName,
          date: data.selectedTopdate,
          server: data.server,
        },
      });
      return nSeversFullData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getAlertTrend = createAsyncThunk(
  // action type string
  "cams/summaryview/getNServers",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const alertTrend = await client_new.query({
        query: GET_ALERT_TREND,
        variables: {
          // company_name: data.companyName,
          company_name: data.companyName,
          end_date: data.currentDate,
          start_date: data.previousDate,
        },
      });
      return alertTrend;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCriticalWarningCount = createAsyncThunk(
  // action type string
  "cams/summaryview/getNServers",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const criticalWarningCount = await client_new.query({
        query: CRITICAL_WARNING_COUNT,
        variables: {
          company_name: data.companyName,
          date: data.selectedTopdate,
        },
      });
      return criticalWarningCount;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getWeeklyData = createAsyncThunk(
  // action type string
  "cams/summaryview/getNServers",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const weeklyData = await client_new.query({
        query: GET_WEEKLY_DATA,
        variables: {
          company_name: data.companyName,
          date: data.selectedTopdate,
        },
      });
      return weeklyData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCamsAlertsDetails = createAsyncThunk(
  // action type string
  "cams/summaryview/getCamsDetails",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const camsDailyDetail = await client_new.mutate({
        mutation: GET_CAMS_ALERTS_DETAILS,
        variables: {
          company_name: data.companyName,
          date: data.selectedTopdate,
          alert_type: data.alert_type,
          server_name: data.server_name,
          severity: data.severity,
          subject: data.subject,
        },
      });
      return camsDailyDetail;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllAlertsDetails = createAsyncThunk(
  // action type string
  "cams/summaryview/getAllAlertsDetails",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const AllAlertsDailyDetail = await client_new.mutate({
        mutation: GET_ALL_ALERTS_DETAIL,
        variables: {
          company_name: data.companyName,
          date: data.selectedTopdate,
          alert_type: data.alert_type,
          server_name: data.server_name,
          severity: data.severity,
          subject: data.subject,
        },
      });
      return AllAlertsDailyDetail;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const zohoMapping = createAsyncThunk(
  // action type string
  "cams/summaryview/getAllAlertsDetails",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const zohoMapping = await client_new.query({
        query: ZOHO_MAPPER,
        variables: {
          date: data.date,
          subject: data.subject,
          event_type: data.event_type,
        },
      });
      return zohoMapping;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getReportsPages = createAsyncThunk(
  // action type string
  "cams/summaryview/getpagecount",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const pageCount = await client_new.query({
        query: GET_PAGE_COUNT_REPORTS,
        variables: {
          start_date: data.start_date,
          end_date: data.end_date,
          alert_type: data.alert_type,
          company_name: data.company_name,
          count: data.count,
        },
      });
      return pageCount;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCustomersReducer = createAsyncThunk(
  // action type string
  "cams/summaryview/getCustomer",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const getCustomers = await client_new.query({
        query: GET_CUSTOMERS,
      });
      return getCustomers;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getReportData = createAsyncThunk(
  // action type string
  "cams/summaryview/getReportData",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const getReportData = await client_new.query({
        query: GET_REPORT_DATA,
        variables: {
          start_date: data.start_date,
          end_date: data.end_date,
          alert_type: data.alert_type,
          company_name: data.company_name,
          page_number: data.page_count,
          page_size: data.page_size,
        },
      });
      return getReportData;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const actionlistitems = createAsyncThunk(
  "cams/summaryview/getReportData",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const getActionItems = await client_a2c.query({
        query: GET_ACTION_LIST,
        variables: {
          action_choice: data.value,
        },
      });
      return getActionItems;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// action list items
export const actionlists = createAsyncThunk(
  "cams/summaryview/getReportData",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const getActionlist = await client_a2c.query({
        query: GET_ACTION,
        variables: {
          choice: data.choice,
        },
      });
      return getActionlist;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// Region List
export const regionlists = createAsyncThunk(
  "cams/summaryview/getReportData",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const regionlist = await client_a2c.query({
        query: REGION_LIST,
        variables: {
          region_name: data.region_name,
        },
      });
      return regionlist;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// Pipline action
export const piplinetrigger = createAsyncThunk(
  "cams/summaryview/getReportData",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const instancetrigger = await client_a2c.query({
        query: PIPELINE_ACTION,
        variables: {
          ACTION: data.ACTION,
          AWS_DEFAULT_REGION: data.AWS_DEFAULT_REGION,
          INSTANCE_NAME: data.instanceName,
        },
      });
      return instancetrigger;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
