import { PDFViewer } from '@react-pdf/renderer';
import { useState } from 'react';
import MyDocument from '../../dashboard-v2/NewSavingsModel/MyDocument';
import MyDocument_dummy from '../../dashboard-v2/NewSavingsModel/MyDocument_dummy';
import { useDispatch, useSelector } from 'react-redux';
import QuickGlanceOverallReport from '../../dashboard-v2/PDF/QuickGlanceOverallReport';


const AppPDF = () => {
  const { selectedTopdate  } = useSelector((state) => state.constimize);

  const [recommendationlist, setrecommendationlist] = useState([
    {
        "text": "SPOT & START/STOP",
        "value": "SPOT & START/STOP"
    },
    {
        "text": "RI",
        "value": "RI"
    },
    {
        "text": "Stop/Start",
        "value": "Stop/Start"
    },
    {
        "text": "OS-Recommendation",
        "value": "OS-Recommendation"
    }
]);
    
  const [tablerecords, settablerecords] = useState([
    {
        "key": 1,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "navision",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-065798b91b4d30ec7",
        "resource_name": "fsg_uat_navision_app_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 0,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 2,
        "date": "2023-03-01",
        "account": "Other",
        "application": "Datavault",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-0d9306c3b4bf2f759",
        "resource_name": "fsg_pre_prod_data_vault_app_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 87.0728,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 3,
        "date": "2023-03-01",
        "account": "fs-prod-business",
        "application": "nach cancellation",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_prod_nach_cancellation_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 223.2,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 4,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-0de96ffa3575584ed",
        "resource_name": "fsg_sit_employee_portal_job_schedular_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 27.4288,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 5,
        "date": "2023-03-01",
        "account": "fs-prod-business",
        "application": "employee portal",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_prod_employee_portal_api_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 122.4,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 6,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-048768ab20f0a707c",
        "resource_name": "fsg_sit_employee_portal_api_mumbai_az1",
        "current_configuration": "13/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 20.956457,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 7,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "ckyc",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-0e17d1125a11cacd8",
        "resource_name": "fsg_uat_ckyc_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 204.6496,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 8,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-0a4b299cc8142747b",
        "resource_name": "fsg_uat_employee_portal_api_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 21.0304,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 9,
        "date": "2023-03-01",
        "account": "fs-prod-business",
        "application": "employee portal",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_prod_employee_portal_app_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 1053.6,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 10,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-0dc26e1baa07f57d7",
        "resource_name": "fsg_sit_employee_portal_app_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 59.4456,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 11,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "Credence",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-0e78aae4d9c943ec0",
        "resource_name": "fsg_uat_credence_web_server_mumbai_az1",
        "current_configuration": "15/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 144.62398,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 12,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-0de96ffa3575584ed",
        "resource_name": "fsg_sit_employee_portal_job_schedular_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 21.0304,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 13,
        "date": "2023-03-01",
        "account": "fs-prod-business",
        "application": "storage gateway",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "new-storage-gateway",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 324,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 14,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "nucleus",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-09cb6816312721f66",
        "resource_name": "fsg_uat_nucleus_cas_cms_sso_mumbai_az1",
        "current_configuration": "21/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 509.65192,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 15,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-0de96ffa3575584ed",
        "resource_name": "fsg_sit_employee_portal_job_schedular_mumbai_az1",
        "current_configuration": "13/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 11.955596,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 16,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-048768ab20f0a707c",
        "resource_name": "fsg_sit_employee_portal_api_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 21.0304,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 17,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "mulesoft",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Machine",
        "recommendation_type": "OS-Recommendation",
        "resource_id": "i-02808601c506e94a4",
        "resource_name": "fsg_nprd_stg_mulesoft_app_mumbai_az2",
        "current_configuration": "Red Hat Enterprise Linux with HA",
        "recommended_configuration": "Linux",
        "commitment": "NA",
        "potential_savings": 70,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 18,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Machine",
        "recommendation_type": "OS-Recommendation",
        "resource_id": "i-048768ab20f0a707c",
        "resource_name": "fsg_sit_employee_portal_api_mumbai_az1",
        "current_configuration": "Windows",
        "recommended_configuration": "Linux",
        "commitment": "NA",
        "potential_savings": 14,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 19,
        "date": "2023-03-01",
        "account": "fs-prod-business",
        "application": "employee portal",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_prod_employee_portal_job_schedular_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 122.4,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 20,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-0293c3d418651c322",
        "resource_name": "fsg_uat_employee_portal_job_schedular_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 182.032,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 21,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Machine",
        "recommendation_type": "OS-Recommendation",
        "resource_id": "i-08cdba3be2f21d7b1",
        "resource_name": "fsg_sit_employee_portal_db_mumbai_az1",
        "current_configuration": "Windows",
        "recommended_configuration": "Linux",
        "commitment": "NA",
        "potential_savings": 20,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 22,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "dedupe",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-00bb0835105966209",
        "resource_name": "fsg_nprd_dev_dedupe_app-mumbai_az1",
        "current_configuration": "21/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 302.9401,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 23,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-08cdba3be2f21d7b1",
        "resource_name": "fsg_sit_employee_portal_db_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 59.4456,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 24,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "nucleus",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-03adf3fef0d592b94",
        "resource_name": "fsg_uat_nucleus_activemq_mcas_mcollect_ecm_mumbai_az1",
        "current_configuration": "21/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 509.65192,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 25,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "management",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-00fcff9607b73d7ad",
        "resource_name": "fsg_nprd_bastion_app_mumbai_az1",
        "current_configuration": "21/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 176.77434,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 26,
        "date": "2023-03-01",
        "account": "fs-common-services",
        "application": "compliance",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_prod_compliancetool_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 1394.4,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 27,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Machine",
        "recommendation_type": "OS-Recommendation",
        "resource_id": "i-0a21e98d563fb0dfc",
        "resource_name": "fsg_uat_employee_portal_app_mumbai_az1",
        "current_configuration": "Windows",
        "recommended_configuration": "Linux",
        "commitment": "NA",
        "potential_savings": 20,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 28,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-08cdba3be2f21d7b1",
        "resource_name": "fsg_sit_employee_portal_db_mumbai_az1",
        "current_configuration": "13/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 57.116253,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 29,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "mulesoft",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Machine",
        "recommendation_type": "OS-Recommendation",
        "resource_id": "i-0aecc7179485ea29c",
        "resource_name": "fsg_nprd_dev_mulesoft_app-mumbai_az1",
        "current_configuration": "Red Hat Enterprise Linux with HA",
        "recommended_configuration": "Linux",
        "commitment": "NA",
        "potential_savings": 70,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 30,
        "date": "2023-03-01",
        "account": "Other",
        "application": "Datavault",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-0faf1826d9d15da2e",
        "resource_name": "fsg_prod_data_vault_bastion_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 145.8984,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 31,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-07ddc331a0b6c8af1",
        "resource_name": "fsg_uat_employee_portal_ftp_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 21.0304,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 32,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "BRS",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-0b292d3b17279b6ef",
        "resource_name": "fsg_uat_brs_app_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 120.3296,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 33,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Machine",
        "recommendation_type": "OS-Recommendation",
        "resource_id": "i-0293c3d418651c322",
        "resource_name": "fsg_uat_employee_portal_job_schedular_mumbai_az1",
        "current_configuration": "Windows",
        "recommended_configuration": "Linux",
        "commitment": "NA",
        "potential_savings": 269,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 34,
        "date": "2023-03-01",
        "account": "fs-common-services",
        "application": "management",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_prod_active_directory_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 309.6,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 35,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-0d0cd7b32d2a8d856",
        "resource_name": " fsg_sit_employee_portal_ftp_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 27.4288,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 36,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-0a21e98d563fb0dfc",
        "resource_name": "fsg_uat_employee_portal_app_mumbai_az1",
        "current_configuration": "13/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 57.45489,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 37,
        "date": "2023-03-01",
        "account": "fs-prod-business",
        "application": "navision",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_prod_navision_app_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 357.6,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 38,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-0dc26e1baa07f57d7",
        "resource_name": "fsg_sit_employee_portal_app_mumbai_az1",
        "current_configuration": "21/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 417.7008,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 39,
        "date": "2023-03-01",
        "account": "fs-prod-business",
        "application": "mulesoft",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_prod_mulesoft_app_mumbai_az2",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 314.4,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 40,
        "date": "2023-03-01",
        "account": "fs-prod-business",
        "application": "mulesoft",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_prod_bastion_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 232.8,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 41,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-0251fe9dd04ffa821",
        "resource_name": "fsg_uat_employee_portal_db_mumbai_az1",
        "current_configuration": "13/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 79.910164,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 42,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-0a21e98d563fb0dfc",
        "resource_name": "fsg_uat_employee_portal_app_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 59.4456,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 43,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "mulesoft",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-02808601c506e94a4",
        "resource_name": "fsg_nprd_stg_mulesoft_app_mumbai_az2",
        "current_configuration": "21/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 657.91425,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 44,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "nucleus",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-06c7ffa9f260bb420",
        "resource_name": "fsg_uat_nucleus_apicenter_gcd_intg_gcd_mumbai_az1",
        "current_configuration": "21/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 509.65192,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 45,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "mulesoft",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-0aecc7179485ea29c",
        "resource_name": "fsg_nprd_dev_mulesoft_app-mumbai_az1",
        "current_configuration": "21/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 655.69904,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 46,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "mulesoft",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Machine",
        "recommendation_type": "OS-Recommendation",
        "resource_id": "i-049a0db9595728023",
        "resource_name": "fsg_nprd_sit_mulesoft_app_mumbai_az1",
        "current_configuration": "Red Hat Enterprise Linux with HA",
        "recommended_configuration": "Linux",
        "commitment": "NA",
        "potential_savings": 70,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 47,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Machine",
        "recommendation_type": "OS-Recommendation",
        "resource_id": "i-0a4b299cc8142747b",
        "resource_name": "fsg_uat_employee_portal_api_mumbai_az1",
        "current_configuration": "Windows",
        "recommended_configuration": "Linux",
        "commitment": "NA",
        "potential_savings": 14,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 48,
        "date": "2023-03-01",
        "account": "fs-prod-business",
        "application": "navision",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_prod_navision_terminal_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 276,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 49,
        "date": "2023-03-01",
        "account": "fs-prod-business",
        "application": "employee portal",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_prod_employee_portal_ftp_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 122.4,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 50,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "Credence",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_uat_credence_DB_Server-25-11-2022",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 268.8,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 51,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "Credence",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Machine",
        "recommendation_type": "OS-Recommendation",
        "resource_id": "i-0ddbefa28ad1117f7",
        "resource_name": "fsg_uat_credence_app_server_mumbai_az1",
        "current_configuration": "RHEL",
        "recommended_configuration": "Windows",
        "commitment": "NA",
        "potential_savings": 24,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 52,
        "date": "2023-03-01",
        "account": "fs-prod-business",
        "application": "model building",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg-prod-model-building",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 1243.2,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 53,
        "date": "2023-03-01",
        "account": "fs-prod-business",
        "application": "employee portal",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_prod_employee_portal_db_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 4216.8,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 54,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "mulesoft",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-07d452d597fd82074",
        "resource_name": "fsg_nprd_stg_mulesoft_app_mumbai_az1",
        "current_configuration": "21/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 657.91425,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 55,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Machine",
        "recommendation_type": "OS-Recommendation",
        "resource_id": "i-0de96ffa3575584ed",
        "resource_name": "fsg_sit_employee_portal_job_schedular_mumbai_az1",
        "current_configuration": "Windows",
        "recommended_configuration": "Linux",
        "commitment": "NA",
        "potential_savings": 67,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 56,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Machine",
        "recommendation_type": "OS-Recommendation",
        "resource_id": "i-0dc26e1baa07f57d7",
        "resource_name": "fsg_sit_employee_portal_app_mumbai_az1",
        "current_configuration": "Windows",
        "recommended_configuration": "Linux",
        "commitment": "NA",
        "potential_savings": 20,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 57,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "nucleus",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-0eb3cbe0420d02860",
        "resource_name": "fsg_uat_nucleus_redis_mumbai_az1",
        "current_configuration": "21/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 256.60803,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 58,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-0d0cd7b32d2a8d856",
        "resource_name": " fsg_sit_employee_portal_ftp_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 21.0304,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 59,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "nucleus",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-02227cacd01fd0c77",
        "resource_name": "fsg_uat_nucleus_lms_mumbai_az1",
        "current_configuration": "21/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 509.65192,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 60,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-0293c3d418651c322",
        "resource_name": "fsg_uat_employee_portal_job_schedular_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 41.9864,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 61,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "mulesoft",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Machine",
        "recommendation_type": "OS-Recommendation",
        "resource_id": "i-07d452d597fd82074",
        "resource_name": "fsg_nprd_stg_mulesoft_app_mumbai_az1",
        "current_configuration": "Red Hat Enterprise Linux with HA",
        "recommended_configuration": "Linux",
        "commitment": "NA",
        "potential_savings": 70,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 62,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-0a4b299cc8142747b",
        "resource_name": "fsg_uat_employee_portal_api_mumbai_az1",
        "current_configuration": "13/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 21.082293,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 63,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-0293c3d418651c322",
        "resource_name": "fsg_uat_employee_portal_job_schedular_mumbai_az1",
        "current_configuration": "14/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 557.2077,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 64,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "navision",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-067a9d05503307509",
        "resource_name": "fsg_uat_navision_db_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 59.4456,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 65,
        "date": "2023-03-01",
        "account": "fs-prod-business",
        "application": "management",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_prod_bastion_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 232.8,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 66,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-0293c3d418651c322",
        "resource_name": "fsg_uat_employee_portal_job_schedular_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 21.0304,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 67,
        "date": "2023-03-01",
        "account": "fs-prod-business",
        "application": "navision",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_prod_navision_db_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 2107.2,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 68,
        "date": "2023-03-01",
        "account": "fs-prod-business",
        "application": "java",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_prod_java_app_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 19.2,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 69,
        "date": "2023-03-01",
        "account": "fs-common-services",
        "application": "management",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_prod_gitlab_app_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 88.8,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 70,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "employee portal",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-0251fe9dd04ffa821",
        "resource_name": "fsg_uat_employee_portal_db_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 0,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 71,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "navision",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-081229bb21239b427",
        "resource_name": "fsg_uat_navision_terminal_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 59.4456,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 72,
        "date": "2023-03-01",
        "account": "fs-prod-business",
        "application": "dedupe",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_prod_dedupe_app_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 2467.2,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 73,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "nucleus",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-0b2926230d4cf849f",
        "resource_name": "fsg_uat_nucleus_database_mumbai_az1",
        "current_configuration": "21/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 1607.3644,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 74,
        "date": "2023-03-01",
        "account": "fs-prod-business",
        "application": "finnone prod-dr",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "AWS Elastic Disaster Recovery Replication Server",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 1353.6,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 75,
        "date": "2023-03-01",
        "account": "Other",
        "application": "Datavault",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "SPOT & START/STOP",
        "resource_id": "i-050ed2e78faf29f60",
        "resource_name": "fsg_pre_prod_data_vault_app_mumbai_az2",
        "current_configuration": "On-Demand",
        "recommended_configuration": "SPOT & START/STOP",
        "commitment": "SPOT-Instance  Start-Stop",
        "potential_savings": 87.0728,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 76,
        "date": "2023-03-01",
        "account": "fs-non-prod-business",
        "application": "mulesoft",
        "environment": "Non-Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "Stop/Start",
        "resource_id": "i-049a0db9595728023",
        "resource_name": "fsg_nprd_sit_mulesoft_app_mumbai_az1",
        "current_configuration": "21/7",
        "recommended_configuration": "8/7",
        "commitment": "On-Demand Start-Stop",
        "potential_savings": 657.91425,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    },
    {
        "key": 77,
        "date": "2023-03-01",
        "account": "fs-prod-business",
        "application": "mulesoft",
        "environment": "Production",
        "services": "AmazonEC2",
        "resource_sub_category": "EC2-Instance",
        "recommendation_type": "RI",
        "resource_id": null,
        "resource_name": "fsg_prod_mulesoft_app_mumbai_az1",
        "current_configuration": "On-Demand",
        "recommended_configuration": "RI",
        "commitment": "1-YR Plan",
        "potential_savings": 314.4,
        "realized_savings": null,
        "lost_opportunity": null,
        "action": "",
        "type": "data"
    }
])

    return(
        <>
           <PDFViewer style={{ width: '100%', height: '700px' }}>
            {/* <MyDocument_dummy /> */}
            {/* <MyDocument 
              date = '2023-03-01'
              recommendationlist = {recommendationlist}
              records={tablerecords}
            /> */}
            <QuickGlanceOverallReport 
                date = '2023-03-01'
                period_budget = '63000'
                spend = '69570'
                monthcredit = '10'
                potential_savings = "27619"
                records={tablerecords}
            />
          </PDFViewer>
        </>
    )
}
export default AppPDF;