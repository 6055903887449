import { gql } from "@apollo/client";

const GET_DISTINCT_SERVERS = gql`
  query MyQuery($company_name: String!, $date: String!) {
    cams_count_api(company_name: $company_name, date: $date) {
      server_count
      alert_count
      cams_daily_alert
    }
  }
`;

const GET_CAMS_COUNT = gql`
  query MyQuery($company_name: String!, $date: String!) {
    cams_daily_alert_count(company_name: $company_name, date: $date) {
      cams_daily_alert
    }
  }
`;
const CAMS_ALL_DETAILS = gql`
  query MyQuery($company_name: String!, $date: String!) {
    cams_all_details(company_name: $company_name, date: $date) {
      alert_type
      from_address
      server_name
      severity
      subject
      to_address
      time
      date
    }
  }
`;

const SEVERITY_COUNT = gql`
  query MyQuery($company_name: String!, $date: String!) {
    cams_severity_count(company_name: $company_name, date: $date) {
      alert_count
      server_count
      cams_alert
      severity
    }
  }
`;

const GET_N_SERVERS = gql`
  query MyQuery($company_name: String!, $date: String!, $count: String!) {
    cams_top_servers(company_name: $company_name, count: $count, date: $date) {
      cams_processed
      recurring_tickets
      server_name
      total_alerts
    }
  }
`;
const GET_N_SERVER_FULL_DETAILS = gql`
  query MyQuery($company_name: String!, $date: String!, $server: String!) {
    cams_top_details(
      company_name: $company_name
      date: $date
      server: $server
    ) {
      alert_type
      from_address
      server_name
      severity
      subject
      to_address
      time
      date
    }
  }
`;
const GET_ALERT_TREND = gql`
  query MyQuery(
    $company_name: String!
    $start_date: String!
    $end_date: String!
  ) {
    cams_alert_trends(
      company_name: $company_name
      end_date: $end_date
      start_date: $start_date
    ) {
      all_alerts
      date
      outgoing_alerts
    }
  }
`;
const CRITICAL_WARNING_COUNT = gql`
  query MyQuery($company_name: String!, $date: String!) {
    cams_alert_percentage(customer_name: $company_name, date: $date) {
      server_name
      alert_count
      warning
      critical
    }
  }
`;

const GET_WEEKLY_DATA = gql`
  query MyQuery($company_name: String!, $date: String!) {
    cams_severity_main_table(company_name: $company_name, date: $date) {
      server_name
      severity
      subject
      alert_count
      date
      alert_type
      cams_processed
    }
  }
`;
const GET_CAMS_ALERTS_DETAILS = gql`
  query MyQuery(
    $company_name: String!
    $date: String!
    $alert_type: String!
    $server_name: String!
    $severity: String!
    $subject: String!
  ) {
    cams_severity_camsprocessed_table(
      company_name: $company_name
      date: $date
      alert_type: $alert_type
      server_name: $server_name
      severity: $severity
      subject: $subject
    ) {
      server_name
      severity
      subject
      date
      alert_type
      from_address
      to_address
      time
      zoho_mapper
    }
  }
`;

const GET_ALL_ALERTS_DETAIL = gql`
  query MyQuery(
    $company_name: String!
    $date: String!
    $alert_type: String!
    $server_name: String!
    $severity: String!
    $subject: String!
  ) {
    cams_severity_alert_table(
      company_name: $company_name
      date: $date
      alert_type: $alert_type
      server_name: $server_name
      severity: $severity
      subject: $subject
    ) {
      server_name
      from_address
      to_address
      date
      time
      severity
      subject
      alert_type
    }
  }
`;
const ZOHO_MAPPER = gql`
  query MyQuery($date: String!, $subject: String!, $event_type: String!) {
    zoho_ticket_mapping(date: $date, subject: $subject, event_type: $event_type)
  }
`;
const GET_CUSTOMERS = gql`
  query MyQuery {
    cams_customer {
      customer
    }
  }
`;
const GET_PAGE_COUNT_REPORTS = gql`
  query MyQuery(
    $start_date: String!
    $end_date: String!
    $count: Int!
    $alert_type: String!
    $company_name: String!
  ) {
    cams_report_tab_table_pagesize(
      alert_type: $alert_type
      company_name: $company_name
      start_date: $start_date
      end_date: $end_date
      count: $count
    ) {
      total_pages
    }
  }
`;

const GET_REPORT_DATA = gql`
  query MyQuery(
    $alert_type: String!
    $company_name: String!
    $start_date: String!
    $end_date: String!
    $page_number: Int!
    $page_size: Int!
  ) {
    cams_report_tab_main_table(
      alert_type: $alert_type
      company_name: $company_name
      start_date: $start_date
      end_date: $end_date
      page_number: $page_number
      page_size: $page_size
    ) {
      server_name
      severity
      subject
      date_column
      alert_type
      from_address
      to_address
      time
      zoho_mapper
    }
  }
`;
const GET_ACTION_LIST = gql`
  query MyQuery($action_choice: String!) {
    yubi_instance(action_choice: $action_choice) {
      id
      choice_details
      action_choice
      impact_type
    }
  }
`;

const GET_ACTION = gql`
  query MyQuery($choice: String!) {
    action_list(choice: $choice) {
      id
      action_choice
    }
  }
`;
const REGION_LIST = gql`
  query MyQuery($region_name: String!) {
    region_list(region_name: $region_name) {
      id
      region_list
    }
  }
`;

const PIPELINE_ACTION = gql`
  query MyQuery(
    $ACTION: String
    $AWS_DEFAULT_REGION: String
    $INSTANCE_NAME: String
  ) {
    instance_action(
      ACTION: $ACTION
      AWS_DEFAULT_REGION: $AWS_DEFAULT_REGION
      INSTANCE_NAME: $INSTANCE_NAME
    )
  }
`;
export {
  GET_DISTINCT_SERVERS,
  GET_CAMS_COUNT,
  CAMS_ALL_DETAILS,
  SEVERITY_COUNT,
  GET_CAMS_ALERTS_DETAILS,
  GET_ALL_ALERTS_DETAIL,
  ZOHO_MAPPER,
  GET_CUSTOMERS,
  GET_N_SERVERS,
  GET_ALERT_TREND,
  CRITICAL_WARNING_COUNT,
  GET_WEEKLY_DATA,
  GET_N_SERVER_FULL_DETAILS,
  GET_PAGE_COUNT_REPORTS,
  GET_REPORT_DATA,
  GET_ACTION_LIST,
  GET_ACTION,
  REGION_LIST,
  PIPELINE_ACTION,
};
