import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';


const styles = StyleSheet.create({
    header: {
      width: '100%',
      textAlign: 'center',
      marginBottom: 20,
    },
    headersecond:{
        width: '100%',
        textAlign: 'center',
        marginBottom: 40,
    },
    footer: {
      position: 'absolute',
      fontSize: 10,
      bottom: 20,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
      paddingTop: 20,
      marginTop: 20,
    },
    customer_logo:{
        width: '145px',
        height: '29px',
        position: 'fixed', top: 0, left: 0, right: 0
    },
    company_logo:{
        width: '145px',
        height: '29px',
        
        
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0 ,
        marginTop: 10
    }, 
    tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
    }, 
    tableCol: { 
        width: "14.3%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    }, 
    tableCell: { 
        margin: "auto", 
        marginTop: 5, 
        fontSize: 10 
    },
  });

const PdfHeader = () => {
    return(
        <>
            <View style={styles.header} fixed>
                <View style={{display: "flex", flexDirection: "row"}} >
                    <Text style={{ flex: 1, textAlign:"left" }}>
                        <Image style={styles.customer_logo} src="assets/images/company_logo.png"  />
                    </Text>
                    <Text style={{ flex: 1, textAlign:"right", marginRight: -30 }}>
                        <Image style={styles.company_logo} src="assets/images/1ch_logo.jpg" />
                    </Text>
                </View>
            </View>
        </>
    )
}

const Page2PdfHeader = () => {
    return(
        <>
            <View style={styles.headersecond} fixed>
                <View style={{display: "flex", flexDirection: "row"}} >
                </View>
            </View>
        </>
    )
}

const PdfFooter = () => {
    return(
        <>
            <View style={styles.footer} fixed>
                <View style={{display: "flex", flexDirection: "row"}} >
                    <Text style={{ flex: 1, textAlign:"left", marginLeft: 20 }}>
                        Date : {new Date().getDate()}-{new Date().getMonth() + 1}-{new Date().getFullYear()}
                    </Text>
                    <Text style={{ flex: 1, textAlign:"right", marginRight: 20 }}>
                        © {new Date().getFullYear()} Copyright: 1CloudHub
                    </Text>
                </View>
            </View>
        </>
    )
}
const MyDocument_dummy = (props) => {
    return(
        <>
            <Document>
                <Page  pageNumber={1} style={{padding: 20}}>
                    <PdfHeader />
                    <View>
                        <Text style={{textAlign:'center', top: 300}}>Cost Saving report for the month of </Text>
                    </View>
                    <PdfFooter />
                </Page>
                <Page  pageNumber={2} style={{paddingLeft:20, paddingRight: 20, paddingBottom: 50, paddingTop: 20}}>
                    <Page2PdfHeader />
                    <View style={{ paddingBottom: 30}}>
                    <View style={{marginTop: 10}}>
                            <Text style={{fontSize: '15px'}}>Summary :</Text>
                        </View>
                        <View style={{marginTop: 5}}>
                            <Text style={{fontSize: '12px', marginLeft: 20}}> We have reviewed the landscape and for the month of March we see a potential savings of upto $ 2345 </Text>
                        </View>
                        <View style={{marginTop: 10}}>
                            <Text style={{fontSize: '12px'}}>Aspects considered for cost savings.</Text>
                        </View>
                        <View style={{marginTop: 10, fontSize: '11px'}}>
                         
                        </View>
                        <View style={{marginTop: 30, fontSize: '11px'}}>
                            <Text style={{marginLeft: 20, lineHeight:1.5}}>1. RI</Text>
                            <Text style={{marginLeft: 40, lineHeight:1.5}}>
                                We see some systems in your landscape are used continuously even through they are in on-demand model converting the instance to reserved instance will help to save cost of upto $ 234, details below.
                            </Text>
                            <View style={styles.table}> 
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                
                                
                            </View>
                        </View>

                        <View style={{marginTop: 30, fontSize: '11px'}}>
                            <Text style={{marginLeft: 20, lineHeight:1.5}}>2. Start/Stop</Text>
                            <Text style={{marginLeft: 40, lineHeight:1.5}}>
                                We see potential savings if we implement scheduled stop/start so that you upto $234, details below.
                            </Text>
                            <View style={styles.table}> 
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>

                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>
                                <View style={styles.tableRow}> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Account</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Application</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Environment</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Service</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Current
                                         Configuration</Text> 
                                    </View>
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Recommended Configuration</Text> 
                                    </View> 
                                    <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>Potential Savings</Text> 
                                    </View>
                                </View>

                                   
                            </View>
                        </View>

                        
                    </View>
                       

                    <PdfFooter />
                    
                </Page>
            </Document>
        </>
    )
}
export default MyDocument_dummy;