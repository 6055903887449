import { memo, useEffect, useState } from "react";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Icon } from "@iconify/react";
import { Table, Typography, Tooltip, Spin, Row, Col, Space } from "antd";
import {
  numberFormat,
  checkRolePermission,
} from "../../../custom_hook/CustomHook";
import { useSelector, useDispatch } from "react-redux";
import {
  dateddeferredrecords,
  listRecommendation,
} from "../../../action/costimizeV2Active";
import "./sav_op.css";
import AssignDeferForm from "./AssignDeferForm";
import { RxOpenInNewWindow } from "react-icons/rx";

const { Text } = Typography;

const ExpandedRowDetails = (props) => {
  return (
    <>
      <Row>
        <Col>
          <h3 className="expanded-row-key">hello</h3>
          <h3 className="expanded-row-value">check</h3>
        </Col>
      </Row>
    </>
  );
};
const PotentialTable = (props) => {
  //state
  const dispatch = useDispatch();
  const [drawerstatus, setDrawerStatus] = useState(false);
  const [tablerecords, setTableRecords] = useState([]);
  const [oldUpdateRecords, setOldUpdateRecords] = useState({});
  const [deferredRecords, setDeferredRecords] = useState([]);
  const [tableTotal, setTableTotal] = useState(0);
  const permission = {
    savingswrite: checkRolePermission("savings_model")
      ? checkRolePermission("savings_model").write
      : 0,
  };
  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const {
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedTopdate,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const columns = [
    {
      title: "Account",
      dataIndex: "account_names",
      key: "account_names",
      // width: "8%",
      align: "center",
      // sorter: (a, b) => {
      //   return a.account > b.account ? -1 : 1;
      // },
      render: (record, index) => {
        if (record === null) {
          return "";
        }
        if (record.length > 10) {
          return (
            <>
              <Tooltip placement="bottom" title={record}>
                {record.substring(0, 10)}...
              </Tooltip>
            </>
          );
        } else {
          return record;
        }
      },
    },
    {
      title: "Application",
      dataIndex: "applications",
      key: "applications",
      // width: "10%",
      align: "center",
      // sorter: (a, b) => {
      //   return a.application > b.application ? -1 : 1;
      // },
      render: (record, index) => {
        if (record === null) {
          return "";
        }
        if (record.length > 15) {
          return (
            <>
              <Tooltip placement="bottom" title={record}>
                {record.substring(0, 15)}...
              </Tooltip>
            </>
          );
        } else {
          return record;
        }
      },
    },
    {
      title: "Environment",
      dataIndex: "environments",
      key: "environments",
      // width: "11%",
      align: "center",
      // sorter: (a, b) => {
      //   return a.environment > b.environment ? -1 : 1;
      // },
      render: (record, index) => {
        if (record.length > 10) {
          return (
            <>
              <Tooltip placement="bottom" title={record}>
                {record.substring(0, 10)}...
              </Tooltip>
            </>
          );
        } else {
          return record;
        }
      },
    },
    {
      title: "Services",
      dataIndex: "services",
      key: "services",
      // width: "8%",
      align: "center",
      // sorter: (a, b) => {
      //   return a.services > b.services ? -1 : 1;
      // },
      render: (record, index) => {
        if (record.length > 10) {
          return (
            <>
              <Tooltip placement="bottom" title={record}>
                {record.substring(0, 10)}...
              </Tooltip>
            </>
          );
        } else {
          return record;
        }
      },
    },
    {
      title: "Resource Name",
      key: "resource_name",
      dataIndex: "resource_name",
      // width: "10%",
      align: "center",
      // sorter: (a, b) => {
      //   return (a.resource_name || "").localeCompare(b.resource_name || "");
      // },
      render: (record, index) => {
        if (!record) {
          return "";
        }

        if (record.length > 10) {
          return (
            <>
              <Tooltip placement="bottom" title={record}>
                {record.substring(0, 10)}...
              </Tooltip>
            </>
          );
        } else {
          return record;
        }
      },
    },
    {
      title: "Current Configuration",
      dataIndex: "current_configuration",
      key: "current_configuration",
      // width: "12%",
      align: "center", // sorter: (a, b) => {
      //   return a.current_configuration > b.current_configuration ? -1 : 1;
      // },
      render: (record) => {
        if (record.length > 16) {
          return (
            <>
              <Tooltip placement="bottom" title={record}>
                {record.substring(0, 16)}...
              </Tooltip>
            </>
          );
        } else {
          return record;
        }
      },
    },
    {
      title: "Recommended Configuration",
      dataIndex: "recommended_configuration",
      key: "recommended_configuration",
      // width: "15%",
      align: "center",
      // sorter: (a, b) => {
      //   return a.environment > b.environment ? -1 : 1;
      // },
      render: (record) => {
        if (record.length > 16) {
          return (
            <>
              <Tooltip placement="bottom" title={record}>
                {record.substring(0, 16)}...
              </Tooltip>
            </>
          );
        } else {
          return record;
        }
      },
    },
    {
      title: "Commitment",
      dataIndex: "commitment",
      key: "commitment",
      // width: "10%",
      sorter: (a, b) => {
        return a.commitment > b.commitment ? -1 : 1;
      },
      render: (record) => {
        if (record) {
          return <>{record}</>;
        } else {
          return <>-</>;
        }
      },
    },
    {
      title: "Potential Savings",
      dataIndex: "potential_savings",
      key: "potential_savings",
      // width: "11%",
      align: "right",
      sorter: (a, b) => {
        return a.potential_savings > b.potential_savings ? -1 : 1;
      },
      render: (record) => {
        return (
          <>
            <div className="number_right">${numberFormat(record)}</div>
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      // width: "7%",
      fixed: "right",
      align: "center",
      render: (record, index) => {
        if (props.rolestatus) {
          if (permission.savingswrite) {
            return (
              <>
                <div
                  onClick={() => {
                    setOldUpdateRecords(index);
                    setDrawerStatus(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Icon
                    icon="clarity:assign-user-solid"
                    style={{ fontSize: "18px" }}
                  />
                </div>
              </>
            );
          } else {
            return <></>;
          }
        } else {
          return <></>;
        }
      },
    },
  ];
  useEffect(() => {
    var input = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: selectedinfra.toString(),
      db: selecteddb.toString(),
      date: selectedTopdate,
      company_name: company_name,
    };
    dispatch(listRecommendation({ input }))
      .unwrap()
      .then(({ data }) => {
        var value_list = data.newfunctionquery10.map((datum, index) => ({
          key: index + 1,
          ...datum,
        }));
        setTableRecords(value_list);
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(dateddeferredrecords({ input }))
      .unwrap()
      .then(({ data }) => {
        const altered_keys = data.list_deferred.map((item) => {
          return {
            account_names: item.account_names,
            applications: item.applications,
            clouds: item.cloud,
            commitment: item.commitment,
            current_configuration: item.current_configuration,
            environments: item.environments,
            potential_savings: item.potential_savings,
            recommendation_rule: item.recommendation_rule,
            recommended_configuration: item.recommended_configuration,
            report_month: item.report_month,
            resource_id: item.resource_id,
            resource_name: item.resource_name,
            resource_sub_category: item.resource_sub_category,
            reason: undefined,
            services: item.services,
            status: "Deferred",
          };
        });
        setDeferredRecords(altered_keys);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    props.recordsupdate,
    selectedAccount,
    selectedApplication,
    selectedCloud,
    selectedservices,
    selectedEnvironment,
    selectedTopdate,
    selecteddb,
    selectedinfra,
    selectedos,
  ]);

  return (
    <>
      <AssignDeferForm
        drawerstatus={drawerstatus}
        setDrawerStatus={setDrawerStatus}
        oldUpdateRecords={oldUpdateRecords}
        recordsupdate={props.recordsupdate}
        setRecordsUpdate={props.setRecordsUpdate}
      />
      <Table
        // scroll={{
        //   // x: 500,
        //   y: "25rem",
        // }}
        className="savop-table"
        columns={columns}
        // expandable={{
        //   expandedRowRender: (record) => (
        //     <ExpandedRowDetails record={record} />
        //   ),
        // }}
        dataSource={
          props.filter === "Deferred"
            ? deferredRecords
            : tablerecords.filter(function (val) {
                return val.recommendation_rule === props.filter;
              })
        }
        // pagination={(true, { pageSize: 5 })}
        pagination={false}
        summary={(pageData) => {
          const potential = pageData.reduce(
            (a, v) => (a = +a + +v?.potential_savings),
            0
          );
          return (
            <>
              <Table.Summary fixed>
                <Table.Summary.Row fixed className="savop-table-footer">
                  <Table.Summary.Cell className="d-flex align-items-center justify-content-end">
                    Total:
                    <Text
                      className="white-color"
                      style={{ paddingLeft: "1rem" }}
                    >
                      ${numberFormat(potential)}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            </>
          );
        }}
      />
    </>
  );
};

export default memo(PotentialTable);
