import React from "react";
import { Card, Progress } from "antd";
import ReactApexChart from "react-apexcharts";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Modal,
} from "antd";
function RangeChartAssets() {
  const categoryData = [
    { start: 10, end: 30 },
    { start: 20, end: 40 },
    { start: 5, end: 25 },
    { start: 15, end: 20 },
  ];

  // Calculate the total range
  const totalRange = categoryData.reduce(
    (total, category) => {
      return {
        start: total.start + category.start,
        end: total.end + category.end,
      };
    },
    { start: 0, end: 0 }
  );

  // Create the data for the chart
  const chartData = [
    {
      x: "Warm Stand By",
      y: [10, 30],
      fill: "#A7EE7C",
    },
    {
      x: "Plot Light",
      y: [20, 40],
      fill: "#A7EE7C",
    },
    {
      x: "No DR",
      y: [5, 25],
      fill: "#A7EE7C",
    },
    {
      x: "Multi Site",
      y: [15, 20],
      fill: "#A7EE7C",
    },

    {
      x: "Total",
      y: [0, totalRange.start],
      fill: "#999999",
      tooltip: {
        content: `Total: ${totalRange.start}`, // Display the total value
      },
    },
  ];
  const tooltipFormatter = (tooltipItems) => {
    if (tooltipItems && tooltipItems.length > 0) {
      const seriesIndex = tooltipItems[0].dataPointIndex;
      if (seriesIndex === 3) {
        return `Total: ${totalRange.start}`;
      } else {
        const category = chartData[seriesIndex];
        const range = `[${category.y[0]}, ${category.y[1]}]`;
        return `${category.x}: ${range}`;
      }
    }
  };
  const options = {
    chart: {
      type: "rangeBar",
      height: 250,
    },
    plotOptions: {
      bar: {
        horizontal: false, // Set to vertical bars
        distributed: true,
        // Distribute bars evenly within each category
      },
    },
    xaxis: {
      type: "category",
      categories: [
        "Warm StandBy",
        "Pilot Light",
        "No DR",
        "Multisite",

        "Total",
      ],
      labels: {
        style: {
          colors: "#FFFFFF", // Define colors for each category
        },
      },
    },
    tooltip: {
      enabled: false,
      // Use the custom tooltip formatter
    },
    dataLabels: {
      enabled: true, // Disable data labels
    },
  };

  const series = [
    {
      name: "Range",
      data: chartData,
      dataLabels: {
        enabled: true, // Enable data labels
      },
    },
  ];

  return (
    <div>
      <ReactApexChart
        className="cd1"
        options={options}
        series={series}
        type="rangeBar"
        height={250}
      />
    </div>
  );
}

export default RangeChartAssets;
