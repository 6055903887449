import { Col, Row, Tabs } from "antd";
import React from "react";
import BudgetvsSpend from "../BudgetvsSpend";
import "./quick-glance-parent-tabs.css";
import CostimazeDatePicker from "../../layout/CostimazeDatePicker";
import TabPane from "antd/es/tabs/TabPane";
import SavingsOppurtunity from "../../quick_glance/savings_oppurtunity/SavingsOppurtunity";
import Compliance from "../compliance/Compliance";
import IndexoOfAnnualCost from "./IndexoOfAnnualCost";

const items = [
  {
    key: "1",
    label: "Budget vs Spend",
    children: <BudgetvsSpend />,
  },
  {
    key: "2",
    label: "Annual Cost Savings",
    children: <IndexoOfAnnualCost />,
  },
  {
    key: "3",
    label: "Compliance",
    children: <Compliance />,
  },
];
const QuickGlanceParentTabContainer = () => (
  <div className=" position-relative ">
    <Tabs defaultActiveKey="1" className="qg-custom-tabs">
      {items.map((item) => (
        <TabPane tab={item.label} key={item.key}>
          {item.children}
        </TabPane>
      ))}
    </Tabs>
    <div className="year-picker-qg-top-right">
      <CostimazeDatePicker />
    </div>
  </div>
);
export default QuickGlanceParentTabContainer;
