import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  overallsummarytable,
  weeklyreportappsummary,
  weeklyreportchart,
  weeklyreportenvironment,
  weeklyreportenvweekwisechart,
  weeklyreportenvweekwisechartsummary,
  weeklyreportservicedetailed,
  weeklyreportweekwisechart,
  weeklyreportweekwisechartsummary,
  weeklyreportweekwisechartsummaryservice,
  weeklyreportweekwisechartsummarytop5,
  weeklyreportweekwisechartsummarytopincrease,
  applicationlevel,
  getservicetotalsummary,
  getapplicationtotal,
  getservicepastfourweeks,
  getapplicationpastfourweeks,
} from "../../action/accountAction";
const Reportdata = (props) => {
  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );

  const start_date = props.selectedDate;
  const end_date = props.endDate;
  const { selectedTopdate } = useSelector((state) => state.constimize);

  const calculateMonthStartDate = (inputDate) => {
    const date = new Date(inputDate);
    date.setDate(1); // Set the date to 1 to get the start of the month
    const year = date.getFullYear();
    const month = date.getMonth();
    const startDate = new Date(year, month, 1);
    return startDate.toISOString().slice(0, 10); // Format as "YYYY-MM-DD"
    // const [year, month] = inputDate.split("-").map(Number);
    // const startDate = new Date(year, month - 1, 1); // Month is 0-indexed
    // return startDate.toISOString().slice(0, 10);
  };

  // const date = "2023-11-01";
  // const end_date = "2023-11-11";
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const currentDate = new Date(selectedTopdate);

    const oneWeekLater = new Date(currentDate);
    oneWeekLater.setDate(oneWeekLater.getDate() + 7);
    // const end_date = `${oneWeekLater.getFullYear()}-${String(
    //   oneWeekLater.getMonth() + 1
    // ).padStart(2, "0")}-${String(oneWeekLater.getDate()).padStart(2, "0")}`;
    //  weekly_report_month_wise_chart

    //weekly_report_env_week_wise_summary

    dispatch(
      weeklyreportenvweekwisechartsummary({
        company_name,
        start_date,
        end_date,
      })
    )
      .unwrap()
      .then(({ data }) => {
        //.log("week wise summary>>s>", data);
        const reportweeksum = data.weekly_report_env_week_wise_summary.map(
          (datas) => {
            return {
              current_week: datas.current_week,
              previous_week: datas.previous_week,
              pre_previous_week: datas.pre_previous_week,
              fourth_week: datas.fourth_week,
              environment: datas.environment,
              no_of_application: datas.no_of_application,
              vm_count: datas.vm_count,
              cpu: datas.cpu,
              memory: datas.memory,
              storage: datas.storage,
              difference: datas.difference,
            };
          }
        );
        //.log(reportweeksum);
        props.setReportweekwisesum(reportweeksum);
      })
      .catch((err) => {
        console.log(err);
      });

    // // weekly_report_week_wise_chart
    dispatch(weeklyreportweekwisechart({ company_name, start_date, end_date }))
      .unwrap()
      .then(({ data }) => {
        // //.log(data)

        const reportweek = data.weekly_report_week_wise_chart.map((datas) => {
          return {
            current_week: datas.current_week,
            previous_week: datas.previous_week,
            pre_previous_week: datas.pre_previous_week,
            t_previous_week: datas.t_previous_week,
          };
        });
        props.setReportweek(reportweek);
      })
      .catch((err) => {
        console.log(err);
      });
    // //weekly_report_env_week_wise_chart

    dispatch(
      weeklyreportenvweekwisechart({ company_name, start_date, end_date })
    )
      .unwrap()
      .then(({ data }) => {
        //.log(data);

        const reportweek = data.weekly_report_env_week_wise_chart.map(
          (datas) => {
            return {
              current_week: datas.current_week,
              previous_week: datas.previous_week,
              pre_previous_week: datas.pre_previous_week,
              environment: datas.environment,
              fourth_week: datas.fourth_week,
            };
          }
        );
        props.setReportweekwise(reportweek);
        props.setIsLoading(false);
      })
      .catch((err) => {
        //.log(err);
      });

    //weekly_report_app_week_wise_summary
    dispatch(
      weeklyreportweekwisechartsummary({
        company_name,
        start_date,
        end_date,
      })
    )
      .unwrap()
      .then(({ data }) => {
        const reportweeksumchart = data.weekly_report_app_week_wise_summary.map(
          (datas) => {
            return {
              current_week: datas.current_week,
              previous_week: datas.previous_week,
              pre_previous_week: datas.pre_previous_week,
              application: datas.application,
              no_of_environment: datas.no_of_environment,
              vm_count: datas.vm_count,
              cpu: datas.cpu,
              memory: datas.memory,
              storage: datas.storage,
              difference: datas.difference,
            };
          }
        );
        //.log(reportweeksumchart);
        props.setReportweekwiseappsum(reportweeksumchart);
      })
      .catch((err) => {
        //.log(err);
      });
    // //weekly_report_app_week_wise_top5_decrease
    dispatch(
      weeklyreportweekwisechartsummarytop5({
        company_name,
        start_date,
        end_date,
      })
    )
      .unwrap()
      .then(({ data }) => {
        const reportweeksumcharttopdec =
          data.weekly_report_app_week_wise_top5_decrease.map((datas) => {
            return {
              current_week: datas.current_week,
              environment: datas.environment,
              previous_week: datas.previous_week,
              pre_previous_week: datas.pre_previous_week,
              fourth_week: datas.fourth_week,
              application: datas.application,
              difference: datas.difference,
            };
          });
        //.log(reportweeksumcharttopdec);
        props.setReportweekwiseappsumdec(reportweeksumcharttopdec);
        props.setSpinner(false)
      })
      .catch((err) => {
        //.log(err);
      });
    // //weekly_report_app_week_wise_top5_increase
    dispatch(
      weeklyreportweekwisechartsummarytopincrease({
        company_name,
        start_date,
        end_date,
      })
    )
      .unwrap()
      .then(({ data }) => {
        const reportweeksumcharttopinc =
          data.weekly_report_app_week_wise_top5_increase.map((datas) => {
            return {
              current_week: datas.current_week,
              environment: datas.environment,
              previous_week: datas.previous_week,
              pre_previous_week: datas.pre_previous_week,
              fourth_week: datas.fourth_week,
              application: datas.application,
              difference: datas.difference,
            };
          });
        //.log(reportweeksumcharttopinc);
        props.setReportweekwiseappsuminc(reportweeksumcharttopinc);
        props.setSpinner(false)
      })
      .catch((err) => {
        //.log(err);
      });

    dispatch(
      applicationlevel({
        company_name,
        start_date,
        end_date,
      })
    )
      .unwrap()
      .then(({ data }) => {
        const reportapplicationsum =
          data.weekly_report_app_week_wise_top5_increase_chart.map((datas) => {
            return {
              application: datas.application,
              environment: datas.environment,
              pre_previous_week: datas.pre_previous_week,
              previous_week: datas.previous_week,
              current_week: datas.current_week,
            };
          });
        //.log(reportapplicationsum);

        props.setReportApplication(reportapplicationsum);
        props.setSpinner(false);
      })
      .catch((err) => {
        //.log(err);
      });

    // //weekly_report_service_week_wise_summary
    dispatch(
      weeklyreportweekwisechartsummaryservice({
        company_name,
        start_date,
        end_date,
      })
    )
      .unwrap()
      .then(({ data }) => {
        // //.log(data)

        const reportweeksumchartservice =
          data.weekly_report_service_week_wise_summary.map((datas) => {
            return {
              current_week: datas.current_week,
              fourth_week: datas.fourth_week,
              previous_week: datas.previous_week,
              pre_previous_week: datas.pre_previous_week,
              difference: datas.difference,
              service: datas.service,
              no_of_environment: datas.no_of_environment,
              no_of_application: datas.no_of_application,
              zeroth_week:datas.zeroth_week,
              region: datas.region,
            };
          });
        props.setReportweekwiseappsumservice(reportweeksumchartservice);
        props.setSpinner(false)
      })
      .catch((err) => {
        console.log(err);
      });

    dispatch(
      weeklyreportappsummary({
        company_name,
        start_date,
        end_date,
      })
    )
      .unwrap()
      .then(({ data }) => {
        //.log("repost app wise", data);
        const reportweekapp =
          data.weekly_report_service_application_week_detailed_summary.map(
            (datas) => {
              return {
                environment: datas.environment,
                application: datas.application,
                vm_count: datas.vm_count,
                cpu: datas.cpu,
                memory: datas.memory,
                storage: datas.storage,
                pre_previous_week: datas.pre_previous_week,
                previous_week: datas.previous_week,
                current_week: datas.current_week,
                fourth_week: datas.fourth_week,
                zeroth_week:datas.zeroth_week,
                difference: datas.difference,
              };
            }
          );
        props.setReportweekappservice(reportweekapp);
        props.setSpinner(false)
      })
      .catch((err) => {
        console.log(err);
      });

    dispatch(
      weeklyreportenvironment({
        company_name,
        start_date,
        end_date,
      })
    )
      .unwrap()
      .then(({ data }) => {
        //.log(data);

        const reportweekenvironment =
          data.weekly_report_service_environment_week_detailed_summary.map(
            (datas) => {
              return {
                environment: datas.environment,
                application: datas.application,
                vm_count: datas.vm_count,
                cpu: datas.cpu,
                memory: datas.memory,
                storage: datas.storage,
                pre_previous_week: datas.pre_previous_week,
                previous_week: datas.previous_week,
                current_week: datas.current_week,
                difference: datas.difference,
                fourth_week: datas.fourth_week,
              };
            }
          );
        //.log(reportweekenvironment);
        props.setReportweekenvironment(reportweekenvironment);
      })
      .catch((err) => {
        //.log(err);
      });

    dispatch(
      weeklyreportservicedetailed({
        company_name,
        start_date,
        end_date,
      })
    )
      .unwrap()
      .then(({ data }) => {
        //.log(data);

        const reportservicedetailed =
          data.weekly_report_service_week_detailed_summary.map((datas) => {
            return {
              service: datas.service,
              environment: datas.environment,
              application: datas.application,
              region: datas.region,
              pre_previous_week: datas.pre_previous_week,
              previous_week: datas.previous_week,
              current_week: datas.current_week,
              difference: datas.difference,
              fourth_week: datas.fourth_week,
            };
          });
        props.setReportdetailedservice(reportservicedetailed);
      })
      .catch((err) => {
        //.log(err);
      });

    //servide
    dispatch(
      getservicetotalsummary({
        company_name,

        end_date,
        start_date,
      })
    )
      .unwrap()
      .then(({ data }) => {
        //.log(data);

        const reportservice =
          data.weekly_report_consumption_service_top_5_summary.map((datas) => {
            return {
              service: datas.service,
              total_spend: datas.total_spend,
            };
          });
        props.setReportService(reportservice);
        props.setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    dispatch(
      getapplicationtotal({
        company_name,

        end_date,
        start_date,
      })
    )
      .unwrap()
      .then(({ data }) => {
        //.log(data);

        const applicationservice =
          data.weekly_report_consumption_application_top_5_summary.map(
            (datas) => {
              return {
                application: datas.application,
                total_spend: datas.total_spend,
              };
            }
          );
        props.setApplicationService(applicationservice);
        props.setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    //service
    dispatch(
      getservicepastfourweeks({
        company_name,

        end_date,
        start_date,
      })
    )
      .unwrap()
      .then(({ data }) => {
        //.log(data);

        const servicepast4weeks =
          data.weekly_report_service_past4_week_top_5_summary.map((datas) => {
            return {
              service: datas.service,
              total_spend: datas.total_spend,
            };
          });
        props.setServiceFourWeeks(servicepast4weeks);
        props.setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    dispatch(
      getapplicationpastfourweeks({
        company_name,

        end_date,
        start_date,
      })
    )
      .unwrap()
      .then(({ data }) => {
        //.log(data);

        const applicationpastfourweeks =
          data.weekly_report_app_wise_past4_week_top5_increase_chart.map(
            (datas) => {
              return {
                application: datas.application,
                total_spend: datas.total_spend,
              };
            }
          );
        props.setApplicationFourweeks(applicationpastfourweeks);
        props.setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.selectedDate, props.endDate]);

  useEffect(() => {
    if (props.selectedDate && props.endDate) {
      const currentDates = new Date();
      const currentDate = currentDates.getDate();
      
      let startDates;
      if (currentDate <= 3) {
        const previousMonth = new Date(currentDates.getFullYear(), currentDates.getMonth() - 1, 1);
        const year = previousMonth.getMonth() === 11 ? currentDates.getFullYear() - 1 : currentDates.getFullYear();
        startDates = new Date(year, previousMonth.getMonth(), 1);
      } else {
        startDates = new Date(currentDates.getFullYear(), currentDates.getMonth(), 1);
      }
      
      // Format the date as YYYY-MM-DD for the start date
      const date = `${startDates.getFullYear()}-${(startDates.getMonth() + 1).toString().padStart(2, '0')}-01`;
      dispatch(weeklyreportchart({ company_name, date }))
        .unwrap()
        .then(({ data }) => {
          //.log("weekly report>>", data);

          const reportmonth = data.weekly_report_month_wise_chart.map(
            (datas) => {
              return {
                month: datas.month,
                total_spend: datas.total_spend,
                credit_spend: datas.credit_spend,
                with_credit: datas.with_credit,
                aws_consumption: datas.aws_consumption,
                projected_spend: datas.projected_spend,
              };
            }
          );
          props.setReportmonth(reportmonth);
          props.setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });

      dispatch(
        overallsummarytable({
          date,
          company_name,
        })
      )
        .unwrap()
        .then(({ data }) => {
          //.log("week wise summary>>s>", data);

          const overall = data.weekly_report_overall_summary.map((datas) => {
            return {
              current_month_spend: datas.current_month_spend,
              previous_month_spend: datas.previous_month_spend,
              projected_spend: datas.projected_spend,
              difference: datas.difference,
            };
          });
          props.setOverall(overall);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [
    props.selectedDate,
    props.endDate,
    // company_name,
    // props.setReportmonth,
    // props.setOverall,
  ]);
  return <></>;
};
export default Reportdata;
