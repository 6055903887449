import { memo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Spin } from "antd";
import { useEffect } from "react";

const RealisedChart = (props) => {
  const [chartvalue, setChartValue] = useState({
    lable: [],
    value: [],
  });
  const [records, setRecords] = useState([]);
  useEffect(() => {
    var value_list = [];

    const tablelist = props.records.map((data) => {
      if (
        data.realised_savings === null ||
        Math.round(data.realised_savings) === 0
      ) {
        const lost_opportunity = data.potential_savings - data.realised_savings;
        value_list.push({
          recommendation_type: data.recommendation_rule,
          potential_savings: Number(data.potential_savings),
        });
      }
    });

    //recommendation type
    const recommendation_list = value_list.map((v) => v.recommendation_type);
    const recommendation_set = new Set([...recommendation_list]);
    const recommendation = [...recommendation_set];
    var chart_lable = [];
    var chart_value = [];
    setRecords(recommendation);
    if (recommendation.length > 0) {
      recommendation.map((data) => {
        const datalist = value_list.filter((datas) => {
          return (
            datas.recommendation_type?.toLowerCase() === data?.toLowerCase()
          );
        });
        const potential = datalist.reduce(
          (a, v) => (a = +a + +v?.potential_savings),
          0
        );
        chart_value.push(Math.round(potential));
        chart_lable.push(data);
      });
      const total = value_list.reduce(
        (a, v) => (a = +a + +v?.potential_savings),
        0
      );

      chart_value.push(Math.round(total));
      chart_lable.push("Total");
      setChartValue({
        lable: chart_lable,
        value: chart_value,
      });
    }
  }, [props]);
  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        distributed: true,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
      truncate: {
        maxChars: 10,
      },
    },
    // colors: ["#76C5F1"],
    colors: [
      "#008FFB",
      "#00E396",
      "#FEB019",
      "#FF4560",
      "#775DD0",
      "#546E7A",
      "#26a69a",
      "#d50000",
      "#FFFF00",
    ],
    grid: {
      show: false,
    },
    yaxis: {
      labels: {
        // maxWidth: 100,
        style: {
          colors: "white",
        },
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: chartvalue.lable,
      labels: {
        style: {
          colors: "white",
        },
        formatter: function (num, opts) {
          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
  };

  const series = [
    {
      name: "Potential Savings",
      data: chartvalue.value,
    },
  ];

  return (
    <>
      <Spin spinning={props.lsavingsstatus} wrapperClassName="costimize-loader">
        {records.length > 0 ? (
          <>
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={150}
            />
          </>
        ) : (
          <>
            <p className="savings-data-not-found text-white">No Data</p>
          </>
        )}
      </Spin>
    </>
  );
};

export default memo(RealisedChart);
