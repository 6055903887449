import React, { useState, useEffect } from "react";
import { Table, Tooltip,Spin} from "antd";
import { filterAllKubernetesList } from "../../action/costimizedashboardAction";
import {
  getK8SpendCloudRecords,
  getK8Applications,
  getK8_ResourcenameRecords,
} from "../../action/costimizeV2Active";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
const AssetKubernetes = (props) => {
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const [envdata, setEnvdata] = useState([]);
  const [clouddata, setclouddata] = useState([]);
  const [appdata, setappdata] = useState([]);
  const [resname, setResname] = useState([]);
const [loadingstatus, setLoadingstatus] = useState(true);

  //   const [chartrecord, setchartrecord] = useState([]);
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Environment",
      dataIndex: "environment",
      key: "environment",
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      align: "right",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "left",
    },
    {
      title: "Savings Oppurtunity",
      dataIndex: "savings",
      key: "savings",
      align: "center",
    },
  ];
  const groupedData = envdata.reduce((acc, item) => {
    const key = `${item.account_name}_${item.environment}`;
    if (!acc[key]) {
      acc[key] = {
        account_name: item.account_name,
        environment: item.environment,
        count: 0,
        spend: 0,
        core: 0,
        savings: 0,
        ram: 0,
        cloud: null,
        storage: null,
      };
    }
    acc[key].count += parseInt(item.count, 10);
    acc[key].spend += parseInt(item.spend, 10);
    acc[key].core += parseInt(item.core, 10);
    acc[key].savings += parseInt(item.savings_opportunity, 10);
    acc[key].storage += parseInt(item.storage, 10);
    acc[key].ram += parseInt(item.ram, 10);
    return acc;
  }, {});
  const aggregatedData = Object.values(groupedData);
  const mergedChildrenMap = new Map();
  aggregatedData.forEach((item, index) => {
    const environmentKey = item.environment;
    if (!mergedChildrenMap.has(environmentKey)) {
      mergedChildrenMap.set(environmentKey, { ...item });
    } else {
      const mergedItem = mergedChildrenMap.get(environmentKey);
      mergedItem.count += item.count;
      mergedItem.spend += parseFloat(item.spend || 0);
      mergedItem.core += parseFloat(item.core || 0);
      if (!isNaN(item.ram)) {
        mergedItem.ram = isNaN(mergedItem.ram)
          ? item.ram
          : mergedItem.ram + item.ram;
      }

      if (!isNaN(item.savings)) {
        mergedItem.savings = isNaN(mergedItem.savings)
          ? item.savings
          : mergedItem.savings + item.savings;
      }
      if (!isNaN(item.cloud)) {
        mergedItem.cloud = isNaN(mergedItem.cloud) ? "" : item.cloud;
      }
    }
  });
  const mergedChildren = Array.from(mergedChildrenMap.values());
  const groupedCloudData = clouddata.reduce((result, environmentGroup) => {
    const environment = environmentGroup.data[0].environment;
    const cloudGroups = environmentGroup.data.reduce((cloudResult, item) => {
      const cloudName = item.cloud;
      const existingEntry = cloudResult.find(
        (entry) => entry.cloud === cloudName
      );
      if (existingEntry) {
        existingEntry.count += parseInt(item.count || 0, 10);
        existingEntry.core += parseFloat(item.core || 0);
        existingEntry.spend += parseFloat(item.spend || 0);
        existingEntry.ram += parseFloat(item.ram || 0);
        existingEntry.savings_opportunity += parseFloat(
          item.savings_opportunity || 0
        );
      } else {
        cloudResult.push({
          cloud: cloudName,
          count: parseInt(item.count || 0, 10),
          spend: parseFloat(item.spend || 0),
          environment: item.environment,
          core: parseFloat(item.core || 0),
          ram: parseFloat(item.ram || 0),
          savings_opportunity: parseFloat(item.savings_opportunity || 0),
        });
      }
      return cloudResult;
    }, []);
    result.push({
      environment,
      clouds: cloudGroups,
    });
    return result;
  }, []);
  const groupedAppData = appdata.reduce((result, environmentGroup) => {
    const environment = environmentGroup.data[0]?.environment;
    const cloudGroups = environmentGroup.data.reduce((cloudResult, item) => {
      const cloudName = item.cloud;
      const existingCloudEntry = cloudResult.find(
        (entry) => entry.cloud === cloudName
      );
      if (existingCloudEntry) {
        const existingAppEntry = existingCloudEntry.applications.find(
          (app) => app.application === item.application
        );
        if (existingAppEntry) {
          existingAppEntry.count += parseInt(item.count || 0, 10);
          existingAppEntry.core += parseFloat(item.core || 0);
          existingAppEntry.spend += parseFloat(item.spend || 0);
          existingAppEntry.ram += parseFloat(item.ram || 0);
          existingAppEntry.savings_opportunity += parseFloat(
            item.savings_opportunity || 0
          );
        } else {
          existingCloudEntry.applications.push({
            application: item.application,
            count: parseInt(item.count || 0, 10),
            spend: parseFloat(item.spend || 0),
            core: parseFloat(item.core || 0),
            spend: parseFloat(item.spend || 0),
            ram: parseFloat(item.ram || 0),
            savings_opportunity: parseFloat(item.savings_opportunity || 0),
          });
        }
      } else {
        cloudResult.push({
          cloud: cloudName,
          applications: [
            {
              application: item.application,
              count: parseInt(item.count || 0, 10),
              spend: parseFloat(item.spend || 0),
              ram: parseFloat(item.ram || 0),
              core: parseFloat(item.core || 0),
              savings_opportunity: parseFloat(item.savings_opportunity || 0),
            },
          ],
        });
      }
      return cloudResult;
    }, []);
    result.push({
      environment,
      clouds: cloudGroups,
    });
    return result;
  }, []);
  const distinctArrayscloud = groupedAppData.reduce((result, item) => {
    const environment = item.environment;
    const existingEnvironment = result.find(
      (el) => el.environment === environment
    );
    if (!existingEnvironment) {
      result.push({ environment, clouds: item.clouds });
    } else {
      existingEnvironment.clouds = [
        ...existingEnvironment.clouds,
        ...item.clouds,
      ];
    }
    return result;
  }, []);
  const insideValues2 = distinctArrayscloud.flatMap((item) =>
    item.clouds.map((cloud) => ({ environment: item.environment, ...cloud }))
  );
  const filteredData = resname.flatMap((group) => {
    const uniqueCombinations = new Set();
    return group.data.filter((item) => {
      const key = `${item.environment}_${item.cloud}_${item.application}_${item.instancetype}_${item.resourcename}_${item.account_name}`;
      if (!uniqueCombinations.has(key)) {
        uniqueCombinations.add(key);
        return true;
      }
      return false;
    });
  });
  const generateAdditionalNestedResChildren = (parentItem, app) => {
    const uniqueCombinations = new Set();
    const filteredEnvironments = filteredData
      .filter((item) => item.environment === parentItem.environment)
      .filter((item) => {
        const key = `${item.environment}_${item.cloud}_${item.application}_${item.instancetype}_${item.resourcename}_${item.account_name} `;
        if (!uniqueCombinations.has(key)) {
          uniqueCombinations.add(key);
          return true;
        }
        return false;
      });
    const filteredCloud = filteredEnvironments.filter((appl) => {
      return (
        appl.cloud &&
        appl.cloud.trim().toLowerCase() ===
          parentItem.cloud.trim().toLowerCase()
      );
    });
    const filteredApp = filteredCloud.filter((appl) => {
      return (
        appl.application &&
        appl.application.trim().toLowerCase() ===
          app.application.trim().toLowerCase()
      );
    });
    const resultArray = filteredApp.map((filteredApp) => {
      const fullName = filteredApp.resourcename;
      const truncatedName =
        fullName && fullName.length > 13
          ? `${fullName.slice(0, 13)}...`
          : fullName;

      const key = `${filteredApp.environment}_${filteredApp.cloud}_${filteredApp.application}_${filteredApp.instancetype}_${filteredApp.resourcename}`;
      return {
        key: key,
        spend: (
          <>
            {`$${parseFloat(filteredApp.spend || 0)
              .toFixed(0)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
            {
              <Icon
                className={
                  "me-1 " +
                  (filteredApp.spend > 0
                    ? "text-danger-icon-custom"
                    : "text-success-icon-custom")
                }
                icon={
                  filteredApp.spend > 0
                    ? "material-symbols:arrow-drop-up"
                    : "material-symbols:arrow-drop-down"
                }
                style={{ fontSize: "20px" }}
              />
            }
          </>
        ),
        name: (
          <Tooltip title={fullName && fullName.length > 13 ? fullName : null}>
            {truncatedName ? truncatedName : " "}
          </Tooltip>
        ),
        instance: filteredApp.instancetype,
        core: filteredApp.core
          ? parseFloat(filteredApp.core).toFixed(1).replace(/\.0$/, "")
          : "-",
        storage: `$${filteredApp.storage ? `${filteredApp.storage} ` : "-"}`,
        savings: filteredApp.savings_opportunity
          ? `$${parseFloat(filteredApp.savings_opportunity)
              .toFixed(0)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
          : "-",
        ram: filteredApp.ram
          ? `${parseFloat(filteredApp.ram).toFixed(0)}`
          : "-",
      };
    });
    return resultArray;
  };
  const generateAdditionalNestedChildren = (parentItem, key, data) => {
    const filteredEnvironments = insideValues2.filter(
      (item) => item.environment === parentItem.environment
    );
    const filteredCloud = filteredEnvironments.filter((app) => {
      return (
        app.cloud &&
        app.cloud.trim().toLowerCase() === parentItem.cloud.trim().toLowerCase()
      );
    });
    const removeDuplicates = (array) => {
      const seen = new Set();
      return array.filter((item) => {
        const key = JSON.stringify(item);
        return seen.has(key) ? false : seen.add(key);
      });
    };
    const uniqueEnvironments = removeDuplicates(filteredCloud);
    const mappedEnvironments = uniqueEnvironments.flatMap((env) => {
      const mappedApplications = (env.applications || []).map((app, index) => {
        return {
          key: `${key}${index + 1}`,
          environment: `${app.application}(${app.count})`,
          spend: (
            <>
              {`$${parseFloat(app.spend || 0)
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              {
                <Icon
                  className={
                    "me-1 " +
                    (app.spend > 0
                      ? "text-danger-icon-custom"
                      : "text-success-icon-custom")
                  }
                  icon={
                    app.spend > 0
                      ? "material-symbols:arrow-drop-up"
                      : "material-symbols:arrow-drop-down"
                  }
                  style={{ fontSize: "20px" }}
                />
              }
            </>
          ),
          core: `${parseFloat(app.core || 0).toFixed(0)}`,
          name: "-",
          instance: "-",
          cloud: parentItem.cloud,
          initialenv: data,
          savings: app.savings
            ? `$${parseFloat(app.savings)
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : "-",
          children: [...generateAdditionalNestedResChildren(parentItem, app)],
        };
      });
      return mappedApplications;
    });
    return mappedEnvironments;
  };
  const uniqueEnvironments = new Set();
  const distinctArrays = groupedCloudData.filter((item) => {
    const environment = item.environment;
    if (!uniqueEnvironments.has(environment)) {
      uniqueEnvironments.add(environment);
      return true;
    }
    return false;
  });
  const cloudsArray = distinctArrays.map((item) => item.clouds);
  const insideValues = cloudsArray.flat();
  const generateNestedChildren = (data, parentKey) => {
    return insideValues
      .filter((item) => item.environment === data)
      .map((item, index) => {
        let currentParentKey = `${parentKey}${index + 1}`;
        const additionalNestedChildren = generateAdditionalNestedChildren(
          item,
          currentParentKey,
          data
        );
        const nestedChildren = item.children
          ? generateNestedChildren(item.children, `${parentKey}${index + 1}`)
          : null;
        return {
          key: currentParentKey,
          environment: (
            <>
              {item.cloud === "AWS" && (
                <Icon icon="skill-icons:aws-light" className="asset-icon" />
              )}
              {item.cloud === "Azure" && (
                <Icon icon="skill-icons:azure-light" className="asset-icon" />
              )}
              {item.cloud === "GCP" && (
                <Icon icon="skill-icons:gcp-light" className="asset-icon" />
              )}
              {item.cloud !== "AWS" &&
                item.cloud !== "Azure" &&
                item.cloud !== "GCP" && (
                  <Icon icon="material-symbols:cloud" className="asset-icon" />
                )}
              {`${item.cloud}(${item.count})`}
            </>
          ),
          spend: (
            <>
              {`$${parseFloat(item.spend || 0)
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              {
                <Icon
                  className={
                    "me-1 " +
                    (item.spend > 0
                      ? "text-danger-icon-custom"
                      : "text-success-icon-custom")
                  }
                  icon={
                    item.spend > 0
                      ? "material-symbols:arrow-drop-up"
                      : "material-symbols:arrow-drop-down"
                  }
                  style={{ fontSize: "20px" }}
                />
              }
            </>
          ),
          name: "-",
          cloud: data,
          savings: item.savings_opportunity
            ? `$${parseFloat(item.savings_opportunity)
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : "-",
          children: [
            ...(nestedChildren || []),
            ...(additionalNestedChildren || []),
          ],
        };
      });
  };
  const spendValue = aggregatedData
    .reduce((total, item) => total + parseFloat(item.spend || 0), 0)
    .toFixed(2)
    .replace(/\.00$/, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const parsedSpendValue = parseFloat(spendValue);
  const isPositive = parsedSpendValue > 0;
  const updatedData = [
    {
      key: 1,
      environment: `All Environment(${aggregatedData.reduce(
        (total, item) => total + item.count,
        0
      )})`,
      spend: (
        <>
          {" "}
          ${spendValue}{" "}
          {
            <Icon
              className={
                "me-1 " +
                (isPositive
                  ? "text-danger-icon-custom"
                  : "text-success-icon-custom")
              }
              icon={
                isPositive
                  ? "material-symbols:arrow-drop-up"
                  : "material-symbols:arrow-drop-down"
              }
              style={{ fontSize: "20px" }}
            />
          }
        </>
      ),
      name: "-",
      savings: `${
        aggregatedData.reduce(
          (total, item) => total + parseFloat(item.savings || 0),
          0
        )
          ? "$" +
            aggregatedData
              .reduce((total, item) => total + parseFloat(item.savings || 0), 0)
              .toFixed(0)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "-"
      }`,
      cloud: "",
      children: mergedChildren.map((item, index) => {
        const childKey = 11 + index;
        return {
          key: 11 + index,
          environment: `${item.environment}(${item.count})`,
          spend: (
            <>
              {`$${parseFloat(item.spend || 0)
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
              {
                <Icon
                  className={
                    "me-1 " +
                    (item.spend > 0
                      ? "text-danger-icon-custom"
                      : "text-success-icon-custom")
                  }
                  icon={
                    item.spend > 0
                      ? "material-symbols:arrow-drop-up"
                      : "material-symbols:arrow-drop-down"
                  }
                  style={{ fontSize: "20px" }}
                />
              }
            </>
          ),
          name: "-",
          savings: item.savings
            ? `$${parseFloat(item.savings)
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : "-",
          children: generateNestedChildren(item.environment, `${childKey}`),
          cloud: "",
        };
      }),
    },
  ];
  const environments = [
    ...new Set(mergedChildren.map((entry) => entry.environment)),
  ];
  const cloudsname = [...new Set(insideValues.map((entry) => entry.cloud))];
  useEffect(() => {
    const apivalue = {
      environment: selectedEnvironment.toString(),
      account: selectedAccount.toString(),
      cloud: selectedCloud.toString(),
      application: selectedApplication.toString(),
      services: selectedservices.toString(),
      os: selectedos.toString(),
      infra: "EKS",
      db: selecteddb.toString(),
      company_name: companyName,
    };
    dispatch(
      filterAllKubernetesList({
        companyName,
        selectedTopdate,
        apivalue,
      })
    )
      .unwrap()
      .then(({ data }) => {
        if (data.newfunctionquery36.length > 0) {
          setEnvdata(data.newfunctionquery36);
        }
        setLoadingstatus(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoadingstatus(false)
      });
  }, [
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selectedservices,
    selectedos,
    selecteddb,
    companyName,
  ]);
  const getcloud = (record) => {
    environments.forEach((environment) => {
      const apivalue = {
        environment: environment,
        account: selectedAccount.toString(),
        cloud: selectedCloud.toString(),
        application: selectedApplication.toString(),
        services: selectedservices.toString(),
        os: selectedos.toString(),
        infra: "EKS",
        db: selecteddb.toString(),
        company_name: companyName,
      };
      dispatch(
        getK8SpendCloudRecords({
          companyName,
          selectedTopdate,
          apivalue,
        })
      )
        .unwrap()
        .then(({ data }) => {
          if (data.newfunctionquery37.length > 0) {
            setclouddata((prevData) => [
              ...prevData,
              {
                data: data.newfunctionquery37,
              },
            ]);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    });
  };
  const getapplication = (record) => {
    environments.forEach((environment) => {
      cloudsname.forEach((cloud) => {
        const apivalue = {
          environment: environment,
          account: selectedAccount.toString(),
          cloud: cloud,
          application: selectedApplication.toString(),
          services: selectedservices.toString(),
          os: selectedos.toString(),
          infra: "EKS",
          db: selecteddb.toString(),
          company_name: companyName,
        };

        dispatch(
          getK8Applications({
            companyName,
            selectedTopdate,
            apivalue,
          })
        )
          .unwrap()
          .then(({ data }) => {
            if (data.newfunctionquery38.length > 0) {
              setappdata((prevData) => [
                ...prevData,
                {
                  data: data.newfunctionquery38,
                },
              ]);
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      });
    });
  };
  const getresource = (record) => {
    insideValues2.forEach((data) => {
      data.applications.forEach((app) => {
        const apivalue = {
          environment: data.environment,
          account: selectedAccount.toString(),
          cloud: data.cloud,
          application: app.application,
          services: selectedservices.toString(),
          os: selectedos.toString(),
          infra: "EKS",
          db: selecteddb.toString(),
          company_name: companyName,
        };
        dispatch(
          getK8_ResourcenameRecords({
            companyName,
            selectedTopdate,
            apivalue,
          })
        )
          .unwrap()
          .then(({ data }) => {
            if (data.newfunctionquery35.length > 0) {
              setResname((prevData) => [
                ...prevData,
                {
                  data: data.newfunctionquery35,
                },
              ]);
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      });
    });
  };
  const handleExpanderClick = (record) => {
    const keyAsString = record.key.toString();
    const numberOfDigits = keyAsString.length;
    if (numberOfDigits === 3) {
      getapplication(record);
    } else if (numberOfDigits === 4) {
      getresource(record);
    } else if (numberOfDigits === 2) {
      getcloud(record);
    }
  };
  return (
    <>
      {" "}
      <div>
      <Spin spinning={loadingstatus}  wrapperClassName="costimize-loader">
        <Table
          columns={columns}
          dataSource={updatedData}
          className="assets-table"
          expandable={{
            onExpand: (expanded, record) => handleExpanderClick(record),
          }}
        />
        </Spin>
      </div>
    </>
  );
};
export default AssetKubernetes;
