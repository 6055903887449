import TabsPanel from "../costimize/components/TabsPanel";
import Tab from "../costimize/components/Tab";
import { MdOutlineShowChart, MdSavings } from "react-icons/md";
import { BiLineChart, BiDesktop } from "react-icons/bi";
import { Tabs } from "antd";
import { Tooltip, Layout } from "antd";
import ActivityTracker from "./ActivityTracker/ActivityTracker";
import PlannedOpenActivity from "./PlannedOpenActivity/PlannedOpenActivity";

//icons
// import Activity_Tracker from "../../assets/images/operation/side_icon/Activity_Tracker.svg";
// import asset_and_spend from "../../assets/images/operation/side_icon/Assets.svg";
// import utilizetion from "../../assets/images/operation/side_icon/Utility.svg";
// import compliance from "../../assets/images/operation/side_icon/Compliances.svg";
// import planned from "../../assets/images/operation/side_icon/Planned.svg";
import AssetSpend from "./AssetSpend/AssetSpend";
import Compliance from "./Compliance/Compliance";
import AssetsManaged from "./Untilization/AssetsManaged";
import RtoAndRpo from "./RtoAndRpo/RtoAndRpo";
import { Icon } from "@iconify/react";

const OperationSection2 = () => {
  const Activity_Tracker = (
    <Icon icon="material-symbols:dashboard" class="tab-icon-size" />
  );
  const utilizetion = (
    <Icon icon="fluent-mdl2:account-activity" class="tab-icon-size" />
  );
  const compliance = (
    <Icon icon="fluent-mdl2:compliance-audit" class="tab-icon-size" />
  );
  const planned = (
    <Icon icon="fluent:shifts-activity-20-filled" class="tab-icon-size" />
  );
  const asset_and_spend = (
    <Icon icon="fluent-mdl2:fixed-asset-management" class="tab-icon-size" />
  );
  const new_icon = (
    <Icon icon="fluent-mdl2:account-activity" class="tab-icon-size" />
  );
  const rtorpo = (
    <Icon icon="fluent-mdl2:web-environment" class="tab-icon-size" />
  );
  // fluent:shifts-activity-24-filled

  const itemlist = [
    {
      label: (
        <>
          <Tooltip placement="right" title="Assets & Spend">
            <h1> {asset_and_spend} </h1>
          </Tooltip>
        </>
      ),
      key: 2,
      children: <AssetSpend />,
    },
    {
      label: (
        <>
          <Tooltip placement="right" title="Utilization & Availability">
            <h1> {utilizetion} </h1>
          </Tooltip>
        </>
      ),
      key: 3,
      children: <AssetsManaged />,
    },
    {
      label: (
        <>
          <Tooltip placement="right" title="Compliance">
            <h1> {compliance} </h1>
          </Tooltip>
        </>
      ),
      key: 4,
      children: <Compliance />,
    },
    {
      label: (
        <>
          <Tooltip placement="right" title="Planned & Open activity">
            <h1> {planned} </h1>
          </Tooltip>
        </>
      ),
      key: 5,
      children: <PlannedOpenActivity />,
    },
    {
      label: (
        <>
          <Tooltip placement="right" title="Activity Tracker">
            {/* <img src={Activity_Tracker} className='tab-icon-size operation-menu' /> */}
            <h1> {Activity_Tracker} </h1>
          </Tooltip>
        </>
      ),
      key: 1,
      children: <ActivityTracker />,
    },
    {
      label: (
        <>
          <Tooltip placement="right" title="RTO and RPO">
            {/* <img src={Activity_Tracker} className='tab-icon-size operation-menu' /> */}
            <h1> {rtorpo} </h1>
          </Tooltip>
        </>
      ),
      key: 6,
      children: <RtoAndRpo />,
    },
  ];
  return (
    <>
      <Tabs
        defaultActiveKey="1"
        type="card"
        size="small"
        tabPosition="left"
        items={itemlist}
        className="costimize_sidebar"
      />

      {/* <Footer style={footerStyle}>Footer</Footer> */}
      {/* <TabsPanel>
                <Tab className="plat-costimize-dashboard-tab" title="Quick Glance"
                        icon={quickglanceicon}> 
                    <QuickGlance />
                </Tab>
                <Tab className="plat-costimize-dashboard-tab" title="Assets/Spend"
                        icon={assetspend}>
                    <AssetAndSpend />
                </Tab>
                <Tab
                    className="plat-costimize-dashboard-tab"
                    title="Savings Models"
                    icon={savingmodel}>
                        <SavingsModel />
                    
                </Tab>
                <Tab
                    className="plat-costimize-dashboard-tab"
                    title="Budget Planning"
                    icon={budget_planning}>
                        <BudgetPlanning />
                    
                </Tab>
                <Tab 
                    className="plat-costimize-dashboard-tab"
                    icon={instance_runtime} 
                    title="Instance Runtime">
                    <InstanceRuntime  />
                </Tab>
                <Tab 
                    className="plat-costimize-dashboard-tab"
                    icon={dashboardanalysis} 
                    title="Dashboard & Analysis">
                    <DashboardTab />
                </Tab>
            </TabsPanel> */}
    </>
  );
};
export default OperationSection2;
