import { DatePicker } from "antd";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { selectedTopDateMethod } from "../../slice/costimizeSlice";
import "./costimaze-date-picker.css";
dayjs.extend(customParseFormat);
const monthFormat = "YYYY-MM";
function CostimazeDatePicker() {
  const dispatch = useDispatch();
  const handleDateChange = (value) => {
    const current = new Date(value); //passing selected month
    dispatch(
      selectedTopDateMethod(
        `${current.getFullYear()}-${current.getMonth() + 1}-01`
      )
    );
  };
  const [currentMonth, setCurrentMonth] = useState(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const lastMonth = new Date(currentDate);
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    return currentDay <= 3
      ? moment(lastMonth).format("YYYY-MM")
      : moment(currentDate).format("YYYY-MM");
  });
  return (
    <span className="year-picker-cs">
      <DatePicker
        defaultValue={dayjs(currentMonth, monthFormat)}
        // defaultValue={currentMonth.date() <= 3 ? currentMonth.subtract(1, 'month') : currentMonth}
        format={monthFormat}
        picker="month"
        allowClear={false}
        disabledDate={(current) => {
          return (
            moment().add(-1, "days") <= current ||
            moment().add(1, "month") <= current
          );
        }}
        onChange={handleDateChange}
      />
    </span>
  );
}

export default CostimazeDatePicker;
