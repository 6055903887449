import {
    ApolloClient,
    InMemoryCache,
    HttpLink,
    ApolloLink,
    ApolloProvider,
    gql,
    concat,
  } from "@apollo/client";
  
  // for a2c rover apis
  const a2c_httpLink = new HttpLink({
    uri: "https://nbvp6cpbrrdppamtuf6hthqrx4.appsync-api.ap-south-1.amazonaws.com/graphql",
  });
  
  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: localStorage.getItem("_token"),
      },
    }));
  
    return forward(operation);
  });
  
  const client_assest = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware, a2c_httpLink),
  });
  
  export default client_assest;
  