import { memo } from "react";
import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { getAssetandSpendDBgraph } from "../../action/costimizedashboardAction";
const AssetDBgraph = (props) => {
  const [discrete, setDiscrete] = useState([]);
  const dispatch = useDispatch();
  const [chartvalue, setChartValues] = useState({
    spendtrandschartdata: [],
    spendtrandschartmonth: [],
  });
  const {
    selectedTopdate,
    selectedAccount,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  let currentDate = new Date(selectedTopdate);
  let lastThreeMonthsDate = new Date(selectedTopdate);
  lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
  var month_list = [];
  for (let i = 0; i < 3; i++) {
    let date = new Date(
      lastThreeMonthsDate.getFullYear(),
      lastThreeMonthsDate.getMonth() + i,
      1
    );
    month_list.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
  }
  var apivalue = {
    environment: props.titles,
    account_name: selectedAccount.toString(),
    cloud: props.cloud,
    application: props.application,
    services: selectedservices.toString(),
    // os: selectedos.toString(),
    infra: "DB",
    db: selecteddb.toString(),
    company_name: companyName,
    resourcename: props.resname,
    first_month: month_list[0],
    second_month: month_list[1],
    thired_month: month_list[2],  
  };
  const [chartseriesdata, setchartseriesdata] = useState([]);
  useEffect(() => {
    dispatch(
      getAssetandSpendDBgraph({ companyName, selectedTopdate, apivalue })
    )
      .unwrap()
      .then(({ data }) => {
        const records = data.assetspend_dbaas_spend_trend.map((datas) => {
          return {
            application: datas.application,
            first_month_spend: datas.first_month_spend,
            second_month_spend: datas.second_month_spend,
            third_month_spend: datas.third_month_spend,
          };
        });
        // const sums = records.reduce(
        //   (sums, record) => {
        //     sums[0] += record.first_month_spend;
        //     sums[1] += record.second_month_spend;
        //     sums[2] += record.third_month_spend;
        //     return sums;
        //   },
        //   [0, 0, 0]
        // );
        const sums = records.reduce(
          (sums, record) => {
            sums[0] += record.first_month_spend;
            sums[1] += record.second_month_spend;
            sums[2] += record.third_month_spend;
            return sums;
          },
          [0, 0, 0]
        ).map(Math.round);
        
        let sumValue = sums;
        setchartseriesdata([]);
        setchartseriesdata(sumValue);
        // setchartseries(records);
        const chartdata = [];
        const chartmonth = [];
        const monthNames = [
          "Jan",
          "Feb",
          "March",
          "April",
          "May",
          "June",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        if (props.records) {
          props.records.chartlable.map((data) => {
            const current = new Date(data);
            chartmonth.push(
              `${monthNames[current.getMonth()]} ${current.getFullYear()}`
            );
          });
        }
        setChartValues({
          spendtrandschartdata: sums,
          spendtrandschartmonth: chartmonth,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [props]);
  const options = {
    chart: {
      type: "line",
      // height: 160,
      offsetY: 5,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      show: true,
      curve: "straight",
      lineCap: "butt",
      colors: "#FFF",
      width: 1,
      dashArray: 0,
    },
    markers: {
      size: [3, 7],
      strokeColors: "#fff",
      strokeWidth: 0,
      strokeOpacity: 0,
      strokeDashArray: 0,
      fillOpacity: 0,
      discrete: discrete,
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: undefined,
        sizeOffset: 3,
      },
    },
    grid: {
      show: false,
    },
    colors: ["#FF6A2F"],
    xaxis: {
      categories: chartvalue.spendtrandschartmonth,
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      intersect: false,
      shared: true,
      marker: {
        show: false,
      },
      x: {
        show: false,
      },
      y: {
        show: false,
        formatter: (num) => {
          return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
            : Math.sign(num) * Math.abs(num);
        },
      },
    },
    yaxis: {
      show: false,
    },
  };
  const series = [
    {
      name: "Trend",
      data: chartvalue.spendtrandschartdata,
    },
  ];
  return (
    <>
      {chartseriesdata.length ? (
        <div className="asset_table_graph">
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height={70}
            width={110}
          />
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <Icon icon="line-md:loading-loop" style={{ fontSize: "20px" }} />
        </div>
      )}
    </>
  );
};

export default memo(AssetDBgraph);
