import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { useEffect, useState } from "react";

const styles = StyleSheet.create({
  header: {
    width: "100%",
    textAlign: "center",
    marginBottom: 20,
  },
  headersecond: {
    width: "100%",
    textAlign: "center",
    marginBottom: 40,
  },
  footer: {
    position: "absolute",
    fontSize: 10,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    paddingTop: 20,
  },
  customer_logo: {
    width: "145px",
    height: "29px",
  },
  company_logo: {
    width: "145px",
    height: "29px",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "14.3%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColRight: {
    width: "14.3%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "right",
    // marginRight: 60,
  },
  tableCellRight: {
    // margin: "auto",
    marginTop: 5,
    fontSize: 10,
    marginRight: 5,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  danger_light: {
    width: "11px",
    height: "11px",
    backgroundColor: "#bb2124",
    borderRadius: "50px",
  },
  warning_light: {
    width: "11px",
    height: "11px",
    backgroundColor: "#f0ad4e",
    borderRadius: "50px",
    marginBottom: 1,
  },
  success_light: {
    width: "11px",
    height: "11px",
    backgroundColor: "#22bb33",
    borderRadius: "50px",
    marginBottom: 1,
  },
  success_light: {
    width: "11px",
    height: "11px",
    backgroundColor: "#22bb33",
    borderRadius: "50px",
    marginBottom: 1,
  },
  off_light: {
    width: "11px",
    height: "11px",
    backgroundColor: "#bebebe",
    borderRadius: "50px",
    marginBottom: 1,
  },
});

const PdfFooter = () => {
  return (
    <>
      <View style={styles.footer} fixed>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Text style={{ flex: 1, textAlign: "left", marginLeft: 20 }}>
            Date : {new Date().getDate()}/{new Date().getMonth() + 1}/
            {new Date().getFullYear()}
          </Text>
          <Text style={{ flex: 1, textAlign: "right", marginRight: 20 }}>
            © {new Date().getFullYear()} Copyright: 1CloudHub
          </Text>
        </View>
      </View>
    </>
  );
};
const PdfHeader = () => {
  return (
    <>
      <View style={styles.header} fixed>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Text style={{ flex: 1, textAlign: "left" }}>
            <Image
              style={styles.customer_logo}
              src="../../../assets/images/company_logo.png"
            />
          </Text>
          <Text style={{ flex: 1, textAlign: "right", marginRight: -30 }}>
            <Image
              style={styles.company_logo}
              src="../../../assets/images/1ch_logo.jpg"
            />
          </Text>
        </View>
      </View>
    </>
  );
};

const Page2PdfHeader = () => {
  return (
    <>
      <View style={styles.headersecond} fixed>
        <View style={{ display: "flex", flexDirection: "row" }}></View>
      </View>
    </>
  );
};

const QuickGlanceOverallReport = (props) => {
  const [reportdate, setReportDate] = useState({
    date: "",
    month: "",
    year: "",
  });
  const [budgetstatus, setBudgetStatus] = useState(0);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [rem_totla, setRemTotal] = useState({
    ri: 0,
    startstop: 0,
    sss: 0,
    savings_plan: 0,
    osr: 0,
    total: 0,
  });
  const [tablerecords, setTableRecords] = useState({
    ri: [],
    startstop: [],
    sss: [],
    savings_plan: [],
    osr: [],
  });

  useEffect(() => {
    setReportDate({
      date: new Date(props.date).getDate(),
      month: monthNames[new Date(props.date).getMonth()],
      year: new Date(props.date).getFullYear(),
    });
    const budget_different = (props.spend * 100) / props.period_budget;
    setBudgetStatus(Math.round(budget_different));

    if (props.records) {
      const total_value = props.records?.reduce(
        (a, v) => (a = +a + +v?.potential_savings),
        0
      );
      //records
      const stat_stop_filter = props.records?.filter(
        (datas) => datas.recommendation_type.toLowerCase() === "stop/start"
      );
      const spot_start_stop_filter = props.records?.filter(
        (datas) =>
          datas.recommendation_type.toLowerCase() === "spot & start/stop"
      );
      const rilist_filter = props.records?.filter(
        (datas) => datas.recommendation_type.toLowerCase() === "ri"
      );
      const savingsplan_filter = props.records?.filter(
        (datas) => datas.recommendation_type.toLowerCase() === "savings plan"
      );
      const os_recommentation_filter = props.records?.filter(
        (datas) =>
          datas.recommendation_type.toLowerCase() === "os-recommendation"
      );
      //total
      const start_total = stat_stop_filter.reduce(
        (a, v) => (a = +a + +v?.potential_savings),
        0
      );
      const sss_total = spot_start_stop_filter.reduce(
        (a, v) => (a = +a + +v?.potential_savings),
        0
      );
      const ri_total = rilist_filter.reduce(
        (a, v) => (a = +a + +v?.potential_savings),
        0
      );
      const savingsplan_total = savingsplan_filter.reduce(
        (a, v) => (a = +a + +v?.potential_savings),
        0
      );
      const osr_total = os_recommentation_filter.reduce(
        (a, v) => (a = +a + +v?.potential_savings),
        0
      );
      //state
      setRemTotal({
        ri: ri_total,
        startstop: start_total,
        sss: sss_total,
        savings_plan: savingsplan_total,
        osr: osr_total,
        total: total_value,
      });
      setTableRecords({
        ri: rilist_filter,
        startstop: stat_stop_filter,
        sss: spot_start_stop_filter,
        savings_plan: savingsplan_filter,
        osr: os_recommentation_filter,
      });
    }
  }, [props]);
  return (
    <>
      <Document>
        <Page pageNumber={1} style={{ padding: 20 }}>
          <PdfHeader />
          <View>
            <Text style={{ textAlign: "center", top: 300 }}>
              Report for the month of {reportdate.month}, {reportdate.year}
            </Text>
          </View>
          <PdfFooter />
        </Page>
        <Page
          pageNumber={2}
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 50,
            paddingTop: 20,
          }}
        >
          <Page2PdfHeader />
          <View style={{ marginTop: 10 }}>
            <Text style={{ fontSize: "15px" }}>Summary :</Text>
          </View>
          <View>
            <View style={{ marginTop: 10, fontSize: "11px" }}>
              <View>
                {budgetstatus <= 100 ? (
                  <Text style={styles.success_light}></Text>
                ) : (
                  <Text style={styles.off_light}></Text>
                )}
                {budgetstatus > 100 && budgetstatus <= 110 ? (
                  <View style={{ marginTop: -12, marginLeft: 14 }}>
                    <Text style={styles.warning_light}></Text>
                  </View>
                ) : (
                  <View style={{ marginTop: -12, marginLeft: 14 }}>
                    <Text style={styles.off_light}></Text>{" "}
                  </View>
                )}
                {budgetstatus > 110 ? (
                  <View style={{ marginTop: -12, marginLeft: 28 }}>
                    <Text style={styles.danger_light}></Text>
                  </View>
                ) : (
                  <View style={{ marginTop: -12, marginLeft: 28 }}>
                    <Text style={styles.off_light}></Text>
                  </View>
                )}
              </View>
              <View>
                <Text
                  style={{
                    lineHeight: 1.5,
                    marginTop: -13,
                    marginLeft: 48,
                    fontSize: "13px",
                  }}
                >
                  Budget vs Spend :
                </Text>
                <Text style={{ marginLeft: 40, lineHeight: 1.5 }}>
                  We observe the estimated budget for the month of{" "}
                  {reportdate.month} is USD{" "}
                  {Number(props.period_budget).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}{" "}
                  , as per the current spend we see there is a{" "}
                  {props.spend > props.period_budget
                    ? `escalation`
                    : `reduction`}{" "}
                  of cost by USD{" "}
                  {Number(props.spend).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}
                  . We see
                  {Number(props.monthcredit) > 0
                    ? ` USD ${Number(props.monthcredit).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2 }
                      )} credit applied. After considering credit the budget the current spend is  which is  budgeted amount.`
                    : `no credit applied`}
                </Text>
              </View>
            </View>

            <View style={{ marginTop: 10, fontSize: "11px" }}>
              <View>
                {Number(props.potential_savings) != 0 ? (
                  <Text style={styles.success_light}></Text>
                ) : (
                  <View style={{ marginTop: -12, marginLeft: 14 }}>
                    <Text style={styles.off_light}></Text>
                  </View>
                )}
                {Number(props.potential_savings) == 0 ? (
                  <Text style={styles.danger_light}></Text>
                ) : (
                  <View style={{ marginTop: -12, marginLeft: 14 }}>
                    <Text style={styles.off_light}></Text>
                  </View>
                )}
              </View>
              <View>
                <Text
                  style={{
                    lineHeight: 1.5,
                    marginTop: -12,
                    marginLeft: 28,
                    fontSize: "13px",
                  }}
                >
                  Cost Saving Opportunity :
                </Text>
                <Text style={{ marginLeft: 40, lineHeight: 1.5 }}>
                  We have reviewed the landscape and for the month of{" "}
                  {reportdate.month} under the below category we see a potential
                  savings of upto USD{" "}
                  {rem_totla.total.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}
                  .
                </Text>
                <Text
                  style={{
                    marginLeft: 40,
                    lineHeight: 1.5,
                    marginTop: 10,
                    fontSize: "13px",
                  }}
                >
                  Aspects considered for cost savings.
                </Text>
                <Text style={{ marginLeft: 60, lineHeight: 1.5 }}>
                  1. On demand & Stop/Start{" "}
                </Text>
                <Text style={{ marginLeft: 60, lineHeight: 1.5 }}>
                  2. Spot & Start/stop{" "}
                </Text>
                <Text style={{ marginLeft: 60, lineHeight: 1.5 }}>
                  3. Conversion to Reserved Instance (RI) for EC2 and RDS
                </Text>
                <Text style={{ marginLeft: 60, lineHeight: 1.5 }}>
                  4. Savings plan{" "}
                </Text>
                <Text style={{ marginLeft: 60, lineHeight: 1.5 }}>
                  5. OS-Recommendation{" "}
                </Text>

                <Text
                  style={{
                    marginLeft: 0,
                    lineHeight: 1.5,
                    marginTop: 10,
                    fontSize: "13px",
                  }}
                >
                  1. On demand & Start/stop:
                </Text>
                {rem_totla.startstop > 0 ? (
                  <>
                    <Text
                      style={{ marginLeft: 40, lineHeight: 1.5, marginTop: 5 }}
                    >
                      We have analysed your non-production landscape under below
                      conditions and recommend automating the start/stop or
                      convert instance to on-demand and automate start/stop. We
                      see a potential savings opportunity of USD{" "}
                      {rem_totla.startstop.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}{" "}
                    </Text>
                    <Text
                      style={{ marginLeft: 80, lineHeight: 1.5, marginTop: 5 }}
                    >
                      {" "}
                      Instance running more 10 hours per day.{" "}
                    </Text>
                    <Text style={{ marginLeft: 80, lineHeight: 1.5 }}>
                      {" "}
                      Instance running in RI even through it is a non-production
                      landscape.{" "}
                    </Text>

                    <View style={styles.table}>
                      <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Account</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Application</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Environment</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Service</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            Current Configuration
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            Recommended Configuration
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            Potential Savings
                          </Text>
                        </View>
                      </View>
                      {tablerecords.startstop.map((data) => {
                        return (
                          <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.account}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.application}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.environment}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.services}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.current_configuration}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.recommended_configuration}
                              </Text>
                            </View>
                            <View style={styles.tableColRight}>
                              <Text style={styles.tableCellRight}>
                                $
                                {data.potential_savings.toLocaleString(
                                  undefined,
                                  { maximumFractionDigits: 2 }
                                )}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </>
                ) : (
                  <Text
                    style={{ marginLeft: 40, lineHeight: 1.5, marginTop: 5 }}
                  >
                    We don’t see any recommendation with respect to savings plan
                    conversion.
                  </Text>
                )}

                <Text
                  style={{
                    marginLeft: 0,
                    lineHeight: 1.5,
                    marginTop: 10,
                    fontSize: "13px",
                  }}
                >
                  2. Spot & Start/stop :
                </Text>
                {rem_totla.sss > 0 ? (
                  <>
                    <Text
                      style={{ marginLeft: 40, lineHeight: 1.5, marginTop: 5 }}
                    >
                      We have analysed your non-production landscape under below
                      conditions and recommend automating the start/stop or
                      convert instance to on-demand and automate start/stop. We
                      see a potential savings opportunity of USD{" "}
                      {rem_totla.sss.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}{" "}
                    </Text>
                    <Text
                      style={{ marginLeft: 80, lineHeight: 1.5, marginTop: 5 }}
                    >
                      {" "}
                      Instance running more 10 hours per day.{" "}
                    </Text>
                    <Text style={{ marginLeft: 80, lineHeight: 1.5 }}>
                      {" "}
                      Instance running in RI even through it is a non-production
                      landscape.{" "}
                    </Text>

                    <View style={styles.table}>
                      <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Account</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Application</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Environment</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Service</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            Current Configuration
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            Recommended Configuration
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            Potential Savings
                          </Text>
                        </View>
                      </View>
                      {tablerecords.sss.map((data) => {
                        return (
                          <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.account}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.application}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.environment}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.services}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.current_configuration}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.recommended_configuration}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                $
                                {data.potential_savings.toLocaleString(
                                  undefined,
                                  { maximumFractionDigits: 2 }
                                )}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </>
                ) : (
                  <Text
                    style={{ marginLeft: 40, lineHeight: 1.5, marginTop: 5 }}
                  >
                    We don’t see any recommendation with respect to savings plan
                    conversion.
                  </Text>
                )}

                <Text
                  style={{
                    marginLeft: 0,
                    lineHeight: 1.5,
                    marginTop: 10,
                    fontSize: "13px",
                  }}
                >
                  3. Conversion to Reserved Instance (RI) for EC2 and RDS:
                </Text>
                {rem_totla.ri > 0 ? (
                  <>
                    <Text
                      style={{ marginLeft: 40, lineHeight: 1.5, marginTop: 5 }}
                    >
                      We have analysed your non-production landscape under below
                      conditions and recommend automating the start/stop or
                      convert instance to on-demand and automate start/stop. We
                      see a potential savings opportunity of USD{" "}
                      {rem_totla.ri.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}{" "}
                    </Text>
                    <Text
                      style={{ marginLeft: 80, lineHeight: 1.5, marginTop: 5 }}
                    >
                      {" "}
                      Instance running more 10 hours per day.{" "}
                    </Text>
                    <Text style={{ marginLeft: 80, lineHeight: 1.5 }}>
                      {" "}
                      Instance running in RI even through it is a non-production
                      landscape.{" "}
                    </Text>

                    <View style={styles.table}>
                      <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Account</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Application</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Environment</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Service</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            Current Configuration
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            Recommended Configuration
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            Potential Savings
                          </Text>
                        </View>
                      </View>
                      {tablerecords.ri.map((data) => {
                        return (
                          <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.account}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.application}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.environment}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.services}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.current_configuration}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.recommended_configuration}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                $
                                {data.potential_savings.toLocaleString(
                                  undefined,
                                  { maximumFractionDigits: 2 }
                                )}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </>
                ) : (
                  <Text
                    style={{ marginLeft: 40, lineHeight: 1.5, marginTop: 5 }}
                  >
                    We don’t see any recommendation with respect to savings plan
                    conversion.
                  </Text>
                )}

                <Text
                  style={{
                    marginLeft: 0,
                    lineHeight: 1.5,
                    marginTop: 10,
                    fontSize: "13px",
                  }}
                >
                  4. Savings plan :
                </Text>
                {rem_totla.savings_plan > 0 ? (
                  <>
                    {/* <Text style={{marginLeft: 60, lineHeight:1.5, marginTop: 5}}>We have analysed your non-production landscape under below conditions and recommend automating the start/stop or convert instance to on-demand and automate start/stop. We see a potential savings opportunity of USD {rem_totla.savings_plan.toLocaleString(undefined, {maximumFractionDigits:2})} </Text>
                                            <Text style={{marginLeft: 80, lineHeight:1.5, marginTop: 5}}> Instance running more 10 hours per day. </Text>
                                            <Text style={{marginLeft: 80, lineHeight:1.5}}> Instance running in RI even through it is a non-production landscape. </Text> */}

                    <View style={styles.table}>
                      <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Account</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Application</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Environment</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Service</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            Current Configuration
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            Recommended Configuration
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            Potential Savings
                          </Text>
                        </View>
                      </View>
                      {tablerecords.savings_plan.map((data) => {
                        return (
                          <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.account}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.application}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.environment}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.services}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.current_configuration}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.recommended_configuration}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                $
                                {data.potential_savings.toLocaleString(
                                  undefined,
                                  { maximumFractionDigits: 2 }
                                )}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </>
                ) : (
                  <Text
                    style={{ marginLeft: 40, lineHeight: 1.5, marginTop: 5 }}
                  >
                    We don’t see any recommendation with respect to savings plan
                    conversion.
                  </Text>
                )}

                <Text
                  style={{
                    marginLeft: 0,
                    lineHeight: 1.5,
                    marginTop: 10,
                    fontSize: "13px",
                  }}
                >
                  5. OS-Recommendation # :
                </Text>
                {rem_totla.ri > 0 ? (
                  <>
                    <Text
                      style={{ marginLeft: 40, lineHeight: 1.5, marginTop: 5 }}
                    >
                      We observe some of the applications are running in Windows
                      OS, refactoring the OS from windows to Linux will help in
                      cost savings.{" "}
                    </Text>

                    <View style={styles.table}>
                      <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Account</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Application</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Environment</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Service</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            Current Configuration
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            Recommended Configuration
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            Potential Savings
                          </Text>
                        </View>
                      </View>
                      {tablerecords.osr.map((data) => {
                        return (
                          <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.account}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.application}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.environment}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.services}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.current_configuration}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                {data.recommended_configuration}
                              </Text>
                            </View>
                            <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                $
                                {data.potential_savings.toLocaleString(
                                  undefined,
                                  { maximumFractionDigits: 2 }
                                )}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                    <Text
                      style={{
                        marginTop: 30,
                        lineHeight: 1.5,
                        textAlign: "center",
                      }}
                    >
                      # This recommendation doesn’t consider the application
                      compatibility, please contact concerned application team
                      for compatibility.
                    </Text>
                  </>
                ) : (
                  <Text
                    style={{ marginLeft: 40, lineHeight: 1.5, marginTop: 5 }}
                  >
                    We don’t see any recommendation with respect to savings plan
                    conversion.
                  </Text>
                )}
              </View>
            </View>
          </View>

          <PdfFooter />
        </Page>
      </Document>
    </>
  );
};

export default QuickGlanceOverallReport;
