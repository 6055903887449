import { useEffect, useState } from "react";
import "./platoperation.css";

const PlatOperation = () => {
    const [embedurl, setEmbedurl] = useState();
    useEffect(() => {
        fetch("https://9dz1ylkqu3.execute-api.us-east-1.amazonaws.com/Prod/",{
            method: "POST",
            body: JSON.stringify({
                "event_type" : "get_url"
            }),
        })
        .then(res => res.json())
        .then(
            (result) => {
                setEmbedurl(result[0].EmbedUrl);
            },
            (error) => {
                console.log(error);
            }
        )
    },[]);
    return(
        <>
            <iframe 
                src={embedurl} 
                className="operation-main-iframe"
            ></iframe>
        </>
    )
}
export default PlatOperation;