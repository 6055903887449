import { Icon } from "@iconify/react";
import { Card, Spin, Tooltip } from "antd";

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getForecastApiData } from "../../../action/costimizedashboardAction";
import CardGraph from "./CardGraph";

function SpendForecast({ handleForecastModal }) {
  const [forecastDataState, setForecastDataState] = useState("");
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  var company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);

  useEffect(() => {
    setLoading(true);
    const apiVariablesForForecast = {
      event_type: "forecast trend",
      screen: "qg",
      account_name: selectedAccount.toString(),
      company: company_name,
      cloud: selectedCloud.toString(),
      month: selectedTopdate,
    };
    dispatch(getForecastApiData(apiVariablesForForecast))
      .unwrap()
      .then(({ data }) => {
        const jsonString = data?.forecasttrend;
        // Parse the JSON-encoded string to a JavaScript object
        let resp = JSON.parse(jsonString);
        // Custom comparator function to sort by report_month
        const sortByDate = (a, b) => {
          const dateA = new Date(a.report_month);
          const dateB = new Date(b.report_month);
          return dateA - dateB;
        };

        // Sort the array
        const sortedData = resp.sort(sortByDate);
        setForecastDataState({
          foreCastTrend: sortedData,
          currentMonth: resp[resp.length - 1],
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        console.log(err?.message);
      });
  }, [selectedTopdate, selectedAccount, selectedCloud]);

  const currencyFormatter = (val) => {
    if (isNaN(Number(val))) {
      return 0;
    } else {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD", // Change to your desired currency code
        minimumFractionDigits: 0, // Minimum number of fraction digits
        maximumFractionDigits: 2, // Maximum number of fraction digits
      }).format(Number(val));
    }
  };
  return (
    <div>
      {/* <pre className="text-white">
        {JSON.stringify(forecastDataState, null, 3)}
      </pre> */}

      <Spin spinning={loading} wrapperClassName="costimize-loader">
        <Card className="plat-card-1 plat-card-p-0 p-0 m-0 mnH overflowHidden">
          <h1 className={` spendCardTitle  ptl-3`}>
            Spend forecast for{" "}
            {moment(forecastDataState?.currentMonth?.report_month).format(
              "MMM"
            )}{" "}
            <Tooltip
              overlayClassName="custom-tooltip"
              placement="top"
              title="Forecast doesn’t include tax"
            >
              {" "}
              <Icon
                icon="iconamoon:information-circle-fill"
                style={{
                  // marginTop: "7px",
                  fontSize: "14px",
                  color: "#848484",
                  cursor: "pointer",
                }}
                onClick={handleForecastModal}
              />
            </Tooltip>
          </h1>
          <div className="usmon">
            {/* <BiSolidUpArrowAlt clolor="red" /> */}
            <p className="t1">
              {isNaN(forecastDataState?.currentMonth?.forecast)
                ? "$0"
                : currencyFormatter(forecastDataState?.currentMonth?.forecast) +
                  " "}

              {/* <BiSolidDownArrowAlt color="green" size={12} /> */}
            </p>
          </div>
          <div className="crdGrpBt">
            <CardGraph chartData={forecastDataState?.foreCastTrend} />
          </div>
        </Card>
      </Spin>
    </div>
  );
}

export default SpendForecast;
