import React, { useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.scss";
import AccountOnboarding from "./components/AccountOnboarding";
import AccountOffering from "./components/Accountoffering";
import AdminActions from "./components/AdminActions";
import AdminDashboard from "./components/AdminDashboard";
import AllAWS from "./components/All-aws";
import AllAZure from "./components/All-azure";
import BasicCard from "./components/Card";
import Chart from "./components/Chart";
import Consolidated from "./components/Consolidated";
import ForgotPassword from "./components/ForgotPassword";
import RoleMng from "./components/RoleMng";
import SignedUpCustomer from "./components/SignedUpCustomer";
import SignedUpCustomerGroup from "./components/SignedUpCustomerGroup";
import UserManagement from "./components/UserMng";
import War from "./components/War";
import AdminPanel from "./components/admin/Index";
import CustomerForm from "./components/admin/useronboard/CustomerForm";
import CostimizeTab from "./components/costimize/costimizeTab";
import Nopsdemo from "./components/WAR/nopsdemo";

// import CostimizeAssestsTab from './components/costimize/costimizeAssetstab';

import "@fontsource/poppins";
import Dashboardv2 from "./components/dashboard-v2/Dashboardv2";
import Header from "./components/header/Header";
// import Header from './components/Header';
// import Signgroup from "./components/signup/Signgroup";     commented for old version

import Operation from "./components/operation/Operation";
import PlatOperation from "./components/operation/PlatOperation";

import { useSelector } from "react-redux";
import AssestsPlandandDesign from "./components/Assets/AssestsPlandandDesign";
import MyRoleAccount from "./components/admin/role/MyRoleAccount";
import RoleManagement from "./components/admin/role/RoleManagement";
import Assets from "./components/assets_a2c/Assets";
import AzureAuth from "./components/auth/AzureAuth";
import CAMS from "./components/cams";
import SavingTracker from "./components/costdemaze/SavingTracker";
import BudgetPlanning from "./components/costimize_v2/BudgetPlanning/budgetPlanning";
import ChatBot from "./components/costimize_v2/ChartBot/ChatBot";
import InstanceOverview from "./components/costimize_v2/InstanceOverView/instanceOverview";
import AssetSpendLayout from "./components/costimize_v2/assetandspend/AssetSpendLayout";
import QuickGlanceLayout from "./components/costimize_v2/quick_glance/QuickGlanceLayout";
import SavingsModelLayout from "./components/costimize_v2/savings_model/SavingsModelLayout";
import Report from "./components/costimize_v2/WeeklyReport/Report";
import DevOpsMain from "./components/devops/DevOpsMain";
import ConfigurationForm from "./components/devops/configurationForm/ConfigurationForm";
import ApprovalPreviewForm from "./components/devops/configurationForm/previewForm/ApprovalPreviewForm";
import ViewCRForm from "./components/devops/configurationForm/previewForm/ViewCRForm";
import LandingHomePage from "./components/landing_home_page/LandingPage";
import Migrate from "./components/migrate/Migrate";
import Operateindex from "./components/operate/Operateindex";
import PeemsIndex from "./components/peems/PeemsIndex";
import AppPDF from "./components/properties/PDF/AppPDF";
import Signgroup from "./components/signup/Signup_new";
import Slm from "./components/slm_a2c/Slm";
import SLM_Quickglance from "./components/slm_v2/SLM_Quickglance";
import SLM_ActivityTracker from "./components/slm_v2/slm_subcomponents/ActivityTracker/ActivityTracker";
import SLM_AssetManaged from "./components/slm_v2/slm_subcomponents/AssetManaged";
import SLM_Main from "./components/slm_v2/slm_subcomponents/AssetsSpend";
import SLM_Compliance from "./components/slm_v2/slm_subcomponents/Compliance";
import SLM_ESCALATIONMATRIX from "./components/slm_v2/slm_subcomponents/EscalationMatrix/EscalationMatrix";
import SLM_POActivity from "./components/slm_v2/slm_subcomponents/PO_Activity/PO_Activity/POActivity";
import SppInvoiceDocument from "./components/spp/Sppviewpdf";
import Spp from "./components/spp/spp";
import Terms from "./components/terms/Terms";
import Dashboardwar from "./components/WAR/Dasboardwar";
import Realiability from "./components/WAR/Realiability";
import CostAnalysisIndex from "./components/cost_analysis/CostAnalysisIndex";
import AssetsNew from "./components/assets_a2c/AssetsNew";
import ReportInsights from "./components/costimize_v2/WeeklyReport/ReportInsights";
import LandingZone from "./components/devops/LandingZone";
import LandingZoneADD from "./components/devops/LandingZoneAdd";
import LandingZoneForm from "./components/devops/LandingZoneForm";
import LandingZoneFormPage from "./components/devops/LandingZoneFormPage";
import DetailedReport from "./components/costimize_v2/WeeklyReport/DetailedReport";

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    if (!location.hash) {
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }
  }, [location]);

  return <>{props.children}</>;
};

const router = [
  // {
  //   path: "/signin_old",
  //   element: <Signin />,
  // },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "signedup-customer",
    element: <SignedUpCustomer />,
  },
  {
    path: "signedup-customer-group",
    element: <SignedUpCustomerGroup />,
  },
  // {
  //   path: "register-new-customer",
  //   element: <StepperData />,
  // },
  {
    path: "register-new-customer",
    element: <CustomerForm />,
  },
  {
    path: "role-management",
    element: <RoleManagement />,
  },
  {
    path: "account-onboarding",
    element: <AccountOnboarding />,
  },
  {
    path: "chart",
    element: <Chart />,
  },
  {
    path: "war",
    element: <War />,
  },
  {
    path: "admin",
    element: <AdminPanel />,
  },
  {
    path: "card",
    element: <BasicCard />,
  },
  {
    path: "consolidated-view",
    element: <Consolidated />,
  },
  {
    path: "admin-dashboard",
    element: <AdminDashboard />,
  },
  {
    path: "admin-actions",
    element: <AdminActions />,
  },
  {
    path: "user-mng",
    element: <UserManagement />,
  },
  {
    path: "role-mng",
    element: <RoleMng />,
  },
  {
    path: "account-offering",
    element: <AccountOffering />,
  },
  {
    path: "admin-user-mng",
    element: <UserManagement />,
  },
  {
    path: "all-aws",
    element: <AllAWS />,
  },
  {
    path: "all-azure",
    element: <AllAZure />,
  },
  {
    path: "all-azure",
    element: <Consolidated />,
  },
  {
    path: "forgotPassword",
    element: <ForgotPassword />,
  },
  {
    path: "costimizeAssests",
    element: <CostimizeTab />,
  },
  {
    path: "costimize_dashboard",
    element: <Dashboardv2 />,
  },
  {
    path: "old_operation",
    element: <PlatOperation />,
  },
  {
    path: "operation",
    element: <Operation />,
  },
  {
    path: "costdemaze/quick_glance",
    element: <QuickGlanceLayout />,
  },
  {
    path: "cams",
    element: <CAMS />,
  },
  {
    path: "/costdemaze/savings_model",
    element: <SavingsModelLayout />,
  },
  {
    path: "/costdemaze/assent_spend",
    element: <AssetSpendLayout />,
  },
  {
    path: "/costdemaze/instance_overview",
    element: <InstanceOverview />,
  },
  {
    path: "/costdemaze/budget_planning",
    element: <BudgetPlanning />,
  },
  // {
  //   path: "/costdemaze/report",
  //   // element: <Report />,
  //   element: <ReportInsights />,
  // },
  {
    path: "/costdemaze/report",
    element: <Report />,
    // element: <ReportInsights />,
  },
  {
    path: "/detailedreport",
    element: <DetailedReport />,
  },
  {
    path: "/slm/dashboard",
    element: <SLM_Quickglance />,
  },
  {
    path: "/slm/assets-spend",
    element: <SLM_Main />,
  },
  {
    path: "/slm/asset-managed",
    element: <SLM_AssetManaged />,
  },
  {
    path: "/slm/compliance",
    element: <SLM_Compliance />,
  },
  {
    path: "/slm/planned-opened-activity",
    element: <SLM_POActivity />,
  },
  {
    path: "/slm/activity-tracker",
    element: <SLM_ActivityTracker />,
  },
  {
    path: "/slm/escalationmatrix",
    element: <SLM_ESCALATIONMATRIX />,
  },
  {
    path: "billing",
    element: <Spp />,
  },
  {
    path: "/billing/invoice/:id",
    element: <SppInvoiceDocument />,
  },
  {
    path: "/peems",
    element: <PeemsIndex />,
  },
  {
    path: "/role",
    element: <MyRoleAccount />,
  },
  {
    path: "/devops-requestor",
    element: <DevOpsMain />,
  },
  {
    path: "/devops-config",
    element: <ConfigurationForm />,
  },
  {
    path: "/azure-auth",
    element: <AzureAuth />,
  },
  {
    path: "/azure-auth/logout",
    element: <AzureAuth />,
  },
  {
    path: "/devops-preview/cr/:id",
    element: <ApprovalPreviewForm />,
  },
  {
    path: "/devops-preview",
    element: <ViewCRForm />,
  },
  {
    path: "/migrate",
    element: <Migrate />,
  },
  {
    path: "/operate",
    element: <Operateindex />,
  },
  {
    path: "/costdemaze",
    element: <SavingTracker />,
  },
  {
    path: "/assests",
    element: <AssestsPlandandDesign />,
  },
  {
    path: "/a2c-rover",
    element: <Assets />,
  },
  {
    path: "/assess",
    element: <Assets />,
  },
  {
    path: "/slm",
    element: <Slm />,
  },
  {
    path: "/wardashboard",
    element: "",
    // element: <Dashboardwar />,
  },
  {
    path: "/realability",
    element: <Realiability />,
  },
  {
    path: "/nopsdemo",
    element: <Nopsdemo />,
  },
  {
    path: "/cost-analysis",
    element: <CostAnalysisIndex />,
  },
  {
    path: "/assetsnew",
    element: <AssetsNew />,
  },
  {
    path: "/landingzone",
    element: <LandingZone />,
  },
  {
    path: "/landingzoneadd",
    element: <LandingZoneADD />,
  },
  {
    path: "/landingzoneform",
    element: <LandingZoneForm />,
  },
  {
    path: "/landingzoneformpage",
    element: <LandingZoneFormPage />,
  },

  // {
  //   path: "/deatiledReport",
  //   element: <DetailedReport />,
  // },
  // {
  //   path: "/warnew",
  //   element: <Performance/>,
  // },
];

function App() {
  useEffect(() => {
    let pathname = window.location.pathname;
    // console.log(window.location.pathname);
    let urlname = pathname.split("/");
    if (urlname[1] === "devops-preview") {
      localStorage.setItem("devops-review", pathname);
    }
  }, []);
  const { chatbotstatus } = useSelector((state) => state.user);
  // useEffect(() => {
  //   console.log("chatbot action");
  // }, [chatbotstatus]);
  // console.log(chatbotstatus);
  const records = {
    user_id: localStorage.getItem("CognitoIdentityServiceProvider.auth"),
    _token: localStorage.getItem("_token"),
  };

  // document.documentElement.style.setProperty("--main-color", "white");
  return (
    <div className="body-width">
      <BrowserRouter>
        <Header />
        <div>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<LandingHomePage />} />
            {records.user_id && records._token ? (
              <>
                <Route
                  path="/signup/:code"
                  element={<Signgroup page_type="signup" />}
                />
                <Route
                  path="/signup"
                  element={<Signgroup page_type="signup" />}
                />
                <Route
                  path="/signin"
                  element={<Navigate to="/role" replace />}
                />
              </>
            ) : (
              <>
                <Route
                  path="/signup/:code"
                  element={<Signgroup page_type="signup" />}
                />
                <Route
                  path="/signup"
                  element={<Signgroup page_type="signup" />}
                />
                <Route
                  path="/signin"
                  element={<Signgroup page_type="signin" />}
                />
              </>
            )}

            {/* <Route path="/signup" element={<ProtectedRoute>
                      <Signgroup page_type='signup' />
                    </ProtectedRoute>} />
            <Route path="/signin" element={<ProtectedRoute>
                      <Signgroup page_type='signin' />
                    </ProtectedRoute>} /> */}
            {router.map((val) => (
              <Route
                path={val.path}
                key={val.path}
                element={val.element}
                // element={<ProtectedRoute>{val.element}</ProtectedRoute>}
              />
            ))}

            <Route path="/pdfview" element={<AppPDF />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
          {/* {userInfo.first_name &&  (
              router.map(val =>
                <Route
                  path={val.path}
                  key={val.path}
                  element={val.element}
                />
              )
            )} */}
        </div>
        {/* {chatbotstatus && <ChatBot />} */}
        {/* <Footer /> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
