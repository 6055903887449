import { Col, Row, Tooltip } from "antd";
import React, { useState } from "react";

import "./spend-trend.css";

import { GoInfo } from "react-icons/go";

import DayWiseSpend from "./DayWiseSpend";
import LastFourWeeksSpendTrend from "./LastFourWeeksSpendTrend";
import LastSixMonthSpends from "./LastSixMonthSpends";

function MonthWeekWiseSpendTrendCard() {
  const [selectedValue, setSelectedValue] = useState("1");

  const handleSelectChange = (value) => {
    setSelectedValue(value);
    // Do something with the selected value if needed
  };

  return (
    <div className="spendTrendContainerCard">
      <Row>
        <Col lg={24}>
          <div className={`  tabsH`}>
            <button
              onClick={() => handleSelectChange("1")}
              className={selectedValue === "1" && "active"}
            >
              Monthly Spend
              <Tooltip
                title="Showing last 6 months spend"
                color="#3b3b3b"
                overlayClassName="custom-tooltip"
              >
                {" "}
                <GoInfo
                  size={12}
                  color={selectedValue === "1" ? "" : "#ff000000"}
                  className={`i-align`}
                />
              </Tooltip>
            </button>
            <button
              onClick={() => handleSelectChange("2")}
              className={selectedValue === "2" && "active"}
            >
              Weekly Spend
              <Tooltip
                title="Showing last 4 weeks spend"
                color="#3b3b3b"
                overlayClassName="custom-tooltip"
              >
                {" "}
                <GoInfo
                  color={selectedValue === "2" ? "" : "#ff000000"}
                  size={12}
                  className="i-align"
                />
              </Tooltip>
            </button>
            <button
              onClick={() => handleSelectChange("3")}
              className={selectedValue === "3" && "active"}
            >
              Daily Spend{" "}
              <Tooltip
                title="Showing last 30days spends"
                color="#3b3b3b"
                overlayClassName="custom-tooltip"
              >
                {" "}
                <GoInfo
                  color={selectedValue === "3" ? "" : "#ff000000"}
                  size={12}
                  className="i-align"
                />
              </Tooltip>
            </button>
          </div>
        </Col>
      </Row>

      {selectedValue === "1" && <LastSixMonthSpends />}
      {selectedValue === "2" && <LastFourWeeksSpendTrend />}
      {selectedValue === "3" && <DayWiseSpend />}
    </div>
  );
}

export default MonthWeekWiseSpendTrendCard;
