import React, { memo, useEffect, useState } from "react";
import { Dropdown, Space } from "antd";
import { Icon } from "@iconify/react";
import { theme, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  environmentListMethod,
  cloudListMethod,
  accountListMethod,
  applicationListMethod,
  selectedEnvironmentMethod,
  selectedTopDateMethod,
  selectedAccountMethod,
  selectedCloudMethod,
  selectedApplicationMethod,
  selectedServicesMethod,
  selectedOSMethod,
  selectedSlaMethod,
  selectedOUMethod,
  selectedTicketClosedMethod,
  selectedTicketOwnerMethod,
  selectedCategoryMethod,
  selectedInfraMethod,
  selectedDbMethod,
  setServiceList,
  setInfraRList,
  setOsList,
  setDbList,
  setFilterButtonStatus,
  selectedDCMethod,
} from "../slice/a2c_roverSlice";
import FilterDropdownDate from "../costimize_v2/layout/FilterDropdownDate";
import { dropDownResult } from "../custom_hook/CustomHook";
import FilterDropdownList from "../costimize_v2/layout/FilterDropdownList";
import { getTopMenuList } from "../action/costimizeV2Active";
import {
  getAssessReducer,
  getslmfilter,
  getOperateReducer,
} from "../action/graphql/a2crover/A2CReducer";
const { useToken } = theme;

function A2cFilter() {
  const { token } = useToken();
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const current = new Date();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const {
    applicationList,
    environmentList,
    accountList,
    cloudList,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    serviceList,
    infraList,
    osList,
    ouList,
    ticketownerList,
    ticketclosedList,
    categoryList,
    slaList,
    dbList,
    selectedservices,
    selectedos,
    selectedou,
    selectedticketclosed,
    selectedticketowner,
    selectedcategory,
    selectedsla,
    selectedinfra,
    selecteddb,
    selectedTopdate,
    filterbuttonstatus,
    selecteddc,
  } = useSelector((state) => state.a2cRover);
  //state
  // const [selectedstaticdate, setSelectedStaticDate] = useState(
  //   `${current.getFullYear()}-${current.getMonth() + 1}-01`
  // );
  // const [selectedstaticdate, setSelectedStaticDate] = useState(selectedTopdate);
  const [recordslist, setRecordsList] = useState([]);
  const [environmentlist, setEnvironmentList] = useState([]);
  const [applicationlist, setApplicationList] = useState([]);
  const [accountlist, setAccountList] = useState([]);
  const [cloudlist, setCloudList] = useState([]);
  const [serviceslist, setServicesList] = useState([]);
  const [oslist, setOSList] = useState([]);
  const [slalist, setSlaList] = useState([]);
  const [oulist, setOUList] = useState([]);
  const [ticketownerlist, setTicketownerList] = useState([]);
  const [ticketclosedlist, setTicketclosedList] = useState([]);
  const [categorylist, setCategorylist] = useState([]);
  const [infralist, setInfraList] = useState([]);
  const [dblist, setdbList] = useState([]);
  const [selectapplication, setSelectApplication] =
    useState(selectedApplication);
  const [selectenvironment, setSelectEnvironment] =
    useState(selectedEnvironment);

  const [selectaccount, setSelectAccount] = useState(selectedAccount);
  const [selectcloud, setSelectCloud] = useState(selectedCloud);
  const [selectedservicessta, setSelectedServices] = useState(selectedservices);
  const [selectedossta, setSelectedOS] = useState(selectedos);
  const [selectedousta, setSelectedOU] = useState(selectedou);
  const [selectedticketownersta, setSelectedTicketowner] =
    useState(selectedticketowner);
  const [selectedticketclosedsta, setSelectedTicketclosed] =
    useState(selectedticketclosed);
  const [selectedcategorysta, setSelectedCategory] = useState(selectedcategory);
  const [selectedslasta, setSelectedSla] = useState(selectedsla);
  const [selectedinfrasta, setSelectedInfra] = useState(selectedinfra);
  const [selecteddbsta, setSelectedDB] = useState(selecteddb);
  const [selecteddcsta, setSelectedDC] = useState(selecteddc);
  const [datachanges, setDataChanges] = useState(true);
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const menuStyle = {
    boxShadow: "none",
  };
  useEffect(() => {
    const companyName = localStorage.getItem(
      "CognitoIdentityServiceProvider.company"
    );
    // console.log(selectedTopdate);
    // console.log({ companyName, selectedTopdate });
    const cloud_list = selectedCloud.toString();
    dispatch(getTopMenuList({ companyName, selectedTopdate, cloud_list }))
      .unwrap()
      .then(({ data }) => {
        setRecordsList(data.newfunctionquery1);
        if (data.newfunctionquery1.length === 0) {
          // messageApi.info("Records not get ready yet.");
        }
        //environment
        // const environmentConfigs = data.newfunctionquery1.map(
        //   (v) => v.environment
        // );
        // const environmentarr = new Set([...environmentConfigs]);
        // const environshort = [...environmentarr].sort((a, b) =>
        //   a.toLowerCase() < b.toLowerCase() ? -1 : 1
        // );
        // dispatch(environmentListMethod([...environshort]));
        // setEnvironmentList([...environshort]);

        //cloud
        const cloudConfigs = data.newfunctionquery1.map((v) => v.cloud);
        // console.log(cloudConfigs)
        const cloudarr = new Set([...cloudConfigs]);
        const cloudshort = [...cloudarr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        dispatch(cloudListMethod([...cloudshort]));
        setCloudList([...cloudshort]);

        //account
        const accountConfigs = data.newfunctionquery1.map(
          (v) => v.account_name
        );
        const accountarr = new Set([...accountConfigs]);
        const accountshort = [...accountarr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        dispatch(accountListMethod([...accountshort]));
        setAccountList([...accountshort]);

        //application
        const applicationConfigs = data.newfunctionquery1.map(
          (v) => v.application
        );
        const applicationarr = new Set([...applicationConfigs]);
        const applicationshort = [...applicationarr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        dispatch(applicationListMethod([...applicationshort]));
        setApplicationList([...applicationshort]);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [selectedTopdate, selectedCloud]);
  useEffect(() => {
    var newdropdownlist = recordslist;
    const filteredlist = recordslist.filter((datas) => {
      //dropdown condition
      var checkenvironment = dropDownResult(
        selectedEnvironment,
        datas.environment
      );
      var checkcloud = dropDownResult(selectedCloud, datas.cloud);
      var checkapplication = dropDownResult(
        selectedApplication,
        datas.application
      );
      var checkaccount = dropDownResult(selectedAccount, datas.account_name);

      const conditionloop = [
        checkapplication,
        checkenvironment,
        checkcloud,
        checkaccount,
      ];
      const eval_value = conditionloop.filter(function (element) {
        return element !== undefined;
      });
      var returnvalue;
      if (eval_value.length == 1) {
        returnvalue = eval(eval_value[0]);
      } else if (eval_value.length == 2) {
        returnvalue = eval(eval_value[0], eval_value[1]);
      } else if (eval_value.length == 3) {
        returnvalue = eval(eval_value[0], eval_value[1], eval_value[2]);
      } else if (eval_value.length == 4) {
        returnvalue = eval(
          eval_value[0],
          eval_value[1],
          eval_value[2],
          eval_value[3]
        );
      } else if (eval_value.length == 5) {
        returnvalue = eval(
          eval_value[0],
          eval_value[1],
          eval_value[2],
          eval_value[3],
          eval_value[4]
        );
      } else if (eval_value.length == 6) {
        returnvalue = eval(
          eval_value[0],
          eval_value[1],
          eval_value[2],
          eval_value[3],
          eval_value[4],
          eval_value[5]
        );
      } else if (eval_value.length == 7) {
        returnvalue = eval(
          eval_value[0],
          eval_value[1],
          eval_value[2],
          eval_value[3],
          eval_value[4],
          eval_value[5],
          eval_value[6]
        );
      } else if (eval_value.length == 8) {
        returnvalue = eval(
          eval_value[0],
          eval_value[1],
          eval_value[2],
          eval_value[3],
          eval_value[4],
          eval_value[5],
          eval_value[6],
          eval_value[7]
        );
      }
      var returncondition;
      if (returnvalue === 1) {
        returncondition = true;
      } else if (returnvalue === true) {
        returncondition = true;
      } else {
        returncondition = false;
      }
      return returncondition;
    });
    if (filteredlist.length > 0) {
      newdropdownlist = filteredlist;
    }
    //service
    const serviceConfigs = newdropdownlist.map((v) => v.service);
    const servicearr = new Set([...serviceConfigs]);
    const serviceshort = [...servicearr].sort((a, b) =>
      a.toLowerCase() < b.toLowerCase() ? -1 : 1
    );
    setServicesList([...serviceshort]);
    dispatch(setServiceList([...serviceshort]));

    //db
    const dbConfigsfilter = newdropdownlist.filter((datas) => {
      return datas.db !== null && datas.db !== "";
    });
    const dbConfigs = dbConfigsfilter.map((v) => v.db);
    const dbarr = new Set([...dbConfigs]);
    const dbshort = [...dbarr].sort((a, b) =>
      a.toLowerCase() < b.toLowerCase() ? -1 : 1
    );
    // setdbList([...dbshort]);
    // dispatch(setDbList([...dbshort]));

    //os
    const osConfigsfilter = newdropdownlist.filter((datas) => {
      return datas.os !== null && datas.os !== "";
    });
    const osConfigs = osConfigsfilter.map((v) => v.os);
    const osarr = new Set([...osConfigs]);
    const osshort = [...osarr].sort((a, b) =>
      a.toLowerCase() < b.toLowerCase() ? -1 : 1
    );
    // setOSList([...osshort]);
    // dispatch(setOsList([...osshort]));

    //infra
    const infraConfigs = newdropdownlist.map((v) => v.infra);
    const infraarr = new Set([...infraConfigs]);
    const infrashort = [...infraarr].sort((a, b) =>
      a.toLowerCase() < b.toLowerCase() ? -1 : 1
    );
    setInfraList([...infrashort]);
    dispatch(setInfraRList([...infrashort]));
  }, [
    recordslist,
    selectedEnvironment,
    selectedAccount,
    selectedCloud,
    selectedApplication,
    selecteddc,
  ]);
  //drop down
  useEffect(() => {
    dispatch(selectedEnvironmentMethod(selectenvironment));
    dispatch(selectedAccountMethod(selectaccount));
    dispatch(selectedCloudMethod(selectcloud));
    dispatch(selectedApplicationMethod(selectapplication));
    dispatch(selectedServicesMethod(selectedservicessta));
    dispatch(selectedOSMethod(selectedossta));
    dispatch(selectedOUMethod(selectedousta));
    dispatch(selectedTicketOwnerMethod(selectedticketownersta));
    dispatch(selectedTicketClosedMethod(selectedticketclosedsta));
    dispatch(selectedCategoryMethod(selectedcategorysta));
    dispatch(selectedSlaMethod(selectedslasta));
    dispatch(selectedInfraMethod(selectedinfrasta));
    dispatch(selectedDbMethod(selecteddbsta));
    dispatch(selectedDCMethod(selecteddcsta));
  }, [datachanges]);
  const handleChange = (type, value, checked) => {
    // console.log(type);
    // console.log(value);
    // console.log(checked);
    if (type === "Environments") {
      if (value === "all") {
        setSelectEnvironment(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectenvironment.indexOf("all") !== -1) {
            setSelectEnvironment([
              ...selectenvironment.filter((x) => x !== "all"),
              value,
            ]);
          } else {
            setSelectEnvironment([...selectenvironment, value]);
          }
        } else {
          const result = selectenvironment.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectEnvironment(result_value);
        }
      }
    }
    if (type === "DC") {
      if (value === "all") {
        setSelectedDC(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selecteddcsta.indexOf("all") !== -1) {
            setSelectedDC([...selecteddcsta.filter((x) => x !== "all"), value]);
          } else {
            setSelectedDC([...selecteddcsta, value]);
          }
        } else {
          const result = selecteddcsta.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectedDC(result_value);
        }
      }
    }
    if (type === "OU") {
      if (value === "all") {
        setSelectedOU(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectedousta.indexOf("all") !== -1) {
            setSelectedOU([...selectedousta.filter((x) => x !== "all"), value]);
          } else {
            setSelectedOU([...selectedousta, value]);
          }
        } else {
          const result = selectedousta.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectedOU(result_value);
        }
      }

    }
    if (type === "Cloud") {
      if (value === "all") {
        setSelectCloud(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectcloud.indexOf("all") !== -1) {
            setSelectCloud([...selectcloud.filter((x) => x !== "all"), value]);
          } else {
            setSelectCloud([...selectcloud, value]);
          }
        } else {
          const result = selectcloud.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectCloud(result_value);
        }
      }
    }
    if (type === "Accounts") {
      if (value === "all") {
        setSelectAccount(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectaccount.indexOf("all") !== -1) {
            setSelectAccount([
              ...selectaccount.filter((x) => x !== "all"),
              value,
            ]);
          } else {
            setSelectAccount([...selectaccount, value]);
          }
        } else {
          const result = selectaccount.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectAccount(result_value);
        }
      }
    }
    if (type === "Applications" || type === "Application") {
      if (value === "all") {
        setSelectApplication(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectapplication.indexOf("all") !== -1) {
            setSelectApplication([
              ...selectapplication.filter((x) => x !== "all"),
              value,
            ]);
          } else {
            setSelectApplication([...selectapplication, value]);
          }
        } else {
          const result = selectapplication.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectApplication(result_value);
        }
      }
    }
    if (type === "Services") {
      if (value === "all") {
        setSelectedServices(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectedservicessta.indexOf("all") !== -1) {
            setSelectedServices([
              ...selectedservicessta.filter((x) => x !== "all"),
              value,
            ]);
          } else {
            setSelectedServices([...selectedservicessta, value]);
          }
        } else {
          const result = selectedservicessta.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectedServices(result_value);
        }
      }
    }
    if (type === "DB" || type === "Database") {
      if (value === "all") {
        setSelectedDB(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectedossta.indexOf("all") !== -1) {
            setSelectedDB([...selectedossta.filter((x) => x !== "all"), value]);
          } else {
            setSelectedDB([...selectedossta, value]);
          }
        } else {
          const result = selectedossta.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectedDB(result_value);
        }
      }
    }

    if (type === "Category") {
      if (value === "all") {
        setSelectedCategory(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectedcategorysta.indexOf("all") !== -1) {
            setSelectedCategory([
              ...selectedcategorysta.filter((x) => x !== "all"),
              value,
            ]);
          } else {
            setSelectedCategory([...selectedcategorysta, value]);
          }
        } else {
          const result = selectedcategorysta.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectedCategory(result_value);
        }
      }
    }

    if (type === "Sla") {
      if (value === "all") {
        setSelectedSla(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectedslasta.indexOf("all") !== -1) {
            setSelectedSla([
              ...selectedslasta.filter((x) => x !== "all"),
              value,
            ]);
          } else {
            setSelectedSla([...selectedslasta, value]);
          }
        } else {
          const result = selectedslasta.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectedSla(result_value);
        }
      }
      // console.log("selectedou");
      // console.log(selectedou);
      // console.log("selectedousta");
      // console.log(selectedousta);
    }

    if (type === "TicketOwner") {
      if (value === "all") {
        setSelectedTicketowner(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectedticketownersta.indexOf("all") !== -1) {
            setSelectedTicketowner([
              ...selectedticketownersta.filter((x) => x !== "all"),
              value,
            ]);
          } else {
            setSelectedTicketowner([...selectedticketownersta, value]);
          }
        } else {
          const result = selectedticketownersta.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectedTicketowner(result_value);
        }
      }
    }

    if (type === "TicketClosed") {
      if (value === "all") {
        setSelectedTicketclosed(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectedticketclosedsta.indexOf("all") !== -1) {
            setSelectedTicketclosed([
              ...selectedticketclosedsta.filter((x) => x !== "all"),
              value,
            ]);
          } else {
            setSelectedTicketclosed([...selectedticketclosedsta, value]);
          }
        } else {
          const result = selectedticketclosedsta.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectedTicketclosed(result_value);
        }
      }
    }

    if (type === "Infra") {
      if (value === "all") {
        setSelectedInfra(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectedinfrasta.indexOf("all") !== -1) {
            setSelectedInfra([
              ...selectedinfrasta.filter((x) => x !== "all"),
              value,
            ]);
          } else {
            setSelectedInfra([...selectedinfrasta, value]);
          }
        } else {
          const result = selectedinfrasta.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectedInfra(result_value);
        }
      }
    }
    if (type === "OS") {
      if (value === "all") {
        setSelectedOS(["all"]);
        dispatch(setFilterButtonStatus(false));
      } else {
        dispatch(setFilterButtonStatus(true));
        if (checked) {
          if (selectedossta.indexOf("all") !== -1) {
            setSelectedOS([...selectedossta.filter((x) => x !== "all"), value]);
          } else {
            setSelectedOS([...selectedossta, value]);
          }
        } else {
          const result = selectedossta.filter((x) => x !== value);
          const result_value = result.length > 0 ? result : ["all"];
          setSelectedOS(result_value);
        }
      }
    }
    setDataChanges(!datachanges);
  };

  const clearAllDate = () => {
    dispatch(setFilterButtonStatus(false));
    setSelectEnvironment(["all"]);
    setSelectAccount(["all"]);
    setSelectCloud(["all"]);
    setSelectApplication(["all"]);
    setSelectedCategory(["all"]);
    setSelectedSla(["all"]);
    setSelectedTicketclosed(["all"]);
    setSelectedTicketowner(["all"]);
    setSelectedOU(["all"]);
    setSelectedDB(["all"]);
    setSelectedDC(["all"]);
    setSelectedOS(["all"]);

    var currentdate = new Date();
    const month_2_current = new Date(
      currentdate.getFullYear(),
      currentdate.getMonth(),
      currentdate.getDate()
    );
    const month_2_current_new = `${month_2_current.getFullYear()}-${
      month_2_current.getMonth() + 1
    }-01`;
    // console.log(month_2_current_new);

    dispatch(selectedTopDateMethod(month_2_current_new));
    dispatch(selectedEnvironmentMethod(["all"]));
    dispatch(selectedAccountMethod(["all"]));
    dispatch(selectedCloudMethod(["all"]));
    dispatch(selectedApplicationMethod(["all"]));
    setDataChanges(!datachanges);
    // console.log("clear");
  };

  useEffect(() => {
    getAPIAssessFilterValues();
    getSlmData();
  }, []);

  const [dcList, setDCList] = useState([]);

  const getAPIAssessFilterValues = () => {
    dispatch(getAssessReducer({ companyName }))
      .unwrap()
      .then(({ data }) => {

        // for Env
        const envResult = data.assess_filter.filter((datas) => {
          return datas.environment !== null && datas.environment !== "";
        });

        const environmentConfigs = envResult.map((v) => v.environment);
        const env_arr = new Set([...environmentConfigs]);
        const sorted_arr_env = [...env_arr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        setEnvironmentList([...sorted_arr_env]);
        // for dc
        const dcResult = data.assess_filter.filter((datas) => {
          return (
            datas.data_center_name !== null && datas.data_center_name !== ""
          );
        });
        const dcConfigs = dcResult.map((v) => v.data_center_name);
        const dc_Arr = new Set([...dcConfigs]);
        const sorted_arr_dc = [...dc_Arr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        setDCList([...sorted_arr_dc]);
        // for application
        const appResult = data.assess_filter.filter((datas) => {
          return datas.application !== null && datas.application !== "";
        });
        const appConfigs = appResult.map((v) => v.application);
        const app_Arr = new Set([...appConfigs]);
        const sorted_arr_app = [...app_Arr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        setApplicationList([...sorted_arr_app]);
        // for os
        const osResult = data.assess_filter.filter((datas) => {
          return (
            datas.operating_system !== null && datas.operating_system !== ""
          );
        });
        const osConfigs = osResult.map((v) => v.operating_system);
        const os_Arr = new Set([...osConfigs]);
        const sorted_arr_os = [...os_Arr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        setOSList([...sorted_arr_os]);
        // for db
        const dbResult = data.assess_filter.filter((datas) => {
          return datas.database_name !== null && datas.database_name !== "";
        });
        const dbConfigs = dbResult.map((v) => v.database_name);
        const db_Arr = new Set([...dbConfigs]);
        const sorted_arr_db = [...db_Arr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        setdbList([...sorted_arr_db]);
      })
      .catch(() => {});
  };

  // Operate Screen

  useEffect(() => {
    getAPIOperateFilterValues();
  }, []);

  const getAPIOperateFilterValues = () => {
    dispatch(getOperateReducer({ companyName }))
      .unwrap()
      .then(({ data }) => {
        // console.log("operate filter data");
        // console.log(data);
        // for ou
        const ouResult = data.operate_filter_c.filter((datas) => {
          return (
            datas.organization_unit !== null && datas.organization_unit !== ""
          );
        });
        const ouConfigs = ouResult.map((v) => v.organization_unit);
        const ou_Arr = new Set([...ouConfigs]);
        const sorted_arr_ou = [...ou_Arr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        setOUList([...sorted_arr_ou]);
      })
      .catch(() => {});
  };

  const getSlmData = () => {
    dispatch(getslmfilter({ companyName }))
      .unwrap()
      .then(({ data }) => {
        // console.log("operate filter data");
        // console.log(data);
        // for ou
        const ouResult = data.slm_filter_c.filter((datas) => {
          // console.log(datas.category, "categorylisty");
          return (
            datas.organization_unit !== null && datas.organization_unit !== ""
          );
        });
        const ouConfigs = ouResult.map((v) => v.organization_unit);
        const ou_Arr = new Set([...ouConfigs]);
        const sorted_arr_ou = [...ou_Arr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        // console.log([...sorted_arr_ou]);
        setOUList([...sorted_arr_ou]);

        const slaResult = data.slm_filter_c.filter((datas) => {
          return datas.sla !== null && datas.sla !== "";
        });
        const slaConfigs = slaResult.map((v) => v.sla);
        const sla_Arr = new Set([...slaConfigs]);
        const sorted_arr_sla = [...sla_Arr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        // console.log([...sorted_arr_sla]);
        setSlaList([...sorted_arr_sla]);

        const categoryResult = data.slm_filter_c.filter((datas) => {
          return datas.category !== null && datas.category !== "";
        });
        const categoryConfigs = categoryResult.map((v) => v.category);
        const category_Arr = new Set([...categoryConfigs]);
        const sorted_arr_category = [...category_Arr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        // console.log([...sorted_arr_category]);
        setCategorylist([...sorted_arr_category]);

        const ticketownerResult = data.slm_filter_c.filter((datas) => {
          return datas.ticket_owner !== null && datas.ticket_owner !== "";
        });
        const ticketownerConfigs = ticketownerResult.map((v) => v.ticket_owner);
        const ticketowner_Arr = new Set([...ticketownerConfigs]);
        const sorted_arr_ticketowner = [...ticketowner_Arr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        // console.log([...sorted_arr_ticketowner]);
        setTicketownerList([...sorted_arr_ticketowner]);

        const ticketclosedResult = data.slm_filter_c.filter((datas) => {
          return (
            datas.ticket_closed_by !== null && datas.ticket_closed_by !== ""
          );
        });
        const ticketclosedConfigs = ticketclosedResult.map(
          (v) => v.ticket_closed_by
        );
        const ticketclosed_Arr = new Set([...ticketclosedConfigs]);
        const sorted_arr_ticketclosed = [...ticketclosed_Arr].sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? -1 : 1
        );
        // console.log([...sorted_arr_ticketclosed]);
        setTicketclosedList([...sorted_arr_ticketclosed]);
      })
      .catch(() => {});
  };

  return (
    <div>
      <>
        {contextHolder}

        <Dropdown
          trigger={["click"]}
          menuPosition="fixed"
          overlayClassName="plat-header-filter"
          placement="bottomRight"
          dropdownRender={(menu) => (
            <div className="filter-dropdown-render a2cfilter">
              <div
                style={{
                  paddingTop: 5,
                  paddingRight: 8,
                  paddingBottom: 8,
                  paddingLeft: 8,
                }}
                className="filter-dropdown-scroll"
              >
                <div className="filter-header">
                  <p className="filter-header-left main-headtext">Filters</p>
                  <p
                    className=" filter-header-right cursor-pointer"
                    onClick={clearAllDate}
                  >
                    <a>Clear All</a>
                  </p>
                </div>
                <div className="filter-sub-header">
                  <p className=" filter-sub-title">Global Filters</p>
                </div>
                {/* <div className="mt-2">
                  <FilterDropdownDate />
                </div> */}

                <div className="mt-2">
                  <FilterDropdownList
                    title="All Applications"
                    list={applicationlist}
                    statelist={setApplicationList}
                    reduxlist={applicationList}
                    type="Application"
                    searchOption={false}
                    selectedvalue={selectapplication}
                    handleChange={handleChange}
                  />
                </div>

                {window.location.pathname === "/assess" ||
                window.location.pathname === "/a2c-rover" ||
                window.location.pathname === "/migrate" ? (
                  <>
                    <div className="mt-2">
                      <FilterDropdownList
                        title="All Environments"
                        list={environmentlist}
                        statelist={setEnvironmentList}
                        reduxlist={environmentlist}
                        type="Environments"
                        searchOption={false}
                        selectedvalue={selectedEnvironment}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="mt-2">
                      <FilterDropdownList
                        title="All DC"
                        list={dcList}
                        statelist={setDCList}
                        reduxlist={dcList}
                        type="DC"
                        selectedvalue={selecteddcsta}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="mt-2 ">
                      <FilterDropdownList
                        title="All OS"
                        list={oslist}
                        statelist={setOSList}
                        reduxlist={oslist}
                        type="OS"
                        selectedvalue={selectedos}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="mt-2 ">
                      <FilterDropdownList
                        title="All Database"
                        list={dblist}
                        statelist={setDbList}
                        reduxlist={dblist}
                        type="Database"
                        selectedvalue={selecteddb}
                        handleChange={handleChange}
                      />
                    </div>
                  </>
                ) : window.location.pathname === "/operate" ? (
                  <>
                    <div className="mt-2">
                      <FilterDropdownList
                        title="All Environments"
                        list={environmentlist}
                        statelist={setEnvironmentList}
                        reduxlist={environmentlist}
                        type="Environments"
                        searchOption={false}
                        selectedvalue={selectedEnvironment}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="mt-2 ">
                      <FilterDropdownList
                        title="All Organization Unit"
                        list={oulist}
                        statelist={setOUList}
                        reduxlist={ouList}
                        type="OU"
                        selectedvalue={selectedou}
                        handleChange={handleChange}
                      />
                    </div>
                    {/* all accounts */}
                    <div className="mt-2">
                      <FilterDropdownList
                        title="All Accounts"
                        list={accountlist}
                        statelist={setAccountList}
                        reduxlist={accountList}
                        type="Accounts"
                        searchOption={false}
                        selectedvalue={selectedAccount}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="mt-2 ">
                      <FilterDropdownList
                        title="All Database"
                        list={dblist}
                        statelist={setDbList}
                        reduxlist={dblist}
                        type="Database"
                        selectedvalue={selecteddb}
                        handleChange={handleChange}
                      />
                    </div>
                  </>
                ) : window.location.pathname === "/slm" ? (
                  <>
                    <div className="mt-2">
                      <FilterDropdownList
                        title="All Accounts"
                        list={accountlist}
                        statelist={setAccountList}
                        reduxlist={accountList}
                        type="Accounts"
                        searchOption={false}
                        selectedvalue={selectedAccount}
                        handleChange={handleChange}
                      />
                    </div>

                    <div className="mt-2 ">
                      <FilterDropdownList
                        title="All Organization Unit"
                        list={oulist}
                        statelist={setOUList}
                        reduxlist={ouList}
                        type="OU"
                        selectedvalue={selectedou}
                        handleChange={handleChange}
                      />
                    </div>

                    <div className="mt-2 ">
                      <FilterDropdownList
                        title="All Ticket Owner"
                        list={ticketownerlist}
                        statelist={setTicketownerList}
                        reduxlist={ticketownerList}
                        type="TicketOwner"
                        selectedvalue={selectedticketowner}
                        handleChange={handleChange}
                      />
                    </div>

                    <div className="mt-2 ">
                      <FilterDropdownList
                        title="All Ticket Closed By"
                        list={ticketclosedlist}
                        statelist={setTicketclosedList}
                        reduxlist={ticketclosedList}
                        type="TicketClosed"
                        selectedvalue={selectedticketclosed}
                        handleChange={handleChange}
                      />
                    </div>

                    <div className="mt-2 ">
                      <FilterDropdownList
                        title="All Category"
                        list={categorylist}
                        statelist={setCategorylist}
                        reduxlist={categoryList}
                        type="Category"
                        selectedvalue={selectedcategory}
                        handleChange={handleChange}
                      />
                    </div>

                    <div className="mt-2 ">
                      <FilterDropdownList
                        title="All Sla"
                        list={slalist}
                        statelist={setSlaList}
                        reduxlist={slaList}
                        type="Sla"
                        selectedvalue={selectedsla}
                        handleChange={handleChange}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        >
          <a
            onClick={(e) => e.preventDefault()}
            className={
              "header-filter-btn " +
              (filterbuttonstatus && "header-filter-btn-active")
            }
          >
            <Space className="cursor-pointer">
              <Icon icon="material-symbols:filter-alt-outline-sharp" />
              Filter
            </Space>
          </a>
        </Dropdown>
      </>
    </div>
  );
}
export default A2cFilter;
