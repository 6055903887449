import { memo, useEffect, useState } from "react";
import DrawerPlat from "../../properties/Drawer/DrawerPlat";
import { Form, Input, Select, Button, Row, Col, message, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirst } from "../../custom_hook/CustomHook";
import { updaterealisedtable } from "../../action/costimizeV2Active";
const TrackingForm = (props) => {
  const { environmentList, applicationList, serviceList, accountList } =
    useSelector((state) => state.constimize);
  const changeDrawerStatus = () => {
    props.setDrawerStatus(false);
  };
  const dispatch = useDispatch();
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const { selectedCloud } = useSelector((state) => state.constimize);
  const [buttonloading, setButtonLoading] = useState(false);
  const [form] = Form.useForm();
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [allapplication, setAllapplication] = useState([]);
  const [allenvironment, setAllenvironment] = useState([]);
  const [allservice, setAllService] = useState([]);
  const [allaccount, setAllAccount] = useState([]);
  const [rowdata, setRowdata] = useState([]);
  useEffect(() => {
    var records = {
      applications: "",
      environments: "",
      status: "",
      services: "",
      account_names: "",
      resource_sub_category: "",
      recommendation_rule: "",
      resource_id: "",
      resource_name: "",
      current_configuration: "",
      recommended_configuration: "",
      commitment: "",
      potential_savings: "",
      points_discussed: "",
      remarks: "",
      status: "",
    };
    if (props.oldUpdateRecords) {
      records = {
        applications: props.oldUpdateRecords.applications,
        environments: props.oldUpdateRecords.environments,
        status: props.oldUpdateRecords.status,
        services: props.oldUpdateRecords.services,
        account_names: props.oldUpdateRecords.account_names,
        resource_sub_category: props.oldUpdateRecords.resource_sub_category,
        recommendation_rule: props.oldUpdateRecords.recommendation_rule,
        resource_id: props.oldUpdateRecords.resource_id,
        resource_name: props.oldUpdateRecords.resource_name,
        current_configuration: props.oldUpdateRecords.current_configuration,
        recommended_configuration:
          props.oldUpdateRecords.recommended_configuration,
        commitment: props.oldUpdateRecords.commitment,
        potential_savings: props.oldUpdateRecords.potential_savings,
        realised_savings: props.oldUpdateRecords.realised_savings,
      };
      setRowdata(records);
    }
    form.setFieldsValue(records);
  }, [props]);
  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const report_month = selectedTopdate;
  const cloud = selectedCloud;
  const handleSubmit = (value) => {
    const report_month = selectedTopdate;
    const cloud = selectedCloud;
    const input = {
      account_names: value.account_names,
      application: value.applications,
      commitment: value.commitment,
      current_configuration: value.current_configuration,
      environment: value.environments,
      potential_savings: value.potential_savings,
      recommendation_rule: value.recommendation_rule,
      recommended_configuration: value.recommended_configuration,
      resource_id: value.resource_id,
      resource_name: value.resource_name,
      resource_sub_category: value.resource_sub_category,
      service: value.services,
      status: value.status,
      username: props.oldUpdateRecords.username,
      targetdate: props.oldUpdateRecords.targetdate,
      // cloud:cloud[0],
      cloud: props.oldUpdateRecords.cloud,
    };
    dispatch(updaterealisedtable({ input, report_month, company_name }))
      .unwrap()
      .then((data) => {
        //   console.log(data);
        //   console.log(data.update_savings_plan_recommendation)

        const status = data.data.update_savings_plan_recommendation[0].status

        if (status === "Implementation Completed") {
        }
        message.success("Updated Successfully ");
        props.setDrawerStatus(false);
        setButtonLoading(false);
        props.setRecordsUpdate(!props.recordsupdate);
      })
      .catch((err) => {
        console.log(err.message);
        message.error(err);
        setButtonLoading(false);
      });
  };

  useEffect(() => {
    setAllapplication(
      applicationList.map((list) => {
        return {
          value: list,
          label: capitalizeFirst(list),
        };
      })
    );
    setAllenvironment(
      environmentList.map((list) => {
        return {
          value: list,
          label: capitalizeFirst(list),
        };
      })
    );
    setAllService(
      serviceList.map((list) => {
        return {
          value: list,
          label: capitalizeFirst(list),
        };
      })
    );
    setAllAccount(
      accountList.map((list) => {
        return {
          value: list,
          label: capitalizeFirst(list),
        };
      })
    );
  }, [applicationList, environmentList, serviceList, accountList]);
  useEffect(() => {
  }, []);

  return (
    <>
      <Modal
        className="costmodal"
        open={props.drawerstatus}
        onCancel={changeDrawerStatus}
        drawerdeletestatus={drawerdeletestatus}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        confirmLoading={confirmLoading}
        setConfirmLoading={setConfirmLoading}
        // rowDelete={rowDelete}
        // deletestatus={deletestatus}
        title="Savings Model"
        footer={null}
      >
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="applications"
                label="Application"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  disabled={true}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={allapplication}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="environments"
                label="Environment"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  disabled={true}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={allenvironment}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="status"
                label="Status"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "Analysis Pending",
                      label: "Analysis Pending",
                    },
                    {
                      value: "Analysis Completed",
                      label: "Analysis Completed",
                    },
                    {
                      value: "Implementation Planned",
                      label: "Implementation Planned",
                    },
                    {
                      value: "Implementation Completed",
                      label: "Implementation Completed",
                    },
                    {
                      value: "Not Implemented",
                      label: "Not Implemented",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="services"
                label="Service"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  disabled={true}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={allservice}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="account_names"
                label="Account"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  disabled={true}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={allaccount}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="resource_sub_category"
                label="Resource Sub Category"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  disabled={true}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "Instance",
                      label: "Instance",
                    },
                    {
                      value: "EBS",
                      label: "EBS",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="recommendation_rule"
                label="Recommendation Type"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  disabled={true}
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "Right Sizing",
                      label: "Right Sizing",
                    },
                    {
                      value: "OS Licensing",
                      label: "OS Licensing",
                    },
                    {
                      value: "DB Licensing",
                      label: "DB Licensing",
                    },
                    {
                      value: "Storage Tiering",
                      label: "Storage Tiering",
                    },
                    {
                      value: "Stop/Start",
                      label: "Stop/Start",
                    },
                    {
                      value: "Savings Plan",
                      label: "Savings Plan",
                    },
                    {
                      value: "Instance Tiering",
                      label: "Instance Tiering",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="resource_id"
                label="Resource ID"
                rules={[{ required: true, message: "" }]}
              >
                <Input
                  className="tracking-input"
                  disabled={true}
                  //   disabled={deletestatus ? true : false}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="resource_name"
                label="Resource Name"
                rules={[{ required: true, message: "" }]}
              >
                <Input className="tracking-input" disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="current_configuration"
                label="Current Configuration"
                rules={[{ required: true, message: "" }]}
              >
                <Input className="tracking-input" disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="recommended_configuration"
                label="Recommended Configuration"
                rules={[{ required: true, message: "" }]}
              >
                <Input className="tracking-input" disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="commitment"
                label="Commitment"
                rules={[{ required: true, message: "" }]}
              >
                <Input className="tracking-input" disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                name="potential_savings"
                label="Potential Savings"
                rules={[{ required: true, message: "" }]}
              >
                <Input
                  prefix="$"
                  style={{
                    width: "100%",
                  }}
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
          {deletestatus && (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  name="realised_savings"
                  label="Realized Savings"
                  // rules={[{ required: true, message: '' }]}
                >
                  <Input
                    prefix="$"
                    style={{
                      width: "100%",
                    }}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => props.setDrawerStatus(false)}
                  className="cancel-btn"
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn"
                  loading={buttonloading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default memo(TrackingForm);
