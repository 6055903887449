import React, { useEffect, useState } from "react";
import { Row, Col, Card, Typography, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { memo } from "react";
import { loginUser } from "../../action/userAction";
import {
  userInfoMethod,
  getCompanyName,
  setUserType,
} from "../../slice/userSlice";
import { roleGetByID } from "../../action/accountAction";

function MyRoleAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userdetails, setUserDetails] = useState();
  const [userrolelist, setUserRoleList] = useState([]);
  const [preloader, setPreLoader] = useState(true);
  useEffect(() => {
    setPreLoader(true);
    const records = {
      user_id: localStorage.getItem("CognitoIdentityServiceProvider.auth"),
      _token: localStorage.getItem("_token"),
    };
    dispatch(loginUser(records))
      .unwrap()
      .then(({ data }) => {
        // console.log("chekcing");
        // console.log(data);
        setPreLoader(false);
        setUserDetails(data.getUsers);
        if (data.getUsers.company_name === "demo") {
          localStorage.setItem(
            "CognitoIdentityServiceProvider.company",
            "Internal1CH"
          );
        } else if (data.getUsers.company_name.toLowerCase() === "1cloudhub") {
          localStorage.setItem(
            "CognitoIdentityServiceProvider.company",
            "Internal1CH"
          );
        } else {
          localStorage.setItem(
            "CognitoIdentityServiceProvider.company",
            data.getUsers.company_name
          );
        }
        dispatch(userInfoMethod(data.getUsers));
        dispatch(getCompanyName(data.getUsers.company_name));
        const role_list = JSON.parse(data.getUsers.role);
        getRoleListDetails(role_list, data.getUsers.company_name);
      })
      .catch((err) => {
        setPreLoader(false);
        console.log(err);
        localStorage.removeItem("CognitoIdentityServiceProvider.auth");
        localStorage.removeItem("_token");
        window.location.href = "/signin";
      });
  }, []);

  const getRoleListDetails = (list, company_name) => {
    var setrecords = [];
    // console.log(list);
    const loopResult = list.reduce(async (previous, value) => {
      await previous;
      // Return new promise
      return new Promise((resolve) => {
        // console.log(value);
        const role_id = value;
        dispatch(roleGetByID({ role_id, company_name }))
          .unwrap()
          .then(({ data }) => {
            // console.log(data);
            if (data.usermanagementroledetails) {
              const records = JSON.parse(data.usermanagementroledetails);
              setrecords.push(records.data[0]);
              resolve();
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      });
    }, Promise.resolve());
    loopResult.then(() => {
      setUserRoleList(setrecords);
    });
  };

  const roleNavigated = (data) => {
    localStorage.setItem(
      "CognitoIdentityServiceProvider.role",
      JSON.stringify(data.resources)
    );
    if (data.role_name.toLowerCase() === "master") {
      localStorage.setItem(
        "CognitoIdentityServiceProvider.offering",
        data.role_name.toLowerCase()
      );
      dispatch(userInfoMethod(data.getUsers));
      dispatch(setUserType({ type: data.role_name.toLowerCase() }));
      navigate("/admin-dashboard");
    } else if (data.role_name.toLowerCase() === "1ch_admin") {
      localStorage.setItem(
        "CognitoIdentityServiceProvider.offering",
        data.role_name.toLowerCase()
      );
      dispatch(userInfoMethod(data.getUsers));
      dispatch(setUserType({ type: data.role_name.toLowerCase() }));
      navigate("/admin-dashboard");
    } else {
      dispatch(setUserType({ type: data.role_name.toLowerCase() }));
      localStorage.setItem(
        "CognitoIdentityServiceProvider.offering",
        data.role_name.toLowerCase()
      );
      const offering_list = JSON.parse(userdetails.Offerings);
      if (offering_list || offering_list.length > 0) {
        // console.log(offering_list[0]);
        if (offering_list[0] === "slm") {
          navigate("/slm/dashboard");
        } else if (offering_list[0] === "event_express") {
          navigate("/peems");
        } else if (offering_list[0] === "smart_alert") {
          navigate("/cams");
        } else if (offering_list[0] === "devops") {
          navigate("/devops-requestor");
        } else {
          navigate("/costdemaze/quick_glance");
        }
      } else {
        navigate("/costdemaze/quick_glance");
      }
    }
  };
  return (
    <>
      {/* <Spin spinning={preloader} wrapperClassName="costimize-loader"> */}
      <div className="text-box p-1">
        <Row className="ad-header">
          <h4>Role</h4>
        </Row>
        {userrolelist.length > 0 ? (
          userrolelist.map((data, key) => {
            return (
              <Row>
                <Card
                  style={{ width: "100%" }}
                  className="admin-card"
                  onClick={() => roleNavigated(data)}
                >
                  <Typography>
                    {data.role_name.toLowerCase() === "1ch_admin" ? (
                      <>1CH Admin</>
                    ) : (
                      data.role_name
                    )}
                  </Typography>
                  <div className="action-btn">
                    <Icon
                      icon="uil:arrow-circle-right "
                      style={{ fontSize: "21px" }}
                    />
                  </div>
                </Card>
              </Row>
            );
          })
        ) : (
          <>
            <div className="d-flex justify-content-center align-items-center">
              <Spin size="large" wrapperClassName="costimize-loader" />
            </div>
          </>
        )}
      </div>
      {/* </Spin> */}
    </>
  );
}

export default memo(MyRoleAccount);
