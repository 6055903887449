import "../costimize_v2.css";
import React, { memo, useEffect } from "react";
import CostimizeSidebar from "../layout/CostimizeSidebar";
import MainHead from "../layout/MainHead";
import QuickHead from "../layout/QuickHead";
import BudgetvsSpend from "./BudgetvsSpend";
import CostSavings from "./CostSavings";
import Compliance from "./Compliance";
import { Icon } from "@iconify/react";
import KeyEvent from "./KeyEvent";
import TopFiveApplication from "./TopFiveApplication";
import TopFiveApplicationSpenLess from "./TopFiveApplicationSpenLess";
import { useState } from "react";
import { checkRolePermission } from "../../custom_hook/CustomHook";
import ChatBot from "../ChartBot/ChatBot";
import { Row, Col, Drawer, Button, Badge } from "antd";
import "../../costimize_v2/layout/Filter.css";
import CostdemazeFilter from "../../costimize_v2/layout/CostdemazeFilter";
import { useDispatch, useSelector } from "react-redux";
import QuickGlanceParentTabContainer from "./parent-tabs/QuickGlanceParentTabContainer";
const QuickGlanceLayout = () => {
  const {
    selectedCloud,
    selectedTopdate,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  } = useSelector((state) => state.constimize);
  let allCount = 0;
  let notAllCount = 0;
  const selectedValues = [
    selectedCloud,
    selectedEnvironment,
    selectedAccount,
    selectedApplication,
    selectedservices,
    selectedos,
    selectedinfra,
    selecteddb,
  ];

  selectedValues.forEach((value) => {
    if (Array.isArray(value) && value.includes("all")) {
      allCount++;
    } else {
      notAllCount++;
    }
  });

  const [budgetstatus, setBudgetStatus] = useState(true);
  const [openfilter, setOpenfilter] = useState(false);
  const [permission, setPermission] = useState({
    budget: checkRolePermission("budget_spend")
      ? checkRolePermission("budget_spend").access
      : 0,
    costsavings: checkRolePermission("cost_savings")
      ? checkRolePermission("cost_savings").access
      : 0,
    compliance: checkRolePermission("compliance")
      ? checkRolePermission("compliance").access
      : 0,
  });
  const showDrawer = () => {
    setOpenfilter(true);
  };
  const onClose = () => {
    setOpenfilter(false);
  };
  
  return (
    <>
      <Row>
        <Col span={1}>
          <CostimizeSidebar />
        </Col>
        <Col span={23} className="p-2">
          <Row>
            <Col span={24}>
              <QuickHead title="Quick Glance" />
            </Col>
          </Row>
          <div className="py-2"></div>
          <Row>
            <Col span={24}>
              <div className="  ps-5 pe-3 pt-0 pb-3">
                <QuickGlanceParentTabContainer />
                {/* {permission.budget ? <BudgetvsSpend /> : ""}
                {permission.costsavings ? <CostSavings /> : ""}
                {permission.compliance ? <Compliance /> : ""} */}
              </div>
            </Col>
          </Row>
        </Col>
        <div className="filter-button ">
          {notAllCount > 0 && (
            <Badge
              count={notAllCount}
              offset={[-35, -6]}
              className="filter-badge"
            />
          )}
          <Button
            className={`vertical-button ${
              notAllCount ? "vertical-button-zero" : ""
            } rotated`}
            onClick={showDrawer}
          >
            <span>Filter</span>
            <span>
              <Icon
                icon="tdesign:filter"
                className={`filter-icon ${
                  notAllCount ? "filter-icon-zero" : ""
                } rotated `}
              />
            </span>
          </Button>
        </div>
      </Row>

      <div className="costdemaze-drawer-filter">
        <Drawer
          title=""
          onClose={onClose}
          open={openfilter}
          size="300"
          className="costdemaze-filter-drawer"
        >
          <CostdemazeFilter />
        </Drawer>
      </div>
    </>
  );
};

export default memo(QuickGlanceLayout);
