import React from "react";
import A2CSidebar from "../a2c_rover/A2CSidebar";
import AssetsPage from "./AssetsPage";
import { Row, Col } from "antd";

function AssetsNew() {
  return (
    <Row>
      <Col xl={4}>
        <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
          <A2CSidebar />
        </div>{" "}
      </Col>
      <Col xl={19}>
        <div>
          <AssetsPage />
        </div>
      </Col>
    </Row>
  );
}
export default AssetsNew;
