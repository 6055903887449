import React, { useEffect, useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBBtn,
  MDBRow,
  MDBSwitch,
} from "mdb-react-ui-kit";
import { ReactComponent as YourSvg } from "../assets/svg/aws-icon.svg";
import Modal from "./Modal";
import AWSAccountSettingForm from "./AWSAccountSettingForm";
import {
  deleteAccount,
  listAccount,
  updateAccountDetails,
  updateAccountStatus,
  accountOnboarding,
  distinictlistAccount,
  companybasedlistAccount,
} from "./action/accountAction";
import { useDispatch, useSelector } from "react-redux";
import {
  addAccountListMethod,
  setSelectedCloud,
  setSelectedCompany,
} from "./slice/accountSlice";
import Dropdown from "react-bootstrap/Dropdown";
import { usermanagerUserList } from "./action/userAction";
import Alert from "@mui/material/Alert";
import ConfirmDialog from "./properties/ConfirmDialog/ConfirmDialog";
import { Icon } from "@iconify/react";
import { checkRolePermission } from "./custom_hook/CustomHook";
import { message, Spin } from "antd";
function AWSOnboarding() {
  const dispatch = useDispatch();
  const user = localStorage.getItem("CognitoIdentityServiceProvider.offering");
  const usercompany = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const user_id= localStorage.getItem("CognitoIdentityServiceProvider.auth")
  const Offer = localStorage.getItem("CognitoIdentityServiceProvider.offering");
  const [permission, setPermission] = useState({
    accourntwrite: checkRolePermission("account_onboarding")
      ? checkRolePermission("account_onboarding").write
      : 0,
  });

  const { accountList, selectedcompany, selectedcloud } = useSelector(
    (state) => state.account
  );

  const { userInfo } = useSelector((state) => state.user);
  const [flag, setFlag] = useState(false);
  const [updateflag, setUpdateFlag] = useState(false);

  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState({});
  const [updatevalue, setUpdateValue] = useState({});

  const [companylist, setCompanyList] = useState([]);
  const [recordlist, setRecordlist] = useState([]);
  const [userstatus, setUserStatus] = useState(false);
  const [alartcontent, setAlartContent] = useState("");
  const [createdby, setCreatedBy] = useState(userInfo.first_name);
  const [confirmdialogstatus, setConfirmDialogStatus] = useState(false);
  const [detSelecetedUser, setDetSelecetedUser] = useState([]);
  const [accountValidate, setAccountValidate] = useState("validate");
  const [closealert, setCloseAlert] = useState(false);
  const [buttonloader, setButtonLoader] = useState(false);
  const [datachanges, setDataChanges] = useState(true);
  const [dropitem, setdropitem] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [errorname, seterrorname] = useState("");
  const setCloseModal = () => {
    setFlag(false);
  };
  const setUpdateCloseModal = () => {
    setUpdateFlag(false);
  };
  const onClickSwitch = (e, index, list) => {
    var status_value = "active";
    if (e.target.checked) {
      status_value = "active";
    } else {
      status_value = "inactive";
    }
    const usage_account_id = list.usage_account_id;
    const account_name = list.account_name;
    dispatch(
      updateAccountStatus({ usage_account_id, account_name, status_value })
    )
      .unwrap()
      .then(({ data }) => {
        setUserStatus(true);
        setAlartContent("User Status Updated Successfully Done.");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const event_type = "getDistinctCustomer";
  const event_type_company = "getAccountDetails";
  const cloud = "aws";
  const [selectedCompanyNameinit, setSelectedCompanyNameinit] = useState("");
  const [initialaccount, setinitialaccount] = useState([]);
  const toggleText =
    user === "master"
      ? selectedCompanyNameinit === ""
        ? "ALL"
        : selectedCompanyNameinit
      : usercompany;
  useEffect(() => {
    dispatch(distinictlistAccount({ event_type, cloud }))
      .unwrap()
      .then(({ data }) => {
        const record = JSON.parse(data.getDoc);
        setdropitem(record);
      })
      .catch((err) => {
        console.log(err.message);
      });

    dispatch(listAccount())
      .unwrap()
      .then(({ data }) => {
        if (data.listAccountConfigs.items) {
          const newAccList = data.listAccountConfigs.items?.filter(
            (v, index) => v.cloud_type !== null && v.company_name !== null
          );
          // const newAccList = data.listAccountConfigs.items;
          dispatch(addAccountListMethod(newAccList));
          setRecordlist(newAccList);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });

    dispatch(usermanagerUserList())
      .unwrap()
      .then(({ data }) => {
        if (data.listPlatformUsers) {
          const listUserConfigs = data.listPlatformUsers.items.map((v) =>
            v.company_name.toLowerCase()
          );
          dispatch(setSelectedCompany(listUserConfigs[0].toUpperCase()));
          const uniqueArr = new Set([...listUserConfigs]);
          setCompanyList([...uniqueArr]);
        }
      })
      .catch((err) => {
        // setError(err);
      });
  }, [datachanges]);
  useEffect(() => {
    dispatch(companybasedlistAccount({ event_type_company, toggleText }))
      .unwrap()
      .then(({ data }) => {
        // setPreloader(true);
        const record = JSON.parse(data.getAccountDetails);
        setRecordlist(record);
        setinitialaccount(record);
        // setPreloader(false);
      })

      .catch((err) => {
        console.log(err.message);
      });
  }, [toggleText]);
  const onSubmit = () => {
    setButtonLoader(true);
    const payload = {
      usage_account_id: value.usage_account_id,
      account_name: value.account_name,
      bucket_name: value.bucket_name,
      iam_arn_role: value.iam_arn_role,
      prefix_path: value.prefix_path,
      report_name: value.report_name,
      environment: value.environment,
      status: value.status, 
      cloud_platform: value.cloud_platform,
      customer: value.customer,
      ec2_regions_list: value.ec2_regions_list,
      environment_tag: value.environment_tag,
      application_name_column: value.application_name_column,
      payer_account_id: value.payer_account_id,
      auto_tag_update: value.auto_tag_update,
      event_type: value.event_type,
      external_id: value.external_id,
      files: value.files,
      user_id: user_id, 
    };
    dispatch(accountOnboarding(payload))
      .unwrap()
      .then(({ data }) => {
        const json_decode = JSON.parse(data.getTask);
        const file_encode = JSON.stringify(json_decode.files);
        setValue((prev) => ({
          ...prev,
          files: file_encode,
        }));
        setAccountValidate("configure");
        setValue((prev) => ({
          ...prev,
          event_type: "configure",
        }));
        if (value.event_type === "configure") {
          setUserStatus(true);
          setAlartContent("Account Added successfully done.");
          setFlag(false);
          setAccountValidate("validate");
          setDataChanges(!datachanges);
          setCloseAlert(false);
        }
        setButtonLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setButtonLoader(false);
        setCloseAlert(true);
        seterrorname(err);
      });
    const changeCloseAlert = () => {
      setCloseAlert(false);
    };
  };

  const onSubmitUpdate = () => {
    dispatch(updateAccountDetails(value))
      .unwrap()
      .then(({ data }) => {
        setUpdateFlag(false);

        setDataChanges(!datachanges);
        const newAccList = accountList.filter(
          (v, index) =>
            v.usage_account_id !== data.updateAccountConfig.usage_account_id
        );
        dispatch(
          addAccountListMethod([data.updateAccountConfig, ...newAccList])
        );
        setValue([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteConfirm = () => {
    const account_id = detSelecetedUser.account_id;
    const account_name = detSelecetedUser.account_name;
    var ind = detSelecetedUser.ind;
    dispatch(deleteAccount({ account_id, account_name }))
      .unwrap()
      .then(({ data }) => {
        if (data) {
          const newAccList = accountList.filter((v, index) => index !== ind);
          dispatch(addAccountListMethod([...newAccList]));
          setConfirmDialogStatus(false);
          setAlartContent("Account deleted successfully done");
          setUserStatus(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onDelete = (account_name, usage_account_id, ind) => {
    setDetSelecetedUser({
      account_name: account_name,
      usage_account_id: usage_account_id,
      ind: ind,
    });
    setConfirmDialogStatus(true);
  };

  const onChange = (name, value) => {
    setValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  let dropDownSelected = selectedcompany;
  const [selectedCompanyName, setSelectedCompanyName] =
    useState(dropDownSelected);

  useEffect(() => {}, [dropDownSelected]);
  const selecteCompanyName = (companyname) => {
    setValue((prev) => ({
      ...prev,
      customer: companyname,
    }));

    setSelectedCompanyName(companyname.toUpperCase());

    let selectedName = companyname;

    const filterList = accountList.filter(
      (v) => v.company_name.toLowerCase() === companyname
    );
  };
  const selectedCloud = (cloud) => {
    setSelectedCloudType(cloud);
    dispatch(setSelectedCloud(cloud.toUpperCase()));
  };
  useEffect(() => {
    const newList = recordlist.filter(
      (list) =>
        list.cloud_platform === selectedcloud.toLowerCase() &&
        list.customer === selectedcompany.toLowerCase()
    );
    dispatch(addAccountListMethod(newList));

    setValue((prev) => ({
      ...prev,
      customer: selectedCompanyNameinit.toLowerCase(),
      cloud_platform: selectedcloud.toLowerCase(),
    }));
  }, [selectedcompany, selectedcloud, selectedCompanyNameinit]);
  const confirmClose = () => {
    setConfirmDialogStatus(false);
  };

  const OnboardClick = () => {
    setIsEditable(false);
    const min = 100000;
    const max = 999999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    if (toggleText === "ALL") {
      message.open({
        type: "warning",
        content: "Please select company",
      });
    } else {
      setFlag(true);
      const random_id = "944321";

      setValue((prev) => ({
        ...prev,
        external_id: randomNumber,
      }));
      setValue((prev) => ({
        ...prev,
        files: "",
      }));
      setValue((prev) => ({
        ...prev,
        event_type: "validate",
      }));
      setValue((prev) => ({
        ...prev,
        account_name: "",
        usage_account_id: "",
        environment: "",
        ec2_regions_list: "",
        report_name: "",
        iam_arn_role: "",
        bucket_name: "",
        prefix_path: "",
        environment_tag: "",
        application_name_column: "",
        payer_account_id: "",
        auto_tag_update: "no",
      }));
      setCloseAlert(false);
    }
  };
  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const [selectedCloudType, setSelectedCloudType] = useState("");
  const [filteredAccountList, setFilteredAccountList] = useState([]);

  useEffect(() => {
    let updatedFilteredList = initialaccount;

    if (selectedCloudType !== "") {
      updatedFilteredList = recordlist.filter((list) => {
        return (
          list.cloud_type.toLowerCase() === selectedCloudType.toLowerCase()
        );
      });
    }

    setFilteredAccountList(updatedFilteredList);
  }, [selectedCloudType, accountList, datachanges]);

  const handledrop = (data) => {
    setSelectedCompanyNameinit(data);
  };
  useEffect(() => {
    let updatedFilteredListcom = initialaccount;

    if (selectedCompanyNameinit !== "") {
      updatedFilteredListcom =
        selectedCompanyNameinit === "All"
          ? recordlist
          : recordlist.filter(
              (list) =>
                list.company_name.toLowerCase() ===
                selectedCompanyNameinit.toLowerCase()
            );
    }

    setFilteredAccountList(updatedFilteredListcom);
  }, [selectedCompanyNameinit, initialaccount, datachanges]);
  const handleedit = (e, index, list) => {
    setIsEditable(true);
    setUpdateValue(list);
    const _environment_tag = list.environment_tag ? list.environment_tag : "";
    const _application_tag = list.application_name_column
      ? list.application_name_column
      : "";
    const _account_name = list.account_name ? list.account_name : "";
    const _account_id = list.account_id ? list.account_id : "";
    const _environment = list.environment ? list.environment : "";
    const _ec2_region_list = list.ec2_regions_list ? list.ec2_regions_list : "";
    const _report_name = list.report_name ? list.report_name : "";
    const _iam_arn_role = list.iam_arn_role ? list.iam_arn_role : "";
    const _bucket_name = list.bucket_name ? list.bucket_name : "";
    const _prefix_path = list.prefix_path ? list.prefix_path : "";
    const _company_name = list.company_name ? list.company_name : "";
    const _cloud_type = list.cloud_type ? list.cloud_type : "";
    const _auto_tag_update = list.auto_tag_update ? list.auto_tag_update : "";
    const _payer_account_id = list.payer_account_id
      ? list.payer_account_id
      : "";
    setValue((prev) => ({
      ...prev,
      account_name: _account_name,
      usage_account_id: _account_id,
      environment: _environment,
      ec2_regions_list: _ec2_region_list,
      report_name: _report_name,
      iam_arn_role: _iam_arn_role,
      bucket_name: _bucket_name,
      prefix_path: _prefix_path,
      customer: _company_name,
      cloud_platform: _cloud_type,
      environment_tag: _environment_tag,
      application_name_column: _application_tag,
      payer_account_id: _payer_account_id,
      auto_tag_update: _auto_tag_update,
    }));
    setUpdateFlag(true);
  };
  return (
    <>
      {confirmdialogstatus && (
        <ConfirmDialog
          confirmClose={confirmClose}
          confirmDelete={deleteConfirm}
        />
      )}
      <MDBRow className="AWSOnboardingContent mt-5">
        <MDBRow>
          <MDBCol xl={12}>
            {userstatus ? (
              <Alert onClose={() => setUserStatus(false)} severity="success">
                {alartcontent}
              </Alert>
            ) : null}
          </MDBCol>
          <MDBCol xl={3}>
            <Dropdown className="account-user-dropdown">
              <Dropdown.Toggle
                id="dropdown-basic"
                className="account-user-dropdown-button"
              >
                {toggleText}
              </Dropdown.Toggle>

              <Dropdown.Menu className="account-dropdown-menu">
                {user === "master" ? (
                  <>
                    <Dropdown.Item
                      className="plat_cursor_pointer"
                      onClick={() => handledrop("All")}
                      key="ALL"
                    >
                      ALL
                    </Dropdown.Item>
                    {dropitem.map((data, index) => (
                      <Dropdown.Item
                        className="plat_cursor_pointer"
                        onClick={() => handledrop(data)}
                        key={index}
                      >
                        {data.toUpperCase()}
                      </Dropdown.Item>
                    ))}
                  </>
                ) : (
                  <Dropdown.Item
                    className="plat_cursor_pointer"
                    onClick={() => handledrop(usercompany)}
                    key={usercompany}
                  >
                    {usercompany}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </MDBCol>
          <MDBCol xl={3}>
            <Dropdown className="account-user-dropdown">
              <Dropdown.Toggle
                id="dropdown-basic"
                className="account-user-dropdown-button"
              >
                {selectedcloud}
              </Dropdown.Toggle>
              <Dropdown.Menu className="account-dropdown-menu">
                <Dropdown.Item
                  className="plat_cursor_pointer"
                  onClick={() => selectedCloud("AWS")}
                >
                  AWS
                </Dropdown.Item>
                <Dropdown.Item
                  className="plat_cursor_pointer"
                  onClick={() => selectedCloud("Azure")}
                >
                  Azure
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </MDBCol>
        </MDBRow>
        {permission.accourntwrite ? (
          <MDBCol xl={12} className="mb-4">
            {Offer === "master" && (
              <MDBBtn
                size="lg"
                className="float-right themeBackgroundColor"
                onClick={OnboardClick}
              >
                <Icon icon="ion:add-circle-outline" /> &nbsp; Add Account
              </MDBBtn>
            )}
            {Offer === "1ch_admin" && (
              <MDBBtn
                size="lg"
                className="float-right themeBackgroundColor"
                onClick={OnboardClick}
              >
                <Icon icon="ion:add-circle-outline" /> &nbsp; Add Account
              </MDBBtn>
            )}
            {Offer === "sales" && (
              <MDBBtn
                size="lg"
                className="float-right themeBackgroundColor"
                onClick={OnboardClick}
              >
                <Icon icon="ion:add-circle-outline" /> &nbsp; Add Account
              </MDBBtn>
            )}
          </MDBCol>
        ) : (
          ""
        )}

        {filteredAccountList.map((list, index) => (
          <>
            <MDBCol xl={12}>
              <MDBCard className="mb-3">
                <MDBCardBody>
                  <MDBRow>
                    <MDBCol
                      sm="12"
                      xl={2}
                      md={2}
                      lg={2}
                      className="text-center"
                    >
                      <YourSvg />
                    </MDBCol>
                    <MDBCol sm="12" xl={7} md={7} lg={7}>
                      <MDBRow>
                        <MDBCol sm="12" xl={12} md={12} lg={12}>
                          <div className="d-flex ">
                            <div className="mb-1 label">Account Name</div>
                            <div className="fw-bold">{list.account_name}</div>
                          </div>
                        </MDBCol>
                        <MDBCol sm="12" xl={12} md={12} lg={12}>
                          <div className="d-flex">
                            <div className="mb-1 label">Account ID</div>
                            <div className="fw-bold">{list.account_id}</div>
                          </div>
                        </MDBCol>
                        <MDBCol sm="12" xl={12} md={12} lg={12}>
                          <div className="d-flex">
                            <div className="mb-0 label">IAM Role ARN</div>
                            <div className="fw-bold">{list.iam_arn_role}</div>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>

                    <MDBCol
                      sm="12"
                      xl={1}
                      md={1}
                      lg={1}
                      className="text-center"
                    >
                      <MDBRow>
                        <MDBCol
                          sm="3"
                          xl={1}
                          md={1}
                          lg={1}
                          className="text-center"
                        >
                          <MDBSwitch
                            onClick={(e) => onClickSwitch(e, index, list)}
                            defaultChecked={
                              list.status === "active" ? true : false
                            }
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol
                      sm="12"
                      xl={2}
                      md={2}
                      lg={1}
                      className="text-center"
                    >
                      {permission.accourntwrite ? (
                        <>
                          {" "}
                          <MDBBtn
                            color="link"
                            rippleColor="primary"
                            className="text-reset m-0"
                          >
                            <span
                              className="cursor-pointer"
                              onClick={(e) => handleedit(e, index, list)}
                            >
                              <Icon icon={"carbon:view-filled"} />
                            </span>
                          </MDBBtn>
                          <MDBBtn
                            color="link"
                            rippleColor="primary"
                            className="text-reset m-0"
                          >
                            <span
                              className="cursor-pointer"
                              onClick={() =>
                                onDelete(
                                  list.account_name,
                                  list.usage_account_id,
                                  index
                                )
                              }
                            >
                              <Icon icon="material-symbols:delete" />
                            </span>
                          </MDBBtn>
                        </>
                      ) : (
                        ""
                      )}
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <br />
          </>
        ))}
      </MDBRow>
      <Modal
        flag={flag}
        setCloseModal={setCloseModal}
        buttonloader={buttonloader}
        closealert={closealert}
        errorname={errorname}
        submitbuttonstatus="new"
        onSubmit={onSubmit}
        user_id={user_id}
        accountValidate={accountValidate}
        content={
          <AWSAccountSettingForm
            onChange={onChange}
            isEditable={isEditable}
            value={value}
            user_id={user_id}
            type="new"
          />
        }
      />

      <Modal
        flag={updateflag}
        setCloseModal={setUpdateCloseModal}
        buttonloader={buttonloader}
        onSubmit={onSubmitUpdate}
        accountValidate={accountValidate}
        submitbuttonstatus="update"
        content={
          <AWSAccountSettingForm
            onChange={onChange}
            updatevalue={updatevalue}
            isEditable={isEditable}
            value={value}
            type="update"
          />
        }
      />
    </>
  );
}
export default AWSOnboarding;
