import React from "react";
import ReactApexChart from "react-apexcharts";

// import html2canvas from "html2canvas";
// import ApexChartComponent from "./ApexChartComponent";
function ThreeMonthsSpend({ reportformonth, stdate }) {
  const currentDate = new Date(stdate); // Create a Date object from the string
  const lastSixMonths = [];
  // Check if currentDate is a valid date
  if (!isNaN(currentDate.getTime())) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    for (let i = 0; i < 6; i++) {
      const tempDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1
      );
      lastSixMonths.push(months[tempDate.getMonth()]);
    }
  } else {
    console.error("Invalid date format for startDate:", stdate);
  }
  const with_credit = reportformonth.map((item) =>
    parseFloat(item.with_credit)
  );
  const credtSpend = reportformonth.map((item) =>
    Math.abs(parseFloat(item.credit_spend))
  );

  const aws_consumption = reportformonth.map((item) =>
    parseFloat(item.aws_consumption)
  );
  const projected_spend = reportformonth.map((item) =>
    parseFloat(item.projected_spend)
  );
  const projected_spend_with = projected_spend.map((value) => {
    if (isNaN(value) || value == null) {
      return 0;
    }
    return value;
  });
  const projected = projected_spend_with[0];
  const allValues = [
    ...with_credit,
    ...credtSpend,
    ...aws_consumption,
    ...projected_spend,
  ];
  const allNumbers = allValues.filter((value) => !isNaN(value)).map(Number);
  const maxValue = Math.max(...allNumbers);
  const minValue = Math.min(...allNumbers);
  const series = [
    {
      name: "Projected Spend",
      type: "area",
      data: projected_spend_with.reverse(),
    },
    {
      name: "InVoiced (Inculding Tax)",
      type: "line",
      data: with_credit.reverse(),
    },

    {
      name: "AWS Consumption",
      type: "column",
      data: aws_consumption.reverse(),
    },
    {
      name: "Credits",
      type: "column",
      data: credtSpend.reverse(),
    },
  ];

  const options = {
    chart: {
      id: "threemonthspend",
      height: 350,
      type: "line",
      stacked: "true",

      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        columnWidth: "50%",
        // stacked: true,
        dataLabels: {
          // align: "top",
          // enabled: true,
          // style: {
          //   fontSize: "7px",
          //   colors: ["#3B3B3B"],
          // },
          // formatter: function (val) {
          //   if (typeof val !== "undefined" && val !== null) {
          //     return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          //   }
          //   return "";
          // },
          total: {
            enabled: false,
            style: {
              fontSize: "10px",
              colors: "#0000",
              background: "#FFF",
              // Change the font size for the total label here
            },
            offsetY: -10, // Adjust the position as needed
          },
          // total: {
          //   enabled: true,

          //   align: "right",
          // },
        },
      },
      line: {
        markers: {
          size: 0,
        },
      },
    },
    markers: {
      discrete: [
        {
          seriesIndex: 0,
          dataPointIndex: 0,
          fillColor: "#eee",
          strokeColor: "#eee",
          size: 5,
          shape: "circle",
        },
        {
          seriesIndex: 0,
          dataPointIndex: 1,
          fillColor: "#eee",
          strokeColor: "#eee",
          size: 5,
          shape: "circle",
        },
        {
          seriesIndex: 0,
          dataPointIndex: 2,
          fillColor: "#eee",
          strokeColor: "#eee",
          size: 5,
          shape: "circle",
        },
        {
          seriesIndex: 0,
          dataPointIndex: 3,
          fillColor: "#eee",
          strokeColor: "#eee",
          size: 5,
          shape: "circle",
        },
        {
          seriesIndex: 0,
          dataPointIndex: 4,
          fillColor: "#eee",
          strokeColor: "#eee",
          size: 5,
          shape: "circle",
        },
        {
          seriesIndex: 0,
          dataPointIndex: 5,
          fillColor: "#9C27B0",
          strokeColor: "#fff",
          size: 5,
          shape: "circle",
        },
      ],
    },
    title: {
      text: "Last Six Months Spend ($)",
      align: "center",
      style: {
        fontSize: "15px",
        fontWeight: "500",
        color: "#3B3B3B",
      },
    },
    forecastDataPoints: {
      count: 1,
    },
    yaxis: {
      title: {
        text: "Spend in $",
        style: {
          fontSize: "1rem",
          color: "#3B3B3B",
          width: "1rem",
          fontWeight: "500",
        },
      },
      min: 0,
      max: maxValue,
      tickAmount: 5,
      forceNiceScale: true,
      labels: {
        formatter: function (value) {
          return Math.round(value);
        },
      },
    },

    // colors: ["#40A2B8", "#9C27B0", "#FF6A2F", "#A7EE7C"],
    colors: ["#9C27B0", "#40A2B8", "#FF6A2F", "#A7EE7C"],
    // stroke: {
    //   width: 2,
    //   curve: "smooth",
    // },

    fill: {
      opacity: [0, 0.95, 1],
      // gradient: {
      //   inverseColors: false,
      //   shade: "light",
      //   type: "vertical",
      //   opacityFrom: 0.85,
      //   opacityTo: 0.55,
      //   stops: [0, 100, 100, 100],
      // },
    },
    // dataLabels: {
    //   enabled: false,
    //   style: {
    //     fontSize: "12px",
    //     colors: ["#3B3B3B"],
    //   },
    //   formatter: function (val) {
    //     if (typeof val !== "undefined" && val !== null) {
    //       return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //     }
    //     return "";
    //   },
    // },

    tooltip: {
      enabled: true,
      shared: true, // Enables a shared tooltip
      intersect: false,
      x: {
        // formatter: function (val) {
        //   return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // },
        formatter: function (val, { dataPointIndex }) {
          // val here refers to the x-axis value, but it can be anything meaningful
          return lastSixMonths[dataPointIndex];
        },
      },
      y: {
        formatter: function (val) {
          if (typeof val !== "undefined" && val !== null) {
            if (isNaN(val) || val === 0) {
              return "-";
            } else {
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
          }
          return "";
        },
      },
    },

    stroke: {
      // curve: "smooth",
      width: [0, 2],
    },

    xaxis: {
      categories: lastSixMonths,
    },

    legend: {
      position: "bottom",
    },
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        height={250}
        type="line"
      />
    </div>
  );
}

export default ThreeMonthsSpend;
