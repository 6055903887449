import { Tabs } from "antd";
import React from "react";
import A2CSidebar from "../a2c_rover/A2CSidebar";
import TCO_Analysis from "../assets_a2c/TCO_Analysis";
import RightSizing from "./RightSizing";

function CostAnalysisIndex() {
  const items = [
    {
      key: "1",
      label: `Right Sizing `,
      children: <RightSizing />,
    },
    {
      key: "2",
      label: `TCO Analysis `,
      children: <TCO_Analysis />,
    },
  ];
  return (
    <div>
      <div style={{ display: "flex", height: "100%", minHeight: "400px" }}>
        <A2CSidebar />
        <main className="position-main-div" style={{ padding: 10, flex: 1 }}>
          {" "}
          <div className="cams-content">
            <div className="container-fluid">
              <div className="header-filter d-flex justify-content-between">
                <div className="header-title-a2c-main">Cost Analysis</div>
                <span className="d-flex align-items-end"></span>
              </div>

              <div className="tabss assets-perspective-tabs cost-analysis-tabs-width">
                <Tabs
                  className="cams-tabs"
                  defaultActiveKey="1"
                  items={items}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default CostAnalysisIndex;
