import { createAsyncThunk } from "@reduxjs/toolkit";
import client_new from "./client/dashboardapplonewClinet";
import client from "../apploClient";
import Chatbotclient from "./client/ChatbotClient";
import { useNavigate } from "react-router-dom";
import client_a2c from "./client/A2C_Client";

import {
  TOP_MENU_LIST,
  LIST_ANNUAL_BUDGET,
  FORCOST_SPEND,
  CREDIT_AMOUNT,
  SPEND_DETAILS,
  LIST_SAVINGS_MODEL,
  LIST_APPLICATION_SPEND,
  UNDER_UTILIZED,
  LIST_APPLICATION_BUDGET,
  START_STOP_SCHEDULER_DASHBOARD,
  LIST_SAVINGS_REALIZATION_CHART,
  ADD_SAVINGS_MODEL,
  UPDATE_SAVINGS_MODEL,
  EDIT_SAVING,
  DELETE_SAVINGS_MODEL,
  PROJECTED_SPEND,
  LIST_INSTANCE_RUNTIME,
  LIST_INSTANCE_TRACKING,
  ASSET_SPEND_VM_RECORDS,
  LIST_CHART_ASSET,
  ASSET_SPEND_POPUP_DETAILS,
  GET_USER_ROLE,
  TAGGING_RESOURCES,
  TAGGING_RESOURCES_PAGINATION_COUNT,
  TAGGING_RESOURCES_LIST,
  SPEND_DETAILED_VIEW,
  UPDATE_TAGGING_DETAILS,
  CHART_BOT_RESPONSE,
  LIST_SPEND_BY_DATE,
  RESOURCES_SPEND_DATE_COUNT,
  RESOURCES_SPEND_DATE_LIST,
  SERVICES_SPEND_DATE_LIST,
  SERVICES_SPEND_MONTH_DATE_LIST,
  CHECK_CHATBOT_LIMIT,
  ASSET_SPEND_VM_ENVIRONMENT_RECORDS,
  ASSET_SPEND_VM_CLOUD_RECORDS,
  ASSET_SPEND_VM_APPLICATION_RECORDS,
  ASSET_SPEND_VM_RESORCES_RECORDS,
  LIST_STOP_INSTANCE,
  LIST_CLOUD_BUDGET,
  ADD_CLOUD_BUDGET,
  UPDATE_CLOUD_BUDGET,
  DELETE_CLOUD_BUDGET,
  GET_TRACKING_SAVINGS,
  EDIT_SAVINGS_UNREALISED,
  UPDATE_SAVINGS_UNREALISED,
  GET_REALISED_SAVINGS,
  LIST_SERVICE_SPEND,
  SPEND_SERVICE_ENVIRONMENT,
  K8_CLOUD_QUERY,
  K8_APPLICATION_RECORDS,
  K8_RESOURCE_RECORDS,
  FORCOST_SPEND_DETAILS,
  WEEKLYSPENDBYSERVICELEVEL,
  LIST_SAVINGS_OPPURTUNITY,
  DOWNLOAD_S3_QUERY,
  SAVING_SUMMARY_TABLES,
  DATED_DEFERRED_RECORDS,
  LIST_UNDER_UTILIZED,
  DEFERRED_SUMMARY,
  ASSET_SPEND_DB_ENVIRONMENT_RECORDS,
  ASSET_SPEND_DB_CLOUD_RECORDS,
  ASSET_SPEND_DB_APPLICATION_RECORDS,
  ASSET_SPEND_DB_RESOURCE_RECORDS,
  SEARCH_RESOURCE_NAME,
  RESOURCE_LIST_API,
} from "./graphql/CostimizeV2Graphql";
import { useErrorNavigation } from "../custom_hook/CustomHook";

export const getTopMenuList = createAsyncThunk(
  // action type string
  "menu/list",
  // callback function
  async (data, { rejectWithValue }) => {
    const companyname = data.companyName;
    const month = data.selectedTopdate;
    const cloud = data.cloud_list;
    try {
      const envList = await client_new.query({
        query: TOP_MENU_LIST,
        variables: {
          company_name: companyname,
          month: month,
          cloud: cloud,
        },
      });
      return envList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listAnnualBudget = createAsyncThunk(
  // action type string
  "dashboard/budget/annual/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await client_new.mutate({
        mutation: LIST_ANNUAL_BUDGET,
        variables: {
          company_name: data.companyName,
          // cloud: data.cloud,
        },
      });
      return listbudget;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listCloudBudget = createAsyncThunk(
  // action type string
  "dashboard/budget/annual/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const cloudBudget = await client_new.mutate({
        mutation: LIST_CLOUD_BUDGET,
        variables: {
          company_name: data.companyNameLower,
          // cloud: data.cloud,
        },
      });
      return cloudBudget;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addCloudBudget = createAsyncThunk(
  // action type string
  "dashboard/budget/cloud/add",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addCloudBudget = await client_new.mutate({
        mutation: ADD_CLOUD_BUDGET,
        variables: {
          company_name: data.data.company_name,
          budget_amount: data.data.budget_amount,
          budget_month: data.data.budget_month,
          budget_year: data.data.budget_year,
          cloud: data.data.cloud,
        },
      });
      return addCloudBudget;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const editCloudBudget = createAsyncThunk(
  // action type string
  "dashboard/budget/cloud/add",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addCloudBudget = await client_new.mutate({
        mutation: UPDATE_CLOUD_BUDGET,
        variables: {
          company_name: data.data.company_name,
          budget_amount: data.data.budget_amount,
          budget_month: data.data.budget_month,
          budget_year: data.data.budget_year,
          budget_id: data.data.id,
          cloud: data.data.cloud,
        },
      });
      return addCloudBudget;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteCloudBudget = createAsyncThunk(
  // action type string
  "dashboard/budget/cloud/delete",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const deleteCloudBudget = await client_new.mutate({
        mutation: DELETE_CLOUD_BUDGET,
        variables: {
          company_name: data.data.company_name,
          budget_id: data.data.budget_id,
          cloud: data.data.cloud,
        },
      });
      return deleteCloudBudget;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getForecastSpend = createAsyncThunk(
  // action type string
  "dashboard/spend/forecast",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.query({
        query: FORCOST_SPEND,
        variables: {
          company_name: data.companyName,
          month: data.projected_date,
          account: data.apivalue.account,
          cloud: data.apivalue.cloud,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getForecastSpendDetails = createAsyncThunk(
  // action type string
  "dashboard/spend/forecast",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.query({
        query: FORCOST_SPEND_DETAILS,
        variables: {
          company_name: data.companyName,
          month: data.projected_date,
          account: data.apivalue.account,
          cloud: data.apivalue.cloud,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCreditAmount = createAsyncThunk(
  // action type string
  "dashboard/summarydashboard",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.query({
        query: CREDIT_AMOUNT,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate_new,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getSpendDetails = createAsyncThunk(
  // action type string
  "dashboard/spend",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log("getspend deatils",data)
    try {
      const spend = await client_new.query({
        query: SPEND_DETAILS,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate_new,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
        },
      });
      return spend;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listRecommendation = createAsyncThunk(
  // action type string
  "dashboard/recommendation/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const list_rec = await client_new.mutate({
        mutation: LIST_SAVINGS_MODEL,
        variables: {
          company_name: data.input.company_name,
          account_name: data.input.account,
          application: data.input.application,
          cloud: data.input.cloud,
          db: data.input.db,
          environment: data.input.environment,
          infra: data.input.infra,
          month: data.input.date,
          os: data.input.os,
          service: data.input.services,
        },
      });
      return list_rec;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const savingsummarytable = createAsyncThunk(
  // action type string
  "dashboard/recommendation/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const list_rec = await client_new.mutate({
        mutation: SAVING_SUMMARY_TABLES,
        variables: {
          company_name: data.companyName,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          db: data.apivalue.db,
          environment: data.apivalue.environment,
          infra: data.apivalue.infra,
          month: data.apivalue.date,
          // month: "all",
          os: data.apivalue.os,
          service: data.apivalue.services,
        },
      });
      return list_rec;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listApplicationSpend = createAsyncThunk(
  // action type string
  "dashboard/application/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listapplication = await client_new.mutate({
        mutation: LIST_APPLICATION_SPEND,
        variables: {
          company_name: data.companyName,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          db: data.apivalue.db,
          environment: data.apivalue.environment,
          infra: data.apivalue.infra,
          month: data.apivalue.date,
          os: data.apivalue.os,
          service: data.apivalue.services,
        },
      });
      return listapplication;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listunderutilized = createAsyncThunk(
  // action type string
  "dashboard/uderutilized",
  // callback function
  async (data, { rejectWithValue }) => {
    // console.log(data);
    try {
      const aasList = await client_new.query({
        query: LIST_UNDER_UTILIZED,
        variables: {
          company_name: data.company_name,
          month: data.selectedTopdate,
          cloud: data.cloud,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUderUtilized = createAsyncThunk(
  // action type string
  "dashboard/uderutilized",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.query({
        query: UNDER_UTILIZED,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate,
          cloud: data.apivalue.cloud,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listApplicationBudget = createAsyncThunk(
  // action type string
  "dashboard/budget/application/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: LIST_APPLICATION_BUDGET,
        variables: {
          company_name: data.input.company_name,
          account_name: data.input.account_name,
          application: data.input.application,
          budget_year: data.input.budget_year,
          cloud: data.input.cloud,
          environment: data.input.environment,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getStartStopScheduler = createAsyncThunk(
  // action type string
  "dashboard/startstopscheduler",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.query({
        query: START_STOP_SCHEDULER_DASHBOARD,
        variables: {
          company_name: data.companyName,
          month: data.yesterdayDate,
          cloud: data.apivalue.cloud,
          account: data.apivalue.account,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listRealizationChart = createAsyncThunk(
  // action type string
  "dashboard/instance/tracking/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: LIST_SAVINGS_REALIZATION_CHART,
        variables: {
          account_name: data.apivalue.account_name,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.apivalue.company_name,
          environment: data.apivalue.environment,
          month: data.apivalue.month,
          os: data.apivalue.os,
          db: data.apivalue.db,
          infra: data.apivalue.infra,
          service: data.apivalue.service,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const insertRecommendation = createAsyncThunk(
  // action type string
  "dashboard/recommendation/insert",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: ADD_SAVINGS_MODEL,
        variables: {
          application: data.inputs.application,
          environment: data.inputs.environment,
          service: data.inputs.service,
          account: data.inputs.account,
          company_name: data.inputs.company_name,
          commitment: data.inputs.commitment,
          current_configuration: data.inputs.current_configuration,
          lost_opportunity: data.inputs.lost_opportunity,
          potential_savings: data.inputs.potential_savings,
          realised_savings: data.inputs.realised_savings,
          recommendation_rule: data.inputs.recommendation_rule,
          recommended_configuration: data.inputs.recommended_configuration,
          report_month: data.inputs.report_month,
          resource_id: data.inputs.resource_id,
          resource_name: data.inputs.resource_name,
          resource_sub_category: data.inputs.resource_sub_category,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateRecommendation = createAsyncThunk(
  // action type string
  "dashboard/recommendation/update",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: UPDATE_SAVINGS_MODEL,
        variables: {
          application: data.inputs.application,
          environment: data.inputs.environment,
          service: data.inputs.service,
          account: data.inputs.account,
          company_name: data.inputs.company_name,
          commitment: data.inputs.commitment,
          current_configuration: data.inputs.current_configuration,
          lost_opportunity: data.inputs.lost_opportunity,
          potential_savings: data.inputs.potential_savings,
          realised_savings: data.inputs.realised_savings,
          recommendation_rule: data.inputs.recommendation_rule,
          recommended_configuration: data.inputs.recommended_configuration,
          report_month: data.inputs.report_month,
          resource_id: data.inputs.resource_id,
          resource_name: data.inputs.resource_name,
          resource_sub_category: data.inputs.resource_sub_category,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editsaving = createAsyncThunk(
  // action type string
  "dashboard/recommendation/update",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: EDIT_SAVING,
        variables: {
          application: data.inputs.applications,
          environment: data.inputs.environments,
          service: data.inputs.services,
          account_name: data.inputs.account_names,
          company_name: data.companyName,
          commitment: data.inputs.commitment,
          current_configuration: data.inputs.current_configuration,
          lost_opportunity: data.inputs.lost_opportunity,
          potential_savings: data.inputs.potential_savings,
          realised_savings: data.inputs.realized_savings,
          recommendation_rule: data.inputs.recommendation_rule,
          recommended_configuration: data.inputs.recommended_configuration,
          report_month: data.inputs.report_month,
          resource_id: data.inputs.resource_id,
          resource_name: data.inputs.resource_name,
          resource_sub_category: data.inputs.resource_sub_category,
          emailid: data.values.email,
          username: data.values.username,
          target_date: data.target_date,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteRecommendation = createAsyncThunk(
  // action type string
  "dashboard/recommendation/update",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const deleteSavings = await client_new.mutate({
        mutation: DELETE_SAVINGS_MODEL,
        variables: {
          companyName: data.companyName,
          deleteid: data.deleteid,
        },
      });
      return deleteSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getProjectedSpend = createAsyncThunk(
  // action type string
  "dashboard/projectedspend",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.query({
        query: PROJECTED_SPEND,
        variables: {
          company_name: data.companyName,
          month: data.projected_date,
          account: data.apivalue.account,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listInstanceRuntime = createAsyncThunk(
  // action type string
  "dashboard/instance/runtime/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: LIST_INSTANCE_RUNTIME,
        variables: {
          account_name: data.apivalue.account_name,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.apivalue.company_name,
          environment: data.apivalue.environment,
          month: data.apivalue.month,
          os: data.apivalue.os,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listInstanceTracking = createAsyncThunk(
  // action type string
  "dashboard/instance/tracking/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: LIST_INSTANCE_TRACKING,
        variables: {
          account_name: data.apivalue.account_name,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.apivalue.company_name,
          environment: data.apivalue.environment,
          month: data.apivalue.month,
          os: data.apivalue.os,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listStopInstance = createAsyncThunk(
  // action type string
  "dashboard/instance/stoped/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: LIST_STOP_INSTANCE,
        variables: {
          account_name: data.apivalue.account_name,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.apivalue.company_name,
          environment: data.apivalue.environment,
          month: data.apivalue.month,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAssetSpendVMRecords = createAsyncThunk(
  // action type string
  "dashboard/instance/tracking/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: ASSET_SPEND_VM_RECORDS,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
          first_month: data.apivalue.first_month,
          second_month: data.apivalue.second_month,
          thired_month: data.apivalue.thired_month,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAssetSpendVMEnvironmentRecords = createAsyncThunk(
  // action type string
  "dashboard/vm/env/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client.mutate({
        mutation: ASSET_SPEND_VM_ENVIRONMENT_RECORDS,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
          // first_month: data.apivalue.first_month,
          // second_month: data.apivalue.second_month,
          // thired_month: data.apivalue.thired_month,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAssetSpendVMCloudRecords = createAsyncThunk(
  // action type string
  "dashboard/vm/env/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client.mutate({
        mutation: ASSET_SPEND_VM_CLOUD_RECORDS,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
          // first_month: data.apivalue.first_month,
          // second_month: data.apivalue.second_month,
          // thired_month: data.apivalue.thired_month,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAssetSpendVMApplicationRecords = createAsyncThunk(
  // action type string
  "dashboard/vm/app/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client.mutate({
        mutation: ASSET_SPEND_VM_APPLICATION_RECORDS,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
          // first_month: data.apivalue.first_month,
          // second_month: data.apivalue.second_month,
          // thired_month: data.apivalue.thired_month,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getSavingsTracking = createAsyncThunk(
  // action type string
  "dashboard/savings/tracking/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const savingsTracking = await client_new.mutate({
        mutation: GET_TRACKING_SAVINGS,
        variables: {
          company_name: data.input.company_name,
          month: data.input.date,
          environment: data.input.environment,
          clouds: data.input.cloud,
          account_name: data.input.account,
          application: data.input.application,
          service: data.input.services,
          db: data.input.db,
          os: data.input.os,
          infra: data.input.infra,
        },
      });
      return savingsTracking;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const dateddeferredrecords = createAsyncThunk(
  // action type string
  "dashboard/savings/tracking/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const savingsTracking = await client_new.mutate({
        mutation: DATED_DEFERRED_RECORDS,
        variables: {
          company_name: data.input.company_name,
          // month: data.apivalue.date,
          month: "all",
          environment: data.input.environment,
          clouds: data.input.cloud,
          account_name: data.input.account,
          application: data.input.application,
          service: data.input.services,
          db: data.input.db,
          os: data.input.os,
          infra: data.input.infra,
        },
      });
      return savingsTracking;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deferredsummary = createAsyncThunk(
  // action type string
  "dashboard/savings/tracking/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const savingsTracking = await client_new.mutate({
        mutation: DEFERRED_SUMMARY,
        variables: {
          company_name: data.companyName,
          // month: data.apivalue.date,
          month: "all",
          environment: data.apivalue.environment,
          clouds: data.apivalue.cloud,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
        },
      });
      return savingsTracking;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAssetSpendVMResorcesRecords = createAsyncThunk(
  // action type string
  "dashboard/vm/app/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client.mutate({
        mutation: ASSET_SPEND_VM_RESORCES_RECORDS,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
          first_month: data.apivalue.first_month,
          second_month: data.apivalue.second_month,
          thired_month: data.apivalue.thired_month,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAssetSpendDetailsView = createAsyncThunk(
  // action type string
  "dashboard/instance/tracking/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: ASSET_SPEND_POPUP_DETAILS,
        variables: {
          customer: data.companyName,
          resourceid: data.resourceid,
          start_date: data.firstDate,
          end_date: data.lastDate,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getMemberRole = createAsyncThunk(
  // action type string
  "user/role",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const role = await client_new.mutate({
        mutation: GET_USER_ROLE,
        variables: {},
      });
      return role;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const taggingResources = createAsyncThunk(
  // action type string
  "user/tagging",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const role = await client_new.mutate({
        mutation: TAGGING_RESOURCES,
        variables: {
          company_name: data.input.company_name,
          account_name: data.input.account_name,
          application: data.input.application,
          cloud: data.input.cloud,
          environment: data.input.environment,
          services: data.input.services,
          os: data.input.os,
          db: data.input.db,
          infra: data.input.infra,
          month: data.input.month,
        },
      });
      return role;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getPaginationCount = createAsyncThunk(
  // action type string
  "user/pagination/count",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const role = await client_new.mutate({
        mutation: TAGGING_RESOURCES_PAGINATION_COUNT,
        variables: {
          tagging_details: data.tagging_details,
          service_name: data.service_name,
          tag_category: data.tag_category,
          companyName: data.companyName,
          date: data.date,
          count: data.count,
          account_name: data.account_name,
          application: data.application,
          cloud: data.cloud,
          environment: data.environment,
          infra: data.infra,
          os: data.os,
          db: data.db,
        },
      });
      return role;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTagginglist = createAsyncThunk(
  // action type string
  "user/tagging/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const role = await client_new.mutate({
        mutation: TAGGING_RESOURCES_LIST,
        variables: {
          companyName: data.companyName,
          tagging_details: data.tagging_details,
          service_name: data.service_name,
          date: data.date,
          page: data.page,
          tag_category: data.tag_category,
          count: data.count,
          account_name: data.account_name,
          application: data.application,
          cloud: data.cloud,
          environment: data.environment,
          infra: data.infra,
          os: data.os,
          db: data.db,
        },
      });
      return role;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getSpendDetailedView = createAsyncThunk(
  // action type string
  "dashboard/spend",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const spend = await client_new.query({
        query: SPEND_DETAILED_VIEW,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate_new,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
        },
      });
      return spend;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateTaggingDetails = createAsyncThunk(
  // action type string
  "dashboard/spend",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const spend = await client_new.mutate({
        mutation: UPDATE_TAGGING_DETAILS,
        variables: {
          resources: data.resources,
          // resources: JSON.stringify(data.resources),
        },
      });
      return spend;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listSpendbyDate = createAsyncThunk(
  // action type string
  "chartbot/responce",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await client_new.mutate({
        mutation: LIST_SPEND_BY_DATE,
        variables: {
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.companyName,
          db: data.apivalue.db,
          environment: data.apivalue.environment,
          infra: data.apivalue.infra,
          month: data.apivalue.date,
          os: data.apivalue.os,
          service: data.apivalue.services,
        },
      });
      return listbudget;
    } catch (error) {
      if (
        error.message === "Response not successful: Received status code 500"
      ) {
        const navigate = useNavigate();
        return navigate("/signin");
      }

      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const countResourcesSpendDate = createAsyncThunk(
  // action type string
  "resources/responce",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await client_new.mutate({
        mutation: RESOURCES_SPEND_DATE_COUNT,
        variables: {
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.companyName,
          db: data.apivalue.db,
          environment: data.apivalue.environment,
          infra: data.apivalue.infra,
          month: data.apivalue.date,
          os: data.apivalue.os,
          service: data.apivalue.services,
          selectedservice: data.apivalue.selectedservice,
        },
      });
      return listbudget;
    } catch (error) {
      if (
        error.message === "Response not successful: Received status code 500"
      ) {
        const navigate = useNavigate();
        return navigate("/signin");
      }

      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listResourcesSpendDate = createAsyncThunk(
  // action type string
  "resources/responce",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await client_new.mutate({
        mutation: RESOURCES_SPEND_DATE_LIST,
        variables: {
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.companyName,
          db: data.apivalue.db,
          environment: data.apivalue.environment,
          infra: data.apivalue.infra,
          month: data.apivalue.date,
          os: data.apivalue.os,
          service: data.apivalue.services,
          page_number: data.apivalue.page,
        },
      });
      return listbudget;
    } catch (error) {
      if (
        error.message === "Response not successful: Received status code 500"
      ) {
        const navigate = useNavigate();
        return navigate("/signin");
      }

      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listServiceSpendDate = createAsyncThunk(
  // action type string
  "service/responce",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await client_new.mutate({
        mutation: SERVICES_SPEND_DATE_LIST,
        variables: {
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.companyName,
          db: data.apivalue.db,
          environment: data.apivalue.environment,
          infra: data.apivalue.infra,
          month: data.apivalue.date,
          os: data.apivalue.os,
          service: data.apivalue.services,
        },
      });
      return listbudget;
    } catch (error) {
      if (
        error.message === "Response not successful: Received status code 500"
      ) {
        const navigate = useNavigate();
        return navigate("/signin");
      }

      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listServiceSpendbyDate = createAsyncThunk(
  // action type string
  "service/responce",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await client_new.mutate({
        mutation: SERVICES_SPEND_MONTH_DATE_LIST,
        variables: {
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          company_name: data.companyName,
          db: data.apivalue.db,
          environment: data.apivalue.environment,
          infra: data.apivalue.infra,
          month: data.apivalue.date,
          os: data.apivalue.os,
          service: data.apivalue.services,
        },
      });
      return listbudget;
    } catch (error) {
      if (
        error.message === "Response not successful: Received status code 500"
      ) {
        const navigate = useNavigate();
        return navigate("/signin");
      }

      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getChartBotResponse = createAsyncThunk(
  // action type string
  "chartbot/responce",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await Chatbotclient.mutate({
        mutation: CHART_BOT_RESPONSE,
        variables: {
          message: data.message,
          companyName: data.companyname,
          date: data.date,
          user_name: data.user_name,
          token: data.token,
        },
      });
      return listbudget;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const checkChatbotLimit = createAsyncThunk(
  // action type string
  "chartbot/responce",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await Chatbotclient.mutate({
        mutation: CHECK_CHATBOT_LIMIT,
        variables: {
          date: data.date,
          user_name: data.user_name,
          companyName: data.companyname,
        },
      });
      return listbudget;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listAssetchart = createAsyncThunk(
  // action type string
  "assets/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listbudget = await client.mutate({
        mutation: LIST_CHART_ASSET,
        variables: {
          application: data.apivalue.application,
          account_name: data.apivalue.account_name,
          company_name: data.companyName,
          cloud: data.apivalue.cloud,
          infra: data.apivalue.infra,
          environment: data.apivalue.environment,
          os: data.apivalue.os,
          report_month: data.selectedTopdate,
          service: data.apivalue.services,
          resourcename: data.apivalue.resourcename,
          resourceid: data.apivalue.resourceid,
          // date: data.date,
          // user_name: data.user_name,
          // companyName: data.companyname,
        },
      });
      return listbudget;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const listunrealisedtotracking = createAsyncThunk(
  // action type string
  "unrealised/table",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const editsaveunrealised = await client_new.mutate({
        mutation: EDIT_SAVINGS_UNREALISED,
        variables: {
          account_name: data.inputs.account_names,
          application: data.inputs.applications,
          commitment: data.inputs.commitment,
          company_name: data.companyName,
          current_configuration: data.inputs.current_configuration,
          email_address: data.values.email,
          environment: data.inputs.environments,
          potential_savings: data.inputs.potential_savings,
          realised_savings: "0",
          recommendation_rule: data.inputs.recommendation_rule,
          report_month: data.inputs.report_month,
          recommended_configuration: data.inputs.recommended_configuration,
          resource_id: data.inputs.resource_id,
          resource_name: data.inputs.resource_name,
          resource_sub_category: data.inputs.resource_sub_category,
          service: data.inputs.services,
          targetdate: data.target_date,
          username: data.values.username,
          status: data.values.reason ? "Deferred" : "Task Assigned",
          cloud: data.inputs.clouds,
          reason: data.values.reason ? data.values.reason : "Null",
        },
      });
      return editsaveunrealised;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// UPDATE_SAVINGS_UNREALISED
export const updaterealisedtable = createAsyncThunk(
  // action type string
  "unrealised/tableupdate",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const editsaveunrealised = await client_new.mutate({
        mutation: UPDATE_SAVINGS_UNREALISED,
        variables: {
          account_name: data.input.account_names,
          application: data.input.application,
          commitment: data.input.commitment,
          company_name: data.input.company_name,
          current_configuration: data.input.current_configuration,
          email: data.input.email,
          environment: data.input.environment,
          potential_savings: data.input.potential_savings,
          realised_savings: "0",
          recommendation_rule: data.input.recommendation_rule,
          report_month: data.input.report_month,
          recommended_configuration: data.input.recommended_configuration,
          resource_id: data.input.resource_id,
          resource_name: data.input.resource_name,
          resource_sub_category: data.input.resource_sub_category,
          service: data.input.service,
          targetdate: data.input.targetdate,
          username: data.input.username,
          status: data.input.status,
          // cloud:"aws"
          cloud: data.input.cloud,
        },
      });
      return editsaveunrealised;
    } catch (error) {
      console.log(error);
      useErrorNavigation(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getSavingsRealised = createAsyncThunk(
  // action type string
  "dashboard/savings/realised/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const savingsRealised = await client_new.mutate({
        mutation: GET_REALISED_SAVINGS,
        variables: {
          company_name: data.input.company_name,
          month: data.input.date,
          environment: data.input.environment,
          cloud: data.input.cloud,
          account_name: data.input.account,
          application: data.input.application,
          service: data.input.services,
          db: data.input.db,
          os: data.input.os,
          infra: data.input.infra,
        },
      });
      return savingsRealised;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const listservicemonth = createAsyncThunk(
  // action type string
  "dashboard/application/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const listservice = await client_new.mutate({
        mutation: LIST_SERVICE_SPEND,
        variables: {
          company_name: data.companyName,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          db: data.apivalue.db,
          environment: data.apivalue.environment,
          month: data.apivalue.month,
          os: data.apivalue.os,
        },
      });

      return listservice;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const SpendService = createAsyncThunk(
  // action type string
  "dashboard/spend",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const spendenvironemnt = await client_new.query({
        query: SPEND_SERVICE_ENVIRONMENT,
        variables: {
          company_name: data.companyName,
          month: data.monthdate,
          cloud: data.apivalue.cloud,
          account: data.apivalue.account,
          application: data.apivalue.application,
          environment: data.apivalue.environment,
        },
      });
      return spendenvironemnt;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// k8 apis
export const getK8SpendCloudRecords = createAsyncThunk(
  // action type string
  "dashboard/vm/env/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.query({
        query: K8_CLOUD_QUERY,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          infra: data.apivalue.infra,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getK8Applications = createAsyncThunk(
  // action type string
  "dashboard/vm/app/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: K8_APPLICATION_RECORDS,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          infra: data.apivalue.infra,
          // first_month: data.apivalue.first_month,
          // second_month: data.apivalue.second_month,
          // thired_month: data.apivalue.thired_month,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getK8_ResourcenameRecords = createAsyncThunk(
  // action type string
  "dashboard/vm/app/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: K8_RESOURCE_RECORDS,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          infra: data.apivalue.infra,
          // first_month: data.apivalue.first_month,
          // second_month: data.apivalue.second_month,
          // thired_month: data.apivalue.thired_month,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getWeeklySpendService = createAsyncThunk(
  // action type string
  "assessdcspread/chart",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const aasList = await client_new.mutate({
        mutation: WEEKLYSPENDBYSERVICELEVEL,
        variables: {
          company_name: data.company_name,
          start_date: data.start_date,
          end_date: data.end_date,
        },
      });
      return aasList;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getListSavingOppurtunitySummary = createAsyncThunk(
  // action type string
  "dashboard/recommendation/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const list_rec = await client_new.mutate({
        mutation: LIST_SAVINGS_OPPURTUNITY,
        variables: {
          company_name: data.companyName,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          cloud: data.apivalue.cloud,
          db: data.apivalue.db,
          environment: data.apivalue.environment,
          infra: data.apivalue.infra,
          month: data.apivalue.date,
          os: data.apivalue.os,
          service: data.apivalue.services,
        },
      });
      return list_rec;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const downloads3file = createAsyncThunk(
  // action type string
  "dashboard/recommendation/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const list_rec = await client_new.query({
        query: DOWNLOAD_S3_QUERY,
        variables: {
          company_name: data.companyName,
          report_month: data.prevMonth,
          year: data.yearvalue,
        },
      });
      return list_rec;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getAssetSpendDBEnvironmentRecords = createAsyncThunk(
  // action type string
  "dashboard/db/env/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: ASSET_SPEND_DB_ENVIRONMENT_RECORDS,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getAssetSpendDBCloudRecords = createAsyncThunk(
  // action type string
  "dashboard/db/cloud/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: ASSET_SPEND_DB_CLOUD_RECORDS,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getAssetSpendDBApplicationRecords = createAsyncThunk(
  // action type string
  "dashboard/db/app/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client_new.mutate({
        mutation: ASSET_SPEND_DB_APPLICATION_RECORDS,
        variables: {
          company_name: data.companyName,
          month: data.selectedTopdate,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getAssetSpendDBResourceRecords = createAsyncThunk(
  // action type string
  "dashboard/db/env/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const addSavings = await client.mutate({
        mutation: ASSET_SPEND_DB_RESOURCE_RECORDS,
        variables: {
          company_name: data.companyName,
          report_month: data.selectedTopdate,
          environment: data.apivalue.environment,
          cloud: data.apivalue.cloud,
          account_name: data.apivalue.account,
          application: data.apivalue.application,
          service: data.apivalue.services,
          db: data.apivalue.db,
          os: data.apivalue.os,
          infra: data.apivalue.infra,
        },
      });
      return addSavings;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const searchresource = createAsyncThunk(
  // action type string
  "dashboard/recommendation/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const searchname = await client_a2c.query({
        query: SEARCH_RESOURCE_NAME,
        variables: {
          customer: data.companyName,
          billingperiodstartdate: data.selectedTopdate,
        },
      });
      return searchname;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const resourceassetspend = createAsyncThunk(
  // action type string
  "dashboard/recommendation/list",
  // callback function
  async (data, { rejectWithValue }) => {
    try {
      const selectresource = await client_new.query({
        query: RESOURCE_LIST_API,
        variables: {
          billingperiodstartdate: data.selectedTopdate,
          customer: data.companyName,
          resourcename: data.searchValue,
        },
      });
      return selectresource;
    } catch (error) {
      useErrorNavigation(error);
      console.log(error);
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
