import { memo, useEffect, useState } from "react";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import UnrealizedForm from "./UnrealizedForm";

import { Table, Typography, Tooltip, Spin, Tag, Popconfirm } from "antd";
import {
  numberFormat,
  checkRolePermission,
} from "../../custom_hook/CustomHook";
import TrackingForm from "./TrackingForm";

const { Text } = Typography;

const TrackingLayout = (props) => {
  const { selectedCloud } = useSelector((state) => state.constimize);
  const [drawerstatus, setDrawerStatus] = useState(false);
  const [tablerecords, setTableRecords] = useState([]);
  const [sum, setSum] = useState(0);
  const [columns, setColumns] = useState([]);
  const [recommendationlist, setRecommendationList] = useState([]);
  const [formType, setFormType] = useState("");
  const [oldUpdateRecords, setOldUpdateRecords] = useState({});
  const [permission, setPermission] = useState({
    savingswrite: checkRolePermission("savings_model")
      ? checkRolePermission("savings_model").write
      : 0,
  });
  // console.log(props.records)
  useEffect(() => {
    const table = props.records.map((datas) => {
      return {
        account: datas.account_names,
        applications: datas.applications,
        environment: datas.environments,
        recommendation_type: datas.recommendation_rule,
        resource_name: datas.resource_name,
        potential_savings: datas.potential_savings,
        status: datas.status,
      };
    });
    setTableRecords(props.records);
    const record = props.records.map((v) => parseFloat(v.potential_savings)); // Convert to numbers
    const sum = record.reduce((total, value) => total + value, 0);
    let n = sum.toFixed(2);
    setSum(n);
  }, [props, selectedCloud]);

  useEffect(() => {
    var value_list = [];
    var keyvalue = 1;
    const tablelist = props.records.map((data) => {
      if (
        data.realised_savings !== null &&
        Math.round(data.realised_savings) !== 0
      ) {
        const lost_opportunity = data.potential_savings - data.realised_savings;
        value_list.push({
          key: keyvalue,
          date: data.report_month,
          account: data.account_names,
          application: data.applications,
          environment: data.environments,
          services: data.services,
          resource_sub_category: data.resource_sub_category,
          recommendation_type: data.recommendation_rule,
          resource_id: data.resource_id,
          resource_name: data.resource_name,
          current_configuration: data.current_configuration,
          recommended_configuration: data.recommended_configuration,
          commitment: data.commitment,
          potential_savings: Number(data.potential_savings),
          realized_savings: Number(data.realised_savings),
          lost_opportunity: Number(lost_opportunity),
          action: "",
          type: "data",
        });
        keyvalue++;
      }
    });
    //recommendation type
    const recommendation_list = value_list.map((v) => v.recommendation_type);
    const recommendation_set = new Set([...recommendation_list]);
    const recommendation = [...recommendation_set];
    var recom_list = [];
    recommendation.map((value) => {
      recom_list.push({
        text: value,
        value: value,
      });
    });
    setRecommendationList(recom_list);
    const potential = value_list.reduce(
      (a, v) => (a = +a + +v?.potential_savings),
      0
    );
    // console.log(potential)
    const realised = value_list.reduce(
      (a, v) => (a = +a + +v?.realized_savings),
      0
    );
    // setTableRecords(value_list);
    // console.log(value_list)

    // const record = value_list.map((v) => v.realized_savings);
    // const record = tablerecords.map((v) => v.potential_savings);
    // console.log(record)
    // const sumtotal = record.reduce((a, b) => a + b, 0);
    // setSum(sumtotal)
    const record = tablerecords.map((v) => parseFloat(v.potential_savings)); // Convert to numbers
    const sum = record.reduce((total, value) => total + value, 0);

    setSum(record);
    const getStatusTagColor = (status) => {
      switch (status) {
        case "Task Assigned":
          return "#FF0000";
        case "Analysis Pending":
          return "#FFA500";
        case "Analysis Completed":
          return "#2db7f5";
        case "Implementation Planned":
          return "#0039a6";
        case "Implementation Completed":
          return "#006400";
        case "Not Implemented":
          return "# 7F00FF";
        default:
          return "default";
      }
    };
    props.isdefer
      ? setColumns([
          {
            title: "Account",
            dataIndex: "account_names",
            key: "account_names",
            width: "13%",
            sorter: (a, b) => {
              return a.account > b.account ? -1 : 1;
            },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 10) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 10)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          {
            title: "Application",
            dataIndex: "applications",
            key: "applications",
            width: "13%",
            // fixed: "left",
            sorter: (a, b) => {
              return a.applications > b.applications ? -1 : 1;
            },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 10) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 10)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          {
            title: "Environment",
            dataIndex: "environments",
            key: "environments",
            width: "13%",
            sorter: (a, b) => {
              return a.environments > b.environments ? -1 : 1;
            },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 10) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 10)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          // {
          //   title: "Recommendation Type",
          //   dataIndex: "recommendation_rule",
          //   key: "recommendation_rule",
          //   filters: recom_list,
          //   onFilter: (value, record) =>
          //     record.recommendation_rule.indexOf(value) === 0,
          //   sorter: (a, b) => {
          //     return a.recommendation_rule > b.recommendation_rule ? -1 : 1;
          //   },
          // },
          {
            title: "Resource Name",
            key: "resource_name",
            width: "13%",
            dataIndex: "resource_name",
            sorter: (a, b) => {
              return a.resource_name > b.resource_name ? -1 : 1;
            },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 10) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 10)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          {
            title: "Potential Savings",
            dataIndex: "potential_savings",
            key: "potential_savings",
            width: "13%",
            sorter: (a, b) => {
              return a.services > b.services ? -1 : 1;
            },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 10) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 10)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          {
            // title: "Savings",
            // dataIndex: "current_configuration",
            // key: "current_configuration",
            title: "Username",
            dataIndex: "username",
            key: "username",
            width: "13%",
            // sorter: (a, b) => {
            //   return a.current_configuration > b.current_configuration ? -1 : 1;
            // },
          },
          {
            // title: "Savings",
            // dataIndex: "current_configuration",
            // key: "current_configuration",
            title: "Target Date",
            dataIndex: "targetdate",
            key: "targetdate",
            width: "13%",
            // sorter: (a, b) => {
            //   return a.current_configuration > b.current_configuration ? -1 : 1;
            // },
          },
          {
            title: "Reason",
            dataIndex: "reason",
            key: "reason",
            fixed: "right",
            width: "9%",
            render: (record, index) => {
              return (
                <>
                  {record && (
                    <div className="description-popup">
                      <Popconfirm
                        className="description-popup"
                        placement="top"
                        title=""
                        description={record}
                        footer={false}
                        okText=""
                        cancelText=""
                        cancelButtonProps={{ style: { display: "none" } }}
                        okButtonProps={{ style: { display: "none" } }}
                      >
                        <Icon
                          icon="material-symbols-light:description-outline"
                          style={{ fontSize: "23px", cursor: "pointer" }}
                          className="cursor-pointer description-popup-icon"
                        />
                      </Popconfirm>
                    </div>
                  )}
                </>
              );
            },
          },
        ])
      : setColumns([
          {
            title: "Account",
            dataIndex: "account_names",
            key: "account_names",
            width: "12%",
            sorter: (a, b) => {
              return a.account > b.account ? -1 : 1;
            },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 10) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 10)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          {
            title: "Application",
            dataIndex: "applications",
            key: "applications",
            width: "12%",
            // fixed: "left",
            sorter: (a, b) => {
              return a.applications > b.applications ? -1 : 1;
            },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 10) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 10)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          {
            title: "Environment",
            dataIndex: "environments",
            key: "environments",
            width: "12%",
            sorter: (a, b) => {
              return a.environments > b.environments ? -1 : 1;
            },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 10) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 10)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          // {
          //   title: "Recommendation Type",
          //   dataIndex: "recommendation_rule",
          //   key: "recommendation_rule",
          //   filters: recom_list,
          //   onFilter: (value, record) =>
          //     record.recommendation_rule.indexOf(value) === 0,
          //   sorter: (a, b) => {
          //     return a.recommendation_rule > b.recommendation_rule ? -1 : 1;
          //   },
          // },
          {
            title: "Resource Name",
            key: "resource_name",
            width: "12%",
            dataIndex: "resource_name",
            sorter: (a, b) => {
              return a.resource_name > b.resource_name ? -1 : 1;
            },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 10) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 10)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          {
            title: "Potential Savings",
            dataIndex: "potential_savings",
            key: "potential_savings",
            width: "12%",
            sorter: (a, b) => {
              return a.services > b.services ? -1 : 1;
            },
            render: (record, index) => {
              if (!record) {
                return "N/A";
              }
              if (record.length > 10) {
                return (
                  <>
                    <Tooltip placement="bottom" title={record}>
                      {record.substring(0, 10)}...
                    </Tooltip>
                  </>
                );
              } else {
                return record;
              }
            },
          },
          {
            // title: "Savings",
            // dataIndex: "current_configuration",
            // key: "current_configuration",
            title: "Assignee",
            dataIndex: "username",
            key: "username",
            width: "12%",
            // sorter: (a, b) => {
            //   return a.current_configuration > b.current_configuration ? -1 : 1;
            // },
          },
          {
            // title: "Savings",
            // dataIndex: "current_configuration",
            // key: "current_configuration",
            title: "Target Date",
            dataIndex: "targetdate",
            key: "targetdate",
            width: "12%",
            // sorter: (a, b) => {
            //   return a.current_configuration > b.current_configuration ? -1 : 1;
            // },
          },

          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: "12%",
            render: (status) => (
              <Tag color={getStatusTagColor(status)}>{status}</Tag>
              // <Tag color={status === "Analysis Completed" ? "green" : "blue"}>
              //   {status}
              // </Tag>
            ),
          },
          //   {
          //     title: "Recommended Configuration",
          //     dataIndex: "recommended_configuration",
          //     key: "recommended_configuration",
          //     sorter: (a, b) => {
          //       return a.environment > b.environment ? -1 : 1;
          //     },
          //   },

          //   {
          //     title: "Commitment",
          //     dataIndex: "commitment",
          //     key: "commitment",
          //     sorter: (a, b) => {
          //       return a.commitment > b.commitment ? -1 : 1;
          //     },
          //     render: (record) => {
          //       if (record) {
          //         return <>{record}</>;
          //       } else {
          //         return <>-</>;
          //       }
          //     },
          //   },
          //   {
          //     title: "Realised Savings",
          //     dataIndex: "realized_savings",
          //     key: "realized_savings",
          //     sorter: (a, b) => {
          //       return a.realized_savings > b.realized_savings ? -1 : 1;
          //     },
          //     render: (record) => {
          //       return (
          //         <>
          //           <div className="number_right">${numberFormat(record)}</div>
          //         </>
          //       );
          //     },
          //   },
          {
            title: "",
            dataIndex: "action",
            key: "action",
            fixed: "right",
            width: "4%",
            render: (record, index) => {
              if (props.rolestatus) {
                if (permission.savingswrite) {
                  return (
                    <>
                      <div
                        onClick={() => showEditRow(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <Icon icon="material-symbols:edit" />
                      </div>
                    </>
                  );
                } else {
                  return <></>;
                }
              } else {
                return <></>;
              }
            },
          },
        ]);
  }, [props, selectedCloud]);

  const tableProps = {};

  const showEditRow = (data) => {
    setFormType("update");
    setOldUpdateRecords(data);
    setDrawerStatus(true);
  };
  const showAddRow = () => {
    setDrawerStatus(true);
    setFormType("add");
    setOldUpdateRecords({});
  };
  return (
    <>
      <TrackingForm
        drawerstatus={drawerstatus}
        setDrawerStatus={setDrawerStatus}
        oldUpdateRecords={oldUpdateRecords}
        formType={formType}
        recordsudpate={props.recordsudpate}
        setRecordsUpdate={props.setRecordsUpdate}
      />
      {/* <div>
        <button className="table-add-button" onClick={() => showAddRow()}>
          <Icon icon="ion:add-circle-outline" />
          Add
        </button>
      </div> */}
      <div className="costimize-table-width">
        <Spin
          spinning={props.lsavingsstatus}
          wrapperClassName="costimize-loader"
        >
          <Table
            {...tableProps}
            className="slm"
            columns={columns}
            dataSource={tablerecords.filter(function (val) {
              return (
                val.recommendation_rule === props.filter &&
                (props.isdefer
                  ? val.status === "Deferred"
                  : val.status !== "Deferred")
              );
            })}
            pagination={(true, { pageSize: 10 })}
            summary={(pageData) => {
              const potential = pageData.reduce(
                (a, v) => (a = +a + +v?.potential_savings),
                0
              );
              let val = potential.toFixed(2);
              return (
                <>
                  <Table.Summary.Row className="costimize-savings-table-footer">
                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                    <Table.Summary.Cell index={6}>Total</Table.Summary.Cell>
                    <Table.Summary.Cell index={7}>
                      <Text className="number_right white-color">${val}</Text>
                    </Table.Summary.Cell>
                    {props.isdefer ? (
                      <></>
                    ) : (
                      <Table.Summary.Cell index={8}></Table.Summary.Cell>
                    )}
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Spin>
      </div>
    </>
  );
};

export default memo(TrackingLayout);
