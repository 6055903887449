import React, { useState, useEffect } from "react";
import { Spin, Table, Modal } from "antd";
import "../../slm.css";
import { Form, Input, Row, Col, Select, Button, Card, message, } from "antd";
import DrawerPlat from "../../../properties/Drawer/DrawerPlat";
import { getSecurityHub, updateSecurityHub, addSecurityHub, deleteSecurityHub,trustadvicer } from "../../../action/graphql/slm/SLM_reducers";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { set } from "react-hook-form";

const SecurityTable = (props) => {
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [deletestatus, setDeleteStatus] = useState(false);
  const [form] = Form.useForm();
  const [updatestatus, setUpdateStatus] = useState(true);
  const [oldrecords, setOldRecords] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const [drawerdeletestatus, setDrawerDeleteStatus] = useState(false);
  const [securityTableData, setSecurityTableData] = useState();
  const [buttonloading, setButtonLoading] = useState(false);
  const [openmodal, setOpenModal] = useState(false);
  const [totalInvestRecommended, setTotalInvestRecommended] = useState(0);
  const [totalActionRecommended, setTotalActionRecommended] = useState(0);
  // const [totalInvestRecommended, setTotalInvestRecommended] = useState();
  const [actionfault,setActionfault] = useState(0)
  const [investfault,setInvestfault] = useState(0)
  const [actionsecurity,setActionsecurity] = useState(0)
  const [investsecurity,setInvestsecurity] = useState(0)
  const [actionperformance,setActionPerformance] = useState(0)
  const [investperformance,setInvestPerformance] = useState(0)
  const [actionservice,setActionservice] = useState(0)
  const [investservice,setInvestservice] = useState(0)
  const [categoryoptions,setcategoryoptions] =useState([])
const [selectedOption,setSelectedOption] = useState({})
const [selectoption, setselectoption] = useState([]);
const [selectCloudoption, setselectCloudoption] = useState('');
const [filteredCategoryOptions, setFilteredCategoryOptions] = useState([]); 

 
  const companyName = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );


  const dispatch = useDispatch();
  const { selectedTopdate,selectedAccount } = useSelector((state) => state.constimize);

  const handleCancel = () => {
    setOpenModal(false);
  };
  const handletrustadviser = () => {
    setOpenModal(true);
  }

  // const handlechangeaccount = (selectaccount) => {
  //   setselectoption(selectaccount);
  //   const accountOptions = categoryoptions
  //     .filter((record) => record.account_name=== selectaccount)
  //     .map((record) => ({
  //       label: record.account_name,
  //       value: record.account_name,
  //     }));
  //   setcategoryoptions(accountOptions);
  //   // getCloudCategory(selectaccount);
  //   form.setFieldsValue({
  //     account_name: "",
  //   });
  // };
  
  const handlechangeaccount = (selectaccount) => {
    setselectCloudoption(selectaccount);
    const filteredOptions = categoryoptions.filter((record) => record.value === selectaccount);
    setFilteredCategoryOptions(filteredOptions);
    form.setFieldsValue({ account_name: "" });
  }; 


  // const handlechangeaccount = (selectaccount) => {
  //   form.setFieldsValue({
  //     account_name: "",
  //   });  
  //   const accountOptions = categoryoptions
  //     .filter((record) => record.build_category === selectaccount)
  //     .map((record) => ({
  //       label: record.account_name,
  //       value: record.account_name,
  //     }));
  //     setcategoryoptions(accountOptions);
  // };

  
  // useEffect(() => {
  //   dispatch(getSecurityHub({ companyName, selectedTopdate }))
  //     .unwrap()
  //     .then(({ data }) => {
  //       console.log(data);
  //       const records = data.newfunctionquery29.map((datas) => {
  //         const passed = datas.security_current_status === "passed" ? 1 : 0;
  //         const failed = datas.security_current_status === "failed" ? 1 : 0;
  //         const scored = passed === 1 ? 100 : 0;

  //         return {
  //           standard: datas.standard,
  //           passed: passed,
  //           failed: failed,
  //           scored: scored,
  //           action: (
  //             <Icon
  //               icon="material-symbols:edit"
  //               onClick={() => onEditClick(datas)}
  //             />
  //           ),
  //           type: "data",
  //         };
  //       });


  //       setShowSpinner(false);
  //       setSecurityTableData(records);
  //     })
  //     .catch((error) => {

  //       console.error("Error occurred:", error);
  //       setShowSpinner(false);
  //     });
  // }, [updatestatus, selectedTopdate]);

  const trustadvisor = [
    {
      title: 'Pillars',
      dataIndex: 'Pillars',
      key: 'Pillars'
    },
    {
      title: 'Action Recommended',
      dataIndex: 'ActionRecommended',
      key: 'ActionRecommended'

    },
    {
      title: 'Investigation Recommended',
      dataIndex: 'InvestigationRecommended',
      key: 'Investigation Recommended'
    },
   
  ];
  const datacolumns = [
    {
      key: '1',
      Pillars: 'Security',
      ActionRecommended: actionsecurity,
      InvestigationRecommended: investsecurity,
    },
    {
      key: '2',
      Pillars: 'Performance',
      ActionRecommended: actionperformance,
      InvestigationRecommended: investperformance,
    },
    {
      key: '3',
      Pillars: 'Fault Tolerance',
      ActionRecommended: actionfault,
      InvestigationRecommended: investfault,
    },
    {
      key: '4',
      Pillars: 'Service Limits',
      ActionRecommended: actionservice,
      InvestigationRecommended: investservice,
    }
  ];
 
  useEffect(() => {
   
    dispatch(getSecurityHub({ companyName, selectedTopdate }))
      .unwrap()
      .then(({ data }) => {
        // console.log(data);

        let passedCount = 0;
        const records = data.newfunctionquery29.map((datas) => {
          const passed = datas.security_current_status === "passed" ? 1 : 0;
          const failed = datas.security_current_status === "failed" ? 1 : 0;
          const scored = passed === 1 ? 100 : 0;


          if (passed === 1) {
            passedCount++;
          }

          return {
            standard: datas.standard,
            passed: passed,
            failed: failed,
            scored: scored,
            action: (
              <Icon
                icon="material-symbols:edit"
                onClick={() => onEditClick(datas)}
              />
            ),
            type: "data",
          };
        });


        const totalCount = records.length;
        const passedPercentage = (passedCount / totalCount) * 100;
        const formattedPercentage = passedPercentage.toFixed(2);

        // console.log(records);
        props.setcounting(passedPercentage)
        // console.log("Passed Percentage:", passedPercentage + "%");

        setShowSpinner(false);
        setSecurityTableData(records);
      })
      .catch((error) => {

        console.error("Error occurred:", error);
        setShowSpinner(false);
      });


  }, [updatestatus, selectedTopdate]);
  // console.log(securityTableData)


  useEffect(() => {
    const companyName = localStorage.getItem("CognitoIdentityServiceProvider.company");
    const apivalue = {
      account_name: selectedAccount.toString(),
    };
    function filterDataByAccountName(data, accountNames) {
      return data.slm_trust_advisor.filter(datas => accountNames.includes(datas.account_name));
    }

    function transformData(records) {
      const options = records.reduce((acc, record) => {
        const foundOption = acc.find((option) => option.value === record.account_name);
        if (!foundOption) {
          acc.push({
            label: record.account_name,
            value: record.account_name,
            config: record.configurations,
          });
        }
        return acc;
      }, []);
      setSelectedOption(options[0])
      setcategoryoptions(options);
      setFilteredCategoryOptions(options); 
      setselectCloudoption(options);  
    }
    
    const accountNamesToFilter = ["fs-common-services", "fs-prod-business"];
  
    dispatch(trustadvicer({ companyName, apivalue }))
      .unwrap()
      .then(({ data }) => {
        const trustedadvisor = data.slm_trust_advisor.filter(datas => datas.account_name === "fs-common-services");
        // console.log("trusted", data);
        // console.log("filtered", trustedadvisor);
        const filteredData = filterDataByAccountName(data, accountNamesToFilter);
   
        // Transform the filtered data
        const transformedData = transformData(filteredData);
    
        // console.log("filtered", filteredData);
        // console.log("transformed", transformedData);
        
        const actionRecommended = trustedadvisor.map(item => parseInt(item.action_recommended, 10));
        const investRecommended = trustedadvisor.map(item => parseInt(item.investigation_recommended, 10));

        const totalActionRecommended = actionRecommended.reduce((acc, val) => acc + val, 0);
        const totalInvestRecommended = investRecommended.reduce((acc, val) => acc + val, 0);

        setTotalActionRecommended(totalActionRecommended);
        setTotalInvestRecommended(totalInvestRecommended);

  
        const categories = ["security", "performance", "fault_tolerance", "service_limits"];
        const actionData = {};
        const investData = {};
  
        categories.forEach(category => {
          const categoryData = trustedadvisor.filter(item => item.category === category);
          actionData[category] = categoryData.map(item => parseInt(item.action_recommended, 10)).reduce((acc, val) => acc + val, 0);
          investData[category] = categoryData.map(item => parseInt(item.investigation_recommended, 10)).reduce((acc, val) => acc + val, 0);
        });
  
        setActionsecurity(actionData["security"]);
        setActionPerformance(actionData["performance"]);
        setActionfault(actionData["fault_tolerance"]);
        setActionservice(actionData["service_limits"]);
  
        setInvestsecurity(investData["security"]);
        setInvestPerformance(investData["performance"]);
        setInvestfault(investData["fault_tolerance"]);
        setInvestservice(investData["service_limits"]);
  
        // console.log("Action Data:", actionData);
        // console.log("Investigation Data:", investData);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [selectedAccount]);
  


  const columns = [
    {
      title: "Standard",
      dataIndex: "standard",
      key: "standard",
      width: 150,
      //   fixed : "left"
    },
    {
      title: "Passed",
      dataIndex: "passed",
      key: "passed",
      width: 150,
    },
    {
      title: "Failed",
      dataIndex: "failed",
      key: "failed",
      width: 150,
    },
    {
      title: "Scored",
      dataIndex: "scored",
      key: "scored",
      width: 150,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 150,
    }
  ];



  const tableProps = {};

  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };

  const handleSubmit = (value) => {

    setButtonLoading(true);

    const input = {
      company_name: companyName,
      standard: value.standard,
      security_current_status: value.security_current_status,
      report_month: selectedTopdate,
    }

    if (deletestatus) {
      const security_id = oldrecords.security_id;
      dispatch(updateSecurityHub({ input, security_id }))
        .unwrap()
        .then(({ data }) => {
          message.success('Record Updated');
          setUpdateStatus(!updatestatus);
          setDrawerStatus(false);
          setButtonLoading(false);

        })
        .catch(err => {
          console.log(err.message);
          setButtonLoading(false);
        });
    } else {
      dispatch(addSecurityHub({ input }))
        .unwrap()
        .then(({ data }) => {
          // console.log(data)
          message.success('New Records Added');
          setUpdateStatus(!updatestatus);
          setDrawerStatus(false);
          setButtonLoading(false);
        })
        .catch(err => {
          console.log(err.message);
          setButtonLoading(false);
        });
    }


  };
  const onAddClick = () => {

    setDeleteStatus(false)
    setDrawerStatus(true)
  }


  const onEditClick = (datas) => {
    // console.log(datas)
    setOldRecords(datas);
    setDeleteStatus(true)
    setDrawerStatus(true)
  }
  // console.log(oldrecords)
  useEffect(() => {
    var records = {
      company: "",
      month: "",
      security_current_status: "",
      security_id: "",
      standard: "",

    }
    if (oldrecords) {
      records = {
        company: oldrecords.company,
        month: oldrecords.month,
        security_current_status: oldrecords.security_current_status,
        security_id: oldrecords.security_id,
        standard: oldrecords.standard,


      }
    }
    form.setFieldsValue(records);
    // console.log(records)
  }, [deletestatus])

  const rowDelete = () => {
    const security_id = oldrecords.security_id;
    dispatch(deleteSecurityHub({ security_id, companyName }))
      .unwrap()
      .then((data) => {
        message.success("Deleted");
        setUpdateStatus(!updatestatus);
        setDrawerStatus(false);
        setButtonLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };


  return (
    <>
      <Modal
      className="slmmodal"
        open={drawerStatus}
        onCancel={changeDrawerStatus}
        footer={null}
        deletestatus={deletestatus}
        setDrawerDeleteStatus={setDrawerDeleteStatus}
        rowDelete={rowDelete}
        title="Security"
      >
        <Form
          layout="vertical"
          onFinish={handleSubmit}
        //  form={form}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="standard"
                label="Standard"
                rules={[{ required: true, message: "" }]}
              >
                <input type="text" className="form-control" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="security_current_status"
                label="Status"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select"
                  options={[
                    {
                      value: "passed",
                      label: "Passed",
                    },
                    {
                      value: "failed",
                      label: "Failed",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={() => setDrawerStatus(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              className="p-1"
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background: "#1b5f9d",
                    color: "#fff",
                    float: "right",
                  }}
                  loading={buttonloading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      

      <div>
        <button
          className="table-add-button float-right"
          // onClick={() => setDrawerStatus(true)}
          onClick={onAddClick}
        >
          <Icon icon="material-symbols:add-circle-outline" />
          Add
        </button>
        {/* <button
          className="table-add-button float-right"
          // onClick={() => setDrawerStatus(true)}
          onClick={handletrustadviser}
        >
          <Icon />
          Trusted Advisor
        </button> */}

      </div>
      <div className="initial_response_timetable">
        <Modal
          width="40%"
          title="Environment Spend Trend"
          open={openmodal}
          onCancel={handleCancel}
          footer={null}
        >
          <hr style={{ borderTop: "2px solid white" }} />
          <p style={{ color: "white" }} >Checks Summary</p>
          <hr style={{ borderTop: "3px solid white", width: '30%' }} />
          {/* <div style={{width: '30%' ,height: '20%'}} > */}
          <Select
                      showSearch
                      className="slm-antd-select"
                      bordered={false}
                      placeholder="Account Name"
                      defaultValue={selectedOption}
                      options={categoryoptions}
                      onChange={handlechangeaccount}
                    />
        
          <Row gutter={16} style={{ gridGap: '8px' }}>
  <Col >
    <Card className="card1">
      {/* <Icon
        icon="material-symbols:close"
        className="action-recommended" /> */}
      <p style={{ color: "red" }}>{totalActionRecommended}</p>
      <p style={{ color: "red" }}>Action recommended</p>
      <u>Info</u>
    </Card>
  </Col>

  <Col >
    <Card className="card2">
      <p>{totalInvestRecommended}</p>
      <p>Investigation recommended</p>
      <u>Info</u>
    </Card>
  </Col>
</Row>
<br></br>
<Row>
            <Col>
          <div className="details">
            <h6>Security: {actionsecurity}</h6>
            <h6>Performance: {actionperformance}</h6>
            <h6>Fault tolerance: {actionfault}</h6>
            <h6>Service limits: {actionservice}</h6>
          </div>
          </Col>
          <Col>
          <div className="details1">
           <h6>Security: {investsecurity}</h6>
            <h6>Performance: {investperformance}</h6>
            <h6>Fault tolerance: {investfault}</h6>
            <h6>Service limits: {investservice}</h6>
          </div>
          </Col>
          </Row>

          <Table
            className="slm mt-3"
            style={{ overflow: "auto", height: "19rem", width: "2rem" }}
            columns={trustadvisor}
            dataSource={datacolumns} 
            pagination={false}
          />
        </Modal>
        <Table
          {...tableProps}
          className="slm"
          // style={{ overflowY: "auto", height: "19rem" }}
          columns={columns}
          dataSource={securityTableData}
          loading={{ indicator: <Spin />, spinning: showSpinner }}
          pagination={false}
      
        />
      </div>
    </>
  );
};

export default SecurityTable;
