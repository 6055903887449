// features/user/userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  environmentList: [],
  selecteddc: ["all"],
  applicationList: [],
  accountList: [],
  cloudList: [],
  serviceList: [],
  infraList: [],

  osList: [],
  ouList: [],
  ticketownerList: [],
  ticketclosedList: [],
  categoryList: [],
  slaList: [],
  dbList: [],
  setOuList: [],
  setCategorylist: [],
  setTicketownerList: [],
  selectedTicketclosedList: [],
  setSlaList: [],
  selectedEnvironment: ["all"],
  selectedAccount: ["all"],
  selectedCloud: ["all"],
  selectedApplication: ["all"],
  selectedcategory: ["all"],
  selectedou: ["all"],
  selectedsla: ["all"],
  selectedticketowner: ["all"],
  selectedticketclosed: ["all"],

  selectedTopdate: `${new Date().getFullYear()}-${
    new Date().getMonth() + 1
  }-01`,
  selectedservices: ["all"],
  selectedos: ["all"],
  selectedou: ["all"],
  selectedinfra: ["all"],
  selecteddb: ["all"],
  spendtrandschartdata: [],
  spendtrandschartmonth: [],
  searchapplication: "",
  alldropdownlist: [],
  dummydatastatus: false,
  tabactive: 1,
  filterbuttonstatus: false,
  selectedrowlist: [],
  selectedWorkload: "",
  selectWorkloadid: "",
  selectPercentage: "",
};

const a2cRoverSlice = createSlice({
  name: "a2cRover",
  initialState,
  reducers: {
    environmentListMethod: (state, { payload }) => {
      state.environmentList = payload;
    },
    selectedDCMethod: (state, { payload }) => {
      state.selecteddc = payload;
    },
    selectedCategoryMethod: (state, { payload }) => {
      state.selectedcategory = payload;
    },
    selectedOUMethod: (state, { payload }) => {
      state.selectedou = payload;
    },
    selectedSlaMethod: (state, { payload }) => {
      state.selectedsla = payload;
    },

    selectedTicketOwnerMethod: (state, { payload }) => {
      state.selectedticketowner = payload;
    },
    selectedTicketClosedMethod: (state, { payload }) => {
      state.selectedticketclosed = payload;
    },

    cloudListMethod: (state, { payload }) => {
      state.cloudList = payload;
    },
    applicationListMethod: (state, { payload }) => {
      state.applicationList = payload;
    },
    accountListMethod: (state, { payload }) => {
      state.accountList = payload;
    },
    selectedEnvironmentMethod: (state, { payload }) => {
      state.selectedEnvironment = payload;
    },
    selectedAccountMethod: (state, { payload }) => {
      state.selectedAccount = payload;
    },
    selectedCloudMethod: (state, { payload }) => {
      state.selectedCloud = payload;
    },
    selectedApplicationMethod: (state, { payload }) => {
      state.selectedApplication = payload;
    },
    selectedOUMethod: (state, { payload }) => {
      state.selectedou = payload;
    },
    selectedTopDateMethod: (state, { payload }) => {
      state.selectedTopdate = payload;
    },
    selectedServicesMethod: (state, { payload }) => {
      state.selectedservices = payload;
    },
    selectedOSMethod: (state, { payload }) => {
      state.selectedos = payload;
    },
    selectedInfraMethod: (state, { payload }) => {
      state.selectedinfra = payload;
    },
    spendTrandChartData: (state, { payload }) => {
      state.spendtrandschartdata = payload;
    },
    spendTrandChartMonth: (state, { payload }) => {
      state.spendtrandschartmonth = payload;
    },
    selectedDbMethod: (state, { payload }) => {
      state.selecteddb = payload;
    },
    searchApplication: (state, { payload }) => {
      state.searchapplication = payload;
    },
    allDropdownList: (state, { payload }) => {
      state.alldropdownlist = payload;
    },
    setDummyDataStatus: (state, { payload }) => {
      state.dummydatastatus = payload;
    },
    setTabActive: (state, { payload }) => {
      state.tabactive = payload;
    },
    setServiceList: (state, { payload }) => {
      state.serviceList = payload;
    },
    setInfraRList: (state, { payload }) => {
      state.infraList = payload;
    },
    setOsList: (state, { payload }) => {
      state.osList = payload;
    },
    setOuList: (state, { payload }) => {
      state.ouList = payload;
    },
    setTicketownerList: (state, { payload }) => {
      state.ticketownerList = payload;
    },
    selectedTicketclosedList: (state, { payload }) => {
      state.ticketclosedList = payload;
    },
    setCategoryList: (state, { payload }) => {
      state.categoryList = payload;
    },
    setSlaList: (state, { payload }) => {
      state.slaList = payload;
    },
    setDbList: (state, { payload }) => {
      state.dbList = payload;
    },
    setFilterButtonStatus: (state, { payload }) => {
      state.filterbuttonstatus = payload;
    },
    setSelectedRowList: (state, { payload }) => {
      state.selectedrowlist = payload;
    },
    setSelectedWorkload: (state, { payload }) => {
      state.selectedWorkload = payload;
    },
    setselectWorkloadid: (state, { payload }) => {
      state.selectWorkloadid = payload;
    },
    setselectpercentage: (state, { payload }) => {
      state.selectPercentage = payload;
    },
  },
  extraReducers: {},
});

export default a2cRoverSlice.reducer;
export const {
  environmentListMethod,
  selectedDCMethod,
  applicationListMethod,
  accountListMethod,
  selectedEnvironmentMethod,
  selectedAccountMethod,
  selectedCloudMethod,
  selectedApplicationMethod,
  selectedTopDateMethod,
  selectedServicesMethod,
  selectedOSMethod,
  selectedOUMethod,
  selectedInfraMethod,
  spendTrandChartData,
  spendTrandChartMonth,
  selectedDbMethod,
  searchApplication,
  allDropdownList,
  setDummyDataStatus,
  cloudListMethod,
  setTabActive,
  setServiceList,
  setInfraRList,
  setOsList,
  setOuList,
  setDbList,
  setTicketownerList,
  setTicketclosedList,
  setCategoryList,
  setSlaList,
  setOgList,
  setFilterButtonStatus,
  setSelectedRowList,
  selectedCategoryMethod,
  selectedSlaMethod,
  selectedTicketOwnerMethod,
  selectedTicketClosedMethod,
  setSelectedWorkload,
  setselectWorkloadid,
  setselectpercentage,
} = a2cRoverSlice.actions;
