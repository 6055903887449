import React from "react";
import SLMSidebar from "../SLMSidebar";
import MainHead from "../../costimize_v2/layout/MainHead";
import { Tabs, Button } from "antd";
// import IncidentTrends from "../../operation/Compliance/IncidentTrends/IncidentTrends";
import ServiceRequest from "../slm_compliance_components/ServiceRequest/ServiceRequest";
import ChangeRequest from "../slm_compliance_components/ChangeRequest/ChangeRequest";
import Security from "../slm_compliance_components/Security/Security";
import PatchManagements from "../slm_compliance_components/PatchManagement/PatchManagements";
import IncidentTrends from "../slm_compliance_components/IncidentTrends/IncidentTrends";
import "../../cams/cams.css";
import { listCompliance } from "../../action/graphql/slm/SLM_reducers";
import Complianceform from "./Complianceform";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";

function SLM_Compliance() {
  const [drawerstatus, setDrawerStatus] = useState(false);
  const [oldrecords, setOldRecords] = useState();
  const dispatch = useDispatch();
  const company = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const { selectedTopdate } = useSelector((state) => state.constimize);
  const [updatestatus, setUpdateStatus] = useState(true);
  const [tablerecords, setTableRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const items = [
    {
      key: "1",
      label: `Incident Trends`,
      children: <IncidentTrends tablerecords={tablerecords} />,
    },
    {
      key: "2",
      label: `Service Request`,
      children: <ServiceRequest tablerecords={tablerecords} />,
    },
    {
      key: "3",
      label: `Change Request`,
      children: <ChangeRequest />,
    },
    {
      key: "4",
      label: `Security`,
      children: <Security />,
    },
    {
      key: "5",
      label: `Patch Management`,
      children: <PatchManagements tablerecords={filteredRecords} />,
    },
  ];
  useEffect(() => {
    let lastThreeMonths = [];
    let currentDate = new Date(selectedTopdate);
    let lastThreeMonthsDate = new Date(selectedTopdate);
    lastThreeMonthsDate.setMonth(currentDate.getMonth() - 2);
    for (let i = 0; i < 3; i++) {
      let date = new Date(
        lastThreeMonthsDate.getFullYear(),
        lastThreeMonthsDate.getMonth() + i,
        1
      );

      lastThreeMonths.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    }
    const report_month = selectedTopdate;
    dispatch(listCompliance({ company, report_month }))
      // dispatch(listCompliance({ company, selectedTopdate: lastThreeMonths }))
      .unwrap()
      .then(({ data }) => {
        const records = data.slm_compliance_ticket.map((datas) => {
          return {
            application: datas.application,
            environment: datas.environment,
            month: datas.month,
            request_category: datas.request_category,
            ticket_status: datas.ticket_status,
            cr_type: datas.cr_type,
            priority_type: datas.priority_type,
            operating_system: datas.operating_system,
            service_request_count: datas.service_request_count,
            change_request_count: datas.change_request_count,
            incident_count: datas.incident_count,
            compliants: datas.compliants,
            non_complaints: datas.non_complaints,
            type: "data",
          };
        });
        setTableRecords(records);
      })
      .catch((err) => {
        console.log(err.message);
      });
    setTableRecords([]);
  }, [updatestatus, selectedTopdate]);
  useEffect(() => {
    const patchRecords = tablerecords.filter(
      (record) => record.request_category.toLowerCase() === "patch"
    );
    setFilteredRecords(patchRecords);
  }, [tablerecords]);

  const handleclick = () => {
    setDrawerStatus(true);
    setOldRecords(null);
  };
  const changeDrawerStatus = () => {
    setDrawerStatus(false);
  };
  return (
    <>
      <Complianceform
        drawerstatus={drawerstatus}
        changeDrawerStatus={changeDrawerStatus}
        updatestatus={updatestatus}
        setUpdateStatus={setUpdateStatus}
        setDrawerStatus={setDrawerStatus}
        oldrecords={oldrecords}
      />{" "}
      <div style={{ display: "flex", height: "100%" }} className="costimize_bg">
        <SLMSidebar />
        <div className="quickglancetitle">
        <MainHead type="slm" title="Compliance" />
        </div>

        <div className="slm-body-screen ">
          <div className="slmbody">
          <div>
            <Button onClick={handleclick} className="table-add-button float-right">
              <Icon icon="material-symbols:add-circle-outline" />
              Add
            </Button>
          </div>
          <Tabs className="slm-tabs" defaultActiveKey="1" items={items} />
        </div>
     
     </div> </div>{" "}
    </>
  );
}

export default SLM_Compliance;
