import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";
import CHLOGO from "../../costimize_v2/WeeklyReport/onechlogo.jpg";
import Companylogo from "../../costimize_v2/WeeklyReport/company_logo.png";
import React, { useState, useRef, useEffect } from "react";
import "../../costimize_v2/costimize_v2.css";
import { Row, Col, Card } from "antd";
import ThreeMonthsSpend from "./ThreeMonthsSpend";
import ThreeWeeksSpend from "./ThreeWeeksSpend";
import GroupedBar from "./GroupedBar";
import EnvironmentWeeklySpend from "./EnvironmentWeeklySpend";
import WeeklySpendTop5App from "./WeeklySpendTop5App";
import ApplicationLevelsummarySpendless from "./ApplicationLevelsummarySpendless";
import ApplicationLevelsummaryspendMore from "./ApplicationLevelsummaryspendMore";
import OverallSummary from "./OverallSummary";
import ReactApexChart from "react-apexcharts";
// import html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";
import WeeklySpendByServiceLevel from "./WeeklySpendByServiceLevel";
import ApplicationSummary from "./ApplicationSummary";
import ServiceDetailed from "./ServiceDetailed";
import ApplicationDeatilesReport from "./ApplicationDeatilesReport";
import EnvironmentDeatiledreport from "./EnvironmentDeatiledreport";
import { helper } from "echarts";
import { width } from "@mui/system";
import ServiceLevelByRegion from "./ServiceLevelByRegion";
import { headercustomerlogo } from "../../action/header_logo";
import { useDispatch } from "react-redux";
import logo from "../../../assets/images/company_logo.png";
// import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 12,
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    width: "100%",
  },
  whiteBg: {
    backgroundColor: "white",
    width: "100%",
    height: "100%",
  },
  horizontalBar: {
    width: "100%",
    height: 16,
    backgroundColor: "#ff6a2f",
    marginVertical: 0,
  },
  headerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "10%",
  },
  imagtag: {
    flexDirection: "row",
  },
  //   textsCenter: {
  //     textAlign: "center",
  //   },
  companyName: {
    color: "#ff6a2f",
    flex: 1,
    textAlign: "center",
  },
  headingCenter: {
    color: "#000",
  },
  subheadingRow: {
    flexDirection: "row",
    justifyContent: "center",
  },
  subheadingTab: {
    fontSize: 35,
  },
  headingTab: {
    fontSize: 70,
  },
  textsCenter: {
    textAlign: "center",
    marginLeft: 20,
  },
  reportCards: {
    borderWidth: 1,
    borderColor: "#cfcfcf",
    backgroundColor: "#eee",
    borderRadius: 5, // border radius in React Native uses a numeric value
    marginBottom: 10, // numeric value for margin in React Native
  },
  // chartContainer: {
  //   width: "50%",
  //   height: 350,
  //   marginBottom: 10,
  // },
  boldheading: {
    fontSize: "1.25rem",
    marginTop: "1rem",
    color: "#3b3b3b",
    fontweight: "700",
    /* text-align: center; */
  },
  chartImage: {
    width: "50%",
  },

  dateheading: {
    marginTop: "-1rem",
    fontsize: "1rem",
    border: "1px solid black",
    display: "inline-block",
    padding: "0.5rem",
    fontweight: "bold",
    /* font-size: 1rem; */
  },
  headerheading: {
    display: "flex",
    justifyContent: " center",
    color: "#ff6a2f",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
  },
  chartheading: {
    display: "flex",
    justifyContent: "left",
    // color: "#ff6a2f",
    // fontFamily: "Poppins",
    // fontStyle: "normal",
    // fontWeight: 700,
    // lineHeight: "normal",
    // marginTop: "1rem",
    textAlign: "left",
    fontWeight: "bold",
    marginTop: 5,
    color: "#FF6A2F",
  },
  subtableheading: {
    color: "#FF6A2F",
    fontWeight: "700",
    fontSize: 20,
  },
  container: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // paddingTop: 10,
    // paddingBottom: 10,
  },
  footerruler: {
    width: "100%",
    height: 1,
    backgroundColor: "#FF6A2F",
  },
  footerRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 1,
  },
  //   footerCol: {
  //     width: "25%",
  //   },
  footerText: {
    fontSize: 8,
  },
  footer: {
    position: "absolute",
    fontSize: 10,
    bottom: 20,
    left: 20,
    right: 20,
    textAlign: "center",
    color: "grey",
    paddingTop: 20,
  },
  // Add your other styles similarly
});

function Rep(props) {
  const dispatch = useDispatch();
  const chartRef = useRef(null);
  const [chartImageURI, setChartImageURI] = useState("");
  const company_name = localStorage.getItem(
    "CognitoIdentityServiceProvider.company"
  );
  const companyName = company_name.toUpperCase();
  const imgsrc =
    "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ1IiBoZWlnaHQ9IjI5IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiBvdmVyZmxvdz0iaGlkZGVuIj48ZGVmcz48Y2xpcFBhdGggaWQ9ImNsaXAwIj48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTQ1IiBoZWlnaHQ9IjI5Ii8+PC9jbGlwUGF0aD48Y2xpcFBhdGggaWQ9ImNsaXAxIj48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTg1Mzk2OCIgaGVpZ2h0PSIzODA5NTIiLz48L2NsaXBQYXRoPjxpbWFnZSB3aWR0aD0iMTQ1IiBoZWlnaHQ9IjI5IiB4bGluazpocmVmPSJkYXRhOmltYWdlL3BuZztiYXNlNjQsaVZCT1J3MEtHZ29BQUFBTlNVaEVVZ0FBQUpFQUFBQWRDQVlBQUFDcXFvNkFBQUFBQVhOU1IwSUFyczRjNlFBQUFBUm5RVTFCQUFDeGp3djhZUVVBQUFBSmNFaFpjd0FBQ3hNQUFBc1RBUUNhbkJnQUFCWTZTVVJCVkhoZTdWc0pjQlRYbVo2TmQyTVRPOTd5VmRseUhDTnBwcTg1cEpuUlNLUFJpUzdFb1FQUU5acjcwQUVDU2VhV3dFUmxlNDJJSFd5cmpMMGh5eFplc0wwR3gwZkFDQWxKakc0dzFtNlJMWmZac0V2dzRuVVN4emJZUUh5QnB2Zi9YM2ZQOUl4R0VrS3A3S2JLcitxcm5uN0gvNjZ2LytOMWowS2pzLzZPWnF4Zk1FejFOWWExWFdlWW1va3B3ZjZKOHlUTVZIOHVmZnlwOHFZcnZ4bDVzODJUQThwWnhqYkJxc29uTm03OENhTVFVL2VTSlJ2NjJhUXJ4elg2eXdOcUFGd0RnT1B3T3dLaFBNUGxmczV3cFY4akllbktNVzNpbFVGV2QzbFFaYno4eTVMcTU4NmVQWHNyeWo3YjJYbG5kM3JlUUIrMGhicVhCMW5EbFNGV2YrV293WHhCd1hIT2l6VGw1aG5hQS9COWk3OFFzTFNYWjVRMWZHdnJzelJoRUtTK0JRdTNub3hMNGsvRTYvaVQ4V3E0Q2hoTHVER2NqTmZ3YjhmcCtBRmxZckEzZjlFdW51ZS9nM0pQdExYOW9NZG83anNaeDRBOGpqOE";

  useEffect(() => {
  }, [props]);
  const [companyLogo, setCompanyLogo] = useState(null);

  useEffect(() => {
    dispatch(headercustomerlogo({ company_name }))
      .unwrap()
      .then(({ data }) => {
        var encodedStringAtoB = data.getCustomerLogo.png_logo;
        var testimg = `data:image/png;base64,${encodedStringAtoB}`;
        setCompanyLogo(testimg);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const formatDate = (date) => {
    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];

    // Function to add suffix to day of the month
    const addSuffix = (day) => {
      if (day > 3 && day < 21) return `${day}th`;
      switch (day % 10) {
        case 1:
          return `${day} st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    const formattedDay = addSuffix(day);

    return `${formattedDay} ${month}`;
  };

  const start_Date = new Date(props.startDate);
  const end_Date = new Date(props.endDate);

  const oneWeek = 7 * 24 * 60 * 60 * 1000;
  const threeweekbeforeStart = new Date(start_Date.getTime() - 3 * oneWeek);
  const threeweekbeforeEnd = new Date(end_Date.getTime() - 3 * oneWeek);
  const lastMonthSpend = new Date(start_Date.getTime() - 7 * oneWeek);
  const lastMonthendSpend = new Date(end_Date.getTime() - 4 * oneWeek);
  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document  title={`${company_name}_weeklyreport.pdf`}>
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.whiteBg}>
            <View style={styles.horizontalBar} />
            <View style={styles.headingCenter}>
              <View style={styles.headerRow}>
                <Image src={CHLOGO} />
              </View>
              <View style={styles.subheadingRow}>
                <Text style={styles.subheadingTab}>
                  Weekly Consumption Report {"\n"}{" "}
                  <Text>
                    <Text
                      style={{
                        marginLeft: "25%",
                        //   marginRight: "auto",
                        //   textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      {"\t"}
                      {"\t"} {"\t"}
                      {"\t"} {"\t"}
                      {"\t"} {"\t"}
                      {"\t"} {"\t"}
                      {"\t"} {"\t"} {"\t"}
                      {"\t"} {"\t"} {"\t"}
                      <Text
                        style={{
                          marginRight: 45,
                        }}
                      >
                        {" "}
                        For{" "}
                        <Text style={styles.companyName}>
                          {companyName}
                        </Text>{" "}
                      </Text>
                    </Text>{" "}
                  </Text>
                </Text>
              </View>
            </View>
          </View>
        </Page>
        <Page size="A4" style={styles.page} orientation="landscape" wrap>
          <View style={styles.horizontalBar} fixed />
          <View style={{ flexDirection: "row", marginTop: 20 }} fixed>
            <View style={{ width: "65%" }}>
              <View style={{ width: "25%" }}>
                {" "}
                <Image src={companyLogo}></Image>
              </View>
            </View>
            <View style={{ width: "35%", marginTop: 10 }} fixed>
              <Text
                style={{
                  fontSize: 9,
                  fontWeight: "bold",
                  color: "#FF6A2F",
                  textAlign: "center",
                }}
              >
                Overall Summary & Spend Trend as of {props.dates}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 10 }} fixed>
            <View style={{ width: "65%" }}>
              <Text style={{ fontWeight: 500, fontSize: 8 }}>
                Weekly Consumption Report{" "}
              </Text>
              <br></br>{" "}
              <Text
                style={{
                  fontWeight: 500,
                  fontSize: 8,
                  marginTop: 10,
                }}
              >
                Start Date :{props.startDate}
              </Text>
              <br></br>
              <Text style={{ fontWeight: 500, fontSize: 8, marginTop: 10 }}>
                End Date : {props.endDate}
              </Text>
            </View>
            <View style={{ width: "35%" }} fixed>
              <OverallSummary overallui={props.overallsummary} />
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 5 }} fixed>
            <View style={styles.footerruler} />
          </View>
          <View style={{ flexDirection: "row", marginTop: 20 }}>
            <View style={{ width: "50%" }}>
              <Text
                style={{ fontSize: 14, fontWeight: "bold", color: "#FF6A2F" }}
              >
                Last Six Months Spend Trend
              </Text>
            </View>
            <View style={{ width: "50%" }}>
              <Text
                style={{ fontSize: 14, fontWeight: "bold", color: "#FF6A2F" }}
              >
                Last Four Weeks Spend Trend
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <View
              style={[styles.reportCards, { marginRight: 10, width: "50%" }]}
            >
              <Image src={props.imgURI}></Image>
            </View>
            <View
              style={[styles.reportCards, { marginRight: 10, width: "50%" }]}
            >
              <Image src={props.imgURIWeek}></Image>
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 10 }} break>
            <View style={{ width: "50%" }}>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  color: "#FF6A2F",
                  marginBottom: 10,
                }}
              >
                Spend By Service in $ for Previous Four Weeks (
                {formatDate(lastMonthSpend).toString()} -
                {formatDate(lastMonthendSpend).toString()})
              </Text>
            </View>
            <View style={{ width: "50%" }}>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  color: "#FF6A2F",
                  marginBottom: 10,
                }}
              >
                Spend By Service in $ for Current Four Weeks ({" "}
                {formatDate(threeweekbeforeStart).toString()} -
                {formatDate(end_Date).toString()})
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View
              style={[styles.reportCards, { marginRight: 10, width: "50%" }]}
            >
              <Image src={props.spendFourWeeks}></Image>
            </View>
            <View
              style={[styles.reportCards, { marginRight: 10, width: "50%" }]}
            >
              {/* <Image src={props.imgApplication}></Image> */}

              <Image src={props.imgSpend}></Image>
            </View>
          </View>

          {/* //application */}

          <View style={{ flexDirection: "row", marginTop: 10 }} break>
            <View style={{ width: "50%" }}>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  color: "#FF6A2F",
                  marginBottom: 10,
                }}
              >
                Spend By Application in $ for Previous Four Weeks (
                {formatDate(lastMonthSpend).toString()} -
                {formatDate(lastMonthendSpend).toString()})
              </Text>
            </View>
            <View style={{ width: "50%" }}>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  color: "#FF6A2F",
                  marginBottom: 10,
                }}
              >
                Spend By Application in $ for Current Four Weeks (
                {formatDate(threeweekbeforeStart).toString()} -
                {formatDate(end_Date).toString()})
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View
              style={[styles.reportCards, { marginRight: 10, width: "50%" }]}
            >
              <Image src={props.applicationfourweeks}></Image>
            </View>
            <View
              style={[styles.reportCards, { marginRight: 10, width: "50%" }]}
            >
              <Image src={props.imgApplication}></Image>
            </View>
          </View>
          {/* <View style={{ flexDirection: "row" }}>
            <View style={{ width: "100%" }}>
              <Image src={props.imgURIGROUPED}></Image>
            </View>
          </View> */}
          <View style={{ flexDirection: "row" }}>
            <Text style={[styles.chartheading, { textAlign: "left" }]}>
              Environment Level Weekly Spend Summary{" "}
            </Text>
          </View>
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <View style={{ width: "100%" }}>
              <EnvironmentWeeklySpend
                tableEnv={props.tableENVIRONMENT}
                startDates={props.startDate}
                endDates={props.endDate}
              />
            </View>
          </View>
          <View style={styles.footer} fixed>
            <View style={{ flexDirection: "row" }}>
              <View style={styles.footerruler} />
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={[styles.footerCol, { width: "50%", marginTop: 10 }]}>
                <Text style={{ textAlign: "left", fontSize: 5 }}>
                  © 1 Cloud Hub Pte Ltd. 2023. All Rights Reserved.
                </Text>
              </View>

              <View style={[styles.footerCol, { marginTop: 10 }]}>
                <Text style={{ textAlign: "right", width: "45%", fontSize: 5 }}>
                  Confidential
                </Text>
              </View>
            </View>
          </View>
        </Page>
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.horizontalBar} fixed />
          <View style={{ flexDirection: "row", marginTop: 20 }} fixed>
            <View style={{ width: "70%" }}>
              <View style={{ width: "25%" }}>
                <Image src={companyLogo}></Image>
              </View>
            </View>
            <View style={{ width: "30%" }} fixed>
              <Text
                style={{ fontSize: 10, fontWeight: "bold", textAlign: "right" }}
              >
                Weekly Consumption Report
              </Text>
              <br></br>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  color: "#FF6A2F",
                  marginTop: 10,
                  marginRight: 45,
                }}
              >
                <Link
                  src="#page1"
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    color: "#FF6A2F",
                    textAlign: "right",
                  }}
                >
                  Back to Home
                </Link>
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 5 }} fixed>
            <View style={styles.footerruler} />
          </View>
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <Text style={[styles.chartheading, { textAlign: "left" }]}>
              Application Level Weekly Spend Summary{" "}
            </Text>
          </View>

          <View style={{ flexDirection: "row", marginTop: 10 }}>
            {Object.keys(props.chartImages).map((env, index) => (
              <View key={index}>
                <Image src={props.chartImages[env]} />
              </View>
            ))}
          </View>

          {/* <View style={{ flexDirection: "row", marginTop: 10 }} wrap>
            <Image src={props.appSummary}></Image>
          </View> */}

          {/* 
          {Array.isArray(appSummary) &&
            appSummary.length > 0 &&
            appSummary.map((base64Image, index) => (
              <View key={index} style={{ flexDirection: "row", marginTop: 10 }}>
                <Image src={base64Image} />
              </View>
            ))}
          {/* If appSummaries is empty or undefined, render an alternate message or element */}
          {/* {!Array.isArray(appSummary) ||
            (appSummary.length === 0 && <Text>No chart images available</Text>)} */}
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <View style={{ width: "50%" }}>
              <Text style={{ color: "#FF6A2F", fontSize: 9 }}>
                Top 5 Increase in Spend By Application ($)
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <View style={{ width: "100%" }}>
              <ApplicationLevelsummaryspendMore
                reportINC={props.tableweekINC}
                startDates={props.startDate}
                endDates={props.endDate}
              />
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <Text style={{ color: "#FF6A2F", fontSize: 9 }}>
              {" "}
              Top 5 Decrease in Spend By Application ($)
            </Text>
          </View>
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <View style={{ width: "100%", marginRight: 10 }}>
              <ApplicationLevelsummarySpendless
                // style={{ height: "100%" }}
                reportDEC={props.tableWeekDec}
                startDates={props.startDate}
                endDates={props.endDate}
              />
            </View>
          </View>
          <View style={styles.footer} fixed>
            <View style={{ flexDirection: "row" }}>
              <View style={styles.footerruler} />
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={[styles.footerCol, { width: "50%", marginTop: 10 }]}>
                <Text style={{ textAlign: "left", fontSize: 5 }}>
                  © 1 Cloud Hub Pte Ltd. 2023. All Rights Reserved.
                </Text>
              </View>

              <View style={[styles.footerCol, { marginTop: 10 }]}>
                <Text style={{ textAlign: "right", width: "45%", fontSize: 5 }}>
                  Confidential
                </Text>
              </View>
            </View>
          </View>
        </Page>

        {/* //application page */}
        <Page size="A4" style={styles.page} orientation="landscape" wrap>
          <View style={styles.horizontalBar} fixed />
          <View style={{ flexDirection: "row", marginTop: 10 }} fixed>
            <View style={{ width: "70%" }}>
              <View style={{ width: "25%" }}>
                {" "}
                <Image src={companyLogo}></Image>
              </View>
            </View>
            <View style={{ width: "30%" }}>
              <Text
                style={{ fontSize: 10, fontWeight: "bold", textAlign: "right" }}
              >
                Weekly Consumption Report
              </Text>
              <br></br>

              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  color: "#FF6A2F",
                  marginTop: 10,
                  marginRight: 25,
                }}
              >
                <Link
                  src="#page1"
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    color: "#FF6A2F",
                    textAlign: "right",
                  }}
                >
                  Back to Home
                </Link>
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 5 }} fixed>
            <View style={styles.footerruler} />
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={{ color: "#FF6A2F", fontSize: 15, marginTop: 10 }}>
              {" "}
              Application Level Weekly Spend Summary
            </Text>
          </View>
          <View style={{ flexDirection: "row", marginTop: 5 }}>
            <View style={{ width: "100%" }}>
              <ApplicationSummary
                appdetail={props.applicationweeklydetailed}
                startDates={props.startDate}
                endDates={props.endDate}
              />
            </View>
          </View>
          <View style={styles.footer} fixed>
            {" "}
            <View style={{ flexDirection: "row" }}>
              <View style={styles.footerruler} />
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={[styles.footerCol, { width: "50%", marginTop: 10 }]}>
                <Text style={{ textAlign: "left", fontSize: 5 }}>
                  © 1 Cloud Hub Pte Ltd. 2023. All Rights Reserved.
                </Text>
              </View>

              <View style={[styles.footerCol, { marginTop: 10 }]}>
                <Text style={{ textAlign: "right", width: "45%", fontSize: 5 }}>
                  Confidential
                </Text>
              </View>
            </View>{" "}
          </View>
        </Page>

        {/* //service page */}
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.horizontalBar} fixed />
          <View style={{ flexDirection: "row", marginTop: 20 }} fixed>
            <View style={{ width: "70%" }}>
              <View style={{ width: "25%" }}>
                {" "}
                <Image src={companyLogo}></Image>
              </View>
            </View>
            <View style={{ width: "30%" }} fixed>
              <Text
                style={{ fontSize: 10, fontWeight: "bold", textAlign: "right" }}
              >
                Weekly Consumption Report
              </Text>
              <br></br>

              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  color: "#FF6A2F",
                  marginTop: 10,
                  marginRight: 25,
                }}
              >
                <Link
                  src="#page1"
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    color: "#FF6A2F",
                    textAlign: "right",
                  }}
                >
                  Back to Home
                </Link>
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 5 }} fixed>
            <View style={styles.footerruler} />
          </View>
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <View>
              <Text style={[styles.chartheading, { textAlign: "left" }]}>
                Service Level Weekly Spend
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 5 }}>
            <View style={{ width: "100%" }}>
              <WeeklySpendByServiceLevel
                tableWeeklySpend={props.tableDATA}
                startDates={props.startDate}
                endDates={props.endDate}
              />
            </View>
          </View>
          {/* <View style={{ flexDirection: "row", marginTop: 10 }}>
            <View style={{ width: "100%" }}>
              {.log(">>", props.tableDATA)}
              <ServiceLevelByRegion
                tableWeeklySpenddetail={props.serviceDetailed}
              />
            </View>
          </View> */}
          <View style={styles.footer} fixed>
            {" "}
            <View style={{ flexDirection: "row" }}>
              <View style={styles.footerruler} />
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={[styles.footerCol, { width: "50%", marginTop: 10 }]}>
                <Text style={{ textAlign: "left", fontSize: 5 }}>
                  © 1 Cloud Hub Pte Ltd. 2023. All Rights Reserved.
                </Text>
              </View>

              <View style={[styles.footerCol, { marginTop: 10 }]}>
                <Text style={{ textAlign: "right", width: "45%", fontSize: 5 }}>
                  Confidential
                </Text>
              </View>
            </View>{" "}
          </View>
        </Page>
        {/* //Application level detailed Summary */}
        <Page style={styles.page} orientation="landscape" wrap>
          <View style={styles.horizontalBar} fixed />
          <View style={{ flexDirection: "row", marginTop: 20 }} fixed>
            <View style={{ width: "70%" }}>
              <View style={{ width: "25%" }}>
                {" "}
                <Image src={companyLogo}></Image>
              </View>
            </View>
            <View style={{ width: "30%" }} fixed>
              <Text
                style={{ fontSize: 10, fontWeight: "bold", textAlign: "right" }}
              >
                Weekly Consumption Report
              </Text>
              <br></br>

              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  color: "#FF6A2F",
                  marginTop: 10,
                  marginRight: 25,
                }}
              >
                <Link
                  src="#page1"
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    color: "#FF6A2F",
                    textAlign: "right",
                  }}
                >
                  Back to Home
                </Link>
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 5 }} fixed>
            <View style={styles.footerruler} />
          </View>
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <View>
              <Text style={{ color: "#FF6A2F", fontSize: 15 }}>
                Detailed view of VM along with Application and Spend:
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 5 }}>
            <View style={{ width: "100%" }}>
              <ApplicationDeatilesReport
                appdetail={props.applicationweeklydetailed}
                startDates={props.startDate}
                endDates={props.endDate}
              />
            </View>
          </View>
          <View style={styles.footer} fixed>
            {" "}
            <View style={{ flexDirection: "row" }}>
              <View style={styles.footerruler} />
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={[styles.footerCol, { width: "50%", marginTop: 10 }]}>
                <Text style={{ textAlign: "left", fontSize: 5 }}>
                  © 1 Cloud Hub Pte Ltd. 2023. All Rights Reserved.
                </Text>
              </View>

              <View style={[styles.footerCol, { marginTop: 10 }]}>
                <Text style={{ textAlign: "right", width: "45%", fontSize: 5 }}>
                  Confidential
                </Text>
              </View>
            </View>{" "}
          </View>
        </Page>
        {/* Environment Level detailed Summary */}
        {/* <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.horizontalBar} fixed />
          <View style={{ flexDirection: "row", marginTop: 20 }} fixed>
            <View style={{ width: "70%" }}>
              <View style={{ width: "25%" }}>
                {" "}
                <Image src={companyLogo}></Image>
              </View>
            </View>
            <View style={{ width: "30%" }}>
              <Text
                style={{ fontSize: 10, fontWeight: "bold", textAlign: "right" }}
              >
                Weekly Consumption Report
              </Text>
              <br></br>

              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  color: "#FF6A2F",
                  marginTop: 10,
                  marginRight: 25,
                }}
              >
                <Link
                  src="#page1"
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    color: "#FF6A2F",
                    textAlign: "right",
                  }}
                >
                  Back to Home
                </Link>
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 5 }} fixed>
            <View style={styles.footerruler} />
          </View>
          <View style={{ flexDirection: "row", marginTop: 20 }}>
            <View>
              <Text style={{ color: "#FF6A2F", fontSize: 15 }}>
                Detailed view of VM along with Environment and Spend:
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 5 }}>
            <View style={{ width: "100%" }}>
              {.log(">>", props.tableDATA)}
              <EnvironmentDeatiledreport
                envrironmentdetailreport={props.environmentweeklydetailed}
                startDates={props.startDate}
                endDates={props.endDate}
              />
            </View>
          </View>
          <View style={styles.footer} fixed>
            {" "}
            <View style={{ flexDirection: "row" }}>
              <View style={styles.footerruler} />
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={[styles.footerCol, { width: "50%", marginTop: 10 }]}>
                <Text style={{ textAlign: "left", fontSize: 5 }}>
                  © 1 Cloud Hub Pte Ltd. 2023. All Rights Reserved.
                </Text>
              </View>

              <View style={[styles.footerCol, { marginTop: 10 }]}>
                <Text style={{ textAlign: "right", width: "45%", fontSize: 5 }}>
                  Confidential
                </Text>
              </View>
            </View>{" "}
          </View>
        </Page> */}

        {/* //Service Detailed Summary */}

        <Page style={styles.page} orientation="landscape" wrap>
          <View style={styles.horizontalBar} fixed />
          <View style={{ flexDirection: "row", marginTop: 20 }} fixed>
            <View style={{ width: "70%" }}>
              <View style={{ width: "25%" }}>
                {" "}
                <Image src={companyLogo}></Image>
              </View>
            </View>
            <View style={{ width: "30%" }}>
              <Text
                style={{ fontSize: 10, fontWeight: "bold", textAlign: "right" }}
              >
                Weekly Consumption Report
              </Text>
              <br></br>

              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  color: "#FF6A2F",
                  marginTop: 10,
                  marginRight: 25,
                }}
              >
                <Link
                  src="#page1"
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    color: "#FF6A2F",
                    textAlign: "right",
                  }}
                >
                  Back to Home
                </Link>
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 5 }} fixed>
            <View style={styles.footerruler} />
          </View>
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <View>
              <Text style={{ color: "#FF6A2F", fontSize: 15 }}>
                Detailed view of along with Service and Spend
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", marginTop: 5 }}>
            <View style={{ width: "100%" }}>
              <ServiceDetailed
                tableWeeklySpenddetail={props.serviceDetailed}
                startDates={props.startDate}
                endDates={props.endDate}
              />
            </View>
          </View>
          <View style={styles.footer} fixed>
            {" "}
            <View style={{ flexDirection: "row" }}>
              <View style={styles.footerruler} />
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={[styles.footerCol, { width: "50%", marginTop: 10 }]}>
                <Text style={{ textAlign: "left", fontSize: 5 }}>
                  © 1 Cloud Hub Pte Ltd. 2023. All Rights Reserved.
                </Text>
              </View>

              <View style={[styles.footerCol, { marginTop: 10 }]}>
                <Text style={{ textAlign: "right", width: "45%", fontSize: 5 }}>
                  Confidential
                </Text>
              </View>
            </View>{" "}
          </View>
        </Page>
        {/* <Page style={styles.page} orientation="landscape">
          <View style={{ flexDirection: "row", marginTop: 20 }} fixed>
            <View style={{ width: "100%", height: "1000px" }}>
              <View style={{ width: "100%", height: "1000px" }}>
                <Image
                  src={props.appDatas}
                  style={{ width: "100%", height: "000px" }}
                />
              </View>
            </View>
          </View>
        </Page> */}
      </Document>
    </PDFViewer>
  );
}

export default Rep;
