import { Row, Col } from 'react-bootstrap';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState  } from 'react';
import Typography from "@material-ui/core/Typography";
import { setTabActive } from '../slice/costimizeSlice';

import { listRecommendation } from "../action/costimizev2DashboardAction";
import SavingsPieChart from './QuickGlanceDashboard/SavingsPieChart';
import { numberFormat } from '../custom_hook/CustomHook';

const QuickGlanceSavings = () => {
    //savings
    const { applicationList, selectedEnvironment, selectedAccount, selectedCloud, selectedApplication, selectedTopdate, selectedservices, selectedos, selectedinfra,selecteddb, tabactive } = useSelector((state) => state.constimize);
    // const { companyName } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [toprecords, setTopRecords] = useState({
        potential_savings : 0,
        realised_savings: 0,
        pending_action: "0/0",
        color_code: '#000',
    });
    const companyName = localStorage.getItem("CognitoIdentityServiceProvider.company")
    //savings
    useEffect(() => {
        var apivalue = {
            environment : selectedEnvironment.toString(),
            account: selectedAccount.toString(),
            cloud: selectedCloud.toString(),
            application: selectedApplication.toString(),
            services: selectedservices.toString(),
            os: selectedos.toString(),
            infra: selectedinfra.toString(),
            db: selecteddb.toString(),
            date: selectedTopdate
        }

        dispatch(listRecommendation({companyName, apivalue }))
        .unwrap()
        .then(({data}) => {
            var potential_list = [];
            var realised_list = [];
            data.newfunctionquery10.map((map_data) => {
                if(map_data.realised_savings === null || Math.round(map_data.realised_savings) ===  0){
                    potential_list.push(map_data);
                }else{
                    realised_list.push(map_data);
                }
            });
            const pending_action = data.newfunctionquery10.length - realised_list.length;
            var pending_percentage = (pending_action * 100) / data.newfunctionquery10.length;
            var color_code = "#000";
            if(pending_percentage >= 50){
                color_code = "#be1e2d";
            }else if(pending_percentage >= 30){
                color_code = "#FFA500";
            }else{
                color_code = "#188f00";
            }
            setTopRecords({
                potential_savings: (potential_list?.reduce((a,v) =>  a = +a + +v.potential_savings , 0 )),
                realised_savings: (realised_list?.reduce((a,v) =>  a = +a + +v.realised_savings , 0 )),
                pending_action: `${data.newfunctionquery10.length - realised_list.length}/${data.newfunctionquery10.length}`,
                color_code: color_code,
            })
        })
        .catch(err => {
        });

    },[applicationList, selectedEnvironment, selectedAccount, selectedCloud, selectedApplication,  selectedservices, selectedos, selectedinfra,selecteddb]);

   

    const saving_col1 = <Box className='plat-box plat-box-hover' onClick={() => dispatch(setTabActive(3))}>
            <Row>
                <Col lg="12">
                    <p className='plat-quick-inline saving-model'>Realised Savings p.a.</p>
                </Col>
                <div className='plat-box-body saving-model'>
                    <Typography className='poppins-font cardnumber plat-totle-value'
                        color="black"
                        gutterBottom
                    >
                        {
                            toprecords.realised_savings === 0 ?
                            <label className='poppins-font cardtext'> none</label>
                            :
                            <label className='poppins-font cardnumber'> ${toprecords.realised_savings.toLocaleString(undefined, {maximumFractionDigits:2})}</label>
                        }
                        
                    </Typography>
                </div>
                
            </Row>
        </Box>;

    const saving_col2 = <Box className='plat-box plat-box-hover' onClick={() => dispatch(setTabActive(3))}>
        <Row>
            <Col lg="12">
                <p className='plat-quick-inline saving-model'>Potential Savings</p>
            </Col>
            <div className='plat-box-body saving-model'>
                <Typography className='poppins-font cardnumber plat-totle-value'
                    color="black"
                    gutterBottom
                >
                        {
                            toprecords.potential_savings === 0 ?
                            <label className='poppins-font cardtext '> none</label>
                            :
                            <label className='poppins-font cardnumber'> ${numberFormat(toprecords.potential_savings)}</label>
                        }
                </Typography>
                {/* <p className='policy-bottom-line potential-bottom'  >
                   More Savings Opportunity Upto $1000 
                </p> */}
            </div>  
        </Row>
    </Box>;
    return(<>
        {/* <ZoomIn zoominstatus={zoominstatus} setZoomInStatus={setZoomInStatus}>
            {zoominpopupcontent}
        </ZoomIn> */}
        <div className='plat-full-cover'>   
                        
                        <Row className='plat-quick-box-body cart-title-bar'>
                        <h1 className='Plat-quick-title'>Cost Savings</h1>
                            <Col lg={6}>
                                <Row>
                                    <Col lg="3" className='mt-3 plat-main-box'>
                                        {saving_col2}
                                        {/* <div className='plat-box-topicon'>
                                            <FaExpandAlt   className='plat-expandalt-icon' onClick={() => sectionZoomin('row3-col2')}/>
                                        </div> */}
                                    </Col> 
                                    <Col lg="3" className='mt-3 plat-main-box'>
                                        {saving_col1}
                                        {/* <div className='plat-box-topicon'>
                                            <FaExpandAlt   className='plat-expandalt-icon' onClick={() => sectionZoomin('row3-col1')}/>
                                        </div> */}
                                    </Col>
                                    <Col lg="3" className='mt-3 plat-main-box'>
                                        <Box className='plat-box plat-box-hover' onClick={() => dispatch(setTabActive(3))}>
                                            <Row>
                                                <Col lg="12">
                                                    <p className='plat-quick-inline saving-model'>Pending Action</p>
                                                </Col>
                                                <div className='plat-box-body saving-model'>
                                                    <Typography className='poppins-font cardnumber plat-totle-value'
                                                        color="black"
                                                        gutterBottom
                                                    >
                                                            <label className='poppins-font cardnumber' style={{color:toprecords.color_code}}>{toprecords.pending_action}</label>
                                                    </Typography>
                                                </div>  
                                            </Row>
                                        </Box>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={6}>
                                <Row>
                                    <Col lg={5} >
                                    </Col>
                                    <Col lg={5} className='dashbaord-chart-bg'>
                                        <Row>
                                            <h4 className="dashbaord-chart-title">Savings Cost</h4>
                                        </Row>
                                        <SavingsPieChart />
                                    </Col>
                                </Row>
                            </Col>
                            
                        </Row>
                    </div>
    </>)
}

export default QuickGlanceSavings;