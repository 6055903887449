import React from "react";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";
function ServiceLevelByRegion({ tableWeeklySpenddetail }) {
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      padding: 20,
    },

    heading: {
      fontSize: 14,
      marginBottom: 10,
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: 10,
      // height: "100%",
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableColumns: {
      width: "14.28%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      // padding: 25,
      // paddingTop: 15,
      // paddingLeft: 15,
      // paddingRight: 15,
    },
    tableCol: {
      width: "14.28%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      // paddingTop: 15,
      // paddingLeft: 15,
      // paddingRight: 15,
      // padding: 25,
    },
    tableColright: {
      width: "14.28%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      textAlign: "right",
    },
    tableColleft: {
      width: "14.28%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      textAlign: "left",
    },
    tableCell: {
      margin: "auto",
      marginTop: 5,
      fontSize: 8,
    },
    tableCellRight: {
      // margin: "auto",
      marginTop: 5,
      fontSize: 8,
      marginRight: 5,
    },
    tableCellleft: {
      marginTop: 5,
      fontSize: 8,
      marginLeft: 5,
    },
    subtableheading: {
      color: "#FF6A2F",
      fontWeight: "700",
      fontSize: 20,
    },
  });
  const regions = [
    ...new Set(tableWeeklySpenddetail.map((item) => item.region)),
  ];
  const formatNumberWithCommas = (number) => {
    const roundedNumber = Math.round(number); // Round the number to remove floating point
    return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div>
      {regions.map((region, index) => (
        <View style={styles.section}>
          <View style={{ flexDirection: "row", marginTop: 20 }}>
            <Text style={{ color: "#FF6A2F", fontSize: 12 }}>
              {" "}
              Regions: {region}
            </Text>
          </View>
          <View style={styles.table} wrap={true}>
            <View style={styles.tableRow}>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>Service</Text>
              </View>

              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>Application</Text>
              </View>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>Environment</Text>
              </View>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>22/10-28/10</Text>
              </View>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>29/10-04/11</Text>
              </View>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>05/11-11/11</Text>
              </View>
              <View style={styles.tableColumns}>
                <Text style={styles.tableCell}>
                  Comparison of last two weeks
                </Text>
              </View>
            </View>
            {tableWeeklySpenddetail
              .filter((item) => item.region === region)
              .map((item, dataIndex) => (
                <View key={dataIndex} style={styles.tableRow}>
                  <View style={styles.tableColleft}>
                    <Text style={styles.tableCellleft}>{item.service}</Text>
                  </View>
                  {/* <View style={styles.tableColleft}>
                    <Text style={styles.tableCellleft}>{item.region}</Text>
                  </View> */}
                  <View style={styles.tableColleft}>
                    <Text style={styles.tableCellleft}>{item.application}</Text>
                  </View>
                  <View style={styles.tableColleft}>
                    <Text style={styles.tableCellleft}>{item.environment}</Text>
                  </View>
                  <View style={styles.tableColright}>
                    <Text style={styles.tableCellRight}>
                      ${formatNumberWithCommas(item.pre_previous_week)}
                    </Text>
                  </View>
                  <View style={styles.tableColright}>
                    <Text style={styles.tableCellRight}>
                      ${formatNumberWithCommas(item.previous_week)}
                    </Text>
                  </View>
                  <View style={styles.tableColright}>
                    <Text style={styles.tableCellRight}>
                      ${formatNumberWithCommas(item.current_week)}
                    </Text>
                  </View>
                  <View style={styles.tableColright}>
                    <Text style={styles.tableCellRight}>
                      {item.difference >= 0
                        ? `$${formatNumberWithCommas(item.difference)}`
                        : `-$${formatNumberWithCommas(
                            item.difference.toString().slice(1)
                          )}`}
                    </Text>
                  </View>
                </View>
              ))}
          </View>
        </View>
      ))}
    </div>
  );
}

export default ServiceLevelByRegion;
