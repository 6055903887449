import { Col, Row } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { memo, useState } from "react";
import SpendDetailsView from "./SpendDetailsView";
import TabsLayoutProdNonProd from "./budget-spend-increase-decrease-spend/TabsLayoutProdNonProd";
import "./budget-vs-spend.css";
import "./quick-glance-card-graph/budget.css";

import BudgetForThePeriod from "./quick-glance-card-graph/BudgetForThePeriod.jsx";
import CreditsApplied from "./quick-glance-card-graph/CreditsApplied.jsx";
import SpendForecast from "./quick-glance-card-graph/SpendForecast.jsx";
import SpendForecastTableData from "./quick-glance-card-graph/SpendForecastTableData.jsx";
import UsageForTheMonth from "./quick-glance-card-graph/UsageForTheMonth.jsx";
import MonthWeekWiseSpendTrendCard from "./spend-trend-month-week-wise/MonthWeekWiseSpendTrendCard.jsx";

dayjs.extend(customParseFormat);
const BudgetvsSpend = () => {
  const [spenddetailsstatus, setSpendDetailsStatus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleUsageForTheMonthModal = () => {
    setIsModalOpen(false); //closing other modal
    setSpendDetailsStatus((prev) => !prev);
  };
  const handleForecastModal = () => {
    setSpendDetailsStatus(false); //closing other modal
    setIsModalOpen((prev) => !prev);
  };
  return (
    <>
      <div className="pt-2 pb-3">
        <Row gutter={[16, 16]}>
          <Col md={6}>
            <UsageForTheMonth
              handleUsageForTheMonthModal={handleUsageForTheMonthModal}
            />
          </Col>

          <Col md={6}>
            <SpendForecast handleForecastModal={handleForecastModal} />
          </Col>
          <Col md={6}>
            <CreditsApplied />
          </Col>
          <Col md={6}>
            <BudgetForThePeriod />
          </Col>
        </Row>
        {spenddetailsstatus && (
          <SpendDetailsView
            setSpendDetailsStatus={setSpendDetailsStatus}
            spenddetailsstatus={spenddetailsstatus}
          />
        )}

        {isModalOpen && <SpendForecastTableData />}
      </div>

      <Row gutter={[16, 16]}>
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <MonthWeekWiseSpendTrendCard />
        </Col>

        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <TabsLayoutProdNonProd />
        </Col>
      </Row>
    </>
  );
};

export default memo(BudgetvsSpend);