import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listunderutilized } from "../../../action/costimizeV2Active";
import { Table } from "antd";

function UnderUtilizedTable() {
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const { selectedCloud, selectedTopdate } = useSelector(
    (state) => state.constimize
  );

  const columns = [
    {
      title: "Resource ID",
      dataIndex: "resourceid",
      key: "resourceid",
      align: "center",
    },
    {
      title: "Instance Name",
      dataIndex: "instance_name",
      key: "instance_name",
      align: "center",
    },
    {
      title: "CPU Utilization(%)",
      dataIndex: "value",
      key: "value",
      align: "center",
    },
  ];
  useEffect(() => {
    const input = {
      company_name: localStorage.getItem(
        "CognitoIdentityServiceProvider.company"
      ),
      cloud: selectedCloud,
      selectedTopdate: selectedTopdate,
    };
    dispatch(listunderutilized(input))
      .unwrap()
      .then(({ data }) => {
        setRecords(data.list_under_utilized);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [selectedCloud, selectedTopdate]);

  return (
    <div>
      <Table
        className="savop-table mt-4"
        columns={columns}
        dataSource={records}
        pagination={{ pageSize: 5 }}
      />
    </div>
  );
}

export default UnderUtilizedTable;
