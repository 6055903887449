import { Modal } from "antd";
import React from "react";

function ModalPopUp(props) {
  return (
    <div>
      <Modal
        title={props.title}
        open={props.open}
        onCancel={props.handleCancel}
        footer={false}
        // style={{ textAlign: "center" }}
        width={props.width ? "50%" : "auto"}
        maxWidth={700}
      >
        {props.data}
      </Modal>
    </div>
  );
}

export default ModalPopUp;
