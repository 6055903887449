import { memo, useEffect, useState } from "react";
import { Modal } from "antd";
import { roleGetByID } from "../../action/accountAction";
import { useDispatch } from "react-redux";
import RoleDetailsTable from "./RoleDetailsTable";

const RoleDetails = (props) => {
  const dispatch = useDispatch();
  const [tablerecords, setTableRecords] = useState([]);
  const [newrecords, setNewRecords] = useState([]);
  useEffect(() => {
    if (props.selectedrole) {
      var setrecords = [];

      props.selectedrole.map((role_id) => {
        const company_name = props.company_name;
        dispatch(roleGetByID({ role_id, company_name }))
          .unwrap()
          .then(({ data }) => {
            if (data.usermanagementroledetails) {
              const records = JSON.parse(data.usermanagementroledetails);
              setrecords.push(records.data[0]);
              if (props.selectedrole.length === setrecords.length) {
                setTableRecords(setrecords);
              }
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      });
    }
  }, [props]);
  const handleCancel = () => {
    props.setModelOpen(false);
  };
  return (
    <>
      <Modal
        title="Role Access Information"
        open={props.modelopen}
        onCancel={handleCancel}
        footer={false}
        style={{ textAlign: "center" }}
        width={800}
        className="form-info-popup"
      >
        {tablerecords.map((data) => {
          return <RoleDetailsTable records={data} />;
        })}
      </Modal>
    </>
  );
};

export default memo(RoleDetails);
