// features/user/userSlice.js
import { createSlice } from "@reduxjs/toolkit";
const currentDate = new Date();
const calculateSelectedTopdate = () => {
  const currentDate = new Date();
  if (currentDate.getDate() <= 3) {
    const lastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    return `${lastMonth.getFullYear()}-${lastMonth.getMonth() + 1}-01`;
  } else {
    return `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-01`;
  }
};
const initialState = {
  environmentList: [],
  applicationList: [],
  accountList: [],
  cloudList: [],
  serviceList: [],
  infraList: [],
  osList: [],
  dbList: [],
  selectedEnvironment: ["all"],
  selectedAccount: ["all"],
  selectedCloud: ["all"],
  selectedApplication: ["all"],
  // selectedTopdate: `${new Date().getFullYear()}-${
  //   new Date().getMonth() + 1
  // }-01`,
  selectedTopdate: calculateSelectedTopdate(),
  selectedservices: ["all"],
  selectedos: ["all"],
  selectedinfra: ["all"],
  selecteddb: ["all"],
  spendtrandschartdata: [],
  spendtrandschartmonth: [],
  searchapplication: "",
  alldropdownlist: [],
  dummydatastatus: false,
  tabactive: 1,
  filterbuttonstatus: false,
  selectedrowlist: [],
};

const costimizeSlice = createSlice({
  name: "costimize",
  initialState,
  reducers: {
    environmentListMethod: (state, { payload }) => {
      state.environmentList = payload;
    },
    cloudListMethod: (state, { payload }) => {
      state.cloudList = payload;
    },
    applicationListMethod: (state, { payload }) => {
      state.applicationList = payload;
    },
    accountListMethod: (state, { payload }) => {
      state.accountList = payload;
    },
    selectedEnvironmentMethod: (state, { payload }) => {
      state.selectedEnvironment = payload;
    },
    selectedAccountMethod: (state, { payload }) => {
      state.selectedAccount = payload;
    },
    selectedCloudMethod: (state, { payload }) => {
      state.selectedCloud = payload;
    },
    selectedApplicationMethod: (state, { payload }) => {
      state.selectedApplication = payload;
    },
    selectedTopDateMethod: (state, { payload }) => {
      state.selectedTopdate = payload;
    },
    selectedServicesMethod: (state, { payload }) => {
      state.selectedservices = payload;
    },
    selectedOSMethod: (state, { payload }) => {
      state.selectedos = payload;
    },
    selectedInfraMethod: (state, { payload }) => {
      state.selectedinfra = payload;
    },
    spendTrandChartData: (state, { payload }) => {
      state.spendtrandschartdata = payload;
    },
    spendTrandChartMonth: (state, { payload }) => {
      state.spendtrandschartmonth = payload;
    },
    selectedDbMethod: (state, { payload }) => {
      state.selecteddb = payload;
    },
    searchApplication: (state, { payload }) => {
      state.searchapplication = payload;
    },
    allDropdownList: (state, { payload }) => {
      state.alldropdownlist = payload;
    },
    setDummyDataStatus: (state, { payload }) => {
      state.dummydatastatus = payload;
    },
    setTabActive: (state, { payload }) => {
      state.tabactive = payload;
    },
    setServiceList: (state, { payload }) => {
      state.serviceList = payload;
    },
    setInfraRList: (state, { payload }) => {
      state.infraList = payload;
    },
    setOsList: (state, { payload }) => {
      state.osList = payload;
    },
    setDbList: (state, { payload }) => {
      state.dbList = payload;
    },
    setFilterButtonStatus: (state, { payload }) => {
      state.filterbuttonstatus = payload;
    },
    setSelectedRowList: (state, { payload }) => {
      state.selectedrowlist = payload;
    },
  },
  extraReducers: {},
});

export default costimizeSlice.reducer;
export const {
  environmentListMethod,
  applicationListMethod,
  accountListMethod,
  selectedEnvironmentMethod,
  selectedAccountMethod,
  selectedCloudMethod,
  selectedApplicationMethod,
  selectedTopDateMethod,
  selectedServicesMethod,
  selectedOSMethod,
  selectedInfraMethod,
  spendTrandChartData,
  spendTrandChartMonth,
  selectedDbMethod,
  searchApplication,
  allDropdownList,
  setDummyDataStatus,
  cloudListMethod,
  setTabActive,
  setServiceList,
  setInfraRList,
  setOsList,
  setDbList,
  setFilterButtonStatus,
  setSelectedRowList,
} = costimizeSlice.actions;
