import { memo, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const CPUChart = (props) => {
  const [chartvalue, setChartValue] = useState({
    option: {},
    series: [],
  });
  useEffect(() => {
    setChartValue({
      option: {
        chart: {
          type: "area",
          height: 160,
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
          width:1,
        },
        grid: {
          show: false,
        },
        colors: ["#9DFF00", "#FCDC2A"],
        // colors: ["#B17C03", "#fff", "#8D1E74", "#0C2E4E"],
        markers: false,
        // markers: {
        //   size: 4,
        //   colors: undefined,
        //   strokeColors: "#fff",
        //   strokeWidth: 2,
        //   strokeOpacity: 0.9,
        //   strokeDashArray: 0,
        //   fillOpacity: 1,
        //   discrete: [],
        //   shape: "circle",
        //   radius: 2,
        //   offsetX: 0,
        //   offsetY: 0,
        //   onClick: undefined,
        //   onDblClick: undefined,
        //   showNullDataPoints: true,
        //   hover: {
        //     size: undefined,
        //     sizeOffset: 3,
        //   },
        // },
        xaxis: {
          //   categories: spendtrandschartmonth,
          categories: props.label.index,
          tickPlacement: "on",
          axisBorder: {
            show: true,
            color: "#78909C",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },

          axisTicks: {
            show: false,
            borderType: "solid",
            color: "#78909C",
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
          labels: {
            offsetX: 0,
            offsetY: -2,
            style: {
              fontSize: "12px",
              fontWeight: 400,
              colors: "#fff",
            },
          },
          title: {
            text: "Days",
            rotate: -180,
            offsetX: 0,
            offsetY: -10,
            style: {
              color: "#fff",
              fontSize: "12px",
              fontWeight: 500,
            },
          },
        },
        yaxis: {
          // tickAmount: 2,
          axisBorder: {
            show: true,
            color: "#78909C",
            offsetX: 0,
            offsetY: 0,
          },
          title: {
            text: "Percentage",
            rotate: -90,
            offsetX: 7,
            offsetY: 0,
            style: {
              color: "#fff",
              fontSize: "12px",
              fontWeight: 500,
            },
          },
          labels: {
            style: {
              fontSize: "8px",
              fontWeight: 400,
              colors: "#fff",
            },
            formatter: (num) => {
              return Math.abs(num) > 999
                ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
                : Math.sign(num) * Math.abs(num);
            },
          },
        },
        title: {
          text: "CPU",
          align: "center",
          margin: 0,
          offsetX: 0,
          offsetY: 15,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "500",
            color: "#fff",
          },
        },
        legend: {
          labels: {
            colors: "#fff",
          },
        },
      },
      series: [
        {
          name: "Max",
          data: props.value.cpu_max,
        },
        // {
        //   name: "Min",
        //   data: props.value.cpu_min,
        // },
        {
          name: "Avg",
          data: props.value.cpu_avg,
        },
      ],
    });
  }, [props]);
  return (
    <>
      <ReactApexChart
        options={chartvalue.option}
        series={chartvalue.series}
        type="area"
        height={250}
        className="trendchartv2"
      />
    </>
  );
};

export default memo(CPUChart);
